import React, {ReactElement, ReactNode, useEffect, useState} from 'react'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'
import {motion} from 'framer-motion'

import {cssTheme} from '../../../themes'
import {Alert, AlertVariant, IconAlignment} from '../Alert'

export interface Props {
  /** Pass through classname to allow styles overrides */
  className?: string
  /** An icon placed before the Toast content. It can be any type of React Element */
  icon?: ReactElement
  /** The content of the Toast, a React component or pure text */
  children: ReactNode
  /** The header content of the Toast, a React component or pure text */
  title?: string
  /** Pass through alignIcon to allow vertically center aligned icon for example */
  alignIcon?: IconAlignment
  /** Toast closing will trigger the callback function. It will be called both on press close button and on self-closing */
  onClose?: () => void
  /** Toast click will trigger the callback function. */
  onClick?: (event: React.SyntheticEvent) => void
  /** Callback function that will fire when the user clicks on the close button. If an onClose callback is passed in, it will be called along with this callback. */
  onCloseButtonClick?: () => void
  /** Determines whether the Toast will be auto closed or not.*/
  autoClose?: boolean
  /** Whether to show the close button. */
  showCloseButton?: boolean
  /** Color schemes are based on pre-defined themes */
  variant?: AlertVariant
  /** Whether to render the toast full width */
  isFullWidth?: boolean
  /** Style prop is used by Linaria to pass CSS variables in cases when we need to style custom components in this way: `const StyledToast = styled(Toast)` */
  style?: React.CSSProperties
}

const ToastContent = styled(motion.div)`
  border-radius: ${cssTheme.layout.radius.sm};
  box-shadow: ${cssTheme.shadows.floating};
  overflow: hidden;
  animation: slide-in 0.2s;
  pointer-events: auto;
  &.isFullWidth {
    width: 100%;
  }
`

const HIDE_TIMEOUT = 3000

export const Toast = ({
  icon,
  onClose,
  onCloseButtonClick,
  onClick,
  children,
  variant,
  title,
  alignIcon,
  showCloseButton = false,
  autoClose = true,
  isFullWidth = false,
  className,
  style
}: Props) => {
  const [timeoutId, setTimeoutId] = useState<number>()

  useEffect(() => {
    if (autoClose) {
      setTimeoutId(window.setTimeout(toggleToast(false), HIDE_TIMEOUT))
    }

    return () => window.clearTimeout(timeoutId)
  }, [])

  const toggleToast = (to: boolean) => () => {
    if (!to && onClose) {
      window.setTimeout(onClose, 200)
    }
  }

  const handleMouseEnter = (): void => {
    window.clearTimeout(timeoutId)
  }

  const handleMouseLeave = (): void => {
    if (autoClose) {
      setTimeoutId(window.setTimeout(toggleToast(false), HIDE_TIMEOUT))
    }
  }

  const handleCloseClick = () => {
    if (onCloseButtonClick) onCloseButtonClick()
    if (onClose) onClose()
  }

  return (
    <ToastContent
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      initial={{opacity: 0, y: 20}}
      animate={{opacity: 1, y: 0}}
      exit={{opacity: 0}}
      layout
      className={cx(isFullWidth && 'isFullWidth', className)}
      style={style}
    >
      <Alert
        variant={variant}
        title={title}
        icon={icon}
        onClose={showCloseButton ? handleCloseClick : undefined}
        alignIcon={alignIcon}
      >
        {children}
      </Alert>
    </ToastContent>
  )
}
