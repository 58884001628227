// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgToggleLeft = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 10A4.5 4.5 0 0 1 8 5.5h4a4.5 4.5 0 1 1 0 9H8A4.5 4.5 0 0 1 3.5 10ZM8 4a6 6 0 1 0 0 12h4a6 6 0 0 0 0-12H8Zm-1.5 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM8 7a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
    />
  </svg>
)

export default SvgToggleLeft
