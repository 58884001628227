import {LogLevel, logToDataDog} from 'services/datadogLogger'

export enum SAPIServiceStatus {
  start = 'Start',
  received = 'Received',
  done = 'Done',
  error = 'Error',
  fatalError = 'FatalError'
}

export enum SAPIServiceEndpointNames {
  Rooms = 'Rooms',
  Hotel = 'Hotel',
  Offers = 'Offers'
}

const logSAPIService = (
  endpoint: SAPIServiceEndpointNames,
  status: SAPIServiceStatus,
  metadata?,
  level?: LogLevel
) => {
  logToDataDog({
    logName: `Services.SAPI.${endpoint}.${status}`,
    metadata: {metadata},
    level
  })
}

export default logSAPIService
