import {toggle} from 'opticks'

import {RateBreakdown} from '@findhotel/sapi'

import {calculateTotalRate} from './calculateTotalRate'

const discountPercentage = 0.02
const discountMinPrice = 10.0

export const isSplitBookingPriceEligible = (
  splitBookingTotalRate: RateBreakdown | undefined,
  cheapestOfferTotalRate: number
): boolean => {
  if (!cheapestOfferTotalRate) {
    return true
  }

  if (!splitBookingTotalRate) {
    return false
  }

  const splitBookingOfferTotalRate = calculateTotalRate(
    splitBookingTotalRate,
    true,
    true
  )

  const minDiscount = Math.max(
    discountPercentage * cheapestOfferTotalRate,
    discountMinPrice
  )

  // Disable split booking price filter. Only used for QA purposes in staging.
  if (toggle('feature-disable-split-booking-price-filter', false, true)) {
    return true
  }

  return splitBookingOfferTotalRate < cheapestOfferTotalRate - minDiscount
}
