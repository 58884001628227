// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgHelpCircle = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.5a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13ZM2 10a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm6.496-1.92-.001.006c0 .001 0 .001 0 0a.75.75 0 0 1-1.49-.169L7.75 8a80.657 80.657 0 0 1-.745-.084V7.91l.002-.01.004-.03.015-.096A4.086 4.086 0 0 1 7.47 6.51C7.87 5.79 8.647 5 10 5c1.165 0 1.993.374 2.485 1.064C12.941 6.701 13 7.466 13 8a2.687 2.687 0 0 1-1.75 2.53c-.17.065-.31.16-.398.257-.084.091-.102.161-.102.213v.25a.75.75 0 1 1-1.5 0V11c0-1.011.825-1.625 1.46-1.87.534-.206.79-.664.79-1.13 0-.466-.066-.826-.235-1.064-.133-.185-.43-.436-1.265-.436-.648 0-.995.335-1.22.74a2.587 2.587 0 0 0-.284.84ZM10 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
    />
  </svg>
)

export default SvgHelpCircle
