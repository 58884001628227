import {ServerCode} from 'services/psd2/types'
import Settings from 'Settings'

import {getCookie} from '@daedalus/core/src/_web/utils/cookies'

import {
  scriptHash as productionScriptHash,
  scriptText as productionScriptText
} from './forterProductionScript'
import {
  scriptHash as testingScriptHash,
  scriptText as testingScriptText
} from './forterTestingScript'

const getScriptTextAndHash = () =>
  Settings.get('REACT_APP_ENV') === 'production'
    ? {text: productionScriptText, hash: productionScriptHash}
    : {text: testingScriptText, hash: testingScriptHash}

let token: string
let isScriptAppended = false

export const initForter = () => {
  if (!isScriptAppended) {
    document.addEventListener('ftr:tokenReady', function (evt) {
      token = (evt as Event & {detail: string}).detail
    })

    const {text, hash} = getScriptTextAndHash()

    const script = document.createElement('script')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('id', '948e1f330455')
    script.setAttribute('script-src', hash)
    script.setAttribute('script-src-elem', hash)
    script.text = text

    document.body.appendChild(script)
    isScriptAppended = true
  }
}

export const getForterTokenObject = (providerCode?: string) => {
  // PPN  token should be sent if the provider is PPN
  if (providerCode === ServerCode.Ppn) {
    const ppnToken = getCookie('RS-CLIENT')

    return ppnToken ? {fraudProtectionToken: ppnToken} : undefined
  }

  return token ? {fraudProtectionToken: token} : undefined
}

// For testing purposes
// This should only be used in a testing environment
export const __setTokenForTesting = (newToken: string) => {
  // eslint-disable-next-line fp/no-mutation
  token = newToken
}
