// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgGlobe = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.47 3.806c.299-.269.484-.306.53-.306.046 0 .231.037.529.306.282.256.6.663.898 1.23.523.996.957 2.425 1.053 4.214H7.52c.096-1.789.53-3.218 1.053-4.213.298-.568.615-.975.898-1.23ZM6.019 9.25c.098-2.02.585-3.69 1.227-4.912.055-.103.11-.205.168-.303a6.506 6.506 0 0 0-3.87 5.215h2.475Zm-2.475 1.5h2.475c.098 2.02.585 3.69 1.227 4.912.055.103.11.205.168.303a6.506 6.506 0 0 1-3.87-5.215Zm3.977 0h4.96c-.096 1.789-.53 3.218-1.053 4.213-.298.568-.616.975-.898 1.23-.298.27-.483.307-.53.307-.045 0-.23-.037-.528-.306-.283-.256-.6-.663-.898-1.23-.524-.996-.957-2.425-1.053-4.214Zm6.462 0c-.098 2.02-.585 3.69-1.227 4.912-.055.103-.11.205-.168.303a6.506 6.506 0 0 0 3.87-5.215h-2.475Zm2.475-1.5a6.506 6.506 0 0 0-3.87-5.215c.057.098.113.2.168.303.642 1.222 1.129 2.892 1.227 4.912h2.475ZM2 10a8 8 0 1 1 8 8 8 8 0 0 1-8-8Z"
    />
  </svg>
)

export default SvgGlobe
