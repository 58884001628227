// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgBell = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2a6 6 0 0 0-6 6v1.832L2.07 13.931A.75.75 0 0 0 2.75 15h3.516a3.467 3.467 0 0 0 .466 1.386C7.247 17.245 8.246 18 10 18c1.754 0 2.753-.755 3.268-1.614A3.468 3.468 0 0 0 13.734 15h3.516a.75.75 0 0 0 .679-1.07l-1.93-4.098V8a6 6 0 0 0-6-6ZM7.784 15h4.432a1.965 1.965 0 0 1-.234.614c-.235.391-.736.886-1.982.886s-1.747-.495-1.982-.886c-.13-.217-.199-.44-.234-.614ZM5.5 8a4.5 4.5 0 0 1 9 0v2c0 .11.024.22.071.32l1.497 3.18H3.932l1.497-3.18a.75.75 0 0 0 .07-.32V8Z"
    />
  </svg>
)

export default SvgBell
