// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgWalk = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 16 16"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.628 2.94a.034.034 0 1 0 0 .067.034.034 0 0 0 0-.068Zm-1.223.033a1.223 1.223 0 1 1 2.446 0 1.223 1.223 0 0 1-2.446 0Zm-.14 3.31-.48 2.876c-.032.19.03.382.166.518l1.817 1.817L9.98 13.92a.595.595 0 1 0 1.064-.532l-1.257-2.513a.592.592 0 0 0-.111-.155L8.008 9.052l.388-2.329 1.069 1.069a.595.595 0 0 0 .232.144l1.885.628a.595.595 0 0 0 .376-1.128l-1.752-.584-1.785-1.786a.595.595 0 0 0-.581-.152l-2.498.624a.595.595 0 0 0-.35.247L3.735 7.67a.595.595 0 0 0 .99.66l1.129-1.694 1.41-.353ZM7.1 10.666c.262.197.316.57.119.832l-1.885 2.514a.595.595 0 1 1-.952-.714l1.885-2.513a.595.595 0 0 1 .833-.119Z"
      fill="#6C6770"
    />
  </svg>
)

export default SvgWalk
