import {MiddlewareType} from 'middlewares'
import {getHttpRequestId} from 'modules/common/selectors'
import logBoFHService, {
  BoFHServiceEndpointNames,
  BoFHServiceStatus
} from 'performance/logBoFHService'
import {AnyAction} from 'redux'
import timing from 'utils/timing'

import {bookingRetrieveApi} from '@daedalus/core/src/booking/services/bookingRetrieveApi'

const requestsTiming = timing()

const middleware: MiddlewareType =
  store => next => async (action: AnyAction) => {
    const {getState} = store
    const state = getState()
    const httpRequestId = getHttpRequestId(state)

    next(action)

    // Get booking by id handling

    if (bookingRetrieveApi.endpoints.getBookingById.matchPending(action)) {
      requestsTiming.start('fetchBooking')
      logBoFHService(
        BoFHServiceEndpointNames.BookingRetrieve,
        BoFHServiceStatus.start,
        {request_id: httpRequestId}
      )
    }

    if (bookingRetrieveApi.endpoints.getBookingById.matchFulfilled(action)) {
      const timings = requestsTiming.stop('fetchBooking')
      logBoFHService(
        BoFHServiceEndpointNames.BookingRetrieve,
        BoFHServiceStatus.done,
        {timings, request_id: httpRequestId}
      )
    }

    if (bookingRetrieveApi.endpoints.getBookingById.matchRejected(action)) {
      const timings = requestsTiming.stop('fetchBooking')
      logBoFHService(
        BoFHServiceEndpointNames.BookingRetrieve,
        BoFHServiceStatus.error,
        {
          error: action?.payload,
          timings,
          request_id: httpRequestId
        },
        'error'
      )
    }
  }

export default middleware
