// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPizza2 = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.5a6.5 6.5 0 1 0 6.5 6.5.75.75 0 0 1 1.5 0 8 8 0 1 1-8-8 .75.75 0 0 1 0 1.5ZM6 8a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm3 4a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm4 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm0-10a.75.75 0 0 0-.737.61l-1 5.25a.75.75 0 0 0 .877.877l5.25-1A.75.75 0 0 0 18 7v-.25A4.75 4.75 0 0 0 13.25 2H13Zm-.057 5.057.674-3.537a3.251 3.251 0 0 1 2.863 2.863l-3.537.674Z"
    />
  </svg>
)

export default SvgPizza2
