import {applyToggles, saveToggles} from '../modules/localstorage'
import {getExperimentsFromUrl} from '../modules/utils'

export const useDebugPanelToggles = ({params}: {params: string}) => {
  const {experiments, persist} = getExperimentsFromUrl(params)

  if (persist) {
    saveToggles(experiments)
  }

  return {
    applyLocalstorageToggles: applyToggles
  }
}
