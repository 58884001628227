import React from 'react'
import {createRoot} from 'react-dom/client'
import {Provider as ReduxProvider} from 'react-redux'
import initializeSimulator from 'mocks/initializeSimulator'
import initializeAnalytics from 'modules/analytics/initializeSDK'
import {trackPerformance} from 'performance/logPerformanceMetrics'
import reportWebVitals from 'performance/reportWebVitals'
import Settings from 'Settings'

import {configureAmplify} from '@daedalus/core/src/auth/services'
import {urlHasParam} from '@daedalus/core/src/utils/url'

import App from './App'
import * as serviceWorker from './serviceWorker'
import {store} from './store'

const URL_SEARCH = window.location.search
const SIMULATE_PARAM = 'simulate'

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
if (
  Settings.get('WEBSITE_ENABLE_SIMULATOR') &&
  urlHasParam({search: URL_SEARCH, param: SIMULATE_PARAM})
) {
  initializeSimulator()
} else {
  serviceWorker.unregister()
}

configureAmplify()
initializeAnalytics()
reportWebVitals(trackPerformance)

const rootElement = document.querySelector('#root')

if (rootElement && store) {
  const root = createRoot(rootElement)

  root.render(
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  )
}
