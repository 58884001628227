// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPoolIndoor = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="M17.78 15.7a2.499 2.499 0 0 0-3.56 0l-.5.5a1.05 1.05 0 0 1-1.44 0l-.5-.5a2.499 2.499 0 0 0-3.56 0l-.5.5a1.05 1.05 0 0 1-1.44 0l-.5-.5a2.499 2.499 0 0 0-3.56 0 .75.75 0 0 0 1.06 1.06 1 1 0 0 1 1.44 0l.5.5a2.58 2.58 0 0 0 3.56 0l.5-.5a1 1 0 0 1 1.44 0l.5.5A2.54 2.54 0 0 0 13 18a2.54 2.54 0 0 0 1.78-.74l.5-.5a1 1 0 0 1 1.44 0 .75.75 0 0 0 1.06-1.06ZM3.34 6.45l6.71-2.87 6.7 2.87a.66.66 0 0 0 .3.07.75.75 0 0 0 .69-.46.75.75 0 0 0-.4-1l-7-3a.76.76 0 0 0-.59 0l-7 3a.75.75 0 0 0-.39 1 .74.74 0 0 0 .98.39Z" />
    <path d="M14.62 10.05a.75.75 0 0 0 .75-.75v-.8a2.05 2.05 0 0 0-2-2 2.05 2.05 0 0 0-2.05 2v2.69h-4V8.5a.55.55 0 0 1 .55-.55.55.55 0 0 1 .54.55v.8a.76.76 0 0 0 .75.75.75.75 0 0 0 .75-.75v-.8a2.05 2.05 0 0 0-2-2 2.05 2.05 0 0 0-2.05 2v5.53a.75.75 0 0 0 .75.75.74.74 0 0 0 .75-.75v-1.34h4v.92a.75.75 0 0 0 .75.75.74.74 0 0 0 .75-.75V8.5a.55.55 0 0 1 .55-.55.55.55 0 0 1 .54.55v.8a.76.76 0 0 0 .67.75Z" />
  </svg>
)

export default SvgPoolIndoor
