import React from 'react'
import {Helmet} from 'react-helmet'
import {defineMessages, useIntl} from 'react-intl'
import {$PropertyType} from 'utility-types'

import {Brand} from '../../../brand/types'

/**
 * Injects a meta description tag into the page html. The content is in the user's current language.
 */
export interface MetaDescriptionProps {
  /** The brand name used in the meta description */
  brandName: $PropertyType<Brand, 'name'>
}

const description = defineMessages({
  legacyMetaDescription: {
    id: 'meta.description',
    defaultMessage:
      'Book the right hotel at the best price. Find unbelievable deals on hotels, all in one place. Save your money & time with {brandName}.'
  },
  metaDescription: {
    id: 'meta.descriptionVio',
    defaultMessage:
      'Get a better deal with {brandName}. Save money and get access to millions of stays; from cheap hotels and motels to luxury villas.'
  }
})

const InjectMetaDescription = ({brandName}: MetaDescriptionProps) => {
  const {formatMessage} = useIntl()

  return (
    <Helmet>
      <meta
        name="description"
        content={formatMessage(
          brandName === 'Vio.com'
            ? description.metaDescription
            : description.legacyMetaDescription,
          {
            brandName
          }
        )}
      />
    </Helmet>
  )
}

export default InjectMetaDescription
