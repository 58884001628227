// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgDisability = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 3.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM6 5a3 3 0 1 1 3.5 2.959V9h2.75a.75.75 0 0 1 0 1.5H9.5v2h2.75a.75.75 0 0 1 .6.3l3 4a.75.75 0 1 1-1.2.9L11.875 14H8.75a.75.75 0 0 1-.75-.75V7.83A3.001 3.001 0 0 1 6 5Zm-.329 3.61a.75.75 0 0 1 0 1.061 4 4 0 0 0 4.66 6.386.75.75 0 0 1 .688 1.334A5.502 5.502 0 0 1 4.61 8.61a.75.75 0 0 1 1.06 0Z"
    />
  </svg>
)

export default SvgDisability
