import React from 'react'

import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

interface Props {
  numberOfGuests: number
  numberOfRooms?: number
  separator?: string
}

export const GuestPickerButtonMessage = ({
  numberOfGuests,
  numberOfRooms,
  separator = ' · '
}: Props) => (
  <>
    <span>{numberOfGuests} </span>
    <FormattedMessageWrapper
      id="roomReservationDetails.guestsCount"
      defaultMessage="{count, plural, one {guest} other {guests}}"
      values={{
        count: numberOfGuests
      }}
    />
    {numberOfRooms && (
      <>
        <span>{separator}</span>
        <span>{numberOfRooms} </span>
        <FormattedMessageWrapper
          id="roomReservationDetails.roomsCount"
          defaultMessage="{count, plural, one {room} other {rooms} }"
          values={{
            count: numberOfRooms
          }}
        />
      </>
    )}
  </>
)
