// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgBolt = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.49 2.04a.75.75 0 0 1 .51.71V8h3.25a.75.75 0 0 1 .596 1.206l-6.5 8.5A.75.75 0 0 1 8 17.25V12H4.75a.75.75 0 0 1-.596-1.206l6.5-8.5a.75.75 0 0 1 .837-.254ZM6.269 10.5H8.75a.75.75 0 0 1 .75.75v3.785L13.732 9.5H11.25a.75.75 0 0 1-.75-.75V4.965L6.268 10.5Z"
    />
  </svg>
)

export default SvgBolt
