// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgNotebook = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 4.75c0-.69.56-1.25 1.25-1.25H7v12H5.75c-.69 0-1.25-.56-1.25-1.25v-9.5ZM7 17v.25a.75.75 0 0 0 1.5 0V17h5.75A2.75 2.75 0 0 0 17 14.25v-9.5A2.75 2.75 0 0 0 14.25 2h-8.5A2.75 2.75 0 0 0 3 4.75v9.5A2.75 2.75 0 0 0 5.75 17H7ZM8.5 3.5h5.75c.69 0 1.25.56 1.25 1.25v9.5c0 .69-.56 1.25-1.25 1.25H8.5v-12ZM11.75 6a.75.75 0 1 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5ZM11 9.75a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75Z"
    />
  </svg>
)

export default SvgNotebook
