import {css} from '@linaria/core'
import {styled} from '@linaria/react'

import {cssTheme} from '../../../themes'
import {Button} from '../Button'

const TOP_CONTENT_ELEMENT_HEIGHT = 48
const ICON_SIZE = 44
const MAX_LOGO_WIDTH = 120
export const MOBILE_HEADER_HEIGHT = 65

export const HeaderElement = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${cssTheme.layout.spacing.s300} 0;
  background-color: ${cssTheme.colors.background.neutral.c000};
  text-align: center;

  &.--shadow {
    box-shadow: ${cssTheme.shadows.fixed};
  }
`

export const HeaderTopContentElement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${cssTheme.layout.spacing.s400};
  height: ${TOP_CONTENT_ELEMENT_HEIGHT}px;
`

export const logoStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: ${ICON_SIZE}px;
  max-width: ${MAX_LOGO_WIDTH}px;
  width: auto;
  margin: 0;

  &.--compact {
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
  }
`

export const BackButtonElement = styled(Button)`
  /* @mirror */
`

export const CenterContentWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-width: 0;
  text-align: center;
  ${cssTheme.typography.text.titleM};
`

export const Spacer = styled.div`
  width: ${ICON_SIZE}px;
`

export const EndContentWrapper = styled.div`
  min-width: ${ICON_SIZE}px;
`
