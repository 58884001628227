/**
 * Determine whether this code is running in a webview.
 * This will NOT target code which runs in React Native in the mobile app
 *
 * - prefer `isMobileApp()` to target both React Native and Webviews (for shared code between web/native)
 * - use `isReactNativeWebView()` to target webviews specifically
 *
 * Uses the `ReactNativeWebView` on the `window` to determine if user is navigating in a WebView.
 */
const isReactNativeWebView = (): boolean => {
  return Boolean(window.ReactNativeWebView) || Boolean(window.isReactNativeApp)
}

export {isReactNativeWebView}
