import React, {useMemo} from 'react'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'

import {Icon} from '@daedalus/atlas/Icon'
import {Price} from '@daedalus/atlas/Price'
import {
  AnnotationLabelStyles,
  BasicTagWrapper,
  Tag,
  Wrapper
} from '@daedalus/atlas/Tag'
import {cssTheme} from '@daedalus/atlas/themes'
import {BrandCode, BrandMessage} from '@daedalus/core/src/brand/types'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {GemTagSavingType} from '@daedalus/core/src/tag'

const Container = styled.div`
  &.isKiwi {
    ${Wrapper} {
      border-radius: ${cssTheme.layout.radius.rounded};
      box-shadow: 0 0 0 1px ${cssTheme.colors.border.neutral.c200};
      ${BasicTagWrapper} {
        border-radius: ${cssTheme.layout.radius.rounded};
        box-shadow: none;
      }
    }
    ${BasicTagWrapper} {
      border-radius: ${cssTheme.layout.radius.rounded};
      box-shadow: 0 0 0 1px ${cssTheme.colors.border.neutral.c200};
    }
    .${AnnotationLabelStyles} {
      border-radius: 0 ${cssTheme.layout.radius.rounded}
        ${cssTheme.layout.radius.rounded} 0;
    }
  }
`

interface Props {
  /** The main message to be displayed */
  message: BrandMessage
  /** Value to be displayed as percentage saved. Will only display if larger than 0. */
  savingPercentage?: number
  /** Brand code */
  brandCode: BrandCode
  /** Value to be displayed as percentage saved. Will only display if larger than 0. */
  savingPrice?: number
  /** Price currency code */
  currencyCode?: string
  /** Saving type to show */
  savingType?: GemTagSavingType // Added as a part of c9f92dea-new-tags-poc
}

export const PrivateDealTag = ({
  message,
  savingPercentage,
  brandCode,
  savingPrice = 0,
  currencyCode,
  savingType = 'percentage'
}: Props) => {
  const hasSavings = Boolean(savingPercentage && savingPercentage > 0)
  const brandIsVio = brandCode === 'vio'
  const isKiwiBrand = brandCode === 'kiwi'
  const dataId = brandIsVio ? 'membershipLabel' : 'privateDealTag'

  // Added as a part of c9f92dea-new-tags-poc
  const annotation = useMemo(() => {
    if (hasSavings) {
      if (savingType === 'number') {
        return (
          <Price noStyle value={-1 * savingPrice} currency={currencyCode} />
        )
      }
      return `-${savingPercentage}%`
    }
    return undefined
  }, [hasSavings, savingType, savingPercentage, currencyCode, savingPrice])

  return (
    <Container data-id={dataId} className={cx(isKiwiBrand && 'isKiwi')}>
      <Tag
        variant={isKiwiBrand ? 'neutral' : 'special'}
        iconStart={
          isKiwiBrand ? <Icon name="StarSolid" size="sm" /> : undefined
        }
        annotation={annotation}
      >
        <FormattedMessageWrapper {...message} />
      </Tag>
    </Container>
  )
}
