import React from 'react'
import {styled} from '@linaria/react'

export type Props = {
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Country code of the flag that should be displayed */
  countryCode?: string
  /** The size of the flag */
  size?: number
}

const StyledFlag = styled.img<{size: number}>`
  width: ${({size}) => size}px;
`

export const Flag = ({countryCode = 'gb', size = 20, className}: Props) => {
  const lowercaseCode = countryCode.toLowerCase()

  // To not crash entire app if we will not have icon for specific country
  try {
    return (
      <StyledFlag
        alt={lowercaseCode}
        src={require(`../../../foundation/flags/${lowercaseCode}.svg`)}
        size={size}
        className={className}
      />
    )
  } catch {
    return null
  }
}
