// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCoins = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.627 4.592a.792.792 0 0 1-.101-.092.791.791 0 0 1 .1-.092c.172-.136.467-.29.899-.434C9.38 3.689 10.608 3.5 12 3.5s2.62.19 3.475.474c.431.144.726.298.898.434.05.038.08.07.101.092a.789.789 0 0 1-.1.092c-.173.135-.467.29-.899.434-.855.285-2.083.474-3.475.474s-2.62-.19-3.475-.474c-.432-.144-.727-.299-.898-.434Zm8.322 1.857c.191-.064.376-.134.551-.211v2.23a.49.49 0 0 1-.081.086c-.124.11-.348.246-.693.381a.75.75 0 0 0 .547 1.397c.435-.17.837-.385 1.143-.657.304-.27.584-.665.584-1.175v-4c0-.57-.347-.994-.698-1.27-.36-.284-.834-.506-1.352-.679C14.904 2.203 13.508 2 12 2s-2.904.203-3.95.551c-.518.173-.992.395-1.352.679-.35.276-.698.7-.698 1.27v2.75a.75.75 0 0 0 1.5 0V6.238c.175.077.36.147.55.21C9.096 6.799 10.492 7 12 7s2.904-.203 3.95-.551Zm.559 2.004-.002.005c0-.003.002-.005.002-.005Zm-.002-3.995a.02.02 0 0 1 .001-.005l-.002.005Zm-9.012 0a.014.014 0 0 1-.001-.005l.001.005Zm-3.867 7.134a.798.798 0 0 1-.101-.092.79.79 0 0 1 .1-.092c.173-.135.468-.29.899-.434C5.38 10.69 6.608 10.5 8 10.5s2.62.19 3.475.474c.431.144.726.299.898.434.05.039.08.07.101.092a.805.805 0 0 1-.1.092c-.173.135-.468.29-.899.434-.855.285-2.083.474-3.475.474s-2.62-.19-3.475-.474c-.431-.144-.726-.299-.898-.434Zm8.323 1.857c.19-.064.375-.134.55-.211v2.23a.581.581 0 0 1-.127.124c-.171.135-.466.29-.898.434-.855.285-2.083.474-3.475.474s-2.62-.19-3.475-.474c-.431-.144-.726-.299-.898-.434a.585.585 0 0 1-.127-.125v-2.23c.175.078.36.148.55.212C5.097 13.797 6.493 14 8 14s2.904-.203 3.95-.551Zm.557 2.005-.001.004.001-.004Zm-9.014 0 .001.004a.014.014 0 0 1-.001-.004ZM14 15.5v-4c0-.57-.347-.993-.698-1.27-.36-.284-.834-.506-1.352-.679-1.046-.348-2.442-.55-3.95-.55s-2.904.202-3.95.55c-.518.173-.992.395-1.352.679-.35.277-.698.7-.698 1.27v4c0 .57.347.994.698 1.27.36.284.834.506 1.353.679C5.096 17.797 6.492 18 8 18s2.904-.203 3.95-.551c.518-.173.992-.395 1.352-.679.35-.276.698-.7.698-1.27Zm-1.494-4.042.001-.004-.001.004Zm-9.012 0a.014.014 0 0 1-.001-.004l.001.004Z"
    />
  </svg>
)

export default SvgCoins
