import {CancellationPenalty} from '../types/offer'
import {getRefundType} from './cancellationPenalties'
import {getOfferService} from './meal'

interface OfferPerks {
  penaltyType?: number
  boardType?: number
}

export const getOfferPerks = (
  cancellationPenalties: CancellationPenalty[] | undefined,
  services: string[] | undefined
) => {
  if (!cancellationPenalties || !services) return null
  const refundType = getRefundType(cancellationPenalties)
  const service = getOfferService(services)

  return `${refundType}|${service}`
}

export const parseOfferPerks = (offerPerks?: string): OfferPerks => {
  if (!offerPerks) {
    return {}
  }

  const [firstPartStr, secondPartStr] = offerPerks.split('|')

  const penaltyType = Number(firstPartStr) ?? undefined
  const boardType = Number(secondPartStr) ?? undefined
  return {
    penaltyType,
    boardType
  }
}
