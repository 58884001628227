import {useEffect} from 'react'

import {isReactNativeWebView} from '../../../native'

const InjectFullStoryBodyClass = () => {
  useEffect(() => {
    if (isReactNativeWebView()) {
      document.body.classList.add('fs-unmask')

      return () => {
        document.body.classList.remove('fs-unmask')
      }
    }
  }, [])

  return null
}

export {InjectFullStoryBodyClass}
