import React, {useMemo} from 'react'
import {useIntl} from 'react-intl'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Flag} from '@daedalus/atlas/Flag'
import {Text} from '@daedalus/atlas/Text'
import {getCountryCodeFromLanguage} from '@daedalus/core/src/localization/services/country'
import {splitPopularAndOtherLanguages} from '@daedalus/core/src/localization/services/locale'
import {LanguageType} from '@daedalus/core/src/localization/types/LanguageType'

import {SearchInput} from '../../SearchInput'
import {SelectorList} from '../SelectorList/SelectorList'

interface Props {
  isMobile?: boolean
  value: string
  onChange: (code: string) => void
  languages: LanguageType[]
  onSearchInputFocus?: () => void
}

const LanguageName = styled(Text)<{isMobile: boolean}>(
  ({isMobile}) => css`
    flex-grow: 1;
    white-space: ${isMobile ? ' nowrap' : 'wrap'};
  `
)

const Wrapper = styled.div<{isMobile: boolean}>(
  ({theme, isMobile}) => css`
    display: flex;
    align-items: ${isMobile ? 'center' : 'flex-start'};
    gap: ${theme.layout.spacing.s300}px;
    ${!isMobile && `padding: ${theme.layout.spacing.s300}px;`}
  `
)

const splitPopularAndOther = (items: LanguageType[]) => {
  const {languagesList, popularLanguagesList} =
    splitPopularAndOtherLanguages(items)

  return {
    itemsList: languagesList,
    popularItemsList: popularLanguagesList
  }
}

export const LanguageSelector: React.FC<Props> = ({
  isMobile = false,
  languages,
  onChange,
  value,
  onSearchInputFocus
}) => {
  const {formatMessage} = useIntl()
  const sortedLanguages = useMemo(() => {
    const result = [...languages]
    result.sort((a, b) => a.name.localeCompare(b.name))
    return result
  }, [languages])

  const renderList = (items: LanguageType[], isSearching = false) => (
    <SelectorList
      isMobile={isMobile}
      items={items}
      value={value}
      onChange={onChange}
      getValue={item => item.code}
      popularItemsTitle={formatMessage({
        id: 'popularLanguages',
        defaultMessage: 'Popular languages'
      })}
      otherItemsTitle={formatMessage({
        id: 'allLanguages',
        defaultMessage: 'All languages'
      })}
      splitPopularAndOther={splitPopularAndOther}
      renderItem={(item, isMobile) => (
        <Wrapper isMobile={isMobile}>
          <Flag countryCode={getCountryCodeFromLanguage(item.code)} />
          <LanguageName
            variant={isMobile ? 'bodyM' : 'bodyS'}
            isMobile={isMobile}
          >
            {item.displayName}
          </LanguageName>
        </Wrapper>
      )}
      isSearching={isSearching}
    />
  )
  if (isMobile) {
    return (
      <SearchInput
        items={sortedLanguages}
        itemMatcher={SearchInput.matchers.default([
          'displayName',
          'code',
          'name'
        ])}
        onFocus={onSearchInputFocus}
      >
        {renderList}
      </SearchInput>
    )
  }

  return renderList(languages)
}
