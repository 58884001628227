import {CurrencyType} from 'types/Currency'

export const defaultCurrencyCode = 'USD'

export const POPULAR_CURRENCIES = ['EUR', 'USD', 'GBP']

/* eslint-disable */
// prettier-ignore
export const ALL_CURRENCIES: CurrencyType[] = [
  { code: 'ADF', displayName: 'Andorran Franc', symbol: 'ADF' },
  { code: 'ADP', displayName: 'Andorran Peseta', symbol: 'ADP' },
  { code: 'AED', displayName: 'United Arab Emirates Dirhams', symbol: 'AED' },
  { code: 'AFA', displayName: 'Afghanistan Afghani', symbol: 'AFA' },
  { code: 'AFN', displayName: 'Afghanistan Afghani', symbol: 'AFN' },
  { code: 'ALL', displayName: 'Albanian Lek', symbol: 'ALL' },
  { code: 'AMD', displayName: 'Armenian Dram', symbol: 'AMD' },
  { code: 'ANG', displayName: 'NL Antillian Guilder', symbol: 'ANG' },
  { code: 'AOA', displayName: 'Angolan Kwanza', symbol: 'AOA' },
  { code: 'AON', displayName: 'Angolan New Kwanza', symbol: 'AON' },
  { code: 'ARS', displayName: 'Argentine Peso', symbol: 'ARS' },
  { code: 'ATS', displayName: 'Austrian Schilling', symbol: 'ATS' },
  { code: 'AUD', displayName: 'Australian Dollars', symbol: 'AUD' },
  { code: 'AWG', displayName: 'Aruban Florin', symbol: 'AWG' },
  { code: 'AZM', displayName: 'Azerbaijan Manat', symbol: 'AZM' },
  { code: 'AZN', displayName: 'Azerbaijan New Manat', symbol: 'AZN' },
  { code: 'BAM', displayName: 'Bosnian Mark', symbol: 'BAM' },
  { code: 'BBD', displayName: 'Barbados Dollar', symbol: 'BBD' },
  { code: 'BDT', displayName: 'Bangladeshi Taka', symbol: 'BDT' },
  { code: 'BEF', displayName: 'Belgian Franc', symbol: 'BEF' },
  { code: 'BGN', displayName: 'Bulgarian Lev', symbol: 'BGN' },
  { code: 'BHD', displayName: 'Bahrain Dinar', symbol: 'BHD' },
  { code: 'BIF', displayName: 'Burundi Franc', symbol: 'BIF' },
  { code: 'BMD', displayName: 'Bermudian Dollar', symbol: 'BMD' },
  { code: 'BND', displayName: 'Brunei Dollars', symbol: 'B$' },
  { code: 'BOB', displayName: 'Bolivian Boliviano', symbol: '$b' },
  { code: 'BRL', displayName: 'Brazil reais', symbol: 'R$' },
  { code: 'BSD', displayName: 'Bahamian Dollar', symbol: 'BSD' },
  { code: 'BTN', displayName: 'Bhutan Ngultrum', symbol: 'BTN' },
  { code: 'BWP', displayName: 'Botswana Pula', symbol: 'BWP' },
  { code: 'BYN', displayName: 'Belarusian Ruble', symbol: 'BYN' },
  { code: 'BZD', displayName: 'Belize Dollar', symbol: 'BZ$' },
  { code: 'CAD', displayName: 'Canadian Dollars', symbol: 'C$' },
  { code: 'CDF', displayName: 'Congolese Franc', symbol: 'CDF' },
  { code: 'CHF', displayName: 'Swiss Francs', symbol: 'CHF' },
  { code: 'CLP', displayName: 'Chile Pesos', symbol: 'CLP' },
  { code: 'CNH', displayName: 'Chinese Yuan (Offshore)', symbol: 'CNH' },
  { code: 'CNY', displayName: 'Chinese yuan', symbol: 'CN¥' },
  { code: 'COP', displayName: 'Colombian Peso', symbol: 'COP' },
  { code: 'CRC', displayName: 'Costa Rican Colon', symbol: 'CRC' },
  { code: 'CSD', displayName: 'Serbian Dinar', symbol: 'CSD' },
  { code: 'CUC', displayName: 'Cuban Convertible Peso', symbol: 'CUC' },
  { code: 'CUP', displayName: 'Cuba Pesos', symbol: 'CUP' },
  { code: 'CVE', displayName: 'Cape Verde Escudo', symbol: 'CVE' },
  { code: 'CYP', displayName: 'Cyprus Pound', symbol: 'CYP' },
  { code: 'CZK', displayName: 'Czech koruna', symbol: 'CZK' },
  { code: 'DJF', displayName: 'Djibouti Franc', symbol: 'DJF' },
  { code: 'DKK', displayName: 'Danish Kroner', symbol: 'kr.' },
  { code: 'DOP', displayName: 'Dominican R. Peso', symbol: 'RD$' },
  { code: 'DZD', displayName: 'Algerian Dinar', symbol: 'DZD' },
  { code: 'ECS', displayName: 'Ecuador Sucre', symbol: 'ECS' },
  { code: 'EEK', displayName: 'Estonian Kroon', symbol: 'EEK' },
  { code: 'EGP', displayName: 'Egyptian Pound', symbol: 'EGP' },
  { code: 'ERN', displayName: 'Egyptian Pound', symbol: 'ERN' },
  { code: 'ETB', displayName: 'Ethiopian Birr', symbol: 'ETB' },
  { code: 'EUR', displayName: 'Euros', symbol: '€' },
  { code: 'FJD', displayName: 'Fijian Dollar', symbol: 'FJD' },
  { code: 'FKP', displayName: 'Falkland Islands Pound', symbol: 'FKP' },
  { code: 'GBP', displayName: 'British Pounds', symbol: '£' },
  { code: 'GEL', displayName: 'Georgian Lari', symbol: 'GEL' },
  { code: 'GGP', displayName: 'Guernsey Pound', symbol: 'GGP' },
  { code: 'GHC', displayName: 'Ghanaian Cedi', symbol: 'GHC' },
  { code: 'GHS', displayName: 'Ghanaian New Cedi', symbol: 'GHS' },
  { code: 'GIP', displayName: 'Gibraltar Pound', symbol: 'GIP' },
  { code: 'GMD', displayName: 'Gambian Dalasi', symbol: 'GMD' },
  { code: 'GNF', displayName: 'Guinea Franc', symbol: 'GNF' },
  { code: 'GTQ', displayName: 'Guatemalan Quetzal', symbol: 'Q' },
  { code: 'GYD', displayName: 'Guyanese Dollar', symbol: 'GYD' },
  { code: 'HKD', displayName: 'Hong Kong Dollars', symbol: 'HK$' },
  { code: 'HNL', displayName: 'Honduran Lempira', symbol: 'HNL' },
  { code: 'HRK', displayName: 'Croatian Kuna', symbol: 'kn' },
  { code: 'HTG', displayName: 'Haitian Gourde', symbol: 'HTG' },
  { code: 'HUF', displayName: 'Hungarian Forint', symbol: 'Ft' },
  { code: 'IDR', displayName: 'Indonesian Rupiah', symbol: 'Rp' },
  { code: 'IEP', displayName: 'Irish Punt', symbol: 'IEP' },
  { code: 'ILS', displayName: 'Israeli New Shekel', symbol: '₪' },
  { code: 'IMP', displayName: 'Manx pound', symbol: 'IMP' },
  { code: 'INR', displayName: 'Indian rupees', symbol: '₹' },
  { code: 'IQD', displayName: 'Iraqi Dinar', symbol: 'IQD' },
  { code: 'IRR', displayName: 'Iranian Rial', symbol: 'IRR' },
  { code: 'ISK', displayName: 'Iceland Krona', symbol: 'kr.' },
  { code: 'JEP', displayName: 'Jersey Pound', symbol: 'JEP' },
  { code: 'JMD', displayName: 'Jamaican Dollar', symbol: 'J$' },
  { code: 'JOD', displayName: 'Jordan Dinar', symbol: 'JOD' },
  { code: 'JPY', displayName: 'Japanese Yen', symbol: '¥' },
  { code: 'KES', displayName: 'Kenyan Shilling', symbol: 'KES' },
  { code: 'KGS', displayName: 'Kyrgyzstanian Som', symbol: 'KGS' },
  { code: 'KHR', displayName: 'Cambodian Riel', symbol: 'KHR' },
  { code: 'KMF', displayName: 'Comoros Franc', symbol: 'KMF' },
  { code: 'KPW', displayName: 'North Korean Won', symbol: 'KPW' },
  { code: 'KRW', displayName: 'South Korean Won', symbol: '₩' },
  { code: 'KWD', displayName: 'Kuwait Dinars', symbol: 'KWD' },
  { code: 'KYD', displayName: 'Cayman Islands Dollar', symbol: 'KYD' },
  { code: 'KZT', displayName: 'Kazakhstan Tenge', symbol: 'KZT' },
  { code: 'LAK', displayName: 'Lao Kip', symbol: 'LAK' },
  { code: 'LBP', displayName: 'Lebanese Pound', symbol: 'LBP' },
  { code: 'LKR', displayName: 'Sri Lanka Rupee', symbol: 'LKR' },
  { code: 'LRD', displayName: 'Liberian Dollar', symbol: 'LRD' },
  { code: 'LSL', displayName: 'Lesotho Loti', symbol: 'LSL' },
  { code: 'LTL', displayName: 'Lithuanian Litas', symbol: 'LTL' },
  { code: 'LUF', displayName: 'Luxembourg Franc', symbol: 'LUF' },
  { code: 'LVL', displayName: 'Latvian Lats', symbol: 'LVL' },
  { code: 'LYD', displayName: 'Libyan Dinar', symbol: 'LYD' },
  { code: 'MAD', displayName: 'Moroccan Dirham', symbol: 'MAD' },
  { code: 'MDL', displayName: 'Moldovan Leu', symbol: 'MDL' },
  { code: 'MGA', displayName: 'Malagasy Ariary', symbol: 'MGA' },
  { code: 'MGF', displayName: 'Malagasy Ariary', symbol: 'MGF' },
  { code: 'MKD', displayName: 'Macedonian Denar', symbol: 'MKD' },
  { code: 'MMK', displayName: 'Myanmar Kyat', symbol: 'MMK' },
  { code: 'MNT', displayName: 'Mongolian Tugrik', symbol: 'MNT' },
  { code: 'MOP', displayName: 'Macau Pataca', symbol: 'MOP' },
  { code: 'MRO', displayName: 'Mauritanian Ouguiya (pre-2018)', symbol: 'MRO' },
  { code: 'MRU', displayName: 'Mauritanian Ouguiya', symbol: 'MRU' },
  { code: 'MTL', displayName: 'Maltese Lira', symbol: 'MTL' },
  { code: 'MUR', displayName: 'Mauritius Rupee', symbol: 'MUR' },
  { code: 'MVR', displayName: 'Maldive Rufiyaa', symbol: 'MVR' },
  { code: 'MWK', displayName: 'Malawi Kwacha', symbol: 'MWK' },
  { code: 'MXN', displayName: 'Mexican pesos', symbol: 'MXN' },
  { code: 'MYR', displayName: 'Malaysian Ringgit', symbol: 'RM' },
  { code: 'MZM', displayName: 'Mozambique Metical', symbol: 'MZM' },
  { code: 'MZN', displayName: 'Mozambique New Metical', symbol: 'MZN' },
  { code: 'NAD', displayName: 'Namibia Dollar', symbol: 'NAD' },
  { code: 'NGN', displayName: 'Nigerian Naira', symbol: 'NGN' },
  { code: 'NIO', displayName: 'Nicaraguan Cordoba Oro', symbol: 'C$' },
  { code: 'NLG', displayName: 'Netherlands Guilders', symbol: 'NLG' },
  { code: 'NOK', displayName: 'Norwegian Kroner', symbol: 'kr' },
  { code: 'NPR', displayName: 'Nepalese Rupee', symbol: 'NPR' },
  { code: 'NZD', displayName: 'New Zealand Dollars', symbol: 'NZ$' },
  { code: 'OMR', displayName: 'Omani Rial', symbol: 'OMR' },
  { code: 'PAB', displayName: 'Panamanian Balboa', symbol: 'PAB' },
  { code: 'PEN', displayName: 'Peruvian Nuevo Sol', symbol: 'PEN' },
  { code: 'PGK', displayName: 'Papua New Guinea Kina', symbol: 'PGK' },
  { code: 'PHP', displayName: 'Philippine Pesos', symbol: 'PHP' },
  { code: 'PKR', displayName: 'Pakistan Rupee', symbol: 'Rs.' },
  { code: 'PLN', displayName: 'Polish Zloty', symbol: 'PLN' },
  { code: 'PTE', displayName: 'Portuguese Escudo', symbol: 'PTE' },
  { code: 'PYG', displayName: 'Paraguay Guarani', symbol: 'PYG' },
  { code: 'QAR', displayName: 'Qatari Rial', symbol: 'QAR' },
  { code: 'ROL', displayName: 'Romanian Lei', symbol: 'ROL' },
  { code: 'RON', displayName: 'Romanian New Lei', symbol: 'RON' },
  { code: 'RSD', displayName: 'Serbian Dinar', symbol: 'RSD' },
  { code: 'RUB', displayName: 'Russian Rouble', symbol: '₽' },
  { code: 'RWF', displayName: 'Rwandan Franc', symbol: 'RWF' },
  { code: 'SAR', displayName: 'Saudi riyal', symbol: 'SAR' },
  { code: 'SBD', displayName: 'Solomon Islands Dollar', symbol: 'SBD' },
  { code: 'SCR', displayName: 'Seychelles Rupee', symbol: 'SCR' },
  { code: 'SDD', displayName: 'Sudanese Dinar', symbol: 'SDD' },
  { code: 'SDG', displayName: 'Sudanese Pound', symbol: 'SDG' },
  { code: 'SDP', displayName: 'Sudanese Pound', symbol: 'SDP' },
  { code: 'SEK', displayName: 'Swedish Kronas', symbol: 'kr' },
  { code: 'SGD', displayName: 'Singapore Dollars', symbol: 'S$' },
  { code: 'SHP', displayName: 'St. Helena Pound', symbol: 'SHP' },
  { code: 'SIT', displayName: 'Slovenian Tolar', symbol: 'SIT' },
  { code: 'SKK', displayName: 'Slovak Koruna', symbol: 'SKK' },
  { code: 'SLL', displayName: 'Sierra Leone Leone', symbol: 'SLL' },
  { code: 'SOS', displayName: 'Somali Shilling', symbol: 'SOS' },
  { code: 'SRD', displayName: 'Suriname Dollar', symbol: 'SRD' },
  { code: 'SSP', displayName: 'South Sudanese Pound', symbol: 'SSP' },
  { code: 'SRG', displayName: 'Suriname Guilder', symbol: 'SRG' },
  { code: 'STN', displayName: 'Sao Tome/Principe Dobra', symbol: 'STN' },
  { code: 'SVC', displayName: 'El Salvador Colon', symbol: 'SVC' },
  { code: 'SYP', displayName: 'Syrian Pound', symbol: 'SYP' },
  { code: 'SZL', displayName: 'Swaziland Lilangeni', symbol: 'SZL' },
  { code: 'THB', displayName: 'Thai baht', symbol: '฿' },
  { code: 'TJS', displayName: 'Tajikistani Somoni', symbol: 'TJS' },
  { code: 'TMM', displayName: 'Turkmenistan Manat', symbol: 'TMM' },
  { code: 'TMT', displayName: 'Turkmenistan New Manat', symbol: 'TMT' },
  { code: 'TND', displayName: 'Tunisia Dinars', symbol: 'TND' },
  { code: 'TOP', displayName: "Tonga Pa'anga", symbol: 'TOP' },
  { code: 'TRY', displayName: 'Turkish Lira', symbol: 'TL' },
  { code: 'TTD', displayName: 'Trinidad/Tobago Dollar', symbol: 'TT$' },
  { code: 'TWD', displayName: 'Taiwan dollars', symbol: 'NT$' },
  { code: 'TZS', displayName: 'Tanzanian Shilling', symbol: 'TZS' },
  { code: 'UAH', displayName: 'Ukraine Hryvnia', symbol: 'UAH' },
  { code: 'UGX', displayName: 'Uganda Shilling', symbol: 'UGX' },
  { code: 'USD', displayName: 'U.S. Dollars', symbol: '$' },
  { code: 'UYP', displayName: 'Uruguayan Peso', symbol: 'UYP' },
  { code: 'UYU', displayName: 'Uruguayan Peso', symbol: 'UYU' },
  { code: 'UZS', displayName: 'Uzbekistan Som', symbol: 'UZS' },
  { code: 'VEB', displayName: 'Venezuelan Bolivar', symbol: 'Bs.' },
  { code: 'VEF', displayName: 'Venezuelan Bolivar Fuerte', symbol: 'VEF' },
  { code: 'VND', displayName: 'Vietnamese Dong', symbol: 'vnd' },
  { code: 'VUV', displayName: 'Vanuatu Vatu', symbol: 'VUV' },
  { code: 'WST', displayName: 'Samoan Tala', symbol: 'WST' },
  { code: 'XAF', displayName: 'CFA Franc BEAC', symbol: 'XAF' },
  { code: 'XCD', displayName: 'East Caribbean Dollar', symbol: 'XCD' },
  { code: 'XOF', displayName: 'CFA Franc BCEAO', symbol: 'XOF' },
  { code: 'XPF', displayName: 'CFP Franc', symbol: 'XPF' },
  { code: 'YER', displayName: 'Yemeni Rial', symbol: 'YER' },
  { code: 'YUN', displayName: 'Yugoslav Dinar', symbol: 'YUN' },
  { code: 'ZAR', displayName: 'South African Rand', symbol: 'R' },
  { code: 'ZMK', displayName: 'Zambian Kwacha (pre-2013)', symbol: 'ZMK' },
  { code: 'ZMW', displayName: 'Zambian Kwacha', symbol: 'ZMW' },
  { code: 'ZWD', displayName: 'Zimbabwe Dollar', symbol: 'ZWD' },
  { code: 'HOTELS', displayName: "Hotels' Currency", symbol: '$€£' }
];
/* eslint-enable */

/**
 * Converts space-separated currencies codes string to list of currencies {@link CurrencyType[]}
 *
 * @param enabledCurrencies - space-separated currencies codes string, e.g. 'NLG USD EUR'
 *
 * @returns {@link CurrencyType[]} list of currencies with codes from argument
 */
export const getEnabledCurrencies = (
  enabledCurrencies: string
): CurrencyType[] => {
  const formatedCurrencies = new Set(
    enabledCurrencies.split(' ').map((item: string) => item.toUpperCase())
  )

  return ALL_CURRENCIES.filter(currencyObject =>
    formatedCurrencies.has(currencyObject.code)
  )
}

/**
 * Cuts popular currencies from available currencies list to separate array and returns both arrays as values in object
 *
 * @param availableCurrencies - list of available currencies {@link CurrencyType[]}
 *
 * @returns object with popularCurrenciesList and currenciesList
 */
export const splitPopularAndOtherCurrencies = (
  availableCurrencies: CurrencyType[]
) => {
  const currenciesList: CurrencyType[] = availableCurrencies.slice()

  const popularCurrenciesList: CurrencyType[] = POPULAR_CURRENCIES.map(
    currencyCode => {
      const index = currenciesList.findIndex(
        currencyObject => currencyObject.code === currencyCode
      )

      return currenciesList.splice(index, 1)[0]
    }
  )

  return {popularCurrenciesList, currenciesList}
}
