import {createSelector} from '@reduxjs/toolkit'
import {getNightlyDisplayTotalPrice} from 'business/price'
import {
  getAnchorPriceNightlyTotal,
  getRaaAnchorPriceBreakdown
} from 'modules/raaOffers/selectors'
import {
  getCheapestOffer,
  getRooms,
  getSplitBookingForAnalytics
} from 'modules/rooms/selectors'

import {selectIsPrivateDeal} from '@daedalus/core/src/auth/modules/selectors'

import {getRoomOfferContext} from './getRoomOfferContext'

export const getSearchRoomResultsContext = createSelector(
  [
    getRooms,
    getCheapestOffer,
    selectIsPrivateDeal,
    getRaaAnchorPriceBreakdown,
    getSplitBookingForAnalytics
  ],
  (
    rooms,
    cheapestOffer,
    isPrivateDeal,
    raaAnchorPriceBreakdown,
    splitBooking
  ) => {
    const roomsMetaData = rooms.map((room, roomIndex) => {
      return {
        roomId: room.id,
        roomPosition: roomIndex + 1,
        cheapestPrice: getNightlyDisplayTotalPrice(cheapestOffer.prices),
        hasClickedOffer: room?.hasClickedOffer as boolean,
        offers: room.offers.map((offer, offerIndex) => ({
          roomName: room.name,
          offerPosition: offerIndex + 1,
          roomMasterId: room.masterId as string,
          roomPosition: roomIndex,
          roomId: room.id,
          numberOfImages: room.images.length,
          roomAmenities: room.amenities,
          roomDescription: room.description,
          squashedIds: room.squashedIds,
          displayPricePerRoomPerNight:
            cheapestOffer && getNightlyDisplayTotalPrice(cheapestOffer.prices),
          isCheapest: cheapestOffer.id === offer.id,
          isPrivateDeal: isPrivateDeal(cheapestOffer),
          anchorPrice: getAnchorPriceNightlyTotal(raaAnchorPriceBreakdown),

          ...getRoomOfferContext(offer)
        }))
      }
    })
    return {
      roomsMetaData,
      splitBooking
    }
  }
)
