const KEYSTRING_ESC = 'Escape'
const KEYSTRING_SHORT_ESC = 'Esc'
const KEYCODE_ESC = 27

interface Props {
  onClose: () => void
}

export const closeOnEscape = ({onClose}: Props) => {
  const handleKeyDown = (e: KeyboardEvent) => {
    if (
      e.key === KEYSTRING_ESC ||
      e.key === KEYSTRING_SHORT_ESC ||
      e.keyCode === KEYCODE_ESC
    ) {
      onClose?.()
    }
  }

  window.addEventListener('keydown', handleKeyDown, true)

  return () => {
    window.removeEventListener('keydown', handleKeyDown, true)
  }
}
