import {compose} from 'ramda'
import {OfferRequestAugmentedType, RoomsRequestType} from 'types/Rooms'
import countNumberOfNights from 'utils/countNumberOfNights'

import {roomsToConfigurationObject} from '@daedalus/core/src/room/business/roomConfiguration'
import {RoomConfigurationType} from '@daedalus/core/src/room/types/RoomConfiguration'
import {UrlCheckoutParamsType} from '@daedalus/core/src/utils/url/types/UrlParams'

import {getRoomsRequestParams} from './getRoomsRequestParams'

const decorateWithRoomConfigurationObject = (reqParams: RoomsRequestType) => ({
  ...reqParams,
  roomsConfig: roomsToConfigurationObject(reqParams.rooms)
})

const decorateWithNumberOfNights = (
  reqParams: RoomsRequestType & {roomsConfig: RoomConfigurationType}
) => ({
  ...reqParams,
  numberOfNights: countNumberOfNights(
    new Date(reqParams.checkIn),
    new Date(reqParams.checkOut)
  )
})

const augmentedOfferRequestParams = compose<
  [UrlCheckoutParamsType],
  RoomsRequestType,
  RoomsRequestType & {roomsConfig: RoomConfigurationType},
  OfferRequestAugmentedType
>(
  decorateWithNumberOfNights,
  decorateWithRoomConfigurationObject,
  getRoomsRequestParams
)

export default augmentedOfferRequestParams
