// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgSauna = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="M11.504 11.685a.764.764 0 0 0-.704-.482H8.86L7.471 8.048a1.759 1.759 0 0 0-.393-.572 2.756 2.756 0 0 0 1.473-4.667 2.767 2.767 0 0 0-4.721 1.954 2.78 2.78 0 0 0 1.237 2.3 1.86 1.86 0 0 0-1.177 1.739v2.451a.753.753 0 0 0 .755.754.765.765 0 0 0 .754-.754V8.802a.372.372 0 0 1 .292-.362.382.382 0 0 1 .402.211l1.579 3.607a.744.744 0 0 0 .694.452h1.92l.573 1.314H2.754a.765.765 0 0 0-.754.754.754.754 0 0 0 .754.753h8.659l.843 1.982a.754.754 0 0 0 .694.472c.093.02.189.02.282 0a.753.753 0 0 0 .422-1.005l-2.15-5.295ZM6.597 3.507a1.258 1.258 0 0 1 1.257 1.256 1.255 1.255 0 0 1-2.146.888 1.255 1.255 0 0 1 .889-2.144ZM12.942 6.386a.241.241 0 0 1-.242-.24V5.25a.754.754 0 0 0-.754-.753.765.765 0 0 0-.754.753v.894a1.747 1.747 0 0 0 1.75 1.749.241.241 0 0 1 .24.24V9.14a.753.753 0 0 0 1.51 0V8.135a1.748 1.748 0 0 0-1.75-1.748ZM16.25 6.386a.242.242 0 0 1-.241-.24V5.25a.763.763 0 0 0-.754-.753.755.755 0 0 0-.755.753v.894a1.747 1.747 0 0 0 1.75 1.749.241.241 0 0 1 .241.24V9.14a.744.744 0 0 0 .755.754.755.755 0 0 0 .754-.754V8.135a1.748 1.748 0 0 0-1.75-1.748Z" />
  </svg>
)

export default SvgSauna
