import {
  COGNITO_LOGIN_CALLBACK_ROUTE,
  COGNITO_LOGOUT_CALLBACK_ROUTE
} from '../constants'
import {amplifyEtripProduction} from './configurations/etrip-production'
import {amplifyEtripStaging} from './configurations/etrip-staging'
import {amplifyVioProduction} from './configurations/vio-production'
import {amplifyVioStaging} from './configurations/vio-staging'

export interface AmplifyConfig {
  aws_project_region: string
  aws_cognito_identity_pool_id: string
  aws_cognito_region: string
  aws_user_pools_id: string
  aws_user_pools_web_client_id: string
  oauth: Oauth
  federationTarget: string
  aws_cognito_username_attributes: string[]
  aws_cognito_social_providers: string[]
  aws_cognito_signup_attributes?: string[]
  aws_cognito_mfa_configuration: string
  aws_cognito_mfa_types?: string[]
  aws_cognito_password_protection_settings: AwsCognitoPasswordProtectionSettings
  aws_cognito_verification_mechanisms?: string[]
  aws_user_files_s3_bucket: string
  aws_user_files_s3_bucket_region: string
  cookieStorage?: CookieStorage
}

interface AwsCognitoPasswordProtectionSettings {
  passwordPolicyMinLength: number
  passwordPolicyCharacters: string[]
}

interface Oauth {
  domain: string
  scope: string[]
  redirectSignIn: string
  redirectSignOut: string
  responseType: string
}

export interface CookieStorage {
  domain: string
  path: string
  expires: number
  sameSite: string
  secure: boolean
}

export interface Params {
  aws_user_pools_id?: string
  aws_cognito_identity_pool_id?: string
  aws_user_pools_web_client_id?: string
  oauth_domain?: string
  redirectSignIn?: string
  redirectSignOut?: string
  aws_user_files_s3_bucket?: string
}

export const getCognitoCookieDomain = (hostName: string) => {
  const parts = hostName.split('.')

  // https://re-search-sc-47-c77d35ff.shared-prod.fih.io returned as shared-prod.fih.io
  if (hostName.includes('.fih.io')) {
    return parts.slice(-3).join('.')
  }

  // https://www.vio.com returned as vio.com
  return parts.slice(-2).join('.')
}

const generateConfig = ({
  aws_user_pools_id = '',
  aws_cognito_identity_pool_id = '',
  aws_user_pools_web_client_id = '',
  oauth_domain = '',
  redirectSignIn = '',
  redirectSignOut = '',
  aws_user_files_s3_bucket = '',
  hostname
}: Params & {hostname: string}): AmplifyConfig => {
  const region = 'eu-west-1'

  return {
    aws_user_pools_id,
    aws_user_pools_web_client_id,
    aws_cognito_identity_pool_id,
    aws_user_files_s3_bucket,
    aws_project_region: region,
    aws_cognito_region: region,
    aws_user_files_s3_bucket_region: region,
    oauth: {
      domain: oauth_domain,
      redirectSignIn,
      redirectSignOut,
      scope: [
        'aws.cognito.signin.user.admin',
        'email',
        'openid',
        'phone',
        'profile'
      ],
      responseType: 'code'
    },
    federationTarget: 'COGNITO_USER_POOLS',
    aws_cognito_username_attributes: ['PHONE_NUMBER'],
    aws_cognito_social_providers: ['FACEBOOK', 'GOOGLE', 'APPLE'],
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [
        'REQUIRES_LOWERCASE',
        'REQUIRES_UPPERCASE',
        'REQUIRES_NUMBERS',
        'REQUIRES_SYMBOLS'
      ]
    },
    cookieStorage: {
      domain: getCognitoCookieDomain(hostname),
      path: '/',
      expires: 365,
      sameSite: 'strict',
      secure: isSecure(hostname)
    },
    aws_cognito_signup_attributes: [],
    aws_cognito_mfa_types: []
  }
}

const isSecure = (hostname: string) => !hostname.includes('localhost')

export const getConfigByHostName = (origin: string) => {
  if (origin.includes('tst.')) return amplifyVioStaging

  if (origin.includes('findhotel.net') || origin.includes('vio.com'))
    return amplifyVioProduction

  if (
    origin.includes('etrip.net') ||
    origin.includes('dealbase.com') ||
    origin.includes('goseek.com')
  )
    return amplifyEtripProduction

  if (origin.includes('fih.io') && origin.includes('etrip'))
    return amplifyEtripStaging

  return amplifyVioStaging
}

export const getAmplifyConfig = (origin: string): AmplifyConfig => {
  const configByHostName = getConfigByHostName(origin)
  const {hostname} = new URL(origin)
  return generateConfig({
    ...configByHostName,
    redirectSignIn: `${origin}${COGNITO_LOGIN_CALLBACK_ROUTE}`,
    redirectSignOut: `${origin}${COGNITO_LOGOUT_CALLBACK_ROUTE}`,
    hostname
  })
}
