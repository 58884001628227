export enum FilterId {
  SortByDisplayContainer = 'SortByDisplayContainer',
  PriceFilterPill = 'PriceFilterPill',
  PropertyTypeFilterPill = 'PropertyTypeFilterPill',
  GemsDealsPill = 'GemsDealsPill',
  StarRatingPill = 'StarRatingPill',
  StarRatingQuickPill = 'StarRatingQuickPill',
  ReviewScorePill = 'ReviewScorePill',
  FacilitiesFilterPill = 'FacilitiesFilterPill',
  FreeCancellationPill = 'FreeCancellationPill',
  PriceFilterPopover = 'PriceFilterPopover',
  FacilitiesFilterPopover = 'FacilitiesFilterPopover',
  ReviewScoreFilterPopover = 'ReviewScoreFilterPopover',
  ThemesFilterPopover = 'ThemesFilterPopover',
  PropertyTypeFilterPopover = 'PropertyTypeFilterPopover',
  StarRatingFilterPopover = 'StarRatingFilterPopover',
  MealPlansFilterPopover = 'MealPlansFilterPopover',
  BreakfastPill = 'BreakfastPill',
  ReviewScoreQuickPill = 'ReviewScoreQuickPill',
  PetFriendlyPill = 'PetFriendlyPill',
  SwimmingPoolPill = 'SwimmingPoolPill',
  ParkingPill = 'ParkingPill',
  BeachPill = 'BeachPill',
  FamilyPill = 'FamilyPill',
  FreeWifiPill = 'FreeWifiPill',
  RestaurantPill = 'RestaurantPill',
  AirConditionedPill = 'AirConditionedPill',
  AirportShuttlePill = 'AirportShuttlePill'
}
