import React, {ReactNode, useCallback} from 'react'
import {useIntl} from 'react-intl'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {append, dropLast, update} from 'ramda'

import {Button} from '@daedalus/atlas/Button'
import {Icon} from '@daedalus/atlas/Icon'
import {Stepper} from '@daedalus/atlas/Stepper'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'
import {DEFAULT_CHILD_AGE} from '@daedalus/core/src/room/business/roomConfiguration/config'

import {AgeField} from './AgeField'
import {guestPickerMessages} from './guestPickerMessages'

type PropsType = {
  /** Number of guests */
  adultGuests: number
  /** Number of children */
  childGuests: number[]
  /** Room's index. For room index 0 the remove button is not shown. */
  index: number
  /** Maximum number of guests allowed in the room */
  maxGuests: number
  /** Maximum number of adult guests allowed in the room */
  maxAdultGuests?: number
  /** Maximum number of child guests allowed in the room */
  maxChildGuests?: number
  /** onAdultsChange callback  */
  onAdultsChange: (index: number, value: number) => void
  /** onChildrenChange callback  */
  onChildrenChange: (index: number, value: number[]) => void
  /** onRemoveRoom callback  */
  onRemoveRoom?: (index: number) => void
  /** Whether the inputs should be smaller */
  isCompactLayout?: boolean
  /** Passing custom title for picker */
  customTitle?: ReactNode
}

const GuestPickerElement = styled.div(
  ({theme}) => css`
    color: ${theme.colors.content.neutral.c950};
    padding: 0 0 ${theme.layout.spacing.s500}px;

    &:not(:first-of-type) {
      margin-top: ${theme.layout.spacing.s600}px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid;
      border-color: ${theme.colors.border.neutral.c100};
    }

    &:last-child {
      padding: 0;
    }
  `
)

const Row = styled.div(
  ({theme}) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: ${theme.layout.spacing.s300}px 0;
  `
)

const HeadingRow = styled(Row)(
  ({theme}) => css`
    margin: 0;
    padding-top: ${theme.layout.spacing.s200} px;
  `
)

const RoomHeading = styled.h4(
  ({theme}) => css`
    ${theme.typography.text.titleM};
    margin: 0;
  `
)

const InputLabel = styled.span(
  ({theme}) => css`
    ${theme.typography.text.bodyS};
    display: flex;
  `
)

const IconWrapper = styled.span(
  ({theme}) => css`
    padding-right: ${theme.layout.spacing.s300}px;
  `
)

const AgeSelectorWrapper = styled.div(
  () =>
    css`
      margin-left: 28px;
    `
)

const AgeFieldWrapper = styled.div(
  ({theme}) =>
    css`
      margin: 0 0 ${theme.layout.spacing.s300}px;

      &:last-child {
        margin: 0;
      }
    `
)

const getAdultsMin = (index: number): number => (index > 0 ? 0 : 1)

export const GuestPickerLayout = ({
  adultGuests,
  childGuests,
  index,
  maxGuests,
  maxAdultGuests,
  onAdultsChange,
  onChildrenChange,
  onRemoveRoom,
  isCompactLayout = false,
  customTitle
}: PropsType) => {
  const {formatMessage} = useIntl()

  const handleRemoveRoom = useCallback(
    (index: number) => {
      onRemoveRoom && onRemoveRoom(index)
    },
    [onRemoveRoom]
  )
  const handleNumberOfAdultsChange = useCallback(
    (value: number) => {
      if (value) onAdultsChange(index, value)
      else handleRemoveRoom(index)
    },
    [handleRemoveRoom, index, onAdultsChange]
  )

  const handleNumberOfChildrenChange = useCallback(
    (value: number) => {
      const newChildGuests =
        value > childGuests.length
          ? append(DEFAULT_CHILD_AGE, childGuests)
          : dropLast(1, childGuests)

      onChildrenChange(index, newChildGuests)
    },
    [childGuests, index, onChildrenChange]
  )

  const handleChildAgeChange = useCallback(
    (childIndex: number, value: number) => {
      const newChildGuests = update(childIndex, value, childGuests)

      onChildrenChange(index, newChildGuests)
    },
    [childGuests, index, onChildrenChange]
  )

  const getChildAgeMessage = useCallback(
    (number: number): string =>
      formatMessage(guestPickerMessages.childAgeWithNumber, {
        number
      }),
    [formatMessage]
  )

  const calculateMaxGuests = useCallback(
    (isChildGuest: boolean): number =>
      isChildGuest ? maxGuests - adultGuests : maxGuests - childGuests.length,
    [adultGuests, childGuests, maxGuests]
  )

  const calculateMaxAdultGuests = useCallback(
    (): number =>
      Math.min(calculateMaxGuests(false), maxAdultGuests || Infinity),
    [calculateMaxGuests, maxAdultGuests]
  )

  const calculateMaxChildGuests = useCallback(
    (): number => calculateMaxGuests(true),
    [calculateMaxGuests]
  )

  return (
    <GuestPickerElement>
      <HeadingRow>
        <RoomHeading>
          {customTitle ? (
            customTitle
          ) : (
            <FormattedMessageWrapper
              id="room.reference"
              defaultMessage="Room {roomNumber} "
              values={{
                roomNumber: index + 1
              }}
            />
          )}
        </RoomHeading>
        {index > 0 && (
          <Button
            size="md"
            variant="quiet"
            dataId="removeRoom"
            onClick={() => {
              handleRemoveRoom(index)
            }}
          >
            <FormattedMessageWrapper
              id="removeRoom"
              defaultMessage="Remove room"
            />
          </Button>
        )}
      </HeadingRow>
      <Row>
        <InputLabel>
          <IconWrapper>
            <Icon name="User" />
          </IconWrapper>
          <FormattedMessageWrapper id="adults" defaultMessage="Adults" />
        </InputLabel>
        <Stepper
          name="GuestPickerAdultsNumber"
          id="GuestPickerAdultsNumber"
          dataId="GuestPickerAdultsNumber"
          value={adultGuests}
          min={getAdultsMin(index)}
          max={calculateMaxAdultGuests()}
          onChange={handleNumberOfAdultsChange}
          size={isCompactLayout ? 'sm' : 'lg'}
        />
      </Row>
      <Row>
        <InputLabel>
          <IconWrapper>
            <Icon name="Person" />
          </IconWrapper>
          <FormattedMessageWrapper id="children" defaultMessage="Children" />
        </InputLabel>
        <Stepper
          name="GuestPickerAdultsNumber"
          id="GuestPickerAdultsNumber"
          dataId="GuestPickerChildrenNumber"
          value={childGuests.length > 0 ? childGuests.length : 0}
          min={0}
          max={calculateMaxChildGuests()}
          onChange={handleNumberOfChildrenChange}
          size={isCompactLayout ? 'sm' : 'lg'}
        />
      </Row>
      {childGuests.length > 0 && (
        <AgeSelectorWrapper>
          {childGuests.map((childAge, index) => (
            <AgeFieldWrapper key={`${index}+${childAge}`}>
              <AgeField
                displayName={getChildAgeMessage(index + 1)}
                value={childAge}
                onChange={value => handleChildAgeChange(index, value)}
                size={isCompactLayout ? 'sm' : 'lg'}
              />
            </AgeFieldWrapper>
          ))}
        </AgeSelectorWrapper>
      )}
    </GuestPickerElement>
  )
}
