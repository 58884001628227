// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgLock = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.283 4.448C7.751 3.968 8.558 3.5 10 3.5c1.442 0 2.249.468 2.717.948.393.403.601.943.702 1.628.087.596.085 1.23.082 1.909V8H6.499v-.015c-.003-.679-.005-1.313.082-1.91.1-.684.309-1.224.702-1.627ZM5 8c-.003-.67-.006-1.428.098-2.142.126-.855.416-1.744 1.113-2.458C6.985 2.605 8.19 2 10 2s3.015.605 3.79 1.4c.697.714.987 1.603 1.113 2.458.104.714.101 1.472.098 2.142h.249c.966 0 1.75.784 1.75 1.75v5.5A2.75 2.75 0 0 1 14.25 18h-8.5A2.75 2.75 0 0 1 3 15.25v-5.5C3 8.784 3.783 8 4.75 8h.249ZM4.5 9.75a.25.25 0 0 1 .25-.25h10.5a.25.25 0 0 1 .25.25v5.5c0 .69-.56 1.25-1.25 1.25h-8.5c-.69 0-1.25-.56-1.25-1.25v-5.5Z"
    />
  </svg>
)

export default SvgLock
