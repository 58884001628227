// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPot = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.75 5.5H3V4.25A2.25 2.25 0 0 1 5.25 2h9.5A2.25 2.25 0 0 1 17 4.25V5.5h.25a.75.75 0 1 1 0 1.5h-.256a5.25 5.25 0 0 1-5.244 5h-3.5a5.25 5.25 0 0 1-5.244-5H2.75a.75.75 0 0 1 0-1.5Zm5.5 5A3.75 3.75 0 0 1 4.5 6.75v-2.5a.75.75 0 0 1 .75-.75h9.5a.75.75 0 0 1 .75.75v2.5a3.75 3.75 0 0 1-3.75 3.75h-3.5ZM2 17.25a.75.75 0 0 1 .75-.75h14.5a.75.75 0 1 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Zm4.5-3.5a.75.75 0 0 0-1.5 0v.5a.75.75 0 0 0 1.5 0v-.5ZM10 13a.75.75 0 0 1 .75.75v.5a.75.75 0 0 1-1.5 0v-.5A.75.75 0 0 1 10 13Zm5 .75a.75.75 0 1 0-1.5 0v.5a.75.75 0 0 0 1.5 0v-.5Z"
    />
  </svg>
)

export default SvgPot
