import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Icon} from '@daedalus/atlas/src/components/designSystem/Icon'
import {Text} from '@daedalus/atlas/src/components/designSystem/Text'
import {
  MenuItemComponent,
  useTrackMenuItemClick
} from '@daedalus/core/src/analytics/hooks/useTrackMenuItemClick'
import {Team} from '@daedalus/core/src/analytics/types/Events'
import {Brand} from '@daedalus/core/src/brand/types'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {IconWrapper, Label} from '../commonStyles'

interface Props {
  brand: Brand
}

const Wrapper = styled.a(
  ({theme}) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    text-decoration: none;
    color: ${theme.colors.content.neutral.c950};

    &:hover,
    &:visited,
    &:focus {
      text-decoration: none;
      color: ${theme.colors.content.neutral.c950};
    }
    & svg {
      /* @mirror */
    }
  `
)

export const GetHelpLink = ({brand}: Props) => {
  const getHelpLink = brand.helpUrl
  const {trackMenuItemClick} = useTrackMenuItemClick()

  const helpLinkOnClick = () => {
    trackMenuItemClick(MenuItemComponent.GetHelp, Team.Retention)
  }

  return (
    <Wrapper
      href={getHelpLink}
      target="_blank"
      rel="noopener noreferrer"
      onClick={helpLinkOnClick}
    >
      <Label>
        <IconWrapper>
          <Icon name="Help" />
        </IconWrapper>
        <Text variant="labelM">
          <FormattedMessageWrapper id="getHelp" defaultMessage="Get Help" />
        </Text>
      </Label>
      <Icon name="Share" />
    </Wrapper>
  )
}
