import React, {lazy, Suspense} from 'react'
import {RoutePropsTypes, VariationsStoreReader} from 'types/types'

import {BottomSheet} from '@daedalus/atlas/BottomSheet'
import {PopUp} from '@daedalus/atlas/PopUp'
import {Spinner} from '@daedalus/atlas/Spinner'

interface Props extends Omit<RoutePropsTypes, 'meta'> {
  isOpen: boolean
  onClose: () => void
  variationsStoreReader?: VariationsStoreReader
  isMobile: boolean
  updateLocation?: (params: Record<string, unknown>) => void
}

const LazyDebugPanel = lazy(async () => import('./DebugPanel'))

const DebugPanelOverlay = ({
  isOpen,
  onClose,
  protectCode,
  thirdPartyExperiments,
  variationsStoreReader,
  scenarios,
  modulesConfig,
  isMobile,
  updateLocation
}: Props) => {
  const Overlay = isMobile ? BottomSheet : PopUp
  return (
    <Overlay
      isOpen={isOpen}
      width={960}
      titleBarProps={{
        centerContent: 'Debug Panel'
      }}
      {...(isMobile ? {onClose} : {closeAction: onClose})}
    >
      <Suspense fallback={<Spinner />}>
        <LazyDebugPanel
          protectCode={protectCode}
          thirdPartyExperiments={thirdPartyExperiments}
          variationsStoreReader={variationsStoreReader}
          scenarios={scenarios}
          modulesConfig={modulesConfig}
          updateLocation={updateLocation}
        />
      </Suspense>
    </Overlay>
  )
}

// default export needed due to lazy loading this component
export default DebugPanelOverlay
