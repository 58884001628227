// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgChevronRight = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.236 5.278a.737.737 0 0 1 1.003-1.081l5.668 5.263a.737.737 0 0 1 0 1.08l-5.668 5.263a.737.737 0 1 1-1.003-1.08L11.32 10 6.236 5.278Z"
    />
  </svg>
)

export default SvgChevronRight
