import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {useInView} from '@daedalus/core/src/_web/utils/browser/hooks/useInView'
import {DatePickerVariant} from '@daedalus/core/src/datePicker/types'

import {HEADER_HEIGHT} from '../styles'

interface Props {
  datePickerVariant: 'horizontal' | 'vertical'
  months: string[]
  isCheckInMonth: boolean
  checkInMonthRef?: React.MutableRefObject<HTMLDivElement | null>
  date: Date
  onInView?: (date: Date) => void
}

const CaptionElement = styled.div<{
  datePickerVariant: DatePickerVariant
}>(({theme, datePickerVariant}) => {
  if (datePickerVariant === 'vertical') {
    return css`
      ${theme.typography.text.titleM};
      text-align: center;
      color: ${theme.colors.content.neutral.c950};
      padding: ${theme.layout.spacing.s500}px ${theme.layout.spacing.s300}px
        ${theme.layout.spacing.s200}px;
    `
  }

  return css`
    ${theme.typography.text.titleM};
    text-align: center;
    color: ${theme.colors.content.neutral.c950};
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${HEADER_HEIGHT}px;
  `
})

export const MonthName = ({
  datePickerVariant,
  months,
  isCheckInMonth,
  checkInMonthRef,
  date,
  onInView
}: Props) => {
  const {ref: monthRef, inView: isMonthInView} = useInView()

  if (isMonthInView) {
    onInView?.(date)
  }

  return (
    <CaptionElement
      datePickerVariant={datePickerVariant}
      {...(isCheckInMonth ? {ref: checkInMonthRef} : {ref: monthRef})}
    >
      {months && `${months[date.getMonth()]} ${date.getFullYear()}`}
    </CaptionElement>
  )
}
