import React, {createContext, ReactNode, useState} from 'react'

interface DeleteAccountContextType {
  isOpen: boolean
  openModal: () => void
  closeModal: () => void
}

export const DeleteAccountContext = createContext<DeleteAccountContextType>(
  {} as DeleteAccountContextType
)

interface DeleteAccountProviderPropTypes {
  children: ReactNode
}

export const DeleteAccountProvider = ({
  children
}: DeleteAccountProviderPropTypes) => {
  const [isOpen, setIsDeleteAccountModalOpen] = useState(false)

  const closeModal = () => {
    setIsDeleteAccountModalOpen(false)
  }

  const openModal = () => {
    setIsDeleteAccountModalOpen(true)
  }

  const value = {
    isOpen,
    openModal,
    closeModal
  }

  return (
    <DeleteAccountContext.Provider value={value}>
      {children}
    </DeleteAccountContext.Provider>
  )
}
