import React from 'react'
import {useDispatch} from 'react-redux'
import getRoomConfig from 'components/data/URLParams/mappers/getRoomConfig'
import useParams from 'components/data/URLParams/useParams'
import {useGetHotel} from 'hooks/useGetHotel'
import {openSearchBox} from 'modules/common/actions/searchBox'

import {dateStringToMiddayDate} from '@daedalus/core/src/utils/date'
import {SearchSummaryButton} from '@daedalus/shared/src/search/SearchSummaryButton'

const CompactSearchSummaryWithStore = () => {
  const dispatch = useDispatch()

  const handleSummaryClick = () => {
    dispatch(openSearchBox())
  }

  const urlParams = useParams()

  const roomsConfig = getRoomConfig(urlParams)
  const {numberOfAdults, numberOfChildren, numberOfRooms} = roomsConfig
  const numberOfGuests = numberOfAdults + numberOfChildren

  const {checkIn, checkOut} = urlParams
  const checkInDate = dateStringToMiddayDate(checkIn)
  const checkOutDate = dateStringToMiddayDate(checkOut)

  const {data: hotelResult} = useGetHotel()

  const hotelName = hotelResult?.hotelName

  return (
    <SearchSummaryButton
      checkIn={checkInDate}
      checkOut={checkOutDate}
      destination={hotelName}
      numberOfGuests={numberOfGuests}
      numberOfRooms={numberOfRooms}
      onClick={handleSummaryClick}
    />
  )
}

export default CompactSearchSummaryWithStore
