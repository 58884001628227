import differenceInHours from 'date-fns/differenceInHours'
import subHours from 'date-fns/subHours'

import {CancellationPenalties} from '@findhotel/sapi'

import isOfferRefundable from '../../utils/refundableOffer'
import {getPersistedConfiguration} from '../services/offerConfigurationApi/store'
import {CancellationPenalty} from '../types/offer'
import {
  freeCancellationPenalty,
  getCancellationDeadline
} from './cancellationPenalties'

/**
 * Checks if an offer is eligible for Charge Later.
 * @param providerCode - The provider code of the offer.
 * @param cancellationPenalties - An array of CancellationPenalty or CancellationPenalties objects.
 * @returns True if the penalties meet the criteria for a valid Charge Later offer, false otherwise.
 */
export const isEligibleForChargeLater = (
  providerCode: string,
  cancellationPenalties: CancellationPenalty[] | CancellationPenalties[]
): boolean => {
  const configuration = getPersistedConfiguration()
  const chargeLaterConfiguration = configuration.chargeLater

  if (!chargeLaterConfiguration?.enabled) return false

  const {offerAllowedProviders, minHoursToFreeCancellation} =
    chargeLaterConfiguration || {}

  if (!offerAllowedProviders?.includes(providerCode)) {
    return false
  }

  const isRefundable = isOfferRefundable(cancellationPenalties)
  const isFreeCancellable =
    isRefundable && freeCancellationPenalty(cancellationPenalties[0])
  if (!isFreeCancellable) return false

  const freeCancellationDeadline = getCancellationDeadline(
    cancellationPenalties
  )

  const hoursToFreeCancellationDeadline = Number(
    freeCancellationDeadline &&
      differenceInHours(new Date(freeCancellationDeadline), new Date())
  )

  if (
    minHoursToFreeCancellation &&
    hoursToFreeCancellationDeadline < minHoursToFreeCancellation
  )
    return false

  return true
}

/**
 * Calculates the charge date based on the cancellation penalties.
 *
 * @param cancellationPenalties - Array of CancellationPenalty or CancellationPenalties objects.
 * @returns The calculated charge date as a Date object.
 */
export const calculateChargeDate = (
  cancellationPenalties: CancellationPenalty[] | CancellationPenalties[]
) => {
  const configuration = getPersistedConfiguration()
  const chargeLaterConfiguration = configuration.chargeLater
  const {autoChargeLeadHours} = chargeLaterConfiguration || {}

  if (!autoChargeLeadHours) return undefined

  const cancellationDeadline = getCancellationDeadline(cancellationPenalties)
  const cancellationDeadlineDate =
    cancellationDeadline && new Date(cancellationDeadline)
  return cancellationDeadlineDate
    ? subHours(cancellationDeadlineDate, autoChargeLeadHours)
    : undefined
}
