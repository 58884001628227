import React from 'react'
import {useDispatch} from 'react-redux'
import useParams from 'components/data/URLParams/useParams'
import {trackEventV1} from 'modules/analytics/actions'
import {
  EventAction,
  EventCategory,
  EventName,
  EventSpace
} from 'modules/analytics/actions/trackEventV1'
import {
  changeDates,
  changeRoomsConfig,
  changeSearchParameters,
  clearRoomResults
} from 'modules/rooms/slice'

import {SearchForm} from './SearchForm'
import {UpdateDates, UpdateRooms, UpdateSearch} from './types'

/**
 * Note: this is the horizontal searchbox that is only used on desktop
 * On Mobile, use CompactSearchSummary and overlays
 */
export const SearchBox = () => {
  const dispatch = useDispatch()
  const urlParams = useParams()
  const {checkIn, checkOut, rooms} = urlParams
  const updateDates: UpdateDates = params => {
    if (params.checkIn === checkIn && params.checkOut === checkOut) return

    dispatch(
      trackEventV1({
        name: EventName.SEARCH_CLICK,
        category: EventCategory.SEARCH_BAR,
        action: EventAction.CLICK,
        space: EventSpace.ROOM_SELECTION_PAGE,
        urlParams,
        label: 'Dates change'
      })
    )

    dispatch(clearRoomResults())
    dispatch(changeDates(params))
  }

  const updateRooms: UpdateRooms = params => {
    if (params.rooms === rooms) return

    dispatch(
      trackEventV1({
        name: EventName.SEARCH_CLICK,
        category: EventCategory.SEARCH_BAR,
        action: EventAction.CLICK,
        space: EventSpace.ROOM_SELECTION_PAGE,
        urlParams,
        label: 'Rooms change'
      })
    )

    dispatch(clearRoomResults())
    dispatch(changeRoomsConfig(params.rooms))
  }

  const updateSearch: UpdateSearch = params => {
    if (
      params.checkIn === checkIn &&
      params.checkOut === checkOut &&
      params.rooms === rooms
    )
      return

    dispatch(clearRoomResults())
    dispatch(changeSearchParameters(params))
  }

  return (
    <SearchForm
      checkIn={checkIn}
      checkOut={checkOut}
      rooms={rooms}
      updateDates={updateDates}
      updateRooms={updateRooms}
      updateSearch={updateSearch}
    />
  )
}
