// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgBuildings = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 3.75C2 2.783 2.784 2 3.75 2h5.5c.966 0 1.75.783 1.75 1.75V5h5.25c.966 0 1.75.783 1.75 1.75v9.5A1.75 1.75 0 0 1 16.25 18H3.75A1.75 1.75 0 0 1 2 16.25V3.75Zm9 2.75v10h5.25a.25.25 0 0 0 .25-.25v-9.5a.25.25 0 0 0-.25-.25H11Zm-1.5 10V3.75a.25.25 0 0 0-.25-.25h-5.5a.25.25 0 0 0-.25.25v12.5c0 .138.112.25.25.25H9.5ZM5 5.775a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1-.75-.75Zm0 3a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1-.75-.75Zm7 0a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1-.75-.75Zm0 3a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1-.75-.75Z"
    />
  </svg>
)

export default SvgBuildings
