// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgStarOutline = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.673 9.203c.643-.668.286-1.816-.61-1.962l-3.607-.588a1.112 1.112 0 0 1-.817-.62l-1.651-3.4a1.087 1.087 0 0 0-1.976 0l-1.65 3.4a1.112 1.112 0 0 1-.817.62l-3.607.588c-.896.146-1.254 1.294-.61 1.962l2.586 2.69c.252.262.368.635.312 1.002l-.579 3.763c-.143.935.792 1.644 1.599 1.212l3.25-1.737c.316-.17.692-.17 1.009 0l3.25 1.738c.806.431 1.741-.278 1.598-1.213l-.579-3.763c-.056-.367.06-.74.312-1.003l2.587-2.69Zm-3.887 3.844a2.188 2.188 0 0 1 .58-1.848l2.586-2.69c.043-.044.062-.108.038-.186a.172.172 0 0 0-.044-.074.077.077 0 0 0-.045-.021l-3.607-.588a2.112 2.112 0 0 1-1.555-1.17l-1.65-3.4c-.02-.04-.04-.054-.047-.059A.08.08 0 0 0 10 3a.08.08 0 0 0-.042.011c-.008.005-.027.018-.046.06L8.26 6.47a2.112 2.112 0 0 1-1.555 1.17l-3.607.588a.078.078 0 0 0-.045.02.172.172 0 0 0-.044.075c-.024.078-.005.142.038.187l2.587 2.69c.473.491.682 1.18.58 1.847l-.58 3.763c-.007.05 0 .084.01.107.011.026.029.048.05.063a.09.09 0 0 0 .042.02c.003 0 .013.001.037-.011l3.25-1.738a2.066 2.066 0 0 1 1.952 0l3.25 1.738c.025.013.035.011.037.01a.091.091 0 0 0 .043-.019.156.156 0 0 0 .049-.064c.01-.023.017-.056.01-.106l-.58-3.763Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgStarOutline
