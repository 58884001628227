// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgMonitor = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.75 2A2.75 2.75 0 0 0 2 4.75v7.5A2.75 2.75 0 0 0 4.75 15h4.225l-2.618 1.611a.75.75 0 0 0 .786 1.278L10 16.13l2.857 1.758a.75.75 0 1 0 .786-1.278L11.025 15h4.225A2.75 2.75 0 0 0 18 12.25v-7.5A2.75 2.75 0 0 0 15.25 2H4.75ZM3.5 4.75c0-.69.56-1.25 1.25-1.25h10.5c.69 0 1.25.56 1.25 1.25v7.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-7.5Z"
    />
  </svg>
)

export default SvgMonitor
