// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCoffeeMachine = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 3.75C2 2.784 2.784 2 3.75 2h12.5c.966 0 1.75.784 1.75 1.75v13.5a.75.75 0 0 1-.75.75H2.75a.75.75 0 0 1 0-1.5H12V8.25A2.25 2.25 0 0 0 9.75 6h-7A.75.75 0 0 1 2 5.25v-1.5ZM13.5 16.5h3V3.75a.25.25 0 0 0-.25-.25H3.75a.25.25 0 0 0-.25.25v.75h6.25a3.75 3.75 0 0 1 3.75 3.75v8.25ZM2.174 9.27A.75.75 0 0 1 2.75 9h5.5a.75.75 0 0 1 .731.916l-.028.125A2.5 2.5 0 0 1 8.5 15h-.467c-.284.6-.894 1-1.581 1H4.585a1.75 1.75 0 0 1-1.722-1.437l-.85-4.679a.75.75 0 0 1 .161-.615Zm6.18 4.23H8.5a1 1 0 0 0 .163-1.987l-.31 1.987Zm-1.614.609.542-3.475a.755.755 0 0 1 .01-.05l.018-.084H3.65l.69 3.795a.25.25 0 0 0 .246.205h1.867a.25.25 0 0 0 .244-.195l.044-.196Z"
    />
  </svg>
)

export default SvgCoffeeMachine
