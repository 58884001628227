import {LinkType} from '@daedalus/core/src/booking/types/Link'

export enum BookingCreationStatus {
  Frozen = 'frozen',
  Initialized = 'initialized',
  Error = 'error'
}

export interface BookingCreationStatusResponse {
  error: null | string
  id: string
  links: LinkType[]
  status: BookingCreationStatus
  threeDomainSecure: null
}

export interface CreateBookingPayload {
  customer: {
    firstName: string
    lastName: string
  }
  offerMetadata: {
    accessTier: string[]
  }
}

export interface BookingCreationResponse {
  id: string
  links: LinkType[]
}
