import {createSelector} from '@reduxjs/toolkit'
import {getHotelsMetaData} from 'modules/hotelApi/getHotelMetaData'

export const getSearchResultsContext = createSelector(
  [getHotelsMetaData],
  hotelMetaData => {
    return {
      hotelsMetaData: hotelMetaData
    }
  }
)
