import {RootState} from 'store'

import {OverlayStateType} from './reducer'

export const OVERLAY_IDS = {
  roomDetails: 'roomDetails',
  dateSelection: 'dateSelection',
  guestSelection: 'guestSelection',
  offerDetails: 'offerDetails',
  preferences: 'preferences',
  membership: 'membership',
  referral: 'referral',
  account: 'account'
} as const

const findOverlayById = (id: string, overlays: OverlayStateType) => {
  return overlays.find(overlay => overlay.id === id)
}

// @Todo dry this
export const getRoomDetailsVisibility = (state: RootState) =>
  findOverlayById(OVERLAY_IDS.roomDetails, state.overlay)

export const getDateSelectionVisibility = (state: RootState) => {
  return findOverlayById(OVERLAY_IDS.dateSelection, state.overlay)
}

export const getGuestSelectionVisibility = (state: RootState) => {
  return findOverlayById(OVERLAY_IDS.guestSelection, state.overlay)
}

export const getOfferDetailsVisibility = (state: RootState) => {
  return findOverlayById(OVERLAY_IDS.offerDetails, state.overlay)
}

export const getPreferencesVisibility = (state: RootState) => {
  return findOverlayById(OVERLAY_IDS.preferences, state.overlay)
}

export const getMembershipVisibility = (state: RootState) => {
  return findOverlayById(OVERLAY_IDS.membership, state.overlay)
}

export const getAccountVisibility = (state: RootState) => {
  return findOverlayById(OVERLAY_IDS.account, state.overlay)
}

export const getReferralVisibility = (state: RootState) => {
  return findOverlayById(OVERLAY_IDS.referral, state.overlay)
}

export const getOverlayList = (state: RootState) => {
  return state.overlay || []
}
