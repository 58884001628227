import {loadModule} from '../../../../utils/loadModule'
import {AuthDialogPropTypes} from './AuthDialog'

export const AuthDialogLoadable = loadModule<AuthDialogPropTypes>(
  async () =>
    import(
      /* webpackChunkName: "AuthDialog" */
      './AuthDialog'
    ),
  {}
)
