import * as React from 'react'

export const GemsBoxSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={92} height={96} fill="none">
    <path
      fill="#365EDB"
      d="M3.167 44.786a1 1 0 0 1 .993-1.12h83.698a1 1 0 0 1 .992 1.125l-5.669 45.3a1 1 0 0 1-.992.877H9.61a1 1 0 0 1-.992-.881l-5.45-45.3Z"
    />
    <path
      fill="#3C69F5"
      d="M10.505 51.287h71.706l-4.669 31.842H14.56l-4.055-31.842Z"
    />
    <path fill="#365EDB" d="M39.98 5.03h12.793V0H39.978z" />
    <path
      fill="#26429B"
      d="M10.171 5.268a3 3 0 0 1 2.998-2.874h66.195A3 3 0 0 1 82.36 5.25l1.836 38.416H8.556l1.615-38.398Z"
    />
    <path
      fill="#13214D"
      d="M16.416 9.138h60.213l1.014 27.785H15.077l1.34-27.785Z"
    />
    <path
      fill="#1B2F6E"
      fillRule="evenodd"
      d="M15.075 36.898h.001c4.251.178 7.956-5.893 8.274-13.559.32-7.665-2.869-14.024-7.12-14.201l-1.155 27.76Zm62.689.322c-4.252.177-7.956-5.894-8.275-13.56-.319-7.666 2.87-14.024 7.12-14.201h.001l1.154 27.76Z"
      clipRule="evenodd"
    />
    <mask
      id="d8713b7a-GemsBoxImage"
      width={92}
      height={40}
      x={0}
      y={4}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha'
      }}
    >
      <path
        fill="#13214D"
        d="M.182 6.892s89.842-4.294 90.412 0c1.949 14.678 0 36.775 0 36.775H.182V6.892Z"
      />
    </mask>
    <g mask="url(#d8713b7a-GemsBoxImage)">
      <g clipPath="url(#fb6dd59e-GemsBoxImage)">
        <path
          fill="#42AAFD"
          d="M68.85 28.913 59.037 11.89c-5.859 3.4-9.807 9.747-9.807 17.023h19.62Z"
        />
        <path
          fill="#6EBDFD"
          d="M78.657 11.89a19.513 19.513 0 0 0-9.807-2.633c-3.574 0-6.92.962-9.808 2.633l9.808 17.018 9.807-17.018Z"
        />
        <path
          fill="#1B2F6E"
          d="M78.656 45.93c5.865-3.4 9.808-9.745 9.808-17.017h-19.62l9.807 17.018h.005Z"
        />
        <path
          fill="#365EDB"
          d="M49.23 28.913c0 7.272 3.948 13.623 9.807 17.018l9.807-17.018H49.23Z"
        />
        <path
          fill="#26429B"
          d="M78.657 11.89 68.85 28.908h19.62c0-7.271-3.949-13.623-9.808-17.018h-.005Z"
        />
        <path
          fill="#2E51BE"
          d="m68.85 28.913-9.808 17.018a19.513 19.513 0 0 0 9.808 2.633c3.573 0 6.92-.962 9.807-2.633L68.85 28.913Z"
        />
        <path
          fill="#3C69F5"
          d="M74.064 19.854H63.63l-5.215 9.06 5.215 9.053h10.435l5.22-9.054-5.22-9.059Z"
        />
      </g>
      <path
        fill="#FFF4E4"
        d="M29.904 13.99a.288.288 0 0 1 .562 0l.446 1.839a.293.293 0 0 0 .212.215l1.806.454c.294.074.294.498 0 .572l-1.806.454a.292.292 0 0 0-.212.215l-.446 1.838a.288.288 0 0 1-.562 0l-.446-1.838a.292.292 0 0 0-.211-.215l-1.807-.454c-.293-.074-.293-.498 0-.572l1.807-.454a.292.292 0 0 0 .211-.215l.446-1.838Z"
      />
      <g clipPath="url(#5bd196b3-GemsBoxImage)">
        <path
          fill="#FFD172"
          d="M41.347 14.629a4.672 4.672 0 0 0-3.617-1.65l-30.844.211a4.727 4.727 0 0 0-2.722.878c-.465.33-.87.731-1.2 1.192l22.593 13.88 15.83-13.634.375-.32a5.027 5.027 0 0 0-.415-.557Z"
        />
        <path
          fill="#C69100"
          d="M42.037 15.671a6.708 6.708 0 0 0-.275-.486L25.557 29.14 23.1 47.124l-.425 3.173a4.825 4.825 0 0 0 3.742-2.476l15.49-27.428a5.01 5.01 0 0 0 .13-4.722Z"
        />
        <path
          fill="#FFC400"
          d="M2.968 15.26c-1.065 1.504-1.32 3.58-.295 5.394l8.185 14.51 2.506 4.441 4.663 8.266c1.016 1.8 2.872 2.601 4.653 2.42l2.882-21.151L2.968 15.26Z"
        />
        <path
          fill="#fff"
          d="M11.58 33.013a.288.288 0 0 1 .562 0l.446 1.838a.29.29 0 0 0 .211.215l1.807.454c.293.074.293.498 0 .572l-1.807.454a.292.292 0 0 0-.211.215l-.446 1.838a.288.288 0 0 1-.562 0l-.446-1.838a.292.292 0 0 0-.212-.215l-1.806-.454c-.294-.074-.294-.498 0-.572l1.806-.454a.292.292 0 0 0 .212-.215l.446-1.838Z"
        />
      </g>
      <g clipPath="url(#e2e24d5b-GemsBoxImage)">
        <path
          fill="#E22252"
          d="M68.423 25.933a5.98 5.98 0 0 0-1.74-1.977 5.817 5.817 0 0 0-3.41-1.1l-9.138-.05-3.253 8.55 9.684 13.9 7.791-13.423c1.138-1.957 1.022-4.167.066-5.905v.005ZM31.5 34.6 21.3 52.17c-1.042 1.799-.87 3.831.096 5.377.218.35.476.674.77.963.9.887 2.13 1.46 3.556 1.47l16.721.092 1.088-19.941-12.036-5.525.005-.006Z"
        />
        <path
          fill="#F36"
          d="M43.45 22.75a8.745 8.745 0 0 0-7.61 4.369l-3.06 5.276L31.5 34.6l11.98 5.5.056.026 7.341-8.73 3.264-8.59-10.691-.057Z"
        />
        <path
          fill="#C6284F"
          d="m50.887 31.361-7.351 8.765-1.088 19.94 4.437.026a8.748 8.748 0 0 0 7.61-4.37l6.076-10.467-9.684-13.899v.005Z"
        />
      </g>
    </g>
    <path
      fill="#fff"
      d="M43.573 15.528a.298.298 0 0 1 .581 0l.46 1.9c.027.11.111.195.22.222l1.866.469c.303.076.303.515 0 .59l-1.867.47a.302.302 0 0 0-.218.222l-.461 1.9a.298.298 0 0 1-.58 0l-.462-1.9a.302.302 0 0 0-.218-.222l-1.867-.47c-.303-.075-.303-.514 0-.59l1.867-.469a.302.302 0 0 0 .218-.222l.461-1.9Z"
    />
    <mask
      id="d53fb7b7-GemsBoxImage"
      width={73}
      height={33}
      x={10}
      y={51}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha'
      }}
    >
      <path
        fill="#3C69F5"
        d="M10.504 51.287h71.705l-4.668 31.842H14.559l-4.055-31.842Z"
      />
    </mask>
    <g mask="url(#d53fb7b7-GemsBoxImage)">
      <path fill="#1B2F6E" d="M8.555 46.455h75.639v8.221H8.555z" />
    </g>
    <ellipse cx={46.376} cy={52.61} fill="#1B2F6E" rx={6.315} ry={6.207} />
    <ellipse cx={46.377} cy={52.61} fill="#fff" rx={3.934} ry={3.867} />
    <ellipse cx={46.376} cy={51.821} fill="#13214D" rx={1.246} ry={1.225} />
    <rect
      width={1.135}
      height={2.452}
      x={45.794}
      y={52.322}
      fill="#13214D"
      rx={0.567}
    />
    <ellipse cx={46.376} cy={51.575} fill="#26429B" rx={6.315} ry={6.207} />
    <ellipse cx={46.377} cy={51.575} fill="#fff" rx={3.934} ry={3.867} />
    <ellipse cx={46.376} cy={50.785} fill="#13214D" rx={1.246} ry={1.225} />
    <rect
      width={1.135}
      height={2.452}
      x={45.794}
      y={51.287}
      fill="#13214D"
      rx={0.567}
    />
    <path
      fill="#2E51BE"
      fillRule="evenodd"
      d="M21.7 90.967H9.609a1 1 0 0 1-.993-.88L7.388 79.885a12.328 12.328 0 0 1 2.081-.176c6.43 0 11.705 4.953 12.232 11.258Zm48.361 0h12.093a1 1 0 0 0 .993-.88l1.227-10.202a12.329 12.329 0 0 0-2.081-.176c-6.43 0-11.705 4.953-12.232 11.258Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M59.127 40.4a.298.298 0 0 1 .58 0l.461 1.9c.027.109.11.195.219.222l1.866.468c.303.077.303.515 0 .591l-1.866.47a.302.302 0 0 0-.219.222l-.46 1.899a.298.298 0 0 1-.581 0l-.461-1.9a.302.302 0 0 0-.219-.222l-1.866-.469c-.303-.076-.303-.514 0-.59l1.866-.47a.302.302 0 0 0 .219-.222l.46-1.9Z"
    />
    <rect
      width={86.469}
      height={5.033}
      x={2.588}
      y={90.967}
      fill="#B8A1FF"
      rx={2}
    />
    <defs>
      <clipPath id="fb6dd59e-GemsBoxImage">
        <path fill="#fff" d="M49.23 9.257h39.234v39.308H49.23z" />
      </clipPath>
      <clipPath id="5bd196b3-GemsBoxImage">
        <path fill="#fff" d="M2.023 12.98h40.544v37.342H2.023z" />
      </clipPath>
      <clipPath id="e2e24d5b-GemsBoxImage">
        <path fill="#fff" d="M20.592 22.75h48.585v37.342H20.592z" />
      </clipPath>
    </defs>
  </svg>
)
