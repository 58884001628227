import React, {useEffect, useState} from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import {usePopper} from 'react-popper'
import {Placement as PopperPlacement, VirtualElement} from '@popperjs/core'

import {StyledPopper} from './styles'

export enum PopoverPlacement {
  Left = 'left',
  Center = 'center',
  Right = 'right',
  BottomLeft = 'bottomLeft',
  BottomRight = 'bottomRight'
}
export interface PopoverProps {
  /** Whether the popover is open */
  isOpen: boolean
  /** The placement of the popover relative to the anchor */
  placement?: PopoverPlacement
  /** Callback which fires on outside click of the popover */
  onOutsideClick: (
    event: MouseEvent
  ) => void /** The `ref` to the anchor element */
  anchorRef: React.RefObject<AnchorElement>
  /** Amount (in px) to adjust vertically */
  verticalOffset?: number
  /** Amount (in px) to adjust horizontally */
  horizontalOffset?: number
  children: React.ReactNode
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
}

type AnchorElement = Element | VirtualElement | null | undefined
type PopperElement = HTMLElement | null | undefined

export const DEFAULT_PLACEMENT = PopoverPlacement.Left

const DEFAULT_OFFSET = 13

// get the height of the anchor element to set the default negative top offset
const getVerticalOffset = (anchorElement: AnchorElement) =>
  anchorElement &&
  (anchorElement.getBoundingClientRect().height + DEFAULT_OFFSET) * -1

// transform popper placement and offset into our simplified placement
const transforms = (
  placement: PopoverPlacement,
  anchorElement: AnchorElement,
  verticalOffset: number,
  horizontalOffset: number
):
  | {
      placement: PopperPlacement
      offset: [number, number]
    }
  | undefined => {
  switch (placement) {
    case PopoverPlacement.Left: {
      return {
        placement: 'bottom-start',
        offset: [
          horizontalOffset * -1,
          Number(getVerticalOffset(anchorElement)) + verticalOffset
        ]
      }
    }
    case PopoverPlacement.Center: {
      return {
        placement: 'bottom',
        offset: [0, Number(getVerticalOffset(anchorElement)) + verticalOffset]
      }
    }
    case PopoverPlacement.Right: {
      return {
        placement: 'bottom-end',
        offset: [
          horizontalOffset,
          Number(getVerticalOffset(anchorElement)) + verticalOffset
        ]
      }
    }
    case PopoverPlacement.BottomLeft: {
      return {
        placement: 'bottom-start',
        offset: [0, verticalOffset]
      }
    }
    case PopoverPlacement.BottomRight: {
      return {
        placement: 'bottom-end',
        offset: [0, verticalOffset]
      }
    }
    default: {
      break
    }
  }
}

/**
 * A Popover can be used to display some content on top
 * of another based around an anchor element
 */
export const Popover = ({
  isOpen = false,
  placement = DEFAULT_PLACEMENT,
  onOutsideClick,
  anchorRef,
  verticalOffset = 0,
  horizontalOffset = DEFAULT_OFFSET,
  children,
  className,
  dataId
}: PopoverProps) => {
  const [popperElement, setPopperElement] = useState<PopperElement>(null)
  const [anchorElement, setAnchorElement] = useState<AnchorElement>(null)

  // The usePopper hook intentionally takes the DOM node, not refs, in order to be able to update when the nodes change.
  useEffect(() => {
    if (anchorRef.current) setAnchorElement(anchorRef.current)
  }, [anchorRef])

  const transformsResult = transforms(
    placement,
    anchorElement,
    verticalOffset,
    horizontalOffset
  )
  const transformedPlacement = transformsResult?.placement
  const offset = transformsResult?.offset

  const {styles, attributes} = usePopper(anchorElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset
        }
      }
    ],
    placement: transformedPlacement
  })

  if (isOpen) {
    return (
      <OutsideClickHandler onOutsideClick={onOutsideClick}>
        <StyledPopper
          ref={setPopperElement}
          style={styles.popper}
          className={className}
          data-id={dataId}
          {...attributes.popper}
        >
          {children}
        </StyledPopper>
      </OutsideClickHandler>
    )
  }

  return null
}
