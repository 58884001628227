// TODO: Send this to @daedalus/common

import {calculateNightlyPrice} from './price'

export type CalculateTotalRateType = (
  baseRate: number,
  taxes: number | null | undefined,
  localTaxes: number | null | undefined,
  includeTaxes: boolean,
  includeLocalTaxes: boolean,
  numberOfNights: number,
  numberOfRooms: number
) => number

/**
 * Calculates the display rate of a room based on the tax display logic
 * @constructor
 * @param {number} baseRate - the hotel base rate
 * @param {number} taxes - the hotel price taxes
 * @param {number} localTaxes - the fees charged at the hotel
 * @param {boolean} includeTaxes - If should include taxes on the price
 * @param {boolean} includeLocalTaxes - If should include local taxes such as tourist taxes on the price
 * @param {number} numberOfNights - Number of nights of the offer
 * @param {number} numberOfRooms - Number of rooms of the offer
 */
const calculateDisplayTotal: CalculateTotalRateType = (
  baseRate,
  taxes,
  localTaxes,
  includeTaxes,
  includeLocalTaxes,
  numberOfNights,
  numberOfRooms
) => {
  let totalRate = baseRate
  if (includeTaxes && taxes) {
    totalRate += taxes
  }

  if (includeLocalTaxes && localTaxes) {
    totalRate += localTaxes
  }

  return calculateNightlyPrice(totalRate, numberOfNights, numberOfRooms)
}

export default calculateDisplayTotal
