import React from 'react'
import {FormattedDate} from 'react-intl'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import isValidDate from 'date-fns/isValid'
import {toggle} from 'opticks'

import {Icon, IconName} from '@daedalus/atlas/Icon'
import {InputButton} from '@daedalus/atlas/InputButton'
import {InputButtonStyledContainer} from '@daedalus/atlas/InputButton'
import {getLinariaClassName} from '@daedalus/atlas/utils/getLinariaClassName'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'
import {MiddayDate} from '@daedalus/core/src/utils/date'

import {guestPickerMessages} from '../GuestPicker/guestPickerMessages'

type Props = {
  /** Check in date */
  checkIn: MiddayDate
  /** Check out date */
  checkOut: MiddayDate
  /** Destination name */
  destination: string
  /** Number of guests */
  numberOfGuests: number
  /** Number of rooms */
  numberOfRooms: number
  /** Click callback */
  onClick: () => void
  /** Whether to show the compacted variant */
  isCompact?: boolean
  /** Whether the rooms count and label is shown */
  isRoomsLabelShown?: boolean
  /** Whether the Summary Button is rounded */
  isRounded?: boolean
  /** Whether the color used for borders and icons is the default or neutral */
  isNeutralColor?: boolean
}

const COMPACT_SIZE = 42

// TODO: Remove getLinariaClassName when migrating to linaria
const SummaryButton = styled(InputButton)<{
  isCompact: boolean
  isNeutralColor: boolean
}>(
  ({theme, isCompact, isNeutralColor}) => css`
    border-radius: ${theme.layout.radius.rounded}px;
    box-shadow: ${isCompact ? theme.shadows.floating : theme.shadows.fixed};
    ${isCompact &&
    `min-height: ${COMPACT_SIZE}px;
      &+div {
        height: ${COMPACT_SIZE}px;
      }`}

    .${getLinariaClassName(InputButtonStyledContainer)} {
      ${!isNeutralColor &&
      `border-color: ${theme.colors.border.interactive.c500};`}
    }
  `
)

const ContentWrapper = styled.span<{isCompact: boolean}>(
  ({theme, isCompact}) => css`
    display: flex;
    flex: 1;
    flex-direction: ${isCompact ? 'row' : 'column'};
    text-align: left;
    min-width: 0;
    padding-right: ${theme.layout.spacing.s400}px;
    ${isCompact && `align-items: center;`}
  `
)

const DestinationText = styled.span(
  ({theme}) => css`
    ${theme.typography.text.labelS};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
)

const GuestsDateWrapper = styled.span<{isCompact: boolean}>(
  ({theme, isCompact}) => css`
    ${theme.typography.text.bodyXS};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${isCompact &&
    `
      margin-top: ${theme.layout.spacing.s100}px;
    `}
  `
)

const CompactBullet = styled.span(
  ({theme}) => css`
    ${theme.typography.text.labelS};
  `
)

const ShortFormattedDate = ({value}: {value: Date}): React.ReactElement => (
  <FormattedDate value={value} month="short" day="2-digit" />
)

export const SearchSummaryButton = ({
  destination,
  checkIn,
  checkOut,
  numberOfGuests,
  numberOfRooms,
  onClick,
  isCompact = false,
  isRoomsLabelShown = true,
  isRounded = true,
  isNeutralColor = false
}: Props) => {
  const isDatesValid = isValidDate(checkIn) && isValidDate(checkOut)

  return (
    <SummaryButton
      id="SearchSummary"
      onClick={onClick}
      dataId="SearchSummary"
      icon={
        <Icon
          name={toggle<IconName[]>(
            'b4f4f3cc-website-restyle-v3',
            'Search',
            'SearchGradient'
          )}
          size="md"
          colorPath={isNeutralColor ? undefined : 'content.interactive.c500'}
        />
      }
      isRounded={isRounded}
      isCompact={isCompact}
      isNeutralColor={isNeutralColor}
    >
      <ContentWrapper isCompact={isCompact}>
        <DestinationText data-id="DestinationValue">
          {destination || (
            <FormattedMessageWrapper
              id="whereAreYouGoing"
              defaultMessage="Where are you going?"
            />
          )}
        </DestinationText>

        {isCompact && <CompactBullet>{'\u00A0·\u00A0'}</CompactBullet>}

        {isDatesValid && (
          <GuestsDateWrapper isCompact={isCompact} className="chromatic-ignore">
            <span data-id="CheckInValue">
              <ShortFormattedDate value={checkIn} />
            </span>
            {' - '}
            <span data-id="CheckOutValue">
              <ShortFormattedDate value={checkOut} />
            </span>
            {!isCompact && (
              <>
                <span data-id="GuestValue">{` · ${numberOfGuests} `}</span>
                <FormattedMessageWrapper
                  {...guestPickerMessages.guest}
                  values={{count: numberOfGuests}}
                />
                {isRoomsLabelShown && (
                  <>
                    <span data-id="RoomValue">{`, ${numberOfRooms} `}</span>
                    <FormattedMessageWrapper
                      {...guestPickerMessages.room}
                      values={{count: numberOfRooms}}
                    />
                  </>
                )}
              </>
            )}
          </GuestsDateWrapper>
        )}
      </ContentWrapper>
    </SummaryButton>
  )
}
