/* eslint-disable fp/no-mutation */
import { SerializeQueryArgs } from '@reduxjs/toolkit/dist/query/defaultSerializeQueryArgs';
import { createApi, defaultSerializeQueryArgs, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { omit } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { ContentHotel, HotelOfferEntity as SapiHotelOfferEntity, ReviewsResponse, RoomsResults, SapiClient, SearchResults, Suggestion, SuggestType } from '@findhotel/sapi';
import { SearchHandler } from '@findhotel/sapi/dist/types/packages/core/src/search';
import { type MappingContext, type OldHotelOfferEntity, sapiHotelOfferEntityToHotelOfferEntity } from '../../../offer/business/offersMapping';
import { Room, SplitBooking } from '../../../room/types/room';
import { getSapiClient } from '../index'; // Prevents a strange require error in mobile-app
import { offersSearchErrored, offersSearchReceived, offersSearchStarted, offersSearchSucceeded, roomSearchStarted, roomSearchSucceeded, searchAnchorReceived, SearchApiGetSearchActionsType, searchCompleted, searchErrored, searchHotelsReceived, searchOffersReceived, searchStarted } from './action';
import { SearchApiError } from './error';
import { type TransformedSearchResults, transformSearchResults } from './transformations/transformSearchResults';
import { type BaseMappingContext, mergeDataRecipe, mergeHotelIds } from './utils';
export { SearchApiError, getSearchApiErrorMessage } from "./error";
export { hasSearchResults } from './utils';
declare let Sentry: typeof import('@sentry/browser');
interface BaseQueryParams {
  useSingleCacheKey?: boolean;
}
export type GetSuggestionsParams = {
  query: string;
  suggestsCount?: number;
  suggestsTypes?: SuggestType[];
};
interface GetHotelParams extends BaseQueryParams {
  hotelId: string;
}
interface GetReviewsParam {
  hotelId: string;
}
export type TransformOffersResponse = (response: OldHotelOfferEntity | undefined) => OldHotelOfferEntity;
export type GetOffersParams = Parameters<SapiClient['offers']>[0] & MappingContext & BaseQueryParams & {
  shouldDispatchActions?: boolean;
  /** Extra fail-safe mechanism to prevent unwanted updates to state.sapiSearch.hotelOfferEntities */
  shouldUpdateOfferEntities?: boolean;
  /**
   * Optional callback function to transform the response data from the API.
   * This function takes the mapped offer entity as an argument and returns
   * the transformed data. If not provided, the original mapped offer entity
   * will be used directly in the response.
   */
  transformResponse?: TransformOffersResponse;
};
export enum OffersState {
  Pending = 'pending',
  OffersRequested = 'offersRequested',
  OffersReceived = 'offersReceived',
  Completed = 'completed',
}
interface OffersData {
  searchId?: string;
  checkIn?: string;
  checkOut?: string;
  status: OffersState;
  offerEntity?: OldHotelOfferEntity;
}
export interface TransformedRoomsResponse extends Omit<RoomsResults, 'rooms' | 'splitBooking'> {
  rooms: Room[];
  searchId: string;
  splitBooking?: SplitBooking;
}
export type TransformRoomsResponse = (response: RoomsResults, params: BaseGetRoomsParams) => TransformedRoomsResponse;
export type BaseGetRoomsParams = Parameters<SapiClient['rooms']>[0] & BaseQueryParams;
export type GetRoomsParams = BaseGetRoomsParams & {
  transformResponse: TransformRoomsResponse;
};
export type GetSearchParams = Parameters<SapiClient['search']>[0] & BaseQueryParams & {
  shouldDispatchActions?: boolean;
  queryContext?: Record<string, unknown>; // Tracked with errors to help isolate issues
  mappingContext: BaseMappingContext;
};
export enum SearchState {
  Pending = 'pending',
  HotelsPending = 'hotelsPending',
  OffersPending = 'offersPending',
  Completed = 'completed',
}
export interface SearchData extends Partial<TransformedSearchResults> {
  searchState?: SearchState;
  currentSearch?: Awaited<ReturnType<SearchHandler>>;
}
export const initialSearchData: SearchData = {
  searchState: SearchState.Pending,
  hotelEntities: {},
  hotelOfferEntities: {},
  hotelIds: []
};
interface GetFreeTextSuggestParams {
  query: string;
}

// baseQuery is unused because we use the SAPI SDK but the error type is enforced for queryFn
const baseQuery = fakeBaseQuery<SearchApiError>();

/**
 * Create a serializer that allows us to customize the cache behavior
 *
 * Serializer option: `ignoreKeys`
 * - Ignore these keys for ALL queries for this endpoint when generating a cache key
 * - Intended for custom transformations / side-effects
 *
 * Query hook option: `useSingleCacheKey`
 *  - Pass in query (hook) arguments to store data under a single key for the endpoint, regardless of query arguments
 *  - Use `select({useSingleCacheKey: true})` to retrieve the data for the last query call in selectors
 */
const makeSerializeQueryArgs = <Args extends BaseQueryParams,>({
  ignoreKeys
}: {
  ignoreKeys?: string[];
} = {}): SerializeQueryArgs<Args> => ({
  queryArgs,
  endpointDefinition,
  endpointName
}) => {
  if (!queryArgs) return endpointName;
  if (queryArgs.useSingleCacheKey) return endpointName;
  const filteredQueryArgs = ignoreKeys ? omit(ignoreKeys, queryArgs) : queryArgs;
  return defaultSerializeQueryArgs({
    endpointName,
    queryArgs: filteredQueryArgs,
    endpointDefinition
  });
};
export const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery,
  endpoints: builder => ({
    getHotel: builder.query<ContentHotel | undefined, GetHotelParams>({
      async queryFn({
        hotelId
      }) {
        try {
          const sapiClient = await getSapiClient();
          const data = await sapiClient.hotel(hotelId);
          return {
            data
          };
        } catch (error) {
          return {
            error: new SearchApiError('Error fetching hotel', error)
          };
        }
      },
      serializeQueryArgs: makeSerializeQueryArgs()
    }),
    getRooms: builder.query<TransformedRoomsResponse, GetRoomsParams>({
      queryFn: async parameters => {
        const {
          transformResponse,
          ...getRoomsParams
        } = parameters;
        try {
          const sapiClient = await getSapiClient();
          const data = await sapiClient.rooms(getRoomsParams);
          const decoratedData = transformResponse(data, parameters);
          return {
            data: {
              ...decoratedData,
              searchId: uuidv4() // Provide a unique ID per request/results which we can use for other caching/memo purposes
            }
          };
        } catch (error) {
          return {
            error: new SearchApiError('Error fetching rooms', error)
          };
        }
      },
      serializeQueryArgs: makeSerializeQueryArgs({
        ignoreKeys: ['transformResponse', 'searchId']
      }),
      async onQueryStarted(params, {
        dispatch,
        queryFulfilled
      }) {
        try {
          dispatch(roomSearchStarted());
          await queryFulfilled;
          dispatch(roomSearchSucceeded({
            params
          }));
        } catch (error) {
          if (typeof Sentry !== 'undefined') Sentry.captureException(error);
        }
      }
    }),
    getOffers: builder.query<OffersData, GetOffersParams>({
      // Initial data
      queryFn() {
        return {
          data: {
            status: OffersState.Pending
          }
        };
      },
      serializeQueryArgs: makeSerializeQueryArgs({
        ignoreKeys: ['shouldDispatchActions', 'transformResponse']
      }),
      async onCacheEntryAdded(parameters, {
        updateCachedData,
        cacheDataLoaded,
        cacheEntryRemoved,
        dispatch,
        getCacheEntry
      }) {
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        try {
          const {
            transformResponse,
            shouldDispatchActions,
            ...getOffersParameters
          } = parameters;
          const mapOfferEntityV3ToOldDataModel = (offerEntity?: SapiHotelOfferEntity) => {
            if (!offerEntity) return undefined;
            const cacheEntry = getCacheEntry();
            const mappedOfferEntity = sapiHotelOfferEntityToHotelOfferEntity(offerEntity, {
              // Prefer using dates returned by SAPI
              checkIn: cacheEntry.data?.checkIn || parameters.checkIn,
              checkOut: cacheEntry.data?.checkOut || parameters.checkOut,
              includeLocalTaxes: parameters.includeLocalTaxes,
              includeTaxes: parameters.includeTaxes,
              includeRoomsInNightlyPrice: parameters.includeRoomsInNightlyPrice,
              numberOfRooms: parameters.numberOfRooms
            });
            return transformResponse ? transformResponse(mappedOfferEntity) : mappedOfferEntity;
          };
          const sapiClient = await getSapiClient();
          await sapiClient.offers(getOffersParameters, {
            onStart: ({
              searchId,
              checkIn,
              checkOut,
              rooms
            }) => {
              updateCachedData(() => ({
                searchId,
                // Store actual dates used by SAPI (eg. defaults or modified)
                checkIn: checkIn ?? parameters.checkIn,
                checkOut: checkOut ?? parameters.checkOut,
                status: OffersState.OffersRequested
              }));
              if (shouldDispatchActions) {
                dispatch(offersSearchStarted({
                  parameters,
                  searchId,
                  rooms,
                  checkIn,
                  checkOut
                }));
              }
            },
            onOffersReceived: offerEntity => {
              updateCachedData(draft => ({
                ...draft,
                offerEntity: mapOfferEntityV3ToOldDataModel(offerEntity),
                status: OffersState.OffersReceived
              }));
              const cacheEntry = getCacheEntry();
              if (shouldDispatchActions) {
                dispatch(offersSearchReceived({
                  parameters,
                  offers: cacheEntry.data?.offerEntity,
                  searchId: (cacheEntry.data?.searchId as string)
                }));
              }
            },
            onComplete: offerEntity => {
              updateCachedData(draft => ({
                ...draft,
                offerEntity: mapOfferEntityV3ToOldDataModel(offerEntity),
                status: OffersState.Completed
              }));
              const cacheEntry = getCacheEntry();
              if (shouldDispatchActions && cacheEntry.data) {
                dispatch(offersSearchSucceeded({
                  parameters,
                  searchId: (cacheEntry.data?.searchId as string),
                  offers: cacheEntry.data?.offerEntity
                }));
              }
            }
          });
        } catch (error) {
          const cacheEntry = getCacheEntry();
          dispatch(offersSearchErrored({
            parameters,
            searchId: (cacheEntry.data?.searchId as string),
            error: new SearchApiError('Error fetching offers', error)
          }));
          if (typeof Sentry !== 'undefined') {
            Sentry.captureException(new SearchApiError('Error fetching offers', error));
          }
        }

        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
      }
    }),
    getSearch: builder.query<SearchData, GetSearchParams>({
      queryFn() {
        return {
          data: initialSearchData
        };
      },
      serializeQueryArgs: makeSerializeQueryArgs({
        ignoreKeys: ['shouldDispatchActions', 'queryContext']
      }),
      async onCacheEntryAdded(parameters, {
        updateCachedData,
        cacheDataLoaded,
        cacheEntryRemoved,
        getCacheEntry,
        dispatch
      }) {
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        const {
          shouldDispatchActions,
          queryContext,
          // eslint-disable-line @typescript-eslint/no-unused-vars
          mappingContext,
          ...searchParameters
        } = parameters;
        try {
          /**
           * The callbacks responses are a partial of the results object so we need to merge them into a coherent state
           * Note that onHotelsReceived,onOffersReceived,onComplete will be called again after `loadMore`
           **/
          const updateData = (results: Partial<SearchResults>, searchState?: SearchState) => {
            const transformedResults = transformSearchResults(results, mappingContext);
            return updateCachedData(draft => mergeDataRecipe(draft, transformedResults, searchState));
          };
          const dispatchEvent = (action: SearchApiGetSearchActionsType) => {
            if (!shouldDispatchActions) return;
            const {
              data
            } = getCacheEntry();
            if (data) dispatch(action({
              parameters,
              data
            }));
          };
          const sapiClient = await getSapiClient();
          await sapiClient.search(searchParameters, {
            onStart: response => {
              updateData(response, SearchState.HotelsPending);
              dispatchEvent(searchStarted);
            },
            onAnchorReceived: response => {
              updateData(response);
              dispatchEvent(searchAnchorReceived);
            },
            onHotelsReceived: response => {
              updateData(response, SearchState.OffersPending);
              dispatchEvent(searchHotelsReceived);
            },
            onOffersReceived: response => {
              updateData(response);
              dispatchEvent(searchOffersReceived);
            },
            onComplete: ({
              hotelIds
            }) => {
              const {
                data
              } = getCacheEntry();
              const hotelIdsMerged = mergeHotelIds(data?.hotelIds, hotelIds);
              const hotelIdsForHotelsWithOffers = hotelIdsMerged.filter(hotelId => data?.hotelOfferEntities?.[hotelId]?.offers?.length);
              updateCachedData(draft => {
                draft.searchState = SearchState.Completed;
                draft.hotelIds = hotelIdsForHotelsWithOffers;
              });
              dispatchEvent(searchCompleted);
            }
          });
        } catch (originalError) {
          const {
            data
          } = getCacheEntry();
          const error = new SearchApiError('Error fetching search', originalError);
          dispatch(searchErrored({
            parameters,
            data,
            error
          }));
          if (typeof Sentry !== 'undefined') Sentry.captureException(error, {
            extra: {
              parameters
            }
          });
        }

        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
      }
    }),
    getSuggestions: builder.query<Suggestion[] | undefined, GetSuggestionsParams>({
      queryFn: async parameters => {
        const {
          query,
          suggestsCount,
          suggestsTypes
        } = parameters;
        try {
          const sapiClient = await getSapiClient();
          const data = await sapiClient.suggest(query, suggestsCount, suggestsTypes);
          return {
            data
          };
        } catch (error) {
          return {
            error: new SearchApiError('Error fetching suggestions', error)
          };
        }
      }
    }),
    getFreeTextSuggest: builder.query<Suggestion[], GetFreeTextSuggestParams>({
      queryFn: async parameters => {
        const {
          query
        } = parameters;
        try {
          const sapiClient = await getSapiClient();
          const data = await sapiClient.freeTextSuggest(query);
          return {
            data
          };
        } catch (error) {
          return {
            error: new SearchApiError('Error fetching suggestions', error)
          };
        }
      }
    }),
    getUserReviews: builder.query<ReviewsResponse, GetReviewsParam>({
      queryFn: async ({
        hotelId
      }) => {
        try {
          const sapiClient = await getSapiClient();
          const data = await sapiClient.reviews(hotelId);
          return {
            data
          };
        } catch (error) {
          return {
            error: new SearchApiError('Error fetching reviews', error)
          };
        }
      }
    })
  })
});
export const {
  useGetHotelQuery,
  useGetRoomsQuery,
  useLazyGetRoomsQuery,
  useGetOffersQuery,
  useGetSearchQuery,
  useGetSuggestionsQuery,
  useGetFreeTextSuggestQuery,
  usePrefetch,
  useGetUserReviewsQuery
} = searchApi;