import React, {useContext, useEffect, useRef, useState} from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {PopoverPlacement} from '@daedalus/atlas/Popover'
import {DatePickerType} from '@daedalus/core/src/datePicker/types'
import {ConfigureI18nContext} from '@daedalus/core/src/localization/components/ConfigureI18n'
import {
  roomsSplitToString,
  roomsToConfigurationObject
} from '@daedalus/core/src/room/business/roomConfiguration'
import {RoomSplitType} from '@daedalus/core/src/room/types/RoomConfiguration'
import {DatePickerPopover} from '@daedalus/shared/src/search/DatePickerPopover'
import {GuestPickerPopover} from '@daedalus/shared/src/search/GuestPickerPopover'
import {SearchFormHorizontal} from '@daedalus/shared/src/search/SearchFormHorizontal'

import {POPOVER_HORIZONTAL_OFFSET, POPOVER_VERTICAL_OFFSET} from './constants'
import {SearchBoxProps} from './types'

const FormContainer = styled.div(
  () => css`
    width: 100%;
    display: flex;
  `
)
enum OpenedFormControl {
  NONE = 'none',
  CHECK_IN = 'checkIn',
  CHECK_OUT = 'checkOut',
  GUEST = 'guest'
}

export const SearchForm = ({
  checkIn,
  checkOut,
  rooms,
  updateDates,
  updateRooms,
  updateSearch
}: SearchBoxProps) => {
  const i18n = useContext(ConfigureI18nContext)
  const containerRef = useRef<HTMLDivElement>(null)
  const guestPickerRef = useRef<HTMLDivElement>(null)

  const [openedFormControl, setOpenedFormControl] = useState<OpenedFormControl>(
    OpenedFormControl.NONE
  )

  // Input state
  const [newCheckIn, setNewCheckIn] = useState<string>(checkIn)
  const [newCheckOut, setNewCheckOut] = useState<string>(checkOut)
  const [newRooms, setNewRooms] = useState<string>(rooms)
  const {numberOfAdults, numberOfChildren, numberOfRooms, roomsSplit} =
    roomsToConfigurationObject(newRooms)

  // Keep internal/external state in sync
  useEffect(() => {
    setNewCheckIn(checkIn)
    setNewCheckOut(checkOut)
    setNewRooms(rooms)
  }, [checkIn, checkOut, rooms])

  const handleReset = () => {
    setNewCheckIn(checkIn)
    setNewCheckOut(checkOut)
    setNewRooms(rooms)
  }

  const handleSubmit = () => {
    updateSearch({
      checkIn: newCheckIn,
      checkOut: newCheckOut,
      rooms: newRooms
    })
  }

  const handleSearchButtonClick = () => {
    handleSubmit()
  }

  const handleCheckInClick = () => {
    setOpenedFormControl(OpenedFormControl.CHECK_IN)
  }

  const handleCheckOutClick = () => {
    setOpenedFormControl(OpenedFormControl.CHECK_OUT)
  }

  const handleDateChange = (checkInDate: string, checkOutDate: string) => {
    setNewCheckIn(checkInDate)
    setNewCheckOut(checkOutDate)
  }

  const handleDatePickerOpen = (datePickerType: DatePickerType) => {
    const openedControl = {
      [DatePickerType.CheckIn]: OpenedFormControl.CHECK_IN,
      [DatePickerType.CheckOut]: OpenedFormControl.CHECK_OUT
    }[datePickerType]
    setOpenedFormControl(openedControl)
  }

  const handleCloseDatePicker = () => {
    setOpenedFormControl(OpenedFormControl.NONE)
  }

  const handleGuestsClick = () => {
    setOpenedFormControl(OpenedFormControl.GUEST)
  }

  const handleGuestPickerClose = () => {
    setOpenedFormControl(OpenedFormControl.NONE)
  }

  const handleGuestChange = (
    newRoomsSplit: RoomSplitType[],
    shouldPerformSearch?: boolean
  ) => {
    const newRooms = roomsSplitToString(newRoomsSplit)
    setNewRooms(newRooms)
    if (shouldPerformSearch) updateRooms({rooms: newRooms})
  }

  const handleSearchSubmit = () => {
    setOpenedFormControl(OpenedFormControl.NONE)
    updateRooms({rooms: newRooms})
    updateDates({checkIn: newCheckIn, checkOut: newCheckOut})
  }

  const handleOutsideClick = () => {
    if (
      checkIn !== newCheckIn ||
      checkOut !== newCheckOut ||
      rooms !== newRooms
    ) {
      handleReset()
    }
  }

  const isCheckInPickerOpen = openedFormControl === OpenedFormControl.CHECK_IN
  const isCheckOutPickerOpen = openedFormControl === OpenedFormControl.CHECK_OUT
  const isGuestPickerOpen = openedFormControl === OpenedFormControl.GUEST

  const openedDatePickerType: DatePickerType | null = {
    [OpenedFormControl.CHECK_IN]: DatePickerType.CheckIn,
    [OpenedFormControl.CHECK_OUT]: DatePickerType.CheckOut,
    [OpenedFormControl.GUEST]: null,
    [OpenedFormControl.NONE]: null
  }[openedFormControl]

  return (
    <>
      <FormContainer ref={containerRef}>
        <OutsideClickHandler
          display="contents"
          onOutsideClick={handleOutsideClick}
        >
          <SearchFormHorizontal
            checkIn={newCheckIn}
            checkOut={newCheckOut}
            numberOfAdults={numberOfAdults}
            numberOfChildren={numberOfChildren}
            numberOfRooms={numberOfRooms}
            onCheckInDatePickerClick={handleCheckInClick}
            onCheckOutDatePickerClick={handleCheckOutClick}
            onGuestPickerClick={handleGuestsClick}
            onSubmit={handleSearchButtonClick}
            isCheckInPickerOpen={isCheckInPickerOpen}
            isCheckOutPickerOpen={isCheckOutPickerOpen}
            isGuestPickerOpen={isGuestPickerOpen}
            guestPickerRef={guestPickerRef}
          />
          <DatePickerPopover
            isOpen={isCheckInPickerOpen || isCheckOutPickerOpen}
            openedDatePickerType={openedDatePickerType}
            anchorRef={containerRef}
            checkIn={newCheckIn}
            checkOut={newCheckOut}
            onClose={handleCloseDatePicker}
            onChange={handleDateChange}
            onDatePickerOpen={handleDatePickerOpen}
            onSubmit={handleSearchSubmit}
            verticalOffset={POPOVER_VERTICAL_OFFSET}
            horizontalOffset={POPOVER_HORIZONTAL_OFFSET}
            weekdaysShort={i18n.weekDayNamesShort}
            months={i18n.monthNames}
            firstDayOfWeek={i18n.firstDayOfWeek}
          />
          <GuestPickerPopover
            isOpen={isGuestPickerOpen}
            anchorRef={guestPickerRef}
            placement={PopoverPlacement.Left}
            roomsSplit={roomsSplit}
            onChange={handleGuestChange}
            onSubmit={handleSearchSubmit}
            onClose={handleGuestPickerClose}
            verticalOffset={POPOVER_VERTICAL_OFFSET}
            horizontalOffset={POPOVER_HORIZONTAL_OFFSET}
          />
        </OutsideClickHandler>
      </FormContainer>
    </>
  )
}
