import React from 'react'

import {Icon} from '@daedalus/atlas/src/components/designSystem/Icon'
import {Text} from '@daedalus/atlas/src/components/designSystem/Text'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {ArrowButton} from '../ArrowButton'
import {IconWrapper, Label, RowWrapper} from '../commonStyles'

interface Props {
  onClick: () => void
}

export const MyWatchlistsLink = ({onClick}: Props) => (
  <RowWrapper>
    <ArrowButton onClick={onClick}>
      <Label>
        <IconWrapper>
          <Icon name="Heart" />
        </IconWrapper>
        <Text variant="labelM">
          <FormattedMessageWrapper
            id="myWishlists"
            defaultMessage="My wishlists"
          />
        </Text>
      </Label>
    </ArrowButton>
  </RowWrapper>
)
