// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgWhatsapp = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 21 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="m2.536 18 1.125-4.108a7.912 7.912 0 0 1-1.059-3.964C2.604 5.556 6.162 2 10.533 2a7.88 7.88 0 0 1 5.61 2.325 7.88 7.88 0 0 1 2.321 5.61c-.002 4.37-3.56 7.928-7.93 7.928h-.004a7.927 7.927 0 0 1-3.79-.965L2.536 18Zm4.399-2.538.24.143c1.013.6 2.173.918 3.356.918h.002a6.6 6.6 0 0 0 6.592-6.59c0-1.76-.684-3.416-1.929-4.661a6.548 6.548 0 0 0-4.66-1.933 6.6 6.6 0 0 0-6.595 6.59c0 1.245.348 2.457 1.008 3.506l.157.25-.666 2.431 2.495-.654Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.551 6.614c-.16-.387-.324-.334-.446-.34a7.937 7.937 0 0 0-.38-.007.728.728 0 0 0-.528.247c-.182.199-.694.678-.694 1.653s.71 1.917.81 2.05c.099.132 1.397 2.133 3.385 2.99.473.205.842.327 1.13.418.474.15.906.13 1.248.079.38-.057 1.172-.48 1.337-.943.165-.462.165-.859.116-.941-.05-.083-.182-.133-.38-.232s-1.172-.578-1.354-.644c-.182-.067-.314-.1-.446.099-.132.198-.512.644-.627.777-.116.132-.231.148-.43.05-.198-.1-.836-.31-1.593-.984-.59-.525-.987-1.174-1.103-1.373-.115-.198-.012-.305.087-.404.09-.089.198-.231.297-.347.1-.116.133-.198.199-.33.066-.133.033-.248-.017-.348-.05-.099-.446-1.074-.61-1.47Z"
    />
  </svg>
)

export default SvgWhatsapp
