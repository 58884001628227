import {styled} from '@linaria/react'

export const NoStyleLink = styled.a`
  color: inherit;
  text-decoration: none;
  font-size: inherit;
  line-height: inherit;
  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: inherit;
    text-decoration: none;
  }
  -webkit-tap-highlight-color: transparent;
`
