import * as React from 'react'

const SvgCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={80}
    height={80}
    viewBox="0 0 80 80"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M80 40c0 22.091-17.909 40-40 40S0 62.091 0 40 17.909 0 40 0s40 17.909 40 40Z"
    />
  </svg>
)

export default SvgCircle
