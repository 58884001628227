import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {SkeletonLoader} from '@daedalus/atlas/SkeletonLoader'

const SkeletonWrapper = styled.div(
  ({theme}) => css`
    width: 100%;
    display: flex;
    padding: ${theme.layout.spacing.s600}px ${theme.layout.spacing.s500}px;
  `
)

export const MobileMenuUserSectionSkeleton = () => (
  <SkeletonWrapper>
    <SkeletonLoader width={90} height={90} circle />
    <ContentWrapper paddingY="s200" paddingX="s500">
      <SkeletonLoader width={90} height={20} />
      <SkeletonLoader width={150} height={20} />
      <SkeletonLoader width={100} height={20} />
    </ContentWrapper>
  </SkeletonWrapper>
)
