import {createApi} from '@reduxjs/toolkit/query/react'

import {OffersConfigurations} from '../../../api-types/bovio/response/offers_configurations'
import {axiosBaseQuery} from '../../../utils/network/axiosBaseQuery'

export const offerConfigurationApi = createApi({
  reducerPath: 'offerConfigurationApi',
  baseQuery: axiosBaseQuery({
    baseUrl: ''
  }),
  serializeQueryArgs: ({endpointName}) => endpointName,
  endpoints: builder => ({
    getOfferConfiguration: builder.query<
      OffersConfigurations,
      {url: string; headers?: Record<string, string>}
    >({
      keepUnusedDataFor: 0,
      query: ({url, headers}) => ({
        url,
        headers
      })
    })
  })
})

export const {useGetOfferConfigurationQuery} = offerConfigurationApi
