// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPlaneTakeOff = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.202 2.158a2.625 2.625 0 0 1 1.935.057l2.64 1.26 2.34-.85a2.622 2.622 0 0 1 3.358 1.562l.48 1.314a.75.75 0 0 1-.45.963l-4.14 1.503-2.513 4.247c-.309.522-.79.92-1.36 1.128l-1.69.613a.75.75 0 0 1-.974-.923l.92-3.027-.347.126a3.557 3.557 0 0 1-4.556-2.12l-.8-2.19a.75.75 0 0 1 .98-.955l2.353.93 2.047-.742L6.237 3.56a.75.75 0 0 1 .33-1.172l.635-.23ZM8.106 3.5l.807 1.014.885-.322L8.51 3.58a1.125 1.125 0 0 0-.405-.079Zm7.96 1.201a1.122 1.122 0 0 0-1.437-.666L5.623 7.304a.75.75 0 0 1-.532-.008l-1.064-.42.227.621A2.057 2.057 0 0 0 6.89 8.721l1.758-.638a.75.75 0 0 1 .973.924l-.92 3.026.28-.101c.244-.089.45-.26.581-.482l2.65-4.479a.75.75 0 0 1 .39-.323l3.687-1.338-.222-.609ZM2 17.251a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Z"
    />
  </svg>
)

export default SvgPlaneTakeOff
