import React, {createContext, ReactNode, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import axios from 'axios'
import {stringify} from 'qs'

import {selectUserId} from '@daedalus/core/src/auth/modules/selectors'
import {Brand, BrandCode} from '@daedalus/core/src/brand/types'

const axiosInstance = axios.create()

interface ReferralShortLinkParams {
  referralShortLinkUrl: string
  cognitoId?: string
  brand: BrandCode
}

interface ReferralShortLinkResponseData {
  shortUrl: string
}

const generateReferralShortLink = async ({
  referralShortLinkUrl,
  cognitoId,
  brand
}: ReferralShortLinkParams) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const {data} = await axiosInstance.post<ReferralShortLinkResponseData>(
    referralShortLinkUrl,
    JSON.stringify({cognitoId, brand}),
    config
  )

  return data
}

interface ReferralLinkContextType {
  isLoading: boolean
  referralShortLink: string
}

export const ReferralLinkContext = createContext<ReferralLinkContextType>(
  {} as ReferralLinkContextType
)

interface ReferralLinkProviderPropTypes {
  children: ReactNode
  referralShortLinkUrl: string
  brand: Brand
}

const brandUrlDomain = {
  findhotel: 'https://www.findhotel.net',
  vio: 'https://www.vio.com'
}

const getQueryParams = (cognitoId?: string) =>
  stringify({
    referrer: cognitoId,
    susi: true,
    utm_medium: 'referral',
    utm_source: 'sharelink',
    utm_campaign: 'referralMembership'
  })

export const buildReferralUrl = ({
  brand,
  cognitoId
}: {
  brand: BrandCode
  cognitoId?: string
}) =>
  `${
    brandUrlDomain[brand as keyof typeof brandUrlDomain]
  }/user/membership?${getQueryParams(cognitoId)}`

export const ReferralLinkProvider = ({
  children,
  referralShortLinkUrl,
  brand
}: ReferralLinkProviderPropTypes) => {
  const [referralShortLink, setReferralShortLink] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const cognitoId = useSelector(selectUserId)

  useEffect(() => {
    const requestShortLink = async () => {
      setIsLoading(true)

      try {
        const {shortUrl} = await generateReferralShortLink({
          referralShortLinkUrl,
          cognitoId,
          brand: brand.code
        })
        setReferralShortLink(shortUrl)
      } catch (error) {
        const referralLink = buildReferralUrl({brand: brand.code, cognitoId})
        setReferralShortLink(referralLink)
      }

      setIsLoading(false)
    }

    if (cognitoId) {
      requestShortLink()
    }
  }, [cognitoId, brand.code])

  const value = {
    referralShortLink,
    isLoading
  }

  return (
    <ReferralLinkContext.Provider value={value}>
      {children}
    </ReferralLinkContext.Provider>
  )
}
