// This whole implementation is duplicated on src/lib/utils/botDetection.ts as it can't import from core
import {once} from 'ramda'

const WEBSITE_GOOD_BOTS = 'BOT|SPIDER|SLURP|FACEBOOKEXTERNALHIT'
const WEBSITE_GOOD_BOTS_FALSE_POSITIVES = 'CUBOT'

const getFalsePositivesRegex = once(
  () => new RegExp(WEBSITE_GOOD_BOTS_FALSE_POSITIVES)
)
const getBotsRegex = once(() => new RegExp(WEBSITE_GOOD_BOTS))

/**
 * Returns if the user is not a non beneficial bot nor a false positive
 * @param userAgent - user's browser userAgent
 */
export const isGoodBot = (userAgent: string | null | undefined): boolean => {
  if (!userAgent) return false

  if (getFalsePositivesRegex().test(userAgent.toUpperCase())) return false

  return getBotsRegex().test(userAgent.toUpperCase())
}
