// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgTag = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2a.75.75 0 0 0-.52.21L2.843 8.59a2.75 2.75 0 0 0-.1 3.864l4.394 4.682a2.75 2.75 0 0 0 3.946.066l6.696-6.67A.75.75 0 0 0 18 10V2.75a.75.75 0 0 0-.75-.75H10ZM3.882 9.671a1.25 1.25 0 0 0-.045 1.757l4.394 4.681a1.25 1.25 0 0 0 1.793.03L16.5 9.69V3.5h-6.198l-6.42 6.171ZM13 6.001a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
    />
  </svg>
)

export default SvgTag
