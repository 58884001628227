// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgRestaurant = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.515 2.07a.765.765 0 0 0-.807.11l-2.019 1.735c-.317.27-.571.603-.745.978a2.833 2.833 0 0 0-.264 1.197v3.072c-.001.473.118.939.345 1.355.227.417.556.77.957 1.03l1.504.997v4.678c0 .198.08.389.222.529.142.14.334.219.535.219a.772.772 0 0 0 .532-.222.754.754 0 0 0 .225-.526V2.758a.743.743 0 0 0-.485-.688Zm-1.07 8.668-.666-.439a1.333 1.333 0 0 1-.626-1.137V6.09a1.363 1.363 0 0 1 .485-.998l.807-.678v6.324ZM10.318 2a.772.772 0 0 0-.532.222.754.754 0 0 0-.225.526v2.783c.001.532-.17 1.05-.488 1.479a2.523 2.523 0 0 1-1.278.905V2.788a.744.744 0 0 0-.222-.529.762.762 0 0 0-.536-.22.772.772 0 0 0-.532.223.754.754 0 0 0-.225.526v5.127a2.533 2.533 0 0 1-1.277-.907 2.484 2.484 0 0 1-.489-1.477V2.788a.744.744 0 0 0-.222-.529.762.762 0 0 0-.535-.22.772.772 0 0 0-.532.223.754.754 0 0 0-.225.526v2.743c0 .929.327 1.828.926 2.544A4.047 4.047 0 0 0 6.28 9.45v7.8a.754.754 0 0 0 .225.527c.141.14.333.22.532.222.201 0 .394-.079.536-.22a.744.744 0 0 0 .222-.528v-7.8a4.047 4.047 0 0 0 2.354-1.377 3.962 3.962 0 0 0 .926-2.544V2.788a.74.74 0 0 0-.207-.555.757.757 0 0 0-.55-.233Z"
    />
  </svg>
)

export default SvgRestaurant
