// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgGemsLogo = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 36 16"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.082 3.434a.217.217 0 0 0-.423 0l-.336 1.382a.22.22 0 0 1-.16.162l-1.36.34c-.22.056-.22.375 0 .43l1.36.342a.22.22 0 0 1 .16.162l.336 1.381a.217.217 0 0 0 .423 0l.336-1.381a.22.22 0 0 1 .159-.162l1.36-.341c.221-.056.221-.375 0-.43l-1.36-.341a.22.22 0 0 1-.16-.162l-.335-1.382ZM8.6 7.691v4.306H7.436l-.108-1.08c-.371.624-1.247 1.26-2.602 1.26-2.22 0-4.186-1.631-4.186-4.438 0-2.794 2.075-4.426 4.282-4.426 2.063 0 3.382 1.188 3.838 2.591l-1.356.516c-.288-.96-1.127-1.775-2.482-1.775-1.38 0-2.82.983-2.82 3.094 0 2.075 1.344 3.119 2.808 3.119 1.715 0 2.362-1.176 2.434-1.907H4.438V7.69H8.6Zm3.77-.551c-.875 0-1.38.671-1.427 1.295h2.866c-.024-.684-.48-1.295-1.439-1.295Zm.132 3.838c.84 0 1.271-.444 1.463-1.02l1.176.372c-.312 1.02-1.236 1.847-2.639 1.847-1.583 0-2.987-1.151-2.987-3.13 0-1.847 1.368-3.07 2.843-3.07 1.8 0 2.855 1.187 2.855 3.034 0 .228-.024.42-.036.444h-4.27c.036.887.731 1.523 1.595 1.523Zm5.141 1.02V8.53c0-.744.468-1.295 1.212-1.295.791 0 1.127.527 1.127 1.199v3.562h1.367V8.531c0-.72.48-1.295 1.212-1.295.78 0 1.127.515 1.127 1.199v3.562h1.344v-3.79c0-1.547-1.008-2.219-2.063-2.219-.756 0-1.44.264-1.896 1.032-.311-.684-.983-1.032-1.75-1.032-.66 0-1.392.312-1.728.912v-.744h-1.331v5.841h1.38Zm10.76.18c-1.69 0-2.338-1.104-2.41-1.764l1.211-.336c.048.528.444.996 1.187.996.576 0 .876-.312.876-.672 0-.312-.216-.552-.684-.648l-.863-.192c-1.032-.227-1.572-.9-1.572-1.703 0-1.02.936-1.883 2.148-1.883 1.63 0 2.158 1.056 2.254 1.631l-1.175.336c-.048-.336-.3-.875-1.08-.875-.491 0-.84.312-.84.671 0 .312.229.54.589.612l.887.18c1.104.24 1.68.912 1.68 1.775 0 .888-.72 1.871-2.208 1.871Z"
    />
  </svg>
)

export default SvgGemsLogo
