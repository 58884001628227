import React from 'react'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'

import {StyledButtonContent} from '../Button'
import {StyledButtonContent as NewStyledButtonContent} from '../experimental/NewStyleButton'
import {InputButtonStyledContainer} from '../InputButton'

interface Props {
  /** Pass through classname to allow styles overrides */
  className?: string
  /** If the buttons are stacked vertically  */
  isVertical?: boolean
  /** The list of Buttons or InputButtons */
  children?: React.ReactNode
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
}

const ButtonGroupStyled = styled('div')`
  display: flex;
  > button {
    position: relative;
  }
  > button:hover {
    z-index: 1;
  }
  > button:active {
    z-index: 1;
  }
  > button:focus {
    z-index: 2;
  }
  > button
    ${InputButtonStyledContainer},
    > button
    .${StyledButtonContent},
    > button
    .${NewStyledButtonContent} {
    box-shadow: none;
  }
  &.isHorizontal {
    flex-direction: row;

    > button:not(:last-of-type)
      ${InputButtonStyledContainer},
      > button:not(:last-of-type)
      .${StyledButtonContent},
      > button:not(:last-of-type)
      .${NewStyledButtonContent} {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    > button:not(:first-of-type)
      ${InputButtonStyledContainer},
      > button:not(:first-of-type)
      .${StyledButtonContent},
      > button:not(:first-of-type)
      .${NewStyledButtonContent} {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    > button:not(:first-of-type) {
      margin-left: -1px;
    }
  }
  &.isVertical {
    flex-direction: column;
    align-items: flex-start;
    > button:not(:last-of-type)
      ${InputButtonStyledContainer},
      > button:not(:last-of-type)
      .${StyledButtonContent},
      > button:not(:last-of-type)
      .${NewStyledButtonContent} {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    > button:not(:first-of-type)
      ${InputButtonStyledContainer},
      > button:not(:first-of-type)
      .${StyledButtonContent},
      > button:not(:first-of-type)
      .${NewStyledButtonContent} {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    > button:not(:first-of-type) {
      margin-top: -1px;
    }
  }
`

export const ButtonGroup = ({
  isVertical = false,
  children,
  className,
  dataId
}: Props) => {
  return (
    <ButtonGroupStyled
      data-id={dataId}
      className={cx(
        isVertical && 'isVertical',
        !isVertical && 'isHorizontal',
        className
      )}
    >
      {children}
    </ButtonGroupStyled>
  )
}
