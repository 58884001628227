import {pickAll} from 'ramda'
import {HotelRequestType} from 'types/Hotel'

import {UrlCheckoutParamsType} from '@daedalus/core/src/utils/url/types/UrlParams'

const hotelRequestParams = pickAll(['hotelId']) as (
  param: UrlCheckoutParamsType
) => HotelRequestType

export default hotelRequestParams
