import createCache from '@emotion/cache'
import {StylisPlugin} from '@emotion/cache/types'
import {prefixer} from 'stylis'
import rtlPlugin from 'stylis-plugin-rtl'

import stylisMirror, {MIRROR_REGEX} from './stylis-mirror'

// FIXME: won't work with SSR, inject instead
const isRTL =
  typeof document !== 'undefined' &&
  document.documentElement &&
  document.documentElement.getAttribute('dir') === 'rtl'

// Remove /* @mirror */ from output for LTR languages
const dummyMirrorPlugin: StylisPlugin = content => {
  if (MIRROR_REGEX.test(content.value)) {
    return content.value.replace(MIRROR_REGEX, '')
  }
}

const runCreateCache = (isRtl: boolean) => {
  return createCache({
    // prefixer goes at the end
    stylisPlugins: isRtl
      ? [stylisMirror, rtlPlugin, prefixer]
      : [dummyMirrorPlugin, prefixer],
    key: isRtl ? 'ec-rtl' : 'ec' //emotion-cache
  })
}

export const evaluateInRuntime = (isRtl: boolean) => {
  return runCreateCache(isRtl)
}

export default runCreateCache(isRTL)
