// @Todo - After A/B website-2 cleanup old error messages
import {defineMessages} from 'react-intl'
import {path} from 'ramda'

export const getErrorCategory = path(['errorResponse', 'details', 'category'])

export const payment = defineMessages({
  paymentTitle: {
    id: 'paymentTitle',
    defaultMessage: 'Payment details are not correct'
  },
  paymentMessageFirstLine: {
    id: 'paymentMessageFirstLine',
    defaultMessage: "We don't recognize that card number."
  },
  paymentMessageSecondLine: {
    id: 'paymentMessageSecondLine',
    defaultMessage: 'Please check your card details and try again.'
  },
  paymentFailedMessageFirstLine: {
    id: 'paymentFailedMessageFirstLine',
    defaultMessage:
      'Sorry, we could not process your payment. Please try again.'
  },
  requestErrorTitle: {
    id: 'requestErrorTitle',
    defaultMessage: 'Payment failed'
  },
  requestErrorSecondLine: {
    id: 'requestErrorSecondLine',
    defaultMessage:
      'Please try again in a moment or contact our customer support.'
  }
})

export const genericMessages = defineMessages({
  backToForm: {
    id: 'backToForm',
    defaultMessage: 'Back to form'
  },
  customerSupport: {
    id: 'customerSupport',
    defaultMessage: 'Customer Support'
  },
  somethingWrong: {
    id: 'somethingWrong',
    defaultMessage: 'Oops, something went wrong'
  },
  bookingIncompleteMessage: {
    id: 'bookingIncompleteMessage',
    defaultMessage:
      "We couldn't book this stay. Please return to the previous page or contact us for assistance"
  },
  unknownErrorFirstLine: {
    id: 'unknownErrorFirstLine',
    defaultMessage: `Please return to the previous page or contact us for assistance.`
  },
  buttonCallToAction: {
    id: 'buttonCallToAction',
    defaultMessage: `Back to the previous page`
  }
})

export const buttonsMessage = defineMessages({
  backToSearch: {
    id: 'backToSearch',
    defaultMessage: 'Back to search'
  },
  tryAgain: {
    id: 'tryAgain',
    defaultMessage: 'Try Again'
  },
  chooseAnotherRoom: {
    id: 'chooseAnotherRoom',
    defaultMessage: 'Back to previous page'
  }
})

export const providerErrorMessagesFallback = defineMessages({
  soldOutTitle: {
    id: 'soldOutTitle',
    defaultMessage: 'Sold out'
  },
  soldOutMessage: {
    id: 'soldOutMessage',
    defaultMessage:
      'We are sorry! Seems like the offer is not available anymore. Please try choosing another room or hotel.'
  },
  genericError: {
    id: 'unexpectedError',
    defaultMessage: 'An unexpected error happened.'
  },
  priceMismatch: {
    id: 'priceMismatchMessage',
    defaultMessage: `The room price has changed since you started the checkout process, please try again. Don't worry you won't lose the form information.`
  }
})
