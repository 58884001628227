import React from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'

import {Button} from '@daedalus/atlas/Button'
import {Icon} from '@daedalus/atlas/Icon'
import {Spinner} from '@daedalus/atlas/Spinner'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {selectUserId} from '@daedalus/core/src/auth/modules/selectors'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {logAndCaptureErrorMessage} from '@daedalus/core/src/utils/logging/errorHandlers'

interface Props {
  referralShortLink: string
  isLoading: boolean
}

export const ReferralShareButton = ({isLoading, referralShortLink}: Props) => {
  const dispatch = useDispatch()
  const userId = useSelector(selectUserId)
  const intl = useIntl()

  const trackReferralShareClick = () => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.ReferralLinkShare,
        action: Action.Clicked,
        payload: {
          userId,
          referralLink: referralShortLink
        },
        team: Team.Retention
      })
    )
  }

  const trackReferralShareSuccess = () => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.ReferralLinkShare,
        action: Action.Succeeded,
        payload: {
          userId,
          referralLink: referralShortLink
        },
        team: Team.Retention
      })
    )
  }

  const onShare = async () => {
    trackReferralShareClick()
    try {
      await navigator.share({
        url: referralShortLink,
        text: intl.formatMessage({
          id: 'referralProgram.shareLinkText',
          defaultMessage:
            "Vio.com has some amazing accommodation deals. You'll get instant access to the most exclusive discounts if you sign up using my link."
        })
      })
      trackReferralShareSuccess()
    } catch (error) {
      logAndCaptureErrorMessage(error)
    }
  }

  return (
    <Button
      iconStart={isLoading ? null : <Icon name="Share2" />}
      variant="primary"
      size="lg"
      fullWidth
      onClick={onShare}
      disabled={isLoading}
    >
      {isLoading ? (
        <Spinner size={20} />
      ) : (
        <FormattedMessageWrapper
          id="referralProgram.shareLink"
          defaultMessage="Share link"
        />
      )}
    </Button>
  )
}
