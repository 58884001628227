import {RaaOfferType} from '@daedalus/core/src/offer/types/Raa'
export const getRaaOfferContext = (offer: RaaOfferType) => {
  return {
    offerId: offer.id,
    roomName: offer.roomName,
    meals: offer.meals,
    displayPricePerRoomPerNight: offer.nightlyRate,
    offerTags: offer.tags,
    availableRooms: offer.availableRooms,
    canPayLater: offer.canPayLater,
    hasFreeCancellation: offer.hasFreeCancellation,
    isAnchorPriceOffer: offer.isAnchorPriceOffer,
    isCheapest: offer.isCheapest,
    isCheapestRAA: offer.isCheapest,
    isSharedRoom: offer.isSharedRoom,
    isSplitBooking: false,
    isTopOffer: offer.isTopOffer,
    providerCode: offer.providerCode,
    cancellationPolicy: offer.cancellationPolicy,
    cancellationPenalties:
      offer.cancellationPenalties ||
      offer.cancellationPolicy?.cancellationPenalties,
    bookURI: offer.bookURI,
    cug: offer.cug,
    proxyProviderCode: offer.proxyProviderCode
  }
}
