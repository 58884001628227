// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgBaseball = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.682 3.722a6.471 6.471 0 0 0-4.118.251 8.002 8.002 0 0 1-2.687 10.029 6.471 6.471 0 0 0 3.44 2.277c1.42.38 2.855.26 4.12-.253A8.002 8.002 0 0 1 15.123 6a6.471 6.471 0 0 0-3.44-2.277Zm4.223 3.561a6.502 6.502 0 0 0-2.15 8.023 6.474 6.474 0 0 0 2.523-3.624 6.474 6.474 0 0 0-.373-4.399ZM3.721 8.318a6.474 6.474 0 0 1 2.525-3.624 6.502 6.502 0 0 1-2.15 8.024 6.474 6.474 0 0 1-.375-4.4Zm-1.449-.389a8 8 0 1 1 15.455 4.142A8 8 0 0 1 2.273 7.929Z"
    />
  </svg>
)

export default SvgBaseball
