// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgLinkedin = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="M17.205 2H2.695C2.299 2 2 2.3 2 2.7v14.7c0 .3.298.6.696.6h14.608c.398 0 .696-.3.696-.7V2.7c-.1-.4-.398-.7-.795-.7ZM6.671 15.6H4.385V8H6.77v7.6h-.1ZM5.578 7c-.795 0-1.392-.7-1.392-1.4 0-.8.597-1.4 1.392-1.4.795 0 1.39.6 1.39 1.4-.098.7-.695 1.4-1.39 1.4Zm9.938 8.6H13.13v-3.7c0-.9 0-2-1.192-2-1.193 0-1.391 1-1.391 2v3.8H8.16V8h2.286v1c.298-.6 1.093-1.2 2.187-1.2 2.385 0 2.782 1.6 2.782 3.6v4.2h.1Z" />
  </svg>
)

export default SvgLinkedin
