import React from 'react'
import {useIntl} from 'react-intl'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {CurrencyType} from 'types/Currency'

import {Radio} from '@daedalus/atlas/Radio'
import {Text} from '@daedalus/atlas/Text'
import {mq} from '@daedalus/atlas/utils/breakpoints'
import {splitPopularAndOtherCurrencies} from '@daedalus/core/src/localization/business/currency'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {SearchInput} from '../../SearchInput'
import {SelectorList} from '../SelectorList/SelectorList'

const PriceDisplayTitle = styled(Text)(
  ({theme}) => css`
    margin-bottom: ${theme.layout.spacing.s300}px;
  `
)

const PriceDisplayWrapper = styled.div(
  ({theme}) => css`
    padding: 0 ${theme.layout.spacing.s200}px;
    margin-bottom: ${theme.layout.spacing.s600}px;
  `
)

const RadioGroup = styled.div(
  ({theme}) => css`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    ${mq.desktopXs(css`
      flex-direction: row;
      align-items: center;
      gap: ${theme.layout.spacing.s800}px;
    `)}
  `
)

interface Props {
  isMobile?: boolean
  value: string
  onChange: (code: string) => void
  currencies: CurrencyType[]
  onSearchInputFocus?: () => void
  showPriceDisplaySetting?: boolean
  showTotalPrices?: boolean
  onChangePriceDisplay?: (showTotal: boolean) => void
}

const splitPopularAndOther = (items: CurrencyType[]) => {
  const {currenciesList, popularCurrenciesList} =
    splitPopularAndOtherCurrencies(items)

  return {
    itemsList: currenciesList.filter(Boolean),
    popularItemsList: popularCurrenciesList.filter(Boolean)
  }
}

export const CurrencySelector: React.FC<Props> = ({
  isMobile = false,
  currencies,
  onChange,
  value,
  onSearchInputFocus,
  showTotalPrices,
  showPriceDisplaySetting,
  onChangePriceDisplay
}) => {
  const {formatMessage} = useIntl()
  const renderList = (items: CurrencyType[], isSearching = false) => (
    <>
      {showPriceDisplaySetting && !isSearching && (
        <PriceDisplayWrapper>
          <PriceDisplayTitle variant={isMobile ? 'titleM' : 'titleL'}>
            <FormattedMessageWrapper
              id="priceDisplay"
              defaultMessage="Price display"
            />
          </PriceDisplayTitle>
          <RadioGroup>
            <div id="PriceDisplay--nightly">
              <Radio
                hasTouchArea
                fullWidth
                checked={!showTotalPrices}
                name={`reviewScore-any`}
                label={formatMessage({
                  id: 'priceDisplay.nightly',
                  defaultMessage: 'Nightly price incl tax & fees'
                })}
                onChange={() => onChangePriceDisplay?.(false)}
                labelSize="bodyM"
              />
            </div>
            <div id="PriceDisplay--total">
              <Radio
                hasTouchArea
                fullWidth
                checked={Boolean(showTotalPrices)}
                name={`reviewScore-any`}
                label={formatMessage({
                  id: 'priceDisplay.total',
                  defaultMessage: 'Total price incl tax & fees'
                })}
                onChange={() => onChangePriceDisplay?.(true)}
                labelSize="bodyM"
              />
            </div>
          </RadioGroup>
        </PriceDisplayWrapper>
      )}

      <SelectorList
        isMobile={isMobile}
        items={items}
        value={value}
        onChange={onChange}
        getValue={item => item.code}
        popularItemsTitle={formatMessage({
          id: 'popularCurrencies',
          defaultMessage: 'Popular currencies'
        })}
        otherItemsTitle={formatMessage({
          id: 'allCurrencies',
          defaultMessage: 'All currencies'
        })}
        splitPopularAndOther={splitPopularAndOther}
        renderItem={item => (
          <>
            <div>
              <Text variant={isMobile ? 'bodyM' : 'bodyS'}>
                {item.displayName}
              </Text>
              <Text
                variant={isMobile ? 'bodyM' : 'bodyS'}
                colorPath={'content.neutral.c600'}
              >
                {item.code} {item.code !== item.symbol && <>- {item.symbol}</>}
              </Text>
            </div>
          </>
        )}
        isSearching={isSearching}
      />
    </>
  )
  if (isMobile) {
    return (
      <SearchInput
        items={currencies}
        itemMatcher={SearchInput.matchers.default([
          'displayName',
          'code',
          'symbol'
        ])}
        onFocus={onSearchInputFocus}
      >
        {renderList}
      </SearchInput>
    )
  }

  return renderList(currencies)
}
