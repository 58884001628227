// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgDribbble = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.157 4.153A6.474 6.474 0 0 1 10 3.5c1.123 0 2.179.285 3.1.786a13.145 13.145 0 0 1-2.664 2.3 22.437 22.437 0 0 0-3.279-2.433Zm4.386 3.498a14.862 14.862 0 0 0 2.79-2.496 6.507 6.507 0 0 1 1.815 2.73c-1.218.373-2.259.87-3.147 1.44a21.033 21.033 0 0 0-1.458-1.674Zm.25 2.565a19.602 19.602 0 0 0-1.571-1.769c-1.792.941-4.032 1.575-6.72 1.359L3.5 10a6.503 6.503 0 0 0 4.58 6.212 11.744 11.744 0 0 1 1.27-3.093 11.323 11.323 0 0 1 2.443-2.903Zm-2.234 6.27a10.243 10.243 0 0 1 1.084-2.604 9.81 9.81 0 0 1 2.032-2.447 19.288 19.288 0 0 1 1.765 3.312 6.477 6.477 0 0 1-4.881 1.738Zm4.324-5.944a11.14 11.14 0 0 1 2.585-1.186 6.47 6.47 0 0 1-.958 4.093 20.733 20.733 0 0 0-1.627-2.907ZM5.898 5.15a21.022 21.022 0 0 1 3.141 2.208 10.662 10.662 0 0 1-5.32.96A6.501 6.501 0 0 1 5.775 5.06a.753.753 0 0 0 .124.09ZM10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16Z"
    />
  </svg>
)

export default SvgDribbble
