// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgMap = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m6.5 3.904-3 1.333v10.859l3-1.334V3.904Zm.778 12.154-4.223 1.877A.75.75 0 0 1 2 17.25V4.75a.75.75 0 0 1 .445-.685l4.497-1.999a.752.752 0 0 1 .569-.02l5.21 1.896 4.224-1.877A.75.75 0 0 1 18 2.75v12.5a.75.75 0 0 1-.445.685l-4.494 1.997a.748.748 0 0 1-.574.02l-5.209-1.894ZM8 14.725l4 1.454V5.275L8 3.821v10.904Zm5.5 1.37 3-1.332V3.903l-3 1.333v10.859Z"
    />
  </svg>
)

export default SvgMap
