/**
 * This file is segregated from selectors.ts to avoid a missing Crypto dependency on the Mobile app package.
 * Soon, once we solve that dependency issue, we will be able to remove this file and use selectors.ts directly
 */

import {includes} from 'ramda'

import {safeJsonParse} from '../../utils/object'
import {
  CustomAppDataAttributes,
  User,
  UserAttributeIdentities
} from '../types/Cognito'
import {StateType} from './slice'

export enum UserTier {
  Employee = 'employee',
  Plus = 'plus',
  FriendsAndFamily = 'fsf'
}

/**
 * Get stringified Cognito's custom:app_data attribute, and return as object
 */
export const getCognitoCustomAppData = (
  user?: StateType['user']
): CustomAppDataAttributes =>
  safeJsonParse(user?.attributes?.['custom:app_data'])

/**
 * Get the current Cognito user tier.
 * Different tiers can be offered different rates and experiences
 * @param user - Cognito user
 * @returns ['employee', 'plus']
 */
export const getCognitoUserTier = (user?: StateType['user']) =>
  getCognitoCustomAppData(user)?.tier ?? []

export enum Connections {
  google = 'google-oauth2',
  facebook = 'facebook',
  apple = 'apple'
}

export const getConnection = (user?: User) => {
  if (!user?.attributes?.identities) {
    return null
  }

  const identities = safeJsonParse<UserAttributeIdentities[]>(
    user?.attributes?.identities
  )
  const providerName = identities?.[0]?.providerName

  const providerNameMap: Record<string, Connections> = {
    Google: Connections.google,
    signInWithApple: Connections.apple,
    SignInWithApple: Connections.apple,
    Facebook: Connections.facebook
  }

  return providerNameMap[providerName] || null
}

/**
 * Discover if the current user is in the employee tier.
 * @param userTier - User tier, eg: ['employee', 'plus']
 * @returns boolean
 */
export const getIsUserTierEmployee = (userTier: UserTier[]) =>
  includes(UserTier.Employee, userTier) ||
  includes(UserTier.FriendsAndFamily, userTier)

/**
 * Discover if the current user is in the plus tier.
 * @param userTier - User tier, eg: ['employee', 'plus']
 * @returns boolean
 */
export const getIsUserTierPlus = (userTier: UserTier[]) =>
  includes(UserTier.Plus, userTier)
