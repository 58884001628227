import type {CaptureContext, SeverityLevel} from '@sentry/types'

declare let Sentry: typeof import('@sentry/browser')

const captureErrorMessageOnSentry = (
  error: unknown,
  sentryOptions: CaptureContext
) => {
  if (typeof Sentry !== 'undefined') {
    Sentry.captureMessage(
      error as string,
      sentryOptions as {level: SeverityLevel; tags: Record<string, string>}
    )
  }
}

export const logAndCaptureErrorMessage = (
  error: unknown,
  sentryOptions: CaptureContext = {}
): void => {
  console.error(error)
  captureErrorMessageOnSentry(error, sentryOptions)
}
