import React from 'react'

import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {ArrowRow} from '../ArrowButton'
import {IconWrapper, Label, Link, RowWrapper} from '../commonStyles'

interface Props {
  onClick?: () => void | null
}

export const ShareYourFeedbackLink = ({onClick}: Props) => {
  if (!onClick) return null

  return (
    <Link onClick={onClick}>
      <RowWrapper>
        <ArrowRow>
          <Label>
            <IconWrapper>
              <Icon name="Pencil" />
            </IconWrapper>
            <Text variant="labelM">
              <FormattedMessageWrapper
                id="shareYourFeedback"
                defaultMessage="Share your feedback"
              />
            </Text>
          </Label>
        </ArrowRow>
      </RowWrapper>
    </Link>
  )
}
