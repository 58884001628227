import * as React from 'react'
import {useIntl} from 'react-intl'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import addDays from 'date-fns/addDays'
import differenceInDays from 'date-fns/differenceInDays'
import parseISO from 'date-fns/parseISO'
import {toggle} from 'opticks'

import {Button, Variant} from '@daedalus/atlas/Button'
import {Divider} from '@daedalus/atlas/Divider'
import {Popover, PopoverPlacement} from '@daedalus/atlas/Popover'
import {DatePickerType} from '@daedalus/core/src/datePicker/types'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {
  dateFormat,
  dateStringToMiddayDate,
  getEarliestCheckInDate,
  UTS_DATE_FORMAT
} from '@daedalus/core/src/utils/date'

import {BaseDatePicker} from '../DatePicker/BaseDatePicker'
import {
  ColorPriceDatePicker,
  DayInnerContainer
} from '../DatePicker/ColorPriceDatePicker'
import {Legend} from '../DatePicker/components'
import {datePickerMessages} from '../DatePicker/helpers/datePickerMessages'
import {
  HORIZONTAL_PICKER_MAX_WIDTH,
  HORIZONTAL_PICKER_MIN_WIDTH
} from '../DatePicker/styles'
import {SelectedDatesLabel} from '../SearchOverlayFooter/SelectedDatesLabel'

interface Props {
  isOpen: boolean
  anchorRef: React.RefObject<HTMLElement>
  onClose: () => void
  checkIn: string
  checkOut: string
  openedDatePickerType: DatePickerType | null | undefined
  onDOMChange?: () => void
  onMount?: () => void
  onChange: (checkInDate: string, checkOutDate: string) => void
  onDatePickerDayClick?: (name: DatePickerType, value: string) => void
  onDatePickerOpen: (type: DatePickerType) => void
  onSubmit: () => void
  verticalOffset?: number
  horizontalOffset?: number
  placement?: PopoverPlacement
  firstDayOfWeek: number
  months: string[]
  weekdaysShort: string[]
  submitButtonMessage?: React.ReactNode
  showTotalPrices?: boolean
  shouldShowPrices?: boolean
  onMonthView?: (month: Date) => void
  isColorPriceDatePickerEnabled?: boolean
  DayInnerContainer?: DayInnerContainer
}

type FooterProps = Pick<
  Props,
  'checkIn' | 'checkOut' | 'onSubmit' | 'submitButtonMessage'
> & {
  legendSlot?: React.ReactNode
}

const ContentWrapper = styled.div(
  () => css`
    box-sizing: border-box;
    width: 100vw;
    min-width: ${HORIZONTAL_PICKER_MIN_WIDTH}px;
    max-width: ${HORIZONTAL_PICKER_MAX_WIDTH}px;
  `
)

export const FooterWrapper = styled.div(
  ({theme}) => css`
    display: flex;
    align-items: center;
    padding: ${theme.layout.spacing.s400}px ${theme.layout.spacing.s600}px;
  `
)

const ActionsWrapper = styled.div(
  ({theme}) => css`
    display: grid;
    grid-template-columns: auto auto;
    gap: ${theme.layout.spacing.s600}px;
    margin-left: auto;
    align-items: center;
    flex-shrink: 0;
  `
)

const Footer = ({
  checkIn,
  checkOut,
  onSubmit,
  submitButtonMessage = (
    <FormattedMessageWrapper id="search" defaultMessage="Search" />
  ),
  legendSlot
}: FooterProps) => {
  const {formatMessage} = useIntl()

  const numberOfNights =
    checkIn && checkOut
      ? differenceInDays(parseISO(checkOut), parseISO(checkIn))
      : undefined
  const nightsMessage = formatMessage(datePickerMessages.nights, {
    count: numberOfNights
  })

  return (
    <>
      <Divider />
      <FooterWrapper data-id="DatePickerPopOverFooter">
        {!!legendSlot && legendSlot}
        <ActionsWrapper>
          <SelectedDatesLabel checkIn={checkIn} checkOut={checkOut} />
          <Button
            dataId="SearchButton"
            variant={toggle<Variant[]>(
              'b4f4f3cc-website-restyle-v3',
              'primary',
              'primaryGradient'
            )}
            size="md"
            onClick={onSubmit}
          >
            <span>{submitButtonMessage}</span>
            <span>{numberOfNights ? ` · ${nightsMessage}` : ''}</span>
          </Button>
        </ActionsWrapper>
      </FooterWrapper>
    </>
  )
}

export const DatePickerPopover = ({
  isOpen,
  anchorRef,
  onClose,
  checkIn,
  checkOut,
  openedDatePickerType,
  onMount,
  onChange,
  onDatePickerDayClick,
  onDatePickerOpen,
  onDOMChange,
  onSubmit,
  verticalOffset,
  horizontalOffset,
  placement = PopoverPlacement.Center,
  firstDayOfWeek,
  months,
  weekdaysShort,
  submitButtonMessage = (
    <FormattedMessageWrapper id="search" defaultMessage="Search" />
  ),
  showTotalPrices,
  shouldShowPrices,
  onMonthView,
  isColorPriceDatePickerEnabled,
  DayInnerContainer
}: Props) => {
  const containerRef = React.useRef<null | HTMLDivElement>(null)
  const earliestCheckInDate = getEarliestCheckInDate()

  const isColorPriceDatePicker =
    isColorPriceDatePickerEnabled &&
    isOpen &&
    toggle('a8fj1jd3-color-price-calendar-2', false, true)

  const handleSubmit = () => {
    if (checkIn && !checkOut) {
      const checkInDate = dateStringToMiddayDate(checkIn)
      const checkOut = dateFormat(addDays(checkInDate, 1), UTS_DATE_FORMAT)

      onChange(checkIn, checkOut)
    }

    onSubmit()
  }

  return (
    <Popover
      isOpen={isOpen}
      placement={placement}
      anchorRef={anchorRef}
      verticalOffset={verticalOffset}
      horizontalOffset={horizontalOffset}
      onOutsideClick={onClose}
    >
      <ContentWrapper>
        {isColorPriceDatePicker ? (
          <ColorPriceDatePicker
            containerRef={containerRef}
            openedDatePickerType={openedDatePickerType}
            numberOfMonthsToShow={2}
            firstDayOfWeek={firstDayOfWeek}
            weekdaysShort={weekdaysShort}
            checkIn={checkIn}
            checkOut={checkOut}
            months={months}
            variant="horizontal"
            earliestCheckInDate={earliestCheckInDate}
            onDOMChange={onDOMChange}
            onMount={onMount}
            onChange={onChange}
            onDayClick={onDatePickerDayClick}
            onDatePickerOpen={onDatePickerOpen}
            showTotalPrices={showTotalPrices}
            shouldShowPrices={shouldShowPrices}
            onMonthView={onMonthView}
            DayInnerContainer={DayInnerContainer}
          />
        ) : (
          <BaseDatePicker
            containerRef={containerRef}
            openedDatePickerType={openedDatePickerType}
            numberOfMonthsToShow={2}
            firstDayOfWeek={firstDayOfWeek}
            weekdaysShort={weekdaysShort}
            checkIn={checkIn}
            checkOut={checkOut}
            months={months}
            variant="horizontal"
            earliestCheckInDate={earliestCheckInDate}
            onDOMChange={onDOMChange}
            onMount={onMount}
            onChange={onChange}
            onDayClick={onDatePickerDayClick}
            onDatePickerOpen={onDatePickerOpen}
          />
        )}
        <Footer
          checkIn={checkIn}
          checkOut={checkOut}
          onSubmit={handleSubmit}
          submitButtonMessage={submitButtonMessage}
          legendSlot={isColorPriceDatePicker ? <Legend /> : undefined}
        />
      </ContentWrapper>
    </Popover>
  )
}
