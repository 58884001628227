import {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import useParams from 'components/data/URLParams/useParams'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'

export enum BackButtonMountType {
  default = 'default',
  soldOut = 'soldOut',
  error = 'somethingWentWrong'
}
export const TRACKING_TIMEOUT_BEFORE_NAVIGATING_BACK = 500

export const useHandleBackButtonClick = () => {
  const urlParams = useParams()
  const dispatch = useDispatch()

  const handleBackButtonClick = useCallback(
    (type: BackButtonMountType = BackButtonMountType.default) => {
      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.BackButton,
          action: Action.Clicked,
          payload: {type}
        })
      )
      setTimeout(() => {
        history.back()
        return
      }, TRACKING_TIMEOUT_BEFORE_NAVIGATING_BACK)
    },
    [urlParams]
  )

  return handleBackButtonClick
}
