import {createSelector} from '@reduxjs/toolkit'
import {includes, pathOr} from 'ramda'

import {getBrand, getBrandShowSignIn} from '../../_web/brand/modules/selectors'
import {
  FRIENDS_AND_FAMILY_CUG_TYPE,
  isWeekendPromoOffer,
  OFFLINE_CUG_TYPE,
  PLUS_CUG_TYPE,
  PlusTiers,
  SENSITIVE_CUG_TYPE,
  SIGNED_IN_CUG_TYPE,
  UserTier
} from '../../offer/business/privateDeals'
import {GenericOffer} from '../../offer/types/offer'
import {safeJsonParse} from '../../utils/object'
import {getCognitoUserTierFromCookie} from '../business/persistData'
import {getUserPoolName} from '../services'
import {
  User,
  UserAttributeIdentities,
  UserIdentifiableTraits
} from '../types/Cognito'
import {
  Connections,
  getCognitoCustomAppData,
  getCognitoUserTier,
  getConnection,
  getIsUserTierEmployee
} from './selectors.shared'
import {StateType} from './slice'
import {updateUserAttributes} from './thunks'

const AUTHENTICATION_PATH = 'authentication'

export type AuthenticationStateType = {
  authentication: StateType
}

/**
 * Returns the stored user id
 */
export const getUserId: (user?: StateType['user']) => string | null = pathOr(
  null,
  ['attributes', 'sub']
)

/**
 * Selector that returns the user's authentication error
 * @param state - the redux state holding the authentication info
 */
const getAuthenticationError = (state: AuthenticationStateType) =>
  state[AUTHENTICATION_PATH].error

export const selectAuthenticationError = createSelector(
  getAuthenticationError,
  error => error
)
/**
 * Returns whether the user is member plus or not
 * @param user - Cognito user
 */
export const getCognitoIsMemberPlus = (user?: StateType['user']) =>
  hasPlusTier(getCognitoUserTier(user)) || false

/**
 * Checks whether the user has any plus tier (is a plus member or not)
 * @param userTier - The user tier values from Cognito
 * @returns boolean
 */
const hasPlusTier = (userTier: UserTier[] | []) =>
  userTier.some(tier => PlusTiers.includes(tier))

/**
 * Selector that returns whether the UI visibility state of the passwordless login overlay
 * @param state - the redux state holding the authentication info
 */
export const isPasswordlessLoginVisible = (state: AuthenticationStateType) =>
  state.authentication?.isPasswordlessLoginVisible

/**
 * Selector that returns passwordless overlay source component parameter
 * @param state - the redux state holding the authentication info
 */
export const getPasswordlessLoginSourceComponent = (
  state: AuthenticationStateType
) => state.authentication?.sourceComponent

/**
 * Get Cognito User Id
 */
export const getCognitoUserId = (user?: User | null) => user?.attributes?.sub

/**
 * Get auth0_id from migrated users
 * @param user - Cognito user
 * @returns string
 */
export const getUserLegacyId = (user?: User) => {
  const customAppData = getCognitoCustomAppData(user)
  return customAppData?.auth0_id || null
}

/**
 * Get the current user tier.
 * Different tiers can be offered different rates and experiences
 * @param user - Cognito user
 * @returns ['employee', 'plus']
 */
export const getUserTier = (user?: User | null) =>
  getCognitoCustomAppData(user)?.tier || []

/**
 * Returns which {@link AuthenticationFlows | authentication flow} the user is trying to use
 * @param state - the redux state holding the authentication info
 */
export const getAuthenticationFlow = (state: AuthenticationStateType) =>
  state[AUTHENTICATION_PATH].flow

/**
 * Returns the hotelId where the user clicked to unlock the offer
 * @param state - the redux state holding the authentication info
 */
export const getUnlockHotelId = (state: AuthenticationStateType) =>
  state[AUTHENTICATION_PATH].unlockHotelId

/**
 * Selector that returns if the user is privileged
 * @param state - the redux state holding the authentication info
 */
export const getShouldSeeOffersUnlocked = (state: AuthenticationStateType) =>
  state[AUTHENTICATION_PATH].shouldSeeOffersUnlocked

export const selectShouldSeeOffersUnlocked = createSelector(
  getShouldSeeOffersUnlocked,
  shouldSeeOffersUnlocked => shouldSeeOffersUnlocked
)

/**
 * Selector that returns if the user is authenticated
 * @param state - the redux state holding the authentication info
 */
export const getIsAuthenticated = (state: AuthenticationStateType) =>
  state[AUTHENTICATION_PATH].isAuthenticated

export const selectIsAuthenticated = createSelector(
  getIsAuthenticated,
  isAuthenticated => isAuthenticated
)

/**
 * Selector that returns the user
 * @param state - the redux state holding the authentication info
 */
export const getUser = (state: AuthenticationStateType) =>
  state[AUTHENTICATION_PATH].user

/**
 * Selector that returns the loading state
 * @param state - the redux state holding the authentication info
 */
export const getIsLoading = (state: AuthenticationStateType) =>
  state[AUTHENTICATION_PATH].isLoading

export const selectIsLoading = createSelector(
  getIsLoading,
  isLoading => isLoading
)

export const selectUserId = createSelector(getUser, getCognitoUserId)

export const getUserEmail = (user?: User | null) => user?.attributes?.email

export const selectUserEmail = createSelector(getUser, getUserEmail)

export const getCognitoHasPlusTier = (userTier: UserTier[] | []) =>
  userTier.some(tier => PlusTiers.includes(tier))

/**
 * Selector that returns the cognito user
 * @param state - the redux state holding the authentication info
 */
export const getCognitoUserAttributes = (state: AuthenticationStateType) =>
  state[AUTHENTICATION_PATH]?.user?.attributes

/**
 * In order to show correct success or error message in the account update form, we keep track of the status of the action that was performed.
 * This is related to the action which is performed by Cognito.
 * @param state - the redux state holding the authentication info
 */
export const getLastAction = (state: AuthenticationStateType) =>
  state[AUTHENTICATION_PATH]?.lastAction

export const getUserProfileUpdatePending = (state: AuthenticationStateType) =>
  getLastAction(state) === updateUserAttributes.pending.type

export const selectUser = createSelector(getUser, user => user)

export const selectCognitoUserTier = createSelector(getUser, user =>
  getCognitoUserTier(user)
)

export const getUserIdentifiableTraits = (
  state: AuthenticationStateType
): UserIdentifiableTraits | null => {
  const user = state?.authentication?.user
  if (!user) return null

  const legacy_id = getUserLegacyId(user)
  const tier = getCognitoUserTier(user)
  const userPoolName = getUserPoolName(state?.authentication?.userPoolId)

  return {
    legacy_id,
    tier,
    userPoolName
  }
}

export const getAuthenticationMethod = (state: AuthenticationStateType) => {
  const providerName = safeJsonParse<UserAttributeIdentities[]>(
    state?.authentication?.user?.attributes?.identities
  )?.[0]?.providerName
  if (providerName === 'SignInWithApple') return Connections.apple

  return providerName || Connections.google
}

// @todo - move getIsRtlLanguage to core/localization
interface MetaState {
  userCountryCode: string
  languageCode: string
  isRTLLanguage: boolean
}

export interface State {
  meta: MetaState
}

export const getIsRtlLanguage = (state: State): boolean =>
  Boolean(state.meta.isRTLLanguage)

/**
 * Returns whether the auth is enabled for the current brand.
 * It is based on whether the brand is a meta to meta brand or not.
 */
export const selectAuthEnabled = createSelector(
  getBrandShowSignIn,
  getBrandShowSignIn => getBrandShowSignIn
)

export const selectAccountUrl = createSelector(
  getBrand,
  brand => `${brand.address}/user/account`
)

export const getIsEmployee = (user?: User | null) => {
  const userTierFromCookie = getCognitoUserTierFromCookie()
  const userTierFromMetaData = (user && getUserTier(user)) || []
  const userTier =
    userTierFromCookie.length > 0 ? userTierFromCookie : userTierFromMetaData

  return getIsUserTierEmployee(userTier)
}

export const selectIsEmployee = createSelector(selectUser, getIsEmployee)

export const getIsMemberPlus = () =>
  hasPlusTier(getCognitoUserTierFromCookie() as []) || false

export const selectIsMemberPlus = createSelector(
  selectCognitoUserTier,
  userTier => hasPlusTier(userTier) || getIsMemberPlus()
)

/**
 *
 * Selector that returns if an offer is private or not, based on the offer accessTier and user state.
 * Keep the logic synced with isPrivateDeal.
 */
export const getIsPrivateDeal =
  (offer?: GenericOffer | null) =>
  (state: AuthenticationStateType & State): boolean => {
    if (!offer) return false

    if (isWeekendPromoOffer(offer)) return getShouldSeeOffersUnlocked(state)

    const accessTier = offer?.accessTier || offer?.cug?.[0] || ''

    return includes(accessTier, [
      OFFLINE_CUG_TYPE,
      SIGNED_IN_CUG_TYPE,
      SENSITIVE_CUG_TYPE,
      PLUS_CUG_TYPE,
      FRIENDS_AND_FAMILY_CUG_TYPE
    ])
  }

export const selectIsPrivateDeal = createSelector(
  (state: AuthenticationStateType & State) => (offer: GenericOffer) =>
    getIsPrivateDeal(offer)(state),
  isPrivateDeal => isPrivateDeal
)

export const getIsLockedDeal =
  (offer?: GenericOffer | null) =>
  (state: AuthenticationStateType & State): boolean =>
    !getShouldSeeOffersUnlocked(state) && getIsPrivateDeal(offer)(state)

export {Connections, getCognitoUserTier, getConnection}
