// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgLanguage = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 2a.75.75 0 0 1 .75.75V4h3.5a.75.75 0 0 1 0 1.5H9.931a7.78 7.78 0 0 1-.21.956 7.661 7.661 0 0 1-1.963 3.346 6.398 6.398 0 0 0 2.158.672 4.248 4.248 0 0 0 .325.026h.011c.001 0 0 0 0 0h-.001a.75.75 0 0 1-.001 1.5v-.75.75h-.048l-.104-.005a5.745 5.745 0 0 1-.358-.031 7.825 7.825 0 0 1-3.24-1.157 7.825 7.825 0 0 1-3.24 1.157 5.748 5.748 0 0 1-.462.035h-.031l-.01.001H2.75l-.001-.75.001.75a.751.751 0 0 1-.004-1.5h.012l.062-.003c.058-.003.147-.01.263-.023a6.398 6.398 0 0 0 2.158-.672 7.645 7.645 0 0 1-.022-.022c-.273-.273-.565-.72-.76-1.039a15.777 15.777 0 0 1-.34-.579l-.02-.039-.006-.01-.003-.005a.75.75 0 0 1 1.318-.716v.002l.005.007.018.033a12.753 12.753 0 0 0 .305.522c.203.33.412.632.543.764.073.072.146.142.22.209a6.162 6.162 0 0 0 1.779-2.885c.056-.198.099-.381.13-.544H2.75a.75.75 0 0 1 0-1.5h3.5V2.75A.75.75 0 0 1 7 2Zm7 8a.75.75 0 0 1 .67.415l3.25 6.5a.75.75 0 1 1-1.34.67L15.786 16h-3.574l-.792 1.585a.75.75 0 1 1-1.342-.67l3.25-6.5A.75.75 0 0 1 14 10Zm-1.037 4.5h2.074L14 12.427 12.963 14.5Z"
    />
  </svg>
)

export default SvgLanguage
