// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgDoubleChatBubble = ({
  color = 'currentColor',
  size = 20
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.802 4.505C4.03 5.147 3.5 6.147 3.5 7.63c0 1.098.29 1.923.74 2.53.251.341.288.778.281 1.06-.007.302-.072.664-.227.951-.019.036-.017.054-.015.063a.163.163 0 0 0 .042.08.29.29 0 0 0 .281.088c.566-.13 1.136-.3 1.618-.518a1.965 1.965 0 0 1 1.03-.165c.242.027.49.041.742.041 1.271 0 2.396-.345 3.189-1.005.772-.642 1.302-1.642 1.302-3.125s-.53-2.483-1.302-3.125C10.388 3.845 9.263 3.5 7.99 3.5c-1.271 0-2.396.345-3.189 1.005ZM3.843 3.35C4.973 2.411 6.468 2 7.992 2c1.523 0 3.019.412 4.148 1.351 1.15.957 1.843 2.397 1.843 4.279 0 1.882-.693 3.323-1.843 4.279-1.13.94-2.625 1.351-4.148 1.351-.307 0-.61-.017-.91-.05a.464.464 0 0 0-.243.04c-.607.275-1.281.472-1.902.614a1.79 1.79 0 0 1-1.738-.556c-.41-.463-.589-1.174-.225-1.849 0 .001 0 0 0 0 .002-.005.013-.031.025-.083a1.03 1.03 0 0 0 .023-.193.716.716 0 0 0-.013-.164C2.36 10.125 2 8.988 2 7.63c0-1.882.693-3.322 1.843-4.279Zm-.839 7.652.001.002v-.002Zm12.75-3.085a.75.75 0 0 1 1.055.108c.75.922 1.174 2.132 1.174 3.604 0 1.358-.36 2.495-1.009 3.389l-.001.005a.716.716 0 0 0-.012.159c.002.068.01.136.023.193a.475.475 0 0 0 .025.083c.363.675.186 1.387-.225 1.85a1.79 1.79 0 0 1-1.738.555c-.62-.142-1.295-.34-1.902-.614a.464.464 0 0 0-.243-.04c-.3.034-.603.05-.91.05-1.413 0-2.8-.354-3.896-1.154a.75.75 0 0 1 .885-1.212c.779.57 1.833.866 3.011.866.253 0 .5-.014.741-.04.356-.041.712.019 1.031.163.482.22 1.052.39 1.618.52a.29.29 0 0 0 .28-.09.163.163 0 0 0 .043-.079c.002-.01.004-.028-.015-.063-.155-.287-.22-.649-.227-.952-.007-.281.03-.717.281-1.058.45-.608.74-1.433.74-2.531 0-1.173-.332-2.036-.837-2.656a.75.75 0 0 1 .108-1.056Zm1.225 7.085-.001.002v-.002Z"
    />
  </svg>
)

export default SvgDoubleChatBubble
