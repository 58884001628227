// TODO (Core): This import will be fixed when the host file is migrated
import {getAnonymousId} from '../../_web/anonymousId'
import {parseQueryString} from '../url'
import {getPersistedSapiLabel} from '.'
import {generateEncodedSapiLabel} from './generateSapiLabel'

/**
 * Get an encoded sapiLabel from browser context (landing query string, cookies)
 */
export const generateSapiLabelFromBrowserContext = (
  landingQueryString: string,
  partnerId: string | undefined
) => {
  const anonymousId = getAnonymousId()
  const hasPersistedSapiLabel = !!getPersistedSapiLabel()
  const landingParameters = parseQueryString(landingQueryString) as Record<
    string,
    string
  >

  if (!anonymousId) return // type guard

  return generateEncodedSapiLabel({
    landingParameters,
    anonymousId,
    partnerId,
    hasPersistedSapiLabel
  })
}
