// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgBellCheck = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.258 2.593a.9.9 0 0 1 .15 1.264l-5.2 6.6a.9.9 0 0 1-1.349.074l-2.6-2.64a.9.9 0 0 1 1.283-1.263l1.883 1.913 4.569-5.798a.9.9 0 0 1 1.264-.15ZM12 2.4a7.2 7.2 0 0 0-7.2 7.2v2.199l-2.314 4.918A.9.9 0 0 0 3.3 18h17.4a.9.9 0 0 0 .815-1.282l-1.97-4.2a.9.9 0 1 0-1.629.764l1.368 2.918H4.72l1.796-3.817A.9.9 0 0 0 6.6 12V9.6A5.4 5.4 0 0 1 12 4.2a.9.9 0 0 0 0-1.8ZM8.26 18a4.856 4.856 0 0 0 .466 1.663c.515 1.03 1.514 1.937 3.268 1.937 1.754 0 2.753-.906 3.268-1.937A4.704 4.704 0 0 0 15.728 18H14.21a2.667 2.667 0 0 1-.234.737c-.235.47-.736 1.063-1.982 1.063s-1.747-.594-1.982-1.063c-.13-.26-.199-.528-.234-.737H8.26Z"
    />
  </svg>
)

export default SvgBellCheck
