import {pickAll, pipe} from 'ramda'
import renamePropName from 'utils/renamePropName'

import {
  UrlCheckoutParamsType,
  UrlSearchParamsType
} from '@daedalus/core/src/utils/url/types/UrlParams'

const paramsKeys = [
  'hotelId',
  'checkIn',
  'checkOut',
  'rooms',
  'providerCode',
  'oid',
  'baseRate',
  'localTax',
  'tax'
] as const

const searchWebsiteParams = pipe(
  pickAll(paramsKeys),
  renamePropName('currency', 'curr'),
  renamePropName('locale', 'lang')
) as unknown as (params: UrlCheckoutParamsType) => UrlSearchParamsType

export default searchWebsiteParams
