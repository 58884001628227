import {CommonErrorPayloadFormatType} from 'types/errorResponse'

import {redirectUsersFromCallbackUrl} from '@daedalus/core/src/auth/modules/thunks'
import {bookingRetrieveApi} from '@daedalus/core/src/booking/services/bookingRetrieveApi'
import {offerCheckApi} from '@daedalus/core/src/offer/services/offerCheckApi'

import {ModuleActionType} from './actions'
import {CLEAR_ERROR, ERROR, ErrorActionType} from './actions/error'
import {FATAL_ERROR} from './actions/fatalError'
import {
  closeOfferCheckModal,
  openOfferCheckModal
} from './actions/offerCheckLoading'
import {CLOSE_SEARCH_BOX, OPEN_SEARCH_BOX} from './actions/searchBox'

export type CommonStateType = {
  error: CommonErrorPayloadFormatType | null | undefined
  isSearchBoxVisible: boolean
  moduleState?: string
  isOfferCheckModalVisible: boolean
  httpRequestId?: string
}

export const initialState = {
  error: null,
  isSearchBoxVisible: false,
  isOfferCheckModalVisible: false
}

const reducer = (
  state: CommonStateType = initialState,
  action: ModuleActionType
) => {
  if (offerCheckApi.endpoints.getOfferCheck.matchFulfilled(action)) {
    return {
      ...state,
      httpRequestId: action.payload.httpRequestId
    }
  }

  if (offerCheckApi.endpoints.getOfferCheck.matchRejected(action)) {
    return {
      ...state,
      httpRequestId: action?.payload?.headers?.['x-request-id']
    }
  }

  if (
    offerCheckApi.endpoints.getSplitBookingOfferCheck.matchFulfilled(action)
  ) {
    return {
      ...state,
      httpRequestId: action.payload.httpRequestId
    }
  }

  if (offerCheckApi.endpoints.getSplitBookingOfferCheck.matchRejected(action)) {
    return {
      ...state,
      httpRequestId: action?.payload?.headers?.['x-request-id']
    }
  }

  if (bookingRetrieveApi.endpoints.getBookingById.matchFulfilled(action)) {
    return {
      ...state,
      httpRequestId: action.payload?.httpRequestId
    }
  }
  if (bookingRetrieveApi.endpoints.getBookingById.matchRejected(action)) {
    return {
      ...state,
      httpRequestId: action?.payload?.headers?.['x-request-id']
    }
  }
  switch (action.type) {
    case FATAL_ERROR:
    case ERROR: {
      return {
        ...state,
        error: (action as ErrorActionType).error,
        moduleState: action.type
      }
    }

    case CLEAR_ERROR: {
      return {
        ...state,
        error: null,
        moduleState: action.type
      }
    }

    case OPEN_SEARCH_BOX: {
      return {
        ...state,
        isSearchBoxVisible: true,
        moduleState: action.type
      }
    }

    case redirectUsersFromCallbackUrl.fulfilled.type:
    case CLOSE_SEARCH_BOX: {
      return {
        ...state,
        isSearchBoxVisible: false,
        moduleState: action.type
      }
    }

    case openOfferCheckModal.type: {
      return {
        ...state,
        isOfferCheckModalVisible: true,
        moduleState: action.type
      }
    }

    case closeOfferCheckModal.type: {
      return {
        ...state,
        isOfferCheckModalVisible: false,
        moduleState: action.type
      }
    }

    default: {
      return state
    }
  }
}

export default reducer
