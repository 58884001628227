import {State} from '../../auth/modules/selectors'

// TODO: (@Search) - Rename this to something like languageToFlagCodeMapping after 15b632cb-rebrand-search as it is only used on the flag component
// By renaming this to flag code, we need to change other places from countryCode to flagCode which I think it requires a seprate story
const languageToCountryCodeMapping: Record<string, string> = {
  ar: 'ar-arl',
  cs: 'cz',
  da: 'dk',
  de: 'de',
  en: 'us',
  'en-GB': 'gb',
  es: 'es',
  fi: 'fi',
  fr: 'fr',
  he: 'il',
  hu: 'hu',
  id: 'id',
  it: 'it',
  ja: 'jp',
  ko: 'kr',
  ms: 'my',
  nl: 'nl',
  no: 'no',
  pl: 'pl',
  'pt-BR': 'br',
  pt: 'pt',
  ro: 'ro',
  ru: 'ru',
  sv: 'se',
  th: 'th',
  tr: 'tr',
  uk: 'ua',
  'zh-HK': 'hk',
  'zh-TW': 'tw',
  zh: 'cn',
  // New country codes added as part of 2a98dd4d-add-new-languages
  bn: 'in',
  fil: 'ph',
  'fr-CA': 'ca',
  el: 'gr',
  hi: 'in',
  mr: 'in',
  vi: 'vi'
  // 'es-AR': 'ar',
  // 'es-CL': 'cl',
  // 'es-CO': 'co',
  // 'es-CR': 'cr',
  // 'es-EC': 'ec',
  // 'es-GT': 'gt',
  // 'es-MX': 'mx',
  // 'es-PA': 'pa',
  // 'es-PY': 'py',
  // 'es-PE': 'pe',
  // 'es-PR': 'pr',
  // 'es-DO': 'do',
  // 'es-UY': 'uy',
  // 'es-VE': 've'
}

/**
 * Returns the mapped country code for the specified language code.
 *
 * @param languageCode
 * @returns Matching country code
 */
export const getCountryCodeFromLanguage = (languageCode: string) =>
  languageToCountryCodeMapping[languageCode]

export const countriesThatUse12HourFormat = new Set([
  'US',
  'CA',
  'NZ',
  'AU',
  'PH'
])

export const getCountryCode = (state: State) => state.meta.userCountryCode
