import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface Psd2AuthState {
  moduleState: string
  error?: Psd2AuthErrorType
  errorType?: Psd2AuthErrorTypes
  showError: boolean
}

export type Psd2AuthErrorType = {
  statusCode?: string
  message?: string
}

export enum Psd2AuthErrorTypes {
  INITIALIZATION = 'INITIALIZATION',
  RECOVERABLE = 'RECOVERABLE'
}

export const initialState: Psd2AuthState = {
  moduleState: '',
  error: undefined,
  errorType: undefined,
  showError: false
}

export const startPsd2AuthChallenge = createAction('psd2Auth/startChallenge')

const {actions, reducer} = createSlice({
  name: 'psd2Auth',
  initialState,
  reducers: {
    psd2AuthInitialization: (
      state: Psd2AuthState,
      action: PayloadAction<string | undefined>
    ) => {
      state.error = undefined
      state.moduleState = action.type
    },
    psd2AuthSuccess: (
      state: Psd2AuthState,
      action: PayloadAction<string | undefined>
    ) => {
      state.error = undefined
      state.moduleState = action.type
    },
    psd2AuthAborted: (state: Psd2AuthState, action: PayloadAction<string>) => {
      state.moduleState = action.type
    },
    psd2AuthChallenge: (
      state: Psd2AuthState,
      action: PayloadAction<string | undefined>
    ) => {
      state.moduleState = action.type
    },
    psd2AuthError: (
      state: Psd2AuthState,
      action: PayloadAction<Psd2AuthErrorType>
    ) => {
      state.moduleState = action.type
      state.error = action.payload
      state.errorType = Psd2AuthErrorTypes.RECOVERABLE
    },
    psd2AuthInitializationError: (
      state: Psd2AuthState,
      action: PayloadAction<Psd2AuthErrorType | undefined>
    ) => {
      state.moduleState = action.type
      state.error = action.payload
      state.errorType = Psd2AuthErrorTypes.INITIALIZATION
    },
    psd2AuthReset: (state: Psd2AuthState) => {
      state.moduleState = ''
      state.error = undefined
      state.errorType = undefined
      state.showError = false
    }
  }
})

export const {
  psd2AuthInitialization,
  psd2AuthSuccess,
  psd2AuthAborted,
  psd2AuthError,
  psd2AuthChallenge,
  psd2AuthInitializationError,
  psd2AuthReset
} = actions

export default reducer
