// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgRefresh3 = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.252 2.193a.75.75 0 0 0-1.004 1.114l1.048.943H8.75A6.75 6.75 0 0 0 2 11v.25a.75.75 0 0 0 1.5 0V11c0-2.9 2.35-5.25 5.25-5.25h2.546l-1.048.943a.75.75 0 1 0 1.004 1.114l2.5-2.25a.75.75 0 0 0 0-1.114l-2.5-2.25ZM18 8.75a.75.75 0 0 0-1.5 0V9c0 2.9-2.35 5.25-5.25 5.25H8.704l1.048-.943a.75.75 0 0 0-1.004-1.114l-2.5 2.25a.75.75 0 0 0 0 1.114l2.5 2.25a.75.75 0 1 0 1.004-1.114l-1.048-.943h2.546A6.75 6.75 0 0 0 18 9v-.25Z"
    />
  </svg>
)

export default SvgRefresh3
