import {createContext, useContext} from 'react'

type MetaContextType = {
  anonymousId?: string
}

const MetaContext = createContext<MetaContextType>({})

export const MetaContextProvider = MetaContext.Provider

export const useMetaContext = () => {
  const context = useContext(MetaContext)
  if (!context)
    throw new Error(`useMetaContext must be used inside MetaContextProvider`)
  return context
}
