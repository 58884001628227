import {styled} from '@linaria/react'

export const NoStyleButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none;
  cursor: pointer;
`
