import * as React from 'react'
export const Snowflake = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={30}
    fill="none"
    {...props}
  >
    <path
      fill="#2E51BE"
      fillRule="evenodd"
      d="M13 0a1.49 1.49 0 0 1 1.478 1.5v1.442l.967-.518a1.467 1.467 0 0 1 1.998.625c.381.732.105 1.64-.616 2.027l-2.349 1.26v2.853c1.057.276 2 .841 2.74 1.607l2.437-1.427-.099-2.694a1.491 1.491 0 0 1 1.422-1.555 1.487 1.487 0 0 1 1.532 1.443l.041 1.109 1.231-.721a1.466 1.466 0 0 1 2.02.549 1.512 1.512 0 0 1-.541 2.049l-1.232.72.927.591c.69.44.9 1.367.465 2.068a1.466 1.466 0 0 1-2.038.472l-2.25-1.433-2.434 1.426c.14.511.214 1.05.214 1.607 0 .556-.075 1.096-.215 1.607l2.436 1.426 2.249-1.434a1.466 1.466 0 0 1 2.038.473 1.513 1.513 0 0 1-.465 2.068l-.926.59 1.23.721c.708.414.95 1.332.542 2.05a1.467 1.467 0 0 1-2.02.548l-1.23-.72-.041 1.108a1.488 1.488 0 0 1-1.533 1.443 1.491 1.491 0 0 1-1.422-1.555l.1-2.694-2.437-1.427a5.889 5.889 0 0 1-2.74 1.607v2.853l2.348 1.26c.721.387.997 1.295.616 2.027a1.467 1.467 0 0 1-1.998.625l-.967-.518V28.5c0 .828-.662 1.5-1.478 1.5a1.49 1.49 0 0 1-1.478-1.5v-1.442l-.967.518a1.468 1.468 0 0 1-1.998-.625 1.511 1.511 0 0 1 .616-2.027l2.349-1.26v-2.853a5.889 5.889 0 0 1-2.741-1.607L6.345 20.63l.099 2.693a1.491 1.491 0 0 1-1.422 1.555 1.487 1.487 0 0 1-1.533-1.443l-.04-1.108-1.231.72a1.466 1.466 0 0 1-2.02-.548 1.512 1.512 0 0 1 .541-2.05l1.231-.72-.926-.59A1.513 1.513 0 0 1 .58 17.07a1.466 1.466 0 0 1 2.038-.472l2.25 1.434 2.435-1.427A6.089 6.089 0 0 1 7.087 15c0-.556.075-1.095.215-1.606l-2.436-1.427-2.249 1.434c-.691.44-1.604.23-2.038-.472a1.513 1.513 0 0 1 .466-2.068l.925-.59-1.23-.722A1.512 1.512 0 0 1 .197 7.5a1.467 1.467 0 0 1 2.02-.549l1.23.721.041-1.108A1.487 1.487 0 0 1 5.022 5.12c.816.03 1.452.727 1.422 1.555l-.1 2.693 2.437 1.427a5.887 5.887 0 0 1 2.74-1.607V6.336l-2.348-1.26a1.511 1.511 0 0 1-.616-2.027 1.468 1.468 0 0 1 1.998-.625l.967.518V1.5c0-.828.662-1.5 1.478-1.5Zm0 18c1.633 0 2.956-1.343 2.956-3S14.633 12 13 12s-2.956 1.343-2.956 3 1.323 3 2.956 3Z"
      clipRule="evenodd"
    />
  </svg>
)
