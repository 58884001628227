// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgMail = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.75 3A2.75 2.75 0 0 0 2 5.75v8.5A2.75 2.75 0 0 0 4.75 17h10.5A2.75 2.75 0 0 0 18 14.25v-8.5A2.75 2.75 0 0 0 15.25 3H4.75Zm-.112 1.505c.037-.003.074-.005.112-.005h10.5c.038 0 .075.002.112.005L10 9.25 4.638 4.505ZM3.52 5.52a1.257 1.257 0 0 0-.021.23v8.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-8.5c0-.079-.007-.155-.021-.23l-5.982 5.292a.75.75 0 0 1-.994 0L3.521 5.52Z"
    />
  </svg>
)

export default SvgMail
