// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgShare2 = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 3.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM12 5a3 3 0 1 1 .895 2.138.763.763 0 0 1-.085.045L7.94 9.397a3.014 3.014 0 0 1-.127 1.649l4.775 2.17a3 3 0 1 1-.563 1.392l-5.091-2.314a3 3 0 1 1 .345-4.244l4.843-2.202A2.998 2.998 0 0 1 12 5ZM6.284 9.225a1.5 1.5 0 1 0 .107.214.754.754 0 0 1-.107-.214ZM13.5 15a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
    />
  </svg>
)

export default SvgShare2
