// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgDiamond = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 14 31"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="M.125 15.5 6.583 0l6.458 15.5L6.583 31 .125 15.5Z" />
  </svg>
)

export default SvgDiamond
