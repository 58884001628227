import {colors} from '../colors'
import {fonts} from '../fonts'

const textFontSize = {
  titleXS: fonts.size.xs,
  titleS: fonts.size.sm,
  titleM: fonts.size.md,
  titleL: fonts.size.lg,
  titleXL: fonts.size.xl,
  title2XL: fonts.size.xxl,
  title3XL: '3.8rem',
  title4XL: '4.6rem',
  title5XL: '6.4rem',
  title6XL: '8.0rem',
  bodyXS: fonts.size.xs,
  bodyS: fonts.size.sm,
  bodyM: fonts.size.md,
  labelXS: fonts.size.xs,
  labelS: fonts.size.sm,
  labelM: fonts.size.md
}

type TextFontSize = typeof textFontSize
type TextFontSizeKeys = keyof TextFontSize

const textFontWeight = {
  titleXS: fonts.weight.lg,
  titleS: fonts.weight.lg,
  titleM: fonts.weight.lg,
  titleL: fonts.weight.lg,
  titleXL: fonts.weight.lg,
  title2XL: fonts.weight.md,
  title3XL: fonts.weight.md,
  title4XL: fonts.weight.md,
  title5XL: fonts.weight.sm,
  title6XL: fonts.weight.sm,
  bodyXS: fonts.weight.md,
  bodyS: fonts.weight.md,
  bodyM: fonts.weight.sm,
  labelXS: fonts.weight.lg,
  labelS: fonts.weight.lg,
  labelM: fonts.weight.lg
}

const textLineHeight = {
  titleXS: fonts.lineHeight.xxs,
  titleS: fonts.lineHeight.xs,
  titleM: fonts.lineHeight.md,
  titleL: fonts.lineHeight.xl,
  titleXL: fonts.lineHeight.xxl,
  title2XL: '3.8rem',
  title3XL: '4.6rem',
  title4XL: '5.4rem',
  title5XL: '7.2rem',
  title6XL: '8.8rem',
  bodyXS: fonts.lineHeight.xxs,
  bodyS: fonts.lineHeight.sm,
  bodyM: fonts.lineHeight.lg,
  labelXS: fonts.lineHeight.xxs,
  labelS: fonts.lineHeight.xs,
  labelM: fonts.lineHeight.md
}

const textLetterSpacing: Partial<TextFontSize> = {
  title2XL: '-0.5px',
  title3XL: '-1px',
  title4XL: '-1px',
  title5XL: '-2px',
  title6XL: '-2px'
}

const makeText = (type: TextFontSizeKeys) => {
  const fontSize = textFontSize[type]
  const fontWeight = textFontWeight[type]
  const lineHeight = textLineHeight[type]
  const letterSpacing = textLetterSpacing[type] || 'normal'

  const styles = {
    fontSize,
    lineHeight,
    fontWeight,
    letterSpacing,
    fontFamily: fonts.family.default
  }

  return styles
}

const text = {
  titleXS: makeText('titleXS'),
  titleS: makeText('titleS'),
  titleM: makeText('titleM'),
  titleL: makeText('titleL'),
  titleXL: makeText('titleXL'),
  title2XL: makeText('title2XL'),
  title3XL: makeText('title3XL'),
  title4XL: makeText('title4XL'),
  title5XL: makeText('title5XL'),
  title6XL: makeText('title6XL'),
  bodyXS: makeText('bodyXS'),
  bodyS: makeText('bodyS'),
  bodyM: makeText('bodyM'),
  labelXS: makeText('labelXS'),
  labelS: makeText('labelS'),
  labelM: makeText('labelM')
}

/**
 * Links
 */
const linkDefault = {
  color: colors.content.brand.c500,
  cursor: 'pointer',
  textDecoration: 'none',
  outline: 'none',
  ...text.labelM,
  '&:hover': {
    color: colors.content.brand.c700,
    textDecoration: 'underline'
  },
  '&:active': {
    color: colors.content.brand.c900,
    textDecoration: 'underline'
  },
  '&:focus': {
    color: colors.content.brand.c900,
    textDecoration: 'underline'
  },
  '&:visited': {
    color: colors.content.brand.c900
  }
}

const link = {
  default: linkDefault
}

export {link, text}
