import {AnyAction, isRejectedWithValue} from '@reduxjs/toolkit'
import {CaptureContext} from '@sentry/types'
import {MiddlewareType} from 'middlewares'
import {getPageSpace} from 'modules/analytics/trackEventV1Helpers'
import {
  error,
  sapiRtkQueryErrorToCommonErrorPayloadFormat
} from 'modules/common/actions/error'
import {clearError} from 'modules/common/actions/error'
import {getSearchId} from 'modules/meta/selectors'
import logSAPIService, {
  SAPIServiceEndpointNames,
  SAPIServiceStatus
} from 'performance/logSAPIService'
import captureException from 'utils/captureException'
import timing from 'utils/timing'

import {searchApi} from '@daedalus/core/src/sapi/services/searchApi'
import {
  offersSearchErrored,
  offersSearchReceived,
  offersSearchStarted,
  offersSearchSucceeded
} from '@daedalus/core/src/sapi/services/searchApi/action'

const requestsTiming = timing()

const middleware: MiddlewareType =
  store => next => async (action: AnyAction) => {
    next(action)
    const {dispatch, getState} = store
    const state = getState()
    const searchId = getSearchId(state)

    const params = action?.meta?.arg?.originalArgs
    const paramsWithSearchId = {...params, searchId}

    const space = getPageSpace(window.location.pathname)

    // getHotel
    if (searchApi.endpoints.getHotel.matchPending(action)) {
      requestsTiming.start('hotelFetch')

      logSAPIService(SAPIServiceEndpointNames.Hotel, SAPIServiceStatus.start, {
        params,
        space
      })
    }

    if (searchApi.endpoints.getHotel.matchFulfilled(action)) {
      const timings = requestsTiming.stop('hotelFetch')
      logSAPIService(SAPIServiceEndpointNames.Hotel, SAPIServiceStatus.done, {
        params,
        timings,
        space
      })
    }

    if (
      searchApi.endpoints.getHotel.matchRejected(action) &&
      isRejectedWithValue(action)
    ) {
      const timings = requestsTiming.stop('hotelFetch')
      logSAPIService(SAPIServiceEndpointNames.Hotel, SAPIServiceStatus.error, {
        params,
        timings,
        space
      })
    }

    // getRooms
    if (searchApi.endpoints.getRooms.matchPending(action)) {
      requestsTiming.start('roomsFetch')

      logSAPIService(SAPIServiceEndpointNames.Rooms, SAPIServiceStatus.start, {
        paramsWithSearchId,
        space
      })
      dispatch(clearError())
    }

    if (searchApi.endpoints.getRooms.matchFulfilled(action)) {
      const timings = requestsTiming.stop('roomsFetch')
      logSAPIService(SAPIServiceEndpointNames.Rooms, SAPIServiceStatus.done, {
        paramsWithSearchId,
        timings,
        space
      })
    }

    if (
      searchApi.endpoints.getRooms.matchRejected(action) &&
      isRejectedWithValue(action)
    ) {
      const timings = requestsTiming.stop('roomsFetch')
      logSAPIService(
        SAPIServiceEndpointNames.Rooms,
        SAPIServiceStatus.fatalError,
        {response: action?.payload, timings},
        'error'
      )
      captureException(
        action?.error,
        action?.payload as unknown as CaptureContext
      )
      dispatch(error(sapiRtkQueryErrorToCommonErrorPayloadFormat(action)))
    }

    // getOffers
    if (offersSearchStarted.match(action)) {
      requestsTiming.start('offersFetch')

      logSAPIService(SAPIServiceEndpointNames.Offers, SAPIServiceStatus.start, {
        paramsWithSearchId: action.payload,
        space
      })
      dispatch(clearError())
    }

    if (offersSearchReceived.match(action)) {
      logSAPIService(
        SAPIServiceEndpointNames.Offers,
        SAPIServiceStatus.received,
        {
          paramsWithSearchId: action?.payload,
          space
        }
      )
    }

    if (offersSearchSucceeded.match(action)) {
      const timings = requestsTiming.stop('offersFetch')
      logSAPIService(SAPIServiceEndpointNames.Offers, SAPIServiceStatus.done, {
        paramsWithSearchId: action?.payload,
        timings,
        space
      })
    }

    if (offersSearchErrored.match(action) && isRejectedWithValue(action)) {
      const timings = requestsTiming.stop('offersFetch')
      logSAPIService(
        SAPIServiceEndpointNames.Offers,
        SAPIServiceStatus.fatalError,
        {response: action?.payload, timings},
        'error'
      )
      dispatch(error(sapiRtkQueryErrorToCommonErrorPayloadFormat(action)))
    }
  }

export default middleware
