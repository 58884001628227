import {useEffect} from 'react'
import {useSelector} from 'react-redux'

import {selectIsAuthenticated, selectIsLoading} from '../modules/selectors'

export const useExecutionIfNotAuthenticated = (callback: () => void) => {
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const isLoading = useSelector(selectIsLoading)

  useEffect(() => {
    if (!isAuthenticated && !isLoading) callback()
  }, [isAuthenticated, isLoading])
}
