export interface Timing {
  /**
   * The timestamp on unix epoch of the begin of the timing event
   */
  startTime: number
  /**
   * The timestamp on unix epoch of the end of the timing event
   */
  endTime?: number
  /**
   * The difference between endTime and startTime
   */
  delta?: number
}
export interface TimingFactory {
  /**
   * Starts a timing session for a specific timer
   */
  start: (timerName: string) => Timing
  /**
   * Ends a timing session for a specific timer
   */
  stop: (timerName: string) => Timing | undefined
  /**
   * Gets the timing session with the current time without removing
   */
  lap: (timerName: string) => Timing | undefined
}

/**
 * Calculates the difference in time between two moments. Useful for timing
 * requests.
 * @returns an object with a start and a stop method.
 */
const timing = (): TimingFactory => {
  const timers: Record<string, Timing> = {}

  const start = (timerName: string) => {
    timers[timerName] = {startTime: Date.now()}
    return timers[timerName]
  }

  const lap = (timerName: string) => {
    const selectedTimer = timers[timerName]

    if (!selectedTimer) {
      return undefined
    }

    const now = Date.now()
    return {
      startTime: selectedTimer.startTime,
      endTime: now,
      delta: now - selectedTimer.startTime
    }
  }

  const stop = (timerName: string) => {
    const selectedTimer = timers[timerName]

    if (!selectedTimer) {
      return undefined
    }

    // If you call stop a second time, we just return the previous timing
    if (selectedTimer.endTime) {
      return selectedTimer
    }

    selectedTimer.endTime = Date.now()
    selectedTimer.delta = selectedTimer.endTime - selectedTimer.startTime
    return selectedTimer
  }

  return {start, stop, lap}
}

export default timing
