import React, {ReactNode} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Grid} from '@daedalus/atlas/helpers/Grid'
import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'

interface Props {
  /** An icon placed before the alert content. It can be any type of React Element */
  hasIcon?: boolean
  /** The content of the Alert, a React component or pure text */
  children: ReactNode
  /** Title string of the Alert */
  title?: string | ReactNode
}

const AlertWrapper = styled(Grid)<{
  hasIcon?: boolean
}>(
  ({theme, hasIcon}) => css`
    border-radius: ${theme.layout.radius.md}px;
    border: 1px solid ${theme.colors.content.danger.c700};
    background-color: ${theme.colors.background.danger.c050};
    position: relative;
    padding: ${theme.layout.spacing.s500}px ${theme.layout.spacing.s600}px;
    padding-left: ${hasIcon
      ? theme.layout.spacing.s400
      : theme.layout.spacing.s600}px;
  `
)

export const ErrorBanner = ({hasIcon = true, children, title}: Props) => {
  return (
    <AlertWrapper
      hasIcon={hasIcon}
      container
      alignItems="flex-start"
      wrap="nowrap"
      gap="s400"
      spacing="s000"
    >
      {hasIcon && (
        <Icon name="WarningTriangle" colorPath="content.danger.c700" />
      )}
      <Grid
        container
        spacing="s000"
        gap="s100"
        alignItems="flex-start"
        direction="column"
      >
        {title && (
          <Text variant="labelM" colorPath="content.danger.c700">
            {title}
          </Text>
        )}
        {children}
      </Grid>
    </AlertWrapper>
  )
}
