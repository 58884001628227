import * as React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {append} from 'ramda'
import {v4 as uuid} from 'uuid'

import {Button} from '@daedalus/atlas/Button'
import {ContentWrapper} from '@daedalus/atlas/helpers/ContentWrapper'
import {Icon} from '@daedalus/atlas/Icon'
import {Popover, PopoverPlacement} from '@daedalus/atlas/Popover'
import {scrollTo} from '@daedalus/core/src/_web/utils/browser'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {
  addIdsToRoomsSplit,
  removeIdsFromRoomsSplit,
  roomsSplitToConfigurationObject
} from '@daedalus/core/src/room/business/roomConfiguration'
import {MAX_NUMBER_OF_ROOMS} from '@daedalus/core/src/room/business/roomConfiguration/config'
import {
  RoomSplitType,
  UniqueRoomSplitType
} from '@daedalus/core/src/room/types/RoomConfiguration'

import {GuestPicker, GuestPickerButtonMessage} from '../GuestPicker'

interface PropTypes {
  isOpen: boolean
  anchorRef: React.RefObject<HTMLElement>
  onChange: (roomSplit: RoomSplitType[]) => void
  onSubmit: (roomSplit: RoomSplitType[]) => void
  roomsSplit: RoomSplitType[]
  onClose: () => void
  verticalOffset?: number
  horizontalOffset?: number
  placement?: PopoverPlacement
  submitButtonMessage?: React.ReactNode
}

const PopoverContent = styled(ContentWrapper)(
  () => css`
    width: 312px;
  `
)

const GuestPickerWrapper = styled.div(
  () => css`
    position: relative;
    padding: 0;
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
  `
)

const ButtonsWrapper = styled.div(
  ({theme}) => css`
    margin-top: ${theme.layout.spacing.s500}px;
  `
)

const AddButtonsWrapper = styled.div(
  ({theme}) => css`
    margin-bottom: ${theme.layout.spacing.s500}px;
  `
)

export const GuestPickerPopover = ({
  anchorRef,
  isOpen,
  roomsSplit,
  onChange,
  onClose,
  onSubmit,
  verticalOffset,
  horizontalOffset,
  placement = PopoverPlacement.Right,
  submitButtonMessage = (
    <FormattedMessageWrapper id="search" defaultMessage="Search" />
  )
}: PropTypes) => {
  const [stateRoomsSplit, setStateRoomsSplit] = React.useState(
    addIdsToRoomsSplit(roomsSplit)
  )

  const comparibleRoomsSplit = JSON.stringify(roomsSplit) // A more efficient way to "deep compare" a shallow object

  React.useEffect(() => {
    setStateRoomsSplit(addIdsToRoomsSplit(roomsSplit))
  }, [comparibleRoomsSplit])

  const containerRef = React.useRef<HTMLDivElement>(null)

  const {numberOfAdults, numberOfChildren, numberOfRooms} =
    roomsSplitToConfigurationObject(stateRoomsSplit)

  const scrollToBottom = () => {
    if (!containerRef.current) return
    scrollTo(containerRef.current, containerRef.current.scrollHeight, 1000)
  }

  const updateRoomsSplit = React.useCallback(
    (roomsSplit: UniqueRoomSplitType[]) => {
      setStateRoomsSplit(roomsSplit)
      // updates the searchBoxValue so we can submit search via parent component
      const roomsSplitWithoutIds = removeIdsFromRoomsSplit(roomsSplit)
      onChange(roomsSplitWithoutIds)
    },
    [onChange]
  )

  const handleAddRoom = React.useCallback(() => {
    const newRoomsSplit = append(
      {adults: 1, children: [], id: uuid()},
      stateRoomsSplit
    )

    updateRoomsSplit(newRoomsSplit)
    scrollToBottom()
  }, [stateRoomsSplit, updateRoomsSplit])

  const handleSubmit = React.useCallback(() => {
    const roomsSplitWithoutIds = removeIdsFromRoomsSplit(stateRoomsSplit)
    onSubmit(roomsSplitWithoutIds)
  }, [onSubmit, stateRoomsSplit])

  return (
    <Popover
      isOpen={isOpen}
      placement={placement}
      anchorRef={anchorRef}
      verticalOffset={verticalOffset}
      horizontalOffset={horizontalOffset}
      onOutsideClick={onClose}
    >
      <PopoverContent padding="s500">
        <GuestPickerWrapper ref={containerRef}>
          <GuestPicker
            isCompactLayout
            roomsSplit={stateRoomsSplit}
            updateRoomsSplit={updateRoomsSplit}
          />
        </GuestPickerWrapper>

        <ButtonsWrapper>
          {roomsSplit.length < MAX_NUMBER_OF_ROOMS && (
            <AddButtonsWrapper>
              <Button
                dataId="AddRoomButton"
                variant="secondary"
                onClick={handleAddRoom}
                size="md"
                fullWidth
                iconStart={<Icon name="Plus" />}
              >
                <FormattedMessageWrapper
                  id="addRoom"
                  defaultMessage="Add room"
                />
              </Button>
            </AddButtonsWrapper>
          )}
          <Button
            dataId="UpdateRoomsGuestsButton"
            onClick={handleSubmit}
            size="md"
            fullWidth
          >
            {submitButtonMessage}
            <> · </>
            <GuestPickerButtonMessage
              numberOfGuests={numberOfAdults + numberOfChildren}
              numberOfRooms={numberOfRooms}
            />
          </Button>
        </ButtonsWrapper>
      </PopoverContent>
    </Popover>
  )
}
