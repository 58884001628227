import {useEffect, useState} from 'react'

export const useNetworkStatus = (): boolean => {
  const [isOnline, setOnline] = useState<boolean>(true)

  const updateNetworkStatus = () => {
    setOnline(navigator.onLine)
  }

  // manually setting network status on initial mount
  useEffect(() => {
    updateNetworkStatus()
  }, [])

  useEffect(() => {
    window.addEventListener('online', updateNetworkStatus)
    window.addEventListener('offline', updateNetworkStatus)

    return () => {
      window.removeEventListener('online', updateNetworkStatus)
      window.removeEventListener('offline', updateNetworkStatus)
    }
  }, [])

  return isOnline
}
