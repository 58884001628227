import {MiddlewareType} from 'middlewares'
import {
  bookOfferCreationConfirm,
  bookOfferCreationError,
  bookOfferCreationFatalError
} from 'modules/book/slice'
import {fatalError, OriginalError} from 'modules/common/actions/fatalError'
import {getHttpRequestId} from 'modules/common/selectors'
import logBoFHService, {
  BoFHServiceEndpointNames,
  BoFHServiceStatus
} from 'performance/logBoFHService'
import {AnyAction} from 'redux'
import {getCurrentBookingRequestId} from 'utils/bookingRequestId'
import captureException from 'utils/captureException'
import {maskSensitiveData} from 'utils/maskSensitiveData'
import timing from 'utils/timing'

import {BookingStatus} from '@daedalus/core/src/api-types/bovio/response/booking'
import {bookApi} from '@daedalus/core/src/booking/services/bookApi'
import {BookingStatusErrorResponse} from '@daedalus/core/src/booking/types/BookingStatus'

const requestsTiming = timing()

const middleware: MiddlewareType =
  store => next => async (action: AnyAction) => {
    const {dispatch, getState} = store
    const state = getState()
    const httpRequestId = getHttpRequestId(state)
    const bookingRequestId = getCurrentBookingRequestId()

    next(action)

    // Booking creation handling

    if (bookApi.endpoints.createBooking.matchPending(action)) {
      const {rooms, customerAccount} =
        action?.meta?.arg?.originalArgs?.payload || {}

      requestsTiming.start('bookOfferCreation')
      const loggedPayload = {
        customerId: customerAccount?.id,
        rooms: rooms.map(room => ({
          guests: room.guests,
          specialRequest: room.specialRequest
        }))
      }

      logBoFHService(
        BoFHServiceEndpointNames.BookingCreation,
        BoFHServiceStatus.start,
        {
          params: loggedPayload,
          request_id: httpRequestId,
          booking_request_id: bookingRequestId
        }
      )
    }

    // Booking finalization handling

    if (bookApi.endpoints.bookingFinalization.matchPending(action)) {
      requestsTiming.start('bookOfferFinalize')

      logBoFHService(
        BoFHServiceEndpointNames.BookingFinalize,
        BoFHServiceStatus.start,
        {
          response: action?.meta?.arg?.originalArgs?.link,
          request_id: httpRequestId,
          booking_request_id: bookingRequestId
        }
      )
    }

    if (bookApi.endpoints.bookingFinalization.matchRejected(action)) {
      const timings = requestsTiming.stop('bookOfferFinalize')

      logBoFHService(
        BoFHServiceEndpointNames.BookingFinalize,
        BoFHServiceStatus.error,
        {
          error: action?.payload,
          request_id: httpRequestId,
          booking_request_id: bookingRequestId,
          timings
        },
        'error'
      )
    }

    // Booking status handling

    if (bookApi.endpoints.getBookingStatus.matchPending(action)) {
      const timings = requestsTiming.start('bookOfferStatus')

      logBoFHService(
        BoFHServiceEndpointNames.BookingStatus,
        BoFHServiceStatus.start,
        {
          link: action?.meta?.arg?.originalArgs?.link,
          timings,
          request_id: httpRequestId,
          booking_request_id: bookingRequestId
        }
      )
    }

    if (bookApi.endpoints.getBookingStatus.matchFulfilled(action)) {
      const timings = requestsTiming.stop('bookOfferStatus')
      const {id, links, status} = action.payload
      const error = (action?.payload as BookingStatusErrorResponse)?.error

      logBoFHService(
        BoFHServiceEndpointNames.BookingStatus,
        BoFHServiceStatus.done,
        {
          link: action?.meta?.arg?.originalArgs?.link,
          response: {id, links, status, error},
          timings,
          request_id: httpRequestId,
          booking_request_id: bookingRequestId
        },
        status === BookingStatus.Error ? 'error' : 'info'
      )

      if (status === BookingStatus.ThreeDomainSecureRequired) {
        const timings = requestsTiming.stop('bookOfferCreation')
        const {id, links, status} = action.payload
        logBoFHService(
          BoFHServiceEndpointNames.BookingCreation,
          BoFHServiceStatus.done,
          {
            data: {
              id,
              links,
              status
            },
            timings,
            request_id: httpRequestId,
            booking_request_id: bookingRequestId
          }
        )
      }
    }

    if (bookApi.endpoints.getBookingStatus.matchRejected(action)) {
      const timings = requestsTiming.stop('bookOfferStatus')

      logBoFHService(
        BoFHServiceEndpointNames.BookingStatus,
        BoFHServiceStatus.error,
        {
          error: action?.payload,
          timings,
          request_id: httpRequestId,
          booking_request_id: bookingRequestId
        },
        'error'
      )
    }

    // Booking confirmation handling

    // It's the only way to catch difference between successful status after creation and finalization
    // Additional parameters inside status request not working (because of cache I guess)
    if ((action as AnyAction).type === bookOfferCreationConfirm.type) {
      const {isWithThreeDomainSecure} = action.payload

      if (isWithThreeDomainSecure) {
        const timings = requestsTiming.stop('bookOfferFinalize')
        logBoFHService(
          BoFHServiceEndpointNames.BookingFinalize,
          BoFHServiceStatus.done,
          {
            response: action.payload,
            timings,
            request_id: httpRequestId,
            booking_request_id: bookingRequestId
          }
        )
      }
    }

    // Resend booking confirmation handling

    if (bookApi.endpoints.sendBookingConfirmation.matchPending(action)) {
      requestsTiming.start('sendBookingConfirmation')

      // TODO: make a types revision, because in types this fields are not optional,
      // but in fact seems it's possible be undefined
      const args = action.meta?.arg?.originalArgs

      const params = args
        ? {
            ...args,
            payload: args.payload
              ? maskSensitiveData(args.payload, ['email'])
              : {}
          }
        : {}

      logBoFHService(
        BoFHServiceEndpointNames.SendBookingConfirmation,
        BoFHServiceStatus.start,
        {
          params,
          request_id: httpRequestId,
          booking_request_id: bookingRequestId
        }
      )
    }

    if (bookApi.endpoints.sendBookingConfirmation.matchRejected(action)) {
      requestsTiming.stop('sendBookingConfirmation')

      logBoFHService(
        BoFHServiceEndpointNames.SendBookingConfirmation,
        BoFHServiceStatus.error,
        {
          error: action.error,
          request_id: httpRequestId,
          booking_request_id: bookingRequestId
        },
        'error'
      )
    }

    if (bookApi.endpoints.sendBookingConfirmation.matchFulfilled(action)) {
      requestsTiming.stop('sendBookingConfirmation')

      // TODO: make a types revision, because in types this fields are not optional,
      // but in fact seems it's possible be undefined
      const args = action.meta?.arg?.originalArgs

      const params = args
        ? {
            ...args,
            payload: args.payload
              ? maskSensitiveData(args.payload, ['email'])
              : {}
          }
        : {}

      logBoFHService(
        BoFHServiceEndpointNames.SendBookingConfirmation,
        BoFHServiceStatus.done,
        {
          params,
          request_id: httpRequestId,
          booking_request_id: bookingRequestId
        }
      )
    }

    if (action.type === bookOfferCreationError.type) {
      const timings = requestsTiming.stop('bookOfferCreation')
      logBoFHService(
        BoFHServiceEndpointNames.BookingCreation,
        BoFHServiceStatus.error,
        {
          error: action?.payload,
          timings,
          request_id: httpRequestId,
          booking_request_id: bookingRequestId
        },
        'error'
      )
    }

    if (bookOfferCreationFatalError.match(action)) {
      const timings = requestsTiming.stop('bookOfferCreation')

      logBoFHService(
        BoFHServiceEndpointNames.BookingCreation,
        BoFHServiceStatus.fatalError,
        {
          response: action?.payload,
          timings,
          request_id: httpRequestId,
          booking_request_id: bookingRequestId
        },
        'error'
      )
      captureException(action?.payload)
      dispatch(fatalError(action?.payload as Error & OriginalError))
    }

    // Last used billing address handling

    if (bookApi.endpoints.getLastUsedBillingAddress.matchPending(action)) {
      requestsTiming.start('lastUsedBillingAddress')

      logBoFHService(
        BoFHServiceEndpointNames.LastUsedBillingAddress,
        BoFHServiceStatus.start,
        {request_id: httpRequestId}
      )
    }

    if (bookApi.endpoints.getLastUsedBillingAddress.matchFulfilled(action)) {
      const timings = requestsTiming.stop('lastUsedBillingAddress')

      logBoFHService(
        BoFHServiceEndpointNames.LastUsedBillingAddress,
        BoFHServiceStatus.done,
        {request_id: httpRequestId, timings}
      )
    }

    if (bookApi.endpoints.getLastUsedBillingAddress.matchRejected(action)) {
      const timings = requestsTiming.stop('lastUsedBillingAddress')

      logBoFHService(
        BoFHServiceEndpointNames.LastUsedBillingAddress,
        BoFHServiceStatus.error,
        {request_id: httpRequestId, timings}
      )
    }
  }

export default middleware
