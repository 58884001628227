import axios from 'axios'
import {identity, memoizeWith, path, propOr} from 'ramda'

import {logAndCaptureErrorMessage} from '../../utils/logging/errorHandlers'
import {defaultLanguage, isEnabledLanguage} from './locale'

const getCldrDatesLocale = (locale: string): string => {
  if (!isEnabledLanguage(locale)) return defaultLanguage

  return propOr(locale, locale, {
    'en-GB': 'en',
    'pt-BR': 'pt',
    'zh-HK': 'zh-Hans-HK',
    'zh-TW': 'zh',
    'zh-CN': 'zh',
    no: 'nb'
  })
}

/**
 * Loads the correct static `cldr-dates-full` file, based on the provided `intlLocaleCode`,
 * and returns the gregorian calendar configuration.
 *
 * @returns An object containing the gregorian calendar configuration
 * @param intlLocaleCode The locale whose `cldr-dates-full` file will be loaded
 * @param publicUrl The base URL where the application's static files (including the `cldr-dates-full` files) are hosted
 */
export const getGregorianCalendar = memoizeWith(
  identity,
  async (
    intlLocaleCode: string,
    publicUrl: string
  ): Promise<Record<string, unknown> | undefined> => {
    let mappedLocaleCode = getCldrDatesLocale(intlLocaleCode)
    const gregorianCalendarFolder = `${publicUrl}js/cldr-dates-full/main/${mappedLocaleCode}`
    const res = await axios
      .get(`${gregorianCalendarFolder}/ca-gregorian.json`)
      .catch(async (error: Error) => {
        logAndCaptureErrorMessage(
          `An error occurred while loading cldr dates - ${error.message}`,
          {level: 'error'}
        )
        // fall back to english to prevent safari crash
        mappedLocaleCode = 'en'
        return {data: require('../config/fallback-ca-gregorian.json')}
      })

    return path(
      ['main', mappedLocaleCode, 'dates', 'calendars', 'gregorian'],
      res.data
    )
  }
)
