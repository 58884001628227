// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgSwimmingPoolLadder = ({
  color = 'currentColor',
  size = 20
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="M3.28 13.77a.999.999 0 0 1 1.44 0l.5.508a2.523 2.523 0 0 0 3.56 0l.5-.508a.999.999 0 0 1 1.44 0l.5.508a2.51 2.51 0 0 0 1.78.729 2.505 2.505 0 0 0 1.78-.729l.5-.508a.999.999 0 0 1 1.44 0 .74.74 0 0 0 1.06 0 .748.748 0 0 0 0-1.058 2.522 2.522 0 0 0-3.56 0l-.5.509a1 1 0 0 1-1.44 0l-.5-.51a2.522 2.522 0 0 0-3.56 0l-.5.51a1.002 1.002 0 0 1-1.44 0l-.5-.51a2.523 2.523 0 0 0-3.56 0 .747.747 0 0 0 0 1.059.74.74 0 0 0 1.06 0Z" />
    <path d="m14.22 15.709-.5.499a.998.998 0 0 1-1.11.226.999.999 0 0 1-.33-.226l-.5-.499a2.563 2.563 0 0 0-3.56 0l-.5.499a.999.999 0 0 1-1.44 0l-.5-.499a2.563 2.563 0 0 0-3.56 0 .748.748 0 1 0 1.06 1.058.999.999 0 0 1 1.44 0l.5.499a2.523 2.523 0 0 0 3.56 0l.5-.5a.999.999 0 0 1 1.44 0l.5.5a2.511 2.511 0 0 0 1.78.728 2.505 2.505 0 0 0 1.78-.728l.5-.5a1 1 0 0 1 1.44 0 .751.751 0 0 0 1.28-.528.748.748 0 0 0-.22-.529 2.563 2.563 0 0 0-3.56 0ZM6.47 11.212a.74.74 0 0 0 .75-.749V8.997h4v1.047a.748.748 0 0 0 .75.749.74.74 0 0 0 .75-.749V3.995a.548.548 0 0 1 .932-.38.538.538 0 0 1 .158.38v.809a.757.757 0 0 0 .75.748.751.751 0 0 0 .75-.748v-.809c0-.529-.21-1.036-.585-1.41A2.002 2.002 0 0 0 13.31 2a2.052 2.052 0 0 0-2 1.995V7.5h-4V3.995a.548.548 0 0 1 .932-.38.538.538 0 0 1 .158.38v.809a.757.757 0 0 0 .75.748.751.751 0 0 0 .75-.748v-.809c0-.529-.21-1.036-.585-1.41A2.002 2.002 0 0 0 7.9 2a2.052 2.052 0 0 0-2 1.995v6.468a.747.747 0 0 0 .57.749Z" />
  </svg>
)

export default SvgSwimmingPoolLadder
