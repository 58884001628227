// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgThumbsUp = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.315 2.17c-1.08-.54-2.25.32-2.293 1.399-.032.788-.152 1.713-.463 2.415-.203.46-.453.762-.782.91A1.75 1.75 0 0 0 4.25 6h-.5A1.75 1.75 0 0 0 2 7.75v8.5c0 .966.784 1.75 1.75 1.75h.5c.615 0 1.156-.317 1.468-.798.113.036.231.061.353.074l6.933.71a2.75 2.75 0 0 0 3.005-2.356l.66-4.745A2 2 0 0 0 15.999 7h-5V4.897c0-1.155-.651-2.21-1.684-2.727ZM4.5 7.745v8.505a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-8.5a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.245ZM6 15.54a.25.25 0 0 0 .224.243l6.933.711a1.25 1.25 0 0 0 1.366-1.07L15.138 11h-.388a.75.75 0 0 1 0-1.5H16a.5.5 0 1 0 0-1h-5.75a.75.75 0 0 1-.75-.75V4.897c0-.587-.331-1.123-.856-1.385a.02.02 0 0 0-.013-.003.105.105 0 0 0-.045.018.133.133 0 0 0-.066.102c-.034.847-.163 1.999-.59 2.962-.353.8-.96 1.555-1.93 1.814v7.135Z"
    />
  </svg>
)

export default SvgThumbsUp
