import {MiddlewareType} from 'middlewares'
import {validateOfferCheckUrl} from 'modules/offerCheck/validation'
import logBoFHService, {
  BoFHServiceEndpointNames,
  BoFHServiceStatus
} from 'performance/logBoFHService'
import {logToDataDog} from 'services/datadogLogger'
import timing from 'utils/timing'
import {urlParams} from 'utils/urlParams'

import {offerCheckApi} from '@daedalus/core/src/offer/services/offerCheckApi'

const requestsTiming = timing()

const middleware: MiddlewareType = () => next => action => {
  next(action)

  /**
   * Regular OfferCheck
   */
  if (offerCheckApi.endpoints.getOfferCheck.matchPending(action)) {
    const offerCheckUrl = action.meta.arg.originalArgs.url
    const {providerCode} = urlParams()

    // TODO (Checkout) [2022-07-01]: Replace with booking type value (assisted booking or MOR) if becomes available via SAPI
    const validateMorParams = providerCode !== 'EPS' && providerCode !== 'PPN'

    const {isValid, validationErrors} = validateOfferCheckUrl(
      offerCheckUrl,
      validateMorParams
    )

    if (!isValid) {
      logToDataDog({
        logName: 'OfferCheckInvalidUrlParams',
        metadata: {
          offerCheckUrl,
          errors: validationErrors
        },
        level: 'error'
      })

      validationErrors.forEach(validationError => {
        console.warn(validationError.message)
      })
    }

    requestsTiming.start('offerCheck')

    logBoFHService(BoFHServiceEndpointNames.OfferCheck, BoFHServiceStatus.start)
  }

  if (offerCheckApi.endpoints.getOfferCheck.matchFulfilled(action)) {
    const timings = requestsTiming.stop('offerCheck')
    logBoFHService(
      BoFHServiceEndpointNames.OfferCheck,
      BoFHServiceStatus.done,
      {timings, request_id: action?.payload?.httpRequestId}
    )
  }

  if (offerCheckApi.endpoints.getOfferCheck.matchRejected(action)) {
    const isFatalError = Number(action.error.code) >= 500
    const timings = requestsTiming.stop('offerCheck')

    logBoFHService(
      BoFHServiceEndpointNames.OfferCheck,
      isFatalError ? BoFHServiceStatus.fatalError : BoFHServiceStatus.error,
      {
        error: action?.payload?.data?.details,
        timings
      },
      'error'
    )
  }

  /**
   * getSplitBookingOfferCheck
   */
  if (offerCheckApi.endpoints.getSplitBookingOfferCheck.matchPending(action)) {
    const sbOfferCheckUrl = action.meta.arg.originalArgs.url

    const {isValid, validationErrors} = validateOfferCheckUrl(sbOfferCheckUrl)

    if (!isValid) {
      logToDataDog({
        logName: 'SplitBookingOfferCheckInvalidUrlParams',
        metadata: {
          offerCheckUrl: sbOfferCheckUrl,
          errors: validationErrors
        },
        level: 'error'
      })

      validationErrors.forEach(validationError => {
        console.warn(validationError.message)
      })
    }

    requestsTiming.start('splitBookingOfferCheck')

    logBoFHService(
      BoFHServiceEndpointNames.SbOfferCheck,
      BoFHServiceStatus.start
    )
  }

  if (
    offerCheckApi.endpoints.getSplitBookingOfferCheck.matchFulfilled(action)
  ) {
    const timings = requestsTiming.stop('splitBookingOfferCheck')
    logBoFHService(
      BoFHServiceEndpointNames.SbOfferCheck,
      BoFHServiceStatus.done,
      {timings, request_id: action?.payload?.httpRequestId}
    )
  }

  if (offerCheckApi.endpoints.getSplitBookingOfferCheck.matchRejected(action)) {
    const isFatalError = Number(action.error.code) >= 500
    const timings = requestsTiming.stop('splitBookingOfferCheck')

    logBoFHService(
      BoFHServiceEndpointNames.SbOfferCheck,
      isFatalError ? BoFHServiceStatus.fatalError : BoFHServiceStatus.error,
      {
        error: action?.payload?.data?.details,
        timings
      },
      'error'
    )
  }
}

export default middleware
