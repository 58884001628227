// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgTrash = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75 3.5c-.69 0-1.25.56-1.25 1.25V5h3v-.25c0-.69-.56-1.25-1.25-1.25h-.5ZM13 5v-.25A2.75 2.75 0 0 0 10.25 2h-.5A2.75 2.75 0 0 0 7 4.75V5H4.76a.75.75 0 0 0-.017 0H3a.75.75 0 0 0 0 1.5h1.062l.782 8.988A2.75 2.75 0 0 0 7.584 18h4.832a2.75 2.75 0 0 0 2.74-2.512l.782-8.988H17A.75.75 0 0 0 17 5h-4Zm1.432 1.5H5.568l.77 8.858A1.25 1.25 0 0 0 7.584 16.5h4.832a1.25 1.25 0 0 0 1.246-1.142l.77-8.858Z"
    />
  </svg>
)

export default SvgTrash
