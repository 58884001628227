import React from 'react'
import {useIntl} from 'react-intl'

import {CrashErrorMessage} from './CrashErrorMessage'
import {searchErrorMessages} from './searchCrashErrorMessage'

const reload = () => {
  window.location.reload()
}

export interface ErrorMessage {
  heading: string
  message: string
  button: string
}

interface Props {
  onClick?: () => void
  t?: ErrorMessage
}

export const CrashErrorMessageContainer: React.FC<Props> = ({
  onClick = reload,
  t: customErrors
}) => {
  const {formatMessage} = useIntl()

  const t = {
    heading:
      customErrors?.heading ||
      formatMessage(searchErrorMessages.crashPageMessageTitle),
    message:
      customErrors?.message ||
      formatMessage(searchErrorMessages.crashPageMessageBody),
    button:
      customErrors?.button ||
      formatMessage(searchErrorMessages.crashPageButtonRefresh)
  }

  return <CrashErrorMessage t={t} onButtonClick={onClick} />
}
