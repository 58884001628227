import load from 'react-loadable'

export const loadModule = <T>(
  loader: () => Promise<any>,
  options: Record<string, unknown>
) =>
  load<T, object>({
    loader,
    loading: () => null,
    timeout: 10000,
    delay: 500,
    ...options
  })
