export const OPEN_SEARCH_BOX = 'OPEN_SEARCH_BOX'
export const CLOSE_SEARCH_BOX = 'CLOSE_SEARCH_BOX'

type OpenSearchBoxActionType = {
  type: typeof OPEN_SEARCH_BOX
}

type CloseSearchBoxActionType = {
  type: typeof CLOSE_SEARCH_BOX
}

export type ActionType = OpenSearchBoxActionType | CloseSearchBoxActionType

export const openSearchBox = (): OpenSearchBoxActionType => {
  return {
    type: OPEN_SEARCH_BOX
  }
}

export const closeSearchBox = (): CloseSearchBoxActionType => {
  return {
    type: CLOSE_SEARCH_BOX
  }
}
