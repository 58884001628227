import {createSelector} from '@reduxjs/toolkit'

import {ExperimentsState} from '../types'

type DataFile = {
  featureFlags: Array<{key: string}>
}
/**
 * Selector that returns the experiments datafile.
 * @param state [ExperimentsState]
 * @returns the datafile containing the experiments from optimizely
 */
export const getDataFile = createSelector(
  [(state: ExperimentsState) => state.experiments?.dataFile as DataFile],
  dataFile => dataFile
)
