// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgBirdHouse = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 2.192a.75.75 0 0 1 1 0l5.994 5.373a.432.432 0 0 1 .012.01l1.245 1.117a.75.75 0 0 1-1.002 1.116l-.13-.117-.499 5.803v.004A2.75 2.75 0 0 1 13.38 18H6.62a2.75 2.75 0 0 1-2.739-2.502v-.004L3.38 9.691l-.13.117a.75.75 0 0 1-1.002-1.116l1.245-1.116a.773.773 0 0 1 .012-.01l5.993-5.374ZM4.78 8.438l.594 6.925v.001A1.25 1.25 0 0 0 6.62 16.5h6.762a1.25 1.25 0 0 0 1.245-1.136v-.001l.595-6.925L10 3.758l-5.22 4.68ZM10 10.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM7 12a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z"
    />
  </svg>
)

export default SvgBirdHouse
