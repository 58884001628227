import {
  RaaOfferType,
  RaaOfferWithPrices
} from '@daedalus/core/src/offer/types/Raa'
import {TaxDisplayLogic} from '@daedalus/core/src/offer/types/TaxDisplayLogic'

import {adaptRaaPriceToChargeablePrice} from './adaptRaaPriceToChargeablePrice'

const decorateRaaResponseWithChargeablePrices = (
  offers: RaaOfferType[],
  taxDisplayLogic: TaxDisplayLogic,
  numberOfNights: number
): RaaOfferWithPrices[] => {
  return offers.map(offer => {
    const prices = [
      adaptRaaPriceToChargeablePrice(
        offer.rateBreakdown,
        taxDisplayLogic,
        offer.currency,
        numberOfNights,
        1
      )
    ]
    return {
      ...offer,
      prices
    }
  })
}

export default decorateRaaResponseWithChargeablePrices
