// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgReception = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="M12.71 8.063a.761.761 0 0 0-.463-1.004.768.768 0 0 0-1.008.462l-.927 2.64a.77.77 0 0 0 .101.692.775.775 0 0 0 .615.311h1.501v.542a.761.761 0 0 0 .756.753.768.768 0 0 0 .755-.753V9.7a.762.762 0 0 0-.755-.753.767.767 0 0 0-.756.753h-.433l.614-1.636Z" />
    <path d="M16.56 13.232a.728.728 0 0 0-.474-.1l-2.51.24a.776.776 0 0 0-.674.834.762.762 0 0 0 .755.672h.081l.615-.07a6.472 6.472 0 0 1-4.333 1.656 6.541 6.541 0 0 1-4.6-1.908A6.492 6.492 0 0 1 3.511 9.97a.761.761 0 0 0-.755-.753A.757.757 0 0 0 2 9.97c0 2.13.85 4.172 2.36 5.678A8.076 8.076 0 0 0 10.06 18a8.018 8.018 0 0 0 5.23-1.947v.28a.75.75 0 0 0 .705.804.757.757 0 0 0 .756-.703l.15-2.49a.73.73 0 0 0-.342-.712ZM10 2a7.988 7.988 0 0 0-5.23 1.937v-.28a.75.75 0 0 0-.705-.804.768.768 0 0 0-.806.693l-.15 2.5a.73.73 0 0 0 .332.682.697.697 0 0 0 .393.1h.08l2.51-.24a.777.777 0 0 0 .674-.834.772.772 0 0 0-.836-.672l-.615.07A6.462 6.462 0 0 1 9.98 3.506a6.532 6.532 0 0 1 4.6 1.902 6.483 6.483 0 0 1 1.909 4.582.751.751 0 0 0 .755.753A.748.748 0 0 0 18 9.99a8.015 8.015 0 0 0-2.354-5.631A8.076 8.076 0 0 0 10 2Z" />
    <path d="m8.015 9.458-.232.07a2.016 2.016 0 0 0-1.257.787 2.002 2.002 0 0 0-.375 1.431.751.751 0 0 0 .756.753h2.357a.767.767 0 0 0 .756-.753.751.751 0 0 0-.756-.752H8.196c.131-.035.259-.078.383-.13a2.015 2.015 0 0 0 1.35-1.868 2.45 2.45 0 0 0 0-.381A1.844 1.844 0 0 0 8.67 7.199a2.022 2.022 0 0 0-2.015.322l-.252.23a.723.723 0 0 0-.222.532.752.752 0 0 0 .756.753c.2 0 .392-.08.534-.22l.171-.161a.516.516 0 0 1 .484 0 .444.444 0 0 1 .292.27v.111c0 .04 0 .281-.403.422Z" />
  </svg>
)

export default SvgReception
