import {uniq} from 'ramda'

import {ApiSearchParameters, SearchParameters} from '@findhotel/sapi'

import {MappingContext} from '../../../offer/business/offersMapping'
import {numberOfRooms} from '../../../room/business/roomConfiguration'

import type {SearchData, SearchState} from '.'
import type {TransformedSearchResults} from './transformations/transformSearchResults'
import type {MaybeDrafted} from '@reduxjs/toolkit/dist/query/core/buildThunks'

export type BaseMappingContext = Omit<
  MappingContext,
  // We get these params from SAPI responses in SAPI.search and SAPI.offers
  'checkIn' | 'checkOut' | 'numberOfRooms'
>

/**
 * Merge hotelIds in state with newly supplied hotelIds - ensuring they are unique
 */
export const mergeHotelIds = (
  stateIds: string[] = [],
  responseIds?: string[]
) => (responseIds ? uniq([...stateIds, ...responseIds]) : stateIds)

/**
 * Merge the current state (Immer draft) with new response data & current searchState
 */
export const mergeDataRecipe = (
  draft: MaybeDrafted<SearchData>,
  {
    status,
    hotelIds,
    anchorHotelId,
    hotelEntities,
    hotelOfferEntities,
    ...base
  }: Partial<TransformedSearchResults>,
  searchState?: SearchState
) => ({
  currentSearch: draft.currentSearch,
  ...base,
  status: status ?? draft.status,
  searchState: searchState ?? draft.searchState,
  hotelIds: mergeHotelIds(draft.hotelIds, hotelIds),
  anchorHotelId: anchorHotelId ?? draft.anchorHotelId,
  hotelEntities: {...draft.hotelEntities, ...hotelEntities},
  hotelOfferEntities: {
    ...draft.hotelOfferEntities,
    ...hotelOfferEntities
  }
})

/**
 * Helper to narrow the SearchData type to one with results - ideally SearchData would be a union that narrows itself
 */
export const hasSearchResults = (
  data: SearchData
): data is Required<SearchData> =>
  !!(data.hotelIds && data.hotelEntities && data.hotelOfferEntities)

/**
 * Type guard to validate whether params *returned from* SAPI are complete
 * @param params - as returned in searchResponse.searchParameters in SAPI.search() callbacks
 * @returns boolean - narrows the type to SearchParameters
 */
export const isValidResponseSearchParams = (
  params: ApiSearchParameters | SearchParameters | undefined
): params is SearchParameters =>
  !!(params && params.checkIn && params.checkOut && params.rooms)

/**
 * Compose base mapping options with search params
 * @param baseMappingContext - mapping options from app/user context
 * @param currentParameters - (validated) search parameters for the current search
 */
export const createMappingContext = (
  baseMappingContext: BaseMappingContext,
  currentParameters: SearchParameters
) => ({
  ...baseMappingContext,
  checkIn: currentParameters.checkIn,
  checkOut: currentParameters.checkOut,
  numberOfRooms: numberOfRooms(currentParameters.rooms)
})
