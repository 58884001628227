import isPast from 'date-fns/isPast'
import parseISO from 'date-fns/parseISO'

/**
 *
 * Returns a boolean that tells if an offer is partially refundable.
 * A partially refundable offer is cancellable and the user receives part of the
 * refund but not all.
 * @returns boolean to whether an offer is partially refundable
 * @param isRefundableOffer Tells if the offer is refundable. It doesn't mean that is necessarily fully refundable.
 * @param cancellationDeadline The deadline in datetime format ie.: 2099-05-28T23:59:00+08:00
 */
const getIsPartialRefundableOffer = (
  isRefundableOffer: boolean,
  cancellationDeadline?: string
) => {
  return Boolean(
    isRefundableOffer &&
      cancellationDeadline &&
      isPast(parseISO(cancellationDeadline))
  )
}

export default getIsPartialRefundableOffer
