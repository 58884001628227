// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPodium = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 33 32"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <g clipPath="url(#podium_svg__a)">
      <path
        d="M10.667 30h-9V19h9M22.667 24h9v6h-9"
        stroke="#0F0E0F"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M22.667 14h-12v16h12V14Z"
        stroke="#0F0E0F"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="m16.667 0 1.485 3.008 3.319.483-2.402 2.341.567 3.306-2.969-1.561-2.969 1.561.567-3.306-2.402-2.341 3.319-.483L16.667 0Z"
        fill="#0F0E0F"
      />
    </g>
    <defs>
      <clipPath id="podium_svg__a">
        <path fill="#fff" transform="translate(.667)" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgPodium
