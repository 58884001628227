import {Params} from '../getConfig'

export const amplifyVioStaging: Params = {
  aws_user_pools_id: 'eu-west-1_LTpBgi2PL',
  aws_cognito_identity_pool_id:
    'eu-west-1:babe3571-1ed0-4968-93a0-cd8ffbc57dd8',
  aws_user_pools_web_client_id: '7aasijoci83u5r5h3ruh8oq8eh',
  oauth_domain: 'staging.dev-authentication.vio.com',
  aws_user_files_s3_bucket: 'findhotel-profile-pictures-dev'
}
