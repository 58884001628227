// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgNavigation = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2c.3 0 .57.178.689.453l6.25 14.5a.75.75 0 0 1-1.055.951L10 14.61l-5.884 3.294a.75.75 0 0 1-1.055-.95l6.25-14.5A.75.75 0 0 1 10 2ZM5.315 15.514l4.319-2.418a.75.75 0 0 1 .732 0l4.319 2.418L10 4.644l-4.685 10.87Z"
    />
  </svg>
)

export default SvgNavigation
