import {styled} from '@linaria/react'

import {cssTheme} from '../../../themes'
import {Button} from '../Button'

const HEADER_TOP_HEIGHT = 88
export const COMPACT_HEADER_TOP_HEIGHT = 61

type HeaderElementProps = {
  isCompact?: boolean
}

export const HeaderElement = styled.header<HeaderElementProps>`
  z-index: 4;
  position: relative;
  background-color: ${cssTheme.colors.background.neutral.c000};

  &.--shadow {
    box-shadow: ${cssTheme.shadows.fixed};
  }
`

export const HeaderTopContent = styled.div<HeaderElementProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${({isCompact}) =>
    isCompact ? COMPACT_HEADER_TOP_HEIGHT - 1 : HEADER_TOP_HEIGHT}px;
  &.--leftPadding {
    padding-left: ${cssTheme.layout.spacing.s600};
  }
`

export const HeaderBottomContent = styled.div``

export const CenterComponentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`

export const RightComponentWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${cssTheme.layout.spacing.s300};
  padding-left: ${cssTheme.layout.spacing.s200};
`

export const BackButton = styled(Button)`
  color: ${cssTheme.colors.content.neutral.c950};
  /* @mirror */
`
