// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgMountain = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.689 2.453a.75.75 0 0 0-1.364-.03L6.99 9.332c-1.04.003-2.077.576-2.54 1.718l-2.395 5.92a.75.75 0 0 0 .695 1.03h1.99l.01.001h12.5a.75.75 0 0 0 .689-1.047l-6.25-14.5Zm.067 14.047h4.354L10.961 4.555 8.46 9.74a2.71 2.71 0 0 1 1.09 1.31l2.207 5.451ZM5.84 11.611c.422-1.04 1.896-1.04 2.318 0l1.978 4.889H3.863L5.84 11.61Z"
    />
  </svg>
)

export default SvgMountain
