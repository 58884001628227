import {styled} from '@linaria/react'

import {cssTheme} from '../../../themes'

export const StyledPopper = styled.div`
  border-radius: ${cssTheme.layout.radius.lg};
  background-color: ${cssTheme.colors.background.neutral.c000};
  box-shadow: ${cssTheme.shadows.floating};
  z-index: 4;
  overflow: hidden;
`
