import {useEffect, useState} from 'react'
import {isEmpty} from 'ramda'

import {OffersConfigurations} from '../../../api-types/bovio/response/offers_configurations'
import {useGetOfferConfigurationQuery} from './index'
import {getPersistedConfiguration, persistConfiguration} from './store'

interface Props {
  boVioUrl?: string | null
  // BoVio API key
  boVioProfile?: string | null
}
export const useGetBoVioConfiguration = ({boVioUrl, boVioProfile}: Props) => {
  const [configuration, setConfiguration] =
    useState<OffersConfigurations | null>(getPersistedConfiguration())

  const {data} = useGetOfferConfigurationQuery(
    {
      url: `${boVioUrl}offers/configurations`,
      headers: {'X-Api-Key': boVioProfile || ''}
    },
    {skip: !isEmpty(configuration) || !boVioUrl || !boVioProfile}
  )

  useEffect(() => {
    if (data) {
      /**
       * The configuration is persisted in the store to reduce the number of requests to the BoVio API.
       * This was requested by the back-end team because these requests were creating noise in the logs, and
       * could be persisted for more efficiency.
       */
      persistConfiguration(data)
      setConfiguration(data)
    }
  }, [data])

  return configuration
}
