import React from 'react'
import {css} from '@linaria/core'

import {cssTheme} from '../../../../themes'
import {linariaMq} from '../../../../utils/breakpoints'

const GRID_SIZES = [true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] as const
const GUTTERS = [
  's000',
  's200',
  's300',
  's400',
  's500',
  's600',
  's800'
] as const
const keys = [
  'mobileSm',
  'mobileLg',
  'desktopXs',
  'desktopSm',
  'desktopMd',
  'desktopLg'
] as const

type KeyValues = (typeof keys)[number]

type GridStyles = Record<string, string>

function generateGrid(breakpoint: KeyValues): string {
  // For the auto layouting
  const gridStyles: GridStyles = {
    [`.grid-${breakpoint}`]: `
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    `
  }

  // fit to the size of child component
  // eslint-disable-next-line fp/no-mutation
  gridStyles[`.grid-${breakpoint}-fit`] = `
    flex-basis: auto;
    max-width: 100%;
  `

  // fill available space
  // eslint-disable-next-line fp/no-mutation
  gridStyles[`.grid-${breakpoint}-fill`] = `
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
  `

  GRID_SIZES.forEach(size => {
    if (typeof size === 'boolean') {
      // Skip the first one as handle above.
      return
    }

    // Only keep 6 significant numbers.
    const width = `${Math.round((size / 12) * 10 ** 6) / 10 ** 4}%`

    // eslint-disable-next-line fp/no-mutation
    gridStyles[`.grid-${breakpoint}-${size}`] = `
      flex-basis: ${width};
      max-width: ${width};
    `
  })

  const serializedStyles = Object.entries(gridStyles)
    .map(([selector, styles]) => `${selector} { ${styles} }`)
    .join('\n')

  // No need for a media query for the first size.
  if (breakpoint === 'mobileSm') {
    return serializedStyles
  } else {
    return `
      ${linariaMq[breakpoint]} {
        ${serializedStyles}
      }
    `
  }
}

function generateGutter() {
  type GutterStyles = Record<
    `.grid-spacing-${(typeof GUTTERS)[number]}`,
    string
  >
  const styles: GutterStyles = {} as GutterStyles

  GUTTERS.forEach((spacing, index) => {
    if (index === 0) {
      // Skip the default style.
      return
    }

    // eslint-disable-next-line fp/no-mutation
    styles[`.grid-spacing-${spacing}`] = `
      margin: 0 calc(${cssTheme.layout.spacing[spacing]} / 2 * -1);
      flex-basis: calc(100% + ${cssTheme.layout.spacing[spacing]});
      & > .grid-item {
        padding: 0 calc(${cssTheme.layout.spacing[spacing]} / 2);
      }
    `
  })

  return styles
}

export const gridGlobalStyles = css`
  :global() {
    .grid-container {
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      flex-basis: 100%;
    }
    .grid-item {
      box-sizing: border-box;
      flex: 0 0 auto;
      margin: 0;
      height: 100%;
    }
    .direction-mobileSm-column {
      flex-direction: column;
    }
    .direction-mobileSm-column-reverse {
      flex-direction: column-reverse;
    }
    .direction-mobileSm-row-reverse {
      flex-direction: row-reverse;
    }

    .wrap-mobileSm-nowrap {
      flex-wrap: nowrap;
    }

    .wrap-mobileSm-wrap-reverse {
      flex-wrap: wrap-reverse;
    }

    .align-items-mobileSm-center {
      align-items: center;
    }

    .align-items-mobileSm-flex-start {
      align-items: flex-start;
    }
    .align-items-mobileSm-flex-end {
      align-items: flex-end;
    }

    .align-items-mobileSm-baseline {
      align-items: baseline;
    }

    .align-content-mobileSm-center {
      align-content: center;
    }
    .align-content-mobileSm-flex-start {
      align-content: flex-start;
    }
    .align-content-mobileSm-flex-end {
      align-content: flex-end;
    }
    .align-content-mobileSm-space-between {
      align-content: space-between;
    }
    .align-content-mobileSm-space-around {
      align-content: space-around;
    }
    .justify-mobileSm-center {
      justify-content: center;
    }
    .justify-mobileSm-flex-end {
      justify-content: flex-end;
    }
    .justify-mobileSm-space-between {
      justify-content: space-between;
    }
    .justify-mobileSm-space-around {
      justify-content: space-around;
    }

    ${Object.entries(generateGutter())
      .map(([selector, styles]) => `${selector} { ${styles} }`)
      .join('\n')}

    ${keys.map(generateGrid).join('\n')}
  }
`

const GridGlobalStyles = () => <div className={gridGlobalStyles} />

export default GridGlobalStyles
