// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgBellRinging = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m4.25 2.75-.167-.731H4.08l-.003.001-.01.003-.032.008c-.026.006-.06.016-.102.028a3.85 3.85 0 0 0-.325.111c-.24.094-.603.265-.888.55-.286.285-.456.649-.55.888a3.848 3.848 0 0 0-.14.427l-.007.031-.003.01v.006s-.001.001.73.168l-.73-.168a.751.751 0 0 0 1.461.335v.002l.002-.01a1.189 1.189 0 0 1 .297-.628c.067-.068.203-.147.376-.215a2.35 2.35 0 0 1 .252-.083l.011-.002m10.6-.898a.75.75 0 0 1 .898-.564l-.167.731.167-.731h.003l.003.001.01.003.032.008c.026.006.06.016.102.028.083.024.197.06.325.111.24.094.603.265.888.55.285.285.456.649.55.888a3.9 3.9 0 0 1 .14.427l.007.031.003.01v.006s.001.001-.73.168l.73-.168a.751.751 0 0 1-1.46.336l-.003-.01a1.19 1.19 0 0 0-.297-.628 1.19 1.19 0 0 0-.376-.214 2.336 2.336 0 0 0-.252-.083l-.01-.002a.75.75 0 0 1-.563-.898ZM4 8a6 6 0 1 1 12 0v1.832l1.929 4.099A.75.75 0 0 1 17.25 15h-3.548l-.011.064a3.929 3.929 0 0 1-.408 1.17 3.235 3.235 0 0 1-1.157 1.226c-.556.34-1.257.54-2.126.54s-1.57-.2-2.125-.54a3.235 3.235 0 0 1-1.158-1.226A3.93 3.93 0 0 1 6.297 15H2.75a.75.75 0 0 1-.679-1.07L4 9.833V8Zm3.83 7c.043.161.108.34.203.516.137.25.333.487.624.664.288.177.712.32 1.343.32s1.055-.143 1.343-.32c.29-.177.487-.414.624-.664.095-.175.16-.355.202-.516H7.83ZM10 3.5A4.5 4.5 0 0 0 5.5 8v2a.75.75 0 0 1-.071.32L3.932 13.5h12.136l-1.497-3.18A.75.75 0 0 1 14.5 10V8A4.5 4.5 0 0 0 10 3.5Z"
    />
  </svg>
)

export default SvgBellRinging
