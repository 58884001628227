export const CHANGE_CURRENCY = 'CHANGE_CURRENCY'

/* eslint-disable */
type CurrencyType = {currency: string}

export type ChangeCurrencyType = {
  currency: string
  type: typeof CHANGE_CURRENCY
}

export type ActionType = ChangeCurrencyType

/* eslint-enable */

export const changeCurrency = (currency: string): ChangeCurrencyType => ({
  type: CHANGE_CURRENCY,
  currency
})
