import React, {FC, HTMLAttributes} from 'react'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'
import {SpacingPropsType} from 'types/Layout'

import {cssTheme} from '../../../../themes'
import {getLinariaClassName} from '../../../../utils/getLinariaClassName'

interface StyleProps {
  rowGap?: string
  columnGap?: string
}

interface FlexProps extends HTMLAttributes<HTMLDivElement> {
  alignItems?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline'
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  gap?: SpacingPropsType
  isReversed?: boolean
  style?: React.CSSProperties
  className?: string
  componentRef?: React.Ref<HTMLDivElement>
}

const BaseElement = styled.div<StyleProps>`
  display: flex;
  row-gap: ${({rowGap}) => rowGap ?? 0};
  column-gap: ${({columnGap}) => columnGap ?? 0};
  &-ai-stretch {
    align-items: stretch;
  }
  &-ai-flex-start {
    align-items: flex-start;
  }
  &-ai-flex-end {
    align-items: flex-end;
  }
  &-ai-center {
    align-items: center;
  }
  &-ai-baseline {
    align-items: baseline;
  }
  &-jc-flex-start {
    justify-content: flex-start;
  }
  &-jc-flex-end {
    justify-content: flex-end;
  }
  &-jc-center {
    justify-content: center;
  }
  &-jc-space-between {
    justify-content: space-between;
  }
  &-jc-space-around {
    justify-content: space-around;
  }
  &-jc-space-evenly {
    justify-content: space-evenly;
  }
  &-jc-stretch {
    justify-content: stretch;
  }
  &-fw-nowrap {
    flex-wrap: nowrap;
  }
  &-fw-wrap {
    flex-wrap: wrap;
  }
  &-fw-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  &-fd-row {
    flex-direction: row;
  }
  &-fd-row-reverse {
    flex-direction: row-reverse;
  }
  &-fd-column {
    flex-direction: column;
  }
  &-fd-column-reverse {
    flex-direction: column-reverse;
  }
`

const FlexColumn: FC<FlexProps> = ({
  children,
  style,
  className,
  alignItems = 'stretch',
  justifyContent = 'flex-start',
  wrap = 'nowrap',
  gap = 's000',
  isReversed = false,
  componentRef,
  ...rest
}) => (
  <BaseElement
    rowGap={cssTheme.layout.spacing[gap]}
    style={style}
    className={cx(
      `${getLinariaClassName(BaseElement)}-ai-${alignItems}`,
      `${getLinariaClassName(BaseElement)}-jc-${justifyContent}`,
      `${getLinariaClassName(BaseElement)}-fw-${wrap}`,
      `${getLinariaClassName(BaseElement)}-fd-${isReversed ? 'column-reverse' : 'column'}`,
      className
    )}
    ref={componentRef}
    {...rest}
  >
    {children}
  </BaseElement>
)

export const FlexRow: FC<FlexProps> = ({
  children,
  style,
  className,
  alignItems = 'stretch',
  justifyContent = 'flex-start',
  wrap = 'nowrap',
  gap = 's000',
  isReversed = false,
  componentRef,
  ...rest
}) => (
  <BaseElement
    columnGap={cssTheme.layout.spacing[gap]}
    style={style}
    className={cx(
      `${getLinariaClassName(BaseElement)}-ai-${alignItems}`,
      `${getLinariaClassName(BaseElement)}-jc-${justifyContent}`,
      `${getLinariaClassName(BaseElement)}-fw-${wrap}`,
      `${getLinariaClassName(BaseElement)}-fd-${isReversed ? 'row-reverse' : 'row'}`,
      className
    )}
    ref={componentRef}
    {...rest}
  >
    {children}
  </BaseElement>
)

export const Flex = {
  Row: FlexRow,
  Column: FlexColumn
}
export const FlexComponent: FC<FlexProps> = ({children}) => <>{children}</>
