import {MiddlewareType} from 'middlewares'
import {
  OVERLAY_HIDE,
  OVERLAY_REPLACE_TOP,
  OVERLAY_SHOW
} from 'modules/overlay/actions'
import {OVERLAY_IDS} from 'modules/overlay/selectors'
import {update} from 'ramda'
import {history} from 'utils/history'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Page
} from '@daedalus/core/src/analytics/types/Events'

import {Component} from './analyticsV2'

const middleware: MiddlewareType = store => next => action => {
  const state = store.getState()
  const {dispatch} = store

  next(action)

  switch (action.type) {
    case OVERLAY_SHOW: {
      const {id} = action.params
      if (action.ignoreHistory) break

      history.push(`${history.location.pathname}${history.location.search}`, {
        locationState: {
          overlays: [...state.overlay, action.params]
        }
      })

      if (id === OVERLAY_IDS.preferences) {
        dispatch(
          trackEvent({
            category: Category.System,
            entity: Entity.PreferencesOverlay,
            action: Action.Displayed,
            page: Page.Payment
          })
        )
      }

      break
    }

    case OVERLAY_HIDE: {
      const {offerEventContext, id} = action.params
      if (action.ignoreHistory) break

      history.goBack()

      if (id === OVERLAY_IDS.preferences) {
        dispatch(
          trackEvent({
            category: Category.System,
            entity: Entity.OverlayClose,
            action: Action.Succeeded,
            page: Page.Payment,
            component: Component.Preferences,
            analyticsContext: {
              [AnalyticsContext.OfferContext]: offerEventContext
            }
          })
        )
      }

      break
    }

    case OVERLAY_REPLACE_TOP: {
      history.replace(
        `${history.location.pathname}${history.location.search}`,
        {
          locationState: {
            overlays: update(
              state.overlay.length - 1,
              action.params,
              state.overlay
            )
          }
        }
      )
      break
    }

    default:
      break
  }
}

export default middleware
