import React from 'react'
import SoldOutOverlay from 'components/SoldOutOverlay'
import {BookingError} from 'hooks/useBookingError'
import {useHandleBackButtonClick} from 'hooks/useHandleBackButtonClick'

import {Category as ErrorCategory} from '@daedalus/core/src/api-types/bovio/response/error'

export const getIsPriceMismatch = (error?: BookingError) => {
  return error?.data?.details?.category === ErrorCategory.PriceMismatch
}

export const PriceMismatch = React.memo(() => {
  const handleTrackPaymentButtonClick = useHandleBackButtonClick()
  return <SoldOutOverlay onBackButtonClick={handleTrackPaymentButtonClick} />
})

PriceMismatch.displayName = 'PriceMismatch'
