import {RateBreakdown} from '@findhotel/sapi/dist/types/packages/core/src'
/**
 * Calculates the total rate for a RateBreakdown and a given tax configuration.
 */
export const calculateTotalRate = (
  rate: RateBreakdown,
  includeTaxes: boolean,
  includeLocalTaxes: boolean
): number => {
  const baseRate = includeTaxes ? rate.base + rate.taxes : rate.base
  const totalRate = includeLocalTaxes ? baseRate + rate.hotelFees : baseRate
  return totalRate
}
