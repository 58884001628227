import {useEffect} from 'react'
import {useSelector} from 'react-redux'

// TODO (Core): This import will be fixed when the host file is migrated
import {getAnonymousId} from '../../../_web/anonymousId'
import {getBrand, getIsBrandVio} from '../../../_web/brand/modules/selectors'
import {getUser} from '../../../auth/modules/selectors'
import {User} from '../../../auth/types/Cognito'
import {getCountryCode} from '../../../localization/services/country'
import {getLanguageCode} from '../../../localization/services/locale'
import {isReactNativeWebView} from '../../../native/isReactNativeWebView'
import {getTimezone} from '../../../utils/date'
import {customerIo} from '../../services/CustomerIo'

const useIdentifyOnCustomerIo = (isLoaded: boolean) => {
  const isBrandVio = useSelector(getIsBrandVio)
  const brand = useSelector(getBrand)
  const cio = customerIo(brand)
  const anonymousId = getAnonymousId()
  const countryCode = useSelector(getCountryCode)
  const language = useSelector(getLanguageCode) as string
  const user = useSelector(getUser)
  const isWebView = isReactNativeWebView()

  useEffect(() => {
    if (!isLoaded) return

    const timezone = getTimezone()

    if (isWebView) {
      const guestWithAnonymousIdAsFallback = {
        ...user,
        attributes: {
          ...user?.attributes,
          sub: user?.attributes.sub || anonymousId
        }
      } as User

      cio.addOrUpdateUser({
        anonymousId,
        country: countryCode,
        language,
        user: guestWithAnonymousIdAsFallback,
        timezone
      })

      return
    }

    if (isBrandVio && user) {
      cio.addOrUpdateUser({
        anonymousId,
        country: countryCode,
        language,
        user,
        timezone
      })
    }
  }, [
    isLoaded,
    anonymousId,
    cio,
    countryCode,
    isBrandVio,
    isWebView,
    language,
    user
  ])

  return null
}

export {useIdentifyOnCustomerIo}
