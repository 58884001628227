import {Room} from '@daedalus/core/src/room/types/room'
import PriceTypes from '@daedalus/core/src/offer/types/PriceTypes'
import {ActionLinkTypes} from '@daedalus/core/src/offer/types/actionLink'
import {MatchedDim} from '@findhotel/sapi'

export const room: Room = {
  amenities: [
    'Iron/ironing board',
    'Electric kettle',
    'Free toiletries',
    'Portable shower seat',
    'Non-Smoking',
    'Soundproofed rooms',
    'Flat-panel TV',
    'Height-adjustable showerhead',
    'Premium TV channels',
    'Soap',
    'In-room safe (laptop compatible)',
    'Shampoo',
    'Pay movies',
    'Desk',
    'Towels provided',
    'Hair dryer',
    'Bathtub or shower',
    'In-room massage available',
    'Hypo-allergenic bedding available',
    'Roll-in shower',
    'Coffee/tea maker',
    'Eco-friendly toiletries',
    'Daily housekeeping',
    'Free cribs/infant beds',
    'Satellite TV service',
    'Wired Internet access (surcharge)',
    'Connecting/adjoining rooms available',
    'In-room childcare (surcharge)',
    'Private bathroom',
    'Linens provided',
    'Blackout drapes/curtains',
    'Pillow menu',
    'Free WiFi',
    'Air conditioning',
    'Room service (24 hours)',
    'Toilet paper',
    'Laptop-friendly workspace'
  ],
  bedTypes: [{id: '37336', name: '1 Twin Bed'}],
  squashedIds: [],
  description:
    '<p><strong>1 Twin Bed</strong></p><p>280 sq feet </p><br/><p><b>Relax</b> - In-room massage available</p><p><b>Internet</b> - Free WiFi </p><p><b>Entertainment</b> - Flat-screen TV with premium channels and pay movies</p><p><b>Food & Drink</b> - Coffee/tea maker, electric kettle, and 24-hour room service</p><p><b>Sleep</b> - Hypo-allergenic bedding, a pillow menu, and blackout drapes/curtains </p><p><b>Bathroom</b> - Private bathroom, bathtub or shower, free toiletries, and a hair dryer</p><p><b>Practical</b> - Laptop-compatible safe, twin sofa bed, and iron/ironing board; free cribs/infant beds available on request</p><p><b>Comfort</b> - Air conditioning and daily housekeeping</p><p><b>Accessibility</b> - Low-height view port in door, wheelchair-width doorways, portable shower seat, roll-in shower, wheelchair accessible, low-height counter and sink, grab bar near toilet, height-adjustable showerhead, and bathroom emergency pull cord</p><p><b>Eco-friendly</b> - Eco-friendly toiletries</p><p>Non-Smoking, renovated in December 2017</p><p>Connecting/adjoining rooms can be requested, subject to availability </p>',
  id: '0e94543fefc02854c88f4956818b50eee3d7f25b4b34ad606e8ec0e979dae434',
  masterId: '90807060',
  images: [
    {
      url: 'https://i.travelapi.com/hotels/2000000/1460000/1452100/1452083/d53f269d_z.jpg',
      thumbnailUrl:
        'https://i.travelapi.com/hotels/2000000/1460000/1452100/1452083/d53f269d_z.jpg'
    },
    {
      url: 'https://i.travelapi.com/hotels/2000000/1460000/1452100/1452083/a09642c9_z.jpg',
      thumbnailUrl:
        'https://i.travelapi.com/hotels/2000000/1460000/1452100/1452083/a09642c9_z.jpg'
    },
    {
      url: 'https://i.travelapi.com/hotels/2000000/1460000/1452100/1452083/910b608c_z.jpg',
      thumbnailUrl:
        'https://i.travelapi.com/hotels/2000000/1460000/1452100/1452083/910b608c_z.jpg'
    }
  ],
  name: 'Classic Twin Room, 1 Twin Bed',
  occupationPerRoom: 3,
  offers: [
    {
      metadata: {
        providerRateType: 'default',
        feedID: '123',
        originalAccessTier: ''
      },
      matchedOfferPriceDiff: 0,
      matchedDim: {} as MatchedDim,
      providerCode: 'TEST',
      rateId: '435719',
      merchantOfRecord: 'getaroom',
      availableRooms: 2147483647,
      cancellationPenalties: [
        {
          currency: 'EUR',
          end: '2020-08-12T12:00:00.000+02:00',
          amount: '454',
          start: '2020-08-09T12:00:00.000+02:00'
        }
      ],
      cug: [],
      id: 'a56bf9e91f6d6dd0c03c8bee46a8dcc1',
      links: [
        {
          href: 'http://localhost:3000/checkout/?hotelID=1055713&checkIn=2022-02-26&checkOut=2022-02-27&currency=GBP&locale=en&rooms=2&offer_token=75ac2c27-0226-4dfa-9484-bdb4d1949ec8',
          method: 'GET',
          type: ActionLinkTypes.CHECKOUT
        }
      ],
      postPaid: false,
      prices: [
        {
          currencyCode: 'USD',
          chargeable: {
            base: 345.45,
            nightlyBase: 172.72,
            taxes: 34.51,
            nightlyTaxes: 17.25,
            nightlyDisplayTotal: 379.96
          },
          hotelFees: {
            total: 42.46,
            nightly: 21.23,
            currencyCode: 'USD',
            breakdown: []
          },
          type: PriceTypes.DISPLAY_PRICE
        },
        {
          currencyCode: 'JPY',
          chargeable: {
            base: 1345.45,
            nightlyBase: 1172.72,
            taxes: 134.51,
            nightlyTaxes: 117.25,
            nightlyDisplayTotal: 379.96
          },
          hotelFees: {
            total: 142.46,
            nightly: 121.23,
            breakdown: [],
            currencyCode: 'JPY'
          },
          type: PriceTypes.CHARGEABLE_PRICE
        }
      ],
      providerRateId: '244316800',
      providerRateType: 'default',
      refundable: true,
      services: [],
      canPayLater: true
    }
  ],
  smokingOptionsAvailable: false
}
