import React, {useEffect} from 'react'
import {BookingError} from 'hooks/useBookingError'

import {ContentWrapper} from '@daedalus/atlas/helpers/ContentWrapper'
import {Text} from '@daedalus/atlas/Text'
import {useScrollTo} from '@daedalus/core/src/_web/utils/browser/hooks/useScrollTo'
import {ErrorCategory} from '@daedalus/core/src/booking/types/ErrorCategory'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {ErrorBanner} from '@daedalus/shared/src/booking/ErrorBannerRedesigned'

export const getIsPaymentNotAuthorized = (error?: BookingError) => {
  return error?.data?.details?.category === ErrorCategory.PaymentNotAuthorized
}

export const PaymentNotAuthorized = React.memo(() => {
  const {ref, scroll} = useScrollTo(500, 100)

  useEffect(() => {
    scroll()
  }, [])

  return (
    <ContentWrapper paddingY="s600">
      <div ref={ref}>
        <ErrorBanner
          title={
            <FormattedMessageWrapper
              id="requestErrorTitle"
              defaultMessage="Payment failed"
            />
          }
        >
          <Text variant="bodyS">
            <FormattedMessageWrapper
              id="errors.genericPaymentDeclinedDescription"
              defaultMessage="Try a different payment method or contact your bank"
            />
          </Text>
        </ErrorBanner>
      </div>
    </ContentWrapper>
  )
})

PaymentNotAuthorized.displayName = 'PaymentNotAuthorized'
