import {CancellationPenalties} from '@findhotel/sapi'

import {CancellationPenalty} from '../types/offer'

export enum RefundType {
  freeCancellation,
  partialRefund,
  nonRefundable
}

export const freeCancellationPenalty = (
  cancellationPenalty: CancellationPenalty | CancellationPenalties
): boolean => {
  const {amount} = cancellationPenalty
  return !Number(amount)
}

export const getLastCancellationDeadline = (
  cancellationPenalties: CancellationPenalty[]
) => {
  return cancellationPenalties[cancellationPenalties.length - 1]?.end
}

export const getCancellationDeadline = (
  cancellationPenalties: CancellationPenalty[] | CancellationPenalties[]
): string | undefined => {
  const firstPenalty = cancellationPenalties?.[0]
  if (!firstPenalty) return

  return freeCancellationPenalty(firstPenalty)
    ? firstPenalty.end
    : firstPenalty.start
}

export const getRefundType = (cancellationPenalties: CancellationPenalty[]) => {
  if (!cancellationPenalties || cancellationPenalties.length === 0)
    return RefundType.nonRefundable

  const hasFreeCancellation = freeCancellationPenalty(
    cancellationPenalties?.[0]
  )

  if (hasFreeCancellation) return RefundType.freeCancellation
  return RefundType.partialRefund
}

export const getDateTimeAndTimezone = (date: string) => {
  const splittedDate: readonly string[] = date.split('T')
  const time = splittedDate[1]
  const isZuluTime = time.slice(-1).toLowerCase() === 'z'

  if (isZuluTime) {
    const zuluTimeZone = time.slice(-1)
    return {
      date: date.replace(zuluTimeZone, ''),
      timeZone: '00:00'
    }
  }

  // the last 5 chars are the timezone
  const timeZone = time.slice(-6)
  // the timezone could be separated by '+' or '-'
  const timezoneSeparator = timeZone[0]
  if (['+', '-'].includes(timezoneSeparator)) {
    return {
      date: date.replace(timeZone, ''),
      timeZone
    }
  }

  return {
    date,
    timeZone: undefined
  }
}

export const formatTimeZone = (timezone?: string) => {
  // timezone string example: +08:00
  if (!timezone || timezone === '00:00') return ''
  const operator = timezone[0]
  const timezoneNoOperator = timezone.slice(1)
  const hoursAndMinutes = timezoneNoOperator.split(':')
  // If the minutes part of the timezone is zero, remove that part for easier reading
  if (hoursAndMinutes[1] === '00') {
    if (hoursAndMinutes[0].startsWith('0'))
      return `${operator}${hoursAndMinutes[0][1]}`
    return `${operator}${hoursAndMinutes[0]}`
  }

  if (hoursAndMinutes[0].startsWith('0')) {
    return `${operator}${hoursAndMinutes[0][1]}:${hoursAndMinutes[1]}`
  }

  return `${operator}${timezoneNoOperator}`
}
