import React from 'react'
import {styled} from '@linaria/react'
import {SizesType} from 'types/Sizes'
import {ColorPath} from 'types/Theme'

import * as iconsList from '@findhotel/atlas-assets'

import {getCssColorByPath} from '../../../utils/colors'

export type IconSize = Exclude<SizesType, 'xxl'>

export type IconName = keyof typeof iconsList

interface Props {
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Specify the color path from the theme's colors file to explicitly set the Icon's color */
  colorPath?: ColorPath
  /** The name of the custom icon that should be retrieved and rendered */
  name: IconName
  /** The size of the component */
  size?: IconSize
}

export const Wrapper = styled.div`
  display: flex;
`

export const iconSizeMap = {
  xs: 12,
  sm: 16,
  md: 20,
  lg: 24,
  xl: 32
}

export const Icon = ({name, colorPath, size = 'md', className}: Props) => {
  const color = colorPath ? getCssColorByPath(colorPath) : 'currentColor'
  const IconElement = iconsList[name]

  if (!IconElement) return null

  return (
    <Wrapper className={className}>
      <IconElement color={color} size={iconSizeMap[size]} />
    </Wrapper>
  )
}

export * as iconsList from '@findhotel/atlas-assets'
