// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgOneFinger = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4a2 2 0 0 1 4 0v4.5h3.821A4.179 4.179 0 0 1 18 12.679 5.321 5.321 0 0 1 12.679 18h-1.63a8.25 8.25 0 0 1-7.644-5.145L2.06 9.543a.768.768 0 0 1 .158-.822 2.355 2.355 0 0 1 3.504.126L6 9.18V4Zm2-.5a.5.5 0 0 0-.5.5v7.25a.75.75 0 0 1-1.326.48L4.57 9.808a.855.855 0 0 0-.895-.274l1.12 2.756a6.75 6.75 0 0 0 6.253 4.21h1.63c2.111 0 3.822-1.71 3.822-3.821 0-1.48-1.2-2.679-2.679-2.679H9.25a.75.75 0 0 1-.75-.75V4a.5.5 0 0 0-.5-.5Z"
      fill="#6C6770"
    />
  </svg>
)

export default SvgOneFinger
