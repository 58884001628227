// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgApple = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="M9.46 5.69c.907.076 1.815-.454 2.382-1.126.558-.69.927-1.618.832-2.564-.803.038-1.796.53-2.364 1.22-.52.597-.964 1.562-.85 2.47ZM9.601 6.617c.624 0 1.75-.823 3.064-.747.51.038 1.985.189 2.93 1.59-.075.056-1.748 1.021-1.73 3.046.02 2.422 2.119 3.226 2.138 3.245-.02.057-.332 1.145-1.097 2.261-.672.984-1.362 1.95-2.459 1.968-1.059.019-1.409-.634-2.628-.634-1.21 0-1.608.615-2.61.653-1.059.038-1.863-1.04-2.534-2.025-1.371-1.987-2.42-5.6-1.002-8.042.69-1.22 1.948-1.987 3.3-2.006 1.04-.018 1.995.691 2.628.691Z" />
  </svg>
)

export default SvgApple
