import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {BrandCode} from '@daedalus/core/src/brand/types'

export interface MetaState {
  brandCode?: BrandCode
  anonymousId?: string
  userCountry?: string
  userIp?: string
  searchId: string
  deviceId?: string
}

export const initialState: MetaState = {
  brandCode: undefined,
  anonymousId: undefined,
  userCountry: undefined,
  userIp: undefined,
  searchId: '',
  deviceId: undefined
}

interface SetUserCountry {
  userCountry: string
}

interface SetUserIp {
  userIp: string
}

const {actions, reducer} = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    setUserCountry: (state, {payload}: PayloadAction<SetUserCountry>) => {
      state.userCountry = payload.userCountry
    },
    setUserIp: (state, {payload}: PayloadAction<SetUserIp>) => {
      state.userIp = payload.userIp
    },
    setSearchId: (state: MetaState, action: PayloadAction<string>) => {
      state.searchId = action.payload
    },
    setDeviceId: (state, {payload}: PayloadAction<{deviceId: string}>) => {
      state.deviceId = payload.deviceId
    }
  }
})

export default reducer

export const {setUserCountry, setUserIp, setSearchId, setDeviceId} = actions
