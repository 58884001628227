import {cookieSecuritySettings} from '@daedalus/core/src/_web/utils/cookies/cookieSecuritySettings'

const maxAge = 1 / 48 // 30 minutes

export const simulationConfig = {
  name: 'simulate',
  options: {
    maxAge,
    ...cookieSecuritySettings(true)
  }
}
