import React from 'react'

import {scrollToElement} from '..'

export const useScrollTo = (duration = 250, offset = 0) => {
  const ref = React.useRef<HTMLDivElement>(null)

  const scroll = (customDuration = duration, customOffset = offset) => {
    scrollToElement(ref.current, customDuration, customOffset)
  }

  return {
    scroll,
    ref
  }
}
