import {useEffect} from 'react'

import {getCookie, setCookie} from '..'

export const useSyncToCookieFromUrlParam = (
  paramName: string,
  urlParam?: string
) => {
  const cookies = getCookie()
  const shouldSync = urlParam && urlParam !== cookies[paramName]

  useEffect(() => {
    if (shouldSync) {
      setCookie(paramName, urlParam)
    }
  }, [])
}
