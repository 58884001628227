// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgShieldTick = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.307 2.066a.75.75 0 0 0-.614 0l-7.25 3.25a.75.75 0 0 0-.441.634L2.75 6l-.748-.05v.004L2 5.962l-.002.029a14.072 14.072 0 0 0-.016.48c-.005.322-.003.779.026 1.326.056 1.089.218 2.556.645 4.036.426 1.475 1.131 3.013 2.308 4.19C6.158 17.219 7.802 18 10 18c2.198 0 3.842-.781 5.038-1.977 1.177-1.177 1.881-2.715 2.308-4.19.427-1.48.589-2.947.645-4.036a19.743 19.743 0 0 0 .015-1.703A7.346 7.346 0 0 0 18 5.991l-.002-.029v-.011L17.25 6l.748-.05a.75.75 0 0 0-.441-.634l-7.25-3.25Zm-6.824 4.43v-.003L10 3.572l6.517 2.921v.002c.004.294.003.716-.024 1.224-.053 1.02-.204 2.365-.589 3.698-.386 1.338-.994 2.612-1.927 3.545-.913.913-2.175 1.538-3.977 1.538s-3.064-.625-3.978-1.538c-.932-.933-1.54-2.207-1.926-3.545-.386-1.333-.536-2.678-.59-3.698a18.27 18.27 0 0 1-.023-1.224Zm9.375 1.693a.75.75 0 0 0-1.216-.878l-2.684 3.717-.632-.758a.75.75 0 1 0-1.152.96l1.25 1.5a.75.75 0 0 0 1.184-.04l3.25-4.5Z"
    />
  </svg>
)

export default SvgShieldTick
