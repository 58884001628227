// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgAlert = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.066 3.942a1.508 1.508 0 0 0-2.132 0L3.942 8.934a1.508 1.508 0 0 0 0 2.132l4.992 4.992a1.508 1.508 0 0 0 2.132 0l4.992-4.992a1.508 1.508 0 0 0 0-2.132l-4.992-4.992Zm-3.193-1.06a3.008 3.008 0 0 1 4.254 0l4.992 4.991a3.008 3.008 0 0 1 0 4.254l-4.992 4.992a3.008 3.008 0 0 1-4.254 0l-4.992-4.992a3.008 3.008 0 0 1 0-4.254l4.992-4.992ZM9.25 13a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 0 1.5H10a.75.75 0 0 1-.75-.75Zm1.5-6.25a.75.75 0 0 0-1.5 0v3.5a.75.75 0 0 0 1.5 0v-3.5Z"
    />
  </svg>
)

export default SvgAlert
