import {useContext} from 'react'

import {
  TeamContext,
  TeamContextType
} from '../components/TeamContext/TeamContext'

export const useTrackingTeam = () => {
  const context = useContext<TeamContextType>(TeamContext)

  return context.team
}
