import React, {useContext, useDebugValue, useEffect, useState} from 'react'
import throttle from 'lodash/throttle'
import {memoizeWith} from 'ramda'

// TODO - refactor this into a theme util
import {
  Breakpoints,
  BreakpointType,
  deviceWidthToBreakpointsMapping
} from '../utils/breakpoints'

interface DeviceLayoutBreakpointsTypes {
  isDesktopXs: boolean
  isDesktopSm: boolean
  isDesktopMd: boolean
  isDesktopLg: boolean
  isMobileSm: boolean
  isMobileLg: boolean
}

export interface DeviceLayoutHookReturnType
  extends DeviceLayoutBreakpointsTypes {
  isMobile: boolean
  isDesktop: boolean
}

export const getDeviceBreakpointLayout = () => {
  if (typeof window === 'undefined') return
  return deviceWidthToBreakpointsMapping(window?.innerWidth)
}

export const breakpointToDeviceType = (deviceBreakpoint?: Breakpoints) => {
  if (!deviceBreakpoint) {
    return {
      isMobile: true,
      isDesktop: false
    }
  }

  return {
    isMobile: [Breakpoints.mobileSm, Breakpoints.mobileLg].includes(
      deviceBreakpoint
    ),
    isDesktop: [
      Breakpoints.desktopXs,
      Breakpoints.desktopSm,
      Breakpoints.desktopMd,
      Breakpoints.desktopLg
    ].includes(deviceBreakpoint)
  }
}

// Intentionally NOT useMemo, this will reuse one cache across all useDeviceLayout hook implementations instead of creating a cache for each one
const breakPointToHookValue = memoizeWith(
  (deviceBreakpoint: Breakpoints | undefined) => deviceBreakpoint || 'unknown',
  (deviceBreakpoint: Breakpoints | undefined): DeviceLayoutHookReturnType => {
    const deviceLayout = breakpointToDeviceType(deviceBreakpoint)
    return {
      ...deviceLayout,
      isDesktopXs: deviceBreakpoint === Breakpoints.desktopXs,
      isDesktopSm: deviceBreakpoint === Breakpoints.desktopSm,
      isDesktopMd: deviceBreakpoint === Breakpoints.desktopMd,
      isDesktopLg: deviceBreakpoint === Breakpoints.desktopLg,
      isMobileSm: deviceBreakpoint === Breakpoints.mobileSm,
      isMobileLg: deviceBreakpoint === Breakpoints.mobileLg
    }
  }
)

export const DeviceLayoutContext = React.createContext<
  BreakpointType | undefined
>(Breakpoints.desktopMd)

export const useDeviceLayout = (): DeviceLayoutHookReturnType => {
  const deviceBreakpoint = useContext(DeviceLayoutContext)
  const output = breakPointToHookValue(deviceBreakpoint)
  useDebugValue(output)
  return output
}

export default DeviceLayoutContext.Consumer

const THROTTLING_INTERVAL = 200 // ms

interface DeviceLayoutProviderProps {
  children: React.ReactNode
  value?: BreakpointType
}

export const DeviceLayoutProvider = ({
  children,
  value = getDeviceBreakpointLayout()
}: DeviceLayoutProviderProps) => {
  const [layout, setLayout] = useState(value)

  useEffect(() => {
    const updateResize = () => {
      setLayout(getDeviceBreakpointLayout())
    }

    const throttledFn = throttle(updateResize, THROTTLING_INTERVAL)

    window.addEventListener('resize', throttledFn)
    return () => {
      window.removeEventListener('resize', throttledFn)
    }
  }, [])

  return (
    <DeviceLayoutContext.Provider value={layout}>
      {children}
    </DeviceLayoutContext.Provider>
  )
}
