import React from 'react'
import {styled} from '@linaria/react'

import {cssTheme} from '../../../themes'
import {ButtonSize} from '.'

interface Props {
  size?: ButtonSize
  className?: string
}

const bulletRadius = {
  sm: 8,
  md: 8,
  lg: 12,
  xl: 12
}

export const ButtonLoadingWrapper = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${cssTheme.layout.spacing.s300};
`

export const LoadingBullet = styled.div<{
  size: ButtonSize
  delay: string
}>`
  display: inline-block;
  opacity: 0;
  border-radius: ${cssTheme.layout.radius.rounded};
  background: currentColor; /* inherit button font color*/
  height: ${({size}) => bulletRadius[size]}px;
  width: ${({size}) => bulletRadius[size]}px;
  animation: fadeInOut 0.6s infinite alternate;
  animation-delay: ${({delay}) => delay};

  @keyframes fadeInOut {
    from {
      opacity: 0.3;
    }

    to {
      opacity: 0.8;
    }
  }
`

export const LoadingBullets = ({size = 'md', className}: Props) => {
  return (
    <ButtonLoadingWrapper className={className}>
      <LoadingBullet size={size} delay="0s" />
      <LoadingBullet size={size} delay="0.2s" />
      <LoadingBullet size={size} delay="0.4s" />
    </ButtonLoadingWrapper>
  )
}
