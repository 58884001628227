import React, {useEffect} from 'react'
import {BookingError} from 'hooks/useBookingError'

import {Button} from '@daedalus/atlas/Button'
import {ContentWrapper} from '@daedalus/atlas/helpers/ContentWrapper'
import {Text} from '@daedalus/atlas/Text'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {useScrollTo} from '@daedalus/core/src/_web/utils/browser/hooks/useScrollTo'
import {Category as ErrorCategory} from '@daedalus/core/src/api-types/bovio/response/error'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {ErrorBanner} from '@daedalus/shared/src/booking/ErrorBannerRedesigned'

export const getIsGenericSupplierOrBackendError = (error?: BookingError) => {
  return error?.data?.details?.category === ErrorCategory.Other
}

const GenericSupplierOrBackendErrorComponent = () => {
  const {ref, scroll} = useScrollTo(500, 100)
  const {brand: currentBrand} = useBrand()

  useEffect(() => {
    scroll()
  }, [])

  const redirectToContactSupport = () => {
    window.open(`${currentBrand.contactUrl}`, '_blank')
  }

  return (
    <ContentWrapper paddingBottom="s400">
      <div ref={ref}>
        <ErrorBanner
          title={
            <FormattedMessageWrapper
              id="errors.bookingFailedTitle"
              defaultMessage="Booking failed"
            />
          }
        >
          <>
            <Text variant="bodyS">
              <FormattedMessageWrapper
                id="errors.bookingErrorDescription"
                defaultMessage="Try again or if the problem persists, contact support"
              />
            </Text>
            <Button
              size="sm"
              variant="secondary"
              fullWidth
              onClick={redirectToContactSupport}
            >
              <FormattedMessageWrapper
                id="errors.contactSupport"
                defaultMessage="Contact support"
              />
            </Button>
          </>
        </ErrorBanner>
      </div>
    </ContentWrapper>
  )
}

export const GenericSupplierOrBackendError = React.memo(
  GenericSupplierOrBackendErrorComponent
)
