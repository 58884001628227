// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCornerFlag = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.613 2.107a.75.75 0 0 1 .738-.02l4.25 2.25a.75.75 0 0 1 .056 1.293L10.75 8.158v4.175l6.895 4.28a.75.75 0 0 1-.79 1.274l-3.268-2.028c-.073.211-.18.446-.338.682-.531.797-1.53 1.46-3.249 1.46-1.718 0-2.718-.663-3.249-1.46a3.017 3.017 0 0 1-.338-.682l-3.267 2.028a.75.75 0 0 1-.792-1.274l6.896-4.28V2.75a.75.75 0 0 1 .363-.643Zm-1.86 12.92a1.55 1.55 0 0 0 .246.681c.219.33.72.792 2.001.792s1.782-.463 2.001-.791a1.552 1.552 0 0 0 .246-.681L10 13.633l-2.247 1.395Zm2.997-8.656 2.02-1.306-2.02-1.07v2.376Z"
    />
  </svg>
)

export default SvgCornerFlag
