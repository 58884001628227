// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgAnnotationDots = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.75 2A3.75 3.75 0 0 0 2 5.75v6.5A3.75 3.75 0 0 0 5.75 16h1.372l2.423 1.846a.75.75 0 0 0 .91 0L12.877 16h1.372A3.75 3.75 0 0 0 18 12.25v-6.5A3.75 3.75 0 0 0 14.25 2h-8.5ZM3.5 5.75A2.25 2.25 0 0 1 5.75 3.5h8.5a2.25 2.25 0 0 1 2.25 2.25v6.5a2.25 2.25 0 0 1-2.25 2.25h-1.625a.75.75 0 0 0-.455.153L10 16.307l-2.17-1.654a.75.75 0 0 0-.455-.153H5.75a2.25 2.25 0 0 1-2.25-2.25v-6.5ZM7 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm2 1a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm4-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
    />
  </svg>
)

export default SvgAnnotationDots
