import {CookieConfig} from 'types/Cookie'

import {getCookie, setCookie} from '../../_web/utils/cookies'
import userIpCookieConfig from './cookieConfig'

const getCookieOptions = (cookieConfig: CookieConfig) => {
  const {secure, sameSite, maxAge: expires, domain} = cookieConfig.options
  return {
    expires,
    secure,
    sameSite,
    domain
  }
}

export const getPersistedUserIp = () => getCookie(userIpCookieConfig.name)

export const persistUserIp = (userIp: string) =>
  setCookie(
    userIpCookieConfig.name,
    userIp,
    getCookieOptions(userIpCookieConfig)
  )
