import {assocPath, pipe, prop, sortBy, values} from 'ramda'

import {CountryType} from '@daedalus/core/src/localization/types/Country'

import {ModuleActionType} from './actions'
import {COUNTRY_LIST_FETCH_DONE, Data} from './actions/fetchCountryList'

export type LocalizationStateType = {
  countriesList: CountryType[]
}

const initialState = {
  countriesList: []
}

const sortAlphabetically = pipe(values, sortBy(prop('name')))

export default (
  state: LocalizationStateType = initialState,
  action: ModuleActionType
) => {
  switch (action.type) {
    case COUNTRY_LIST_FETCH_DONE:
      return assocPath(
        ['countriesList'],
        sortAlphabetically(action.data as Data),
        state
      )

    default:
      return state
  }
}
