import {useSelector} from 'react-redux'

import {selectIsAuthenticated} from '@daedalus/core/src/auth/modules/selectors'
import {defaultBrand} from '@daedalus/core/src/brand/config'
import {Brand} from '@daedalus/core/src/brand/types'
import {checkIsBrandVio} from '@daedalus/core/src/brand/utils'

export const useIsReferralProgramEnabled = (brand: Brand = defaultBrand) => {
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const brandIsVio = checkIsBrandVio(brand)

  return brandIsVio && isAuthenticated
}
