import {useEffect, useRef} from 'react'

/**
 *
 * usePreviousValue is a hook that uses the useRef hook internally for storing the previous value
 * @returns Return previous value (happens before update in useEffect)
 * @param value - The current value to store in the ref
 * @example
 * ```typescript
 * const [count, setCount] = useState(0);
 * const prevCount = usePreviousValue(count);
 * setCount(count + 1)
 * console.log(count)  // 0
 * ```
 */
export const usePreviousValue = <Value>(value: Value): Value | undefined => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<Value>()

  useEffect(() => {
    ref.current = value // eslint-disable-line fp/no-mutation
  }, [value]) // Only re-run if value changes

  return ref.current
}
