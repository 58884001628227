import {and, equals} from 'ramda'

const MS_PER_DAY = 1000 * 60 * 60 * 24

export const calculateDateDifference = (date1: Date, date2: Date) => {
  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())

  return Math.abs(Math.floor((utc2 - utc1) / MS_PER_DAY))
}

/**
 * Checks where 2 pairs of check-in and check-out are are equal in between them.
 *
 * @param currentCheckIn - The current check-in date to be tested
 * @param currentCheckOut - The current check-out date to be tested
 * @param newCheckIn - The new check-in date to be tested
 * @param newCheckOut - The new check-out date to be tested
 */
export const areSameCheckInOutDates = (
  currentCheckIn: string,
  currentCheckOut: string,
  newCheckIn: string,
  newCheckOut: string
): boolean =>
  and(equals(currentCheckIn, newCheckIn), equals(currentCheckOut, newCheckOut))
