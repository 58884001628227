// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgDrag = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 12 12"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 2.25a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0V3a.75.75 0 0 1 .75-.75Zm7 0a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0V3a.75.75 0 0 1 .75-.75ZM6.75 3a.75.75 0 0 0-1.5 0v6a.75.75 0 0 0 1.5 0V3Z"
    />
  </svg>
)

export default SvgDrag
