/* eslint-disable fp/no-mutation */
import React from 'react'
import {isNil} from 'ramda'

// requestAnimationFrame for Smart Animating http://goo.gl/sx5sts
const requestAnimFrame = (() =>
  requestAnimationFrame ||
  // eslint-disable-next-line func-names
  function (callback) {
    setTimeout(callback, 1000 / 60)
  })()

// t = current time
// b = start value
// c = change in value
// d = duration
const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
  t /= d / 2
  if (t < 1) return (c / 2) * t * t + b
  t--
  return (-c / 2) * (t * (t - 2) - 1) + b
}

const scroll = (
  callback: (val: number) => void,
  start: number,
  to: number,
  duration: number
) => {
  const change = to - start
  let currentTime = 0
  const increment = 20

  // Make sure duration is a multiplication of increment
  // so we end up with exactly the right amount of frames
  const durationNormalised = duration - (duration % increment)

  const animateScroll = () => {
    currentTime += increment
    callback(easeInOutQuad(currentTime, start, change, durationNormalised))

    if (currentTime < durationNormalised) {
      requestAnimFrame(animateScroll)
    }
  }

  requestAnimFrame(animateScroll)
}

/**
 * Performs scroll of scrollable element
 *
 * @param element - HTML node of element
 * @param to - amount of pixels to scroll
 * @param duration - duration of scroll in ms
 **/
const scrollTo = (element: HTMLElement, to: number, duration: number) => {
  const callback = (val: number) => {
    element.scrollTop = val
  }

  scroll(callback, element.scrollTop, to, duration)
}

/**
 * Performs page scroll to given element
 *
 * @param element - HTML node of element
 * @param duration - duration of scroll in ms
 * @param offset - element offset from the top edge of viewport after scroll stops
 **/
const scrollToElement = (
  element: HTMLElement | null | undefined,
  duration = 250,
  offset = 0
) => {
  if (!element) return

  const elementTopPositon = element.getBoundingClientRect().top

  const to = window.pageYOffset + elementTopPositon - offset
  const callback = (val: number) => {
    window.scrollTo(0, val)
  }

  if (duration === 0) {
    window.scrollTo(0, to)
  } else {
    scroll(callback, window.pageYOffset, to, duration)
  }
}

/**
 * Performs container scroll to given element
 *
 * @param container - HTML node of container
 * @param element - HTML node of element which container should scroll to
 * @param duration - duration of scroll in ms
 * @param offset - element offset from the top edge of container after scroll stops
 **/
const scrollContainerToElement = (
  container: HTMLElement,
  element: HTMLElement,
  duration = 250,
  offset = 0
) => {
  const containerScrollTop = container.scrollTop
  const containerTopPositon = container.getBoundingClientRect().top
  const elementTopPositon = element.getBoundingClientRect().top

  const to =
    elementTopPositon - containerTopPositon - offset + containerScrollTop

  if (duration === 0) {
    container.scrollTo(0, to)
  } else {
    const callback = (val: number) => {
      container.scrollTop = val
    }

    scroll(callback, container.scrollTop, to, duration)
  }
}

const scrollToWindowTop = () => window.scrollTo(0, 0)

/**
 * Performs page scroll to given element so that it is centered in the viewport
 *
 * @param elementRef - valid string selector
 * @param duration - duration of scroll in ms
 * @param startDelay - delay before scroll starts in ms
 **/
const scrollToElementOnCenter = (
  elementRef: string,
  duration = 2000,
  startDelay = 500
) => {
  const element = document.querySelector(elementRef)
  if (isNil(element)) {
    return
  }

  const elementRect = element.getBoundingClientRect()
  const absoluteElementTop = elementRect.top + window.pageYOffset
  const to = absoluteElementTop - window.innerHeight / 2

  const callback = (val: number) => {
    window.scrollTo(0, val)
  }

  setTimeout(() => {
    scroll(callback, window.pageYOffset, to, duration)
  }, startDelay)
}

/**
 * Checks if given element is in vieport
 *
 * @param element - DOM-element
 * @returns Boolean
 **/
const isInViewport = (element: HTMLElement | null | undefined): boolean => {
  const distance = (element as HTMLElement).getBoundingClientRect()
  return (
    distance.top >= 0 &&
    distance.left >= 0 &&
    distance.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    distance.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  )
}

/**
 * Returns data-id of the target if it has one, or traverses up the DOM
 * to find the data-id of the closest ancestor, if one exists.
 *
 * @param e - Event object to get data-id of closest element for
 * @returns Closest data-id attribute of element or ancestor
 */
const getClosestDataIdToEventTarget = (
  e: React.MouseEvent
): string | undefined => {
  const targetElement = e.target as HTMLElement
  const closestElementWithDataId: HTMLElement | null =
    targetElement.closest('[data-id]')

  return closestElementWithDataId?.dataset?.id
}

/**
 * Sanitizes supplied string to be suitable as data-id DOM attributes, prepended with
 * `_` for easy concatenation.
 *
 * @example Input with non-valid characters
 * ```ts
 * generateValidDataId('Hotel & Spa Name') // '_Hotel___Spa_Name'
 * ```
 *
 * @param id - String to sanitize
 * @returns Sanitized valid id
 */
const generateValidDataId = (id: string): string => '_' + id.replace(/\W/g, '_')

export {
  generateValidDataId,
  getClosestDataIdToEventTarget,
  isInViewport,
  scrollContainerToElement,
  scrollTo,
  scrollToElement,
  scrollToElementOnCenter,
  scrollToWindowTop
}
