import React from 'react'
import {FormattedDate} from 'react-intl'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Text} from '@daedalus/atlas/Text'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'
import {dateStringToMiddayDate} from '@daedalus/core/src/utils/date'

const DatesWrapper = styled('div')(
  () => css`
    flex-shrink: 0;
    text-align: right;
  `
)

type SelectedDatesLabelProps = {
  checkIn?: string | null
  checkOut?: string | null
  dateRange?: string | null
  isCompact?: boolean
}

const getCombinedDateRange = (
  dateValue: string,
  dateRange: string | null,
  isCompact: boolean
) => {
  return (
    <>
      <Text variant="titleS" as="span">
        {isCompact ? (
          <FormattedDate
            value={dateStringToMiddayDate(dateValue)}
            day="2-digit"
            month="short"
          />
        ) : (
          <FormattedDate
            value={dateStringToMiddayDate(dateValue)}
            day="2-digit"
            month="short"
            weekday="short"
          />
        )}
      </Text>{' '}
      {dateRange && (
        <Text variant="titleS" as="span">
          ± {dateRange}
        </Text>
      )}
    </>
  )
}

export const SelectedDatesLabel = ({
  checkIn,
  checkOut,
  dateRange = null,
  isCompact = false
}: SelectedDatesLabelProps) => {
  return (
    <DatesWrapper>
      {checkIn ? (
        getCombinedDateRange(checkIn, dateRange, isCompact)
      ) : (
        <Text variant="titleS" as="span" colorPath="content.neutral.c400">
          <FormattedMessageWrapper id="checkIn" defaultMessage="Check-in" />
        </Text>
      )}
      <Text variant="titleS" as="span">
        {' - '}
      </Text>
      {checkOut ? (
        getCombinedDateRange(checkOut, dateRange, isCompact)
      ) : (
        <Text variant="titleS" as="span" colorPath="content.neutral.c400">
          <FormattedMessageWrapper id="checkOut" defaultMessage="Check-out" />
        </Text>
      )}
    </DatesWrapper>
  )
}
