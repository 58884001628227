// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgHouse = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.426 2.133a.75.75 0 0 0-.852 0l-7.25 5A.75.75 0 0 0 2 7.75v7.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-7.5a.75.75 0 0 0-.324-.617l-7.25-5ZM3.5 15.25V8.144L10 3.661l6.5 4.483v7.106c0 .69-.56 1.25-1.25 1.25H13v-2.75A2.75 2.75 0 0 0 10.25 11h-.5A2.75 2.75 0 0 0 7 13.75v2.75H4.75c-.69 0-1.25-.56-1.25-1.25Zm5-1.5c0-.691.56-1.25 1.25-1.25h.5c.69 0 1.25.559 1.25 1.25v2.75h-3v-2.75Z"
    />
  </svg>
)

export default SvgHouse
