import {createSelector} from '@reduxjs/toolkit'
import getRoomConfig from 'components/data/URLParams/mappers/getRoomConfig'
import {getExistingBookingQueryParams} from 'modules/book/selectors'
import {RootState} from 'store'
import {urlParams} from 'utils/urlParams'

import {bookingRetrieveApi} from '@daedalus/core/src/booking/services/bookingRetrieveApi'
import {getCancellationDeadline} from '@daedalus/core/src/offer/business/cancellationPenalties'
import {
  GET_OFFER_CHECK_QUERY_KEY,
  GET_SPLIT_BOOKING_OFFER_CHECK_QUERY_KEY,
  offerCheckApi
} from '@daedalus/core/src/offer/services/offerCheckApi'
import {getEpochSeconds} from '@daedalus/core/src/utils/date'

import {PaymentFlowType} from './types'
import {
  existingBookingOfferCheckDataTransform,
  getPaymentFlowType
} from './utils'

export const getRegularOfferCheck = createSelector(
  offerCheckApi.endpoints.getOfferCheck.select(GET_OFFER_CHECK_QUERY_KEY),
  offerCheck => offerCheck
)

export const getExistingBookingOfferCheck = (state: RootState) => {
  const params = getExistingBookingQueryParams(state)
  const result =
    bookingRetrieveApi.endpoints.getBookingById.select(params)(state)
  return {
    ...result,
    data: result.data
      ? existingBookingOfferCheckDataTransform([result.data])
      : undefined
  }
}

export const getSplitBookingOfferCheck = createSelector(
  offerCheckApi.endpoints.getSplitBookingOfferCheck.select(
    GET_SPLIT_BOOKING_OFFER_CHECK_QUERY_KEY
  ),
  splitBookingOfferCheck => splitBookingOfferCheck
)

//returns unified offerCheck data wrapper for offer-check sb-offer-check and CL/DF booking data
export const getUnifiedOfferCheck = (state: RootState) => {
  const type = getPaymentFlowType()

  switch (type) {
    case PaymentFlowType.ExistingBooking:
      return getExistingBookingOfferCheck(state)
    case PaymentFlowType.SplitBooking:
      return getSplitBookingOfferCheck(state)
    default:
      return getRegularOfferCheck(state)
  }
}

export const getIsOfferCheckExistingBookingValid = createSelector(
  getUnifiedOfferCheck,
  ({data}) => Boolean(data?.bookingInfo && data.bookingInfo.confirmationId)
)

export const getOfferCheck = (state: RootState) => {
  const queryData = offerCheckApi.endpoints.getOfferCheck.select(
    GET_OFFER_CHECK_QUERY_KEY
  )(state)

  return queryData.data
}

export const getRoomNames = createSelector(
  getUnifiedOfferCheck,
  ({data: offerData}) => {
    const roomsConfig = getRoomConfig(urlParams())

    return new Array(roomsConfig.numberOfRooms).fill(
      offerData?.offers?.[0].room.name,
      0,
      roomsConfig.numberOfRooms
    )
  }
)

export const getRoomGuestName = createSelector(getUnifiedOfferCheck, ({data}) =>
  data?.rooms?.map(room => {
    return {firstName: room.firstName, lastName: room.lastName}
  })
)

export const getRoomIds = createSelector(getUnifiedOfferCheck, ({data}) => {
  return data?.offers?.map(offer => offer.room.id)
})

export const getOfferCheckPageViewContext = (state: RootState) => {
  const paymentFlowType = getPaymentFlowType()

  if (!paymentFlowType) return {}

  return {
    roomName: getRoomNames(state)
  }
}

export const getOfferCheckOfferContext = (state: RootState) => {
  const paymentFlowType = getPaymentFlowType()

  if (!paymentFlowType) return {}

  return {
    roomName: getRoomNames(state),
    roomId: getRoomIds(state)
  }
}

export const getEpochCancellationDeadline = createSelector(
  getOfferCheck,
  offer => {
    const cancellationDeadline = getCancellationDeadline(
      offer?.cancellationPenalties ?? []
    )
    return cancellationDeadline ? getEpochSeconds(cancellationDeadline) : null
  }
)

export const getOfferCheckError = createSelector(
  getUnifiedOfferCheck,
  offerCheck => offerCheck.error
)

export const getOfferCheckLoadingState = createSelector(
  getUnifiedOfferCheck,
  offerCheck => offerCheck.isSuccess
)

export const getOfferCheckMerchantOfRecord = createSelector(
  getUnifiedOfferCheck,
  ({data}) => data?.merchantOfRecord
)
