import React from 'react'
import {useSelector} from 'react-redux'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {CompactHeader} from '@daedalus/atlas/src/components/designSystem/CompactHeader'
import {MOBILE_HEADER_HEIGHT} from '@daedalus/atlas/src/components/designSystem/CompactHeader'
import {
  ANIMATION_TYPES,
  Overlay
} from '@daedalus/atlas/src/components/designSystem/Overlay'
import {
  selectIsAuthenticated,
  selectIsLoading
} from '@daedalus/core/src/auth/modules/selectors'
import {defaultBrand} from '@daedalus/core/src/brand/config'
import {Brand} from '@daedalus/core/src/brand/types'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {MembershipContent} from './MembershipContent'

type PropsType = {
  onClose: () => void
  isOpen: boolean
  brand: Brand
  anonymousId: string
  onExploreButtonClick?: () => void
}

const HeaderWrapper = styled.div(
  ({theme}) => css`
    position: relative;
    z-index: 2;
    border-bottom: 1px solid ${theme.colors.border.neutral.c100};
  `
)

const HeaderContentWrapper = styled.div(
  ({theme}) => css`
    font-family: ${theme.fonts.family.default};
    font-weight: ${theme.fonts.weight.lg};
    line-height: ${theme.fonts.lineHeight.xs};
    font-size: ${theme.fonts.size.md};
    color: ${theme.colors.content.neutral.c950};
  `
)

const OverlaySection = styled.section`
  height: calc(100% - ${MOBILE_HEADER_HEIGHT}px);
  display: flex;
  flex-direction: column;
`

export const MembershipOverlay = ({
  isOpen,
  onClose,
  brand = defaultBrand,
  anonymousId,
  onExploreButtonClick
}: PropsType) => {
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const isLoading = useSelector(selectIsLoading)

  return (
    <Overlay
      isOpen={isOpen}
      animationType={ANIMATION_TYPES.SLIDE_RIGHT}
      shadeBackground={false}
      onClose={onClose}
    >
      <HeaderWrapper>
        <CompactHeader
          brand={brand}
          onBackButtonClick={onClose}
          centerContent={
            <HeaderContentWrapper>
              <FormattedMessageWrapper
                id="membership.membershipTitle"
                defaultMessage="Membership"
              />
            </HeaderContentWrapper>
          }
        />
      </HeaderWrapper>
      <OverlaySection>
        <MembershipContent
          isMobile
          isOverlay
          isAuthenticated={isAuthenticated}
          isLoading={isLoading}
          anonymousId={anonymousId}
          brand={brand}
          onExploreButtonClick={onExploreButtonClick}
        />
      </OverlaySection>
    </Overlay>
  )
}
