// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgDoubleChevronRight = ({
  color = 'currentColor',
  size = 20
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.725 5.197a.75.75 0 0 1 1.02-1.1L16.51 9.45a.75.75 0 0 1 0 1.1l-5.764 5.352a.75.75 0 0 1-1.021-1.099L14.898 10 9.725 5.197Zm-6.235 0a.75.75 0 1 1 1.02-1.1l5.765 5.353a.75.75 0 0 1 0 1.1L4.51 15.902a.75.75 0 0 1-1.02-1.099L8.662 10 3.49 5.197Z"
    />
  </svg>
)

export default SvgDoubleChevronRight
