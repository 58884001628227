// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgBuildingStore = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.785 3.862A2.75 2.75 0 0 1 5.288 2.25h9.424a2.75 2.75 0 0 1 2.504 1.612l.538 1.183c.04.087.074.176.104.266.09.124.142.275.142.439 0 .077-.002.155-.007.233.005.066.007.133.007.2v9.067A2.75 2.75 0 0 1 15.25 18H4.75A2.75 2.75 0 0 1 2 15.25V6.183c0-.066.003-.131.007-.197A3.825 3.825 0 0 1 2 5.75c0-.164.053-.316.143-.44.03-.09.064-.178.104-.265l.538-1.183ZM3.5 8.608v6.642c0 .69.56 1.25 1.25 1.25H7v-2.75A2.75 2.75 0 0 1 9.75 11h.5A2.75 2.75 0 0 1 13 13.75v2.75h2.25c.69 0 1.25-.56 1.25-1.25V8.608A2.924 2.924 0 0 1 15 9a3.176 3.176 0 0 1-2.5-1.182A3.176 3.176 0 0 1 10 9a3.176 3.176 0 0 1-2.5-1.182A3.176 3.176 0 0 1 5 9a2.924 2.924 0 0 1-1.5-.392ZM8.25 5.75c0 .88.713 1.75 1.75 1.75s1.75-.87 1.75-1.75a.75.75 0 0 1 1.5 0c0 .88.713 1.75 1.75 1.75.516 0 .865-.206 1.101-.502A1.94 1.94 0 0 0 16.487 6a1.25 1.25 0 0 0-.099-.334l-.538-1.183a1.25 1.25 0 0 0-1.138-.733H5.288c-.49 0-.935.287-1.138.733l-.538 1.183a1.25 1.25 0 0 0-.098.336c.041.384.177.736.385.996.236.296.585.502 1.101.502 1.037 0 1.75-.87 1.75-1.75a.75.75 0 0 1 1.5 0ZM11.5 16.5v-2.75c0-.691-.56-1.25-1.25-1.25h-.5c-.69 0-1.25.559-1.25 1.25v2.75h3Z"
    />
  </svg>
)

export default SvgBuildingStore
