import {css} from '@emotion/react'

const fadeInOutWithVisibility = {
  exiting: css`
    opacity: 0;
    visibility: visible;
    transition: opacity 300ms ease-in;
  `,
  exited: css`
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms ease-in;
  `,
  entering: css`
    opacity: 0;
    visibility: visible;
    transition: opacity 300ms ease-in;
  `,
  entered: css`
    opacity: 1;
    visibility: visible;
    transition: opacity 300ms ease-in;
  `
}

const fadeInOutWithoutVisibility = {
  exiting: css`
    opacity: 0;
    visibility: visible;
    transition: opacity 300ms ease-in;
  `,
  exited: css`
    display: none;
    transition: opacity 300ms ease-in;
  `,
  entering: css`
    opacity: 0;
    visibility: visible;
    transition: opacity 300ms ease-in;
  `,
  entered: css`
    opacity: 1;
    visibility: visible;
    transition: opacity 300ms ease-in;
  `
}

export default {
  fadeInOutWithVisibility,
  fadeInOutWithoutVisibility
}
