// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCheck = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.676 4.133a.75.75 0 0 1 .191 1.043l-6.65 9.622a2.75 2.75 0 0 1-4.483.058l-2.59-3.547a.75.75 0 0 1 1.212-.885l2.59 3.548a1.25 1.25 0 0 0 2.038-.027l6.649-9.621a.75.75 0 0 1 1.043-.19Z"
    />
  </svg>
)

export default SvgCheck
