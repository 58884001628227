import {css} from '@emotion/react'
import {SerializedStyles} from '@emotion/utils'
import {mapObjIndexed} from 'ramda'
import {CSSDescriptionType} from 'types/Theme'

export enum Breakpoints {
  desktopLg = 'desktopLg',
  desktopMd = 'desktopMd',
  desktopSm = 'desktopSm',
  desktopXs = 'desktopXs',
  mobileLg = 'mobileLg',
  mobileSm = 'mobileSm'
}

export const breakpoints = {
  [Breakpoints.mobileSm]: 0,
  [Breakpoints.mobileLg]: 411,
  [Breakpoints.desktopXs]: 744,
  [Breakpoints.desktopSm]: 960,
  [Breakpoints.desktopMd]: 1280,
  [Breakpoints.desktopLg]: 1920
} as const

export type BreakpointType = keyof typeof breakpoints

type ClsType = string | SerializedStyles | CSSDescriptionType
type ReducedBreakPointsType<T = SerializedStyles> = Record<
  string,
  (cls: ClsType) => T
>

export const deviceWidthToBreakpointsMapping = (
  viewportWidth: number
): BreakpointType => {
  viewportWidth = Math.max(viewportWidth, 0)

  const breakpointDeviceMap: BreakpointType[] = [
    Breakpoints.desktopLg,
    Breakpoints.desktopMd,
    Breakpoints.desktopSm,
    Breakpoints.desktopXs,
    Breakpoints.mobileLg,
    Breakpoints.mobileSm
  ]

  const currentBreakpoint: BreakpointType | null | undefined =
    breakpointDeviceMap.find(breakpoint => {
      return viewportWidth >= breakpoints[breakpoint]
    })

  return currentBreakpoint || Breakpoints.mobileLg
}

export default breakpoints

const createBreakpoint = (
  label: string | number
): ((cls: ClsType) => SerializedStyles) => {
  const prefix = typeof label === 'string' ? '' : 'min-width:'
  const suffix = typeof label === 'string' ? '' : 'px'

  return (cls: ClsType) => css`
    @media (${prefix + label + suffix}) {
      ${cls};
    }
  `
}

export const createBreakpoints = (
  breakpoints: Record<string, number>
): ReducedBreakPointsType =>
  Object.keys(breakpoints).reduce(
    (accumulator: ReducedBreakPointsType, label: string) => {
      accumulator[label] = createBreakpoint(breakpoints[label])

      return accumulator
    },
    {
      retina: (cls: ClsType) => css`
        @media only screen and (-webkit-min-device-pixel-ratio: 2) {
          ${cls};
        }
      `
    }
  )

export const customMq = (label: number, cls: ClsType): SerializedStyles =>
  createBreakpoint(label)(cls)

export const mq: ReducedBreakPointsType = createBreakpoints(breakpoints)

const linariaCreateBreakpoint = (label: string | number) => {
  const prefix = typeof label === 'string' ? '' : 'min-width:'
  const suffix = typeof label === 'string' ? '' : 'px'

  return `@media (${prefix + label + suffix})`
}

export const linariaCreateBreakpoints = (
  breakpointsObj: typeof breakpoints
): Record<keyof typeof Breakpoints | 'retina', string> => ({
  ...mapObjIndexed(linariaCreateBreakpoint, breakpointsObj),
  retina: `@media only screen and (-webkit-min-device-pixel-ratio: 2)`
})

export const linariaCustomMq = (label: number): string =>
  linariaCreateBreakpoint(label)

export const linariaMq = linariaCreateBreakpoints(breakpoints)
