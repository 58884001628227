import {createAction, prepareAutoBatched} from '@reduxjs/toolkit'
import {ToggleIdType, VariantType} from 'opticks'

import {TrackEventPayload} from '../types/Events'

export const TRACK_USER_ACTION = 'TRACK_USER_ACTION'
export const TRACK_ERROR_TRIGGERED = 'TRACK_ERROR_TRIGGERED'
export const TRACK_EXPERIMENT_SERVED = 'TRACK_EXPERIMENT_SERVED'
export const TRACK_PROVIDER_REDIRECT = 'TRACK_PROVIDER_REDIRECT'

export interface TrackExperimentServedActionType {
  experimentId: ToggleIdType
  isActive: boolean
  type: typeof TRACK_EXPERIMENT_SERVED
  variationId: VariantType
}

interface TrackProviderRedirectBaseParams {
  intermediaryProvider: string
  redirectId: string
  offerUrl?: string
  sourceIp?: string
}
interface TrackProviderRedirectParamsOfferProps {
  offerId: string
  bundleId?: string
}

export type TrackProviderRedirectParams = TrackProviderRedirectBaseParams &
  TrackProviderRedirectParamsOfferProps

export type ProviderRedirectParams = {
  click_id?: string
  label?: string
  source_ip?: string
  user_agent?: string
  vclid?: string
  is_direct_supply?: boolean
  locale: string
  offer_id?: string
  bundle_id?: string
  provider_code: string
  redirect_id: string
  redirect_url: string //Should be a url of SRP with AP opened
  search_id: string
  hotel_id: string
  check_in: string
  check_out: string
  country_code: string
  device_type: string
}

/**
 * Returns the tracking action for an experiment being served
 * @param experimentId - the id of the experiment
 * @param variationId - the if of the experiment variation
 */
// TODO (@gabrielmanara) [2022-05-01]: Migrate to toolkit standard before finish the https://app.shortcut.com/findhotel/epic/41880/tracking-standardisation epic
export const trackExperimentServed = (
  experimentId: TrackExperimentServedActionType['experimentId'],
  variationId: TrackExperimentServedActionType['variationId']
): TrackExperimentServedActionType => ({
  experimentId,
  variationId,
  isActive: true,
  type: TRACK_EXPERIMENT_SERVED
})

export const trackEvent = createAction(
  'analytics/trackEvent',
  prepareAutoBatched<TrackEventPayload>()
)

export const trackProviderRedirect = createAction<TrackProviderRedirectParams>(
  'analytics/trackProviderRedirect'
)
