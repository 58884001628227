import React, {ReactNode, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getUserCountry, getUserIp} from 'modules/meta/selectors'
import {setUserCountry, setUserIp} from 'modules/meta/slice'
import logIpInfoService, {
  IpInfoServiceStatus
} from 'performance/logIpInfoService'
import Settings from 'Settings'
import timing from 'utils/timing'

import {persistUserCountryCode} from '@daedalus/core/src/utils/userCountry'
import {persistUserIp} from '@daedalus/core/src/utils/userIp'

interface Props {
  children: ReactNode
}

interface IpInfoResponse {
  ip?: string
  country?: string
}

// On localhost proxy calls to ip-info using http-proxy-middleware to
// avoid CORS error
const IP_INFO_ENDPOINT = Settings.get('REACT_APP_IP_INFO_ENDPOINT')

const FALLBACK_COUNTRY = 'US'
const FALLBACK_IP = '1.2.3.4'

const requestsTiming = timing()

export const FetchIpInfo = ({children}: Props) => {
  const [isFetching, setIsFetching] = useState(false)
  const userCountry = useSelector(getUserCountry)
  const userIp = useSelector(getUserIp)
  const isReady = userCountry && userIp
  const dispatch = useDispatch()

  const getIpInfo = async () => {
    setIsFetching(true)

    requestsTiming.start('ip-info')

    logIpInfoService(
      IpInfoServiceStatus.start,
      {ipInfoUrl: IP_INFO_ENDPOINT},
      'info'
    )

    try {
      const response = await fetch(IP_INFO_ENDPOINT)

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      let {ip, country}: IpInfoResponse = await response.json()

      const timing = requestsTiming.stop('ip-info')

      logIpInfoService(
        IpInfoServiceStatus.done,
        {timing, ipInfoUrl: IP_INFO_ENDPOINT, ipInfoResponse: {ip, country}},
        'info'
      )

      /* eslint-disable fp/no-mutation */
      ip = ip || FALLBACK_IP
      country = country || FALLBACK_COUNTRY
      /* eslint-disable fp/no-mutation */

      dispatch(setUserIp({userIp: ip}))
      dispatch(setUserCountry({userCountry: country}))

      persistUserIp(ip)
      persistUserCountryCode(country)
    } catch (err) {
      const timing = requestsTiming.stop('ip-info')

      logIpInfoService(
        IpInfoServiceStatus.error,
        {timing, ipInfoUrl: IP_INFO_ENDPOINT, error: err},
        'error'
      )

      dispatch(setUserIp({userIp: FALLBACK_IP}))
      dispatch(setUserCountry({userCountry: FALLBACK_COUNTRY}))
    }

    setIsFetching(false)
  }

  useEffect(() => {
    if (!isReady && !isFetching) {
      getIpInfo()
    }
  }, [isReady, isFetching])

  return isReady ? <>{children}</> : null
}
