// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgFaceSad = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 10a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0ZM10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16ZM7.627 13.99a.75.75 0 0 0 .86-.604l.003-.01a1.118 1.118 0 0 1 .211-.396c.166-.2.515-.48 1.299-.48.784 0 1.133.28 1.299.48a1.12 1.12 0 0 1 .21.396l.003.01a.75.75 0 0 0 1.478-.26l-.74.124.74-.124v-.003l-.002-.005-.002-.011-.006-.029a2.343 2.343 0 0 0-.102-.334 2.616 2.616 0 0 0-.427-.724C11.992 11.47 11.216 11 10 11s-1.992.47-2.451 1.02a2.616 2.616 0 0 0-.51.976l-.02.082-.005.029-.002.011-.001.005v.002c0 .001 0 .002.739.125l-.74-.123a.75.75 0 0 0 .617.863ZM7 8a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm5-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
    />
  </svg>
)

export default SvgFaceSad
