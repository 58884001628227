import {getTotalPrice} from '../../../price/business/price'
import {
  Room,
  SapiAbstractOffer,
  SapiAbstractOfferType,
  SplitBooking
} from '../../types/room'
import {mapRoomToAbstract, mapSplitBookingToAbstract} from './mapToAbstract'

/**
 *
 * @param rooms - list of rooms
 * @param splitBooking - splitBooking object
 * @returns Sorted list of combined rooms and sb object as a list of abstract entities.
 *
 * This is currently done on FE layer because rooms and SB data is received separately.
 * There is an initiative to move this logic to SAPI.
 *
 * [BookingCoreExp] - https://app.shortcut.com/findhotel/epic/124618
 */

export const sortRoomsAndSplitBooking = (
  rooms: Room[],
  splitBooking: SplitBooking | undefined | null
): SapiAbstractOffer[] => {
  const combinedList = Array.prototype.concat(
    splitBooking ? [mapSplitBookingToAbstract(splitBooking)] : [],
    rooms ? rooms.map(mapRoomToAbstract) : []
  )
  // eslint-disable-next-line fp/no-mutating-methods
  return combinedList.sort((a, b) => {
    const aPrice =
      a.type === SapiAbstractOfferType.Regular
        ? getTotalPrice(a.data.offers[0].prices[0])
        : getTotalPrice(a.data.prices[0])

    const bPrice =
      b.type === SapiAbstractOfferType.Regular
        ? getTotalPrice(b.data.offers[0].prices[0])
        : getTotalPrice(b.data.prices[0])

    return aPrice - bPrice
  })
}
