import {
  CreditCardType,
  Network
} from '@daedalus/core/src/api-types/bovio/request/booking_creation'

export const acceptedCardTypes = [
  'VI',
  'CA',
  'AX',
  'CU',
  'DC',
  'DS',
  'JC',
  'TO'
]

export const secureFieldsToBofhTypes = {
  VIS: 'VI',
  ECA: 'CA',
  AMX: 'AX',
  DIN: 'DC',
  DIS: 'DS',
  JCB: 'JC',
  CUP: 'CU',
  MAU: 'TO'
}

export const bofhToSecureFieldsTypes = {
  VI: 'VIS',
  CA: 'ECA',
  AX: 'AMX',
  DC: 'DIN',
  DS: 'DIS',
  JC: 'JCB',
  CU: 'CUP',
  TO: 'MAU'
}
/**
 * CoBrand represents payment providers that handle user transactions.
 * EU regulations require users to choose the network for processing payments.
 */
export enum CoBrandCardType {
  CBL = 'CBL' // French payment network Cartes Bancaires
}

/**
 * Maps credit and CoBrand card types to the network type expected by our API.
 * Used for the dropdown menu to select the payment network.
 */
export const cardTypeToNetwork = {
  [CoBrandCardType.CBL]: Network.CartesBancaires,
  [CreditCardType.Vi]: Network.Visa,
  [CreditCardType.CA]: Network.MasterCard
}
