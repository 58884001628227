import {assoc, assocPath, pathOr, pipe} from 'ramda'
import {RaaOffersResponseResult} from 'services/raaOffers'

import {RaaOfferType} from '@daedalus/core/src/offer/types/Raa'

import {ModuleActionType} from './actions'
import {
  FetchRaaOffersReceived,
  RAA_OFFERS_DONE,
  RAA_OFFERS_ERROR,
  RAA_OFFERS_FETCH,
  RAA_OFFERS_RECEIVED
} from './actions/fetchRaaOffers'

export interface FetchRaaOffersResponseType {
  isComplete: boolean
  moduleState: string
  searchId: string
  response: FetchRaaOffersResponse
}

interface FetchRaaOffersResponse {
  [key: string]: unknown
  offers: RaaOfferType[]
}

const initialState = null

export default (
  state: FetchRaaOffersResponseType | null = initialState,
  action: ModuleActionType
) => {
  switch (action.type) {
    case RAA_OFFERS_RECEIVED: {
      const {data} = action as FetchRaaOffersReceived
      const response = data.results?.[0] ?? ({} as RaaOffersResponseResult)
      const status = pathOr(null, ['status', 'complete'], data)

      return pipe(
        assoc('response', response),
        assoc('isComplete', status),
        assoc('moduleState', RAA_OFFERS_RECEIVED)
      )(state)
    }
    case RAA_OFFERS_FETCH:
    case RAA_OFFERS_ERROR:
    case RAA_OFFERS_DONE: {
      return assocPath(['moduleState'], action.type, state)
    }

    default: {
      return state
    }
  }
}
