import React from 'react'

import {Popover, PopoverPlacement} from '../Popover'

export interface DropDownMenuProps {
  isOpen: boolean
  onClose: (event: MouseEvent) => void
  anchorRef: React.RefObject<HTMLElement>
  children: React.ReactNode
  /** Pass through classname to allow styles overrides */
  className?: string
}

export const DropDownMenu = ({
  isOpen = false,
  onClose,
  anchorRef,
  children,
  className
}: DropDownMenuProps) => {
  return (
    <Popover
      isOpen={isOpen}
      anchorRef={anchorRef}
      placement={PopoverPlacement.BottomLeft}
      onOutsideClick={onClose}
      className={className}
    >
      {children}
    </Popover>
  )
}
