// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCaratRight = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="M7.226 16.878c-.973.834-2.476.142-2.476-1.14V4.262c0-1.281 1.503-1.973 2.476-1.139l6.695 5.74a1.5 1.5 0 0 1 0 2.277l-6.695 5.739Z" />
  </svg>
)

export default SvgCaratRight
