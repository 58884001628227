import React, {createContext, ReactNode, useEffect, useState} from 'react'
import {IntlProvider} from 'react-intl'
import axios from 'axios'

import {logAndCaptureErrorMessage} from '../../../utils/logging/errorHandlers'
import retryAxios from '../../../utils/network/retryAxios'
import {
  FirstDayOfWeekByCountryCode,
  getFirstDayOfWeek,
  getMonthNames,
  getWeekDayNamesShort
} from '../../services/dateLocalization'
import {getLocaleByCode} from '../../services/locale'
import {
  localeLanguageMapper,
  mapArabicToLatinNumberSystem
} from '../../services/mappingLocales'
import {
  getTranslationErrorLogger,
  LogPayload
} from '../../utils/translationErrorHandler'

/**
 * Loads required assets and configurations for the provided locale.
 * Wraps its children in a `react-intl` provider and a custom provider that
 * makes the i18n assets and configurations available.
 */
export interface ConfigureI18nProps {
  /** The base URL where the application's static files (including the i18n assets) are hosted */
  publicUrl?: string
  /** The i18n assets will be configured for this languageCode */
  languageCode: string
  /** The user country code */
  countryCode: string
  /** The tree of components that will have access to the i18n context */
  children: ReactNode
  /** Custom translation error handler, can be used as logger for translation errors */
  translationErrorHandler?: (logPayload: LogPayload) => void
}

interface I18nContextValues {
  messages?: number[] | never[]
  monthNames: string[]
  firstDayOfWeek: number
  weekDayNamesShort: string[]
}

const defaultMonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
const defaultWeekDaysShort = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

const defaultI18nContextValues: I18nContextValues = {
  messages: [],
  monthNames: defaultMonthNames,
  firstDayOfWeek: 1,
  weekDayNamesShort: defaultWeekDaysShort
}
/**
 * Custom context that makes the i18n assets and configurations available.
 */
export const ConfigureI18nContext = createContext(defaultI18nContextValues)

type LocalizationStrings = Record<string, unknown>

const ConfigureI18n = ({
  publicUrl = '/',
  languageCode,
  countryCode,
  children,
  translationErrorHandler
}: ConfigureI18nProps) => {
  const [messages, setLoadedMessages] = useState<LocalizationStrings | null>(
    null
  )
  const [nonCriticalAssets, setNonCriticalAssetsLoaded] =
    useState<I18nContextValues | null>(null)

  const mappedLanguage = localeLanguageMapper(languageCode)
  const mappedNumberSystemLocale = mapArabicToLatinNumberSystem(mappedLanguage)

  const locale = getLocaleByCode(languageCode)

  const finalTranslationsDir = `${publicUrl}translations/`

  useEffect(() => {
    const axiosInstance = axios.create()
    retryAxios({axiosInstance, retryAttempts: 2, delayTime: 200})

    const loadTranslations = axiosInstance
      .get(`${finalTranslationsDir}${locale}.json`)
      .then(res => res.data)

    const monthNamesPromise = getMonthNames(languageCode, publicUrl)

    const firstDayOfWeekPromise = getFirstDayOfWeek(
      countryCode as FirstDayOfWeekByCountryCode
    )
    const weekDayNamesShortPromise = getWeekDayNamesShort(
      languageCode,
      publicUrl
    )

    const nonCriticalAssetPromises = [
      monthNamesPromise,
      firstDayOfWeekPromise,
      weekDayNamesShortPromise
    ]

    Promise.all(nonCriticalAssetPromises)
      .then(response => {
        const [monthNames, firstDayOfWeek, weekDayNamesShort] = response
        setNonCriticalAssetsLoaded({
          monthNames,
          firstDayOfWeek,
          weekDayNamesShort
        } as I18nContextValues)
      })
      .catch(() => {
        setNonCriticalAssetsLoaded({
          monthNames: defaultMonthNames,
          firstDayOfWeek: 1,
          weekDayNamesShort: defaultWeekDaysShort
        })
      })

    loadTranslations
      .then(response => {
        setLoadedMessages(response as unknown as LocalizationStrings)
      })
      .catch((error: Error) => {
        // This error may come from Axios or loadScript
        logAndCaptureErrorMessage(
          `An error occurred while loading i18n assets - ${error.message}`,
          {level: 'error', tags: {mappedLanguage}}
        )
        setLoadedMessages({})
      })
  }, [mappedLanguage])

  if (messages) {
    const contextAssets = {
      messages,
      ...(nonCriticalAssets as unknown as LocalizationStrings)
    } as unknown as I18nContextValues

    const finalMessages = {...messages}
    return (
      <IntlProvider
        textComponent="span"
        locale={mappedNumberSystemLocale}
        messages={finalMessages as Record<string, string>}
        onError={
          translationErrorHandler
            ? getTranslationErrorLogger(mappedLanguage, translationErrorHandler)
            : undefined
        }
      >
        <ConfigureI18nContext.Provider value={contextAssets}>
          {children}
        </ConfigureI18nContext.Provider>
      </IntlProvider>
    )
  }

  return null
}

export default ConfigureI18n
