// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgFacebook = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="M18 10.049a8.082 8.082 0 0 0-1.262-4.34 8.015 8.015 0 0 0-3.388-2.97 7.958 7.958 0 0 0-8.545 1.189 8.051 8.051 0 0 0-2.46 3.783 8.092 8.092 0 0 0-.044 4.52 8.054 8.054 0 0 0 2.386 3.832 7.98 7.98 0 0 0 4.063 1.932v-5.62H6.719v-2.326H8.75V8.275a2.854 2.854 0 0 1 .781-2.277 2.82 2.82 0 0 1 2.24-.853c.6.008 1.199.06 1.791.157v1.983h-1.008a1.149 1.149 0 0 0-.95.314 1.162 1.162 0 0 0-.35.943v1.509h2.219l-.355 2.325H11.25V18a7.988 7.988 0 0 0 4.834-2.723A8.075 8.075 0 0 0 18 10.05Z" />
  </svg>
)

export default SvgFacebook
