// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgDog = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m10.75 2.75.3-.688A.75.75 0 0 0 10 2.75v2.704l-.018.006c-.335.112-.797.271-1.308.463-1 .375-2.261.9-3.09 1.453-.327.218-.641.399-.968.587a30.07 30.07 0 0 0-.625.367c-.52.317-1.076.696-1.465 1.21A2.627 2.627 0 0 0 2 11.13v2.06c0 1.417 1.067 2.579 2.432 2.785 1.025.154 2.34.388 3.553.71 1.248.332 2.267.727 2.796 1.15a.75.75 0 0 0 .938-1.17c-.797-.638-2.093-1.095-3.348-1.429-1.29-.342-2.665-.586-3.716-.745-.667-.1-1.155-.663-1.155-1.301v-2.06c0-.258.079-.497.221-.685.206-.27.55-.529 1.05-.833.155-.095.334-.198.524-.308.361-.209.762-.44 1.121-.68.671-.447 1.786-.922 2.785-1.297.287-.107.559-.205.799-.288V8.25a.75.75 0 0 0 1.5 0V4.194c.468.448.977 1.11 1.289 2.043.46 1.384 1.513 2.552 2.381 3.343a14.086 14.086 0 0 0 1.533 1.208l.106.07.03.02.008.005.003.001.001.001.368-.586-.367.586a.75.75 0 0 0 .797-1.27l-.004-.003-.02-.013a10.385 10.385 0 0 1-.406-.282 12.575 12.575 0 0 1-1.04-.847c-.806-.734-1.629-1.691-1.967-2.707a6.483 6.483 0 0 0-1.894-2.853 6.11 6.11 0 0 0-.865-.633 4.592 4.592 0 0 0-.362-.196l-.026-.012-.01-.004-.002-.001-.002-.001-.301.687ZM8 9a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
    />
  </svg>
)

export default SvgDog
