import {CancellationPenalties} from '@findhotel/sapi'

import {CancellationPenalty} from '../../offer/types/offer'

/**
 * Returns a boolean that tells if an offer is refundable.
 */
const isOfferRefundable = (
  cancellationPenalties: CancellationPenalty[] | CancellationPenalties[]
) => {
  return cancellationPenalties?.length > 0
}

export default isOfferRefundable
