import {LogLevel, logToDataDog} from 'services/datadogLogger'

export enum BoFHServiceStatus {
  start = 'Start',
  done = 'Done',
  error = 'Error',
  fatalError = 'FatalError'
}

export enum BoFHServiceEndpointNames {
  BookingRetrieve = 'Bookings',
  BookingFinalize = 'BookingFinalize',
  BookingCreation = 'BookingCreation',
  BookingStatus = 'BookingStatus',
  Rooms = 'Rooms',
  OfferCheck = 'OfferCheck',
  SbOfferCheck = 'SbOfferCheck',
  PaymentOptions = 'PaymentOptions',
  SendBookingConfirmation = 'SendBookingConfirmation',
  LastUsedBillingAddress = 'LastUsedBillingAddress'
}

const logBoFHService = (
  endpoint: BoFHServiceEndpointNames,
  status: BoFHServiceStatus,
  metadata?,
  level?: LogLevel
) => {
  logToDataDog({
    logName: `Services.BoFHAPI.${endpoint}.${status}`,
    metadata: {metadata},
    level
  })
}

export default logBoFHService
