import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {dissocPath, omit} from 'ramda'
import {
  MetricType,
  onCLS,
  onFCP,
  onFID,
  onLCP,
  onTTFB,
  ReportOpts
} from 'web-vitals'
import {onINP} from 'web-vitals/attribution'

import {trackEvent} from '../../modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Team
} from '../../types/Events'

const toPerformanceMeasuredContext = (data: MetricType) => ({
  ...omit(
    ['name'],
    dissocPath<MetricType>(['attribution', 'interactionTargetElement'], data)
  ),
  metric: data.name
})

const useWebVitals = (
  onMetric: (onReport: (metric: MetricType) => void, opts?: ReportOpts) => void,
  sendToAnalytics: (metric: MetricType) => void
) => {
  useEffect(() => {
    onMetric(sendToAnalytics)
  }, [])
}

export const useCoreWebVitals = () => {
  const dispatch = useDispatch()
  const defaultCoreWebVitalsAction = {
    category: Category.System,
    entity: Entity.WebVitals,
    action: Action.PerformanceMeasured,
    team: Team.CoreWebVitals
  }

  const dispatchTrackEvent = (data: MetricType) => {
    dispatch(
      trackEvent({
        ...defaultCoreWebVitalsAction,
        analyticsContext: {
          [AnalyticsContext.PerformanceMeasuredContext]:
            toPerformanceMeasuredContext(data)
        }
      })
    )
  }

  useWebVitals(onFCP, dispatchTrackEvent)
  useWebVitals(onCLS, dispatchTrackEvent)
  useWebVitals(onFID, dispatchTrackEvent)
  useWebVitals(onINP, dispatchTrackEvent)
  useWebVitals(onLCP, dispatchTrackEvent)
  useWebVitals(onTTFB, dispatchTrackEvent)
}
