// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgBicycle = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 3a.75.75 0 0 0-.73.924l1.124 4.719c-.508.4-.883.962-1.05 1.607h-1.443l-2.5-3.75H8.5a.75.75 0 0 0 0-1.5H5a.75.75 0 0 0 0 1.5h1.349l2.5 3.75h-.944a3.001 3.001 0 1 0 0 1.5h4.19a3.001 3.001 0 1 0 2.446-3.715L13.7 4.5H17A.75.75 0 0 0 17 3h-4.25Zm1.475 6.716a.746.746 0 0 0 .214-.108 1.5 1.5 0 1 1-.214.107ZM5 9.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm-2.25 6a.75.75 0 0 0 0 1.5h14.5a.75.75 0 1 0 0-1.5H2.75Z"
    />
  </svg>
)

export default SvgBicycle
