// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgAnnouncement = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.28 4.705c-.458.859-.78 2.05-.78 3.295 0 1.246.322 2.436.78 3.295.23.43.479.75.713.952.104.09.196.15.274.19l.017.003.271.056c.102-.016.255-.077.452-.249.234-.203.483-.522.713-.952.458-.859.78-2.05.78-3.295 0-1.246-.322-2.436-.78-3.295-.23-.43-.479-.75-.713-.952-.197-.172-.35-.233-.452-.249l-.27.056-.018.004a1.3 1.3 0 0 0-.274.189c-.234.203-.483.522-.713.952ZM11 8c0 1.461.367 2.875.931 3.953l-1.653-.343c-2.262-.472-4.615-.967-5.096-1.088-.297-.074-.725-.342-1.087-.814A2.816 2.816 0 0 1 3.5 8c0-.657.243-1.25.595-1.708.362-.472.79-.74 1.087-.814.481-.12 2.834-.616 5.096-1.088l1.653-.343C11.367 5.125 11 6.539 11 8Zm2.004 5.707.82.17c.212.077.438.123.676.123.577 0 1.085-.268 1.491-.62.409-.355.762-.837 1.052-1.378C17.622 10.918 18 9.483 18 8c0-1.483-.378-2.918-.957-4.002-.29-.542-.643-1.023-1.052-1.378-.406-.352-.914-.62-1.491-.62-.238 0-.464.045-.676.123l-.82.17c-.812.168-1.906.394-3.032.629-2.238.466-4.635.97-5.154 1.1-.703.176-1.4.688-1.913 1.357A4.316 4.316 0 0 0 2 7.999c0 1.033.382 1.94.905 2.622.31.405.689.753 1.095 1.003v3.626A2.75 2.75 0 0 0 6.75 18h.5A2.75 2.75 0 0 0 10 15.25v-2.166l3.004.623ZM8.5 12.77c-1.189-.249-2.295-.483-3-.637v3.117c0 .69.56 1.25 1.25 1.25h.5c.69 0 1.25-.56 1.25-1.25v-2.48Z"
    />
  </svg>
)

export default SvgAnnouncement
