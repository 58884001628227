// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPerson = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 16 16"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.8 4a1.2 1.2 0 1 1 2.4 0 1.2 1.2 0 0 1-2.4 0ZM8 1.6a2.4 2.4 0 0 0-1.593 4.195c-.667.189-1.198.518-1.592 1C4.17 7.588 4 8.666 4 9.8a.6.6 0 0 0 .6.6h1v1.8a2.2 2.2 0 0 0 2.2 2.2h.4a2.2 2.2 0 0 0 2.2-2.2v-1.8h1a.6.6 0 0 0 .6-.6c0-1.135-.17-2.213-.815-3.004-.394-.483-.925-.812-1.592-1.001A2.4 2.4 0 0 0 8 1.6ZM6.2 9.2h-.98c.057-.744.226-1.279.525-1.646.351-.43.99-.754 2.255-.754s1.904.324 2.255.754c.3.367.468.902.524 1.646H9.8a.6.6 0 0 0-.6.6v2.4a1 1 0 0 1-1 1h-.4a1 1 0 0 1-1-1V9.8a.6.6 0 0 0-.6-.6Z"
    />
  </svg>
)

export default SvgPerson
