import axios from 'axios'

const axiosInstance = axios.create()

/**
 * Makes a request to fetch the Optimizely's Datafile containing the product's experiments
 *
 */
export interface FetchOptimizelyDataFile {
  /** The remote URL where the datafile is stored */
  dataFileUrl: string
}

export const fetchOptimizelyDataFile = async ({
  dataFileUrl
}: FetchOptimizelyDataFile) => {
  const result = await axiosInstance.get(dataFileUrl)
  return result.data
}
