import axios, {AxiosInstance} from 'axios'
import Settings from 'Settings'
import {RaaSearchParamsType} from 'types/Search'

import {RaaOfferType} from '@daedalus/core/src/offer/types/Raa'

import headers from './headers'

const requestTimeout = Number(Settings.get('REACT_APP_RAA_REQUEST_TIMEOUT'))

const axiosConfig = {
  headers,
  timeout: requestTimeout
}

export interface RaaOffersResponse {
  errors: unknown[]
  results: RaaOffersResponseResult[]
  status: {
    complete: boolean
  }
}

export interface RaaOffersResponseResult {
  [key: string]: unknown
  offers: RaaOfferType[]
}

const axiosInstance = axios.create(axiosConfig)

const getRaaOffersFactory =
  (httpService: AxiosInstance) =>
  async (
    params: RaaSearchParamsType,
    url: string
  ): Promise<{data: RaaOffersResponse}> =>
    httpService.get(url, {params})

const getRaaOffersService = getRaaOffersFactory(axiosInstance)

export {getRaaOffersFactory, getRaaOffersService}
