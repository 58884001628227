// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgAlarm = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.78 3.28a.75.75 0 0 0-1.06-1.06l-.5.5a.75.75 0 0 0 1.06 1.06l.5-.5Zm12.44-1.06a.75.75 0 0 1 1.06 0l.5.5a.75.75 0 0 1-1.06 1.06l-.5-.5a.75.75 0 0 1 0-1.06ZM3.5 10a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0ZM10 2a8 8 0 0 0-5.687 13.627L3.22 16.72a.75.75 0 1 0 1.06 1.06l1.188-1.187A7.963 7.963 0 0 0 10 18c1.683 0 3.244-.52 4.533-1.407l1.187 1.187a.75.75 0 0 0 1.06-1.06l-1.093-1.094A8 8 0 0 0 10 2Zm0 4a.75.75 0 0 1 .75.75v2.94l2.03 2.03a.75.75 0 0 1-1.06 1.06l-2.25-2.25a.75.75 0 0 1-.22-.53V6.75A.75.75 0 0 1 10 6Z"
    />
  </svg>
)

export default SvgAlarm
