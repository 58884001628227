// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPassport = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 2.75A.75.75 0 0 1 3.75 2h10.5A2.75 2.75 0 0 1 17 4.75v10.5A2.75 2.75 0 0 1 14.25 18H3.75a.75.75 0 0 1-.75-.75V2.75Zm1.5.75v13h9.75c.69 0 1.25-.56 1.25-1.25V4.75c0-.69-.56-1.25-1.25-1.25H4.5Zm5.5 3a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM7 8a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm.75 4.5a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5h-4.5Z"
    />
  </svg>
)

export default SvgPassport
