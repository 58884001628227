import {v4 as uuid} from 'uuid'

let bookingRequestId: string
export const generateBookingRequestId = (): string => {
  // eslint-disable-next-line fp/no-mutation
  bookingRequestId = uuid()
  return bookingRequestId
}

export const getCurrentBookingRequestId = (): string => {
  return bookingRequestId
}
