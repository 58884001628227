import {MiddlewareType} from 'middlewares'
import {AxiosErrorResponse} from 'modules/book/actions/bookOfferFinalization'
import {bookOfferPsd2Error} from 'modules/book/actions/bookOfferPsd2'
import {error, toCommonErrorPayloadFormat} from 'modules/common/actions/error'
import {
  psd2AuthChallenge,
  psd2AuthError,
  Psd2AuthErrorType,
  psd2AuthInitialization,
  psd2AuthInitializationError,
  psd2AuthSuccess,
  startPsd2AuthChallenge
} from 'modules/psd2Auth/slice'
import logPSD2Service, {
  PSD2ServiceStatus,
  PSD2ServiceStep
} from 'performance/logPSD2Service'
import Psd2 from 'services/psd2'
import {StatusCodes} from 'services/psd2/types'
import {RejectResponseType} from 'types/errorResponse'
import {getCurrentBookingRequestId} from 'utils/bookingRequestId'

interface PSD2ChallengeError {
  code: string
  message: string
  type: string
}

const middleware: MiddlewareType = store => next => async action => {
  const {dispatch} = store
  const bookingRequestId = getCurrentBookingRequestId()
  const psd2Provider = Psd2.getProvider()

  const dispatchChallengeErrors = (_error: PSD2ChallengeError) => {
    logPSD2Service(
      PSD2ServiceStep.Challenge,
      PSD2ServiceStatus.error,
      {
        serverCode: Psd2.serverCode,
        code: _error.code,
        message: _error.message,
        type: _error.type,
        booking_request_id: bookingRequestId
      },
      'error'
    )

    const errorAction = bookOfferPsd2Error()
    dispatch(psd2AuthError({}))
    dispatch(errorAction)
    dispatch(
      error(
        toCommonErrorPayloadFormat(
          errorAction,
          _error as RejectResponseType | AxiosErrorResponse
        )
      )
    )
  }

  next(action)

  switch (action.type) {
    case startPsd2AuthChallenge.type: {
      logPSD2Service(PSD2ServiceStep.Challenge, PSD2ServiceStatus.start, {
        serverCode: Psd2.serverCode,
        booking_request_id: bookingRequestId
      })

      dispatch(psd2AuthInitialization())
      break
    }

    case psd2AuthInitialization.type: {
      logPSD2Service(PSD2ServiceStep.Initialize, undefined, {
        serverCode: Psd2.serverCode,
        booking_request_id: bookingRequestId
      })

      try {
        const initResponse = await psd2Provider?.initializeBooking(action.data)
        if (initResponse?.statusCode === StatusCodes.SUCCESS) {
          dispatch(psd2AuthChallenge())
        } else {
          dispatch(psd2AuthInitializationError(initResponse))
        }
      } catch (error) {
        dispatch(psd2AuthInitializationError(error as Psd2AuthErrorType))
      }

      break
    }

    case psd2AuthChallenge.type: {
      try {
        const challengeResponse = await psd2Provider?.challenge()
        if (challengeResponse?.status === StatusCodes.SUCCESS) {
          logPSD2Service(PSD2ServiceStep.Challenge, PSD2ServiceStatus.success, {
            serverCode: Psd2.serverCode,
            booking_request_id: bookingRequestId
          })

          dispatch(psd2AuthSuccess())
        } else {
          dispatchChallengeErrors(challengeResponse?.error)
        }
      } catch (error) {
        dispatchChallengeErrors(error as PSD2ChallengeError)
      }

      break
    }

    default: {
      break
    }
  }
}

export default middleware
