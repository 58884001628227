// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgSwitch = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2a.747.747 0 0 1 .55.24l3.25 3.5a.75.75 0 1 1-1.1 1.02l-1.95-2.1v8.59a.75.75 0 0 1-1.5 0V4.66L3.3 6.76a.75.75 0 1 1-1.1-1.02l3.245-3.494A.748.748 0 0 1 6 2Zm5.3 11.24a.75.75 0 0 0-1.1 1.02l3.25 3.5a.749.749 0 0 0 1.1 0l3.25-3.5a.75.75 0 0 0-1.1-1.02l-1.95 2.1V6.75a.75.75 0 0 0-1.5 0v8.59l-1.95-2.1Z"
    />
  </svg>
)

export default SvgSwitch
