import {MiddlewareType} from 'middlewares'
import {trackEventV1} from 'modules/analytics/actions'
import {
  EventAction,
  EventCategory,
  EventName
} from 'modules/analytics/actions/trackEventV1'
import {getPageSpace} from 'modules/analytics/trackEventV1Helpers'
import {ERROR} from 'modules/common/actions/error'
import {FATAL_ERROR} from 'modules/common/actions/fatalError'
import captureException from 'utils/captureException'
import {urlParams} from 'utils/urlParams'

const middleware: MiddlewareType = store => next => action => {
  next(action)
  const {dispatch} = store
  const space = getPageSpace(window.location.pathname)

  switch (action.type) {
    case FATAL_ERROR:
      dispatch(
        trackEventV1({
          name: EventName.PAGE_ERROR,
          category: EventCategory.PAGE_ERROR,
          action: EventAction.ERROR,
          space,
          urlParams: urlParams(),
          value: action.stackTrace.error.message,
          label: 'Non Recoverable error'
        })
      )

      captureException(action.stackTrace.error, action.stackTrace.info)
      break

    case ERROR:
      dispatch(
        trackEventV1({
          name: EventName.PAGE_ERROR,
          category: EventCategory.PAGE_ERROR,
          action: EventAction.ERROR,
          space,
          urlParams: urlParams(),
          value: action.error.value,
          label: 'Recoverable error'
        })
      )
      break

    default:
      break
  }
}

export default middleware
