// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgSpaWellness = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="M10.008 12.768ZM17.875 13.44a6.115 6.115 0 0 0-.361-.537 5.59 5.59 0 0 0-1.508-1.387 7.74 7.74 0 0 0 1.332-4.056c.016-.279.016-.559 0-.838a.787.787 0 0 0-.681-.703 3.39 3.39 0 0 0-.723 0 6.32 6.32 0 0 0-3.293 1.117 7.653 7.653 0 0 0-1.58-3.249 7.704 7.704 0 0 0-.567-.59.773.773 0 0 0-1.033 0c-.181.167-.35.347-.505.539a7.57 7.57 0 0 0-1.6 3.342 6.504 6.504 0 0 0-3.222-1.035c-.264-.02-.53-.02-.795 0a.763.763 0 0 0-.702.735v.714A7.761 7.761 0 0 0 3.97 11.63a5.36 5.36 0 0 0-1.445 1.345c-.155.207-.29.414-.423.631a.798.798 0 0 0 0 .776c.109.193.233.376.371.549A4.918 4.918 0 0 0 6.106 17h.279a4.76 4.76 0 0 0 3.593-1.77 4.75 4.75 0 0 0 3.355 1.76h.278a4.864 4.864 0 0 0 3.84-2.07c.169-.206.317-.428.445-.662a.787.787 0 0 0-.02-.817Zm-6.07-.568c.103-.187.206-.383.3-.58.092-.196.113-.258.154-.393.041-.134.103-.248.145-.383.112-.34.205-.684.278-1.034 0-.145.052-.28.062-.425.059-.4.086-.805.083-1.21a5.094 5.094 0 0 1 2.963-1.345 6.102 6.102 0 0 1-1.178 3.358 6.081 6.081 0 0 1-2.848 2.126l.04-.114ZM7.149 8.857c0 .458.04.915.124 1.366.033.23.081.459.144.683.055.22.12.438.196.652.076.214.162.425.259.63.092.208.196.415.299.611l.103.187a5.988 5.988 0 0 1-2.908-2.066 6.01 6.01 0 0 1-1.221-3.356 5.084 5.084 0 0 1 3.004 1.293Zm-.97 6.54a3.422 3.422 0 0 1-2.489-1.48l.062-.073c.33-.423.74-.775 1.208-1.034a6.706 6.706 0 0 0 3.397 1.841 2.974 2.974 0 0 1-2.179.745Zm5.089-6.86a6.697 6.697 0 0 1-.723 3.414 3.373 3.373 0 0 1-.247.414 3.641 3.641 0 0 1-.28.414 1.922 1.922 0 0 1-.216-.29 1.813 1.813 0 0 1-.186-.3 1.399 1.399 0 0 1-.155-.27 3.68 3.68 0 0 1-.216-.454 5.39 5.39 0 0 1-.217-.57 3.108 3.108 0 0 1-.124-.444 7.113 7.113 0 0 1-.186-1.884 6.3 6.3 0 0 1 1.26-3.6 6.34 6.34 0 0 1 1.3 3.58l-.01-.01Zm4.955 5.39a3.306 3.306 0 0 1-2.818 1.47 3.093 3.093 0 0 1-1.848-.797 6.809 6.809 0 0 0 3.438-1.873c.507.277.947.662 1.29 1.128l-.062.072Z" />
  </svg>
)

export default SvgSpaWellness
