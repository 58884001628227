/**
 * Before adding a new error category:
 * 1. Check if it's already defined in packages/core/src/api-types/bovio/response/error.ts
 * 2. If not, it's preferred to update the backend schema first, then generate
 * types from it.
 */
export enum ErrorCategory {
  /**
   * The customer payment attempt was not authorized
   */
  PaymentNotAuthorized = 'payment_not_authorized',
  /**
   * An error with the duplicate identical reservation.
   */
  DuplicateBooking = 'duplicate_booking',

  /**
   * PCI proxy error
   */
  PCIProxy = 'pci_proxy'
}
