// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgShare3 = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2a.75.75 0 0 1 .557.248l2.25 2.5a.75.75 0 1 1-1.114 1.004l-.943-1.048v7.546a.75.75 0 0 1-1.5 0V4.704l-.943 1.048a.75.75 0 1 1-1.114-1.004l2.25-2.5A.75.75 0 0 1 10 2ZM5.75 9.5c-.69 0-1.25.56-1.25 1.25v4.5c0 .69.56 1.25 1.25 1.25h8.5c.69 0 1.25-.56 1.25-1.25v-4.5c0-.69-.56-1.25-1.25-1.25h-.5a.75.75 0 0 1 0-1.5h.5A2.75 2.75 0 0 1 17 10.75v4.5A2.75 2.75 0 0 1 14.25 18h-8.5A2.75 2.75 0 0 1 3 15.25v-4.5A2.75 2.75 0 0 1 5.75 8h.5a.75.75 0 0 1 0 1.5h-.5Z"
    />
  </svg>
)

export default SvgShare3
