// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgUsers = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 3.5a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM4 5.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0Zm-.38 10.878c.144-.707.442-1.63 1.038-2.364.57-.702 1.438-1.264 2.842-1.264 1.404 0 2.272.562 2.842 1.264.596.733.894 1.657 1.037 2.364a.077.077 0 0 1 .001.038.075.075 0 0 1-.017.03.193.193 0 0 1-.145.054H3.782a.193.193 0 0 1-.145-.055.075.075 0 0 1-.017-.029.078.078 0 0 1 0-.038ZM7.5 11.25c-1.885 0-3.17.789-4.007 1.819-.811 1-1.176 2.19-1.342 3.01C1.929 17.17 2.819 18 3.78 18h7.437c.963 0 1.853-.83 1.632-1.92-.167-.82-.532-2.012-1.343-3.011-.837-1.03-2.122-1.819-4.007-1.819Zm6.25 0a.75.75 0 0 0 0 1.5c.732 0 1.258.36 1.675.95.432.613.704 1.425.867 2.159a.467.467 0 0 1-.111.429.671.671 0 0 1-.504.212h-.927a.75.75 0 0 0 0 1.5h.927c1.252 0 2.386-1.084 2.08-2.467-.182-.814-.508-1.85-1.107-2.698-.614-.87-1.553-1.585-2.9-1.585Zm-1-9.25a.75.75 0 0 0 0 1.5c1.013 0 1.75.798 1.75 2s-.737 2-1.75 2a.75.75 0 0 0 0 1.5C14.774 9 16 7.335 16 5.5S14.774 2 12.75 2Z"
    />
  </svg>
)

export default SvgUsers
