// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgDoubleBed = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 3.75C3 2.784 3.784 2 4.75 2h10.5c.966 0 1.75.784 1.75 1.75v5.879c.61.504 1 1.267 1 2.121v3.5a.75.75 0 0 1-.75.75H16v.25a.75.75 0 0 1-1.5 0V16h-9v.25a.75.75 0 0 1-1.5 0V16H2.75a.75.75 0 0 1-.75-.75v-3.5c0-.854.39-1.617 1-2.121V3.75Zm1.5 5.261c.082-.007.166-.011.25-.011H5v-.25C5 7.784 5.784 7 6.75 7h6.5c.966 0 1.75.784 1.75 1.75V9h.25c.084 0 .168.004.25.011V3.75a.25.25 0 0 0-.25-.25H4.75a.25.25 0 0 0-.25.25v5.261Zm9-.011v-.25a.25.25 0 0 0-.25-.25h-6.5a.25.25 0 0 0-.25.25V9h7Zm1.75 1.5c.69 0 1.25.56 1.25 1.25v2.75h-13v-2.75c0-.69.56-1.25 1.25-1.25h10.5Z"
    />
  </svg>
)

export default SvgDoubleBed
