// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgStar = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2a.75.75 0 0 1 .715.523L12.298 7.5h4.952a.75.75 0 0 1 .46 1.342l-4.105 3.193 1.369 5.018a.75.75 0 0 1-1.2.776L10 14.722l-3.773 3.107a.75.75 0 0 1-1.2-.776l1.368-5.018L2.29 8.842A.75.75 0 0 1 2.75 7.5h4.952l1.583-4.977A.75.75 0 0 1 10 2Zm0 3.224L8.965 8.478A.75.75 0 0 1 8.25 9H4.936l2.774 2.158a.75.75 0 0 1 .264.79l-.88 3.223 2.43-2a.75.75 0 0 1 .953 0l2.429 2-.88-3.223a.75.75 0 0 1 .264-.79L15.064 9H11.75a.75.75 0 0 1-.715-.522L10 5.224Z"
    />
  </svg>
)

export default SvgStar
