export const BOOK_OFFER_PSD2_ERROR = 'BOOK_OFFER_PSD2_ERROR'

type BookOfferPsd2ErrorType = {
  type: typeof BOOK_OFFER_PSD2_ERROR
}

export type ActionType = BookOfferPsd2ErrorType

export const bookOfferPsd2Error = () => ({
  type: BOOK_OFFER_PSD2_ERROR
})
