// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCocktail = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.842 3.21a.75.75 0 0 0-1.184-.92L12.328 4H3.75a.75.75 0 0 0-.591 1.212l6.091 7.796V16.5h-3.5a.75.75 0 1 0 0 1.5h8.5a.75.75 0 0 0 0-1.5h-3.5v-3.492l6.091-7.796A.75.75 0 0 0 16.25 4h-2.022l.614-.79ZM11.161 5.5l-1.003 1.29a.75.75 0 1 0 1.184.92l1.72-2.21h1.65L10 11.532 5.288 5.5h5.873Z"
    />
  </svg>
)

export default SvgCocktail
