// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgMicrophone = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 26 30"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.8 7.8a7.2 7.2 0 0 1 14.4 0v5.4a7.2 7.2 0 0 1-14.4 0V7.8ZM13 3.3a4.5 4.5 0 0 0-4.5 4.5v5.4a4.5 4.5 0 0 0 9 0V7.8A4.5 4.5 0 0 0 13 3.3ZM1.675 15.002a1.35 1.35 0 0 1 1.423 1.271c0 .001 0 .002-1.348.077l-1.348.075a1.35 1.35 0 0 1 1.273-1.423Zm9.975 10.756c-4.954-.314-7.82-2.353-9.42-4.568a10.152 10.152 0 0 1-1.542-3.213 9.156 9.156 0 0 1-.232-1.078 6.56 6.56 0 0 1-.05-.422l-.003-.032V16.427c0-.001 0-.002 1.347-.077l1.348-.075v-.008.003l.003.037c.004.038.011.103.024.19.027.174.076.437.165.757a7.453 7.453 0 0 0 1.13 2.356C5.604 21.25 7.992 23.1 13 23.1c5.008 0 7.395-1.849 8.58-3.49a7.454 7.454 0 0 0 1.13-2.356 6.456 6.456 0 0 0 .19-.947l.002-.037v-.003.006a1.35 1.35 0 0 1 2.696.152l-1.348-.075c1.348.075 1.348.076 1.348.077v.006l-.001.012-.003.032a6.614 6.614 0 0 1-.05.422c-.04.269-.11.64-.232 1.078a10.152 10.152 0 0 1-1.543 3.213c-1.599 2.215-4.465 4.254-9.419 4.568v2.292a1.35 1.35 0 1 1-2.7 0v-2.292Z"
    />
  </svg>
)

export default SvgMicrophone
