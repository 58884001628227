import {
  anyPass,
  compose,
  head,
  isEmpty,
  isNil,
  join,
  last,
  map,
  pipe,
  reject,
  split,
  toUpper
} from 'ramda'

import {User} from '../types/Cognito'

/**
 * Returns string created by combining first uppercased letters of each word in passed string
 * @param name - any string value
 * @returns {string} string consisting of first uppercase letters of each word in the passed string
 */
export const getInitials = (name: string): string =>
  pipe<[string], string[], string[], string>(
    split(' '),
    map(compose<[string], string, string>(toUpper, head)),
    join('')
  )(name || '')

/**
 * Returns first and last initial of a string passed
 * @param name - fullname
 * @returns {string} string consists of first and last initials of the name
 */
export const getFirstAndLastInitials = (name: string): string => {
  const initials = getInitials(name)

  if (initials.length > 1) {
    return `${head(initials)}${last(initials)}`
  }

  return head(initials)
}

/**
 * Returns the domain of an email address
 * @param email - email address
 * @returns {string} email domain prefixed with `@`, or undefined if email is invalid or empty
 */
export const getEmailDomain = (email?: string): string | undefined => {
  if (!email) return

  const splitedEmail = email.split('@')
  if (splitedEmail.length <= 1) return

  const domain = splitedEmail[splitedEmail.length - 1]

  return `@${domain}`
}

/**
 * Return the user's name or a default value if no name is found
 * @param user - user
 * @returns {string} name of the user, or an empty string if no name is found in the user object
 */
export const getUserName = (user: Partial<User> | null): string =>
  head(
    reject(anyPass([isNil, isEmpty]))([
      user?.attributes?.given_name,
      user?.attributes?.family_name,
      user?.attributes?.email
    ])
  ) || ''
