// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgTimer = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 2.75A.75.75 0 0 1 7.75 2h4.5a.75.75 0 0 1 0 1.5h-1.5v.54a6.967 6.967 0 0 1 3.641 1.508l.329-.328a.75.75 0 1 1 1.06 1.06l-.328.329a7 7 0 1 1-6.202-2.57V3.5h-1.5A.75.75 0 0 1 7 2.75ZM4.5 11a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0Zm6.25-3.25a.75.75 0 0 0-1.5 0v3.5a.75.75 0 0 0 1.5 0v-3.5Z"
    />
  </svg>
)

export default SvgTimer
