// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPram = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2.75a.75.75 0 0 1 .75-.75c3.136 0 4.978 1.382 6.014 2.81a7.318 7.318 0 0 1 1.173 2.556 4.941 4.941 0 0 1 .05.245l.003.018.001.006v.003l-.741.112.742-.111A.74.74 0 0 1 18 7.75v2.5a3.75 3.75 0 0 1-1.948 3.29A2.5 2.5 0 1 1 12.5 14h-4a2.5 2.5 0 1 1-3.552-.46A3.749 3.749 0 0 1 3 10.25V7.927l-.92-1.841a.75.75 0 0 1 1.34-.671L4.215 7H10V2.75ZM11.5 7h4.756a5.78 5.78 0 0 0-.707-1.31c-.7-.965-1.909-1.966-4.049-2.157V7Zm-7 1.5v1.75a2.25 2.25 0 0 0 2.25 2.25h7.5a2.25 2.25 0 0 0 2.25-2.25V8.5h-12Zm2 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm7 1a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
    />
  </svg>
)

export default SvgPram
