// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPencil = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.644 2.52a1.75 1.75 0 0 0-2.475 0l-9.95 9.95a.75.75 0 0 0-.199.358l-1 4.25a.75.75 0 0 0 .902.902l4.25-1a.75.75 0 0 0 .358-.2l9.95-9.949a1.75 1.75 0 0 0 0-2.475l-1.836-1.835Zm-1.415 1.061a.25.25 0 0 1 .354 0l1.836 1.836a.25.25 0 0 1 0 .354l-1.302 1.301-2.19-2.19 1.303-1.3Zm-2.362 2.362L4.431 13.38l-.673 2.863 2.863-.673 7.435-7.436-2.189-2.19Z"
    />
  </svg>
)

export default SvgPencil
