import React, {createContext} from 'react'

import {Team} from '../../types/Events'

export type TeamContextType = {
  team: Team
}

export const TeamContext = createContext<TeamContextType>({
  team: Team.Default
})

type PropsTypes = {
  team: Team
  children: React.ReactNode
}

export const TeamContextProvider: React.FC<PropsTypes> = ({
  team = Team.Default,
  children
}) => <TeamContext.Provider value={{team}}>{children}</TeamContext.Provider>
