import {getCountryCallingCode} from 'react-phone-number-input'
import {dissocPath, omit} from 'ramda'

import {getConnection, getUserTier} from '../../../auth/modules/selectors'
import {User} from '../../../auth/types/Cognito'
import {Brand} from '../../../brand/types'
import {checkIsBrandVio} from '../../../brand/utils'
import {getDeviceCategory} from '../../../utils/userAgent'
import {Action, Category, Entity} from '../../types/Events'
import {getCio} from './getCio'
import {BookingContactDetails, EventPayload, TrackEvent} from './types'

const deviceInfo = () => ({
  deviceType: getDeviceCategory(),
  userAgent: navigator.userAgent
})

const getBookingPhoneNumber = (phone?: BookingContactDetails['phone']) => {
  if (!phone) return
  const countryCallingCode = getCountryCallingCode(phone.countryCode)

  return `+${countryCallingCode}${phone.number}`
}

const formatUserContactDetails = (
  bookingContactDetails?: BookingContactDetails | null,
  user?: User | null
) => {
  const {attributes} = user || {}

  if (!bookingContactDetails && !user) return

  return {
    firstName: attributes?.given_name || bookingContactDetails?.firstName,
    lastName: attributes?.family_name || bookingContactDetails?.lastName,
    phone:
      attributes?.phone_number ||
      getBookingPhoneNumber(bookingContactDetails?.phone),
    email: attributes?.email || bookingContactDetails?.email,
    bookingFirstName: bookingContactDetails?.firstName,
    bookingLastName: bookingContactDetails?.lastName,
    bookingEmail: bookingContactDetails?.email,
    bookingPhone: getBookingPhoneNumber(bookingContactDetails?.phone)
  }
}

const addOrUpdateUser: TrackEvent = ({
  user,
  country,
  language,
  anonymousId,
  bookingContactDetails,
  timezone
}) => {
  if (!user && !bookingContactDetails) return

  const userId = user?.attributes?.sub || bookingContactDetails?.email
  const authenticationMethod = user && getConnection(user)
  const tier = getUserTier(user)
  const userContactDetails = formatUserContactDetails(
    bookingContactDetails,
    user
  )

  const event: EventPayload = {
    ...(tier && {tier}),
    ...(country && {country}),
    ...(language && {language}),
    ...(timezone && {timezone}),
    ...(authenticationMethod && {authenticationMethod}),
    ...userContactDetails,
    ...deviceInfo()
  }

  getCio()?.identify({
    anonymous_id: anonymousId,
    id: userId,
    ...event
  })
}

/**
 * Formats a given date (in milliseconds since the Unix Epoch) according to the specified locale.
 *
 * @param {string} locale - A locale string (e.g., 'en-US', 'fr-FR') to format the date.
 * @param {number} date - A date in milliseconds since the Unix Epoch (January 1, 1970).
 * @returns {string} - The formatted date string according to the specified locale.
 *
 * @example
 * console.log(toLocale('en-US', 1672531199000)); // Output: "Dec 31, 2022"
 * console.log(toLocale('fr-FR', 1672531199000)); // Output: "31 déc. 2022"
 * console.log(toLocale('ja-JP', 1672531199000)); // Output: "2022年12月31日"
 */
const toLocale = (locale: string, date: number) =>
  new Date(date).toLocaleDateString(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC'
  })

export const addCheckInCheckOutString = (payload: EventPayload) => {
  const {checkIn, checkOut, locale} = payload || {}
  if (!checkIn || !checkOut || !locale) return payload

  return {
    ...payload,
    checkInString: toLocale(locale, checkIn * 1000),
    checkOutString: toLocale(locale, checkOut * 1000)
  }
}

const trackEvent: TrackEvent = ({...event}) => {
  const eventName = event?.name as string
  const payload = omit(['name'], event)

  const augmentedPayload = addCheckInCheckOutString(payload)

  if (payload?.userId) {
    getCio()?.track(eventName, {...augmentedPayload})
    return
  }

  const userContactDetails =
    formatUserContactDetails(augmentedPayload?.bookingContactDetails) || {}

  const removeDuplicatedBookingContactDetails = dissocPath<EventPayload>(
    ['bookingContactDetails'],
    augmentedPayload
  )

  getCio()?.track(eventName, {
    ...removeDuplicatedBookingContactDetails,
    ...userContactDetails,
    ...deviceInfo()
  })
}

export const cioTrackPageViewName = (): string =>
  `${Category.System}_${Entity.Page}_${Action.Displayed}`

const trackPageView: TrackEvent = params =>
  trackEvent({
    ...params,
    name: cioTrackPageViewName()
  })

const emptyFn: () => void = () => undefined

const emptyCio = {
  addOrUpdateUser: emptyFn,
  trackEvent: emptyFn,
  trackPageView: emptyFn
}

export interface CustomerIoFunctions {
  addOrUpdateUser: TrackEvent
  trackEvent: TrackEvent
  trackPageView: TrackEvent
}

export const customerIo = (brand: Brand): CustomerIoFunctions => {
  // Cio should be triggered only when brand is Vio
  if (!checkIsBrandVio(brand)) {
    return emptyCio
  }

  return {
    addOrUpdateUser,
    trackEvent,
    trackPageView
  }
}
