// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgFlower = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 5.5a2 2 0 0 1 3.819-.834.75.75 0 0 0 1.362 0 2 2 0 1 1 3.589 1.767.75.75 0 0 0 .546 1.092 2 2 0 0 1 0 3.95.75.75 0 0 0-.546 1.092 2 2 0 1 1-3.588 1.767.75.75 0 0 0-1.363 0 2 2 0 1 1-3.589-1.767.75.75 0 0 0-.545-1.092 2 2 0 0 1 0-3.95.75.75 0 0 0 .545-1.092A1.988 1.988 0 0 1 5.5 5.5Zm2-3.5a3.5 3.5 0 0 0-3.409 4.295 3.5 3.5 0 0 0 0 6.41A3.5 3.5 0 0 0 10 15.95a3.5 3.5 0 0 0 5.91-3.245 3.5 3.5 0 0 0 0-6.41A3.5 3.5 0 0 0 10 3.05 3.489 3.489 0 0 0 7.5 2Zm1 7.75a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm1.5-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
    />
  </svg>
)

export default SvgFlower
