import {assoc, curry, keys, reduce, reject} from 'ramda'

/**
 * Creates a new object with the own properties of the provided object, but the
 * keys renamed according to the keysMap object as `{oldKey: newKey}`.
 * When some key is not found in the keysMap, then it's passed as-is.
 *
 * Example: {a: b} -> {a: *} -> {b: *}
 */
export const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
)

export const safeJsonParse = <T = unknown>(obj: unknown): T => {
  try {
    const str = obj as string
    return JSON.parse(str) as T
  } catch {
    return {} as T
  }
}

/**
 * Creates a proxy object that allows to access object properties by any-case keys
 **/

export const removeKeyCaseSensitivity = <T extends Record<string, any>>(
  obj: T
) => {
  const lowercaseKeysObject: T = Object.keys(obj).reduce(
    (acc, key) => {
      acc[key.toLowerCase()] = obj[key]
      return acc
    },
    {} as Record<any, any>
  )

  return new Proxy(obj, {
    get: (_, key: string) => {
      if (key.toLowerCase() in lowercaseKeysObject) {
        return lowercaseKeysObject[(key as string).toLowerCase()]
      }
    }
  })
}

/**
 * Removes nullish string values from the object
 * @param obj - object to sanitize
 * @returns sanitized object
 */
export const sanitizeNullishStrings = (obj: Record<string, string>) =>
  reject(value => value === 'null' || value === 'undefined', obj)
