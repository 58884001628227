import {
  any,
  filter,
  find,
  isEmpty,
  isNil,
  lensProp,
  map,
  over,
  pipe,
  propEq
} from 'ramda'

import {MerchantOfRecord} from '@daedalus/core/src/api-types/bovio/response/booking'
import {getCancellationDeadline} from '@daedalus/core/src/offer/business/cancellationPenalties'
import getIsPartiallyRefundableOffer from '@daedalus/core/src/offer/business/getIsPartiallyRefundableOffer'
import {
  ActionLink,
  ActionLinkTypes
} from '@daedalus/core/src/offer/types/actionLink'
import {Offer} from '@daedalus/core/src/offer/types/offer'
import {Room} from '@daedalus/core/src/room/types/room'
import isOfferRefundable from '@daedalus/core/src/utils/refundableOffer'

import {sortOffersByNightlyDisplayTotalAsc} from './sortRooms'

export enum OfferDealType {
  CHEAPEST = 'cheapest',
  CHEAPEST_BY_A_LOT = 'cheapest_by_a_lot',
  EXPENSIVE = 'expensive',
  EXPENSIVE_BY_A_LOT = 'expensive_by_a_lot',
  PARITY = 'parity',
  SOLD_OUT = 'soldOut',
  PARTIAL_PARITY = 'partialParity'
}

const PARITY_TOLERANCE = 0.01
const CHEAPEST_BY_A_LOT_THRESHOLD = 0.03
const EXPENSIVE_BY_A_LOT_THRESHOLD = 0.03

const isSelectedOfferCheapest = (
  selectedOfferPrice: number,
  lowestProviderPrice: number
): boolean => Math.round(selectedOfferPrice) < Math.round(lowestProviderPrice)

export const isSelectedOfferCheapestByALot = (
  selectedOfferPrice: number,
  lowestProviderPrice: number
): boolean =>
  1 - Math.round(selectedOfferPrice) / Math.round(lowestProviderPrice) >
  CHEAPEST_BY_A_LOT_THRESHOLD

const isSelectedOfferExpensive = (
  selectedOfferPrice: number,
  lowestProviderPrice: number
): boolean => Math.round(selectedOfferPrice) > Math.round(lowestProviderPrice)

export const isSelectedOfferExpensiveByALot = (
  selectedOfferPrice: number,
  lowestProviderPrice: number
): boolean =>
  Math.round(selectedOfferPrice) / Math.round(lowestProviderPrice) - 1 >
  EXPENSIVE_BY_A_LOT_THRESHOLD

export const isLowestPriceWithinTolerance = (
  selectedOfferPrice: number,
  lowestProviderPrice: number,
  tolerance: number
): boolean =>
  Math.abs(selectedOfferPrice - lowestProviderPrice) <
  selectedOfferPrice * tolerance

export const isLowestPriceWithinParityTolerance = (
  selectedOfferPrice: number,
  lowestProviderPrice: number
): boolean =>
  isLowestPriceWithinTolerance(
    selectedOfferPrice,
    lowestProviderPrice,
    PARITY_TOLERANCE
  )

export const getDealClassificationTypeWithTolerance = (
  selectedOfferPrice: number | null,
  lowestProviderPrice: number | null,
  isSoldOut: boolean
): OfferDealType => {
  if (isSoldOut) {
    return OfferDealType.SOLD_OUT
  }

  // These scenarios are for when there are no provider offers
  // for comparison or is a private deal
  if (
    any(isNil)([selectedOfferPrice, lowestProviderPrice]) ||
    selectedOfferPrice === lowestProviderPrice
  ) {
    return OfferDealType.PARITY
  }

  if (!(selectedOfferPrice && lowestProviderPrice)) return OfferDealType.PARITY
  // This scenario is if the lowestProviderPrice is within the threshold defined in the constant PARITY_TOLERANCE of not being cheaper or more expensive than the bofh price
  if (
    isLowestPriceWithinParityTolerance(selectedOfferPrice, lowestProviderPrice)
  ) {
    return OfferDealType.PARTIAL_PARITY
  }

  if (isSelectedOfferCheapestByALot(selectedOfferPrice, lowestProviderPrice)) {
    return OfferDealType.CHEAPEST_BY_A_LOT
  }

  if (isSelectedOfferCheapest(selectedOfferPrice, lowestProviderPrice)) {
    return OfferDealType.CHEAPEST
  }

  if (isSelectedOfferExpensiveByALot(selectedOfferPrice, lowestProviderPrice)) {
    return OfferDealType.EXPENSIVE_BY_A_LOT
  }

  if (isSelectedOfferExpensive(selectedOfferPrice, lowestProviderPrice)) {
    return OfferDealType.EXPENSIVE
  }

  return OfferDealType.PARITY
}

export const isDealTypeExpensive = (dealType: OfferDealType) =>
  dealType === OfferDealType.EXPENSIVE

export const isDealTypeExpensiveByALot = (dealType: OfferDealType) =>
  dealType === OfferDealType.EXPENSIVE_BY_A_LOT

export const isDealTypeCheapest = (dealType: OfferDealType) =>
  dealType === OfferDealType.CHEAPEST

export const isDealTypeCheapestByALot = (dealType: OfferDealType) =>
  dealType === OfferDealType.CHEAPEST_BY_A_LOT

export const isDealTypeParity = (dealType: OfferDealType) =>
  dealType === OfferDealType.PARITY || dealType === OfferDealType.PARTIAL_PARITY

export const isDealTypeSoldOut = (dealType: OfferDealType) =>
  dealType === OfferDealType.SOLD_OUT

export const isFreeCancellationOffer = (offer: Offer): boolean => {
  const {cancellationPenalties} = offer
  const refundable = isOfferRefundable(cancellationPenalties)

  const deadline = getCancellationDeadline(cancellationPenalties)
  return (
    Boolean(refundable) && !getIsPartiallyRefundableOffer(refundable, deadline)
  )
}

// MOR refers to Merchant of Records, meaning, which entity takes and processes the payment for this offer, this can be the providers, the property/hotel, or FindHotel itself
export const isHotelMor = (offer: Offer) =>
  offer?.merchantOfRecord === MerchantOfRecord.Hotel

export const roomHasOffers = (room: Room) => !isEmpty(room.offers)

type RoomPredicateType = (room: Room) => boolean
type RoomOffersPredicateType = (offer: Offer) => boolean

// Iterates over the rooms and applies a function to the list of offers in each
// room. The function determines whether a given offer will be filtered out or not
export const filterRoomOffersByPredicate = (
  predicate: RoomOffersPredicateType,
  rooms: Room[]
) => {
  const offersLens = lensProp<Room, 'offers'>('offers')
  return map(over(offersLens, filter(predicate)), rooms)
}

// Iterates over the rooms and applies a function to the object.
// The function determines whether a given room will be filtered out or not
export const filterRoomsByPredicate = (
  predicate: RoomPredicateType,
  rooms: Room[]
) => filter(predicate, rooms)

export const filterRefundableRooms = (unfilteredRooms: Room[]) =>
  pipe<[Room[]], Room[], Room[]>(
    rooms => filterRoomOffersByPredicate(isFreeCancellationOffer, rooms),
    rooms => filterRoomsByPredicate(roomHasOffers, rooms)
  )(unfilteredRooms)

/**
 * Returns the offers that will be displayed to the user
 * @param offers
 */
export const getDisplayOffers = (offers: Offer[]) =>
  sortOffersByNightlyDisplayTotalAsc(offers)

export const getCheckoutUrlFromOffer = (offer: Offer | null | undefined) =>
  find<ActionLink>(
    propEq('type', ActionLinkTypes.CHECKOUT),
    (offer?.links as ActionLink[]) || []
  )
