// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgAnchor = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM10 2a3 3 0 0 0-.75 5.905V9.5h-1.5a.75.75 0 0 0 0 1.5h1.5v5.458a7.649 7.649 0 0 1-3.498-1.322c-1.219-.855-2.054-2.008-2.262-3.26a.75.75 0 1 0-1.48.247c.292 1.75 1.427 3.221 2.88 4.241C6.346 17.386 8.183 18 10 18c1.817 0 3.654-.614 5.11-1.636 1.453-1.02 2.588-2.492 2.88-4.24a.75.75 0 1 0-1.48-.247c-.208 1.25-1.043 2.404-2.262 3.26a7.649 7.649 0 0 1-3.498 1.321V11h1.5a.75.75 0 0 0 0-1.5h-1.5V7.905A3.001 3.001 0 0 0 10 2Z"
    />
  </svg>
)

export default SvgAnchor
