// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgBurger = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 5.75A3.75 3.75 0 0 1 6.75 2h6.5A3.75 3.75 0 0 1 17 5.75v2a.75.75 0 0 1-.75.75H3.75A.75.75 0 0 1 3 7.75v-2ZM6.75 3.5A2.25 2.25 0 0 0 4.5 5.75V7h11V5.75a2.25 2.25 0 0 0-2.25-2.25h-6.5ZM3 12.75a.75.75 0 0 1 .75-.75H10a.75.75 0 0 1 .335.08l1.665.832 1.665-.833A.75.75 0 0 1 14 12h2.25a.75.75 0 0 1 .75.75v1.5A3.75 3.75 0 0 1 13.25 18h-6.5A3.75 3.75 0 0 1 3 14.25v-1.5Zm1.5.75v.75a2.25 2.25 0 0 0 2.25 2.25h6.5a2.25 2.25 0 0 0 2.25-2.25v-.75h-1.323l-1.841.92a.75.75 0 0 1-.671 0l-1.842-.92H4.5Zm-1.75-4a.75.75 0 0 0 0 1.5h14.5a.75.75 0 1 0 0-1.5H2.75Z"
    />
  </svg>
)

export default SvgBurger
