// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgUnlock = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 16 16"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.625 3.797C5.968 3.326 6.632 2.8 8 2.8l2.141.986a.6.6 0 0 0 .92-.77l-.44.367.44-.368-.001-.001-.002-.002-.004-.006-.014-.016a3.63 3.63 0 0 0-.208-.214 4.522 4.522 0 0 0-.599-.476C9.72 1.957 8.953 1.6 8 1.6c-1.74 0-2.77.7-3.345 1.491-.37.507-.527 1.078-.597 1.621-.06.47-.06.958-.058 1.39V6.4h-.2a1.4 1.4 0 0 0-1.4 1.4v4.4a2.2 2.2 0 0 0 2.2 2.2h6.8a2.2 2.2 0 0 0 2.2-2.2V7.8a1.4 1.4 0 0 0-1.4-1.4h-7v-.304c0-.44-.002-.845.048-1.23.055-.428.168-.782.377-1.069ZM8 2.8l2.141.986-.003-.004a3.329 3.329 0 0 0-.57-.483c-.386-.257-.921-.5-1.568-.5Zm-4.4 5c0-.11.09-.2.2-.2h8.4c.11 0 .2.09.2.2v4.4a1 1 0 0 1-1 1H4.6a1 1 0 0 1-1-1V7.8Z"
    />
  </svg>
)

export default SvgUnlock
