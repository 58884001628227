// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgMessage = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 10c0-2.01.822-3.347 2.004-4.206C6.716 4.912 8.36 4.5 10 4.5c1.64 0 3.284.412 4.496 1.294C15.678 6.654 16.5 7.991 16.5 10c0 2.01-.822 3.347-2.004 4.206C13.284 15.088 11.64 15.5 10 15.5c-.492 0-.988-.037-1.475-.114a2.99 2.99 0 0 0-1.02.006l-3.207.602a.25.25 0 0 1-.291-.292l.262-1.399c.133-.712-.043-1.393-.296-1.946C3.677 11.711 3.5 10.933 3.5 10ZM10 3c-1.86 0-3.84.463-5.379 1.581C3.053 5.721 2 7.51 2 10.001c0 1.126.215 2.12.61 2.98.176.387.241.742.184 1.046l-.262 1.398a1.75 1.75 0 0 0 2.043 2.043l3.207-.602c.153-.028.324-.028.508.001.568.09 1.143.133 1.71.133 1.86 0 3.841-.463 5.379-1.58C16.947 14.277 18 12.49 18 10c0-2.49-1.053-4.278-2.621-5.419-1.538-1.118-3.52-1.58-5.379-1.58ZM7 9a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm2 1a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm4-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
    />
  </svg>
)

export default SvgMessage
