import {useEffect, useState} from 'react'
import {equals} from 'ramda'
import {urlParams} from 'utils/urlParams'

import {UrlCheckoutParamsType} from '@daedalus/core/src/utils/url/types/UrlParams'

const useParams = (): UrlCheckoutParamsType => {
  const [params, setParams] = useState(urlParams())

  useEffect(() => {
    const currentUrlParams = urlParams()
    if (!equals(params, currentUrlParams)) {
      setParams(currentUrlParams)
    }
  })

  return params
}

export default useParams
