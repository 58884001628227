import {Dispatch, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {lensPath, set} from 'ramda'

import {trackEvent} from '../../analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity
} from '../../analytics/types/Events'
import AnalyticsUtils from '../../analytics/utils'
import {loadFullStory} from '../../analytics/utils/FullStory'
import {postMessageToWebView, RNWebViewMessageTypes} from '../../native'
import {getConnection} from '../modules/selectors'
import {setIsLoading, setUser} from '../modules/slice'
import {getCurrentUserService} from '../services'
import {User} from '../types/Cognito'

interface FetchUserProps {
  publicUrl?: string | null
}

type UpdateUserProps = {
  publicUrl?: FetchUserProps['publicUrl']
  dispatch: Dispatch<unknown>
  skipLoadingState?: boolean
}

export const updateUser = async ({
  publicUrl,
  dispatch,
  skipLoadingState = false
}: UpdateUserProps) => {
  if (!skipLoadingState) dispatch(setIsLoading(true))

  try {
    const {user, userPoolId} = await getCurrentUserService()
    const isUserAuthenticated = Boolean(user?.id)

    AnalyticsUtils.resetAnalytics(user?.attributes?.sub)

    postMessageToWebView({
      type: RNWebViewMessageTypes.USER_ID,
      payload: {userId: user?.attributes?.sub}
    })

    if (isUserAuthenticated) {
      const payload = {
        user,
        isAuthenticated: true,
        shouldSeeOffersUnlocked: true,
        userPoolId
      }

      const userWithAuthenticationMethod = set(
        lensPath(['user', 'attributes', 'authenticationMethod']),
        getConnection(user as User),
        payload
      )

      dispatch(setUser(userWithAuthenticationMethod))

      postMessageToWebView({
        type: RNWebViewMessageTypes.USER_AUTHENTICATED,
        payload: {user: user ?? undefined}
      })

      if (publicUrl) loadFullStory(publicUrl)
    }
  } catch (error) {
    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.Search,
        action: Action.Errored,
        analyticsContext: {
          [AnalyticsContext.ErrorContext]: {
            errorType: 'CognitoAuthError',
            errorMessage: (error as Error).message
          }
        }
      })
    )
  }
  dispatch(setIsLoading(false))
}

export const FetchUser = ({publicUrl}: FetchUserProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    updateUser({publicUrl, dispatch})
  }, [dispatch])

  return null
}
