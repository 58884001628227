import axios from 'axios'
import {hash as md5} from 'spark-md5'
import {v4 as uuid} from 'uuid'

import {UserIdentifiableTraits} from '../../../auth/types/Cognito'
import {Brand} from '../../../brand/types'

interface Traits {
  user?: UserIdentifiableTraits
}

const axiosInstance = axios.create()

interface Msg {
  anonymousId: string
  userId: string
  traits?: Traits
  brandName: Brand['name']
}

// NOTE: This is inspired by Segment normalize method
// Reference: https://github.com/FindHotel/analytics.js/blob/findhotel-master/analytics.js#L3380
const normalize = (msg: Msg) => {
  const id = String(md5(`${JSON.stringify(msg)}${String(uuid())}`))
  const {anonymousId, userId, traits, brandName} = msg

  return {
    anonymousId,
    userId,
    traits,
    context: {
      brandName,
      platform: 'web',
      userAgent: navigator.userAgent,
      app: {name: 'website', version: '1.0.0'},
      library: {
        name: 'daedalus-client',
        version: '1.0.0'
      }
    },
    sentAt: Date.now(),
    timestamp: Date.now(),
    messageId: `ajs-${id}`,
    type: 'identify',
    version: 1
  }
}

interface CustomIdentityParams {
  anonymousId: string
  userId: string
  customerDataEndpoint: string
  customerDataApiKey: string
  brandName: Brand['name']
  cognitoTraits?: UserIdentifiableTraits
}

export const customIdentify = async ({
  anonymousId,
  userId,
  customerDataEndpoint,
  customerDataApiKey,
  brandName,
  cognitoTraits
}: CustomIdentityParams) => {
  if (!customerDataEndpoint) return

  if (!userId && !anonymousId) {
    throw new Error('Empty userId and anonymousId')
  }

  const traits: Traits = {user: cognitoTraits}

  const data = normalize({
    anonymousId,
    userId,
    traits,
    brandName
  })

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': customerDataApiKey
    }
  }

  return axiosInstance.post(customerDataEndpoint, data, config)
}
