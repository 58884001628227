import {MiddlewareType} from 'middlewares'
import {logEvent} from 'modules/analytics/actions/logEvent'
import {fatalError} from 'modules/common/actions/fatalError'
import {getHttpRequestId} from 'modules/common/selectors'
import {
  paymentOptionsFetch,
  paymentOptionsFetchDone,
  paymentOptionsFetchError,
  paymentOptionsFetchPending
} from 'modules/paymentOptions/slice'
import logBoFHService, {
  BoFHServiceEndpointNames,
  BoFHServiceStatus
} from 'performance/logBoFHService'
import {fetchPaymentOptionsService} from 'services/rooms'
import timing from 'utils/timing'

import {getBrandCode} from '@daedalus/core/src/_web/brand/modules/selectors'

import fetchCycle from '../fetchCycle'

const requestsTiming = timing()
const middleware: MiddlewareType = store => next => action => {
  const {dispatch, getState} = store
  const state = getState()
  const httpRequestId = getHttpRequestId(state)
  const brandCode = getBrandCode(state)
  next(action)

  switch (action.type) {
    case paymentOptionsFetch.type: {
      requestsTiming.start('paymentOptions')
      fetchCycle(
        dispatch,
        fetchPaymentOptionsService(action.payload, httpRequestId, brandCode),
        paymentOptionsFetchPending,
        paymentOptionsFetchDone,
        paymentOptionsFetchError,
        fatalError
      )

      logBoFHService(
        BoFHServiceEndpointNames.PaymentOptions,
        BoFHServiceStatus.start,
        {request_id: httpRequestId}
      )

      dispatch(
        logEvent('services.BoFHAPI.payment-options', {
          meta: {params: action.payload},
          request_id: httpRequestId
        })
      )
      break
    }

    case paymentOptionsFetchDone.type: {
      const timings = requestsTiming.stop('paymentOptions')
      logBoFHService(
        BoFHServiceEndpointNames.PaymentOptions,
        BoFHServiceStatus.done,
        {timings, request_id: httpRequestId}
      )
      break
    }

    case paymentOptionsFetchError.type: {
      const timings = requestsTiming.stop('paymentOptions')
      logBoFHService(
        BoFHServiceEndpointNames.PaymentOptions,
        BoFHServiceStatus.error,
        {
          error: action?.data?.response?.data,
          timings,
          request_id: httpRequestId
        },
        'error'
      )
      break
    }

    default:
      break
  }
}

export default middleware
