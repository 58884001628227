// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCone = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2a.75.75 0 0 1 .705.495L15.776 16.5h1.474a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1 0-1.5h1.474l5.07-14.005A.75.75 0 0 1 10 2Zm2.732 10.5L10 4.953 7.267 12.5h5.465Zm.544 1.5H6.724l-.905 2.5h8.362l-.905-2.5Z"
    />
  </svg>
)

export default SvgCone
