// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgFacebookMessengerOutline = ({
  color = 'currentColor',
  size = 20
}: IconProps) => (
  <svg
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 20"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 9.796C2.5 5.49 6.082 2 10.5 2s8 3.49 8 7.796-3.582 7.796-8 7.796a8.19 8.19 0 0 1-2.448-.372l-2.28.741a.629.629 0 0 1-.555-.082.595.595 0 0 1-.255-.487v-1.97C3.445 14.004 2.5 12.007 2.5 9.796Zm8-6.597c-3.739 0-6.77 2.954-6.77 6.597 0 1.954.871 3.709 2.257 4.917.13.114.205.277.205.447v1.4l1.666-.54a.63.63 0 0 1 .399.002c.701.24 1.456.37 2.243.37 3.739 0 6.77-2.953 6.77-6.596 0-3.643-3.031-6.597-6.77-6.597Zm4.453 5.092-2.175 3.134c-.348.49-1.09.618-1.61.249L9.43 10.513a.478.478 0 0 0-.534 0l-2.345 1.61c-.312.222-.72-.114-.505-.422l2.175-3.134c.348-.49 1.09-.618 1.61-.269L11.57 9.48a.478.478 0 0 0 .534 0l2.345-1.61c.312-.222.72.114.505.422Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgFacebookMessengerOutline
