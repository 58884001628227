// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCheck2 = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.715 6.339h-.002l-.007.007-.036.029-.143.118a17.89 17.89 0 0 0-.532.47c-.45.412-1.062 1.01-1.712 1.75-1.312 1.495-2.726 3.516-3.308 5.728a.75.75 0 0 1-1.335.245l-2.5-3.5a.75.75 0 0 1 1.22-.872l1.648 2.307c.792-1.939 2.038-3.633 3.147-4.897a24.26 24.26 0 0 1 1.827-1.867 19.472 19.472 0 0 1 .74-.645l.045-.036.013-.01.003-.003h.002c0-.001 0-.002.465.587l-.466-.588a.75.75 0 0 1 .93 1.177Z"
    />
  </svg>
)

export default SvgCheck2
