import {Params} from '../getConfig'

export const amplifyEtripStaging: Params = {
  aws_user_pools_id: 'eu-west-1_ilOJ69tYk',
  aws_cognito_identity_pool_id:
    'eu-west-1:3f68cc1d-b3a3-45a0-aa16-4a5be96f1acf',
  aws_user_pools_web_client_id: '4efn9h58jld0ktao399r7ik69n',
  oauth_domain: 'staging.dev-authentication.etrip.net',
  aws_user_files_s3_bucket: 'etrip-profile-pictures-dev'
}
