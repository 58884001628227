// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgDownHand = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 40 32"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      opacity={0.5}
      d="M37.566 16.05c1.078-.246 2.146.449 2.386 1.55.229 1.052-.38 2.095-1.374 2.4l-.145.039-16.48 3.742a8.812 8.812 0 0 1-3.518.079l-.387-.079-16.481-3.742C.488 19.794-.192 18.702.048 17.6c.229-1.052 1.213-1.733 2.24-1.58l.146.028 16.481 3.742c.625.142 1.27.16 1.9.054l.27-.054 16.48-3.742Z"
    />
  </svg>
)

export default SvgDownHand
