import React from 'react'
import {css, useTheme} from '@emotion/react'
import styled from '@emotion/styled'

import {PdLocked, PdUnlocked} from '@findhotel/atlas-assets'

export interface Props {
  /** Whether the Lock is opened or closed */
  isLocked?: boolean
  /** The size of the lock */
  size?: number
  /** Whether the Lock is white color */
  isWhite?: boolean
  /** Whether the Lock follows the current color (color from the parent element) */
  isCurrentColor?: boolean
}

const WrapperElement = styled.span<{size: number}>(
  ({size}) => css`
    font-size: ${size}px;
    display: inline-flex;
    align-items: center;
  `
)

// added as part of b4f4f3cc-website-restyle-v3
export const NewStyleLock = ({isLocked = true, size = 16}: Props) => {
  return (
    <WrapperElement size={size}>
      {isLocked ? <PdLocked size={size} /> : <PdUnlocked size={size} />}
    </WrapperElement>
  )
}

export const Lock = ({
  isLocked = true,
  size = 16,
  isWhite = false,
  isCurrentColor = false
}: Props) => {
  const theme = useTheme()
  const color = isWhite
    ? theme.colors.content.neutral.c000
    : theme.colors.content.special.c500

  return (
    <WrapperElement size={size}>
      {isLocked ? (
        <PdLocked
          size={size}
          color={!isCurrentColor ? color : 'currentColor'}
        />
      ) : (
        <PdUnlocked
          size={size}
          color={!isCurrentColor ? color : 'currentColor'}
        />
      )}
    </WrapperElement>
  )
}
