import {PaymentFormValues} from 'components/PaymentForm/initialFormValues'
import {assocPath, isEmpty, map, omit, pipe} from 'ramda'

interface PhoneNumberRequest {
  countryCode: string
  number: string
}

interface RoomRequest {
  bedType: string
  email: string
  firstName: string
  guests: string
  lastName: string
  phone: PhoneNumberRequest
  smoking: boolean
  specialRequest: string
  id: string
}

export interface SanitizedFormData {
  payment?: {
    creditCard: EmptyCreditCardData
    billing: {
      addredd: {
        fullAddress: string
      }
    }
  }
  customer: {
    email: string
    firstName: string
    lastName: string
    phone: PhoneNumberRequest
  }
  rooms?: RoomRequest[]
}

export const setSessionStorageItem = (
  key: string,
  obj: SanitizedFormData | boolean
) => {
  window.sessionStorage.setItem(key, JSON.stringify(obj))
}

export const getSessionStorageItem = (key: string) =>
  JSON.parse(window.sessionStorage.getItem(key) as string)

const SESSION_STORAGE_FORM_DATA_KEY = 'formData'
const SESSION_STORAGE_REFRESHED_BROWSER_ONCE = 'refreshedOnce'

interface EmptyCreditCardData {
  expirationMonth: string
  expirationYear: string
  number: string
  rawExpirationDate: string
  securityCode: string
  cardholdersName: string
  type: string
}

const emptyCreditCardData = (): EmptyCreditCardData => ({
  expirationMonth: '',
  expirationYear: '',
  number: '',
  rawExpirationDate: '',
  securityCode: '',
  cardholdersName: '',
  type: ''
})

const omitRoomsOptions = (formData?: SanitizedFormData | PaymentFormValues) =>
  isEmpty(formData)
    ? null
    : map(omit(['bedType', 'smoking']), formData?.rooms || [])

export const setFormData = (
  formData?: SanitizedFormData | PaymentFormValues
) => {
  const sanitizedFormData = pipe(
    assocPath(['payment', 'creditCard'], emptyCreditCardData()),
    assocPath(['rooms'], omitRoomsOptions(formData)),
    assocPath(['payment', 'billing', 'address', 'fullAddress'], '')
  )(formData) as SanitizedFormData

  return setSessionStorageItem(SESSION_STORAGE_FORM_DATA_KEY, sanitizedFormData)
}

export const setPageAsRefreshedStatus = (status = true) => {
  return setSessionStorageItem(SESSION_STORAGE_REFRESHED_BROWSER_ONCE, status)
}

export const getPageAsRefreshedStatus = () => {
  return getSessionStorageItem(SESSION_STORAGE_REFRESHED_BROWSER_ONCE) || false
}

export const getFormData = (): SanitizedFormData => {
  return getSessionStorageItem(SESSION_STORAGE_FORM_DATA_KEY) || {}
}
