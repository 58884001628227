import React, {useContext} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import copy from 'copy-to-clipboard'

import {Icon} from '@daedalus/atlas/src/components/designSystem/Icon'
import {InputButton} from '@daedalus/atlas/src/components/designSystem/InputButton'
import {Spinner} from '@daedalus/atlas/src/components/designSystem/Spinner'
import {Text} from '@daedalus/atlas/src/components/designSystem/Text'
import {useToastController} from '@daedalus/atlas/src/context/toastController'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {selectUser} from '@daedalus/core/src/auth/modules/selectors'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {ReferralLinkContext} from '../../ReferralLinkProvider'

interface Props {
  isLoading: boolean
  referralShortLink: string
}

const CopyButtonContent = styled.div(
  ({theme}) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: ${theme.layout.spacing.s500}px;
  `
)

const CopyButtonText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const PlaceholderText = styled(Text)(
  ({theme}) => css`
    flex-grow: 1;
    text-align: left;
    color: ${theme.colors.content.neutral.c300};
  `
)

const StyledInputButton = styled(InputButton)(
  ({theme}) => css`
    box-shadow: none;
    &:focus {
      box-shadow: none;
      border-color: ${theme.colors.border.neutral.c200};
    }
    &:focus-visible {
      box-shadow: ${theme.shadows.inputInner},
        0px 0px 0px 1px ${theme.colors.border.interactive.c500};
      border-color: ${theme.colors.border.interactive.c200};
    }
  `
)

const useReferralLinkCopyTracking = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const userId = user?.attributes?.sub
  const {referralShortLink} = useContext(ReferralLinkContext)

  return () => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.ReferralLinkCopy,
        action: Action.Clicked,
        payload: {
          userId,
          referralLink: referralShortLink
        },
        team: Team.Retention
      })
    )
  }
}

export const CopyLinkButton = ({isLoading, referralShortLink}: Props) => {
  const toast = useToastController()
  const trackLinkCopyEvent = useReferralLinkCopyTracking()
  const copyToastContent = (
    <FormattedMessageWrapper
      id="referralProgram.linkCopied"
      defaultMessage="Link copied"
    />
  )

  return (
    <StyledInputButton
      id="referral-link"
      onClick={() => {
        copy(referralShortLink)
        toast.open(copyToastContent, {icon: <Icon name="Checkmark" />})
        trackLinkCopyEvent()
      }}
      disabled={isLoading}
    >
      <CopyButtonContent>
        {isLoading ? (
          <PlaceholderText>
            <FormattedMessageWrapper
              id="referralProgram.gettingLink"
              defaultMessage="Getting link..."
            />
          </PlaceholderText>
        ) : (
          <CopyButtonText>{referralShortLink}</CopyButtonText>
        )}

        {isLoading ? <Spinner size={20} /> : <Icon name="Copy" />}
      </CopyButtonContent>
    </StyledInputButton>
  )
}
