// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgFire = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.35 2.086c.246.13.4.385.4.664 0 1.9 1.072 3.012 2.392 4.27l.168.159C15.54 8.346 17 9.73 17 12c0 2.192-.767 3.753-2.123 4.733C13.566 17.68 11.817 18 10 18c-4.38 0-7-3.152-7-6 0-1.326.344-2.58.822-3.594.467-.994 1.104-1.842 1.762-2.28a.75.75 0 0 1 1.166.624c0 1.149.546 2.108 1.264 2.785.166.156.338.295.51.414-.496-1.456-.456-2.897-.152-4.134.412-1.68 1.341-3.09 2.203-3.683a.75.75 0 0 1 .774-.046ZM9.827 6.173c-.343 1.397-.266 3.068.796 4.661a.75.75 0 0 1-.577 1.165c-.498.03-1.041-.111-1.545-.34a5.67 5.67 0 0 1-1.516-1.032 5.59 5.59 0 0 1-1.46-2.226A7.059 7.059 0 0 0 4.5 12c0 1.951 1.88 4.499 5.5 4.499 1.684 0 3.06-.305 3.998-.983.894-.645 1.502-1.71 1.502-3.517 0-1.616-1.016-2.584-2.368-3.871l-.024-.023c-.985-.938-2.11-2.029-2.603-3.624a6.474 6.474 0 0 0-.677 1.69Z"
    />
  </svg>
)

export default SvgFire
