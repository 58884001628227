// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgSearchGradient = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 3.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM2 9a7 7 0 1 1 12.452 4.391l3.328 3.329a.75.75 0 1 1-1.06 1.06l-3.329-3.328A7 7 0 0 1 2 9Z"
      fill="url(#searchGradient_svg__a)"
    />
    <defs>
      <linearGradient
        id="searchGradient_svg__a"
        x1={2}
        y1={10}
        x2={18}
        y2={10}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6700E4" />
        <stop offset={0.081} stopColor="#6701E4" />
        <stop offset={0.155} stopColor="#6602E4" />
        <stop offset={0.225} stopColor="#6505E4" />
        <stop offset={0.29} stopColor="#6308E3" />
        <stop offset={0.353} stopColor="#610CE3" />
        <stop offset={0.412} stopColor="#5E11E2" />
        <stop offset={0.471} stopColor="#5B17E2" />
        <stop offset={0.529} stopColor="#571EE1" />
        <stop offset={0.588} stopColor="#5425E0" />
        <stop offset={0.647} stopColor="#4F2DE0" />
        <stop offset={0.71} stopColor="#4B36DF" />
        <stop offset={0.775} stopColor="#463FDE" />
        <stop offset={0.845} stopColor="#4149DD" />
        <stop offset={0.919} stopColor="#3C53DC" />
        <stop offset={1} stopColor="#365EDB" />
      </linearGradient>
    </defs>
  </svg>
)

export default SvgSearchGradient
