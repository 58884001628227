// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgRoom = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="M15.547 10.283h-5.67a.749.749 0 0 0-.748.745v2.713H3.496v-2.713a.754.754 0 0 0-.748-.745.749.749 0 0 0-.748.745v5.227a.744.744 0 0 0 1.274.524c.14-.14.22-.327.222-.524v-.994h13.008v.994a.754.754 0 0 0 .748.745.76.76 0 0 0 .748-.745v-3.527a2.44 2.44 0 0 0-.718-1.729 2.457 2.457 0 0 0-1.735-.716Zm-4.922 1.49h4.922a.999.999 0 0 1 .997.994v.994h-5.88l-.04-1.987ZM11.233 7.918h1.336v1.203a.744.744 0 0 0 .748.745.74.74 0 0 0 .694-.459.732.732 0 0 0 .054-.286V7.918H15.4a.74.74 0 0 0 .598-.298.744.744 0 0 0 .13-.655l-.997-3.418A.745.745 0 0 0 14.414 3H12.24a.77.77 0 0 0-.718.547l-.997 3.418a.763.763 0 0 0 .12.655.747.747 0 0 0 .588.298Zm1.565-3.428h.998l.568 1.988H12.18l.618-1.988Z" />
    <path d="M6.307 13.214c.595 0 1.166-.235 1.587-.655a2.231 2.231 0 0 0 0-3.161 2.247 2.247 0 0 0-3.173 0 2.232 2.232 0 0 0 0 3.162c.42.419.991.654 1.586.654Zm0-2.98a.75.75 0 0 1 .748.745.744.744 0 0 1-1.274.524.754.754 0 0 1-.221-.524.754.754 0 0 1 .747-.745Z" />
  </svg>
)

export default SvgRoom
