// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgFork = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 2a.75.75 0 0 1 .75.75V5.5c0 1.12.736 2.067 1.75 2.386V2.75a.75.75 0 0 1 1.5 0v5.136A2.501 2.501 0 0 0 12.5 5.5V2.75a.75.75 0 0 1 1.5 0V5.5c0 1.953-1.4 3.579-3.25 3.93v7.82a.75.75 0 0 1-1.5 0V9.43A4.001 4.001 0 0 1 6 5.5V2.75A.75.75 0 0 1 6.75 2Z"
    />
  </svg>
)

export default SvgFork
