// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgChevronLeft = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.906 5.278a.737.737 0 1 0-1.003-1.081L6.236 9.46a.737.737 0 0 0 0 1.08l5.667 5.263a.737.737 0 1 0 1.003-1.08L7.821 10l5.085-4.722Z"
    />
  </svg>
)

export default SvgChevronLeft
