// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgLightCeiling = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2a.75.75 0 0 1 .75.75v3.272c2.526.151 4.32 1.07 5.497 2.392C17.522 9.844 18 11.662 18 13.25a.75.75 0 0 1-.75.75H2.75a.75.75 0 0 1-.75-.75c0-1.588.478-3.406 1.753-4.836 1.177-1.322 2.97-2.24 5.497-2.392V2.75A.75.75 0 0 1 10 2ZM3.543 12.5h12.914c-.127-1.088-.538-2.2-1.33-3.089C14.168 8.335 12.575 7.5 10 7.5s-4.169.835-5.128 1.912c-.791.888-1.202 2-1.329 3.088Zm7.207 3.25a.75.75 0 0 0-1.5 0v1.5a.75.75 0 0 0 1.5 0v-1.5Zm-4.263-.712a.75.75 0 0 1 .475.949l-.5 1.5a.75.75 0 1 1-1.424-.474l.5-1.5a.75.75 0 0 1 .95-.475Zm7.974.475a.75.75 0 1 0-1.422.474l.5 1.5a.75.75 0 1 0 1.422-.474l-.5-1.5Z"
    />
  </svg>
)

export default SvgLightCeiling
