// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgBus = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 4.75A2.75 2.75 0 0 1 5.75 2h8.5A2.75 2.75 0 0 1 17 4.75v1.335a1.5 1.5 0 0 1 0 2.83v5.335A1.75 1.75 0 0 1 15.25 16H15v1.25a.75.75 0 0 1-1.5 0V16h-7v1.25a.75.75 0 0 1-1.5 0V16h-.25A1.75 1.75 0 0 1 3 14.25V8.915a1.5 1.5 0 0 1 0-2.83V4.75ZM5.75 3.5c-.69 0-1.25.56-1.25 1.25V8.5h4.75v-5h-3.5Zm5 0v5h4.75V4.75c0-.69-.56-1.25-1.25-1.25h-3.5ZM9.99 10h5.51v4.25a.25.25 0 0 1-.25.25H4.75a.25.25 0 0 1-.25-.25V10h5.49ZM6 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm7-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
    />
  </svg>
)

export default SvgBus
