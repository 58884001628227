import * as React from 'react'
import {mergeDeepRight} from 'ramda'
import checkoutTheme from 'themes/findhotel'
import {ThemeType} from 'types/Theme'

import {getTheme} from '@daedalus/atlas/themes'
import {BrandThemeName} from '@daedalus/core/src/brand/types'

type MergedThemeType = ThemeType & ReturnType<typeof checkoutTheme>

type GetThemePropsType = {
  children(data: MergedThemeType): React.ReactElement
  themeName?: BrandThemeName
}

const GetTheme = React.memo<GetThemePropsType>(({children, themeName}) => {
  const websiteTheme: ThemeType = getTheme(themeName)
  return children(mergeDeepRight(websiteTheme, checkoutTheme()))
})

GetTheme.displayName = 'GetTheme'

export default GetTheme
