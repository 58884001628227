// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgFace = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.5a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13ZM2 10a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm5-2.75a.25.25 0 1 0 0-.5.25.25 0 0 0 0 .5ZM5.75 7a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm7.25.25a.25.25 0 1 0 0-.5.25.25 0 0 0 0 .5ZM11.75 7a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0ZM10 9.5a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1ZM8 10a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm-1.25 2.75-.576.48.002.002.002.003.006.007.017.02.057.063a5.657 5.657 0 0 0 .95.8c.644.428 1.6.875 2.792.875 1.192 0 2.148-.447 2.791-.876a5.648 5.648 0 0 0 .951-.8 3.04 3.04 0 0 0 .057-.062l.017-.02.006-.007.003-.003v-.001l-.575-.481.576.48a.75.75 0 0 0-1.15-.963m0 0-.004.005a2.984 2.984 0 0 1-.164.168c-.124.117-.31.276-.549.436-.482.321-1.15.624-1.959.624-.808 0-1.477-.303-1.959-.624a4.165 4.165 0 0 1-.684-.572l-.029-.032-.004-.005a.75.75 0 0 0-1.15.964l.576-.481"
    />
  </svg>
)

export default SvgFace
