import React from 'react'
import {useSelector} from 'react-redux'
import useParams from 'components/data/URLParams/useParams'
import {getAnonymousId} from 'modules/meta/selectors'
import {getMyBookingsUrl} from 'utils/urls'

import {TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY} from '@daedalus/core/src/auth/modules/actions'
import {
  getUser,
  selectAccountUrl,
  selectIsAuthenticated,
  selectIsLoading
} from '@daedalus/core/src/auth/modules/selectors'
import {
  AuthenticationFlows,
  ShowPasswordlessLoginPayload,
  SourceComponentType
} from '@daedalus/core/src/auth/types/Auth'
import {useLogIn} from '@daedalus/shared/src/authentication/hooks/useLogIn'
import {useLogOut} from '@daedalus/shared/src/authentication/hooks/useLogOut'
import {HeaderUserWidget} from '@daedalus/shared/src/user/account/HeaderUserWidget'

interface UserWidgetWithStoreProps {
  hideMyBookings?: boolean
  hideManageAccount?: boolean
  referralComponent?: SourceComponentType
}

const UserWidgetWithStore = ({
  hideMyBookings = false,
  hideManageAccount = false,
  referralComponent
}: UserWidgetWithStoreProps) => {
  const accountUrl = useSelector(selectAccountUrl)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const isLoading = useSelector(selectIsLoading)
  const logIn = useLogIn()
  const logOut = useLogOut()
  const user = useSelector(getUser)

  const urlParams = useParams()
  const {locale, userCountry} = urlParams
  const anonymousId = useSelector(getAnonymousId)
  const searchParams = {anonymousId, locale, userCountry}
  const myBookingsUrl = hideMyBookings ? '' : getMyBookingsUrl(searchParams)

  if (isLoading) return null

  const logInWithTracking = ({
    sourceComponent,
    flow
  }: {
    sourceComponent: ShowPasswordlessLoginPayload['sourceComponent']
    flow: AuthenticationFlows
  }) => {
    if (anonymousId) {
      setTimeout(
        () =>
          logIn({
            sourceComponent: referralComponent
              ? referralComponent
              : sourceComponent,
            anonymousId,
            flow
          }),
        TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY
      )
    }
  }

  const handleLogOutClick = () => {
    if (logOut) logOut()
  }

  return (
    <HeaderUserWidget
      isAuthenticated={Boolean(isAuthenticated)}
      user={user}
      logOut={handleLogOutClick}
      accountUrl={hideManageAccount ? '' : accountUrl}
      myBookingsUrl={myBookingsUrl}
      onSignIn={logInWithTracking}
    />
  )
}

export default UserWidgetWithStore
