import React, {useContext, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Optimizely from '@optimizely/optimizely-sdk'
import * as Opticks from 'opticks'
import {ActivateNotificationPayload, ToggleIdType, VariantType} from 'opticks'

import {trackExperimentServed} from '../../../analytics/modules/actions'
import {fetchOptimizelyDataFile} from '../../modules/actions'
import {getDataFile} from '../../modules/selectors'

const OpticksContext = React.createContext<typeof Opticks | null>(null)

export const useOpticks = () => useContext(OpticksContext)

interface OpticksProviderProps {
  children: React.ReactNode
  /** The url where your datafile is stored */
  dataFileUrl: string
  /** Fallback dataFile for local usage (e.g. testing) */
  fallbackDataFile?: Record<string, unknown>
  /** Flag to disable logs from optimizely-sdk (false by default) */
  disableOptimizelyLogs?: boolean
}

/**
 * Returns the experiments and decision for each experiment that Opticks decides
 */
export const experimentVariationsStore = (() => {
  let experiments: Record<ToggleIdType, VariantType> = {}

  const add = (featureKey: ToggleIdType, variation: VariantType) => {
    experiments[featureKey] = variation
  }

  /**
   *
   * @param asArray - if we should return the list of experiments as an array where each key has the format `experiment:variation`
   * @returns a list of experiments on Object or Array format
   */
  const getAll = (asArray = false) => {
    if (asArray) {
      return Object.keys(experiments).map(featureKey => {
        const variation = experiments[featureKey]
        return `${featureKey}:${variation}`
      })
    }

    return experiments
  }

  const clean = () => {
    experiments = {}
  }

  return {
    add,
    getAll,
    clean
  }
})()

const OpticksProvider = ({
  children,
  dataFileUrl,
  fallbackDataFile,
  disableOptimizelyLogs
}: OpticksProviderProps) => {
  const dataFile = useSelector(getDataFile) ?? fallbackDataFile
  const dispatch = useDispatch()

  useEffect(() => {
    if (dataFileUrl) {
      dispatch(fetchOptimizelyDataFile({dataFileUrl}))
    }
  }, [dataFileUrl])

  if (dataFile) {
    if (disableOptimizelyLogs) {
      Optimizely.setLogger(null)
    }

    Opticks.registerLibrary(Optimizely)
    Opticks.initialize(dataFile, (trackObject: ActivateNotificationPayload) => {
      // FIXME: This should be abstracted from Opticks itself as it references internals from Optimizely

      /**
       * Starting from Optimizely SDK v4.5, it is no longer possible to distinguish
       * between MVT (Multi-Variant or A/B/C/...) experiments and Rollouts (now called
       * Target Deliveries) using the `trackObject` properties.
       *
       * The `variationKey` value is now defined in Optimizely's dashboard. This change
       * simplifies the code by centralizing the definitions of MVT and Target Delivery
       * conventions within the dashboard itself.
       */
      const {flagKey, variationKey} = trackObject.decisionInfo
      const experimentId: ToggleIdType = flagKey

      // Making this call asynchronous to decouple it from the Redux update cycle,
      // preventing endless recursion when called from selectors.
      // The action will cause a store update -> selector -> toggle -> action -> store update -> etc.
      if (Opticks.isUserInRolloutAudience(experimentId)) {
        setTimeout(
          () => dispatch(trackExperimentServed(experimentId, variationKey)),
          0
        )
        experimentVariationsStore.add(experimentId, variationKey)
      }
    })
  }

  return (
    <OpticksContext.Provider value={dataFile ? Opticks : null}>
      {children}
    </OpticksContext.Provider>
  )
}

export const getRawOpticks = () => Opticks

export default OpticksProvider
