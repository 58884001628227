import Settings from '../Settings'

const bofhDefaultHeaders = {
  Accept: 'application/json',
  'X-Client-User-Agent': `checkout/${Settings.get('REACT_APP_VERSION')}`
}

export default bofhDefaultHeaders as {
  Accept: 'application/json'
  'X-Client-User-Agent': string
  'x-auth-token'?: string
}
