import {ErrorDetails} from '@daedalus/core/src/booking/types/ErrorDetails'

import {DealFreezeEndpoints} from '../../services/dealFreezeApi'

export enum PayloadErrorType {
  GlobalLimitReached = 'global_limit_reached',
  UserLimitReached = 'user_limit_reached',
  PriceMismatch = 'price_mismatch'
}

export enum ErrorCases {
  SoldOut = 'sold_out',
  Unavailability = 'unavailability',
  LimitReached = 'limit_reached',
  PriceMismatch = 'price_mismatch',
  UnexpectedError = 'unexpected_error'
}

export enum DealFreezeErrorSource {
  ConfirmationOverlay = 'confirmation_overlay',
  FreezeOverlay = 'freeze_overlay'
}

export type DealFreezeError = {
  errored: boolean
  message?: string
  payloadErrorType?: string | PayloadErrorType
  endpoint?: DealFreezeEndpoints | string
  errorDetails?: ErrorDetails | unknown
  component?: DealFreezeErrorSource | string
}
