import React, {ElementType, ReactNode} from 'react'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'
import {ColorPath} from 'types/Theme'

import {cssTheme} from '../../../themes'
import {getCssColorByPath} from '../../../utils/colors'
import {getLinariaClassName} from '../../../utils/getLinariaClassName'

export type TitleVariant =
  | 'titleXS'
  | 'titleS'
  | 'titleM'
  | 'titleL'
  | 'titleXL'
  | 'title2XL'
  | 'title3XL'
  | 'title4XL'
  | 'title5XL'
  | 'title6XL'

export type BodyVariant = 'bodyXS' | 'bodyS' | 'bodyM'

export type LabelVariant = 'labelXS' | 'labelS' | 'labelM'

export type TextVariant = BodyVariant | LabelVariant | TitleVariant

export type TextAlign =
  | 'center'
  | 'end'
  | 'start'
  | 'justify'
  | 'left'
  | 'right'

interface TextProps {
  /** HTML Element that text will be rendered */
  /** @deprecated Linaria doesn't support `as` prop usage for the styled component. Use `tag` prop instead*/
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: ElementType<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tag?: ElementType<any>
  /** Specify the color path from the theme's colors file to explicitly set the Text's color */
  colorPath?: ColorPath
  /** The text variant style from the theme */
  variant?: TextVariant
  /** HTML class to be included in the tag */
  className?: string
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** Text to be displayed */
  children: ReactNode
  /** text-align style */
  align?: TextAlign
  id?: string
  style?: React.CSSProperties
}

interface StyleProps extends Pick<TextProps, 'align'> {
  colorValue?: string
}

/** We don't want to change Text component interface for developers, so we should still accept all possible values, but convert them into the proper ones */
const textAlignMap = {
  center: 'center',
  end: 'end',
  start: 'start',
  justify: 'justify',
  left: 'start',
  right: 'end'
}

const TextElement = styled.p<StyleProps>`
  &--typography-bodyXS {
    ${cssTheme.typography.text.bodyXS}
  }
  &--typography-bodyS {
    ${cssTheme.typography.text.bodyS}
  }
  &--typography-bodyM {
    ${cssTheme.typography.text.bodyM}
  }
  &--typography-labelXS {
    ${cssTheme.typography.text.labelXS}
  }
  &--typography-labelS {
    ${cssTheme.typography.text.labelS}
  }
  &--typography-labelM {
    ${cssTheme.typography.text.labelM}
  }
  &--typography-titleXS {
    ${cssTheme.typography.text.titleXS}
  }
  &--typography-titleS {
    ${cssTheme.typography.text.titleS}
  }
  &--typography-titleM {
    ${cssTheme.typography.text.titleM}
  }
  &--typography-titleL {
    ${cssTheme.typography.text.titleL}
  }
  &--typography-titleXL {
    ${cssTheme.typography.text.titleXL}
  }
  &--typography-title2XL {
    ${cssTheme.typography.text.title2XL}
  }
  &--typography-title3XL {
    ${cssTheme.typography.text.title3XL}
  }
  &--typography-title4XL {
    ${cssTheme.typography.text.title4XL}
  }
  &--typography-title5XL {
    ${cssTheme.typography.text.title5XL}
  }
  &--typography-title6XL {
    ${cssTheme.typography.text.title6XL}
  }

  text-align: ${({align}) => (align ? textAlignMap[align] : 'unset')};
  margin: 0;
  color: ${({colorValue}) => colorValue || 'initial'};
`

const getTagName = (variant: TextVariant) => {
  switch (variant) {
    case 'titleXS':
      return 'h6'
    case 'titleS':
      return 'h5'
    case 'titleM':
      return 'h4'
    case 'titleL':
      return 'h3'
    case 'titleXL':
      return 'h2'
    case 'title2XL':
    case 'title3XL':
    case 'title4XL':
    case 'title5XL':
    case 'title6XL':
      return 'h1'

    default:
      return 'p'
  }
}

export const Text = ({
  as,
  tag,
  colorPath,
  variant = 'bodyM',
  className,
  dataId,
  align,
  children,
  id,
  style
}: TextProps) => {
  const colorValue = colorPath && getCssColorByPath(colorPath)
  const tagName = tag || as || getTagName(variant)

  return (
    <TextElement
      className={cx(
        className,
        `${getLinariaClassName(TextElement)}--typography-${variant}`
      )}
      as={tagName}
      colorValue={colorValue}
      data-id={dataId}
      align={align}
      id={id}
      style={style}
    >
      {children}
    </TextElement>
  )
}
