// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgArrowUpRight = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 4a.75.75 0 0 0 0 1.5h6.69l-9.22 9.22a.75.75 0 0 0 1.06 1.06l9.22-9.22v6.69a.75.75 0 1 0 1.5 0v-8.5a.75.75 0 0 0-.75-.75h-8.5Z"
    />
  </svg>
)

export default SvgArrowUpRight
