import React, {ButtonHTMLAttributes, ReactNode} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Icon} from '@daedalus/atlas/src/components/designSystem/Icon'

import {IconWrapper} from '../commonStyles'

const rowBase = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border: none;
  background: transparent;
  padding: 0;
  .inline-icon {
    display: inline;
    svg {
      vertical-align: bottom;
    }
  }
`

const StyledRow = styled.div`
  ${rowBase}
`

const StyledButton = styled.button(
  ({theme}) => css`
    ${rowBase};
    position: relative;
    text-decoration: none;
    text-align: left;
    color: ${theme.colors.content.neutral.c950};

    &:hover,
    &:focus {
      text-decoration: none;
      color: ${theme.colors.content.neutral.c950};
    }
  `
)

export const ArrowButton = ({
  children,
  ...restButtonProps
}: Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'style'>) => (
  <StyledButton {...restButtonProps}>
    {children}
    <IconWrapper>
      <Icon name="ChevronRight" />
    </IconWrapper>
  </StyledButton>
)

export const ArrowRow = ({children}: {children: ReactNode}) => (
  <StyledRow>
    {children}
    <IconWrapper>
      <Icon name="ChevronRight" />
    </IconWrapper>
  </StyledRow>
)
