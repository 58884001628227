// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgLink = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.47 3.47a4.993 4.993 0 0 1 7.06 7.06l-1.25 1.25a.75.75 0 0 1-1.06-1.06l1.25-1.25a3.493 3.493 0 1 0-4.94-4.94L9.28 5.78a.75.75 0 0 1-1.06-1.06l1.25-1.25ZM5.78 8.22a.75.75 0 0 1 0 1.06l-1.25 1.25a3.493 3.493 0 1 0 4.94 4.94l1.25-1.25a.75.75 0 1 1 1.06 1.06l-1.25 1.25a4.993 4.993 0 0 1-7.06-7.06l1.25-1.25a.75.75 0 0 1 1.06 0Zm7 .06a.75.75 0 0 0-1.06-1.06l-4.5 4.5a.75.75 0 1 0 1.06 1.06l4.5-4.5Z"
    />
  </svg>
)

export default SvgLink
