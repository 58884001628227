// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgBellOff = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m16 5.06 1.78-1.78a.75.75 0 1 0-1.06-1.06l-14.5 14.5a.75.75 0 1 0 1.06 1.06L6.06 15h.206a3.467 3.467 0 0 0 .466 1.386C7.247 17.245 8.246 18 10 18c1.754 0 2.753-.755 3.268-1.614A3.468 3.468 0 0 0 13.734 15h3.516a.75.75 0 0 0 .679-1.07L16 9.833V5.061Zm-1.5 1.5L7.56 13.5h8.508l-1.497-3.18a.75.75 0 0 1-.07-.32V6.56ZM10 3.5A4.5 4.5 0 0 0 5.5 8v2a.75.75 0 0 1-.071.32l-1 2.124a.75.75 0 1 1-1.358-.638l.93-1.974V8a6 6 0 0 1 6-6v.75V2H10.026l.061.001a10.157 10.157 0 0 1 .93.067c.547.065 1.303.202 1.916.513a.75.75 0 1 1-.679 1.338c-.37-.189-.912-.302-1.415-.362a8.656 8.656 0 0 0-.784-.056h-.044L10 3.5ZM12.216 15H7.784c.035.174.104.397.234.614.235.391.736.886 1.982.886s1.747-.495 1.982-.886c.13-.217.199-.44.234-.614Z"
    />
  </svg>
)

export default SvgBellOff
