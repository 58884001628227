// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCloud = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 5.5a3.5 3.5 0 0 0-3.494 3.3.75.75 0 0 1-.694.707A2.5 2.5 0 0 0 6 14.5h8a2.5 2.5 0 0 0 .188-4.993.75.75 0 0 1-.694-.706A3.5 3.5 0 0 0 10 5.5ZM5.08 8.107a5.001 5.001 0 0 1 9.84 0A4.001 4.001 0 0 1 14 16H6a4 4 0 0 1-.92-7.893Z"
    />
  </svg>
)

export default SvgCloud
