import * as React from 'react'

export const CookieIcon = () => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="37.358%" y1="3.26%" x2="82.969%" y2="91.466%" id="a">
        <stop stopColor="#FFDFAA" offset="0%" />
        <stop stopColor="#F6B550" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="37.358%"
        y1="14.215%"
        x2="82.969%"
        y2="81.747%"
        id="b"
      >
        <stop stopColor="#FCC773" offset="0%" />
        <stop stopColor="#EEA83B" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="2.414%" x2="87.969%" y2="83.736%" id="c">
        <stop stopColor="#C4965A" offset="0%" />
        <stop stopColor="#925C2E" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="2.41%" x2="87.969%" y2="83.739%" id="d">
        <stop stopColor="#C4965A" offset="0%" />
        <stop stopColor="#925C2E" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="2.405%" x2="87.969%" y2="83.742%" id="e">
        <stop stopColor="#C4965A" offset="0%" />
        <stop stopColor="#925C2E" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="2.413%" x2="87.969%" y2="83.736%" id="f">
        <stop stopColor="#C4965A" offset="0%" />
        <stop stopColor="#925C2E" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="2.41%" x2="87.969%" y2="83.739%" id="g">
        <stop stopColor="#C4965A" offset="0%" />
        <stop stopColor="#925C2E" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="2.41%" x2="87.969%" y2="83.739%" id="h">
        <stop stopColor="#C4965A" offset="0%" />
        <stop stopColor="#925C2E" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="0%" x2="87.969%" y2="85.447%" id="i">
        <stop stopColor="#C4965A" offset="0%" />
        <stop stopColor="#925C2E" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M22.792 18.08c-1.723 3.637-4.299 6.17-9.214 5.9l-.487-.033-.478-.021-1.09-.02-.32-.012-.326-.018-.339-.03-.353-.042c-1.204-.167-2.697-.603-4.97-1.785-4.43-2.307-5.432-5.56-5.179-8.975C.29 9.63 3.196 2.114 8.233.56 11.485-.443 13.574.085 14.7.717l.206.123.182.123.16.122.135.117.113.11.09.102.069.091.046.077a.415.415 0 0 1 .015.033c.06.145-.084.278-.316.444l-.397.28c-.543.393-1.186.984-1.164 2.07.025 1.187.772 1.7 1.419 2.004l.223.1.388.164c.14.063.245.122.295.189.062.082.014.24-.085.453l-.33.655-.06.127-.118.266c-.3.728-.462 1.598.226 2.352.754.828 1.817.857 2.678.756l.295-.04.735-.118c.198-.027.34-.03.406.02.084.063.117.19.139.355l.042.372c.063.475.22 1.063 1.002 1.436.782.374 1.411.047 1.863-.285l.414-.325c.115-.088.206-.145.272-.139.071.007.157.092.226.263l.044.126.036.152c.017.082.03.175.039.277l.011.218c.018.85-.238 2.248-1.207 4.292z"
        fill="url(#a)"
      />
      <path
        d="M22.792 17.798c-.006.013-.01.008-.011-.013v-.106l.07-1.086.018-.366.016-.56-.001-.362c-.013-.584-.082-1.06-.27-1.13-.096-.036-.247-.023-.437.016l-.245.057-.574.147c-.773.187-1.711.33-2.365-.249-.527-.465-.532-.96-.446-1.322l.045-.158.107-.29c.037-.107.02-.133-.148-.04-.593.328-4.354.314-5.151-1.743-.374-.965-.038-1.85.328-2.511l.147-.252.317-.51c.147-.252.19-.397-.016-.405l-.079.003c-.94.082-2.634-1.099-2.224-3.097.232-1.132.937-1.738 1.444-2.062l.17-.104.346-.194c.05-.033.057-.053.002-.064-3.255-.667-6.062.64-7.902 1.94l-.334.244-.31.24-.146.118-.274.23a13.43 13.43 0 0 0-.127.112l-.235.214-.304.293-.24.25-.126.138-.16.195C1.313 8.558.612 11.43.626 15.17c.008 2 1.087 3.595 2.033 4.61l.243.252c.08.08.157.154.232.225l.217.196.197.169.371.293.117.084c-3.433-2.208-4.23-5.119-4-8.158C.288 9.48 3.196 2.08 8.232.552c3.252-.988 5.341-.468 6.467.154l.206.12.182.122.16.12.135.115.113.11.09.1.069.089.046.075a.406.406 0 0 1 .015.033c.06.143-.084.274-.316.437l-.397.275c-.543.388-1.186.969-1.164 2.038.025 1.169.772 1.674 1.419 1.974l.223.097.388.163c.14.061.245.12.295.185.062.08.014.236-.085.446l-.33.645-.06.125-.118.262c-.3.716-.462 1.573.226 2.316.754.815 1.817.843 2.678.744l.295-.04.735-.116c.198-.026.34-.03.406.019.084.062.117.187.139.35l.042.366c.063.468.22 1.047 1.002 1.414.782.368 1.411.046 1.863-.28l.414-.32c.115-.087.206-.143.272-.137.071.007.157.09.226.258l.044.124.036.15c.017.081.03.172.039.273l.011.215c.018.836-.238 2.213-1.207 4.225z"
        fill="url(#b)"
      />
      <path
        d="M20.053 4.712c-.008.725-.617 1.319-1.36 1.327-.743.008-1.339-.573-1.33-1.298.007-.725.616-1.319 1.36-1.327.743-.008 1.338.573 1.33 1.298z"
        fill="url(#c)"
        transform="rotate(111 18.708 4.727)"
      />
      <path
        d="M21.278 9.717a.572.572 0 0 1-.57.556.542.542 0 0 1-.556-.544.572.572 0 0 1 .57-.556c.31-.003.56.24.556.544z"
        fill="url(#d)"
        transform="rotate(111 20.715 9.723)"
      />
      <path
        d="M17.6 19.457c-.008.618-.526 1.124-1.159 1.13a1.102 1.102 0 0 1-1.133-1.104c.007-.618.526-1.124 1.158-1.13a1.102 1.102 0 0 1 1.133 1.104z"
        fill="url(#e)"
        transform="rotate(111 16.454 19.47)"
      />
      <path
        d="M9.324 17.864c-.01.789-.672 1.436-1.481 1.445-.809.008-1.458-.624-1.449-1.413.01-.79.672-1.436 1.481-1.445.809-.01 1.458.623 1.449 1.413z"
        fill="url(#f)"
        transform="rotate(111 7.86 17.88)"
      />
      <path
        d="M8.58 8.007c-.01.85-.724 1.546-1.595 1.556-.871.01-1.57-.672-1.56-1.522.01-.85.723-1.546 1.594-1.556.872-.01 1.57.672 1.56 1.522z"
        fill="url(#g)"
        transform="rotate(111 7.002 8.024)"
      />
      <path
        d="M3.232 14.69a.55.55 0 0 1-.55.536.522.522 0 0 1-.536-.524.55.55 0 0 1 .549-.535c.3-.003.54.231.537.524z"
        fill="url(#h)"
        transform="rotate(111 2.689 14.696)"
      />
      <path
        d="M14.087 17.641a.447.447 0 0 1-.446.435.424.424 0 0 1-.436-.425.447.447 0 0 1 .446-.435.424.424 0 0 1 .436.425z"
        fill="url(#i)"
        transform="rotate(111 13.646 17.646)"
      />
    </g>
  </svg>
)
