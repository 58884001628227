// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPeople = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM7 2a3 3 0 0 0-1.992 5.244c-.834.236-1.497.647-1.99 1.25C2.213 9.485 2 10.832 2 12.25c0 .414.336.75.75.75H4v2.25A2.75 2.75 0 0 0 6.75 18h.5A2.75 2.75 0 0 0 10 15.25V13h1.25a.75.75 0 0 0 .75-.75c0-1.42-.212-2.766-1.019-3.755-.492-.604-1.155-1.015-1.99-1.251A3 3 0 0 0 7 2Zm5.75 0a.75.75 0 0 0 0 1.5c.426 0 .88.177 1.225.476.343.297.525.666.525 1.024s-.182.727-.525 1.024a1.925 1.925 0 0 1-1.225.476.75.75 0 0 0 0 1.5c1.52 0 2.402.492 2.931 1.177.445.576.702 1.367.787 2.323H15.25a.75.75 0 0 0-.75.75v3c0 .69-.56 1.25-1.25 1.25h-.5a.75.75 0 0 0 0 1.5h.5A2.75 2.75 0 0 0 16 15.25V13h1.25a.75.75 0 0 0 .75-.75c0-1.455-.288-2.898-1.131-3.99a4.228 4.228 0 0 0-1.694-1.308C15.659 6.448 16 5.773 16 5c0-.884-.447-1.64-1.043-2.158A3.423 3.423 0 0 0 12.75 2Zm-9.224 9.5H4.75a.75.75 0 0 1 .75.75v3c0 .69.56 1.25 1.25 1.25h.5c.69 0 1.25-.56 1.25-1.25v-3a.75.75 0 0 1 .75-.75h1.224c-.07-.93-.281-1.599-.655-2.057C9.379 8.904 8.58 8.5 7 8.5c-1.58 0-2.38.404-2.819.943-.374.458-.585 1.127-.655 2.057Z"
    />
  </svg>
)

export default SvgPeople
