// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgTrash2 = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.75 3.5c-.69 0-1.25.56-1.25 1.25V5h4v-.25c0-.69-.56-1.25-1.25-1.25h-1.5ZM13 5v-.25A2.75 2.75 0 0 0 10.25 2h-1.5A2.75 2.75 0 0 0 6 4.75V5H2.75a.75.75 0 0 0 0 1.5h.312l.782 8.988A2.75 2.75 0 0 0 6.584 18h5.832a2.75 2.75 0 0 0 2.74-2.512l.782-8.988h.312a.75.75 0 0 0 0-1.5H13Zm1.432 1.5H4.568l.77 8.858A1.25 1.25 0 0 0 6.584 16.5h5.832a1.25 1.25 0 0 0 1.246-1.142l.77-8.858ZM7.75 8a.75.75 0 0 1 .75.75v5.5a.75.75 0 0 1-1.5 0v-5.5A.75.75 0 0 1 7.75 8Zm4.25.75a.75.75 0 1 0-1.5 0v5.5a.75.75 0 0 0 1.5 0v-5.5Z"
    />
  </svg>
)

export default SvgTrash2
