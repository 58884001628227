import React from 'react'
import {styled} from '@linaria/react'
import {ColorPath} from 'types/Theme'

import {cssTheme} from '../../../themes'
import {getCssColorByPath} from '../../../utils/colors'

interface Props {
  /** Specify the color path from the theme's colors file to explicitly set the Spinner's color */
  colorPath?: ColorPath
  size?: number
  /** Pass through classname to allow styles overrides */
  className?: string
}

interface StyleProps {
  color?: string
  size: number
}

const SpinnerWrapper = styled.div<StyleProps>`
  height: ${({size}) => size}px;
  width: ${({size}) => size}px;
  margin: 0 auto;
  position: relative;
`

export const SpinnerElement = styled.div<StyleProps>`
  position: relative;
  margin: 0;
  width: ${({size}) => size}px !important;
  height: ${({size}) => size}px !important;
  transform: ${({
    size
  }) => `translate(-${size / 2}px, -${size / 2}px) scale(${size * 0.005})
    translate(${size / 2}px, ${size / 2}px)`};

  /* Fix unintended shift left from RTL changes */
  [dir='rtl'] & {
    right: ${({size}) => size}px;
  }

  > div > div {
    background: ${({color}) => color || cssTheme.colors.background.brand.c400};
  }
`

export const AnimatedCircle = styled.div`
  @keyframes circle {
    0% {
      opacity: 1;
      transform: scale(4, 4);
    }
    100% {
      opacity: 0;
      transform: scale(1, 1);
    }
  }

  > div {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    animation: circle 1s linear infinite;
  }

  :nth-of-type(1) > div {
    left: 168px;
    top: 94px;
    animation-delay: -0.875s;
  }
  :nth-of-type(1) {
    transform-origin: 174px 100px;
  }
  :nth-of-type(2) > div {
    left: 146.325901794px;
    top: 146.325901794px;
    animation-delay: -0.75s;
  }
  :nth-of-type(2) {
    transform-origin: 152.325901794px 152.325901794px;
  }
  :nth-of-type(3) > div {
    left: 94px;
    top: 168px;
    animation-delay: -0.625s;
  }
  :nth-of-type(3) {
    transform-origin: 100px 174px;
  }
  :nth-of-type(4) > div {
    left: 41.674098206px;
    top: 146.325901794px;
    animation-delay: -0.5s;
  }
  :nth-of-type(4) {
    transform-origin: 47.674098206px 152.325901794px;
  }
  :nth-of-type(5) > div {
    left: 20px;
    top: 94px;
    animation-delay: -0.375s;
  }
  :nth-of-type(5) {
    transform-origin: 26px 100px;
  }
  :nth-of-type(6) > div {
    left: 41.674098206px;
    top: 41.674098206px;
    animation-delay: -0.25s;
  }
  :nth-of-type(6) {
    transform-origin: 47.674098206px 47.674098206px;
  }
  :nth-of-type(7) > div {
    left: 94px;
    top: 20px;
    animation-delay: -0.125s;
  }
  :nth-of-type(7) {
    transform-origin: 100px 26px;
  }
  :nth-of-type(8) > div {
    left: 146.325901794px;
    top: 41.674098206px;
    animation-delay: 0s;
  }
  :nth-of-type(8) {
    transform-origin: 152.325901794px 47.674098206px;
  }
`

export const Spinner = ({colorPath, size = 24, className}: Props) => {
  const color = colorPath && getCssColorByPath(colorPath)

  return (
    <SpinnerWrapper size={size} className={className}>
      <SpinnerElement color={color} size={size}>
        {[...Array(8).keys()].map((value: number) => (
          <AnimatedCircle key={value}>
            <div />
          </AnimatedCircle>
        ))}
      </SpinnerElement>
    </SpinnerWrapper>
  )
}
