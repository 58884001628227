// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgHelp = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 10c0-1.525.525-2.927 1.404-4.035l1.787 1.787A3.981 3.981 0 0 0 6 10c0 .834.255 1.608.691 2.248l-1.787 1.787A6.472 6.472 0 0 1 3.5 10Zm4.252-3.309L5.965 4.904A6.472 6.472 0 0 1 10 3.5c1.525 0 2.927.525 4.035 1.404l-1.787 1.787A3.981 3.981 0 0 0 10 6.001c-.834 0-1.607.254-2.248.69Zm5.557 1.06c.436.642.69 1.416.69 2.25 0 .833-.254 1.607-.69 2.247l1.787 1.787A6.473 6.473 0 0 0 16.5 10a6.473 6.473 0 0 0-1.404-4.035l-1.787 1.787Zm-1.06 5.558a3.982 3.982 0 0 1-2.25.691 3.981 3.981 0 0 1-2.247-.691l-1.787 1.787A6.473 6.473 0 0 0 10 16.5a6.473 6.473 0 0 0 4.035-1.404l-1.787-1.787ZM10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm-2.5 8a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Z"
    />
  </svg>
)

export default SvgHelp
