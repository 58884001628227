import {
  InvalidConfigError,
  MessageFormatError,
  MissingDataError,
  MissingTranslationError,
  UnsupportedFormatterError
} from '@formatjs/intl'
import {FormatError} from 'intl-messageformat'

type IntlErrors =
  | MissingTranslationError
  | MessageFormatError
  | MissingDataError
  | InvalidConfigError
  | UnsupportedFormatterError
  | FormatError

export interface LogPayload {
  errorName: string
  errorMessage: string
  translationId?: string
  locale?: string
}

export const getIsMissingTranslationOrMessageFormatError = (
  error: any
): error is MissingTranslationError | MessageFormatError =>
  Boolean(error?.descriptor?.id && error?.descriptor?.defaultMessage)

export const getTranslationErrorLogger =
  (locale: string, translationErrorLogger: (logData: LogPayload) => void) =>
  (error: IntlErrors) => {
    if (
      process.env.NODE_ENV === 'production' &&
      getIsMissingTranslationOrMessageFormatError(error)
    ) {
      const payload: LogPayload = {
        errorName: error.name,
        errorMessage: error.message,
        translationId: error.descriptor?.id,
        locale
      }

      try {
        translationErrorLogger(payload)
      } catch (error) {
        console.error(error)
      }
    } else if (process.env.NODE_ENV !== 'production') {
      console.error(error)
    }
  }
