import React from 'react'
import {defineMessages, useIntl} from 'react-intl'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {toggle} from 'opticks'

import {Button, Variant} from '@daedalus/atlas/Button'
import {Grid} from '@daedalus/atlas/helpers/Grid'
import {Icon} from '@daedalus/atlas/Icon'
import {InputButton} from '@daedalus/atlas/InputButton'
import {mq} from '@daedalus/atlas/utils/breakpoints'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {dateStringToMiddayDate} from '@daedalus/core/src/utils/date'

import {datePickerMessages} from '../DatePicker/helpers/datePickerMessages'
import {guestPickerMessages} from '../GuestPicker/guestPickerMessages'
import {SearchFormDestinationInput} from './SearchFormDestinationInput'
import {
  DIVIDER_WIDTH,
  formButtonActiveStyles,
  formButtonBaseStyles,
  formButtonErrorStyles,
  IconWrapper
} from './styles'

interface BaseProps {
  /** Check in date */
  checkIn: string
  /** Check out date */
  checkOut: string
  /** Rooms configuration */
  numberOfAdults: number
  /** Rooms configuration */
  numberOfChildren: number
  /** Rooms configuration */
  numberOfRooms: number
  /** Callback for check-in date picker click */
  onCheckInDatePickerClick: () => void
  /** Callback for check-out date picker click */
  onCheckOutDatePickerClick: () => void
  /** Callback for Guest picker click */
  onGuestPickerClick: () => void
  /** Callback for Submit click */
  onSubmit: () => void
  /** Whether the check in picker popover is currently open */
  isCheckInPickerOpen: boolean
  /** Whether the check out picker popover is currently open */
  isCheckOutPickerOpen: boolean
  /** Whether the guest picker popover is currently open */
  isGuestPickerOpen: boolean
  /** A ref to the guest picker container */
  guestPickerRef: React.RefObject<HTMLDivElement>
  /** Whether the rooms count and label is shown */
  isRoomsLabelShown?: boolean
}

interface PropsWithDestination extends BaseProps {
  /** Destination name displayed in the destination button */
  destinationButtonDisplayValue: string
  /** Destination name displayed in the destination input */
  destinationInputDisplayValue: string
  /** Callback for Destination picker click */
  onDestinationPickerClick: (
    event: React.SyntheticEvent<Element, Event>
  ) => void
  /** A ref to the destination input container */
  destinationInputRef: React.RefObject<HTMLDivElement>
  /** Whether the destination picker popover is currently open */
  isDestinationPickerOpen: boolean
  /** Callback for suggestions requested */
  onSuggestionsRequested: (
    value: string,
    shouldLoadUserSearchHistory: boolean
  ) => void
  /** A flag to see if the destination input has error */
  destinationHasError?: boolean
}

interface PropsWithoutDestination extends BaseProps {
  destinationButtonDisplayValue?: never
  destinationInputDisplayValue?: never
  onDestinationPickerClick?: never
  destinationInputRef?: never
  isDestinationPickerOpen?: never
  onSuggestionsRequested?: never
  destinationHasError?: never
}

type Props = PropsWithDestination | PropsWithoutDestination

const FormContainer = styled.div(
  () => css`
    width: 100%;
    display: flex;
  `
)

const SearchFormGrid = styled(Grid)(
  ({theme}) => css`
    align-self: flex-start;
    border: solid 1px ${theme.colors.border.neutral.c200};
    border-radius: ${theme.layout.radius.rounded}px;
    padding: 3px;
    min-width: 0;
    ${mq.desktopMd(css`
      width: 800px;
    `)}
  `
)

const DestinationWrapper = styled(Grid)`
  flex: 4 1 190px !important;
  min-width: 0;
  position: relative;
`

const DateWrapper = styled(Grid)`
  flex: 1 1 124px !important;
  position: relative;
`

const GuestWrapper = styled(Grid)`
  flex: 1 1 182px !important;
  position: relative;
`

const Divider = styled('div')(
  ({theme}) => css`
    width: ${DIVIDER_WIDTH}px;
    align-self: center;
    background: ${theme.colors.background.neutral.c200};
    height: ${theme.layout.spacing.s600}px;
  `
)

const FormButton = styled(InputButton)<{
  isActive?: boolean
  isFirstInput?: boolean
  hasError?: boolean
}>(
  ({isActive, isFirstInput = false, theme}) =>
    isActive && formButtonActiveStyles({isFirstInput, theme}),
  ({isFirstInput, theme, hasError}) =>
    (hasError && formButtonErrorStyles({theme, isFirstInput})) ||
    formButtonBaseStyles({isFirstInput: Boolean(isFirstInput), theme})
)

const SearchButtonWrapper = styled.div(css`
  margin-left: 1px;
`)

const ButtonContent = styled.div(
  () => css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
)

export const destinationPickerMessages = defineMessages({
  destinationPlaceholder: {
    id: 'whereAreYouGoing',
    defaultMessage: 'Where are you going?'
  }
})

export const SearchFormHorizontal = React.memo(function SearchFormHorizontal({
  checkIn,
  checkOut,
  destinationButtonDisplayValue,
  destinationInputDisplayValue,
  numberOfAdults,
  numberOfChildren,
  numberOfRooms,
  onDestinationPickerClick,
  onCheckInDatePickerClick,
  onCheckOutDatePickerClick,
  onGuestPickerClick,
  onSubmit,
  isDestinationPickerOpen,
  isCheckInPickerOpen,
  isCheckOutPickerOpen,
  isGuestPickerOpen,
  destinationInputRef,
  guestPickerRef,
  onSuggestionsRequested,
  destinationHasError = false,
  isRoomsLabelShown = true
}: Props) {
  const {formatDate, formatMessage} = useIntl()

  const showDestination = Boolean(onDestinationPickerClick)
  const numberOfGuests = numberOfAdults + numberOfChildren

  const guestMessage = formatMessage(guestPickerMessages.guest, {
    count: numberOfGuests
  })

  const roomsMessage = formatMessage(guestPickerMessages.room, {
    count: numberOfRooms
  })

  const checkInDate =
    checkIn &&
    formatDate(dateStringToMiddayDate(checkIn), {
      day: '2-digit',
      month: 'short'
    })

  const checkOutDate =
    checkOut &&
    formatDate(dateStringToMiddayDate(checkOut), {
      day: '2-digit',
      month: 'short'
    })

  return (
    <FormContainer className="search-form-horizontal-container">
      <SearchFormGrid
        container
        spacing="s000"
        wrap="nowrap"
        className="search-form-horizontal-grid"
      >
        {showDestination && (
          <>
            <DestinationWrapper>
              <FormButton
                size="md"
                id="DestinationButton"
                dataId="DestinationButton"
                isActive={isDestinationPickerOpen}
                onClick={onDestinationPickerClick}
                icon={
                  <IconWrapper>
                    <Icon name="Pin" />
                  </IconWrapper>
                }
                isRounded
                placeholder={formatMessage(
                  destinationPickerMessages.destinationPlaceholder
                )}
                isFirstInput={showDestination}
                hasError={destinationHasError}
              >
                {destinationButtonDisplayValue && (
                  <ButtonContent data-id="DestinationValue">
                    {destinationButtonDisplayValue}
                  </ButtonContent>
                )}
              </FormButton>
              {isDestinationPickerOpen && (
                <div ref={destinationInputRef}>
                  <SearchFormDestinationInput
                    focusOnMount
                    shouldLoadUserSearchHistory
                    destination={destinationInputDisplayValue}
                    onSuggestionsRequested={onSuggestionsRequested}
                    isDestinationPickerOpen={isDestinationPickerOpen}
                  />
                </div>
              )}
            </DestinationWrapper>
            <Divider />
          </>
        )}
        <DateWrapper>
          <FormButton
            size="md"
            id="CheckInInput"
            dataId="CheckInInput"
            isActive={isCheckInPickerOpen}
            onClick={onCheckInDatePickerClick}
            className="chromatic-ignore"
            icon={
              <IconWrapper>
                <Icon name="CalendarStart" />
              </IconWrapper>
            }
            isRounded
            placeholder={formatMessage(datePickerMessages.checkInDate)}
            isFirstInput={!showDestination}
          >
            {checkInDate && <ButtonContent>{checkInDate}</ButtonContent>}
          </FormButton>
        </DateWrapper>
        <Divider />
        <DateWrapper>
          <FormButton
            size="md"
            id="CheckOutInput"
            dataId="CheckOutInput"
            isActive={isCheckOutPickerOpen}
            onClick={onCheckOutDatePickerClick}
            className="chromatic-ignore"
            icon={
              <IconWrapper>
                <Icon name="CalendarEnd" />
              </IconWrapper>
            }
            isRounded
            placeholder={formatMessage(datePickerMessages.checkOutDate)}
          >
            {checkOutDate && <ButtonContent>{checkOutDate}</ButtonContent>}
          </FormButton>
        </DateWrapper>
        <Divider />
        <GuestWrapper>
          <div ref={guestPickerRef}>
            <FormButton
              size="md"
              id="GuestInput"
              dataId="GuestInput"
              isActive={isGuestPickerOpen}
              onClick={onGuestPickerClick}
              icon={
                <IconWrapper>
                  <Icon name="People" />
                </IconWrapper>
              }
              isRounded
            >
              <ButtonContent>
                {numberOfGuests} {guestMessage}
                {isRoomsLabelShown && (
                  <>
                    , {numberOfRooms} {roomsMessage}
                  </>
                )}
              </ButtonContent>
            </FormButton>
          </div>
        </GuestWrapper>

        <Grid mobileSm="fit">
          <SearchButtonWrapper>
            <Button
              variant={toggle<Variant[]>(
                'b4f4f3cc-website-restyle-v3',
                'primary',
                'primaryGradient'
              )}
              size="lg"
              dataId="SearchButton"
              onClick={onSubmit}
              iconStart={<Icon name="Search" />}
              isRounded
            >
              <FormattedMessageWrapper id="search" defaultMessage="Search" />
            </Button>
          </SearchButtonWrapper>
        </Grid>
      </SearchFormGrid>
    </FormContainer>
  )
})
