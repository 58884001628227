import React, {ReactNode} from 'react'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'

import {cssTheme} from '../../../themes'
import {linariaMq} from '../../../utils/breakpoints'
import {getLinariaClassName} from '../../../utils/getLinariaClassName'
import {
  baseInputBackgroundStyles,
  baseInputBaseLayoutStyles,
  baseInputBorderStyles,
  baseInputInvalidStateStyles,
  baseInputRadiusStyles,
  baseInputShadowStyles,
  baseInputSizeStyles,
  baseInputTypographyStyles,
  defaultBorderStyles,
  focusBorderStyles,
  focusShadowStyles,
  getBaseInputIconPaddingStyles,
  InputBaseSize,
  StartIconsWrapper
} from '../helpers/InputBase'
import {NoStyleButton} from '../helpers/NoStyleButton'

type Props = {
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** Whether the input is disabled */
  disabled?: boolean
  /** An Icon to display on the left side of the input */
  icon?: JSX.Element
  /** ID of this component, passed down by the controlling parent component */
  id: string
  /** Whether the input is rounded */
  isRounded?: boolean
  /** An onClick callback passed down by the controlling parent component */
  onClick?: (event: React.SyntheticEvent<Element, Event>) => void
  /** Content to be used as the input placeholder */
  placeholder?: ReactNode
  /** The size of the input */
  size?: InputBaseSize
  /** HTML class to be include in the button */
  className?: string
  /** The content of the InputButton, a react component or pure text */
  children: ReactNode
  /** Style prop is used by Linaria to pass CSS variables in cases when we need to style custom components in this way: `const StyledButton = styled(InputButton)` */
  style?: React.CSSProperties
}

export const InputButtonStyledContainer = styled.div<{
  sizeVariant?: InputBaseSize
  withStartIcon?: boolean
  isRounded?: boolean
}>`
  ${baseInputBaseLayoutStyles}
  ${baseInputBackgroundStyles}
  ${baseInputInvalidStateStyles}
  ${baseInputShadowStyles}
  ${baseInputRadiusStyles}
  ${baseInputBorderStyles}
  
  select,
  input {
    ${cssTheme.typography.text.bodyS};
  }
  padding-left: ${({sizeVariant, withStartIcon, isRounded}) =>
    getBaseInputIconPaddingStyles({sizeVariant, withStartIcon, isRounded})
      .paddingLeft};
  z-index: 0;
  padding-right: 0;
  cursor: pointer;
  height: 100%;
  &.hasPlaceholder {
    color: ${cssTheme.colors.input.default.placeholder};
  }
`

const ButtonElement = styled(NoStyleButton)`
  width: 100%;
  position: relative;

  &:focus ${InputButtonStyledContainer} {
    ${defaultBorderStyles}
  }

  ${linariaMq.desktopXs} {
    &:focus ${InputButtonStyledContainer} {
      ${focusShadowStyles}
      ${focusBorderStyles}
    }
  }
  &:disabled {
    ${InputButtonStyledContainer} {
      color: ${cssTheme.colors.input.disabled.text};
      border-color: ${cssTheme.colors.input.disabled.border};
      background-color: ${cssTheme.colors.input.disabled.background};
    }
  }
`

export const InputButton = ({
  dataId,
  disabled,
  icon,
  id,
  isRounded,
  onClick,
  placeholder,
  size = 'lg',
  className,
  children,
  style
}: Props) => (
  <ButtonElement
    onClick={onClick}
    className={className}
    data-id={dataId}
    style={style}
    disabled={disabled}
  >
    <InputButtonStyledContainer
      id={id}
      sizeVariant={size}
      isRounded={isRounded}
      withStartIcon={Boolean(icon)}
      className={cx(
        `${getLinariaClassName(InputButtonStyledContainer)}--size-${size}`,
        `${baseInputSizeStyles}--size-${size}`,
        `${baseInputTypographyStyles}--size-${size}`,
        isRounded && 'isRounded',
        !children && 'hasPlaceholder'
      )}
    >
      {children ? children : placeholder}
    </InputButtonStyledContainer>
    {icon && <StartIconsWrapper sizeVariant={size}>{icon}</StartIconsWrapper>}
  </ButtonElement>
)
