import {FullStory} from '@daedalus/core/src/analytics/types/FullStory'

type TagSessionType = Record<string, boolean>

declare let FS: FullStory

export const getFullStoryObject = () => {
  return typeof FS === 'undefined' ? undefined : FS
}

export const getFullStorySessionUrl = () => {
  const fs = getFullStoryObject()
  if (fs) {
    return fs.getCurrentSessionURL?.(true)
  }
}

export const trackFullStoryEvent = (eventName, eventProperties) => {
  getFullStoryObject()?.event(eventName, eventProperties)
}

export const tagFullStorySession = (tags: TagSessionType) => {
  getFullStoryObject()?.setUserVars(tags)
}
