// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgMug3 = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.75a.75.75 0 1 0-1.5 0v1.5a.75.75 0 0 0 1.5 0v-1.5Zm-7 4A.75.75 0 0 1 5.75 6h11.5a.75.75 0 0 1 .75.75v8.5A2.75 2.75 0 0 1 15.25 18h-7.5A2.75 2.75 0 0 1 5 15.25V15h-.25A2.75 2.75 0 0 1 2 12.25v-1.5A2.75 2.75 0 0 1 4.75 8H5V6.75ZM5 9.5v4h-.25c-.69 0-1.25-.56-1.25-1.25v-1.5c0-.69.56-1.25 1.25-1.25H5Zm1.5-2v7.75c0 .69.56 1.25 1.25 1.25h7.5c.69 0 1.25-.56 1.25-1.25V7.5h-10Zm-1-4.75A.75.75 0 0 1 6.25 2v.75L6.251 2h.02l.028.001.074.006a1.714 1.714 0 0 1 .827.33c.478.359.8.98.8 1.913a.75.75 0 0 1-1.5 0c0-.563-.175-.694-.2-.712a.214.214 0 0 0-.076-.037l-.008-.002A.75.75 0 0 1 5.5 2.75Zm11.5 0a.75.75 0 0 1-.716.75l-.008.001a.215.215 0 0 0-.076.037c-.025.018-.2.15-.2.712a.75.75 0 0 1-1.5 0c0-.933.322-1.554.8-1.913a1.714 1.714 0 0 1 .901-.336h.039L16.245 2h.003c.001 0 .002 0 .002.75V2a.75.75 0 0 1 .75.75Z"
    />
  </svg>
)

export default SvgMug3
