// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgAlignArrowUp = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.25 4.66v8.59a.75.75 0 0 0 1.5 0V4.66l1.95 2.1a.75.75 0 1 0 1.1-1.02l-3.25-3.5A.755.755 0 0 0 10 2a.748.748 0 0 0-.555.246L6.2 5.74a.75.75 0 1 0 1.1 1.02l1.95-2.1ZM2 17.25a.75.75 0 0 1 .75-.75h14.5a.75.75 0 1 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Z"
    />
  </svg>
)

export default SvgAlignArrowUp
