import * as React from 'react'

const SvgTriangle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 90 80"
    width={90}
    height={80}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.774 0C2.492 0-2.683 8.877 1.457 15.978l34.227 58.696c4.141 7.102 14.494 7.101 18.634-.001l34.225-58.695C92.683 8.877 87.508 0 79.226 0H10.774Z"
    />
  </svg>
)

export default SvgTriangle
