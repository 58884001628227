import {LogLevel, logToDataDog} from 'services/datadogLogger'
import {Timing} from 'utils/timing'

export enum IpInfoServiceStatus {
  start = 'Start',
  done = 'Done',
  error = 'Error'
}

const logIpInfoService = (
  status: IpInfoServiceStatus,
  metadata: {
    error?: unknown
    timing?: Timing
    ipInfoUrl: string
    ipInfoResponse?: {
      ip?: string
      country?: string
    }
  },
  level?: LogLevel
) => {
  logToDataDog({
    logName: `Services.IpInfo.Fetch.${status}`,
    metadata: {metadata},
    level
  })
}

export default logIpInfoService
