import React from 'react'

import {Icon} from '@daedalus/atlas/src/components/designSystem/Icon'
import {Text} from '@daedalus/atlas/src/components/designSystem/Text'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {ArrowRow} from '../ArrowButton'
import {IconWrapper, Label, Link, RowWrapper} from '../commonStyles'

interface Props {
  link: string
}

export const DebugPanelLink = ({link}: Props) => {
  return (
    <Link href={link}>
      <RowWrapper>
        <ArrowRow>
          <Label>
            <IconWrapper>
              <Icon name="SettingsSliders" />
            </IconWrapper>
            <Text variant="labelM">
              <FormattedMessageWrapper
                id="debugPanel"
                defaultMessage="Debug Panel"
              />
            </Text>
          </Label>
        </ArrowRow>
      </RowWrapper>
    </Link>
  )
}
