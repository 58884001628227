// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPin = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 9c0-3.071 2.586-5.5 5.5-5.5s5.5 2.429 5.5 5.5c0 .81-.32 1.688-.867 2.586-.544.892-1.281 1.752-2.038 2.509A23.793 23.793 0 0 1 10 16.326a23.803 23.803 0 0 1-2.595-2.232c-.757-.756-1.494-1.616-2.038-2.508C4.819 10.688 4.5 9.81 4.5 9Zm5.078 8.87.422-.62-.422.62a.75.75 0 0 0 .844 0L10 17.25l.422.62.002-.001.004-.003.016-.011.057-.04a23.778 23.778 0 0 0 .94-.703 25.3 25.3 0 0 0 2.214-1.957c.806-.805 1.631-1.758 2.259-2.788C16.538 11.343 17 10.19 17 9c0-3.929-3.287-7-7-7S3 5.07 3 9c0 1.19.462 2.343 1.086 3.367.628 1.03 1.453 1.983 2.259 2.788a25.31 25.31 0 0 0 3.154 2.66l.057.04.016.01.004.004h.001ZM8.5 9a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM10 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
    />
  </svg>
)

export default SvgPin
