// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgLayer3 = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.38 2.103a.75.75 0 0 0-.76 0l-7.25 4.25a.75.75 0 0 0 0 1.294l1.456.853-1.455.853a.75.75 0 0 0 0 1.294l1.455.853-1.455.853a.75.75 0 0 0 0 1.294l7.25 4.25a.75.75 0 0 0 .758 0l7.25-4.25a.75.75 0 0 0 0-1.294l-1.455-.853 1.455-.853a.75.75 0 0 0 0-1.294L16.174 8.5l1.455-.853a.75.75 0 0 0 0-1.294l-7.25-4.25ZM14.69 9.37l-4.312 2.528a.75.75 0 0 1-.758 0L5.309 9.37 4.233 10 10 13.38 15.767 10l-1.076-.63ZM10 10.381 4.233 7 10 3.618 15.767 7 10 10.38Zm.38 4.516 4.311-2.528 1.076.631L10 16.38 4.233 13l1.076-.63 4.312 2.527a.75.75 0 0 0 .758 0Z"
    />
  </svg>
)

export default SvgLayer3
