import {createSelector} from '@reduxjs/toolkit'
import {
  calculateNightlyPrice,
  getAnchorTotalPrice,
  getNightlyDisplayTotalPrice,
  getRaaOrSapiNightlyDisplayTotalPrice
} from 'business/price'
import {room} from 'components/__fixtures__/room'
import getNumberOfNights from 'components/data/URLParams/mappers/getNumberOfNights'
import getRoomConfig from 'components/data/URLParams/mappers/getRoomConfig'
import {getRaaOfferContext} from 'modules/analytics/contexts/getRaaOfferContext'
import {
  getRaaAnchorPriceBreakdown,
  getRaaBestOffers
} from 'modules/raaOffers/selectors'
import {
  getCheapestOffer,
  getSelectedRoomPosition,
  getTaxDisplayLogic
} from 'modules/rooms/selectors'
import {urlParams} from 'utils/urlParams'

import {selectIsPrivateDeal} from '@daedalus/core/src/auth/modules/selectors'
import {RaaOfferWithPrices} from '@daedalus/core/src/offer/types/Raa'

export const getHotelsMetaData = createSelector(
  [
    getCheapestOffer,
    getRaaBestOffers,
    getSelectedRoomPosition,
    getRaaAnchorPriceBreakdown,
    selectIsPrivateDeal,
    getTaxDisplayLogic
  ],
  (
    cheapestOffer,
    raaOffers: RaaOfferWithPrices[],
    roomPosition,
    raaAnchorPriceBreakdown,
    isPrivateDeal,
    taxDisplayLogic
  ) => {
    const params = urlParams()
    const numberOfNights = getNumberOfNights(params)
    const {rooms, hotelId} = params
    const {numberOfRooms} = getRoomConfig({rooms})
    const raaTopOffers = raaOffers.map((offer, index) => ({
      roomMasterId: room.masterId,
      offerPosition: index + 1,
      roomPosition,
      roomId: room.id,
      numberOfImages: room.images.length,
      roomAmenities: room.amenities,
      roomDescription: room.description,
      squashedIds: room.squashedIds,
      anchorPrice: calculateNightlyPrice(
        getAnchorTotalPrice(raaAnchorPriceBreakdown),
        numberOfNights,
        numberOfRooms
      ),
      rateBreakdown: {
        baseRate: calculateNightlyPrice(
          offer.rateBreakdown.baseRate,
          numberOfNights,
          numberOfRooms
        ),
        localTaxes: calculateNightlyPrice(
          offer.rateBreakdown.localTaxes,
          numberOfNights,
          numberOfRooms
        ),
        taxes: calculateNightlyPrice(
          offer.rateBreakdown.taxes,
          numberOfNights,
          numberOfRooms
        )
      },
      isPrivateDeal: isPrivateDeal(offer),
      totalPrice: getRaaOrSapiNightlyDisplayTotalPrice(
        offer.rateBreakdown.baseRate,
        offer.rateBreakdown.taxes,
        offer.rateBreakdown.localTaxes,
        numberOfNights,
        numberOfRooms,
        taxDisplayLogic
      ),
      ...getRaaOfferContext(offer)
    }))

    return [
      {
        hotelId,
        cheapestPrice: cheapestOffer
          ? getNightlyDisplayTotalPrice(cheapestOffer.prices)
          : undefined,
        isHotelMapOpen: false,
        distanceFromSearchedLocation: 0,
        topOffers: raaTopOffers
      }
    ]
  }
)
