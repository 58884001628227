// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgBeach2 = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 3.75A.75.75 0 0 1 6.75 3c2.176 0 3.81.324 4.916.657a10.08 10.08 0 0 1 1.334.5l.056-.027c.257-.125.62-.29 1.05-.455C14.95 3.35 16.114 3 17.25 3a.75.75 0 0 1 0 1.5c-.852 0-1.799.267-2.573.563.1.072.201.151.303.236.866.721 1.77 1.915 1.77 3.701a.75.75 0 0 1-1.5 0c0-1.214-.596-2.02-1.23-2.549a4.642 4.642 0 0 0-.87-.569c-1.276 1.267-1.661 2.77-1.713 4.024a7.79 7.79 0 0 0 .23 2.203l.014.05a7.722 7.722 0 0 1 3.625 1.777c.552.482.95.966 1.21 1.331a6.28 6.28 0 0 1 .394.627l.007.013.002.004.001.002s0 .002-.647.326l.648-.324a.75.75 0 1 1-1.342.67l.67-.335-.67.335.001.002s0 .002 0 0l-.01-.018a4.823 4.823 0 0 0-.274-.43 6.335 6.335 0 0 0-.977-1.075c-.89-.778-2.283-1.564-4.319-1.564s-3.43.786-4.319 1.564a6.34 6.34 0 0 0-.977 1.075 4.817 4.817 0 0 0-.274.43l-.01.017a.75.75 0 0 1-1.34-.671l.67.335-.67-.335v-.004l.003-.004.007-.013a2.495 2.495 0 0 1 .099-.176c.066-.113.164-.268.294-.45a7.836 7.836 0 0 1 1.21-1.332C5.805 12.964 7.537 12 10 12h.104a9.287 9.287 0 0 1-.166-2.157c.04-.97.25-2.1.819-3.208-1.253.55-2.62 1.29-3.477 2.145a.75.75 0 0 1-1.06-1.06C7.326 6.613 9 5.755 10.33 5.186c.17-.073.336-.142.497-.207C9.866 4.728 8.51 4.5 6.75 4.5A.75.75 0 0 1 6 3.75Z"
    />
  </svg>
)

export default SvgBeach2
