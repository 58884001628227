// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgArrowUp = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.543 2.232a.75.75 0 0 0-1.086 0l-5.25 5.5a.75.75 0 0 0 1.086 1.036L9.25 4.622V17.25a.75.75 0 0 0 1.5 0V4.622l3.957 4.146a.75.75 0 0 0 1.085-1.036l-5.25-5.5Z"
    />
  </svg>
)

export default SvgArrowUp
