import {MiddlewareType} from 'middlewares'
import {getSearchContext} from 'modules/analytics/contexts/getSearchContext'
import {navigateTo} from 'modules/browser/actions'
import {clearError} from 'modules/common/actions/error'
import {fatalError} from 'modules/common/actions/fatalError'
import {getSearchId} from 'modules/meta/selectors'
import {setSearchId} from 'modules/meta/slice'
import {
  changeDates,
  changeRoomsConfig,
  changeSearchParameters,
  roomsFetch,
  roomsFetchDone,
  roomsFetchError,
  roomsFetchFatalError,
  roomsFetchPending
} from 'modules/rooms/slice'
import logSAPIService, {
  SAPIServiceEndpointNames,
  SAPIServiceStatus
} from 'performance/logSAPIService'
import {ROOM_SELECTION_PAGE_PATH} from 'routes'
import {fetchRoomsSapi} from 'services/rooms'
import captureException from 'utils/captureException'
import timing from 'utils/timing'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Page,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {generateSearchId} from '@findhotel/sapi'

import fetchCycle from '../fetchCycle'

const requestsTiming = timing()

const middleware: MiddlewareType = store => next => action => {
  const {dispatch, getState} = store
  const state = getState()
  const searchId = getSearchId(state)

  const updateSearchId = () => {
    dispatch(setSearchId(generateSearchId()))
  }

  next(action)

  switch (action.type) {
    case roomsFetch.type: {
      const {
        params,
        isMemberPlus,
        isEmployee,
        isAuthenticated,
        useClickedOfferParams,
        userId,
        emailDomain,
        urlParams,
        deviceId
      } = action.payload
      dispatch(clearError())
      requestsTiming.start('roomsFetch')
      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.Search,
          action: Action.Requested,
          page: Page.RoomSelection,
          analyticsContext: {
            [AnalyticsContext.SearchContext]: getSearchContext(state)
          },
          team: Team.Select
        })
      )
      fetchCycle(
        dispatch,
        fetchRoomsSapi({
          ...params,
          isMemberPlus,
          isEmployee,
          isAuthenticated,
          useClickedOfferParams,
          userId,
          emailDomain,
          searchId,
          urlParams,
          deviceId
        }),
        roomsFetchPending,
        roomsFetchDone,
        roomsFetchError,
        roomsFetchFatalError
      )

      logSAPIService(SAPIServiceEndpointNames.Rooms, SAPIServiceStatus.start, {
        params
      })

      break
    }

    case roomsFetchDone.type: {
      const timings = requestsTiming.stop('roomsFetch')

      logSAPIService(SAPIServiceEndpointNames.Rooms, SAPIServiceStatus.done, {
        searchId: action.params,
        timings
      })

      break
    }

    case roomsFetchError.type: {
      const timings = requestsTiming.stop('roomsFetch')

      logSAPIService(
        SAPIServiceEndpointNames.Rooms,
        SAPIServiceStatus.error,
        {params: action.params, timings},
        'error'
      )
      break
    }

    case roomsFetchFatalError.type: {
      const timings = requestsTiming.stop('roomsFetch')

      logSAPIService(
        SAPIServiceEndpointNames.Rooms,
        SAPIServiceStatus.fatalError,
        {response: action?.payload, timings},
        'error'
      )

      captureException(action?.payload)
      dispatch(fatalError(action?.payload))
      break
    }

    case changeDates.type: {
      const {checkIn, checkOut} = action.payload
      updateSearchId()
      dispatch(
        navigateTo(
          ROOM_SELECTION_PAGE_PATH,
          {
            checkIn,
            checkOut,
            price: undefined,
            baseRate: undefined,
            localTax: undefined,
            tax: undefined,
            originalBaseRate: undefined,
            originalTax: undefined,
            originalLocalTax: undefined,
            originalCurrency: undefined,
            oid: undefined,
            bundleId: undefined
          },
          false,
          true
        )
      )
      break
    }

    case changeRoomsConfig.type: {
      updateSearchId()
      dispatch(
        navigateTo(
          ROOM_SELECTION_PAGE_PATH,
          {
            rooms: action.payload,
            price: undefined,
            baseRate: undefined,
            localTax: undefined,
            tax: undefined,
            originalBaseRate: undefined,
            originalTax: undefined,
            originalLocalTax: undefined,
            originalCurrency: undefined,
            oid: undefined,
            bundleId: undefined
          },
          false,
          true
        )
      )
      break
    }

    case changeSearchParameters.type: {
      const {checkIn, checkOut, rooms} = action.payload
      updateSearchId()
      dispatch(
        navigateTo(
          ROOM_SELECTION_PAGE_PATH,
          {
            checkIn,
            checkOut,
            rooms,
            price: undefined,
            baseRate: undefined,
            localTax: undefined,
            tax: undefined,
            originalBaseRate: undefined,
            originalTax: undefined,
            originalLocalTax: undefined,
            originalCurrency: undefined
          },
          false,
          true
        )
      )
      break
    }

    default:
      break
  }
}

export default middleware
