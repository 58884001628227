import {createSelector} from '@reduxjs/toolkit'
import {anyPass, find, last, pathOr, propEq} from 'ramda'
import {RootState} from 'store'

import {defaultCountry} from '@daedalus/core/src/localization/business/countries'
import {isSplitBookingPriceEligible} from '@daedalus/core/src/offer/business/isSplitBookingPriceEligible'
import {
  HotelFeesBreakdown,
  Offer,
  OfferPrice
} from '@daedalus/core/src/offer/types/offer'
import {
  getDisplayPrice,
  getTotalPrice
} from '@daedalus/core/src/price/business/price'
import {sortRoomsAndSplitBooking} from '@daedalus/core/src/room/business/utils/sortRoomsAndSplitBooking'
import {
  Room,
  SapiAbstractOfferType,
  SplitBooking
} from '@daedalus/core/src/room/types/room'
import {searchApi} from '@daedalus/core/src/sapi/services/searchApi'

import {getCheckoutUrlFromOffer} from '../../business/offer'
import {OVERLAY_IDS} from '../../modules/overlay/selectors'
import {roomsFetchDone, roomsFetchError} from './slice'
const pathToRoomsList = ['rooms', 'roomsList']
const pathToSplitBooking = ['rooms', 'splitBooking']

type Selector<S> = (state: RootState) => S

// @ts-expect-error RTK Query serializes the request params and store in the Redux module
export const getRoomsState = searchApi.endpoints.getRooms.select()

export const getRooms: Selector<Room[]> = createSelector(
  (state: RootState) => {
    return pathOr<Room[]>([], pathToRoomsList, state)
  },
  rooms => rooms
)

export const getCheapestRoom = createSelector(
  getRooms,
  rooms => rooms[0] || null
)

export const getCheapestOffer = createSelector(
  getCheapestRoom,
  cheapestRoom => cheapestRoom?.offers[0] || null
)

export const getFlatOffersFromRooms = createSelector(getRooms, rooms => {
  return rooms.reduce<Offer[]>((acc, room) => {
    return [...acc, ...room.offers]
  }, [])
})

export const nightlyDisplayTotalPath = ['chargeable', 'nightlyDisplayTotal']

export const basePath = ['chargeable', 'base']
export const nightlyBasePath = ['chargeable', 'nightlyBase']

export const taxesPath = ['chargeable', 'taxes']
export const nightlyTaxesPath = ['chargeable', 'nightlyTaxes']

/* TODO: remove total paths after BoFH remove these properties from the api */
export const nightlyTotalPath = ['chargeable', 'nightlyTotal']

export const hotelFeesPath = ['hotelFees', 'total']

export const getHotelFees = pathOr(0, hotelFeesPath)

export const getHotelNightlyFees = (price?: OfferPrice) =>
  price?.hotelFees?.nightly ?? 0

export const hotelFeesNightlyPath = ['hotelFees', 'nightly']

export const hotelFeesBreakdownPath = ['hotelFees', 'breakdown']

export const getHotelFeesBreakdown = pathOr<HotelFeesBreakdown>(
  [],
  hotelFeesBreakdownPath
)

export const getRoomById = (roomId: string | unknown) => (state: RootState) => {
  const rooms = getRooms(state)
  return find<Room>(propEq('id', roomId))(rooms)
}

export const getModuleState = (state: RootState) => {
  return state?.rooms?.fetchRoomsState
}

export const getOfferById =
  (roomId?: string, offerId?: string) => (state: RootState) => {
    const room = getRoomById(roomId)(state)
    if (!room) return null

    const offers = pathOr<Offer[]>([], ['offers'], room)
    return find<Offer>(propEq('id', offerId))(offers)
  }

export const getRoomDetails = (state: RootState) => {
  const topmostOverlay = last(state.overlay)
  if (
    !topmostOverlay ||
    !propEq('id', OVERLAY_IDS.roomDetails, topmostOverlay)
  ) {
    return
  }

  return getRoomById(topmostOverlay.extraState?.roomId)(state)
}

export const getSelectedRoomPosition = (state: RootState) => {
  return state.rooms?.selectedRoomPosition
}

export const getSelectedOfferPosition = (state: RootState) => {
  return state.rooms?.selectedOfferPosition
}

export const getSelectedOfferCheckoutUrl = (
  state: RootState,
  roomId?: string,
  offerId?: string
) => {
  const storedUrl = state?.rooms?.selectedOfferCheckoutUrl
  if (storedUrl) return storedUrl

  const offer = getOfferById(roomId, offerId)(state)
  const checkoutLink = getCheckoutUrlFromOffer(offer)
  return checkoutLink?.href
}

export const getAreRoomsLoaded = (state: RootState) => {
  const areOldEndpointRoomsLoaded = anyPass([
    propEq('fetchRoomsState', roomsFetchDone.type),
    propEq('fetchRoomsState', roomsFetchError.type)
  ])(state.rooms)

  return areOldEndpointRoomsLoaded
}

export const getTaxDisplayLogic = createSelector(
  (state: RootState) => state.rooms,
  (state: RootState['rooms']) => {
    const defaultTaxDisplayLogic = {
      includeLocalTaxes: defaultCountry.includeLocalTaxes,
      includeTaxes: defaultCountry.includeTaxes
    }

    return state?.taxDisplayLogic || defaultTaxDisplayLogic
  }
)
export const getSplitBookingFromState = createSelector(
  (state: RootState, isMobile: boolean) => {
    return !isMobile
      ? pathOr<SplitBooking | null>(null, pathToSplitBooking, state)
      : null
  },
  splitBooking => splitBooking
)

/**
 * Returns split booking object if it is cheaper than all the room offers by at least max (2%, 10 currency units).
 */
export const getSplitBooking = createSelector(
  [getSplitBookingFromState, getCheapestOffer],
  (splitBooking, cheapestOffer) => {
    if (splitBooking?.isClicked) return splitBooking
    const cheapestOfferTotalPrice = cheapestOffer
      ? getTotalPrice(getDisplayPrice(cheapestOffer.prices))
      : 0

    return isSplitBookingPriceEligible(
      splitBooking?.totalRate,
      cheapestOfferTotalPrice
    )
      ? splitBooking
      : null
  }
)
export const getSplitBookingForAnalytics = createSelector(
  [getSplitBookingFromState, getSplitBooking, getRooms],
  (stateSplitBooking, splitBooking, rooms) => {
    if (!stateSplitBooking) return null

    const sortedRoomsAndSB = sortRoomsAndSplitBooking(rooms, splitBooking)

    return {
      ...stateSplitBooking,
      isHidden: !splitBooking,
      roomPosition:
        sortedRoomsAndSB.findIndex(
          entity => entity.type === SapiAbstractOfferType.SplitBooking
        ) + 1
    }
  }
)
