import React, {ReactNode, useCallback} from 'react'
import {assocPath, remove} from 'ramda'

import {MANUAL_MAX_GUESTS_PER_ROOM} from '@daedalus/core/src/room/business/roomConfiguration/config'
import {UniqueRoomSplitType} from '@daedalus/core/src/room/types/RoomConfiguration'

import {GuestPickerLayout} from './GuestPickerLayout'

export type Props = {
  roomsSplit: UniqueRoomSplitType[]
  updateRoomsSplit: (roomsSplit: UniqueRoomSplitType[]) => void
  isCompactLayout?: boolean
  customTitle?: ReactNode
}

export const GuestPicker = ({
  roomsSplit,
  updateRoomsSplit,
  isCompactLayout = false,
  customTitle
}: Props) => {
  const handleRemoveRoom = useCallback(
    (index: number) => {
      const newRoomsSplit = remove(index, 1, roomsSplit)

      updateRoomsSplit(newRoomsSplit)
    },
    [roomsSplit, updateRoomsSplit]
  )

  const handleAdultsChange = useCallback(
    (index: number, adults: number) => {
      if (adults) {
        const newRoomsSplit = assocPath([index, 'adults'], adults, roomsSplit)
        updateRoomsSplit(newRoomsSplit)
      } else handleRemoveRoom(index)
    },
    [handleRemoveRoom, roomsSplit, updateRoomsSplit]
  )

  const handleChildrenChange = useCallback(
    (index: number, children: number[]) => {
      const newRoomsSplit = assocPath([index, 'children'], children, roomsSplit)

      updateRoomsSplit(newRoomsSplit)
    },
    [roomsSplit, updateRoomsSplit]
  )

  return (
    <>
      {roomsSplit.map((room, index) => (
        <React.Fragment key={room.id}>
          <GuestPickerLayout
            customTitle={customTitle}
            adultGuests={room.adults}
            childGuests={room.children}
            index={index}
            maxGuests={MANUAL_MAX_GUESTS_PER_ROOM}
            onAdultsChange={handleAdultsChange}
            onChildrenChange={handleChildrenChange}
            onRemoveRoom={handleRemoveRoom}
            isCompactLayout={isCompactLayout}
          />
        </React.Fragment>
      ))}
    </>
  )
}
