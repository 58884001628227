import * as React from 'react'

const SvgDiamond = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={110}
    height={80}
    viewBox="0 0 110 80"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M96.524 0c10.359 0 16.832 10.66 11.653 19.188L76.986 70.545C73.433 76.395 66.866 80 59.76 80H11.718C2.711 80-2.919 70.73 1.585 63.315l32.711-53.86C37.85 3.604 44.416 0 51.522 0h45.002Z"
    />
  </svg>
)

export default SvgDiamond
