import React, {ReactNode} from 'react'
import {styled} from '@linaria/react'
import {SizesType} from 'types/Sizes'

import {cssTheme} from '../../../../themes'
import {Divider} from '../../Divider'

type BottomBarSize = Extract<SizesType, 'md' | 'lg'>
export interface BottomBarProps {
  /** Content of the BottomBar */
  children: ReactNode
  /** Determine if a divider is rendered in the top of the BottomBar */
  hasDivider?: boolean
  /** Pass through className to allow styles overrides */
  className?: string
  /** Whether the BottomBar should be sticky or not */
  sticky?: boolean
  /** The size of the BottomBar (spacings) */
  size?: BottomBarSize
}

const HEADER_HEIGHT = 56

export const BottomBarWrapper = styled.div<{
  sticky?: boolean
  size?: BottomBarSize
}>`
  position: relative;
  display: flex;
  flex: auto;
  justify-content: center;
  flex-direction: column;
  background: ${cssTheme.colors.background.neutral.c000};
  padding: ${({size}) =>
    size === 'lg'
      ? `${cssTheme.layout.spacing.s500} ${
          cssTheme.layout.spacing.s500 + cssTheme.layout.spacing.s200
        }`
      : `${cssTheme.layout.spacing.s400} ${cssTheme.layout.spacing.s500}`};
  min-height: ${HEADER_HEIGHT}px;
  border-top: ${({sticky}) =>
    sticky ? `solid 1px ${cssTheme.colors.border.neutral.c100}` : 'none'};
`

export const BottomBar = ({
  hasDivider = false,
  children,
  className,
  size = 'md',
  sticky = false
}: BottomBarProps) => {
  return (
    <>
      {hasDivider && <Divider />}
      <BottomBarWrapper className={className} sticky={sticky} size={size}>
        {children}
      </BottomBarWrapper>
    </>
  )
}
