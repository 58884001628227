// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgUser = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 16 16"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4.8a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2-3.2A3.2 3.2 0 1 0 8 8a3.2 3.2 0 0 0 0-6.4ZM3.218 12.487a3.827 3.827 0 0 1 1.448-1.514C5.401 10.534 6.462 10.2 8 10.2s2.6.334 3.334.773a3.827 3.827 0 0 1 1.448 1.514c.096.18.066.324-.031.445-.113.142-.338.268-.629.268H3.878c-.29 0-.516-.126-.629-.268-.097-.121-.127-.264-.031-.445ZM8 9c-1.716 0-2.998.375-3.949.943a5.026 5.026 0 0 0-1.894 1.984 1.57 1.57 0 0 0 .154 1.753c.36.453.944.72 1.567.72h8.244c.623 0 1.207-.267 1.567-.72a1.57 1.57 0 0 0 .154-1.753 5.026 5.026 0 0 0-1.894-1.984C10.998 9.375 9.716 9 8 9Z"
    />
  </svg>
)

export default SvgUser
