import React, {Suspense} from 'react'
import {RoutePropsTypes} from 'types/types'

import GridGlobalStyles from '@daedalus/atlas/foundation/GridGlobalStyles'

import {MetaContextProvider} from '../context/meta'

const LazyDebugPanelPage = React.lazy(
  async () => import('../components/DebugPanel/DebugPanelPage')
)

export const DebugPanelLoader = ({
  protectCode,
  thirdPartyExperiments,
  meta,
  scenarios,
  variationsStoreReader,
  modulesConfig
}: RoutePropsTypes) => {
  return (
    <MetaContextProvider value={meta}>
      <GridGlobalStyles />
      <Suspense fallback={<span>Loading</span>}>
        <LazyDebugPanelPage
          protectCode={protectCode}
          thirdPartyExperiments={thirdPartyExperiments}
          variationsStoreReader={variationsStoreReader}
          modulesConfig={modulesConfig}
          scenarios={scenarios}
        />
      </Suspense>
    </MetaContextProvider>
  )
}
