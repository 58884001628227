// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgHeartBroken = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.53 2.47a.75.75 0 0 1 0 1.06l-13 13a.75.75 0 0 1-1.06-1.06l13-13a.75.75 0 0 1 1.06 0Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.995 4.193C8.29 2.77 5.785 2.486 3.837 4.08c-2.127 1.742-2.446 4.696-.753 6.783a.746.746 0 0 0 .073.078l6.329 5.86a.75.75 0 0 0 1.019 0l6.328-5.86a.744.744 0 0 0 .073-.078c1.686-2.078 1.416-5.056-.76-6.789-1.97-1.568-4.45-1.305-6.151.12Zm-.56 1.54C8.21 4.363 6.246 4.05 4.788 5.242c-1.49 1.22-1.693 3.21-.57 4.637l5.778 5.35 5.778-5.35c1.131-1.435.942-3.433-.562-4.63-1.496-1.192-3.436-.88-4.656.486a.75.75 0 0 1-1.12 0Z"
    />
  </svg>
)

export default SvgHeartBroken
