// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgSparkles2 = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 2a.75.75 0 0 1 .75.75c0 .336.167.717.475 1.025.308.308.689.475 1.025.475a.75.75 0 1 1 0 1.5c-.336 0-.717.167-1.025.475-.308.308-.475.689-.475 1.025a.75.75 0 1 1-1.5 0c0-.336-.167-.717-.475-1.025-.308-.308-.689-.475-1.025-.475a.75.75 0 0 1 0-1.5c.336 0 .717-.167 1.025-.475.308-.308.475-.689.475-1.025A.75.75 0 0 1 15 2Zm0 2.659a3.286 3.286 0 0 1-.341.341 3.33 3.33 0 0 1 .34.341A3.329 3.329 0 0 1 15.342 5 3.33 3.33 0 0 1 15 4.659ZM15 12a.75.75 0 0 1 .75.75c0 .336.167.717.475 1.025.308.308.689.475 1.025.475a.75.75 0 1 1 0 1.5c-.336 0-.717.167-1.025.475-.308.308-.475.689-.475 1.025a.75.75 0 1 1-1.5 0c0-.336-.167-.717-.475-1.025-.308-.308-.689-.475-1.025-.475a.75.75 0 0 1 0-1.5c.336 0 .717-.167 1.025-.475.308-.308.475-.689.475-1.025A.75.75 0 0 1 15 12Zm0 2.659a3.257 3.257 0 0 1-.341.341 3.334 3.334 0 0 1 .34.342 3.337 3.337 0 0 1 .342-.342A3.334 3.334 0 0 1 15 14.66ZM7.75 5.75a.75.75 0 0 0-1.5 0c0 .846-.412 1.727-1.093 2.407-.68.68-1.56 1.093-2.407 1.093a.75.75 0 0 0 0 1.5c.846 0 1.727.412 2.407 1.093.68.68 1.093 1.56 1.093 2.407a.75.75 0 0 0 1.5 0c0-.846.412-1.726 1.093-2.407.68-.68 1.56-1.093 2.407-1.093a.75.75 0 0 0 0-1.5c-.846 0-1.727-.412-2.407-1.093-.68-.68-1.093-1.56-1.093-2.407ZM6.218 9.218c.294-.294.558-.624.782-.98A5.582 5.582 0 0 0 8.763 10 5.579 5.579 0 0 0 7 11.763 5.58 5.58 0 0 0 5.237 10c.357-.224.687-.488.98-.782Z"
    />
  </svg>
)

export default SvgSparkles2
