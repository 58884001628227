import {BrandThemeName} from '@daedalus/core/src/brand/types'
import {cssTheme as rawCssTheme} from '@findhotel/atlas-tokens'

import {theme as dealbase} from './dealbase'
import {theme as flighthub} from './flighthub'
import {theme as goseek} from './goseek'
import {theme as justfly} from './justfly'
import {theme as kayak} from './kayak'
import {theme as kiwi} from './kiwi'
import {theme as newvio} from './newvio'
import {theme as tripadvisor} from './tripadvisor'
import {theme as trivago} from './trivago'
import {theme as vio} from './vio'

export enum ThemeNames {
  checkfelix = 'checkfelix',
  dealbase = 'dealbase',
  flighthub = 'flighthub',
  goseek = 'goseek',
  hotelscombined = 'hotelscombined',
  justfly = 'justfly',
  kayak = 'kayak',
  kiwi = 'kiwi',
  momondo = 'momondo',
  vio = 'vio',
  newvio = 'newvio',
  trivago = 'trivago',
  tripadvisor = 'tripadvisor'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ThemeMap = Record<ThemeNames, any>

const themeMap: ThemeMap = {
  checkfelix: kayak,
  dealbase: dealbase,
  flighthub: flighthub,
  goseek: goseek,
  hotelscombined: kayak,
  justfly: justfly,
  kayak: kayak,
  kiwi: kiwi,
  momondo: kayak,
  vio: vio,
  newvio: newvio,
  trivago: trivago,
  tripadvisor: tripadvisor
}

export const getTheme = (themeName: BrandThemeName = ThemeNames.vio) =>
  themeMap[themeName] || vio

const {
  custom,
  semantic,
  components,
  opacity,
  layout,
  gradient: gradients,
  shadow: shadows
} = rawCssTheme

const {fonts, typography} = vio

export const cssTheme = {
  colors: {
    ...components,
    ...custom,
    ...semantic
  },
  opacity,
  layout,
  shadows,
  fonts,
  typography,
  ...(gradients ? {gradients} : {})
}
