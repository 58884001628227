import {logEvent} from 'services/datadogLogger'
import {Metric} from 'web-vitals'

import {TrackEventProperties} from '@daedalus/core/src/analytics/types/Events'
import AnalyticsUtils from '@daedalus/core/src/analytics/utils'
import {getUrlParam} from '@daedalus/core/src/utils/url'

export interface PerformanceMetric {
  name: string
  id: string
  value: number
  meta?: {
    field: string
    eventType?: string
    rating?: string
  }
  rating?: string
  target?: {
    name: string
  }
  entries: PerformanceMetric[]
}

export const trackPerformance = (metric: Metric & PerformanceMetric) => {
  const {name, id, value, meta} = metric
  const logTracking = getUrlParam({
    search: window.location.search,
    param: 'logPerformanceTracking'
  })
  AnalyticsUtils.trackEvent('WebsitePerformanceMeasured', {
    id,
    metric: `Checkout.${name}`,
    value,
    meta,
    unit: 'ms'
  } as unknown as TrackEventProperties)

  if (value) {
    logEvent(
      `Checkout.Timing.${name?.replace(/\s+/g, '')}`,
      {
        id,
        value,
        meta
      },
      'info',
      Boolean(logTracking)
    )
  }
}
