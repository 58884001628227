// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgChevronDown = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.278 6.236a.737.737 0 0 0-1.081 1.003l5.263 5.667a.737.737 0 0 0 1.08 0l5.263-5.667a.737.737 0 1 0-1.08-1.003L10 11.32 5.278 6.236Z"
    />
  </svg>
)

export default SvgChevronDown
