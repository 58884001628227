import {useEffect} from 'react'

import {useOpticks} from '../../experiments/components/OpticksProvider'
import {getDataDogRumInstance} from '../components/DataDogClient'

export const useAddFeatureFlagTracking = () => {
  const Opticks = useOpticks()
  useEffect(() => {
    if (Opticks) {
      Opticks.addActivateListener(data => {
        const {flagKey, variationKey} = data.decisionInfo
        getDataDogRumInstance().addFeatureFlagEvaluation(flagKey, variationKey)
      })
    }
  }, [Opticks])

  return null
}
