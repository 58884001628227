export const DEFAULT_ADULTS_PER_ROOM = 2
export const DEFAULT_ROOMS = `${DEFAULT_ADULTS_PER_ROOM}`

export const CHILD_SEPARATOR = ','
export const GUEST_SEPARATOR = ':'
export const ROOM_SEPARATOR = '|'

export const DEFAULT_CHILD_AGE = 8
export const MAX_NUMBER_OF_ROOMS = 8

export const MIN_ADULTS_PER_ROOM = 1
export const MIN_CHILDREN_PER_ROOM = 0

export const MANUAL_MAX_GUESTS_PER_ROOM = 5

export const OPTIMIZED_MAX_GUESTS_PER_ROOM = 20
export const OPTIMIZED_MAX_ADULTS_PER_ROOM = 20
