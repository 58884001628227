import Intl from 'intl'
import {path} from 'ramda'

import {getGregorianCalendar} from './getGregorianCalendar'

// TODO: unify intlLocaleCode and language concepts

export const [
  WEEKDAY_INDEX_SUNDAY,
  WEEKDAY_INDEX_MONDAY,
  WEEKDAY_INDEX_FRIDAY,
  WEEKDAY_INDEX_SATURDAY
] = [0, 1, 5, 6]

/**
 * Referenced from CLDR (Central Locale Data Repository)
 * https://github.com/unicode-org/cldr-json/blob/main/cldr-json/cldr-core/supplemental/weekData.json#L59
 * All countries that start on monday have been removed as that is what we fall back to
 */
const firstDayOfWeekByCountryCode = {
  AE: WEEKDAY_INDEX_SATURDAY,
  AF: WEEKDAY_INDEX_SATURDAY,
  AG: WEEKDAY_INDEX_SUNDAY,
  AS: WEEKDAY_INDEX_SUNDAY,
  BD: WEEKDAY_INDEX_SUNDAY,
  BH: WEEKDAY_INDEX_SATURDAY,
  BR: WEEKDAY_INDEX_SUNDAY,
  BS: WEEKDAY_INDEX_SUNDAY,
  BT: WEEKDAY_INDEX_SUNDAY,
  BW: WEEKDAY_INDEX_SUNDAY,
  BZ: WEEKDAY_INDEX_SUNDAY,
  CA: WEEKDAY_INDEX_SUNDAY,
  CN: WEEKDAY_INDEX_SUNDAY,
  CO: WEEKDAY_INDEX_SUNDAY,
  DJ: WEEKDAY_INDEX_SATURDAY,
  DM: WEEKDAY_INDEX_SUNDAY,
  DO: WEEKDAY_INDEX_SUNDAY,
  DZ: WEEKDAY_INDEX_SATURDAY,
  EG: WEEKDAY_INDEX_SATURDAY,
  ET: WEEKDAY_INDEX_SUNDAY,
  GT: WEEKDAY_INDEX_SUNDAY,
  GU: WEEKDAY_INDEX_SUNDAY,
  HK: WEEKDAY_INDEX_SUNDAY,
  HN: WEEKDAY_INDEX_SUNDAY,
  ID: WEEKDAY_INDEX_SUNDAY,
  IL: WEEKDAY_INDEX_SUNDAY,
  IN: WEEKDAY_INDEX_SUNDAY,
  IQ: WEEKDAY_INDEX_SATURDAY,
  IR: WEEKDAY_INDEX_SATURDAY,
  JM: WEEKDAY_INDEX_SUNDAY,
  JO: WEEKDAY_INDEX_SATURDAY,
  JP: WEEKDAY_INDEX_SUNDAY,
  KE: WEEKDAY_INDEX_SUNDAY,
  KH: WEEKDAY_INDEX_SUNDAY,
  KR: WEEKDAY_INDEX_SUNDAY,
  KW: WEEKDAY_INDEX_SATURDAY,
  LA: WEEKDAY_INDEX_SUNDAY,
  LY: WEEKDAY_INDEX_SATURDAY,
  MH: WEEKDAY_INDEX_SUNDAY,
  MM: WEEKDAY_INDEX_SUNDAY,
  MO: WEEKDAY_INDEX_SUNDAY,
  MT: WEEKDAY_INDEX_SUNDAY,
  MV: WEEKDAY_INDEX_FRIDAY,
  MX: WEEKDAY_INDEX_SUNDAY,
  MZ: WEEKDAY_INDEX_SUNDAY,
  NI: WEEKDAY_INDEX_SUNDAY,
  NP: WEEKDAY_INDEX_SUNDAY,
  OM: WEEKDAY_INDEX_SATURDAY,
  PA: WEEKDAY_INDEX_SUNDAY,
  PE: WEEKDAY_INDEX_SUNDAY,
  PH: WEEKDAY_INDEX_SUNDAY,
  PK: WEEKDAY_INDEX_SUNDAY,
  PR: WEEKDAY_INDEX_SUNDAY,
  PT: WEEKDAY_INDEX_SUNDAY,
  PY: WEEKDAY_INDEX_SUNDAY,
  QA: WEEKDAY_INDEX_SATURDAY,
  SA: WEEKDAY_INDEX_SUNDAY,
  SD: WEEKDAY_INDEX_SATURDAY,
  SG: WEEKDAY_INDEX_SUNDAY,
  SV: WEEKDAY_INDEX_SUNDAY,
  SY: WEEKDAY_INDEX_SATURDAY,
  TH: WEEKDAY_INDEX_SUNDAY,
  TT: WEEKDAY_INDEX_SUNDAY,
  TW: WEEKDAY_INDEX_SUNDAY,
  UM: WEEKDAY_INDEX_SUNDAY,
  US: WEEKDAY_INDEX_SUNDAY,
  VE: WEEKDAY_INDEX_SUNDAY,
  VI: WEEKDAY_INDEX_SUNDAY,
  WS: WEEKDAY_INDEX_SUNDAY,
  YE: WEEKDAY_INDEX_SUNDAY,
  ZA: WEEKDAY_INDEX_SUNDAY,
  ZW: WEEKDAY_INDEX_SUNDAY
}

export const DEFAULT_FIRST_DAY_OF_WEEK = WEEKDAY_INDEX_MONDAY

export type FirstDayOfWeekByCountryCode =
  keyof typeof firstDayOfWeekByCountryCode

/**
 * Returns the index of the first day of the week for the specified locale.
 * By default, returns Monday's index.
 *
 * @returns The index of the first day of the week
 * @param intlLocaleCode The locale used to determine the first day of the week
 */
export const getFirstDayOfWeek = (
  countryCode: FirstDayOfWeekByCountryCode
): number =>
  firstDayOfWeekByCountryCode[countryCode] ?? DEFAULT_FIRST_DAY_OF_WEEK
/**
 * Returns an array of the gregorian calendar month names in the language of the specified locale.
 * By default, returns month names in English.
 *
 * @returns An array of month names in the correct language
 * @param intlLocaleCode The locale used to determine the language of the month names
 */
export const getMonthNames = async (
  intlLocaleCode: string,
  publicUrl: string
) => {
  try {
    const dateConfig = await getGregorianCalendar(intlLocaleCode, publicUrl)
    const monthsObj = await path(
      'months.stand-alone.wide'.split('.'),
      dateConfig
    )

    return Object.values(monthsObj as Record<string, unknown>)
  } catch (error) {
    console.error(
      `Failed to load or parse CDLR JSON file for ${intlLocaleCode} locale in getMonthNames`,
      'error',
      error
    )

    // Use Months in 1970 to get month names
    return new Array(12).fill('x').map((_, i) =>
      // eslint-disable-next-line new-cap
      Intl.DateTimeFormat(intlLocaleCode, {month: 'long'}).format(
        new Date(1970, i, WEEKDAY_INDEX_MONDAY)
      )
    )
  }
}

/**
 * Returns an array of the gregorian calendar short week day names in the language of the specified locale.
 * By default, returns short week day names in English.
 *
 * @returns An array of short week day names in the correct language
 * @param intlLocaleCode The locale used to determine the language of the short week day names
 */
export const getWeekDayNamesShort = async (
  intlLocaleCode: string,
  publicUrl: string
) => {
  try {
    const dateConfig = await getGregorianCalendar(intlLocaleCode, publicUrl)
    const weekDayNamesObj = await path(
      'days.stand-alone.short'.split('.'),
      dateConfig
    )

    return Object.values(weekDayNamesObj as Record<string, unknown>)
  } catch (error) {
    console.error(
      `Failed to load or parse CDLR JSON file for ${intlLocaleCode} locale in getWeekDayNamesShort`,
      'error',
      `${error}`
    )

    // We want to use 2 character display limit for English
    const maxLength = intlLocaleCode === 'en' ? 2 : 3
    // Use Sunday January 4 1970 to get weeknames
    return new Array(7).fill('x').map((_, i) =>
      // eslint-disable-next-line new-cap
      Intl.DateTimeFormat(intlLocaleCode, {weekday: 'short'})
        .format(new Date(1970, 0, i + 4))
        .slice(0, Math.max(0, maxLength))
    )
  }
}
