import {RoomsRequestType} from 'types/Rooms'

import {UrlCheckoutParamsType} from '@daedalus/core/src/utils/url/types/UrlParams'

export const getRoomsRequestParams = <T extends UrlCheckoutParamsType>(
  params: T
) => {
  const requestParamsKeys = [
    'hotelId',
    'checkIn',
    'checkOut',
    'rooms',
    'providerCode',
    'oid',
    'baseRate',
    'bundleId',
    'localTax',
    'tax'
  ] as const

  const filteredParams: RoomsRequestType = Object.assign(
    {},
    ...requestParamsKeys.map(key => ({[key]: params[key]}))
  )

  return filteredParams
}

export default getRoomsRequestParams
