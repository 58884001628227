import React, {useEffect, useMemo} from 'react'
import {Virtuoso} from 'react-virtuoso'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import differenceInMonths from 'date-fns/differenceInMonths'
import startOfMonth from 'date-fns/startOfMonth'
import {once} from 'ramda'

interface Props {
  checkInDate?: Date
  initialMonth: Date
}

const OpacityWrapper = styled.div<{isMounted: boolean}>(
  ({isMounted}) => css`
    height: 100%;
    opacity: ${isMounted ? 1 : 0};
  `
)

/**
 * A virtualized list of months, to be used to improve performance
 * when rendering a large number of months.
 *
 * Be aware that this list is not working correctly on iOS devices,
 * but it's a great improvement on Android phones.
 */
export const VirtualizedMonths: React.FC<Props> = ({
  children,
  checkInDate,
  initialMonth
}) => {
  const [isMounted, setIsMounted] = React.useState(false)

  /**
   * Hiding the list at the start, and showing it:
   * - Immediately if the initial index is 0
   * - After the list is scrolled to the initial index
   * Without doing this, the list would flicker when it's first rendered.
   */
  const showList = useMemo(
    () =>
      once(() => {
        setIsMounted(true)
      }),
    []
  )

  const initialIndex = checkInDate
    ? differenceInMonths(checkInDate, startOfMonth(initialMonth))
    : 0
  useEffect(() => {
    if (initialIndex === 0) {
      showList()
    }
  }, [])

  const months = React.Children.toArray(children)
  return (
    <OpacityWrapper isMounted={isMounted}>
      <Virtuoso
        increaseViewportBy={100}
        initialTopMostItemIndex={initialIndex}
        onScroll={showList}
        data={months}
        itemContent={index => months[index]}
      />
    </OpacityWrapper>
  )
}
