// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPlane2 = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.142 4.311A.75.75 0 0 1 7.75 4h.722a2.75 2.75 0 0 1 1.887.75L12.565 7h2.685A2.75 2.75 0 0 1 18 9.75v1.5a.75.75 0 0 1-.75.75h-4.731l-4.098 3.373A2.75 2.75 0 0 1 6.673 16H4.75a.75.75 0 0 1-.6-1.2l2.1-2.8h-.5A3.75 3.75 0 0 1 2 8.25v-2.5a.75.75 0 0 1 1.248-.56L5.285 7H7.71l-.67-2.013a.75.75 0 0 1 .103-.676ZM9.29 7h1.173l-1.16-1.183a1.25 1.25 0 0 0-.498-.272L9.29 7Zm-1.166 5L6.25 14.5h.423c.29 0 .571-.1.795-.285L10.158 12H8.126ZM3.5 7.42v.83a2.25 2.25 0 0 0 2.25 2.25H16.5v-.75c0-.69-.56-1.25-1.25-1.25H5a.75.75 0 0 1-.498-.19L3.5 7.42Z"
    />
  </svg>
)

export default SvgPlane2
