// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgUserCircle = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.5a6.5 6.5 0 0 0-5.023 10.625A6.981 6.981 0 0 1 10 12c1.971 0 3.752.815 5.024 2.125A6.5 6.5 0 0 0 10 3.5Zm3.944 11.667A5.482 5.482 0 0 0 10 13.5a5.482 5.482 0 0 0-3.944 1.667A6.472 6.472 0 0 0 10 16.5c1.483 0 2.85-.497 3.944-1.333ZM2 10a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm8-3.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM7 8a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z"
    />
  </svg>
)

export default SvgUserCircle
