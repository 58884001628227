import {Params} from '../getConfig'

export const amplifyEtripProduction: Params = {
  aws_user_pools_id: 'eu-west-1_ZJ1HZg9RJ',
  aws_cognito_identity_pool_id:
    'eu-west-1:615f5ff6-d962-4277-b750-6b18e094f5a9',
  aws_user_pools_web_client_id: '32r2k9slupuedq9m2mjdjr0re3',
  oauth_domain: 'etrip-auth-production.auth.eu-west-1.amazoncognito.com',
  aws_user_files_s3_bucket: 'etrip-profile-pictures-prod'
}
