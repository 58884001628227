// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgParty = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.22 2.22a.75.75 0 0 1 1.06 0l.129.128a3.75 3.75 0 0 1 0 5.304l-.129.128a.75.75 0 1 1-1.06-1.06l.128-.129a2.25 2.25 0 0 0 0-3.182l-.128-.129a.75.75 0 0 1 0-1.06ZM9 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM7.598 7.565c.013-.016.213-.197.931.121.649.288 1.454.895 2.224 1.783.767.885 1.306 1.828 1.568 2.614.131.394.184.72.179.965-.006.247-.067.351-.098.387-.013.016-.213.197-.931-.121-.65-.288-1.454-.894-2.224-1.783-.767-.885-1.306-1.827-1.568-2.614-.131-.394-.184-.72-.179-.965.006-.247.067-.351.098-.387Zm4.288.921c-.87-1.004-1.845-1.77-2.748-2.17-.834-.37-1.959-.558-2.674.267-.08.092-.148.19-.205.294a.756.756 0 0 0-.047.084L2.2 15.464c-.73 1.45.74 3.02 2.232 2.403l8.42-2.972a.764.764 0 0 0 .095-.041c.215-.093.415-.234.59-.437.334-.385.453-.873.463-1.337.01-.466-.088-.97-.255-1.471-.335-1.005-.985-2.116-1.858-3.123Zm-1.782 5.788c-.674-.43-1.36-1.032-1.99-1.76-.717-.827-1.284-1.724-1.649-2.574l-1.118 2.369c.251.408.553.822.9 1.223.553.638 1.13 1.136 1.657 1.473l.044.03 2.156-.761Zm-6.553 1.84 1.054-2.232c.159.213.329.425.509.632.364.42.745.797 1.13 1.122l-2.334.824a.722.722 0 0 0-.046.018.25.25 0 0 1-.323-.343l.01-.021Zm12.272-4.877a.25.25 0 0 1 .354 0l.293.293a.75.75 0 0 0 1.06-1.06l-.293-.293a1.75 1.75 0 0 0-2.474 0l-.293.293a.75.75 0 0 0 1.06 1.06l.293-.292Z"
    />
  </svg>
)

export default SvgParty
