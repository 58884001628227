// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCheckmark = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.267 5.206c.302.282.312.75.02 1.043l-8.44 8.525a.771.771 0 0 1-.56.226.77.77 0 0 1-.553-.244l-3.039-3.279a.723.723 0 0 1 .057-1.042.776.776 0 0 1 1.072.055l2.493 2.69 7.876-7.954a.776.776 0 0 1 1.074-.02Z"
    />
  </svg>
)

export default SvgCheckmark
