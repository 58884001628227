import {getCookie, setCookie} from '@daedalus/core/src/_web/utils/cookies'

const KEY_NAME = 'FH.experimentsToggles'

export const getForcedToggles = () => {
  try {
    const cookieValue = getCookie(KEY_NAME)
    return cookieValue ? JSON.parse(cookieValue) : {}
  } catch {
    console.warn(
      "Problem with getting FH_experiments from localstorage, it's either empty of impossible to be parsed"
    )
    return {}
  }
}

export const setValue = (name, value) => {
  const toggles = getForcedToggles()
  const updatedToggles = {
    ...toggles,
    [name]: value
  }
  saveToggles(updatedToggles)
  return updatedToggles
}

export const saveToggles = toggles => {
  //getting 2nd lvl with TLD to set it as cookie domain for shareability between the apps (f.e. "vio.com")
  const domain = window.location.hostname.split('.').slice(-2).join('.')
  setCookie(KEY_NAME, JSON.stringify(toggles), {expires: 7, domain}) // Set cookie to expire in 7 days
  return toggles
}

export const deleteToggle = name => {
  const toggles = getForcedToggles()
  if (name in toggles) {
    delete toggles[name]
    saveToggles(toggles)
  }

  return toggles
}

const availableValues = new Set(['a', 'b'])

export const applyToggles = toggles => {
  const forcedTogglesFromCookies = getForcedToggles()

  const togglesToAdd = Object.keys(forcedTogglesFromCookies).reduce(
    (acc, cur) => {
      const key = cur
      const val = forcedTogglesFromCookies[key]
      if (availableValues.has(val)) {
        acc[key] = val
      }
      return acc
    },
    {}
  )

  return {
    ...togglesToAdd,
    ...toggles
  }
}
