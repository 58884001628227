/* eslint-disable fp/no-mutation,fp/no-mutating-methods */
import {ClientOptions, ProfileKey, sapi, SapiClient} from '@findhotel/sapi'

const SAPI_CLIENT_TIMEOUT = 1500
const SAPI_CLIENT_POLL = 50

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

let clientPromise: Promise<SapiClient>
/**
 * Key used to reinitialize the clientPromise if the parameters change
 */
let clientCacheKey: string
const FIXED_KEY = 'FIXED_KEY'

/**
 * Initialize a SAPI client and store it in the module scope to be retrieved asynchronously (potentially outside react)
 * SAPI SDK expects to only have one client per app, so we use this module to act as a singleton
 *
 * @param profileKey - Profile key for customer identification
 * @param clientOptions - Options for initializing SAPI client
 * @param allowResetInstance - Boolean to allow SAPI singleton to be reset if the options change. Default: `false`
 * @param onReset - Callback that will be called whenever the client is reset, use this to reset your existing state if needed
 *
 * @returns Promise which resolves to SAPI client
 */
export const initSapiClient = async (
  profileKey: ProfileKey,
  options: ClientOptions,
  allowResetInstance = false,
  onReset?: () => void
): Promise<SapiClient> => {
  const key = allowResetInstance
    ? JSON.stringify(options)
    : clientCacheKey || FIXED_KEY // If another consumer DOES use reset, reuse it to avoid flipping between keys at every render (= infinite loop)

  if (clientPromise && clientCacheKey === key) return clientPromise
  const hadExistingClient = !!clientCacheKey

  clientPromise = sapi(profileKey, options)
  clientCacheKey = key

  if (hadExistingClient) onReset?.()

  return clientPromise
}

/**
 * Get the SAPI client that was initialized asynchronously
 */
export const getSapiClient = async (): typeof clientPromise => {
  // Is initializing / already initialized
  if (clientPromise) return clientPromise

  // Prevent silent hang by timing out, this can happen when you forget to initialize SAPI (eg. sc-92616)
  const timeout = setTimeout(() => {
    throw new Error('get SAPI client timeout')
  }, SAPI_CLIENT_TIMEOUT)

  // Poll for promise of client
  while (!clientPromise) await sleep(SAPI_CLIENT_POLL)
  clearTimeout(timeout)
  return clientPromise
}
