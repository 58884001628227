// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgQuote = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.75 2A2.75 2.75 0 0 0 2 4.75v1.5A2.75 2.75 0 0 0 4.75 9h.5v2.25a.75.75 0 0 0 1.259.551l2.285-2.109A3.75 3.75 0 0 0 10 6.937V4.75A2.75 2.75 0 0 0 7.25 2h-2.5ZM3.5 4.75c0-.69.56-1.25 1.25-1.25h2.5c.69 0 1.25.56 1.25 1.25v2.187a2.25 2.25 0 0 1-.724 1.653l-1.026.947V8.25A.75.75 0 0 0 6 7.5H4.75c-.69 0-1.25-.56-1.25-1.25v-1.5ZM12.75 8A2.75 2.75 0 0 0 10 10.75v1.5A2.75 2.75 0 0 0 12.75 15h.5v2.25a.75.75 0 0 0 1.259.551l2.285-2.109A3.75 3.75 0 0 0 18 12.937V10.75A2.75 2.75 0 0 0 15.25 8h-2.5Zm-1.25 2.75c0-.69.56-1.25 1.25-1.25h2.5c.69 0 1.25.56 1.25 1.25v2.187a2.25 2.25 0 0 1-.724 1.653l-1.026.947V14.25a.75.75 0 0 0-.75-.75h-1.25c-.69 0-1.25-.56-1.25-1.25v-1.5Z"
    />
  </svg>
)

export default SvgQuote
