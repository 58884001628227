// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgBackpack = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75 3.5a1.25 1.25 0 0 0-1.084.627 7.036 7.036 0 0 1 2.668 0A1.25 1.25 0 0 0 10.25 3.5h-.5Zm0-1.5a2.75 2.75 0 0 0-2.749 2.673 7 7 0 0 0-4 6.327v5a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-5a7 7 0 0 0-4.002-6.327A2.75 2.75 0 0 0 10.25 2h-.5ZM10 5.5A5.5 5.5 0 0 0 4.5 11v5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-5A5.5 5.5 0 0 0 10 5.5Zm-2.25 6a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5h-4.5Z"
    />
  </svg>
)

export default SvgBackpack
