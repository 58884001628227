// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgMedal = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 32 32"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <g clipPath="url(#medal_svg__a)">
      <path
        d="M15.133 11.037 11 1H2l5.849 14.205M16.867 11.037 21 1h9l-5.85 14.205"
        stroke="#0F0E0F"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M16 31c5.523 0 10-4.477 10-10s-4.477-10-10-10S6 15.477 6 21s4.477 10 10 10Z"
        stroke="#0F0E0F"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="m16 16.058 1.607 3.254 3.59.52-2.598 2.534.613 3.576L16 24.254l-3.211 1.688.613-3.576-2.598-2.533 3.59-.521L16 16.058Z"
        fill="#0F0E0F"
      />
    </g>
    <defs>
      <clipPath id="medal_svg__a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgMedal
