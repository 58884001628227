// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgSafe = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.75 2A2.75 2.75 0 0 0 2 4.75v8.5A2.75 2.75 0 0 0 4.75 16h.5v1.25a.75.75 0 0 0 1.5 0V16h6.5v1.25a.75.75 0 0 0 1.5 0V16h.5A2.75 2.75 0 0 0 18 13.25v-8.5A2.75 2.75 0 0 0 15.25 2H4.75ZM3.5 4.75c0-.69.56-1.25 1.25-1.25h10.5c.69 0 1.25.56 1.25 1.25v8.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-8.5ZM7.5 9a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0ZM10 5a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
    />
  </svg>
)

export default SvgSafe
