import React from 'react'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'
import {SizesType} from 'types/Sizes'

import {cssTheme} from '../../../themes'
import {linariaMq} from '../../../utils/breakpoints'
import {getLinariaClassName} from '../../../utils/getLinariaClassName'
import {NoStyleButton} from '../helpers/NoStyleButton'
import {Icon} from '../Icon'

type StepperSize = Extract<SizesType, 'sm' | 'lg'>

interface Props {
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** ID of this component, passed down by the controlling parent component */
  id: string
  /** The maximum value allowed */
  max?: number
  /** The minimum value allowed */
  min?: number
  /** Name of this component, passed down by the controlling parent component */
  name: string
  /** An onChange callback passed down by the controlling parent component */
  onChange: (value: number) => void
  /** The value passed down by the controlling parent component */
  value: number
  /** The size of the component */
  size?: StepperSize
}

const StepperContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StepperButton = styled(NoStyleButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  width: 45px;
  margin: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
  background: ${cssTheme.colors.stepper.default.background} none;
  border: ${cssTheme.layout.borderWidth.bw100} solid
    ${cssTheme.colors.stepper.default.border};
  color: ${cssTheme.colors.stepper.default.content};

  &.--size-sm {
    width: 33px;
    height: 34px;
  }

  ${linariaMq.desktopXs} {
    &:hover {
      background-color: ${cssTheme.colors.stepper.default.hover.background};
      border-color: ${cssTheme.colors.stepper.default.hover.border};
      color: ${cssTheme.colors.stepper.default.hover.content};
    }

    &:focus-visible {
      z-index: 1;
      outline: 1px solid ${cssTheme.colors.border.interactive.c950};
      outline-offset: 1px;
      background-color: ${cssTheme.colors.stepper.default.hover.background};
      color: ${cssTheme.colors.stepper.default.hover.content};
    }
  }

  &:active {
    background-color: ${cssTheme.colors.stepper.default.active.background};
    border-color: ${cssTheme.colors.stepper.default.active.border};
    color: ${cssTheme.colors.stepper.default.active.content};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${cssTheme.colors.stepper.disabled.background};
    border-color: ${cssTheme.colors.stepper.disabled.border};
    color: ${cssTheme.colors.stepper.disabled.content};
  }
  &:first-child {
    border-radius: ${cssTheme.layout.radius.md} 0 0 ${cssTheme.layout.radius.md};
    border-width: ${cssTheme.layout.borderWidth.bw100};
    [dir='rtl'] & {
      border-radius: 0 ${cssTheme.layout.radius.md} ${cssTheme.layout.radius.md}
        0;
    }
  }
  &:last-child {
    border-radius: 0 ${cssTheme.layout.radius.md} ${cssTheme.layout.radius.md} 0;
    border-width: ${cssTheme.layout.borderWidth.bw100};
    [dir='rtl'] & {
      border-radius: ${cssTheme.layout.radius.md} 0 0
        ${cssTheme.layout.radius.md};
    }
  }
`

export const InputElement = styled.input`
  background-color: ${cssTheme.colors.stepper.default.background};
  border-top: ${cssTheme.layout.borderWidth.bw100} solid
    ${cssTheme.colors.stepper.default.border};
  border-bottom: ${cssTheme.layout.borderWidth.bw100} solid
    ${cssTheme.colors.stepper.default.border};
  border-right: 0;
  border-left: 0;
  color: ${cssTheme.colors.stepper.default.text};
  outline: none;
  text-align: center;
  height: 46px;
  width: 46px;
  cursor: default;
  border-radius: 0;
  padding: 0;
  ${cssTheme.typography.text.bodyS}
  &--size-sm {
    width: 34px;
    height: 34px;
  }
`

const isInRange = (value: number, min: number, max: number) =>
  value >= min && value <= max

export const Stepper = ({
  dataId,
  id,
  min = 0,
  max = 10,
  name,
  size,
  onChange,
  value,
  className
}: Props) => {
  const removeValue = () => {
    const newValue = value - 1

    if (isInRange(newValue, min, max)) {
      onChange(newValue)
    }
  }

  const addValue = () => {
    const newValue = value + 1

    if (isInRange(newValue, min, max)) {
      onChange(newValue)
    }
  }

  return (
    <StepperContainer data-id={dataId} className={className}>
      <StepperButton
        data-id={`StepperMinus`}
        disabled={value <= min}
        onClick={removeValue}
        className={cx(`--size-${size}`)}
      >
        <Icon name="Minus" size="md" />
      </StepperButton>

      <InputElement
        readOnly
        id={id}
        name={name}
        type="text"
        value={value}
        className={cx(`${getLinariaClassName(InputElement)}--size-${size}`)}
      />

      <StepperButton
        data-id={`StepperPlus`}
        disabled={value >= max}
        onClick={addValue}
        className={cx(`--size-${size}`)}
      >
        <Icon name="Plus" size="md" />
      </StepperButton>
    </StepperContainer>
  )
}
