import {useEffect, useState} from 'react'
import {isNil} from 'ramda'

import loadScript from '../../../_web/utils/network/loadScript'
import {useIdentifyOnCustomerIo} from '../../hooks/useIdentifyOnCustomerIo'
import {Action, Category, Entity} from '../../types/Events'
import {dataDogLogEvent} from '../DataDogClient/index'

interface LoadCIOParams {
  publicUrl: string
  customerIoSiteId: string
}

enum State {
  Idle = 'Idle',
  Loading = 'Loading',
  Ready = 'Ready'
}

export const loadCustomerIo = async ({
  publicUrl,
  customerIoSiteId
}: LoadCIOParams) => {
  if (isNil(window?._cio)) {
    try {
      await loadScript(`${publicUrl}js/customerIo/index.js`, true, {
        customerIoSiteId
      })
    } catch {
      dataDogLogEvent(
        `${Category.System}_${Entity.CustomerIoScript}_${Action.Errored}`,
        {}
      )
    }
  }
}

export const InitializeCustomerIo = (CIOParams: LoadCIOParams) => {
  const [state, setState] = useState(State.Idle)

  // eslint-disable-next-line no-void
  useEffect(() => {
    const callLoad = async () => {
      if (state !== State.Idle) return
      setState(State.Loading)
      await loadCustomerIo(CIOParams)
      setState(State.Ready)
    }

    callLoad()
  }, [state, CIOParams])

  useIdentifyOnCustomerIo(state === State.Ready)

  return null
}
