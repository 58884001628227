import React, {ReactNode, useEffect, useState} from 'react'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'
import {anyPass, isEmpty, isNil} from 'ramda'
import {SizesType} from 'types/Sizes'
import {ColorPath} from 'types/Theme'

import {getFirstAndLastInitials} from '@daedalus/core/src/auth/business/authentication'
// NOTE: This component uses an Icon size not supported by Icon component
import {User} from '@findhotel/atlas-assets'

import {cssTheme} from '../../../themes'
import {getCssColorByPath} from '../../../utils/colors'
import minus from '../../../utils/minus'
import {Text, TextVariant} from '../Text'

type AvatarSize = Extract<SizesType, 'md' | 'lg' | 'xl'>

interface Props {
  /** Border color */
  borderColorPath: ColorPath
  /** Bottom icon */
  bottomIcon?: ReactNode
  /** Pass through classname to allow styles overrides */
  className?: string
  /** The size of the Avatar */
  size?: AvatarSize
  /** Appearance of avatar */
  type?: AvatarType
  /** User's picture */
  picture?: string
  /** User's name */
  name?: string
}

interface UserPhotoFallbackProps {
  size: AvatarSize
  name?: Props['name']
}

interface StandardAvatarProps {
  size: AvatarSize
  name?: Props['name']
}

interface AvatarStyles {
  borderColorValue?: string
  name?: Props['name']
  size: AvatarSize
}

export enum AvatarType {
  Picture = 'picture',
  Icon = 'icon'
}

export const avatarSizesMap = {
  md: {
    size: 32,
    iconSize: 16,
    padding: 4,
    border: 1,
    textVariant: 'titleXS'
  },
  lg: {
    size: 72,
    iconSize: 40,
    padding: 9,
    border: 2,
    textVariant: 'titleM'
  },
  xl: {
    size: 96,
    iconSize: 48,
    padding: 12,
    border: 3,
    textVariant: 'titleXL'
  }
}

const AvatarWrapper = styled.div<AvatarStyles>`
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  padding: ${({size}) => avatarSizesMap[size].padding}px;
  &.--border {
    box-shadow: inset 0 0 0 ${({size}) => avatarSizesMap[size].border}px
      ${({borderColorValue}) => borderColorValue!};
  }
`

export const PictureAvatar = styled.img<StandardAvatarProps>`
  height: ${({size}) => avatarSizesMap[size].size}px;
  width: ${({size}) => avatarSizesMap[size].size}px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
`

export const DefaultPictureWrapper = styled.div<StandardAvatarProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({size}) => avatarSizesMap[size].size}px;
  height: ${({size}) => avatarSizesMap[size].size}px;
  border-radius: 50%;
  background-color: ${cssTheme.colors.background.inverse.c950};
  color: ${cssTheme.colors.content.neutral.c000};
`

export const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: ${minus(cssTheme.layout.spacing.s200)};
`

const StandardAvatar = ({size}: StandardAvatarProps) => (
  <DefaultPictureWrapper size={size}>
    <User size={avatarSizesMap[size].iconSize} />
  </DefaultPictureWrapper>
)

const UserPhotoFallback = ({name, size}: UserPhotoFallbackProps) => {
  const initials = getFirstAndLastInitials(name || '')

  return (
    <DefaultPictureWrapper data-id="ProfilePicture" size={size}>
      <Text variant={avatarSizesMap[size].textVariant as TextVariant}>
        {initials}
      </Text>
    </DefaultPictureWrapper>
  )
}

export const getShowAvatar = ({
  type,
  name,
  picture
}: {
  type: AvatarType
  name?: string
  picture?: string
}) =>
  type === AvatarType.Icon ||
  (anyPass([isNil, isEmpty])(name) && anyPass([isNil, isEmpty])(picture))

export const Avatar = ({
  borderColorPath,
  picture,
  name,
  bottomIcon,
  size = 'md',
  type = AvatarType.Icon,
  className
}: Props) => {
  const [shouldShowFallbackAvatar, setShouldShowFallbackAvatar] = useState(true)
  const shouldShowIconAvatar = getShowAvatar({type, name, picture})
  const borderColorValue = getCssColorByPath(borderColorPath)

  useEffect(() => {
    if (!picture) return setShouldShowFallbackAvatar(true)

    setShouldShowFallbackAvatar(false)
    const image = new Image()
    image.addEventListener('error', () => setShouldShowFallbackAvatar(true))
    image.src = picture
  }, [picture])

  const AvatarElement = shouldShowIconAvatar
    ? StandardAvatar
    : shouldShowFallbackAvatar
      ? UserPhotoFallback
      : PictureAvatar

  return (
    <AvatarWrapper
      borderColorValue={borderColorValue}
      size={size}
      className={cx(className, Boolean(borderColorValue) && '--border')}
    >
      <AvatarElement
        data-id="ProfilePicture"
        className="fs-exclude"
        src={picture}
        size={size}
        alt={name}
        title={name}
        name={name}
      />
      {Boolean(bottomIcon) && <IconWrapper>{bottomIcon}</IconWrapper>}
    </AvatarWrapper>
  )
}
