import useScreenshotTracking from '../../hooks/useScreenshotTracking'

interface Props {
  /** The redux action to be dispatched */
  action?: () => void
}

/**
 *
 * This React Component is a wrapper for the {@link useScreenshotTracking} hook
 * @returns null
 * @example
 * ```typescript
 * <TrackScreenshot action={action} />
 * ```
 */
const TrackScreenshot = ({action}: Props) => {
  useScreenshotTracking(action)

  return null
}

export default TrackScreenshot
