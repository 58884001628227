// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgDimensions = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.81 3.25a.75.75 0 0 0-1.09-1.03L7.47 3.47a.75.75 0 0 0 0 1.06l1.25 1.25a.75.75 0 0 0 1.09-1.03h5.63a.75.75 0 0 0 1.09 1.03l1.25-1.25a.75.75 0 0 0 0-1.06l-1.25-1.25a.75.75 0 0 0-1.09 1.03H9.81ZM4 5a.75.75 0 0 1 .576.27l1.25 1.5A.75.75 0 0 1 4.75 7.81v7.38a.75.75 0 0 1 1.076 1.04l-1.25 1.5a.75.75 0 0 1-1.152 0l-1.25-1.5a.75.75 0 0 1 1.076-1.04V7.81a.75.75 0 0 1-1.076-1.04l1.25-1.5A.75.75 0 0 1 4 5Zm4 5.75A2.75 2.75 0 0 1 10.75 8h4.5A2.75 2.75 0 0 1 18 10.75v4.5A2.75 2.75 0 0 1 15.25 18h-4.5A2.75 2.75 0 0 1 8 15.25v-4.5Zm2.75-1.25c-.69 0-1.25.56-1.25 1.25v4.5c0 .69.56 1.25 1.25 1.25h4.5c.69 0 1.25-.56 1.25-1.25v-4.5c0-.69-.56-1.25-1.25-1.25h-4.5Z"
    />
  </svg>
)

export default SvgDimensions
