// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgWifi = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.429 6.56a11.444 11.444 0 0 1 6.57-2.06c2.445 0 4.71.762 6.572 2.06a.75.75 0 1 0 .858-1.23A12.944 12.944 0 0 0 9.999 3c-2.76 0-5.322.862-7.428 2.33a.75.75 0 1 0 .858 1.23ZM10 8.5c-1.594 0-3.07.496-4.284 1.343a.75.75 0 1 1-.857-1.23A8.962 8.962 0 0 1 10 7c1.91 0 3.684.596 5.142 1.612a.75.75 0 0 1-.858 1.23A7.462 7.462 0 0 0 10 8.5Zm-2.058 4.669a3.48 3.48 0 0 1 2.058-.67c.77 0 1.48.249 2.058.67a.75.75 0 1 0 .884-1.213A4.98 4.98 0 0 0 10 11a4.98 4.98 0 0 0-2.942.957.75.75 0 1 0 .884 1.212ZM10 14.999A1 1 0 1 0 10 17a1 1 0 0 0 0-2Z"
    />
  </svg>
)

export default SvgWifi
