// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgEmailPlus = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.75 3A2.75 2.75 0 0 0 2 5.75v8.5A2.75 2.75 0 0 0 4.75 17h4.5a.75.75 0 0 0 0-1.5h-4.5c-.69 0-1.25-.56-1.25-1.25v-8.5c0-.079.007-.155.021-.23l5.982 5.292a.75.75 0 0 0 .994 0l5.982-5.292c.014.075.021.152.021.23v3.5a.75.75 0 1 0 1.5 0v-3.5A2.75 2.75 0 0 0 15.25 3H4.75Zm10.612 1.505a1.265 1.265 0 0 0-.112-.005H4.75c-.038 0-.075.002-.112.005L10 9.25l5.362-4.744ZM15 12a.75.75 0 0 1 .75.75v1.5h1.5a.75.75 0 1 1 0 1.5h-1.5v1.5a.75.75 0 0 1-1.5 0v-1.5h-1.5a.75.75 0 0 1 0-1.5h1.5v-1.5A.75.75 0 0 1 15 12Z"
    />
  </svg>
)

export default SvgEmailPlus
