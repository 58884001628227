import {hasUnlockPlusTokenInUrl, PLUS_CUG_TYPE} from './privateDeals'

export interface TokenFromUrlParams {
  token?: string
  priceWatchId?: string
}

export const getPlusCugType = (
  isMembersPlus: boolean,
  urlParams: TokenFromUrlParams = {},
  isBrandOffersLockedByDefault = true
) =>
  isMembersPlus ||
  hasUnlockPlusTokenInUrl(urlParams) ||
  !isBrandOffersLockedByDefault
    ? [PLUS_CUG_TYPE]
    : []
