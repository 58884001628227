import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'

import {useShouldSeeOffersUnlocked} from '../hooks/useShouldSeeOffersUnlocked'
import {setShouldSeeOffersUnlocked} from '../modules/slice'

const SyncStateWithPrivilege = () => {
  const dispatch = useDispatch()
  const shouldSeeOffersUnlocked = useShouldSeeOffersUnlocked()
  const [triggerLazyEvaluation, setTriggerLazyEvaluation] = useState(false)

  useEffect(() => {
    // We're having issues with Mobile App users who seem member deals locked.
    // This timeout is to evaluate useShouldSeeOffersUnlocked() after a couple of seconds, as we suspect isReactNativeWebView() is null in the first <App /> render.
    setTimeout(() => {
      setTriggerLazyEvaluation(true)
    }, 4000)
  }, [])

  useEffect(() => {
    dispatch(setShouldSeeOffersUnlocked(shouldSeeOffersUnlocked))
  }, [dispatch, shouldSeeOffersUnlocked, triggerLazyEvaluation])

  return null
}

export default SyncStateWithPrivilege
