// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPinOnMap = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 6.95C6.5 5.053 8.059 3.5 10 3.5s3.5 1.553 3.5 3.45c0 .593-.209 1.242-.573 1.913-.362.667-.854 1.311-1.362 1.882A16.779 16.779 0 0 1 10 12.28a16.785 16.785 0 0 1-1.565-1.534c-.508-.57-1-1.215-1.362-1.882-.364-.67-.573-1.32-.573-1.913Zm3.044 6.895L10 13.25l-.456.596a.75.75 0 0 0 .912 0L10 13.25l.456.595h.002l.003-.003.011-.009.04-.031.145-.117a18.28 18.28 0 0 0 2.028-1.943c.555-.623 1.125-1.362 1.56-2.163.433-.796.755-1.697.755-2.63C15 4.209 12.753 2 10 2S5 4.209 5 6.95c0 .933.322 1.834.755 2.63.435.8 1.005 1.54 1.56 2.163a18.278 18.278 0 0 0 2.173 2.06l.04.031.011.009.004.002v.001h.001Zm-5.056-1.96a.75.75 0 0 0-1.476-.27l-.786 4.322A1.75 1.75 0 0 0 3.948 18h12.104a1.75 1.75 0 0 0 1.722-2.063l-.786-4.321a.75.75 0 1 0-1.476.268l.786 4.321a.25.25 0 0 1-.246.295H3.948a.25.25 0 0 1-.246-.295l.786-4.32ZM10 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
    />
  </svg>
)

export default SvgPinOnMap
