import {dissocPath, isNil, omit} from 'ramda'

import {capitalizeFirstLetter} from '../../utils/string'
import {
  AnalyticsContext,
  TrackEventPayload,
  TrackEventProperties
} from '../types/Events'

export const createDataPipelineEventName = (
  trackEventPayload: TrackEventPayload
) => `${capitalizeFirstLetter(String(trackEventPayload.category))}Event`

export const increasePositionIndexByOne = (position?: number) => {
  if (isNil(position) || position === -1) return null
  return position + 1
}

export const removeSearchResultsContext = (
  trackEventProperties: TrackEventProperties
): TrackEventPayload => {
  return dissocPath(
    ['analyticsContext', AnalyticsContext.SearchResultsContext],
    trackEventProperties
  )
}

export const removeAllAnalyticsContexts = (
  trackEventProperties: TrackEventProperties
): TrackEventPayload => {
  return omit(['analyticsContext'], trackEventProperties)
}
