import {assocPath, filter, head, pathOr, propEq} from 'ramda'

import {RoomPrice} from '@findhotel/sapi'

import {
  HotelFeeBreakdownType,
  HotelFeesBreakdown,
  OfferPrice
} from '../../../../offer/types/offer'
import PriceTypes from '../../../../offer/types/PriceTypes'
import {
  getNightlyPrice,
  isThereACurrencyConversion
} from '../../../../price/business/price'

type DefaultValueType = any

type GetPriceByTypePropsType = (
  type: string,
  prices: OfferPrice[],
  propertyPath?: string[],
  defaultValue?: DefaultValueType
) => OfferPrice | DefaultValueType

export const nightlyDisplayTotalPath = ['chargeable', 'nightlyDisplayTotal']

export const basePath = ['chargeable', 'base']
export const nightlyBasePath = ['chargeable', 'nightlyBase']

export const taxesPath = ['chargeable', 'taxes']
export const nightlyTaxesPath = ['chargeable', 'nightlyTaxes']

export const hotelFeesPath = ['hotelFees', 'total']

export const hotelFeesNightlyPath = ['hotelFees', 'nightly']
export const hotelFeesBreakdownPath = ['hotelFees', 'breakdown']

export const getHotelFeesBreakdown = pathOr<HotelFeesBreakdown>(
  [],
  hotelFeesBreakdownPath
)

export const getPriceByType: GetPriceByTypePropsType = (
  type,
  prices,
  propertyPath = [],
  defaultValue = null
) =>
  pathOr(defaultValue, [...propertyPath])(
    head(filter(propEq('type', type), prices))
  )

/** Converts price to float number */
export const convertPriceToFloat = (updatePath: string[], price: RoomPrice) => {
  const convertedPrice = Number.parseFloat(pathOr('', updatePath, price))
  return assocPath(updatePath, convertedPrice, price)
}

/**
 * Calculates the display rate of a room based on the tax display logic
 * @constructor
 * @param {number} baseRate - the hotel base rate
 * @param {number} taxes - the hotel price taxes
 * @param {number} localTaxes - the fees charged at the hotel
 * @param {boolean} includeTaxes - If should include taxes on the price
 * @param {boolean} includeLocalTaxes - If should include local taxes such as tourist taxes on the price
 * @param {number} numberOfNights - Number of nights of the offer
 * @param {number} numberOfRooms - Number of rooms of the offer
 */
export const calculateDisplayTotal = (
  baseRate: number,
  taxes: number | null | undefined,
  localTaxes: number | null | undefined,
  includeTaxes: boolean,
  includeLocalTaxes: boolean,
  numberOfNights: number,
  numberOfRooms: number
): number | null => {
  let totalRate = baseRate
  if (includeTaxes && taxes) {
    totalRate += taxes
  }

  if (includeLocalTaxes && localTaxes) {
    totalRate += localTaxes
  }

  return getNightlyPrice(totalRate, numberOfNights, numberOfRooms)
}

/**
Returns the price the user sees in checkout rounded.
 */
export const getNightlyDisplayTotalPrice = (prices: OfferPrice[]): number => {
  const type = isThereACurrencyConversion(prices)
    ? PriceTypes.DISPLAY_PRICE
    : PriceTypes.CHARGEABLE_PRICE

  return getPriceByType(type, prices, nightlyDisplayTotalPath, null)
}

/**
 * This function will verify if the chargeable and hotelFees portion of the price
 * object have each their own currencyCode. If they don't have, we will add the one from the root of the
 * object. Future changes in the API will bring this information making this function obsolete.
 * When 003bfcb2-pay-at-property-chargeable-currency-ui is complete we can remove this function
 * @param price an offer price object
 * @example
 * // This
 * {
      "chargeable": {
        "base": "378.00",
        "taxes": "49.14",
        "total": "427.14"
      },
      "currencyCode": "USD",
      "hotelFees": {
        "breakdown": [
          {
            "total": "88.14",
            "type": "resort_fee"
          }
        ],
        "total": "88.14"
      },
      "type": "chargeable_currency"
    }

    // becomes this
    {
      "chargeable": {
        "base": "378.00",
        "taxes": "49.14",
        "total": "427.14",
        "currencyCode": "USD",
      },          
      "hotelFees": {
        "breakdown": [
          {
            "total": "88.14",
            "type": "resort_fee"
          }
        ],
        "total": "88.14",
        "currencyCode": "USD"
      },
      "type": "chargeable_currency"
    }
 */
export const addCurrencyToChargeableAndHotelFees = (
  price: OfferPrice
): OfferPrice => {
  const baseCurrency = price?.currencyCode
  const chargeableCurrency = price.chargeable?.currencyCode
  const hotelFeesCurrency = price.hotelFees?.currencyCode

  let newPrice = {...price}

  if (!chargeableCurrency) {
    newPrice = assocPath(['chargeable', 'currencyCode'], baseCurrency, newPrice)
  }

  if (!hotelFeesCurrency) {
    newPrice = assocPath(['hotelFees', 'currencyCode'], baseCurrency, newPrice)
  }

  return newPrice
}

/**
 * SAPI returns no breakdown at the moment this function is being introduced (21/10/2021)
 * To avoid changing the way we calculate and display hotel fees we are picking up the total
 * and creating a generic breakdown.
 *
 * @param price an offer price object
 * @returns the price decorated with the breakdown
 */
export const guaranteeAtLeastOneHotelFeeBreakdown = (
  price: OfferPrice
): OfferPrice => {
  if (price.hotelFees?.breakdown?.length === 0 && price.hotelFees.total !== 0) {
    return {
      ...price,
      hotelFees: {
        ...price.hotelFees,
        breakdown: [
          {
            total: price.hotelFees.total,
            type: 'other' as HotelFeeBreakdownType
          }
        ]
      }
    }
  }

  return price
}

export const convertHotelFeesBreakdownToFloat = (price: RoomPrice) => {
  const hotelFeesBreakdown = getHotelFeesBreakdown(price)
  const convertedHotelFeesBreakdown = hotelFeesBreakdown.map(fee =>
    assocPath(['total'], Number.parseFloat(fee.total as string), fee)
  )

  return assocPath(hotelFeesBreakdownPath, convertedHotelFeesBreakdown, price)
}

export const decorateWithNightlyDisplayTotal = (
  price: OfferPrice,
  numberOfNights: number,
  numberOfRooms: number,
  includeTaxes: boolean,
  includeLocalTaxes: boolean
) => {
  const nightlyDisplayTotal = calculateDisplayTotal(
    pathOr(0, basePath, price),
    pathOr(0, taxesPath, price),
    pathOr(0, hotelFeesPath, price),
    includeTaxes,
    includeLocalTaxes,
    numberOfNights,
    numberOfRooms
  )

  return assocPath(nightlyDisplayTotalPath, nightlyDisplayTotal, price)
}

export const decorateWithNightlyPrice = (
  updatePath: string[],
  totalPath: string[],
  price: RoomPrice,
  numberOfNights: number,
  numberOfRooms: number
) => {
  const nightlyPrice = getNightlyPrice(
    pathOr(null, totalPath, price),
    numberOfNights,
    numberOfRooms
  )
  return assocPath(updatePath, nightlyPrice, price)
}
