import React from 'react'
import {defineMessages, useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {toggle} from 'opticks'

import {Text} from '@daedalus/atlas/Text'
import {getAuthenticationFlow} from '@daedalus/core/src/auth/modules/selectors'
import {AuthenticationFlows} from '@daedalus/core/src/auth/types/Auth'

const BottomSheetTitle = styled.div(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.layout.spacing.s100}px;
    text-align: center;
  `
)

const DescriptionBox = styled.div<{fullWidth: boolean}>(
  ({fullWidth}) => css`
    max-width: ${fullWidth ? 'auto' : '240px'};
  `
)
const titles = defineMessages({
  default: {
    id: 'magicLinkModal.title.memberDeals',
    defaultMessage: 'Unlock member deals for FREE!'
  },
  [AuthenticationFlows.DealFreeze]: {
    id: 'susi.title.dealFreeze',
    defaultMessage: 'Freeze this price for free'
  },
  vioDeal: {
    id: 'vioDeals.unlock',
    defaultMessage: 'Unlock Vio deals'
  }
})

const descriptions = defineMessages({
  default: {
    id: 'magicLinkModal.unlockDetailsDesktop.joinMembers',
    defaultMessage:
      'Join over 2,000,000 members who are saving on every booking'
  },
  [AuthenticationFlows.DealFreeze]: {
    id: 'susi.description.dealFreeze',
    defaultMessage:
      "Lock in the current price and complete your booking when you're ready."
  },
  vioDeal: {
    id: 'vioDeals.getFreeAccess',
    defaultMessage:
      'Sign up to Vio.com to get free access to insider hotel deals and save up to 45% on your stays.'
  }
})
export const SignInTitle = () => {
  const {formatMessage} = useIntl()
  const authenticationFlow = useSelector(getAuthenticationFlow)
  const isDealFreezeFlow = authenticationFlow === AuthenticationFlows.DealFreeze

  const title = formatMessage(
    isDealFreezeFlow
      ? titles[authenticationFlow]
      : toggle('c9f92dea-new-tags-poc', titles.default, titles.vioDeal)
  )
  const description = formatMessage(
    isDealFreezeFlow
      ? descriptions[authenticationFlow]
      : toggle(
          'c9f92dea-new-tags-poc',
          descriptions.default,
          descriptions.vioDeal
        )
  )

  return (
    <BottomSheetTitle>
      <Text variant="titleXL" dataId="magicLinkModal.title.memberDeals">
        {title}
      </Text>
      <DescriptionBox
        fullWidth={toggle('c9f92dea-new-tags-poc', isDealFreezeFlow, true)}
      >
        <Text>{description}</Text>
      </DescriptionBox>
    </BottomSheetTitle>
  )
}
