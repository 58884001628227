import {StoreStateType} from 'modules'
import {Middleware} from 'redux'
import createSentryMiddleware from 'redux-sentry-middleware'
import {localizationApi} from 'services/localizationApi'

import coreAnalytics from '@daedalus/core/src/analytics/modules/middleware'
import {
  removeUserData,
  removeUserProfile
} from '@daedalus/core/src/auth/modules/middleware'
import {State as MetaState} from '@daedalus/core/src/auth/modules/selectors'
import {bookApi as bookApiCore} from '@daedalus/core/src/booking/services/bookApi'
import {bookingRetrieveApi as bookingRetrieveApiCore} from '@daedalus/core/src/booking/services/bookingRetrieveApi'
import {paymentOptionsApi as paymentOptionsApiCore} from '@daedalus/core/src/booking/services/paymentOptionsApi'
import {offerCheckApi as offerCheckApiCore} from '@daedalus/core/src/offer/services/offerCheckApi'
import {offerConfigurationApi} from '@daedalus/core/src/offer/services/offerConfigurationApi'
import {searchApi as searchApiCore} from '@daedalus/core/src/sapi/services/searchApi'
import {dealFreezeMiddleware} from '@daedalus/shared/src/dealFreeze/modules/middleware'
import {dealFreezeApi as dealFreezeApiCore} from '@daedalus/shared/src/dealFreeze/services/dealFreezeApi'

import analytics from './sideEffects/analyticsV1'
import analyticsV2 from './sideEffects/analyticsV2'
import bookApi from './sideEffects/bookApi'
import retrieveBookingApi from './sideEffects/bookingRetrieveApi'
import browser from './sideEffects/browser'
import common from './sideEffects/common'
import localization from './sideEffects/localization'
import offerCheckApi from './sideEffects/offerCheckApi'
import overlay from './sideEffects/overlay'
import paymentOptions from './sideEffects/paymentOptions'
import psd2Auth from './sideEffects/psd2Auth'
import raaOffers from './sideEffects/raaOffers'
import rooms from './sideEffects/rooms'
import searchApi from './sideEffects/searchApi'

export type MiddlewareType = Middleware<StoreStateType>

const middlewareList = [
  bookApiCore.middleware,
  offerCheckApiCore.middleware,
  searchApiCore.middleware,
  dealFreezeApiCore.middleware,
  localizationApi.middleware,
  paymentOptionsApiCore.middleware,
  offerConfigurationApi.middleware,
  bookingRetrieveApiCore.middleware,
  localization,
  overlay,
  common,
  browser,
  analytics,
  analyticsV2,
  rooms,
  raaOffers,
  psd2Auth,
  paymentOptions,
  coreAnalytics,
  offerCheckApi,
  searchApi,
  bookApi,
  retrieveBookingApi,
  dealFreezeMiddleware
]

const middlewareFinalList =
  typeof Sentry === 'undefined'
    ? middlewareList
    : [
        ...middlewareList,
        createSentryMiddleware(Sentry, {
          stateTransformer: (state: MetaState) => removeUserProfile(state),
          actionTransformer: action => removeUserData(action)
        })
      ]

export default middlewareFinalList
