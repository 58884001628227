// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgShieldLock = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 3.75C2 2.784 2.784 2 3.75 2h12.5c.966 0 1.75.784 1.75 1.75V10a8 8 0 1 1-16 0V3.75Zm1.75-.25a.25.25 0 0 0-.25.25V10a6.5 6.5 0 1 0 13 0V3.75a.25.25 0 0 0-.25-.25H3.75ZM6 8.75A.75.75 0 0 1 6.75 8H7v-.25A2.75 2.75 0 0 1 9.75 5h.5A2.75 2.75 0 0 1 13 7.75V8h.25a.75.75 0 0 1 .75.75v2.5A2.75 2.75 0 0 1 11.25 14h-2.5A2.75 2.75 0 0 1 6 11.25v-2.5ZM11.5 8v-.25c0-.69-.56-1.25-1.25-1.25h-.5c-.69 0-1.25.56-1.25 1.25V8h3Zm-4 1.5v1.75c0 .69.56 1.25 1.25 1.25h2.5c.69 0 1.25-.56 1.25-1.25V9.5h-5Z"
    />
  </svg>
)

export default SvgShieldLock
