// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgFacebookMessenger = ({
  color = 'currentColor',
  size = 20
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <g clipPath="url(#facebookMessenger_svg__a)">
      <path
        d="M10 2C5.494 2 2 5.302 2 9.76c0 2.332.956 4.348 2.512 5.74.13.116.21.28.214.456l.044 1.424a.64.64 0 0 0 .898.566l1.588-.7a.643.643 0 0 1 .428-.032c.73.2 1.506.308 2.316.308 4.506 0 8-3.302 8-7.76S14.506 2 10 2Z"
        fill="url(#facebookMessenger_svg__b)"
      />
      <path
        d="m5.196 12.03 2.35-3.728a1.2 1.2 0 0 1 1.736-.32l1.87 1.402a.48.48 0 0 0 .578-.002l2.524-1.916c.336-.256.776.148.552.506l-2.352 3.726a1.198 1.198 0 0 1-1.736.32l-1.87-1.402a.48.48 0 0 0-.578.002l-2.524 1.916c-.336.256-.776-.146-.55-.504Z"
        fill="#fff"
      />
    </g>
    <defs>
      <radialGradient
        id="facebookMessenger_svg__b"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(17.6 0 0 17.6 4.68 18)"
      >
        <stop stopColor="#09F" />
        <stop offset={0.6} stopColor="#A033FF" />
        <stop offset={0.9} stopColor="#FF5280" />
        <stop offset={1} stopColor="#FF7061" />
      </radialGradient>
      <clipPath id="facebookMessenger_svg__a">
        <path fill="#fff" transform="translate(2 2)" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgFacebookMessenger
