// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgForbid = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 10a6.5 6.5 0 0 1 10.535-5.096l-9.131 9.131A6.473 6.473 0 0 1 3.5 10Zm2.465 5.096a6.5 6.5 0 0 0 9.131-9.131l-9.131 9.131ZM10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16Z"
    />
  </svg>
)

export default SvgForbid
