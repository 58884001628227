import {getBookingFinalizationLink} from 'business/bofhLinks'
import {logEvent} from 'services/datadogLogger'
import {
  BaseInitializationResponse,
  BoFHResponses,
  ChallengeResponse,
  FinalizationBookingResponse,
  Psd2Strategy,
  ServerCode,
  StatusCodes
} from 'services/psd2/types'
import Settings from 'Settings'

import loadScript from '@daedalus/core/src/_web/utils/network/loadScript'
import {
  BookingStatusThreeDomainSecureRequiredResponse,
  StripeThreeDomainSecureParams
} from '@daedalus/core/src/booking/types/BookingStatus'

export type StripeBookingCreationResponse =
  BookingStatusThreeDomainSecureRequiredResponse<StripeThreeDomainSecureParams>

const stripeScriptURL = 'https://js.stripe.com/v3/'

let stripe: stripe.Stripe

const _bofhResponses: BoFHResponses<StripeBookingCreationResponse> = {
  initialization: undefined,
  challenge: undefined,
  isChargeLater: false
}

// #region stripe flow
const setup = async () => {
  try {
    logEvent('services.PSD2.Stripe.LoadingScript', {
      stripeScriptURL
    })
    await loadScript(stripeScriptURL, true)
    // eslint-disable-next-line new-cap,fp/no-mutation
    stripe = Stripe(Settings.get('REACT_APP_STRIPE_API_KEY'))
  } catch (error) {
    logEvent('services.PSD2.Stripe.LoadingScriptError', {meta: error})
    console.log('error in set up')
  }
}

const initializeBooking = async (
  resp: StripeBookingCreationResponse
): Promise<BaseInitializationResponse> => {
  if (resp) _bofhResponses.initialization = resp
  logEvent('services.PSD2.Stripe.initializedBookingResponse', {
    meta: _bofhResponses.initialization
  })
  return {
    statusCode: StatusCodes.SUCCESS
  }
}

const challenge = async (): Promise<ChallengeResponse> => {
  try {
    const isChargeLater = getIsChargeLater()
    let intent
    let result
    if (isChargeLater) {
      result = await stripe.handleNextAction({
        clientSecret: getClientSecret()
      })
      intent = result?.setupIntent
    } else {
      result = await stripe.handleCardAction(getClientSecret())
      intent = result?.paymentIntent
    }
    if (intent) {
      const challengeSucceded = didChallengeSucceed(intent)
      if (challengeSucceded) {
        return {
          status: StatusCodes.SUCCESS
        }
      }
    }

    return {
      status: StatusCodes.ERROR,
      error: result?.error
    }
  } catch (error) {
    logEvent('services.PSD2.Stripe.ChallengeLoadError', {meta: error})
    return {
      status: StatusCodes.ERROR,
      error
    }
  }
}

const finalizeBooking = async (): Promise<FinalizationBookingResponse> => {
  const params = _bofhResponses.initialization
  const bookingFinalizationLink = getBookingFinalizationLink(params?.links)

  logEvent('services.PSD2.Stripe.finalizeBooking', {
    meta: {bookingFinalizationLink}
  })

  return {
    success: true,
    link: bookingFinalizationLink
  }
}
// #endregion

const getClientSecret = () => {
  return (
    _bofhResponses?.initialization?.threeDomainSecure?.params?.clientSecret ||
    ''
  )
}
const getIsChargeLater = () => _bofhResponses.isChargeLater

const isEnrolled = (
  resp: StripeBookingCreationResponse,
  isChargeLater: boolean = false
) => {
  _bofhResponses.initialization = resp
  _bofhResponses.isChargeLater = isChargeLater
  return (
    resp?.status === 'three_domain_secure_required' ||
    (resp?.threeDomainSecure?.enrolled &&
      resp?.threeDomainSecure.serverCode === ServerCode.Stripe)
  )
}

const didChallengeSucceed = (paymentIntent: {status: string}) => {
  if (!paymentIntent) return false
  return (
    paymentIntent.status === 'requires_capture' ||
    paymentIntent.status === 'succeeded' ||
    paymentIntent.status === 'requires_confirmation'
  )
}

const Psd2: Psd2Strategy<void, StripeBookingCreationResponse> = {
  setup,
  initializeBooking,
  finalizeBooking,
  challenge,
  isEnrolled,
  serverCode: ServerCode.Stripe
}

export default Psd2
