// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgSmokingRoom = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="M5.15 7a.49.49 0 0 1 .36-.22H6a2.345 2.345 0 1 0 0-4.69h-.84a1.73 1.73 0 0 0-1.83 1.7l-.23.1A1.57 1.57 0 0 0 2 5.45V7a.75.75 0 0 0 .75.75h1A1.71 1.71 0 0 0 5.15 7Zm-1.24-.89a.22.22 0 0 1-.18.1h-.21v-.86l.19-.09a1.49 1.49 0 0 0 1.12-1.43c0-.07 0-.24.33-.24H6a.83.83 0 1 1 0 1.65h-.49a2 2 0 0 0-1.6.87ZM2.77 8.81a.75.75 0 0 0-.77.75v3a.75.75 0 1 0 1.5 0v-3a.76.76 0 0 0-.73-.75ZM17.23 8.81H5.16a.76.76 0 0 0-.75.75v3a.75.75 0 0 0 .75.75h12.07a.74.74 0 0 0 .75-.75v-3a.75.75 0 0 0-.75-.75Zm-.75 3H5.91v-1.5h10.57v1.5Z" />
  </svg>
)

export default SvgSmokingRoom
