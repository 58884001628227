import {LogLevel, logToDataDog} from 'services/datadogLogger'

export enum RAAServiceStatus {
  start = 'Start',
  received = 'Received',
  done = 'Done',
  error = 'Error',
  fatalError = 'FatalError'
}

export enum RAAServiceEndpointNames {
  Search = 'Search'
}

const logRAAService = (
  endpoint: RAAServiceEndpointNames,
  status: RAAServiceStatus,
  metadata?,
  level?: LogLevel
) => {
  logToDataDog({
    logName: `Services.RAA.${endpoint}.${status}`,
    metadata: {metadata},
    level
  })
}

export default logRAAService
