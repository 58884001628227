// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgInstagram = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 5.75A3.75 3.75 0 0 1 5.75 2h8.5A3.75 3.75 0 0 1 18 5.75v8.5A3.75 3.75 0 0 1 14.25 18h-8.5A3.75 3.75 0 0 1 2 14.25v-8.5ZM5.75 3.5A2.25 2.25 0 0 0 3.5 5.75v8.5a2.25 2.25 0 0 0 2.25 2.25h8.5a2.25 2.25 0 0 0 2.25-2.25v-8.5a2.25 2.25 0 0 0-2.25-2.25h-8.5ZM13 6a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-5.5 5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0ZM10 7a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
    />
  </svg>
)

export default SvgInstagram
