// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgLocation = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.773.212a.75.75 0 0 1 .161.845l-6.5 14.5a.75.75 0 0 1-1.348.041l-2.627-5.014L.455 8.44a.75.75 0 0 1-.031-1.364l14.5-7a.75.75 0 0 1 .849.137ZM2.559 7.71l3.736 1.602a.75.75 0 0 1 .37.34l2.033 3.883 5.027-11.215L2.559 7.71Z"
    />
  </svg>
)

export default SvgLocation
