import dompurify from 'dompurify'

/**
 * Capitalize first letter
 *
 * @param string - String you'd like to capitalize the first letter
 *
 * @returns String with the first letter capitalized
 */
export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

/**
 * Converts kebab-case string to camelCase
 *
 * @param string - string in kebab-case format
 *
 * @returns string in camelCase format
 *
 * @example
 * kebabCaseToCamelCase('kebab-case') // 'kebabCase'
 * kebabCaseToCamelCase('kebab-case-string') // 'kebabCaseString'
 */
export const kebabCaseToCamelCase = (string: string) =>
  string.replace(/(-\w)/g, m => m[1].toUpperCase())

/**
 * Sanitizes passed html string from XSS
 *
 * @param html - HTML code you'd like to sanitize
 *
 * @returns {string} Sanitized safe HTML
 */
export const sanitizeHtml = (html: string) => {
  return dompurify.sanitize(html)
}

/**
 * Converts color format from HEX string to [R, G, B] array
 *
 * @param string - string describes color in HEX format, e.g. #ff44ed
 *
 * @returns {number[]} Array of R, G and B
 */
export const hexToRGB = (hex: string): number[] => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? [
        Number.parseInt(result[1], 16),
        Number.parseInt(result[2], 16),
        Number.parseInt(result[3], 16)
      ]
    : []
}

/**
 * Converts color format from HEX string to "R,G,B" string
 *
 * @param string - string describes color in HEX format, e.g. #ff44ed
 *
 * @returns {string} - string containing R, G and B divided by comma
 */
export const hexToRGBString = (hex: string): string => hexToRGB(hex).join(',')

/**
 * Converts color format in the following way:
 * - from HEX string to "R,G,B,A" string applying the given opacity
 * - from CSS variable to "color-mix(in srgb, <COLOR>, <OPACITY_%>, transparent)"
 *
 * @param Color - {@link Color} object containing HEX format color (e.g. #ff44ed) and the opacity for the color
 *
 * @returns A `string` containing R, G, B and, A divided by comma or color-mix function
 */
export const hexToRGBAString = (
  color: string,
  opacity: number | string
): string => {
  if (color.startsWith('var')) {
    // we're using CSS variables here
    return `color-mix(in srgb, ${color} calc(${opacity} * 100%), transparent)`
  }

  const rgbValues = hexToRGB(color)

  if (rgbValues.length === 3) {
    const [r, g, b] = rgbValues
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  }

  return ''
}

/* In case the URL locale is 'ar' the ConfigureI18n component will
 * change it to 'ar-u-nu-latn' to be able to use  latin numbers. In this case
 * we need to send the original 'ar' locale to Sapi because RAA doesn't recognise
 * the updated locale as a valid language.
 */
export const mapLatinNumbersSystemToArabic = (locale: string) =>
  locale === 'ar-u-nu-latn' ? 'ar' : locale

/**
 * Splits the provided string at the last occurrence of the provided delimiter
 * and returns the two parts as an array.
 * If the delimiter is not found, the original string is returned as the first element
 * If the provided string is falsy, an empty array is returned.
 *
 * @param string - The string to split.
 * @param delimiter - The delimiter to split the string at.
 * @returns An array containing the two parts of the split string.
 */
export const splitAtLastDelimiter = (
  string: string | undefined,
  delimiter: string
): string[] => {
  if (!string) return []

  const indexOfLastDelimiter = string.lastIndexOf(delimiter)

  if (indexOfLastDelimiter === -1) {
    return [string]
  }

  const firstPart = string.slice(0, indexOfLastDelimiter)
  const secondPart = string.slice(
    indexOfLastDelimiter + delimiter.length,
    string.length
  )

  return [firstPart, secondPart]
}
