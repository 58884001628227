import {LogLevel, logToDataDog} from 'services/datadogLogger'

export enum PSD2ServiceStatus {
  start = 'Start',
  success = 'Success',
  error = 'Error'
}

export enum PSD2ServiceStep {
  Initialize = 'Initialize',
  Challenge = 'Challenge'
}

const logPSD2Service = (
  step: PSD2ServiceStep,
  status?: PSD2ServiceStatus,
  metadata?,
  level?: LogLevel
) => {
  let logName = `Services.PSD2.${step}`

  if (status) {
    logName += `.${status}`
  }

  logToDataDog({
    logName,
    metadata: {metadata},
    level
  })
}

export default logPSD2Service
