// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgWorld = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.5c-.698 0-1.37.11-2 .314V7.25A3.75 3.75 0 0 1 4.25 11h-.674a6.508 6.508 0 0 0 3.84 4.966A8.042 8.042 0 0 0 8 13c.001-.7.207-1.228.599-1.632.286-.296.665-.502.943-.654l.128-.07c.318-.18.585-.354.816-.631.228-.274.459-.698.607-1.42C11.363 7.27 12.439 6 14 6h1.124A6.489 6.489 0 0 0 10 3.5Zm6.002 4H14c-.649 0-1.266.553-1.439 1.394-.188.92-.51 1.581-.922 2.077-.41.493-.87.775-1.236.98l-.177.1c-.287.159-.435.241-.552.361-.08.082-.174.214-.174.588a9.541 9.541 0 0 1-.641 3.4 6.5 6.5 0 0 0 7.143-8.9Zm-12.483 2a6.497 6.497 0 0 1 2.98-4.978V7.25A2.25 2.25 0 0 1 4.25 9.5h-.73ZM2 10a8 8 0 1 1 16 0 8 8 0 0 1-16 0Z"
    />
  </svg>
)

export default SvgWorld
