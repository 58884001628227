import {RefObject, useEffect, useState} from 'react'
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock-upgrade'

// @Todo change to BODY_SCROLL_LOCK_IGNORE_SELECTOR
export const BODY_SCROLL_LOCK_IGNORE = '[data-body-scroll-lock-ignore]'

interface Props {
  ref: RefObject<HTMLDivElement | undefined>
  isEnabled?: boolean
}

/**
 * Looks for attributes applied by body-scroll-lock to
 * the body element, and returns them.
 */
function getBodyData() {
  const isLocked = document.body.style.overflow === 'hidden'
  const scrollBarGap =
    Number.parseInt(document.body.style.paddingRight, 10) || 0

  return {
    isLocked,
    scrollBarGap
  }
}

/**
 * If the scroll is prevented in the body, returns `isLocked = true`
 * and `scrollBarGap` will return the width in px of the scrollbar.
 *
 * Use it to apply paddings to your floating elements such as headers.
 */
export const useBodyScrollLockObserver = () => {
  const [state, setState] = useState(getBodyData())

  useEffect(() => {
    try {
      const observer = new MutationObserver(() => {
        setState(getBodyData())
      })

      observer.observe(document.body, {attributes: true})

      return () => {
        observer.disconnect()
      }
    } catch {}
  })

  return state
}

export const useBodyScrollLock = ({ref, isEnabled = true}: Props) => {
  useEffect(() => {
    if (!isEnabled || !ref.current) return

    const element = ref.current

    const timeout = setTimeout(() => {
      disableBodyScroll(element, {
        // See docs: https://www.npmjs.com/package/body-scroll-lock#allowtouchmove
        allowTouchMove: el => {
          if (el instanceof Element)
            return Boolean(el.closest(BODY_SCROLL_LOCK_IGNORE))
          return false
        },
        reserveScrollBarGap: true
      })
    }, 0)

    return () => {
      if (timeout) window.clearTimeout(timeout)

      enableBodyScroll(element)
    }
  }, [ref, isEnabled])
}

export default useBodyScrollLock
