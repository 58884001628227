import {useCallback, useEffect, useState} from 'react'
import noop from 'lodash/noop'

import {
  cookieSecuritySettings,
  setCookie
} from '@daedalus/core/src/_web/utils/cookies'
import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {MenuItemComponent} from '@daedalus/core/src/analytics/hooks/useTrackMenuItemClick'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  CookieConsentContext,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {
  isReactNativeWebView,
  postMessageToWebView,
  RNWebViewMessageTypes
} from '@daedalus/core/src/native'
import {safelyGetStorageItem} from '@daedalus/core/src/utils/persistence'

export enum CookieBannerClickComponent {
  AcceptButton = 'AcceptButton',
  RejectButton = 'RejectButton',
  PreferenceCenter = 'PreferenceCenter',
  CloseButton = 'CloseButton',
  AppPage = 'AppPage'
}

const getCookieConsentId = (): string => {
  const consentId =
    safelyGetStorageItem(localStorage, 'osano_consentmanager') ?? ''

  return consentId as string
}

const getEnabledCookieGroups = (): string[] => {
  if (typeof window === 'undefined') return []
  const consent = window.Osano?.cm?.getConsent() || ''

  return Object.entries(consent)
    .filter(([, value]) => value === 'ACCEPT')
    .map(([key]) => key)
}

const getCookieConsentContext = (): CookieConsentContext => ({
  consentId: getCookieConsentId(),
  enabledCookieGroups: getEnabledCookieGroups()
})

export const useCookieConsent = (onAccept: () => void = noop) => {
  const [isPreferencesCenterVisible, setIsPreferencesCenterVisible] =
    useState(false)
  const dispatchTeamEvent = useDispatchTeamEvent()
  const trackBannerSubmitted = useCallback(
    (component?: CookieBannerClickComponent) => {
      const cookieConsentContext = getCookieConsentContext()

      dispatchTeamEvent(
        trackEvent({
          category: Category.User,
          entity: Entity.CookieBanner,
          action: Action.Submitted,
          analyticsContext: {
            [AnalyticsContext.CookieConsentContext]: cookieConsentContext
          },
          component
        })
      )
    },
    [dispatchTeamEvent]
  )

  const storeCookieConsentTimestamp = useCallback(() => {
    setCookie('cookieConsentTimestamp', Date.now().toString(), {
      expires: 365,
      ...cookieSecuritySettings(true)
    })
  }, [])

  const handlePreferenceCenterAcceptCookies = useCallback(() => {
    onAccept()
    trackBannerSubmitted(CookieBannerClickComponent.PreferenceCenter)
    setIsPreferencesCenterVisible(false)

    storeCookieConsentTimestamp()
  }, [trackBannerSubmitted, onAccept, storeCookieConsentTimestamp])

  const openPreferencesCenter = () => {
    if (isReactNativeWebView()) {
      postMessageToWebView({
        type: RNWebViewMessageTypes.NAVIGATE,
        payload: {screen: MenuItemComponent.PrivacyPolicy}
      })
    } else {
      window.Osano?.cm?.showDrawer()
      setIsPreferencesCenterVisible(true)
    }
  }

  const acceptAll = useCallback(() => {
    const acceptAllButton = document.querySelector(
      '.osano-cm-accept-all'
    ) as HTMLButtonElement

    acceptAllButton?.click()
    onAccept()

    storeCookieConsentTimestamp()
  }, [onAccept, storeCookieConsentTimestamp])

  const denyAll = useCallback(() => {
    const denyAllButton = document.querySelector(
      '.osano-cm-denyAll'
    ) as HTMLButtonElement

    denyAllButton?.click()
  }, [])

  /**
   * Listen to consents inside preference center
   */
  useEffect(() => {
    if (!isPreferencesCenterVisible) {
      return
    }

    const preferenceCenterSaveButton = document.querySelector(
      '.osano-cm-info .osano-cm-save'
    )
    preferenceCenterSaveButton?.addEventListener(
      'click',
      handlePreferenceCenterAcceptCookies
    )

    const handleUiChange = () => {
      setIsPreferencesCenterVisible(window.Osano?.cm?.drawerOpen ?? false)
    }
    window.Osano?.cm?.addEventListener('osano-cm-ui-changed', handleUiChange)

    return () => {
      preferenceCenterSaveButton?.removeEventListener(
        'click',
        handlePreferenceCenterAcceptCookies
      )
      window.Osano?.cm?.removeEventListener(
        'osano-cm-ui-changed',
        handleUiChange
      )
    }
  }, [isPreferencesCenterVisible, handlePreferenceCenterAcceptCookies])

  return {
    acceptAll,
    denyAll,
    trackBannerSubmitted,
    openPreferencesCenter,
    cookieConsentContext: getCookieConsentContext(),
    isPreferencesCenterVisible
  }
}
