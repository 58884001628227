// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgWarningMark = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 3a1 1 0 1 0-2 0v9a1 1 0 1 0 2 0V3Zm0 13a1 1 0 1 0-2 0v1a1 1 0 1 0 2 0v-1Z"
    />
  </svg>
)

export default SvgWarningMark
