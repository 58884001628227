import {defineMessages} from 'react-intl'

export const searchErrorMessages = defineMessages({
  crashPageMessageTitle: {
    id: 'crashPage.message.title',
    defaultMessage: 'Uh oh, something went wrong'
  },
  crashPageMessageBody: {
    id: 'crashPage.message.body',
    defaultMessage:
      "Our developers have been notified. But don't worry, we'll get you back to finding the best deals."
  },
  crashPageButtonRefresh: {
    id: 'crashPage.button.refresh',
    defaultMessage: 'Refresh'
  }
})
