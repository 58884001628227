import {CheckoutOfferContext} from '@daedalus/core/src/analytics/types/Events'

import {OVERLAY_IDS} from '../selectors'

export const OVERLAY_SHOW = 'OVERLAY_SHOW'
export const OVERLAY_HIDE = 'OVERLAY_HIDE'
export const OVERLAY_STACK_REPLACE = 'OVERLAY_STACK_REPLACE'
export const OVERLAY_REPLACE_TOP = 'OVERLAY_REPLACE_TOP'

type ShowOverlayId = string
type ShowOverlayExtraState = Record<string, unknown> | null | undefined

export interface ShowOverlayParams {
  id: ShowOverlayId
  extraState: ShowOverlayExtraState
}

export const showOverlay = (
  id: ShowOverlayId,
  extraState?: ShowOverlayExtraState,
  ignoreHistory?: boolean
) => ({
  type: OVERLAY_SHOW,
  params: {id, extraState},
  ignoreHistory
})

type HideOverlayId = string

export interface HideOverlayParams {
  id: HideOverlayId
}

export const hideOverlay = (
  id: HideOverlayId,
  ignoreHistory: boolean,
  offerEventContext?: CheckoutOfferContext
) => ({
  type: OVERLAY_HIDE,
  params: {id, offerEventContext},
  ignoreHistory
})

type ReplaceOverlays = {
  id: string
  extraState?: Record<string, unknown> | null
}[]

export interface ReplaceOverlaysParams {
  overlays: ReplaceOverlays
}

export const replaceOverlayStack = (overlays: ReplaceOverlays) => ({
  type: OVERLAY_STACK_REPLACE,
  params: {overlays}
})

export interface ReplaceOverlayTopParams {
  id: string
  extraState: Record<string, unknown> | null
}

export const replaceOverlayTop = (
  id: string,
  extraState: Record<string, unknown> | null | undefined,
  ignoreHistory: boolean
) => ({
  type: OVERLAY_REPLACE_TOP,
  params: {id, extraState},
  ignoreHistory
})

export type ModuleActionType =
  | ReturnType<typeof showOverlay>
  | ReturnType<typeof hideOverlay>
  | ReturnType<typeof replaceOverlayStack>
  | ReturnType<typeof replaceOverlayTop>

const changeOverlayVisibility =
  (id: string) =>
  (
    willBecomeVisible: boolean,
    replaceOverlay?: boolean,
    ignoreHistory = false
  ) =>
  (extraState?: Record<string, unknown>) => {
    if (!willBecomeVisible) {
      return hideOverlay(id, ignoreHistory)
    }

    if (replaceOverlay) {
      return replaceOverlayTop(id, extraState, ignoreHistory)
    }

    return showOverlay(id, extraState, ignoreHistory)
  }

export const changeRoomDetailsVisibility = changeOverlayVisibility(
  OVERLAY_IDS.roomDetails
)

export const changeDateSelectionVisibility = changeOverlayVisibility(
  OVERLAY_IDS.dateSelection
)

export const changeGuestSelectionVisibility = changeOverlayVisibility(
  OVERLAY_IDS.guestSelection
)

export const changeOfferDetailsVisibility = changeOverlayVisibility(
  OVERLAY_IDS.offerDetails
)

export const changePreferencesVisibility = changeOverlayVisibility(
  OVERLAY_IDS.preferences
)

export const changeAccountVisibility = changeOverlayVisibility(
  OVERLAY_IDS.account
)

export const changeMembershipVisibility = changeOverlayVisibility(
  OVERLAY_IDS.membership
)

export const changeReferralVisibility = changeOverlayVisibility(
  OVERLAY_IDS.referral
)

export default showOverlay
