import {safeJsonParse} from '../../utils/object'
import {CustomAppDataAttributes, User} from '../types/Cognito'

/**
 * Get Cognito User Id
 */
/** @deprecated Use getCognitoUserId from selector */
export const getCognitoUserId = (user?: User | null) => user?.attributes?.sub

/** @deprecated Use getCognitoCustomUserData from selector */
const getCognitoCustomUserData = (
  user: Pick<User, 'attributes'> | null
): CustomAppDataAttributes =>
  safeJsonParse(user?.attributes?.['custom:app_data'])

/**
 * Get the current Cognito user tier.
 * Different tiers can be offered different rates and experiences
 * @param user - Cognito user
 * @returns ['employee', 'plus']
 */
/** @deprecated Use getCognitoUserTier from selector */
export const getCognitoUserTier = (user: Pick<User, 'attributes'> | null) =>
  getCognitoCustomUserData(user)?.tier ?? []
