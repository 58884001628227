import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {InputTextType} from '@daedalus/atlas/helpers/InputBase'
import {Icon} from '@daedalus/atlas/Icon'
import {InputText} from '@daedalus/atlas/InputText'
import useDebounce from '@daedalus/core/src/utils/debounce/hooks/useDebounce'

import {destinationPickerMessages} from './SearchFormHorizontal'
import {
  formButtonFocusBorderStyles,
  formButtonFocusHoverStyles,
  formButtonFocusIconStyles,
  IconWrapper
} from './styles'

interface Props {
  /** Destination name */
  destination: string
  /** Whether the destination picker popover is currently open */
  isDestinationPickerOpen: boolean
  /** Callback for when autocomplete data is requested */
  onSuggestionsRequested: (
    value: string,
    shouldLoadUserSearchHistory: boolean
  ) => void
  /** Whether search history should be shown */
  shouldLoadUserSearchHistory?: boolean
  /** Whether the input should be focused on mount */
  focusOnMount?: boolean
}

const DestinationInput = styled(InputText)<{isActive?: boolean}>(
  ({theme}) => formButtonFocusHoverStyles({isFirstInput: true, theme}),
  ({theme}) => css`
    input {
      background: white !important;
      ::-webkit-search-cancel-button {
        display: none;
      }
      ${formButtonFocusBorderStyles({theme})}
    }
    ${IconWrapper} {
      ${formButtonFocusIconStyles({theme})}
    }
  `
)

export const SearchFormDestinationInput = React.memo(
  function SearchFormDestinationInput({
    destination,
    isDestinationPickerOpen,
    onSuggestionsRequested,
    shouldLoadUserSearchHistory = false,
    focusOnMount = false
  }: Props) {
    const {formatMessage} = useIntl()
    const inputFieldRef = useRef<null | HTMLInputElement>(null)
    const [inputValue, setInputValue] = useState(destination)
    const debouncedInputValue: string = useDebounce(inputValue, 250)

    useEffect(() => {
      if (focusOnMount) inputFieldRef.current?.focus?.()
    }, [focusOnMount])

    useEffect(() => {
      setInputValue(destination)
    }, [setInputValue, destination])

    // Requesting suggestions can cause a loop when we rely on dep stability so check that value is actually changed
    const previousDebouncedInputValue = useRef<string>(debouncedInputValue)

    useEffect(() => {
      const isChanged =
        debouncedInputValue !== previousDebouncedInputValue.current
      if (!isChanged) return

      previousDebouncedInputValue.current = debouncedInputValue

      onSuggestionsRequested(
        debouncedInputValue ?? '',
        Boolean(!debouncedInputValue)
      )
    }, [debouncedInputValue, onSuggestionsRequested])

    const handleInputChange = useCallback(
      (value: string) => {
        setInputValue(value)
      },
      [setInputValue]
    )

    const handleInputFocus = useCallback(
      ({target}: React.FocusEvent<HTMLInputElement>) => {
        const {value} = target

        if (destination === '') {
          onSuggestionsRequested('', true)
        } else {
          onSuggestionsRequested(value, shouldLoadUserSearchHistory)
        }
      },
      [destination, shouldLoadUserSearchHistory, onSuggestionsRequested]
    )

    const handleClearDestination = useCallback(
      (event: React.SyntheticEvent) => {
        event.preventDefault()

        setInputValue('')
        inputFieldRef.current?.focus()
      },
      [inputFieldRef, setInputValue]
    )

    return (
      <DestinationInput
        ref={inputFieldRef}
        customProps={{
          role: 'presentation',
          autoCapitalize: 'off',
          autoCorrect: 'off',
          spellCheck: 'false',
          autocomplete: 'off',
          dataLpignore: true,
          dataFormType: 'other'
        }}
        size="md"
        id="DestinationInput"
        dataId="DestinationInput"
        name="DestinationInput"
        closeButtonDataId="DestinationInputClear"
        isActive={isDestinationPickerOpen}
        onFocus={handleInputFocus}
        icon={
          <IconWrapper>
            <Icon name="Pin" />
          </IconWrapper>
        }
        isRounded
        placeholder={formatMessage(
          destinationPickerMessages.destinationPlaceholder
        )}
        type={InputTextType.search}
        value={inputValue}
        onChange={handleInputChange}
        onClear={handleClearDestination}
      />
    )
  }
)
