import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Card} from '@daedalus/atlas/src/components/designSystem/Card'
import {PageWidthContainer as PageWidthContainerElement} from '@daedalus/atlas/src/components/designSystem/helpers/PageWidthContainer'
import {mq} from '@daedalus/atlas/src/utils/breakpoints'

import {PropsType} from './types'
import {WhatIsGems} from './WhatIsGems/WhatIsGems'

const SIDEBAR_MAX_WIDTH = 294
const FOOTER_HEIGHT = 65
const HEADER_DESKTOP_HEIGHT = 89
const HEADER_MOBILE_HEIGHT = 65

const PageWidthContainer = styled(PageWidthContainerElement)(
  ({theme}) =>
    css`
      height: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      ${mq.desktopXs(css`
        padding: 0 ${theme.layout.spacing.s500}px;
      `)}
    `
)

const PageContainerWrapper = styled.div(
  ({theme}) =>
    css`
      min-height: calc(100vh - ${HEADER_MOBILE_HEIGHT}px);
      ${mq.desktopXs(css`
        min-height: calc(
          100vh - ${HEADER_DESKTOP_HEIGHT}px - ${FOOTER_HEIGHT}px
        );
        padding: ${theme.layout.spacing.s500}px 0;
        background: ${theme.colors.background.neutral.c050};
      `)}
    `
)

const PageGrid = styled.div(
  ({theme}) => css`
    margin-bottom: ${theme.layout.spacing.s600}px;
    margin-top: -3px;

    ${mq.desktopXs(css`
      display: grid;
      grid-template-columns:
        ${SIDEBAR_MAX_WIDTH}px
        minmax(min-content, auto);
      gap: ${theme.layout.spacing.s600}px;
      margin-bottom: 0;
      margin-top: 0;
    `)}
  `
)

const SideBarWrapper = styled(Card)`
  height: fit-content;
  overflow: auto;
`

const MainWrapper = styled.div(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    ${mq.desktopXs(css`
      gap: ${theme.layout.spacing.s500}px;
    `)}
  `
)

export const MembershipContent = ({
  isMobile,
  isAuthenticated,
  anonymousId,
  profilePicture,
  accountSideMenu,
  brand
}: PropsType) => {
  const DesktopSideMenu = () =>
    !isMobile ? (
      <div>
        <SideBarWrapper borderRadius="md" shadow="action">
          {isAuthenticated && profilePicture}
          {!!accountSideMenu && accountSideMenu}
        </SideBarWrapper>
      </div>
    ) : null

  return (
    <PageContainerWrapper>
      <PageWidthContainer>
        <PageGrid>
          <DesktopSideMenu />
          <MainWrapper>
            <WhatIsGems brand={brand} anonymousId={anonymousId} />
          </MainWrapper>
        </PageGrid>
      </PageWidthContainer>
    </PageContainerWrapper>
  )
}
