import React from 'react'
import {toggle} from 'opticks'

import {ContentWrapper} from '@daedalus/atlas/helpers/ContentWrapper'
import {useIsReferralProgramEnabled} from '@daedalus/atlas/hooks/useIsReferralProgramEnabled'
import {defaultBrand} from '@daedalus/core/src/brand/config'
import {checkIsBrandVio} from '@daedalus/core/src/brand/utils'
import {isReactNativeWebView} from '@daedalus/core/src/native'

import {RowWrapper} from './commonStyles'
import {MobileCurrencySelector} from './CurrencySelector/Mobile'
import {DebugPanelLink} from './DebugPanelLink'
import {DebugScreenLink} from './DebugScreenLink'
import {GetHelpLink} from './GetHelpLink'
import {InviteFriendsToGems} from './InviteFriendsToGems'
import {MobileLanguageSelector} from './LanguageSelector/Mobile'
import {MembershipLink} from './MembershipLink'
import {MyBookingsLink} from './MyBookingsLink'
import {MyWatchlistsLink} from './MyWatchlistsLink'
import {NotificationsLink} from './NotificationsLink'
import {PrivacyPolicyLink} from './PrivacyPolicyLink'
import {ShareYourFeedbackLink} from './ShareYourFeedbackLink'
import {PropsType} from './types'

export const PreferenceSelector = ({
  availableLanguages,
  brand = defaultBrand,
  currenciesList,
  currentCurrency,
  currentLanguage,
  hasCurrencySelector = true,
  hasLanguageSelector = true,
  myBookingsUrl,
  onChangeCurrency,
  onClickCurrency,
  onChangeLanguage,
  onClickLanguage,
  onShowMembershipOverlay,
  onShowReferralOverlay,
  onShowWatchlistCollectionOverlay,
  onShareFeedbackClick,
  popularCurrenciesList,
  showNotifications,
  showPrivacyPolicy,
  showShareYourFeedback,
  showWatchlist,
  hideGemsMembership,
  hideDebugPanel,
  hideDebugScreen,
  hidePrivacyPolicy,
  hideShareYourFeedback,
  hideNotification,
  hideWatchlist,
  hideReferral,
  hideMyBooking,
  onChangePriceDisplay,
  showTotalPrices = false,
  showPriceDisplaySetting = false,
  getTheAppSlot = null
}: PropsType) => {
  const brandIsVio = checkIsBrandVio(brand)
  const showReferral = useIsReferralProgramEnabled(brand)

  const showDebugPanel = toggle('442c55a9-debug-panel', false, true)
  const showDebugScreen = toggle(
    '11c076a5-debug-screen',
    false,
    isReactNativeWebView()
  )
  return (
    <ContentWrapper padding="s500" paddingTop="s300">
      {hasLanguageSelector && (
        <RowWrapper>
          <MobileLanguageSelector
            availableLanguages={availableLanguages}
            currentLanguage={currentLanguage}
            onChangeLanguage={onChangeLanguage}
            onClickLanguage={onClickLanguage}
          />
        </RowWrapper>
      )}
      {hasCurrencySelector && (
        <RowWrapper>
          <MobileCurrencySelector
            currenciesList={currenciesList}
            popularCurrenciesList={popularCurrenciesList}
            currentCurrency={currentCurrency}
            onChangeCurrency={onChangeCurrency}
            onClickCurrency={onClickCurrency}
            showTotalPrices={showTotalPrices}
            showPriceDisplaySetting={showPriceDisplaySetting}
            onChangePriceDisplay={onChangePriceDisplay}
          />
        </RowWrapper>
      )}
      {myBookingsUrl && !hideMyBooking && (
        <MyBookingsLink myBookingsUrl={myBookingsUrl} />
      )}
      {showReferral && !hideReferral && (
        <InviteFriendsToGems
          brand={brand}
          onShowReferralOverlay={() => onShowReferralOverlay?.()}
        />
      )}
      {showWatchlist && !hideWatchlist && (
        <MyWatchlistsLink
          onClick={() => onShowWatchlistCollectionOverlay?.()}
        />
      )}

      {brandIsVio && !hideGemsMembership && (
        <MembershipLink onShowMembershipOverlay={onShowMembershipOverlay} />
      )}

      {showNotifications && !hideNotification && <NotificationsLink />}
      {showShareYourFeedback && !hideShareYourFeedback && (
        <ShareYourFeedbackLink onClick={onShareFeedbackClick} />
      )}
      {showPrivacyPolicy && !hidePrivacyPolicy && <PrivacyPolicyLink />}
      {showDebugPanel && !hideDebugPanel && (
        <DebugPanelLink link="/*debugPanel?accessCode=0,0,1,1,0,1" />
      )}

      {showDebugScreen && !hideDebugScreen && <DebugScreenLink />}
      {brandIsVio && getTheAppSlot && <RowWrapper>{getTheAppSlot}</RowWrapper>}
      {brandIsVio && (
        <RowWrapper>
          <GetHelpLink brand={brand} />
        </RowWrapper>
      )}
    </ContentWrapper>
  )
}
