import {css} from '@emotion/react'
import styled from '@emotion/styled'

export const CustomInputSelect = styled.select(
  ({theme}) => css`
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    ${theme.typography.text.labelM};
  `
)

export const WrapperSelector = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`

export const RowWrapper = styled.div(
  ({theme}) => css`
    padding: ${theme.layout.spacing.s500}px 0;
    border-bottom: solid 1px ${theme.colors.border.neutral.c100};
  `
)

export const Link = styled.a(
  ({theme}) => css`
    text-decoration: none;
    color: ${theme.colors.content.neutral.c950};

    &:hover,
    &:visited,
    &:focus {
      text-decoration: none;
      color: ${theme.colors.content.neutral.c950};
    }
  `
)

export const IconWrapper = styled.div(
  ({theme}) => css`
    color: ${theme.colors.content.neutral.c600};
    /* @mirror */
  `
)

export const Label = styled.div(
  ({theme}) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${IconWrapper} {
      margin-right: ${theme.layout.spacing.s300}px;
    }
  `
)
