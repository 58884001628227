import {defineMessages} from 'react-intl'

export const guestPickerMessages = defineMessages({
  adult: {
    id: 'adult',
    defaultMessage: '{count, plural, one {Adult} other {Adults} }'
  },
  child: {
    id: 'child',
    defaultMessage: '{count, plural, one {Child} other {Children} }'
  },
  childAgeWithNumber: {
    id: 'childAgeWithNumber',
    defaultMessage: 'Child {number} age'
  },
  guest: {
    id: 'guest',
    defaultMessage: '{count, plural, one {Guest} other {Guests} }'
  },
  guests: {
    id: 'guests',
    defaultMessage: 'Guests'
  },
  room: {
    id: 'room',
    defaultMessage: '{count, plural, one {Room} other {Rooms} }'
  },
  rooms: {
    id: 'rooms',
    defaultMessage: 'Rooms'
  }
})
