// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgMicrophoneMute = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 36 36"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 3.6a7.2 7.2 0 0 0-7.2 7.2v5.724c0 .096.001.211.01.337.024.383.124 1.515.506 2.65.21.624.57 1.436 1.186 2.078L10.39 23.7a7.284 7.284 0 0 1-1.897-2.824 7.155 7.155 0 0 1-.338-1.203 4.872 4.872 0 0 1-.057-.398v-.007a1.351 1.351 0 0 0-2.696.156l1.348-.075-1.348.075v.012l.002.016.003.049.016.152c.014.126.038.3.077.51.078.422.217 1 .465 1.662A9.993 9.993 0 0 0 8.48 25.61l-2.685 2.685a1.35 1.35 0 1 0 1.91 1.909l22.5-22.5a1.35 1.35 0 0 0-1.91-1.91l-3.299 3.3A7.203 7.203 0 0 0 18 3.6Zm4.5 7.991V10.8a4.5 4.5 0 0 0-9 0v5.724c0 .072 0 .12.004.161.02.3.1 1.159.37 1.964.173.51.363.834.54 1.028l8.086-8.086Zm-15.75 7.76 1.348-.077v.002l-1.348.074Z"
    />
    <path
      d="M30.598 19.425a1.35 1.35 0 0 0-2.696-.157l-.003.039a6.485 6.485 0 0 1-.189.948 7.453 7.453 0 0 1-1.13 2.355C25.395 24.25 23.007 26.1 18 26.1c-.578 0-1.12-.025-1.629-.07a1.35 1.35 0 1 0-.242 2.688c.17.016.344.03.52.04v2.292a1.35 1.35 0 1 0 2.7 0v-2.292c4.955-.314 7.82-2.353 9.42-4.567a10.153 10.153 0 0 0 1.542-3.214c.122-.439.193-.81.233-1.078a6.61 6.61 0 0 0 .041-.324l.006-.062.003-.036.002-.032.001-.012v-.008l-1.347-.075 1.348.075Z"
      fill="#B8B5BA"
    />
  </svg>
)

export default SvgMicrophoneMute
