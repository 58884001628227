import {OffersConfigurations} from '../../../api-types/bovio/response/offers_configurations'
import {safeJsonParse} from '../../../utils/object'
import {
  safelyGetStorageItem,
  safelySetStorageItem
} from '../../../utils/persistence'

const CONFIGURATION_STORAGE_KEY = 'boVioConfiguration'

/**
 * Get the BoVio persisted configuration from the session storage.
 */
export const getPersistedConfiguration = () => {
  return (safeJsonParse(
    safelyGetStorageItem(sessionStorage, CONFIGURATION_STORAGE_KEY)
  ) || {}) as OffersConfigurations
}

export const persistConfiguration = (data: OffersConfigurations) => {
  return safelySetStorageItem(
    sessionStorage,
    CONFIGURATION_STORAGE_KEY,
    JSON.stringify(data)
  )
}
