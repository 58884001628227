import {createSlice, PayloadAction, SliceCaseReducers} from '@reduxjs/toolkit'

import {ExperimentsState} from '../types'
import {fetchOptimizelyDataFile} from './actions'
import fallbackDataFile from './fallbackDataFile'

/**
 * The slice that contains information regarding the experiments that run on our product
 */
const experimentsSlice = createSlice<
  ExperimentsState['experiments'],
  SliceCaseReducers<ExperimentsState['experiments']>
>({
  name: 'experiments',
  initialState: {state: 'idle', dataFile: undefined},
  reducers: {},
  extraReducers: builder => {
    /** Reducer action that will run when the remote datafile is correctly loaded */
    builder.addCase(
      fetchOptimizelyDataFile.fulfilled.type,
      (state, action: PayloadAction) => {
        state.state = 'initialized'
        state.dataFile = action.payload
      }
    )

    /** Reducer action that will run when the remote datafile is NOT correctly loaded */
    builder.addCase(fetchOptimizelyDataFile.rejected.type, state => {
      console.error('Error loading Optimizely dataFile')
      state.state = 'error'
      state.dataFile = fallbackDataFile
    })
  }
})

export default experimentsSlice.reducer
