// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgMapPin = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 26 30"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m14.405 29.427 7.787-7.644a12.595 12.595 0 0 0 0-18.046c-5.076-4.983-13.308-4.983-18.384 0a12.595 12.595 0 0 0 0 18.046l7.787 7.643a2.009 2.009 0 0 0 2.81 0ZM13 17a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      fill="#F36"
    />
  </svg>
)

export default SvgMapPin
