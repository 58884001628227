// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgSunrise = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.75 2.75a.75.75 0 0 0-1.5 0v.5a.75.75 0 1 0 1.5 0v-.5ZM6.5 11a3.5 3.5 0 1 1 6.355 2.026l-.435.474H7.58l-.435-.474A3.48 3.48 0 0 1 6.5 11Zm.748 4H17.25a.75.75 0 0 0 0-1.5h-2.919a5 5 0 1 0-8.662 0H2.74a.75.75 0 0 0 0 1.5h4.508ZM3.99 17.25a.75.75 0 0 1 .75-.75h10.51a.75.75 0 0 1 0 1.5H4.74a.75.75 0 0 1-.75-.75ZM14 3.072a.75.75 0 0 1 .274 1.024l-.25.433a.75.75 0 1 1-1.298-.75l.25-.433A.75.75 0 0 1 14 3.072Zm2.654 3.952a.75.75 0 0 0-.75-1.299l-.433.25a.75.75 0 0 0 .75 1.3l.433-.25ZM16 10a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5A.75.75 0 0 1 16 10ZM2.75 9.25a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5ZM3.072 6a.75.75 0 0 1 1.024-.275l.433.25a.75.75 0 0 1-.75 1.3l-.433-.25A.75.75 0 0 1 3.072 6Zm3.952-2.654a.75.75 0 0 0-1.299.75l.25.433a.75.75 0 0 0 1.3-.75l-.25-.433Z"
    />
  </svg>
)

export default SvgSunrise
