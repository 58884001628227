import React, {useEffect} from 'react'
import {BookingError} from 'hooks/useBookingError'

import {ContentWrapper} from '@daedalus/atlas/helpers/ContentWrapper'
import {Grid} from '@daedalus/atlas/helpers/Grid'
import {Text} from '@daedalus/atlas/Text'
import {useScrollTo} from '@daedalus/core/src/_web/utils/browser/hooks/useScrollTo'
import {Category as ErrorCategory} from '@daedalus/core/src/api-types/bovio/response/error'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {ErrorBanner} from '@daedalus/shared/src/booking/ErrorBannerRedesigned'

export const getIsInvalidEmail = (error?: BookingError) => {
  return error?.data?.details?.category === ErrorCategory.InvalidEmail
}

export const InvalidEmail = React.memo(() => {
  const {ref, scroll} = useScrollTo(500, 100)

  useEffect(() => {
    scroll()
  }, [])

  return (
    <ContentWrapper paddingBottom="s600">
      <div ref={ref}>
        <ErrorBanner
          title={
            <FormattedMessageWrapper
              id="errors.invalidEmailTitle"
              defaultMessage="Invalid email"
            />
          }
        >
          <Grid container spacing="s000" gap="s400">
            <Text variant="bodyS">
              <FormattedMessageWrapper
                id="errors.invalidEmailDescriptionV3"
                defaultMessage="Reenter your email and try again."
              />
            </Text>
          </Grid>
        </ErrorBanner>
      </div>
    </ContentWrapper>
  )
})

InvalidEmail.displayName = 'InvalidEmail'
