export const toastMessages = {
  pictureChanged: {
    id: 'pictureChanged',
    defaultMessage: 'Your photo has updated'
  },
  emailAddressInUserError: {
    id: 'emailAddressInUserError',
    defaultMessage: 'Email has already been registered before'
  },
  userUpdateError: {
    id: 'userUpdateError',
    defaultMessage: 'Update error, please check the personal details'
  },
  failedToSaveS3: {
    id: 'imageSaveFailed',
    defaultMessage: 'Updating your photo has been failed, please try again.'
  },
  noImageToSave: {
    id: 'noImageToSave',
    defaultMessage: 'Please select an image'
  },
  unknownFileType: {
    id: 'unknownFileType',
    defaultMessage: 'Wrong file type. Please select a jpg or a png file'
  },
  reSendEmailValidationFailed: {
    id: 'reSendEmailValidationFailed',
    defaultMessage: 'Resend email validation has been failed. Please try again.'
  },
  imageSizeError: {
    id: 'imageSizeIsBig',
    defaultMessage: 'Max image size is 3 mb. Please select a smaller image.'
  },
  userDataSaved: {
    id: 'changesSaved',
    defaultMessage: 'Your changes have been saved'
  },
  userDataSaveError: {
    id: 'saveFailed',
    defaultMessage: "Sorry, we couldn't save your changes. Please try again."
  },
  get imageResizeFailed() {
    return this.failedToSaveS3
  },
  get invalidFileExtention() {
    return this.unknownFileType
  },
  get invalidFileType() {
    return this.unknownFileType
  }
}
