export const TRACK_RAA_OFFER_CLICKED = 'TRACK_RAA_OFFER_CLICKED'

export type OfferClickedParamsType = {
  anchorPrice?: number
  availableRooms?: number | null
  bookURI: string
  calculatedTotalRate: number
  canPayLater: boolean
  currency?: string
  hasAnchorPrice?: boolean
  hasFreeCancellation: boolean
  hotelId?: string
  hotelPosition?: number
  integrationType?: string
  isAnchorPriceOffer: boolean
  isCheapest: boolean
  isPrivateDeal: boolean
  isSharedRoom?: boolean
  isTopOffer: boolean
  meals: string[]
  nightlyRate: number
  nights?: number
  offerId?: string
  offerPosition: number
  providerCode?: string
  providerHotelId?: string
  proxyProviderCode: string
  rateBreakdown:
    | {baseRate: number; localTaxes: number; taxes: number}
    | {
        base: number
        hotelFees: number
        taxes: number
      }
  sourceComponent?: string
  yourChoice?: boolean
  intermediaryProvider?: string
}

type OfferClickedMetaDataType = {
  checkIn: string
  checkOut: string
  nights: number
  rooms: string
  searchId: string | null | undefined
  searchType: string
}

/* eslint-disable */
// prettier-ignore
type OfferClickedTrackingParamsType = OfferClickedParamsType & OfferClickedMetaDataType;

/* eslint-enable */

export type OfferClickedActionType = {
  event: OfferClickedParamsType
  type: typeof TRACK_RAA_OFFER_CLICKED
}

export const trackRAAOfferClicked = (
  event: OfferClickedParamsType
): OfferClickedActionType => ({
  event,
  type: TRACK_RAA_OFFER_CLICKED
})
