import {createApi} from '@reduxjs/toolkit/query/react'

import {Booking} from '../../../api-types/bovio/response/booking'
import {axiosBaseQuery} from '../../../utils/network/axiosBaseQuery'

export interface GetBookingByIdRequest {
  headers: {
    Accept: 'application/json'
    'X-Client-User-Agent': string
  }
  url: string
  bookingId: string
  deviceType: string
  locale: string
}

export interface ExtendedBooking extends Booking {
  httpRequestId: string
}

export const bookingRetrieveApi = createApi({
  reducerPath: 'bookingRetrieveApi',
  baseQuery: axiosBaseQuery({
    baseUrl: ''
  }),
  serializeQueryArgs: ({endpointName}) => endpointName,
  endpoints: builder => ({
    getBookingById: builder.query<ExtendedBooking, GetBookingByIdRequest>({
      query: ({bookingId, deviceType, locale, url, headers}) => ({
        url: `${url}bookings/${bookingId}`,
        params: {
          deviceType,
          locale
        },
        headers
      }),
      serializeQueryArgs: ({endpointName, queryArgs}) => {
        return `${endpointName}/${queryArgs.bookingId}`
      },
      transformResponse: (
        response: Booking,
        meta: {headers: Record<string, string>}
      ) => ({...response, httpRequestId: meta.headers?.['x-request-id']})
    })
  })
})

export const {useGetBookingByIdQuery} = bookingRetrieveApi
