import {
  ascend,
  descend,
  lensProp,
  map,
  over,
  pathOr,
  pipe,
  prop,
  sort
} from 'ramda'

import {Offer} from '@daedalus/core/src/offer/types/offer'
import {Room} from '@daedalus/core/src/room/types/room'

import {getNightlyDisplayTotalPrice} from '../business/price'

const firstOfferSelector = pathOr(null, ['offers', '0'])

export const getOfferNightlyDisplayTotal = (offer: Offer) => {
  const displayTotalPrice = getNightlyDisplayTotalPrice(
    pathOr([], ['prices'], offer)
  )
  return Math.round(displayTotalPrice)
}

const byNightlyDisplayTotalAsc = ascend(getOfferNightlyDisplayTotal)

const getFirstOfferNightlyDisplayTotal = ascend(
  pipe(firstOfferSelector, getOfferNightlyDisplayTotal)
)

export const sortOffersByNightlyDisplayTotalAsc = (offers: Offer[]) => {
  return sort(byNightlyDisplayTotalAsc, offers)
}

export const sortOffersByCanPayLater = (offers: Offer[]) => {
  const canPayLater = prop('canPayLater')
  return sort(descend(canPayLater), offers)
}

export const sortRoomOffersByCanPayLaterFirst = (room: Room) => {
  const lensOffers = lensProp<Room, 'offers'>('offers')
  return over(lensOffers, sortOffersByCanPayLater, room)
}

export const sortRoomOffersByNightlyDisplayTotalAsc = (room: Room) => {
  const lensOffers = lensProp<Room, 'offers'>('offers')
  return over(lensOffers, sortOffersByNightlyDisplayTotalAsc, room)
}

export const sortRoomsByNightlyDisplayTotalAsc = (rooms: Room[]) => {
  return pipe(
    map(sortRoomOffersByCanPayLaterFirst),
    map(sortRoomOffersByNightlyDisplayTotalAsc), // rooms -> rooms
    sort(getFirstOfferNightlyDisplayTotal) // rooms -> number
  )(rooms)
}
