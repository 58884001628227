import React from 'react'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'
import {ColorPath, ThemeType} from 'types/Theme'

import {cssTheme} from '../../../themes'
import {getCssColorByPath} from '../../../utils/colors'

interface Props {
  /** Card content */
  children?: React.ReactNode
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Whether card should have rounded corners at the top */
  hasRoundedTop?: boolean
  /** Whether card should have rounded corners at the bottom */
  hasRoundedBottom?: boolean
  /** Radius of rounded corners */
  borderRadius?: keyof ThemeType['layout']['radius']
  /** Shows up 1px border with specified color */
  borderColorPath?: ColorPath
  /** Sets background color of Card component */
  backgroundColorPath?: ColorPath
  /** Whether card should have shadow */
  shadow?: keyof ThemeType['shadows']
  /** Whether overflowing content of the card should be hidden */
  isOverflowHidden?: boolean
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
}

interface StyledCardProps extends Props {
  backgroundColorValue?: string
  borderColorValue?: string
}

const CardElement = styled.div<StyledCardProps>`
  background: ${({backgroundColorValue}) =>
    backgroundColorValue ?? cssTheme.colors.background.neutral.c000};
  overflow: ${({isOverflowHidden}) =>
    isOverflowHidden ? 'hidden' : 'visible'};
  border: ${({borderColorValue}) =>
    borderColorValue ? `1px solid ${borderColorValue}` : 'none'};
  box-shadow: ${({shadow}) =>
    shadow && shadow !== 'flat' ? cssTheme.shadows[shadow] : 'none'};

  &.hasRoundedTop {
    border-top-left-radius: ${({borderRadius}) =>
      borderRadius ? cssTheme.layout.radius[borderRadius] : 0};
    border-top-right-radius: ${({borderRadius}) =>
      borderRadius ? cssTheme.layout.radius[borderRadius] : 0};
  }
  &.hasRoundedBottom {
    border-bottom-left-radius: ${({borderRadius}) =>
      borderRadius ? cssTheme.layout.radius[borderRadius] : 0};
    border-bottom-right-radius: ${({borderRadius}) =>
      borderRadius ? cssTheme.layout.radius[borderRadius] : 0};
  }
`

export const Card = ({
  children,
  dataId,
  hasRoundedTop = true,
  hasRoundedBottom = true,
  borderRadius,
  borderColorPath,
  backgroundColorPath,
  shadow,
  isOverflowHidden = true,
  className
}: Props) => {
  const borderColorValue = borderColorPath && getCssColorByPath(borderColorPath)
  const backgroundColorValue =
    backgroundColorPath && getCssColorByPath(backgroundColorPath)

  return (
    <CardElement
      data-id={dataId}
      className={cx(
        className,
        hasRoundedTop && 'hasRoundedTop',
        hasRoundedBottom && 'hasRoundedBottom'
      )}
      borderRadius={borderRadius}
      borderColorValue={borderColorValue}
      backgroundColorValue={backgroundColorValue}
      isOverflowHidden={isOverflowHidden}
      shadow={shadow}
    >
      {children}
    </CardElement>
  )
}
