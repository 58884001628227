import {pickAll} from 'ramda'
import {MetaType} from 'types/Meta'

import {UrlCheckoutParamsType} from '@daedalus/core/src/utils/url/types/UrlParams'

const metaParams = (params: UrlCheckoutParamsType) =>
  pickAll<UrlCheckoutParamsType, MetaType>(
    ['currency', 'deviceType', 'locale', 'gsite'],
    params
  )

export default metaParams
