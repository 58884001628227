import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Button, ButtonSize} from '@daedalus/atlas/Button'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

interface Props {
  /** Specify button sizes */
  size: ButtonSize
  /** Callback for Login click */
  onLogInClick: () => void
  /** Callback for Signup click */
  onSignUpClick: () => void
}

const ButtonsWrapper = styled.div(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    > button + button {
      margin-top: ${theme.layout.spacing.s400}px;
    }
  `
)

export const AuthenticationButtons = ({
  size,
  onLogInClick,
  onSignUpClick
}: Props) => (
  <ButtonsWrapper>
    <Button fullWidth size={size} dataId="LogInButton" onClick={onLogInClick}>
      <FormattedMessageWrapper
        id="userWidget.signIn"
        defaultMessage="Sign in"
      />
    </Button>
    <Button
      fullWidth
      variant="secondary"
      dataId="SignUpButton"
      size={size}
      onClick={onSignUpClick}
    >
      <FormattedMessageWrapper
        id="userWidget.createAccount"
        defaultMessage="Create an account"
      />
    </Button>
  </ButtonsWrapper>
)
