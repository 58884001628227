// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPersonBorder = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    fill={color}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0C4.58 0 0 4.58 0 10s4.58 10 10 10 10-4.58 10-10S15.42 0 10 0Zm0 5c1.73 0 3 1.27 3 3s-1.27 3-3 3-3-1.27-3-3 1.27-3 3-3Zm-1 7.57c-1.71 0-3.2.88-4.1 2.2a6.97 6.97 0 0 0 10.2 0 4.96 4.96 0 0 0-4.1-2.2H9Z"
    />
  </svg>
)

export default SvgPersonBorder
