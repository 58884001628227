import React from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {useDispatch} from 'react-redux'
import {fatalError, OriginalError} from 'modules/common/actions/fatalError'

import {ErrorBoundaryFallback} from '@daedalus/shared/src/search/ErrorBoundaryFallback/ErrorBoundaryFallback'

interface Props {
  children?: React.ReactNode
}
const UnknownErrorBoundary: React.FC<Props> = ({children}) => {
  const dispatch = useDispatch()

  const handleFatalErrorAction = (error: Error, errorInfo: React.ErrorInfo) => {
    dispatch(
      fatalError(error as Error & OriginalError, errorInfo.componentStack)
    )
  }
  const errorHandler = (error: Error, errorInfo: React.ErrorInfo) => {
    handleFatalErrorAction(error, errorInfo)
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onError={errorHandler}
    >
      {children}
    </ErrorBoundary>
  )
}
export default UnknownErrorBoundary
