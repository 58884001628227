export const fadeInOutAndHide = {
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
}

export const slideRight = {
  exited: (time: number) => ({
    x: '100%',
    transition: {
      duration: time,
      ease: [0, 0, 0.2, 1]
    }
  }),
  entered: (time: number) => ({
    x: 0,
    transition: {
      duration: time,
      ease: [0, 0, 0.2, 1]
    }
  })
}

export const slideLeft = {
  exited: (time: number) => ({
    x: '-100%',
    transition: {
      duration: time,
      ease: [0, 0, 0.2, 1]
    }
  }),
  entered: (time: number) => ({
    x: 0,
    transition: {
      duration: time,
      ease: [0, 0, 0.2, 1]
    }
  })
}

export const slideUp = {
  exited: (time: number) => ({
    y: '-100%',
    transition: {
      duration: time,
      ease: [0, 0, 0.2, 1]
    }
  }),
  entered: (time: number) => ({
    y: 0,
    transition: {
      duration: time,
      ease: [0, 0, 0.2, 1]
    }
  })
}

export const slideDown = {
  exited: (time: number) => ({
    y: '100%',
    transition: {
      duration: time,
      ease: [0, 0, 0.2, 1]
    }
  }),
  entered: (time: number) => ({
    y: 0,
    transition: {
      duration: time,
      ease: [0, 0, 0.2, 1]
    }
  })
}

export const overlayFade = {
  exited: () => ({
    opacity: 0,
    transition: {
      ease: [0, 0, 0.2, 1]
    }
  }),
  entered: () => ({
    opacity: 1,
    transition: {
      ease: [0, 0, 0.2, 1]
    }
  })
}

export const transitions = {
  easeIn: {
    duration: 0.3,
    ease: 'easeIn'
  },
  easeInOut: {
    duration: 0.3,
    ease: 'easeInOut'
  }
}

export default {
  fadeInOutAndHide,
  overlayFade,
  slideRight,
  slideLeft,
  slideUp,
  slideDown,
  transitions
}
