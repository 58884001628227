import {MiddlewareType} from 'middlewares'
import {getSearchId} from 'modules/meta/selectors'
import fetchRaaOffers, {
  fetchRaaOffersDone,
  fetchRaaOffersError,
  fetchRaaOffersReceived,
  RAA_OFFERS_DONE,
  RAA_OFFERS_FETCH,
  RAA_OFFERS_RECEIVED
} from 'modules/raaOffers/actions/fetchRaaOffers'
import logRAAService, {
  RAAServiceEndpointNames,
  RAAServiceStatus
} from 'performance/logRAAService'
import {getRaaOffersService} from 'services/raaOffers'
import Settings from 'Settings'
import timing from 'utils/timing'
import {v4 as uuidv4} from 'uuid'

import {getPersistedSapiLabelWithFallback} from '@daedalus/core/src/utils/sapiLabel'

const pollingTimeout = Settings.get('REACT_APP_RAA_POLLING_TIMEOUT')

class PollingMonitor {
  private _id: string | undefined = uuidv4()
  private _iteration = 0

  public clearId() {
    this._id = undefined
  }

  public getId() {
    if (!this._id) this._id = uuidv4()
    return this._id
  }

  public clearIteration() {
    this._iteration = 0
  }

  public getIteration() {
    return this._iteration
  }

  public iterate() {
    return this._iteration++
  }

  public clearAll() {
    this.clearId()
    this.clearIteration()
  }
}

const pollingMonitor = new PollingMonitor()
const requestsTiming = timing()

const middleware: MiddlewareType = store => next => action => {
  const {dispatch, getState} = store
  const state = getState()

  next(action)

  switch (action.type) {
    case RAA_OFFERS_FETCH: {
      const raaUrl = `${Settings.get('REACT_APP_RAA_ENDPOINT')}/search`
      const searchId = getSearchId(state)
      const clientRequestId = pollingMonitor.getId()
      const pollIteration = pollingMonitor.getIteration()
      const paramsWithSearchId = {...action.params, searchId}
      const label = getPersistedSapiLabelWithFallback()

      const params = {
        ...paramsWithSearchId,
        clientRequestId,
        pollIteration,
        label
      }

      if (pollIteration === 0) {
        requestsTiming.start('raaSearch')
        logRAAService(RAAServiceEndpointNames.Search, RAAServiceStatus.start, {
          params,
          clientRequestId
        })
      }

      getRaaOffersService(params, raaUrl)
        .then(result => {
          logRAAService(
            RAAServiceEndpointNames.Search,
            RAAServiceStatus.received,
            {
              params,
              timings: requestsTiming.lap('raaSearch'),
              clientRequestId,
              pollIteration
            }
          )

          dispatch(fetchRaaOffersReceived(result, params))
        })
        .catch(error => {
          logRAAService(
            RAAServiceEndpointNames.Search,
            RAAServiceStatus.error,
            {
              error: action?.data?.response?.data,
              timings: requestsTiming.stop('raaSearch'),
              clientRequestId,
              pollIteration
            },
            'error'
          )
          pollingMonitor.clearAll()
          dispatch(fetchRaaOffersError(error))
        })
      break
    }

    case RAA_OFFERS_RECEIVED: {
      const searchId = getSearchId(state)
      const paramsWithSearchId = {...action.params, searchId}
      const raaFinished = Boolean(action.data?.status?.complete)

      if (raaFinished) {
        dispatch(fetchRaaOffersDone())
        break
      }

      window.setTimeout(() => {
        pollingMonitor.iterate()
        dispatch(fetchRaaOffers(paramsWithSearchId))
      }, Number(pollingTimeout))
      break
    }

    case RAA_OFFERS_DONE: {
      const clientRequestId = pollingMonitor.getId()
      const pollIteration = pollingMonitor.getIteration()
      logRAAService(RAAServiceEndpointNames.Search, RAAServiceStatus.done, {
        timings: requestsTiming.stop('raaSearch'),
        clientRequestId,
        pollIteration
      })
      pollingMonitor.clearAll()
      break
    }

    default:
      break
  }
}

export default middleware
