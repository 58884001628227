import {lensProp, map, over} from 'ramda'

import {defaultCountry} from '@daedalus/core/src/localization/business/countries'
import {OfferPrice} from '@daedalus/core/src/offer/types/offer'
import {TaxDisplayLogic} from '@daedalus/core/src/offer/types/TaxDisplayLogic'
import {Room} from '@daedalus/core/src/room/types/room'
import {
  Room as SapiRoom,
  RoomOffer,
  RoomPrice,
  RoomsSplitBooking
} from '@findhotel/sapi'

import {RoomsStateType} from './slice'
import {updateAllPrices} from './updateAllPrices'

export const formatPrices = (
  rooms: SapiRoom[],
  state: RoomsStateType
): Room[] =>
  deepUpdateRoomsPrices(rooms, price =>
    updateAllPrices(price, state.taxDisplayLogic || defaultCountry)
  )

export const deepUpdateRoomsPrices = (
  rooms: SapiRoom[],
  updateFn: (price: RoomPrice, taxDisplayLogic?: TaxDisplayLogic) => OfferPrice
): Room[] => {
  const offers = lensProp<SapiRoom, 'offers'>('offers')
  const prices = lensProp<RoomOffer, 'prices'>('prices')

  // @ts-expect-error - not sure that we can add proper types here
  return map(over(offers, map(over(prices, map(updateFn)))), rooms)
}

export const decorateSplitBookingPrices = (
  splitBooking: RoomsSplitBooking,
  taxDisplayLogic?: TaxDisplayLogic
) => {
  const taxLogic = taxDisplayLogic || defaultCountry
  const updatedOffers = splitBooking.offers.map(item => {
    const transformedPrices = item.offer.prices.map(price =>
      updateAllPrices(price, taxLogic)
    )

    return {
      ...item,
      offer: {...item.offer, prices: transformedPrices}
    }
  })

  const updatedPrices = splitBooking.prices.map(price =>
    updateAllPrices(price, taxLogic)
  )

  return {...splitBooking, offers: updatedOffers, prices: updatedPrices}
}
