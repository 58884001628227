import React from 'react'
import {useDispatch} from 'react-redux'
import {toggle} from 'opticks'

import {Button} from '@daedalus/atlas/Button'
import {Icon} from '@daedalus/atlas/Icon'
import {trackLoginClick} from '@daedalus/core/src/auth/modules/actions'
import {
  AuthenticationFlows,
  ShowPasswordlessLoginPayload,
  SourceComponentType
} from '@daedalus/core/src/auth/types/Auth'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

interface Props {
  onSignIn: ({
    sourceComponent,
    flow
  }: {
    sourceComponent: ShowPasswordlessLoginPayload['sourceComponent']
    flow: ShowPasswordlessLoginPayload['flow']
  }) => void
}

export const SignInButton = ({onSignIn}: Props) => {
  // Added as part of a707ecbb-home-page-redesign--v3
  const isHomePageRedesign = toggle(
    'a707ecbb-home-page-redesign--v3',
    false,
    true
  )
  const dispatch = useDispatch()

  const component = SourceComponentType.Header

  const handleSingInClick = () => {
    dispatch(trackLoginClick(component))

    onSignIn({
      sourceComponent: component,
      flow: AuthenticationFlows.SignUp
    })
  }

  return (
    <Button
      variant={isHomePageRedesign ? 'secondaryGradient' : 'quiet'}
      isRounded={isHomePageRedesign}
      size={isHomePageRedesign ? 'md' : 'lg'}
      dataId="SignInButton"
      onClick={handleSingInClick}
      iconStart={<Icon name={isHomePageRedesign ? 'Unlock' : 'Avatar'} />}
    >
      <FormattedMessageWrapper
        id="userWidget.signIn"
        defaultMessage="Sign in"
      />
    </Button>
  )
}
