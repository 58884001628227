import {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {ClientOptions, DeviceType, ProfileKey} from '@findhotel/sapi'

import {getDataFile} from '../../experiments/modules/selectors'
import {collectSapiVariationsParam} from '../../experiments/sapi'
import {initSapiClient} from '.'
import {searchApi} from './searchApi'

export interface SapiEffectParams extends ClientOptions {
  profileKey: ProfileKey
  language: string
  currency: string
  countryCode: string
  deviceType: DeviceType
  brand: string
  allowResetInstance?: boolean
}

/**
 * Initialize a SAPI client for your app
 * Use `await getSapiClient()` to retrieve it asynchronously in other parts of your code
 */
export function useInitializeSapi({
  profileKey,
  allowResetInstance,
  ...options
}: SapiEffectParams) {
  const dispatch = useDispatch()
  const dataFile = useSelector(getDataFile) as {
    featureFlags: Array<{key: string}>
  }
  const variations = collectSapiVariationsParam(
    // TODO: @votsa: Opticks should expose Optimisely's getEnabledFeatures method
    // to retrieve only enabled experiments
    // instead of reading all featureFlags from datafile
    // https://github.com/FindHotel/opticks/pull/46
    dataFile?.featureFlags?.map(({key}) => key)
  )

  // reset the RTK cache when options (eg. currency or countryCode) change, so users will see updated results
  const onReset = useCallback(() => {
    if (!allowResetInstance) return
    dispatch(searchApi.util.resetApiState())
  }, [allowResetInstance, dispatch])

  useEffect(() => {
    initSapiClient(
      profileKey,
      {
        ...options,
        variations
      },
      allowResetInstance,
      onReset
    )
  }, [options, allowResetInstance, onReset])
}
