import {BrandCode} from '../../../../brand/types'
import {getDomainName} from '../../../../utils/url'
import {
  cookieSecuritySettings,
  getCookie,
  removeCookie,
  setCookie
} from '../../../utils/cookies'

const COOKIE_NAME = 'brandOverride'

const makeCookieConfiguration = () => ({
  domain: getDomainName(window.location.hostname),
  expires: 1, // 1 day
  ...cookieSecuritySettings(true)
})

/**
 * Set brandOverride value to cookie
 * @param brandCode - BrandCode
 */
export const persistBrandOverrideOnCookie = (brandCode: BrandCode) => {
  if (!brandCode) return

  const cookieConfiguration = makeCookieConfiguration()
  setCookie(COOKIE_NAME, brandCode, cookieConfiguration)
}

/**
 * Get the brandOverride value from cookie
 */
export const getBrandOverrideFromCookie = (): BrandCode =>
  getCookie(COOKIE_NAME) as BrandCode

/**
 * Remove brandOverride cookie
 */
export const removeBrandOverrideFromCookie = () => {
  const cookieConfiguration = makeCookieConfiguration()
  removeCookie(COOKIE_NAME, cookieConfiguration)
}
