// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgFaceBigSmile2 = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.5a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13ZM2 10a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm4-2.25A.75.75 0 0 1 6.75 7h1.5a.75.75 0 0 1 0 1.5h-1.5A.75.75 0 0 1 6 7.75Zm5 0a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1-.75-.75Zm-4.799 2.489A.75.75 0 0 1 6.75 10h6.5a.75.75 0 0 1 .748.803l-.748-.053.748.053v.008l-.002.013-.003.04a5.848 5.848 0 0 1-.095.59c-.08.369-.222.87-.477 1.381C12.9 13.873 11.873 15 10 15c-1.873 0-2.902-1.127-3.42-2.165a5.878 5.878 0 0 1-.573-1.971l-.003-.04-.002-.013v-.006s0-.002.748-.055l-.748.055a.753.753 0 0 1 .2-.566Zm1.46 1.261c.063.21.147.439.26.665.356.712.952 1.335 2.079 1.335 1.127 0 1.723-.623 2.08-1.335.112-.226.196-.455.259-.665H7.66Z"
    />
  </svg>
)

export default SvgFaceBigSmile2
