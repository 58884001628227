import Psd2Eps from './providers/EPS'
import {Psd2Ppn} from './providers/noop'
import Psd2Stripe from './providers/Stripe'
import {ServerCode} from './types'

type Psd2Provider = typeof Psd2Eps | typeof Psd2Stripe | typeof Psd2Ppn

class Psd2 {
  static serverCode?: ServerCode
  private static provider?: Psd2Provider

  private constructor() {}

  /**
   * getProvider will return Psd2 provider based on the server code.
   * For all MOR deals server code is STP (Stripe), whereas for assisted bookings server code is the same as provider code (EPS or PPN).
   * Since server code is not easily accessible throughout the booking flow, calling getProvider()
   * with no arguments will return previously cached Psd2 provider. Calling it with the new server code will override the cached value.
   */
  public static getProvider(providerCode?: ServerCode) {
    if (this.provider && (!providerCode || providerCode === this.serverCode)) {
      return this.provider
    }

    this.serverCode = providerCode

    switch (providerCode) {
      case ServerCode.Eps:
        this.provider = Psd2Eps
        break
      case ServerCode.Stripe:
        this.provider = Psd2Stripe
        break
      case ServerCode.Ppn:
        this.provider = Psd2Ppn
        break
    }

    return this.provider
  }

  public static resetProvider() {
    this.provider = undefined
  }
}

export default Psd2
