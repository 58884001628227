import {doesWindowObjectExist} from './doesWindowObjectExist'

/**
 *
 * Set item on local storage or session storage in a safe way, logging an error when the window objects does not exists
 *
 * @example set `searchUri` on local storage
 *
 * ```
 * safelySetStorageItem(localStorage, 'searchUri', 'https://www.findhotel.net/', 'setSearchUri failed')
 * ```
 *
 * @param storage - the storage where to set the item. Local storage or Session storage
 * @param param - the chosen parameter to set on the storage
 * @param value - the value to set on the storage
 * @param errorString - the error message to log when it fails
 *
 */
export const safelySetStorageItem = (
  storage: typeof localStorage | typeof sessionStorage,
  param: string,
  value: string,
  errorString?: string
): void => {
  try {
    if (doesWindowObjectExist()) {
      storage.setItem(param, value)
    }
  } catch {
    console.error(errorString)
  }
}
