import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {DatePickerVariant} from '@daedalus/core/src/datePicker/types'

import {DAY_SIZE} from '../styles'

export const WeekDay = styled.div<{
  variant: DatePickerVariant
  isWeekend?: boolean
}>(({theme, variant, isWeekend = false}) => {
  const shared = css`
    ${theme.typography.text.bodyS};
    color: ${theme.colors.content.neutral.c600};
    text-align: center;

    & abbr[title] {
      border-bottom: none;
      text-decoration: none;
    }
  `

  if (variant === 'vertical') {
    return css`
      ${shared};
      display: table-cell;
      text-align: center;
    `
  }

  return css`
    ${shared};
    width: ${DAY_SIZE}px;
    margin: ${theme.layout.spacing.s500}px 0;
    ${isWeekend &&
    css`
      background-color: ${theme.colors.background.neutral.c100};
      border-radius: ${theme.layout.radius.sm}px;
    `}
  `
})
