// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPersonWithLuggage = ({
  color = 'currentColor',
  size = 20
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 3.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM5 5a3 3 0 1 1 4.992 2.244c.833.236 1.496.647 1.99 1.25.662.813.924 1.868.996 3.006h3.272a.75.75 0 0 1 .75.75v5a.75.75 0 0 1-.75.75h-8.5A2.75 2.75 0 0 1 5 15.25V13H3.75a.75.75 0 0 1-.75-.75c0-1.42.212-2.766 1.019-3.755.492-.604 1.155-1.015 1.99-1.251A2.993 2.993 0 0 1 5 5Zm6.474 6.5c-.07-.93-.281-1.599-.655-2.057C10.379 8.904 9.58 8.5 8 8.5c-1.58 0-2.38.404-2.819.943-.374.458-.585 1.127-.655 2.057H5.75a.75.75 0 0 1 .75.75v3c0 .69.56 1.25 1.25 1.25H9.5v-4.25a.75.75 0 0 1 .75-.75h1.224Zm-.474 5h4.5V13H11v3.5Z"
    />
  </svg>
)

export default SvgPersonWithLuggage
