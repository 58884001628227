import countNumberOfNights from 'utils/countNumberOfNights'

export interface GetNumberOfNightsParams {
  checkIn: string
  checkOut: string
}

const getNumberOfNights = (reqParams: GetNumberOfNightsParams): number =>
  countNumberOfNights(new Date(reqParams.checkIn), new Date(reqParams.checkOut))

export default getNumberOfNights
