// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCheckOutArrow = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 12 44"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      d="M12 22v22a7.856 7.856 0 0 1-7.579-5.789L.287 23.052a4 4 0 0 1 0-2.104l4.134-15.16A7.855 7.855 0 0 1 12 0v22Z"
      fill="#3C69F5"
    />
  </svg>
)

export default SvgCheckOutArrow
