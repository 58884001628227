import React, {ChangeEvent, ElementType, forwardRef, ReactNode} from 'react'
import InputMask from 'react-input-mask'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'

import {getLinariaClassName} from '../../../utils/getLinariaClassName'
import {Button} from '../Button'
import {
  baseInputBackgroundStyles,
  baseInputBaseLayoutStyles,
  baseInputBorderStyles,
  baseInputDisabledStyles,
  baseInputInvalidStateStyles,
  baseInputRadiusStyles,
  baseInputShadowStyles,
  baseInputSizeStyles,
  baseInputTypographyStyles,
  CaptionWrapper,
  ClearButtonWrapper,
  EndIconsWrapper,
  getBaseInputIconPaddingStyles,
  getClassName,
  InputBaseSize,
  InputTextType,
  InputWrapper,
  StartIconsWrapper,
  TextTransform,
  ValidationBadge
} from '../helpers/InputBase'
import {Icon} from '../Icon'
import {Text} from '../Text'

interface Props {
  /** Field autocomplete id */
  autoComplete?: string
  /** Pass through classname to allow styles overrides */
  className?: string
  /** An object of custom props that is not explicitly supported, but might be required for some custom implementations.
   * This can also be used to override the explicit props, like a custom onChange function.
   * or as a way to get rid of the chrome autofill.
   * */
  // eslint-disable-next-line
  customProps?: Record<string, any>
  /** HTML Tag to wrap the caption Text component */
  captionTag?: ElementType
  /** Message to display below input. Changes color along with validation highlights. */
  caption?: ReactNode
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** Identify the close button for selection in integration tests, FullStory, etc. */
  closeButtonDataId?: string
  /** Whether the input is disabled */
  disabled?: boolean
  /** Whether the input has an error */
  hasError?: boolean
  /** An Icon to display on the left side of the input */
  icon?: JSX.Element
  /** ID of this component, passed down by the controlling parent component */
  id: string
  /** Whether the input content is private and should be masked in FullStory */
  isPrivate?: boolean
  /** Whether the input is rounded */
  isRounded?: boolean
  /** Whether the user has interacted with this input */
  isTouched?: boolean
  /** Display verified label instead of valid icon */
  isVerified?: boolean
  /** Input list property https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#list */
  list?: string
  /** The mask used to format the user input */
  mask?: string
  /** Name of this component, passed down by the controlling parent component */
  name: string
  /** An onFocus callback passed down by the controlling parent component */
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  /** An onBlur callback passed down by the controlling parent component */
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  /** An onChange callback passed down by the controlling parent component */
  onChange?: (value: string, event: ChangeEvent) => void
  /** An onClear callback passed down by the controlling parent component. The clear button will only be visible if this callback is present. */
  onClear?: (event: React.SyntheticEvent) => void
  /** Content to be used as the input placeholder. It must be translated string */
  placeholder: string
  /** Formik function for setting a specific field's value. Used to update the value without sending an HTML event. */
  setFieldValue?: (
    field: string,
    value: unknown,
    shouldValidate?: boolean
  ) => void
  /** The size of the input */
  size?: InputBaseSize
  /** CSS text-transform style to apply to the input field */
  textTransformType?: TextTransform
  /** Specify the HTML input type */
  type?: InputTextType
  /** The value passed down by the controlling parent component */
  value?: string | number
  /** Style prop is used by Linaria to pass CSS variables in cases when we need to style custom components in this way: `const StyledInputText = styled(InputText)` */
  style?: React.CSSProperties
}

export const MaskedInput = styled(InputMask)<{
  showInvalid?: boolean
  showValid?: boolean
  withStartIcon?: boolean
  withEndIcon?: boolean
  sizeVariant?: InputBaseSize
  isRounded?: boolean
  textTransformType?: TextTransform
  value?: Props['value']
}>`
  ${baseInputBaseLayoutStyles}
  ${baseInputBorderStyles}
  ${baseInputBackgroundStyles}
  ${baseInputInvalidStateStyles}
  ${baseInputShadowStyles}
  ${baseInputRadiusStyles}
  ${baseInputDisabledStyles}

  padding-left: ${({
    sizeVariant,
    withStartIcon,
    withEndIcon,
    showInvalid,
    showValid,
    isRounded
  }) =>
    getBaseInputIconPaddingStyles({
      sizeVariant,
      withStartIcon,
      withEndIcon,
      showInvalid,
      showValid,
      isRounded
    }).paddingLeft};
  padding-right: ${({
    sizeVariant,
    withStartIcon,
    withEndIcon,
    showInvalid,
    showValid,
    isRounded
  }) =>
    getBaseInputIconPaddingStyles({
      sizeVariant,
      withStartIcon,
      withEndIcon,
      showInvalid,
      showValid,
      isRounded
    }).paddingRight};
  text-transform: ${({textTransformType}) =>
    textTransformType ? textTransformType : TextTransform.none};
  z-index: 0;
`

export const TextInput = styled.input<{
  showInvalid?: boolean
  showValid?: boolean
  withStartIcon?: boolean
  withEndIcon?: boolean
  sizeVariant?: InputBaseSize
  isRounded?: boolean
  textTransformType?: TextTransform
  value?: Props['value']
}>`
  ${baseInputBaseLayoutStyles}
  ${baseInputBackgroundStyles}
  ${baseInputBorderStyles}
  ${baseInputInvalidStateStyles}
  ${baseInputShadowStyles}
  ${baseInputRadiusStyles}
  ${baseInputDisabledStyles}

  padding-left: ${({
    sizeVariant,
    withStartIcon,
    withEndIcon,
    showInvalid,
    showValid,
    isRounded
  }) =>
    getBaseInputIconPaddingStyles({
      sizeVariant,
      withStartIcon,
      withEndIcon,
      showInvalid,
      showValid,
      isRounded
    }).paddingLeft};
  padding-right: ${({
    sizeVariant,
    withStartIcon,
    withEndIcon,
    showInvalid,
    showValid,
    isRounded
  }) =>
    getBaseInputIconPaddingStyles({
      sizeVariant,
      withStartIcon,
      withEndIcon,
      showInvalid,
      showValid,
      isRounded
    }).paddingRight};
  text-transform: ${({textTransformType}) =>
    textTransformType ? textTransformType : TextTransform.none};
  z-index: 0;
`

export const InputText = forwardRef<HTMLInputElement, Props>(
  (
    {
      autoComplete,
      className,
      customProps,
      caption,
      captionTag,
      dataId,
      closeButtonDataId,
      disabled,
      hasError,
      icon,
      id,
      isPrivate,
      isRounded,
      isTouched,
      isVerified,
      list,
      mask,
      name,
      onBlur,
      onFocus,
      onChange,
      onClear,
      placeholder,
      setFieldValue,
      size = 'lg',
      textTransformType = TextTransform.none,
      type = InputTextType.text,
      value,
      style
    },
    ref
  ) => {
    const showInvalid = isTouched && hasError
    const showValid = isTouched && !hasError

    const handleChange = (event: ChangeEvent) => {
      const newValue = (event.currentTarget as HTMLInputElement).value

      if (setFieldValue) {
        setFieldValue(name, newValue)
      } else if (onChange) {
        onChange(newValue, event)
      }
    }

    const commonProps = {
      autoComplete,
      id,
      disabled,
      isRounded,
      showInvalid,
      showValid,
      maskChar: null,
      name,
      list,
      sizeVariant: size,
      textTransformType,
      type,
      value,
      placeholder,
      onBlur,
      onFocus,
      onChange: handleChange,
      withStartIcon: Boolean(icon),
      withEndIcon: Boolean(onClear),
      ['data-id']: dataId,
      ['data-field-error']: hasError
    }

    return (
      <InputWrapper className={className}>
        {mask ? (
          <MaskedInput
            inputRef={ref}
            mask={mask}
            {...commonProps}
            {...customProps}
            className={cx(
              `${getLinariaClassName(MaskedInput)}--size-${size}`,
              `${baseInputSizeStyles}--size-${size}`,
              `${baseInputTypographyStyles}--size-${size}`,
              isRounded && 'isRounded',
              showInvalid && 'isInvalid',
              getClassName(isPrivate)
            )}
            style={style}
          />
        ) : (
          <TextInput
            ref={ref}
            {...commonProps}
            {...customProps}
            className={cx(
              `${getLinariaClassName(TextInput)}--size-${size}`,
              `${baseInputSizeStyles}--size-${size}`,
              `${baseInputTypographyStyles}--size-${size}`,
              isRounded && 'isRounded',
              showInvalid && 'isInvalid',
              getClassName(isPrivate)
            )}
            style={style}
          />
        )}

        <StartIconsWrapper sizeVariant={size}>{icon}</StartIconsWrapper>
        <EndIconsWrapper isRounded={isRounded} sizeVariant={size}>
          <ValidationBadge
            isVerified={isVerified}
            showInvalid={showInvalid}
            showValid={showValid}
          />

          <ClearButtonWrapper>
            {onClear && value && (
              <Button
                isRounded
                hasTouchArea={size === 'lg'}
                variant="quiet"
                size="sm"
                dataId={closeButtonDataId}
                iconStart={
                  <Icon
                    name="Clear"
                    size="md"
                    colorPath="content.neutral.c600"
                  />
                }
                onClick={onClear}
              />
            )}
          </ClearButtonWrapper>
        </EndIconsWrapper>

        {caption && (
          <CaptionWrapper showInvalid={showInvalid}>
            <Text variant="labelXS" as={captionTag}>
              {caption}
            </Text>
          </CaptionWrapper>
        )}
      </InputWrapper>
    )
  }
)

// eslint-disable-next-line fp/no-mutation
InputText.displayName = 'InputText'
