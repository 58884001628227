// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgSettingsSliders = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 4.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM2.75 6.75h2.345a3.001 3.001 0 0 0 5.81 0h6.345a.75.75 0 0 0 0-1.5h-6.345a3.001 3.001 0 0 0-5.81 0H2.75a.75.75 0 0 0 0 1.5ZM2 14a.75.75 0 0 1 .75-.75h7.345a3.001 3.001 0 0 1 5.81 0h1.345a.75.75 0 0 1 0 1.5h-1.345a3.001 3.001 0 0 1-5.81 0H2.75A.75.75 0 0 1 2 14Zm9.5 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
    />
  </svg>
)

export default SvgSettingsSliders
