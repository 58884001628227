import {styled} from '@linaria/react'

import {cssTheme} from '../../../themes'

export const Header = styled.div`
  background: ${cssTheme.colors.background.neutral.c000};
  position: relative;
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${cssTheme.layout.spacing.s500} ${cssTheme.layout.spacing.s900}
    ${cssTheme.layout.spacing.s200};
`

export const HeaderContent = styled.div`
  touch-action: none;
  flex-grow: 1;
  text-align: center;
  ${cssTheme.typography.text.titleM};
  margin: 0;
  color: ${cssTheme.colors.content.neutral.c950};
`

export const SubHeader = styled.div`
  flex-grow: 1;
  text-align: center;
  ${cssTheme.typography.text.titleS};
  color: ${cssTheme.colors.content.neutral.c950};
  padding: 0 ${cssTheme.layout.spacing.s900} ${cssTheme.layout.spacing.s400};
`

export const OverlayContent = styled.div`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  height: 100%;
  /* needed to apply extra padding at the bottom for iOS gesture bar */
  padding-bottom: env(safe-area-inset-bottom);
`

export const Handle = styled.div`
  touch-action: none;
  width: 32px;
  height: 4px;
  border-radius: ${cssTheme.layout.radius.rounded};
  background-color: ${cssTheme.colors.background.neutral.c200};
  margin-left: auto;
  margin-right: auto;
`
