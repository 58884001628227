import {useEffect} from 'react'
import {useSelector} from 'react-redux'

import {selectIsLoading} from '../../auth/modules/selectors'
import {useOpticks} from '../components/OpticksProvider'
import {AudienceSegmentationAttributes} from '../types'

export const useOpticksAudienceSegmentation = (
  audienceAttributes: AudienceSegmentationAttributes
) => {
  const Opticks = useOpticks()
  const isLoading = useSelector(selectIsLoading)

  useEffect(() => {
    if (Opticks && !isLoading) {
      Opticks.setAudienceSegmentationAttributes({
        ...audienceAttributes
      })
    }
  }, [isLoading, Opticks, audienceAttributes])
}
