import React from 'react'
import {styled} from '@linaria/react'
import {ThemeType} from 'types/Theme'

import SvgCircle from '../../../foundation/illustrations/Circle'
import SvgDiamond from '../../../foundation/illustrations/Diamond'
import SvgTriangle from '../../../foundation/illustrations/Triangle'
import {cssTheme} from '../../../themes'
import {AlertVariant} from './Alert'

interface ShapedBackgroundProps {
  /** Pass through classname to allow styles overrides */
  className?: string
  theme?: ThemeType
  variant?: AlertVariant
}

type ShapeProps = {
  left?: number
  top?: number
  right?: number
  bottom?: number
}

export const ShapedBackgroundWrapper = styled.div<{variant: AlertVariant}>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: ${({variant}) => cssTheme.colors.alert[variant].background};
  color: ${({variant}) => cssTheme.colors.alert[variant].shapes};
`

export const Shape = styled.div<ShapeProps>`
  position: absolute;
  left: ${({left}) => (left ? `${left}px` : 'auto')};
  right: ${({right}) => (right ? `${right}px` : 'auto')};
  top: ${({top}) => (top ? `${top}px` : 'auto')};
  bottom: ${({bottom}) => (bottom ? `${bottom}px` : 'auto')};

  > svg {
    display: block;
  }
`

export const ShapedBackground = ({
  variant = 'default',
  className
}: ShapedBackgroundProps) => {
  return (
    <ShapedBackgroundWrapper variant={variant} className={className}>
      <Shape left={-56} top={32}>
        <SvgTriangle />
      </Shape>
      <Shape right={-86} top={-8}>
        <SvgDiamond />
      </Shape>
      <Shape right={88} bottom={-60}>
        <SvgCircle />
      </Shape>
    </ShapedBackgroundWrapper>
  )
}
