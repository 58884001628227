// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgFacebookColor = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 17.117a.883.883 0 0 1-.883.883h-3.573v-6.386h2.063l.31-2.489h-2.373V7.536c0-.72.192-1.211 1.187-1.211H16V4.097c-.22-.03-.973-.098-1.849-.098-1.83 0-3.082 1.16-3.082 3.29v1.835H9v2.489h2.07V18H2.882A.883.883 0 0 1 2 17.117V2.883C2 2.395 2.395 2 2.883 2h14.234c.488 0 .883.395.883.883v14.234Z"
      fill="#3C5A99"
    />
  </svg>
)

export default SvgFacebookColor
