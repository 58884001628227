import React from 'react'

type OsanoEvents =
  | 'osano-cm-analytics'
  | 'osano-cm-cookie-blocked'
  | 'osano-cm-consent-changed'
  | 'osano-cm-consent-new'
  | 'osano-cm-consent-saved'
  | 'osano-cm-iframe-blocked'
  | 'osano-cm-initialized'
  | 'osano-cm-locale-updated'
  | 'osano-cm-marketing'
  | 'osano-cm-opt-out'
  | 'osano-cm-personalization'
  | 'osano-cm-script-blocked'
  | 'osano-cm-storage'
  | 'osano-cm-ui-changed'

export const CONSENT_CATEGORIES: Array<keyof ConsentObject> = [
  'ANALYTICS',
  'MARKETING',
  'PERSONALIZATION',
  'ESSENTIAL',
  'OPT-OUT',
  'STORAGE'
]
export type ConsentType = 'ACCEPT' | 'DENY'
export interface ConsentObject {
  ANALYTICS: ConsentType
  MARKETING: ConsentType
  PERSONALIZATION: ConsentType
  ESSENTIAL: ConsentType

  /*special IAB categories*/
  'OPT-OUT': ConsentType
  STORAGE: ConsentType
}

declare global {
  interface Window {
    Osano?: {
      cm?: {
        locale: string
        jurisdiction: string
        dialogOpen: boolean
        drawerOpen: boolean
        getConsent: () => ConsentObject
        showDrawer: () => void
        addEventListener: (event: OsanoEvents, listener: () => void) => void
        removeEventListener: (event: OsanoEvents, listener: () => void) => void
      }
    }
    /**
     * Mobile app will inject this variable to indicate that the user has already given
     * consent on the app. It will contain a user id that we set to the Osano SDK so it
     * can fetch the consent data from the server. This feature is called Cross Device Consent.
     */
    osanoMobileAppIdentifier?: string
  }
}

const GDPR_COUNTRIES = new Set([
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LI',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK'
])

/**
 * Uses the `Osano` geolocation API to determine the user location data
 *
 * @returns The user country and state, or null if the sdk is still loading
 */
const getGeolocationData = () => {
  if (typeof window === 'undefined') return null

  const geolocationData = window.Osano?.cm?.jurisdiction

  if (!geolocationData) {
    return null
  }

  const [country, state] = geolocationData.toUpperCase().split('-')

  return {country, state}
}

/**
 * Uses the `OneTrust` geolocation API to determine the user country and state.
 * If the SDK is still loading, it will listen to the load event and updates
 * with the correct value as soon as it is available.
 *
 * @returns The user country and state, or null if the sdk is still loading
 */
const useGeolocationData = () => {
  const [geolocationData, setGeolocationData] =
    React.useState(getGeolocationData)

  const updateGeolocationData = () => {
    setGeolocationData(getGeolocationData)
  }

  React.useEffect(() => {
    if (geolocationData) return

    window.addEventListener('osano-cm-initialized', updateGeolocationData)

    return () => {
      window.removeEventListener('osano-cm-initialized', updateGeolocationData)
    }
  }, [geolocationData])

  return geolocationData
}

/**
 * Uses the OneTrust geolocation API to determine if the user country
 * needs to comply with GDPR.
 *
 * @returns boolean that indicates if the country must comply with GDRP,
 * or null if the SDK is still loading.
 */
export const useIsGDPRCountry = (): boolean | null => {
  const geolocationData = useGeolocationData()

  if (!geolocationData) return null

  return GDPR_COUNTRIES.has(geolocationData.country)
}

/**
 * Uses the OneTrust geolocation API to determine if the user state
 * needs to comply with CCPA.
 *
 * @returns boolean that indicates if the country must comply with CCPA,
 * or null if the SDK is still loading.
 */
export const useIsCCPAState = (): boolean | null => {
  const geolocationData = useGeolocationData()

  if (!geolocationData) return null

  return geolocationData.country === 'US' && geolocationData.state === 'CA'
}
