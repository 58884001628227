export const CHANGE_PREFERENCES = 'CHANGE_PREFERENCES'

interface ChangePreferencesParams {
  currency?: string
  language?: string
}

interface ChangePreferencesAction extends ChangePreferencesParams {
  type: typeof CHANGE_PREFERENCES
}

export const changePreferences = ({
  currency,
  language
}: ChangePreferencesParams): ChangePreferencesAction => ({
  type: CHANGE_PREFERENCES,
  currency,
  language
})
