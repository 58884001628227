// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgBriefcase = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.75 3.5c-.69 0-1.25.56-1.25 1.25V5h5v-.25c0-.69-.56-1.25-1.25-1.25h-2.5ZM6 4.75V5H4.75A2.75 2.75 0 0 0 2 7.75v7.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-7.5A2.75 2.75 0 0 0 15.25 5H14v-.25A2.75 2.75 0 0 0 11.25 2h-2.5A2.75 2.75 0 0 0 6 4.75ZM4.75 6.5c-.69 0-1.25.56-1.25 1.25v2.75H6v-.75a.75.75 0 0 1 1.5 0v.75h5v-.75a.75.75 0 0 1 1.5 0v.75h2.5V7.75c0-.69-.56-1.25-1.25-1.25H4.75ZM12.5 12v.25a.75.75 0 0 0 1.5 0V12h2.5v3.25c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25V12H6v.25a.75.75 0 0 0 1.5 0V12h5Z"
    />
  </svg>
)

export default SvgBriefcase
