// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgGemsText = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 22 6"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      d="M6.176 5.792V2.92H3.4v.84h1.872c-.048.488-.48 1.272-1.624 1.272-.976 0-1.872-.696-1.872-2.08 0-1.408.96-2.064 1.88-2.064.904 0 1.464.544 1.656 1.184l.904-.344C5.912.792 5.032 0 3.656 0 2.184 0 .8 1.088.8 2.952c0 1.872 1.312 2.96 2.792 2.96.904 0 1.488-.424 1.736-.84l.072.72h.776ZM7.939 3.416a.935.935 0 0 1 .952-.864c.64 0 .944.408.96.864H7.939Zm2.016 1.016c-.128.384-.416.68-.976.68-.576 0-1.04-.424-1.064-1.016h2.848c.008-.016.024-.144.024-.296 0-1.232-.704-2.024-1.904-2.024-.984 0-1.896.816-1.896 2.048 0 1.32.936 2.088 1.992 2.088.936 0 1.552-.552 1.76-1.232l-.784-.248ZM12.608 5.792V3.48c0-.496.312-.864.808-.864.528 0 .752.352.752.8v2.376h.912V3.48c0-.48.32-.864.808-.864.52 0 .752.344.752.8v2.376h.896V3.264c0-1.032-.672-1.48-1.376-1.48-.504 0-.96.176-1.264.688-.208-.456-.656-.688-1.168-.688-.44 0-.928.208-1.152.608v-.496h-.888v3.896h.92ZM18.377 4.736c.048.44.48 1.176 1.608 1.176.992 0 1.472-.656 1.472-1.248 0-.576-.384-1.024-1.12-1.184l-.592-.12c-.24-.048-.392-.2-.392-.408 0-.24.232-.448.56-.448.52 0 .688.36.72.584l.784-.224c-.064-.384-.416-1.088-1.504-1.088-.808 0-1.432.576-1.432 1.256 0 .536.36.984 1.048 1.136l.576.128c.312.064.456.224.456.432 0 .24-.2.448-.584.448-.496 0-.76-.312-.792-.664l-.808.224Z"
      fill="#fff"
    />
  </svg>
)

export default SvgGemsText
