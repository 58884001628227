import {
  BrowserHistoryBuildOptions,
  createBrowserHistory,
  createMemoryHistory,
  MemoryHistoryBuildOptions
} from 'history'

/**
 * Creates browsers history instance
 *
 * @param options - options passed to createBrowserHistory call
 * @returns instance of history object
 */
export const createHistory = (options?: BrowserHistoryBuildOptions) =>
  createBrowserHistory(options)
/**
 * Creates virtual/memory history instance, used for tests and mocks
 *
 * @param options - options passed to createMemoryHistory call
 * @returns instance of history object
 */
export const createTestHistory = (options?: MemoryHistoryBuildOptions) =>
  createMemoryHistory(options)
