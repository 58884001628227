import {memo, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {identifyAuthenticatedUser} from '../modules/actions'
import {getUser, selectIsAuthenticated} from '../modules/selectors'
import {isCognitoRoute} from '../utils/url'

const TrackUserAuthentication = () => {
  const user = useSelector(getUser)

  const isAuthenticated = useSelector(selectIsAuthenticated)
  const dispatch = useDispatch()

  const {pathname} = window.location

  useEffect(() => {
    if (isAuthenticated && user && !isCognitoRoute(pathname))
      dispatch(identifyAuthenticatedUser(user))
  }, [
    isAuthenticated,
    user?.attributes?.sub,
    identifyAuthenticatedUser,
    pathname
  ])

  return null
}

export default memo(TrackUserAuthentication)
