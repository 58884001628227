// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgSoapPump = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.288 2.513A.75.75 0 0 1 9 2h2a.75.75 0 0 1 .711.513l.163.487H15a.75.75 0 0 1 0 1.5h-2.626l.528 1.584a2.75 2.75 0 0 1 2.053 2.34l.907 7.62A1.75 1.75 0 0 1 14.124 18H5.876a1.75 1.75 0 0 1-1.738-1.957l.907-7.618a2.75 2.75 0 0 1 2.053-2.34l1.19-3.572Zm2.171.987.834 2.5H8.707l.834-2.5h.918Zm-2.683 4a1.25 1.25 0 0 0-1.241 1.102l-.907 7.618a.25.25 0 0 0 .248.28h8.248a.25.25 0 0 0 .248-.28l-.907-7.618A1.25 1.25 0 0 0 12.224 7.5H7.776Z"
    />
  </svg>
)

export default SvgSoapPump
