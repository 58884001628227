import React, {forwardRef, ReactNode} from 'react'
import {cx} from '@linaria/core'
import {ClassName} from '@linaria/core/types/cx'
import {styled} from '@linaria/react'

import {SpacingPropsType} from '../../../../../types/Layout'
import {cssTheme} from '../../../../themes'

type Props = {
  /** CSS align-content property. Sets the distribution of space between and around content items. */
  alignContent?: string
  /** CSS align-items property. Sets the align-self value on all direct children as a group. */
  alignItems?: string
  /** Content to be displayed inside the Grid */
  children: ReactNode
  /** Pass through classname to allow styles overrides */
  className?: string
  /** React ref object */
  // eslint-disable-next-line
  ref?: React.RefObject<any>
  /** Whether this is a container Grid */
  container?: boolean
  /** CSS direction property. Sets the direction of the child Grid items. */
  direction?: string
  /** Theme spacing index for setting the gap size */
  gap?: SpacingPropsType
  /** CSS justify-content property. Defines how the browser distributes space between and around child Grid items. */
  justify?: string
  /** onClick function to be passed down to the Grid div */
  onClick?: () => null
  /** Theme spacing index for setting the combined gutter size */
  spacing?: SpacingPropsType
  /** Custom styling to be passed down to the Grid div */
  style?: Record<string, number | string>
  /** CSS flex-wrap property. Sets whether child Grid items are forced onto one line or can wrap onto multiple lines. */
  wrap?: string
  /** Grid column width of this Grid item for large desktop screens */
  desktopLg?: number | string
  /** Grid column width of this Grid item for medium desktop screens */
  desktopMd?: number | string
  /** Grid column width of this Grid item for small desktop screens */
  desktopSm?: number | string
  /** Grid column width of this Grid item for extra small desktop screens */
  desktopXs?: number | string
  /** Grid column width of this Grid item for small mobile screens */
  mobileSm?: number | string
  /** Grid column width of this Grid item for large mobile screens */
  mobileLg?: number | string
}

interface StyleProps {
  gap?: SpacingPropsType
}

const GridElement = styled('div')<StyleProps>`
  gap: ${({gap = 's000'}) => cssTheme.layout.spacing[gap]};
`

const defaultProps = {
  alignContent: 'stretch',
  alignItems: 'stretch',
  container: false,
  direction: 'row',
  justify: 'flex-start',
  spacing: 's500',
  wrap: 'wrap'
} as Props

export const Grid = forwardRef(
  (
    {
      alignContent = defaultProps.alignContent,
      alignItems = defaultProps.alignItems,
      className,
      container = defaultProps.container,
      direction = defaultProps.direction,
      gap,
      justify = defaultProps.justify,
      spacing = defaultProps.spacing,
      wrap = defaultProps.wrap,
      mobileSm,
      mobileLg,
      desktopXs,
      desktopSm,
      desktopMd,
      desktopLg,
      children,
      onClick,
      style
    }: Props,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const combinedClassName: ClassName[] = [
      container && 'grid-container',
      !container && 'grid-item',
      direction !== defaultProps.direction &&
        `direction-mobileSm-${String(direction)}`,
      wrap !== defaultProps.wrap && `wrap-mobileSm-${String(wrap)}`,
      alignItems !== defaultProps.alignItems &&
        `align-items-mobileSm-${String(alignItems)}`,
      alignContent !== defaultProps.alignContent &&
        `align-content-mobileSm-${String(alignContent)}`,
      justify !== defaultProps.justify && `justify-mobileSm-${String(justify)}`,
      container && spacing && `grid-spacing-${String(spacing)}`,
      false && 'grid-mobileSm',
      Boolean(mobileSm) && `grid-mobileSm-${String(mobileSm)}`,
      false && 'grid-mobileLg',
      Boolean(mobileLg) && `grid-mobileLg-${String(mobileLg)}`,
      false && 'grid-desktopXs',
      Boolean(desktopXs) && `grid-desktopXs-${String(desktopXs)}`,
      false && 'grid-desktopSm',
      Boolean(desktopSm) && `grid-desktopSm-${String(desktopSm)}`,
      false && 'grid-desktopMd',
      Boolean(desktopMd) && `grid-desktopMd-${String(desktopMd)}`,
      false && 'grid-desktopLg',
      Boolean(desktopLg) && `grid-desktopLg-${String(desktopLg)}`
    ]

    return (
      <GridElement
        gap={gap}
        className={cx(...combinedClassName, className)}
        style={style}
        onClick={onClick}
        ref={ref}
      >
        {children}
      </GridElement>
    )
  }
)

// eslint-disable-next-line fp/no-mutation
Grid.displayName = 'Grid'
