// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgEmojiHappy = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6.75A4.75 4.75 0 0 1 6.75 2h6.5A4.75 4.75 0 0 1 18 6.75v6.5A4.75 4.75 0 0 1 13.25 18h-6.5A4.75 4.75 0 0 1 2 13.25v-6.5ZM6.75 3.5A3.25 3.25 0 0 0 3.5 6.75v6.5a3.25 3.25 0 0 0 3.25 3.25h6.5a3.25 3.25 0 0 0 3.25-3.25v-6.5a3.25 3.25 0 0 0-3.25-3.25h-6.5ZM8 8.25a.25.25 0 1 0 0-.5.25.25 0 0 0 0 .5ZM6.75 8a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm5.25.25a.25.25 0 1 0 0-.5.25.25 0 0 0 0 .5ZM10.75 8a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm3.005 4.555A5.476 5.476 0 0 1 10 14a5.476 5.476 0 0 1-3.754-1.445 5.963 5.963 0 0 1-.868-.984 4.946 4.946 0 0 1-.29-.468l-.005-.01-.002-.005-.001-.001s0-.002.67-.337l.67-.336v-.002l.005.01.032.056a4.457 4.457 0 0 0 .798.967A3.977 3.977 0 0 0 10 12.5a3.977 3.977 0 0 0 2.745-1.055 4.458 4.458 0 0 0 .798-.967l.032-.056.006-.01-.001.001s0 .002.67.337l.67.336v.002l-.003.004-.005.01c-.004.01-.01.02-.017.032a4.938 4.938 0 0 1-.273.436c-.189.27-.474.628-.867.985Z"
    />
  </svg>
)

export default SvgEmojiHappy
