// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgStarSolid = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 16 16"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="M7.21 2.085a.886.886 0 0 1 1.58 0l1.32 2.605c.13.254.373.431.654.475l2.886.451a.886.886 0 0 1 .488 1.503L12.07 9.18a.886.886 0 0 0-.25.768l.463 2.884a.886.886 0 0 1-1.279.93l-2.6-1.332a.886.886 0 0 0-.807 0l-2.6 1.331a.886.886 0 0 1-1.278-.929l.463-2.884a.886.886 0 0 0-.25-.768l-2.07-2.06a.886.886 0 0 1 .49-1.504l2.885-.45a.886.886 0 0 0 .653-.476l1.32-2.605Z" />
  </svg>
)

export default SvgStarSolid
