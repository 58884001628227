import React, {ReactElement, ReactNode} from 'react'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'

import {cssTheme} from '../../../themes'
import {getLinariaClassName} from '../../../utils/getLinariaClassName'
import minus from '../../../utils/minus'
import {Button} from '../Button'
import {Grid} from '../helpers/Grid'
import {Icon} from '../Icon'
import {ShapedBackground} from './ShapedBackground'

export const ALERT_VARIANTS = Object.keys(cssTheme.colors.alert) as Array<
  keyof typeof cssTheme.colors.alert
>
export type AlertVariant = (typeof ALERT_VARIANTS)[number]
export type IconAlignment = 'flex-start' | 'center'

interface Props {
  /** An icon placed before the alert content. It can be any type of React Element */
  icon?: ReactElement
  /** The content of the Alert, a React component or pure text */
  children: ReactNode
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Pass through alignIcon to allow vertically center aligned icon for example */
  alignIcon?: IconAlignment
  /** Title string of the Alert */
  title?: string | ReactNode
  /** Passing this callback will show the close button.
   * Pressing the button will cause the callback execution */
  onClose?: () => void
  /** Color schemes are based on pre-defined themes */
  variant?: AlertVariant
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
}

const getVariantStyles = (
  variant: AlertVariant,
  path: keyof (typeof cssTheme.colors.alert)[AlertVariant]
) => `
  &--variant-${variant} {
    color: ${cssTheme.colors.alert[variant][path]};
  }
`

export const AlertWrapper = styled.div`
  border-radius: ${cssTheme.layout.radius.md};
  position: relative;
  overflow: hidden;
  z-index: 0;
  padding: ${cssTheme.layout.spacing.s500} ${cssTheme.layout.spacing.s400};
`

export const ContentWrapper = styled.div`
  ${ALERT_VARIANTS.map(variant => getVariantStyles(variant, 'text')).join('')}
  ${cssTheme.typography.text.bodyS};
  padding: 0 ${cssTheme.layout.spacing.s400};
`

export const Title = styled.div`
  ${ALERT_VARIANTS.map(variant => getVariantStyles(variant, 'title')).join('')}
  ${cssTheme.typography.text.labelM};
`

export const Description = styled.div`
  padding: 0 0;
  &.--has-title-and-icon {
    padding: 0
      calc(${cssTheme.layout.spacing.s600} + ${cssTheme.layout.spacing.s200});
  }
  &.--has-title {
    padding: 0 ${cssTheme.layout.spacing.s400};
  }
  ${ALERT_VARIANTS.map(variant => getVariantStyles(variant, 'text')).join('')}
  ${cssTheme.typography.text.bodyS};
`

export const IconGrid = styled(Grid)<{
  alignIcon: IconAlignment
}>`
  align-self: ${({alignIcon}) => alignIcon};
  display: flex;
  align-items: center;
  min-height: ${cssTheme.fonts.lineHeight.sm};
  &.--has-title {
    min-height: ${cssTheme.fonts.lineHeight.md};
  }
`

export const IconWrapper = styled.div`
  ${ALERT_VARIANTS.map(variant => getVariantStyles(variant, 'icon')).join('')}
`
export const ButtonWrapper = styled.div`
  min-width: ${cssTheme.layout.spacing.s600};
  color: ${cssTheme.colors.alert.default.icon};
  margin-top: ${minus(cssTheme.layout.spacing.s400)};
  margin-bottom: ${minus(cssTheme.layout.spacing.s400)};
  margin-right: ${minus(cssTheme.layout.spacing.s400)};
`

export const Alert = ({
  icon,
  onClose,
  children,
  className,
  alignIcon = 'flex-start',
  variant = 'default',
  title,
  dataId = ''
}: Props) => {
  const handleCloseButtonClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    onClose?.()
  }

  const hasTitle = Boolean(title)
  const hasIcon = Boolean(icon)
  const hasTitleAndIcon = hasTitle && hasIcon
  return (
    <AlertWrapper className={className} data-id={dataId}>
      <ShapedBackground variant={variant} />
      <Grid container spacing="s000" wrap="nowrap">
        {icon && (
          <IconGrid
            mobileSm="fit"
            alignIcon={alignIcon}
            className={cx(Boolean(title) && '--has-title')}
          >
            <IconWrapper
              className={`${getLinariaClassName(IconWrapper)}--variant-${variant}`}
            >
              {icon}
            </IconWrapper>
          </IconGrid>
        )}
        <Grid mobileSm="fill">
          <ContentWrapper
            className={`${getLinariaClassName(ContentWrapper)}--variant-${variant}`}
          >
            {Boolean(title) && (
              <Title
                data-id="AlertTitle"
                className={`${getLinariaClassName(Title)}--variant-${variant}`}
              >
                {title}
              </Title>
            )}
            {!title && (
              <Description
                className={cx(
                  `${getLinariaClassName(Description)}--variant-${variant}`,
                  hasTitleAndIcon
                    ? '--has-title-and-icon'
                    : hasTitle
                      ? '--has-title'
                      : ''
                )}
              >
                {children}
              </Description>
            )}
          </ContentWrapper>
        </Grid>

        {Boolean(onClose) && (
          <ButtonWrapper>
            <Button
              hasTouchArea
              variant="transparent"
              size="md"
              iconStart={
                <Icon
                  name="Close"
                  size="sm"
                  colorPath={`alert.${variant}.icon`}
                />
              }
              onClick={handleCloseButtonClick}
            />
          </ButtonWrapper>
        )}
      </Grid>
      {Boolean(title) && (
        <Description
          className={cx(
            `${getLinariaClassName(Description)}--variant-${variant}`,
            hasTitleAndIcon
              ? '--has-title-and-icon'
              : hasTitle
                ? '--has-title'
                : ''
          )}
        >
          {children}
        </Description>
      )}
    </AlertWrapper>
  )
}
