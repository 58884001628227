import {cookieSecuritySettings} from '../../_web/utils/cookies/cookieSecuritySettings'
import {getDomainName} from '../url'

const maxAge = 1 // 1 day
const name = 'userCountryCode'

const config = {
  name,
  options: {
    maxAge,
    domain: getDomainName(window.location.hostname),
    ...cookieSecuritySettings(true)
  }
}

export default config
