// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgSpeechBubble = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.405 4.956C4.265 5.905 3.5 7.372 3.5 9.5c0 1.624.446 2.855 1.148 3.761.361.468.57 1.176.254 1.85-.108.232-.222.453-.339.662-.08.144-.079.364.027.55.1.175.2.183.258.171.99-.198 2.058-.488 2.927-.898a1.899 1.899 0 0 1 1.05-.169c.382.049.775.073 1.175.073 1.807 0 3.434-.49 4.595-1.456 1.14-.949 1.905-2.416 1.905-4.544 0-2.128-.764-3.595-1.905-4.544C13.435 3.99 11.807 3.5 10 3.5s-3.434.49-4.595 1.456Zm-.96-1.153C5.944 2.557 7.942 2 10 2c2.059 0 4.057.557 5.554 1.803C17.072 5.065 18 6.973 18 9.5s-.928 4.435-2.446 5.697C14.057 16.443 12.06 17 10 17c-.46 0-.917-.028-1.364-.085a.398.398 0 0 0-.22.037c-1.027.486-2.231.804-3.274 1.013-.857.172-1.528-.324-1.856-.9-.323-.567-.407-1.35-.033-2.022.1-.18.198-.37.29-.568a.167.167 0 0 0 .01-.104.452.452 0 0 0-.091-.191C2.53 12.977 2 11.41 2 9.5c0-2.527.928-4.435 2.446-5.697Z"
    />
  </svg>
)

export default SvgSpeechBubble
