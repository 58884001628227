// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgMoon = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.75 2.75a.75.75 0 0 0-1.5 0c0 1-.238 1.903-.666 2.53-.41.6-.999.97-1.834.97a.75.75 0 0 0 0 1.5c.835 0 1.425.37 1.834.97.428.627.666 1.53.666 2.53a.75.75 0 0 0 1.5 0c0-1 .238-1.903.666-2.53.41-.6.999-.97 1.834-.97a.75.75 0 0 0 0-1.5c-.835 0-1.425-.37-1.834-.97-.428-.627-.666-1.53-.666-2.53Zm-.927 3.376c.062-.091.121-.185.177-.282.056.097.115.19.177.282.228.334.499.63.812.874-.313.245-.585.54-.812.874a4.447 4.447 0 0 0-.177.282 4.494 4.494 0 0 0-.177-.282A3.862 3.862 0 0 0 13.011 7c.313-.245.585-.54.812-.874ZM6.912 4.102a.75.75 0 0 0-1.015-1.014C3.33 4.456 2 6.991 2 10a8 8 0 0 0 8 8c3.01 0 5.544-1.33 6.912-3.898a.75.75 0 0 0-1.015-1.014c-.777.414-1.534.413-2.715.412H13A6.5 6.5 0 0 1 6.5 7v-.182c-.001-1.181-.002-1.939.412-2.715ZM3.5 10c0-1.76.525-3.25 1.517-4.356-.02.365-.019.728-.018 1.087L5 7a8 8 0 0 0 8 8h.268c.36.002.723.003 1.088-.017C13.251 15.975 11.76 16.5 10 16.5A6.5 6.5 0 0 1 3.5 10Z"
    />
  </svg>
)

export default SvgMoon
