import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import Settings from 'Settings'

import {Divider} from '@daedalus/atlas/Divider'
import {ContentWrapper} from '@daedalus/atlas/helpers/ContentWrapper'
import {Grid} from '@daedalus/atlas/helpers/Grid'
import {Icon} from '@daedalus/atlas/Icon'
import {Overlay} from '@daedalus/atlas/Overlay'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {CustomerSupportDetails} from '@daedalus/shared/src/booking/CustomerSupportDetails'
import {BrandLogo} from '@daedalus/shared/src/whiteLabel/BrandLogo'

interface OverlayErrorMessageProps {
  children: React.ReactNode
}

const facebookMessengerUrl = Settings.get(
  'REACT_APP_FACEBOOK_MESSENGER_LINK_VIO'
)

const IconWrapper = styled('div')(
  ({theme}) => css`
    background: ${theme.colors.background.danger.c050};
    display: inline-block;
    border-radius: 100%;
    padding: ${theme.layout.spacing.s300}px;
    margin-bottom: ${theme.layout.spacing.s500}px;
  `
)

const MainContainer = styled('div')(
  ({theme}) => css`
    padding: 0 ${theme.layout.spacing.s500}px;
    max-width: 480px;
  `
)

const IconAndTextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ICON_SIZE = 'lg'

export const OverlayErrorMessage = React.memo(
  ({children}: OverlayErrorMessageProps) => {
    const {brand} = useBrand()
    const whatsappUrl = Settings.get('REACT_APP_WHATSAPP_LINK_VIO_ZENDESK')

    return (
      <Overlay isOpen>
        <ContentWrapper padding="s500">
          <BrandLogo brand={brand} />
        </ContentWrapper>
        <ContentWrapper padding="s500">
          <Grid container justify="center">
            <MainContainer>
              <ContentWrapper paddingBottom="s800" paddingTop="s700">
                <IconAndTextContainer>
                  <IconWrapper>
                    <Icon
                      colorPath="content.danger.c700"
                      size={ICON_SIZE}
                      name="Forbid"
                    />
                  </IconWrapper>
                  {children}
                </IconAndTextContainer>
              </ContentWrapper>
              <Divider />
              <CustomerSupportDetails
                hasInstantMessagingContacts
                whatsAppLink={whatsappUrl}
                facebookMessengerLink={facebookMessengerUrl}
              />
            </MainContainer>
          </Grid>
        </ContentWrapper>
      </Overlay>
    )
  }
)

OverlayErrorMessage.displayName = 'OverlayErrorMessage'
