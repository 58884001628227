import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import {useTrackingTeam} from './useTrackingTeam'

export const useDispatchTeamEvent = () => {
  const team = useTrackingTeam()
  const dispatch = useDispatch()

  const dispatchTeamEvent = useCallback(
    (action: any) =>
      dispatch({
        ...action,
        payload: {
          ...action.payload,
          team
        }
      }),
    [dispatch, team]
  )

  return dispatchTeamEvent
}
