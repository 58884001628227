import {useEffect} from 'react'

import {isReactNativeWebView} from '../../../native'
import {isRateCheckerFromUrl} from '../../../utils/rateChecker'
import {getQueryStringObject} from '../../../utils/url'
import {loadFullStory} from '../../utils/FullStory'

interface Props {
  publicUrl: string
  isPartnersWidget?: boolean
}

export const FullStoryLoader = ({
  publicUrl,
  isPartnersWidget = false
}: Props) => {
  const searchParams = getQueryStringObject()
  const isRateChecker = isRateCheckerFromUrl(searchParams)

  useEffect(() => {
    const isMobileApp = isReactNativeWebView()

    if (isRateChecker || isMobileApp || isPartnersWidget) {
      // eslint-disable-next-line no-void
      void loadFullStory(publicUrl, 1500)
    }
  }, [isPartnersWidget, isRateChecker, publicUrl])

  return null
}
