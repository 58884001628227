import {FilterId} from '../../search/types'
import {Brand, BrandCode} from '../types'

interface BaseConfig
  extends Omit<
    Brand,
    | 'code'
    | 'name'
    | 'address'
    | 'checkoutAddress'
    | 'providerCode'
    | 'level'
    | 'theme'
  > {}

const baseConfig: BaseConfig = {
  tagline: 'Find hidden hotel deals',
  metaDescription: 'Find hidden hotel deals',
  pageTitleMessage: {
    id: 'title.legacyPageTitle',
    defaultMessage: 'Find the right hotel at the best price.'
  },
  pageTitleTagLineMessage: {
    id: 'title.legacyPageTitle',
    defaultMessage: 'Find the right hotel at the best price.'
  },
  homepageHeadingMessage: {
    id: 'homePage.headingDefault',
    defaultMessage: 'Find hidden hotel deals'
  },
  privateDealMessage: {
    id: 'exclusiveDeal',
    defaultMessage: 'Exclusive deal'
  },
  offersLockedByDefault: true,
  isInternal: false,
  showSignIn: false,
  hideInternalRedirect: false,
  bookOnBrandEnabled: false
}
export const brands: Record<BrandCode, Brand> = {
  vio: {
    ...baseConfig,
    code: 'vio',
    name: 'Vio.com',
    address: 'https://www.vio.com',
    checkoutAddress: 'https://secure.vio.com',
    providerCode: 'vio',
    tagline:
      'A better deal on your next hotel, motel or accommodation booking.',
    metaDescription:
      'Get a better deal with Vio.com. Save money and get access to millions of stays; from cheap hotels and motels to luxury villas.',
    pageTitleMessage: {
      id: 'title.pageTitle',
      defaultMessage:
        'A better deal on your next hotel, motel or accommodation booking.'
    },
    pageTitleTagLineMessage: {
      id: 'title.pageTitleTagline',
      defaultMessage: 'Get a better deal.'
    },
    homepageHeadingMessage: {
      id: 'homePage.heading',
      defaultMessage: 'A better deal. Every stay.'
    },
    homepageSubheadingMessage: {
      id: 'homePage.subHeading',
      defaultMessage: 'Search the whole world of travel – all in one place'
    },
    privateDealMessage: {
      id: 'membership.membershipLabel',
      defaultMessage: 'Gems Member'
    },
    cookieConsentId: 'b697fff4-f9e2-4ae1-9eea-e38ef1e470f1',
    meta: {
      'facebook-domain-verification': 'c0vnjgvsd2xx1cegt4tqy5q7v5ksz5'
    },
    level: 'advanced',
    isInternal: true,
    showSignIn: true,
    hideInternalRedirect: true,
    theme: 'vio',
    aboutUsUrl: 'https://www.vio.com/about',
    careersUrl: 'https://www.vio.com/careers',
    developersUrl: 'https://www.vio.com/affiliates',
    partnershipsUrl: 'mailto:partnerships@vio.com',
    blogUrl: 'https://www.vio.com/blog',
    helpUrl: 'https://support.vio.com/',
    privacyPolicyUrl: 'https://www.vio.com/privacy-policy',
    termsOfUseUrl: 'https://www.vio.com/terms-of-use',
    contactUrl: 'https://support.vio.com/hc/en-us/requests/new',
    facebookUrl: 'https://www.facebook.com/viodotcom',
    linkedinUrl: 'https://nl.linkedin.com/company/viodotcom',
    instagramUrl: 'https://www.instagram.com/vio.com.travel/',
    shareUrl: 'to.vio.com',
    bovioProdApiKey: 'fDCbMdVRDPig83dTkmqhpnLrhsBkCMKHzkR',
    bovioStagingApiKey: 'fDCbMdVRDPig83dTkmqhpnLrhsBkCMKHzkR_test'
  },
  findhotel: {
    ...baseConfig,
    code: 'findhotel',
    name: 'FindHotel',
    address: 'https://findhotel.net',
    checkoutAddress: 'https://secure.vio.com',
    providerCode: 'fht',
    tagline: 'Find the right hotel at the best price',
    metaDescription: 'Find the right hotel at the best price',
    cookieConsentId: '',
    meta: {
      'facebook-domain-verification': 'gkn75b19c2r26kvgpfphuua9c5qn1u'
    },
    level: 'advanced',
    isInternal: true,
    showSignIn: true,
    theme: 'vio',
    bovioProdApiKey: 'fDCbMdVRDPig83dTkmqhpnLrhsBkCMKHzkR',
    bovioStagingApiKey: 'fDCbMdVRDPig83dTkmqhpnLrhsBkCMKHzkR_test'
  },
  etrip: {
    ...baseConfig,
    code: 'etrip',
    name: 'Etrip',
    address: 'https://etrip.net',
    providerCode: 'etr',
    cookieConsentId: '6ed51359-d8f7-433c-854f-6434c8c01a6f',
    meta: {
      'facebook-domain-verification': 'tgmfj8y91tp7tpg8u5mvz1925zby0y'
    },
    level: 'advanced',
    isInternal: true,
    showSignIn: true,
    theme: 'vio',
    aboutUsUrl:
      'https://support.etrip.net/hc/en-us/articles/10555108760349-About-us',
    privacyPolicyUrl:
      'https://support.etrip.net/hc/en-us/articles/10555195541533-Privacy-Policy',
    termsOfUseUrl:
      'https://support.etrip.net/hc/en-us/articles/10555116393757-Terms-of-use',
    shareUrl: 'to.etrip.net'
  },
  goseek: {
    ...baseConfig,
    code: 'goseek',
    name: 'goSeek.com',
    address: 'https://goseek.com',
    providerCode: 'gsk',
    cookieConsentId: '76ddf610-e404-45f2-9329-d3b88937c6c4',
    level: 'basic',
    isInternal: true,
    showSignIn: true,
    theme: 'goseek',
    aboutUsUrl:
      'https://goseek.zendesk.com/hc/en-us/articles/11185399104541-About-us',
    privacyPolicyUrl:
      'https://goseek.zendesk.com/hc/en-us/articles/11185467302557-Privacy-Policy',
    termsOfUseUrl:
      'https://goseek.zendesk.com/hc/en-us/articles/11185418486301-Terms-of-use'
  },
  dealbase: {
    ...baseConfig,
    code: 'dealbase',
    name: 'dealbase.com',
    homepageHeadingMessage: {
      id: 'dealbase.homePageHeading',
      defaultMessage: 'Discover exclusive hotel deals'
    },
    address: 'https://dealbase.com',
    providerCode: 'dbs',
    cookieConsentId: '41c41b98-0a09-4073-95b5-fb99ad85cc89',
    level: 'custom',
    isInternal: true,
    showSignIn: true,
    theme: 'dealbase',
    aboutUsUrl:
      'https://dealbase.zendesk.com/hc/en-us/articles/18884421709981-About-us',
    privacyPolicyUrl:
      'https://dealbase.zendesk.com/hc/en-us/articles/18885895455645-Privacy-Policy',
    termsOfUseUrl:
      'https://dealbase.zendesk.com/hc/en-us/articles/18884421715613-Terms-of-use',
    mainHotelImageIndex: 1,
    enabledFilters: [
      FilterId.PriceFilterPill,
      FilterId.PriceFilterPopover,
      FilterId.FreeCancellationPill,
      FilterId.ReviewScorePill,
      FilterId.ReviewScoreFilterPopover,
      FilterId.ReviewScoreQuickPill,
      FilterId.PropertyTypeFilterPill,
      FilterId.PropertyTypeFilterPopover,
      FilterId.StarRatingPill,
      FilterId.StarRatingFilterPopover
    ]
  },
  '85off': {
    ...baseConfig,
    code: '85off',
    name: '85off.com',
    address: 'https://hotels.85off.com',
    providerCode: '85off',
    partnerId: 'hotels85off',
    cookieConsentId: '1d984c87-2ff3-42eb-9e90-52f2ce57b6c5',
    level: 'basic',
    offersLockedByDefault: false,
    theme: 'goseek',
    aboutUsUrl:
      'https://85off.zendesk.com/hc/en-us/articles/13582051674909-About-us',
    privacyPolicyUrl:
      'https://85off.zendesk.com/hc/en-us/articles/13581930925853-Privacy-Policy',
    termsOfUseUrl:
      'https://85off.zendesk.com/hc/en-us/articles/13581993278877-Terms-of-use'
  },
  kiwi: {
    ...baseConfig,
    code: 'kiwi',
    name: 'Kiwi.com',
    address: 'https://kiwi.vio.com',
    providerCode: 'kiwi',
    homepageHeadingMessage: {
      id: 'kiwi.homePage.heading',
      defaultMessage: 'You book for less'
    },
    homepageSubheadingMessage: {
      id: 'kiwi.homePage.subHeading',
      defaultMessage: "Book cheap stays other sites simply can't find."
    },
    privateDealMessage: {
      id: 'travelHack',
      defaultMessage: 'Travel hack'
    },
    partnerId: 'kiwi',
    level: 'custom',
    offersLockedByDefault: false,
    theme: 'kiwi',
    cookieConsentId: '',
    aboutUsUrl:
      'https://kiwi-stays.zendesk.com/hc/en-us/articles/15086862359453-About-us',
    privacyPolicyUrl:
      'https://kiwi-stays.zendesk.com/hc/en-us/articles/15086734253597-Privacy-Policy',
    termsOfUseUrl:
      'https://kiwi-stays.zendesk.com/hc/en-us/articles/15086788552221-Terms-of-use',
    partnerKey: 'ZV0gASHJIyCG7SL9Nsqpnixp',
    widgetHeaderMessage: {
      id: 'multipleHotelsWidgetWithSearch.kiwiStays',
      defaultMessage: 'Kiwi.com Stays'
    }
  },
  kayak: {
    ...baseConfig,
    code: 'kayak',
    name: 'Kayak',
    address: 'https://kayak.vio.com',
    providerCode: 'kayak',
    partnerId: 'kayak',
    level: 'custom',
    theme: 'kayak',
    cookieConsentId: '',
    aboutUsUrl: 'https://www.kayak.com/about',
    privacyPolicyUrl: 'https://www.kayak.com/privacy',
    termsOfUseUrl: 'https://www.kayak.com/terms-of-use',
    sapiCode: 'demo'
  },
  momondo: {
    ...baseConfig,
    code: 'momondo',
    name: 'momondo',
    address: 'https://momondo.vio.com',
    providerCode: 'momondo',
    partnerId: 'momondo',
    level: 'custom',
    theme: 'kayak',
    cookieConsentId: '',
    aboutUsUrl: 'https://www.momondo.com/about',
    privacyPolicyUrl: 'https://www.momondo.com/privacy',
    termsOfUseUrl: 'https://www.momondo.com/terms-of-use',
    sapiCode: 'momondo'
  },
  hotelscombined: {
    ...baseConfig,
    code: 'hotelscombined',
    name: 'hotelscombined',
    address: 'https://hotelscombined.vio.com',
    providerCode: 'hotelscombined',
    partnerId: 'hotelscombined',
    level: 'custom',
    theme: 'kayak',
    cookieConsentId: '',
    aboutUsUrl: 'https://www.hotelscombined.com/about',
    privacyPolicyUrl: 'https://www.hotelscombined.com/privacy',
    termsOfUseUrl: 'https://www.hotelscombined.com/terms-of-use',
    sapiCode: 'hotelscombined'
  },
  checkfelix: {
    ...baseConfig,
    code: 'checkfelix',
    name: 'checkfelix',
    address: 'https://checkfelix.vio.com',
    providerCode: 'checkfelix',
    partnerId: 'checkfelix',
    level: 'custom',
    theme: 'kayak',
    cookieConsentId: '',
    aboutUsUrl: 'https://www.checkfelix.com/about',
    privacyPolicyUrl: 'https://www.checkfelix.com/privacy',
    termsOfUseUrl: 'https://www.checkfelix.com/terms-of-use',
    sapiCode: 'checkfelix'
  },
  flighthub: {
    ...baseConfig,
    code: 'flighthub',
    name: 'FlightHub',
    address: 'https://hotel.flighthub.com',
    providerCode: 'flighthub',
    partnerId: 'flighthub',
    level: 'custom',
    theme: 'flighthub',
    cookieConsentId: '',
    partnerKey: 'ktjJdaSFkOIH5iE6pwX5uFDg',
    aboutUsUrl: 'https://flighthubgroup.com/',
    privacyPolicyUrl: 'https://www.flighthub.com/privacy-policy-ca-en',
    termsOfUseUrl: 'https://www.flighthub.com//terms-and-conditions-ca-en'
  },
  justfly: {
    ...baseConfig,
    code: 'justfly',
    name: 'JustFly',
    address: 'https://hotel.justfly.com',
    providerCode: 'justfly',
    partnerId: 'justfly',
    level: 'custom',
    theme: 'justfly',
    cookieConsentId: '',
    partnerKey: 'jPM27RvaJbSJDZApRhlIqWkU',
    aboutUsUrl: 'https://flighthubgroup.com/',
    privacyPolicyUrl: 'https://www.justfly.com/privacy-policy-us',
    termsOfUseUrl: 'https://www.justfly.com/terms-and-conditions-us'
  },
  trivago: {
    ...baseConfig,
    code: 'trivago',
    name: 'Trivago',
    address: 'https://hotel.trivago.com',
    providerCode: 'trivago',
    partnerId: 'trivago',
    level: 'advanced',
    theme: 'trivago',
    partnerKey: 'jPM27RvaJbSJDZApRhlIqWkU',
    aboutUsUrl: 'https://company.trivago.com/#about-us',
    privacyPolicyUrl: 'https://www.trivago.com/en-US/sp/privacy-policy',
    termsOfUseUrl: 'https://www.trivago.com/en-US/st/terms-of-service',
    sapiCode: 'demo',
    offersLockedByDefault: false
  },
  omio: {
    ...baseConfig,
    code: 'omio',
    name: 'Omio',
    address: 'https://omio.vio.com',
    providerCode: 'omio',
    partnerId: 'omio',
    level: 'advanced',
    theme: 'vio',
    cookieConsentId: '',
    hideInternalRedirect: true,
    aboutUsUrl: 'https://www.vio.com/about',
    privacyPolicyUrl: 'https://www.vio.com/privacy-policy',
    termsOfUseUrl: 'https://www.vio.com/terms-of-use'
  },
  demo: {
    ...baseConfig,
    code: 'demo',
    name: 'Demo',
    address: 'https://demo.vio.com',
    checkoutAddress: 'https://secure.demo.vio.com',
    providerCode: 'demo',
    level: 'basic',
    offersLockedByDefault: false,
    theme: 'kiwi',
    bookOnBrandEnabled: true,
    bovioProdApiKey: 'bXlfcHJvZmlsZV9rZXlfZGVzY3JpcHRpb24',
    bovioStagingApiKey: 'bXlfcHJvZmlsZV9rZXlfZGVzY3JpcHRpb24'
  },
  demoBasic: {
    ...baseConfig,
    code: 'demo',
    name: 'Demo',
    address: 'https://demo.vio.com',
    checkoutAddress: 'https://secure.demo.vio.com',
    providerCode: 'demo',
    level: 'basic',
    offersLockedByDefault: false,
    theme: 'kiwi',
    bovioProdApiKey: 'bXlfcHJvZmlsZV9rZXlfZGVzY3JpcHRpb24',
    bovioStagingApiKey: 'bXlfcHJvZmlsZV9rZXlfZGVzY3JpcHRpb24'
  },
  demoAdvanced: {
    ...baseConfig,
    code: 'demo',
    name: 'Demo',
    address: 'https://demo.vio.com',
    checkoutAddress: 'https://secure.demo.vio.com',
    providerCode: 'demo',
    level: 'advanced',
    offersLockedByDefault: false,
    theme: 'kiwi',
    shareUrl: 'to.vio.com',
    bookOnBrandEnabled: true,
    bovioProdApiKey: 'bXlfcHJvZmlsZV9rZXlfZGVzY3JpcHRpb24',
    bovioStagingApiKey: 'bXlfcHJvZmlsZV9rZXlfZGVzY3JpcHRpb24'
  },
  demoCustom: {
    ...baseConfig,
    code: 'demo',
    name: 'Demo',
    address: 'https://demo.vio.com',
    checkoutAddress: 'https://secure.demo.vio.com',
    providerCode: 'demo',
    level: 'custom',
    offersLockedByDefault: false,
    theme: 'kiwi',
    shareUrl: 'to.vio.com',
    bookOnBrandEnabled: true,
    bovioProdApiKey: 'bXlfcHJvZmlsZV9rZXlfZGVzY3JpcHRpb24',
    bovioStagingApiKey: 'bXlfcHJvZmlsZV9rZXlfZGVzY3JpcHRpb24'
  },
  tripadvisor: {
    ...baseConfig,
    code: 'tripadvisor',
    name: 'Tripadvisor',
    address: 'https://www.tripadvisor.com/',
    providerCode: 'tripadvisor',
    partnerId: 'tripadvisor',
    level: 'advanced',
    theme: 'tripadvisor',
    partnerKey: 'tripadvisor',
    termsOfUseUrl: 'https://tripadvisor.mediaroom.com/us-terms-of-use',
    privacyPolicyUrl: 'https://tripadvisor.mediaroom.com/us-privacy-policy',
    aboutUsUrl: 'https://tripadvisor.mediaroom.com/us-about-us',
    sapiCode: 'demo',
    offersLockedByDefault: false
  }
}
