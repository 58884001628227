import {OfferPrice} from '@daedalus/core/src/offer/types/offer'
import {getTotalPrice} from '@daedalus/core/src/price/business/price'
import {Room, RoomOffer, RoomPrice} from '@findhotel/sapi'

export const findRoomByOfferId = (rooms: Room[], offerId?: string) =>
  rooms.find(room => room.offers.find(offer => offer.id === offerId))

export const findExactMatchedOffer = (
  rooms?: Room[],
  offerId?: string | null
): RoomOffer | undefined => {
  if (!rooms) return undefined

  return rooms.flatMap(room => room.offers).find(({id}) => id === offerId)
}

export const calculatePriceMismatchDifference = (
  firstPrice: OfferPrice | RoomPrice | null,
  secondPrice: OfferPrice | RoomPrice | null
) => {
  if (!firstPrice || !secondPrice) return null

  const firstTotal = getTotalPrice(firstPrice)
  const secondTotal = getTotalPrice(secondPrice)
  if (!firstTotal || !secondTotal) return null

  const mismatch = firstTotal - secondTotal
  return mismatch.toFixed(2)
}
