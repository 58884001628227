import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {ControlWithCheckmark} from '@daedalus/atlas/helpers/ControlWithCheckmark'
import {SelectionCard} from '@daedalus/atlas/SelectionCard'

interface Props<T> {
  item: T
  getValue: (item: T) => string
  renderItem: (item: T, isMobile: boolean) => React.ReactNode
  isMobile: boolean
  isActive: boolean
  onClick: (code: string) => void
}

const ListItemWrapper = styled.li(
  ({theme}) => css`
    cursor: pointer;
    list-style: none;
    display: flex;
    align-items: center;
    padding: ${theme.layout.spacing.s500}px 0;
    :not(:last-child) {
      border-bottom: 1px solid ${theme.colors.border.neutral.c100};
    }
    // Setting the height so items with or without checkbox are the same size
    box-sizing: content-box;
    min-height: ${theme.layout.spacing.s600}px;
  `
)

const FlexGrow = styled.div`
  flex-grow: 1;
`

export const SelectorListItem = <T,>({
  isActive,
  isMobile,
  item,
  onClick,
  renderItem,
  getValue
}: Props<T>) => {
  const value = getValue(item)
  if (isMobile) {
    return (
      <ListItemWrapper
        onClick={() => {
          onClick(value)
        }}
      >
        <FlexGrow>{renderItem(item, isMobile)}</FlexGrow>
        {isActive && <ControlWithCheckmark type="radio" checked name={value} />}
      </ListItemWrapper>
    )
  }

  return (
    <SelectionCard
      size="md"
      type="radio"
      name={value}
      withBorder={isActive}
      withControlButton={isActive}
      selected={isActive}
      alignItems="center"
      onChange={() => {
        onClick(value)
      }}
    >
      {renderItem(item, isMobile)}
    </SelectionCard>
  )
}
