/* eslint-disable fp/no-mutation */

import {createSlice} from '@reduxjs/toolkit'

import {CreditCard} from '@daedalus/core/src/booking/services/paymentOptionsApi'

import {filterCards} from './filterCards'

export interface PaymentOptionsStateType {
  moduleState: string
  creditCardTypes: CreditCard[]
}
const initialState = {
  moduleState: '',
  creditCardTypes: []
}

const {actions, reducer} = createSlice({
  name: 'paymentOptions',
  initialState,
  reducers: {
    paymentOptionsFetch: (state: PaymentOptionsStateType, action) => {
      state.moduleState = action.type
    },
    paymentOptionsFetchPending: (state: PaymentOptionsStateType, action) => {
      state.moduleState = action.type
    },
    paymentOptionsFetchDone: (state: PaymentOptionsStateType, action) => {
      const creditCardTypes = filterCards(action.payload?.data?.creditCardTypes)
      state.creditCardTypes = creditCardTypes
      state.moduleState = action.type
    },
    paymentOptionsFetchError: (state: PaymentOptionsStateType, action) => {
      state.moduleState = action.type
    },
    clearPaymentOptions: (state: PaymentOptionsStateType) => {
      state.moduleState = ''
      state.creditCardTypes = []
    }
  }
})

export const {
  paymentOptionsFetch,
  paymentOptionsFetchPending,
  paymentOptionsFetchDone,
  paymentOptionsFetchError,
  clearPaymentOptions
} = actions

export type PaymentOptionsActionsType =
  | typeof paymentOptionsFetch
  | typeof paymentOptionsFetchPending
  | typeof paymentOptionsFetchDone
  | typeof paymentOptionsFetchError
  | typeof clearPaymentOptions

export default reducer
