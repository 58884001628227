import {getBookingFinalizationLink} from 'business/bofhLinks'
import {logEvent} from 'services/datadogLogger'
import {Psd2Strategy, ServerCode} from 'services/psd2/types'

import {BookingStatusThreeDomainSecureRequiredResponse} from '@daedalus/core/src/booking/types/BookingStatus'

let initializationResponse: BookingStatusThreeDomainSecureRequiredResponse | null =
  null

const finalizeBooking = async () => {
  const params = initializationResponse
  const bookingFinalizationLink = getBookingFinalizationLink(params?.links)

  logEvent('services.PSD2.finalizeBooking', {
    meta: {bookingFinalizationLink}
  })

  return {
    success: true,
    link: bookingFinalizationLink
  }
}

const Psd2 = {
  setup: () => null,
  beforeBooking: () => null,
  initializeBooking: () => null,
  finalizeBooking,
  challenge: () => null,
  isEnrolled: (resp: BookingStatusThreeDomainSecureRequiredResponse) => {
    initializationResponse = resp
    // In this case, this function is only needed to set the initializationResponse as we already have the logic to handle PSD2 even for GAR
    // so we just return false without checking anything like we do in Stripe for example
    return false
  }
}

export const Psd2Ppn: Psd2Strategy<
  void,
  BookingStatusThreeDomainSecureRequiredResponse
> = {
  ...Psd2,
  serverCode: ServerCode.Ppn
}
