import {Middleware} from '@reduxjs/toolkit'

import {TRANSITION_DURATION} from '@daedalus/atlas/src/components/designSystem/Overlay'

import {RootState} from '../../type'
import {
  closeAllFreezeOverlays,
  closeFreezeConfirmationOverlay,
  closeFreezeOverlay,
  resetData
} from './slice'

export const dealFreezeMiddleware: Middleware<unknown, RootState> =
  store => next => action => {
    if (
      closeAllFreezeOverlays.match(action) ||
      closeFreezeOverlay.match(action) ||
      closeFreezeConfirmationOverlay.match(action)
    ) {
      next(action)

      /**
       * Immediately resetting data during the closing animation could slow down or disrupt the animation effect.
       * The timeout ensures that resetData() is called only after the animation has completed, preventing any abrupt visual changes.
       * The duration of the timeout (TRANSITION_DURATION) is set to match the duration of the BottomSheet's closing animation.
       */
      setTimeout(() => {
        store.dispatch(resetData())
      }, TRANSITION_DURATION)

      return
    }

    return next(action)
  }
