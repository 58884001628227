// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgIron = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m8 2.75.334-.672a.75.75 0 0 0-.668 0L8 2.75Zm3.163 2.101c1.36 2.008 2.692 5.27 2.826 10.388A2 2 0 0 0 15 13.5v-.75c0-.966.784-1.75 1.75-1.75h.5a.75.75 0 0 1 0 1.5h-.5a.25.25 0 0 0-.25.25v.75a3.5 3.5 0 0 1-3.533 3.5 1.694 1.694 0 0 1-.085.032C11.912 17.378 9.91 18 8 18c-1.91 0-3.911-.622-4.882-.968a1.683 1.683 0 0 1-1.11-1.626c.108-5.213 1.455-8.525 2.83-10.555.684-1.011 1.369-1.694 1.894-2.129.263-.217.485-.373.647-.476a4.137 4.137 0 0 1 .256-.151l.019-.01.007-.004.003-.001.001-.001L8 2.75c.334-.672.334-.671.335-.671h.001l.003.002.007.004.02.01.059.032c.048.028.115.067.196.119.162.103.384.259.647.476.525.435 1.21 1.118 1.895 2.13ZM7.66 3.42l.003.001H7.66Zm.65.458A5.691 5.691 0 0 0 8 3.637c-.09.064-.194.144-.311.24-.424.352-1.01.93-1.61 1.815-1.194 1.764-2.469 4.788-2.572 9.745-.002.095.054.16.114.182.934.332 2.736.881 4.379.881 1.643 0 3.445-.549 4.38-.881.06-.021.115-.087.113-.182-.103-4.957-1.378-7.98-2.572-9.745-.6-.885-1.186-1.463-1.61-1.815ZM8 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 3a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-1 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm5-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-1 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
    />
  </svg>
)

export default SvgIron
