// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgDumbell = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 8.75A2.75 2.75 0 0 1 5.75 6h.5a.75.75 0 0 1 .75.75v2.5h6v-2.5a.75.75 0 0 1 .75-.75h.5A2.75 2.75 0 0 1 17 8.75v.5h.25a.75.75 0 0 1 0 1.5H17v.5A2.75 2.75 0 0 1 14.25 14h-.5a.75.75 0 0 1-.75-.75v-2.5H7v2.5a.75.75 0 0 1-.75.75h-.5A2.75 2.75 0 0 1 3 11.25v-.5h-.25a.75.75 0 1 1 0-1.5H3v-.5Zm2.5-1.225c-.57.116-1 .62-1 1.225v2.5c0 .605.43 1.11 1 1.225v-4.95Zm9 0v4.95c.57-.116 1-.62 1-1.225v-2.5c0-.605-.43-1.11-1-1.225Z"
    />
  </svg>
)

export default SvgDumbell
