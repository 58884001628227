// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCheckInArrow = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 12 44"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      d="M0 22v22a7.856 7.856 0 0 0 7.579-5.789l4.134-15.159a4 4 0 0 0 0-2.104L7.579 5.788A7.855 7.855 0 0 0 0 0v22Z"
      fill="#3C69F5"
    />
  </svg>
)

export default SvgCheckInArrow
