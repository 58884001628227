import React, {ReactElement, SyntheticEvent} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {BrandBottomSheet} from '../../../../dealFreeze/components/BrandBottomSheet'

interface Props {
  isOpen: boolean
  IconComponent?: ReactElement | null
  TitleComponent?: ReactElement | null
  MainContentComponent?: ReactElement | null
  BottomContentComponent?: ReactElement | null
  onClose?: (e?: SyntheticEvent, element?: string) => void
  /** Whether the bottom sheet should match the height of the content or the full height of the viewport */
  hasFixedHeight?: boolean
  ignoreSnapPoints?: boolean
}

const BottomSheetContent = styled.div(
  ({theme}) => css`
    margin-top: ${theme.layout.spacing.s600}px;
  `
)

export const BottomSheet = ({
  isOpen,
  IconComponent,
  TitleComponent,
  MainContentComponent,
  BottomContentComponent,
  hasFixedHeight = false,
  ignoreSnapPoints,
  onClose
}: Props) => (
  <BrandBottomSheet
    isOpen={isOpen}
    onClose={onClose}
    iconComponent={IconComponent}
    hasFixedHeight={hasFixedHeight}
    ignoreSnapPoints={ignoreSnapPoints}
  >
    {Boolean(TitleComponent) && TitleComponent}
    <BottomSheetContent>
      {Boolean(MainContentComponent) && MainContentComponent}
      {Boolean(BottomContentComponent) && BottomContentComponent}
    </BottomSheetContent>
  </BrandBottomSheet>
)
