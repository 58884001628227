// TODO (Core): Add unit tests for this function
import {brands, defaultBrand} from '../../../brand/config'
import {BrandCode} from '../../../brand/types'
import {getUrlParam} from '../../../utils/url'
import {
  getBrandOverrideFromCookie,
  persistBrandOverrideOnCookie
} from '../business/persistData'

// TODO (Core): Move this to an appropriate platform-agnostic utils domain
const PROD_ENVIRONMENTS = ['prd', 'production']

/**
 * Retrieves the brand code from the URL or cookies, with consideration for demo environments
 * and production settings.
 *
 * @param processENV - The consumer process environment (e.g., 'production', 'prd')
 * Warning: Don't use process.env.NODE_ENV, use the APP_ENV from the env files.
 *
 * @returns brandCode- The determined brand code.
 */
export const getBrandCodeFromUrlOrCookie = (processENV: string) => {
  const {hostname} = location
  if (!hostname) return defaultBrand.code
  const hostnameParts = hostname.split('.')
  const brandCodes = Object.keys(brands) as BrandCode[]

  const brandOverride =
    (getUrlParam({
      search: location.search,
      param: 'brandOverride'
    }) as BrandCode) || getBrandOverrideFromCookie()

  const brandIsDemo = hostnameParts.some(part => part.includes('demo'))

  const envIsProduction = PROD_ENVIRONMENTS.includes(processENV)

  const brandOverrideIsValid =
    !envIsProduction && Boolean(brandOverride) && Boolean(brands[brandOverride])

  const getBrandCode = () =>
    (hostnameParts.find(part =>
      brandCodes.find(code => part.includes(code))
    ) as BrandCode) || defaultBrand.code

  const brandCode = brandIsDemo ? 'demo' : getBrandCode()

  if (brandOverrideIsValid) {
    persistBrandOverrideOnCookie(brandOverride)
    return brandOverride
  }

  return brandCode
}
