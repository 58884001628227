// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPhone = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 4.068C2 2.926 2.926 2 4.068 2h2.825a.75.75 0 0 1 .624.334L9.588 5.44a.75.75 0 0 1-.093.946L7.857 8.025a.197.197 0 0 0-.056.095.078.078 0 0 0 .005.053c.262.553.785 1.47 1.676 2.32a10.344 10.344 0 0 0 2.344 1.673c.02.01.043.014.071.009a.204.204 0 0 0 .104-.059l1.612-1.61a.75.75 0 0 1 .946-.094l3.107 2.071a.75.75 0 0 1 .334.624v2.825A2.068 2.068 0 0 1 15.932 18C8.237 18 2 11.762 2 4.068ZM4.068 3.5a.568.568 0 0 0-.568.568C3.5 10.934 9.066 16.5 15.932 16.5a.568.568 0 0 0 .568-.568v-2.424l-2.262-1.507-1.176 1.176c-.485.485-1.251.67-1.924.321-.64-.33-1.672-.947-2.691-1.92A9.475 9.475 0 0 1 6.45 8.816c-.314-.664-.113-1.391.346-1.85L8 5.761 6.491 3.5H4.068Z"
    />
  </svg>
)

export default SvgPhone
