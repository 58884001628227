// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgShield = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.693 2.066a.75.75 0 0 1 .614 0l7.25 3.25a.75.75 0 0 1 .441.634L17.25 6l.748-.05v.004l.001.008.002.029a9.307 9.307 0 0 1 .015.48c.006.322.004.779-.025 1.326-.056 1.089-.218 2.556-.645 4.036-.426 1.475-1.131 3.013-2.308 4.19C13.842 17.219 12.198 18 10 18c-2.198 0-3.842-.781-5.038-1.977-1.177-1.177-1.882-2.715-2.308-4.19-.427-1.48-.589-2.947-.645-4.036a19.769 19.769 0 0 1-.015-1.703L2 5.991l.002-.029v-.011L2.75 6l-.748-.05a.75.75 0 0 1 .441-.634l7.25-3.25Zm-6.21 4.427v.002c-.004.294-.003.716.024 1.224.053 1.02.204 2.365.589 3.698.386 1.338.994 2.612 1.927 3.545.913.913 2.175 1.538 3.977 1.538s3.064-.625 3.977-1.538c.933-.933 1.541-2.207 1.928-3.545.385-1.333.535-2.678.588-3.698.027-.508.028-.93.024-1.224v-.002L10 3.572 3.483 6.493Z"
      fill="#6C6770"
    />
  </svg>
)

export default SvgShield
