// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPhotoError = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 28 28"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.65 2.8A3.85 3.85 0 0 0 2.8 6.65v14.699a3.85 3.85 0 0 0 3.85 3.85h3.5a1.05 1.05 0 1 0 0-2.1h-3.5a1.75 1.75 0 0 1-1.75-1.75v-1.386l3.62-4.605c.238-.302.714-.496 1.413-.408.694.089 1.368.44 1.751.886a1.05 1.05 0 0 0 1.622-.035c1.455-1.85 3.481-4.47 3.612-4.64l.009-.011a1.75 1.75 0 0 1 2.696-.05 1.05 1.05 0 1 0 1.593-1.369 3.85 3.85 0 0 0-5.937.117l-.005.006-.014.018-.003.004c-.112.145-1.597 2.065-2.93 3.77a5.173 5.173 0 0 0-2.129-.779c-1.135-.144-2.477.11-3.329 1.193l-1.97 2.505V6.65c0-.967.784-1.75 1.75-1.75h14.7c.967 0 1.75.783 1.75 1.75v4.9a1.05 1.05 0 1 0 2.1 0v-4.9a3.85 3.85 0 0 0-3.85-3.85H6.65Zm9.846 15.181a3.5 3.5 0 0 0 4.723 4.723l-4.723-4.723Zm1.485-1.485 4.723 4.723a3.5 3.5 0 0 0-4.723-4.723ZM14 19.6a5.6 5.6 0 1 1 11.2 0 5.6 5.6 0 0 1-11.2 0Z"
      fill="#9F99A3"
    />
  </svg>
)

export default SvgPhotoError
