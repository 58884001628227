// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCalendarStart = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 2a.75.75 0 0 0-.75.75V4h-6.5V2.75a.75.75 0 0 0-1.5 0V4h-.5A2.75 2.75 0 0 0 2 6.75v3.5a.75.75 0 0 0 1.5 0v-3.5c0-.69.56-1.25 1.25-1.25h.5v.75a.75.75 0 0 0 1.5 0V5.5h6.5v.75a.75.75 0 0 0 1.5 0V5.5h.5c.69 0 1.25.56 1.25 1.25v8.5c0 .69-.56 1.25-1.25 1.25h-6.5a.75.75 0 0 0 0 1.5h6.5A2.75 2.75 0 0 0 18 15.25v-8.5A2.75 2.75 0 0 0 15.25 4h-.5V2.75A.75.75 0 0 0 14 2Zm1 6.75a.75.75 0 0 0-.75-.75h-8.5a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 .75-.75ZM1.757 13.454a.5.5 0 0 0-.757.43v4.233a.5.5 0 0 0 .757.429l3.528-2.117a.5.5 0 0 0 0-.858l-3.528-2.117Z"
    />
  </svg>
)

export default SvgCalendarStart
