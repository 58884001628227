/**
 * An object that mimicks the optimizely datafile to be used when the service
 * fails to load the information from a remote source
 */
const fallbackDataFile = {
  version: '4',
  rollouts: [],
  typedAudiences: [],
  anonymizeIP: true,
  projectId: '19771913743',
  variables: [],
  featureFlags: [],
  experiments: [],
  audiences: [],
  groups: [],
  attributes: [],
  botFiltering: false,
  accountId: '19771913743',
  events: [],
  revision: '10'
}

export default fallbackDataFile
