import {StylisPlugin} from '@emotion/cache/types'

export const MIRROR_REGEX = /\/\*\s*@mirror\s*\*\//g

const plugin: StylisPlugin = content => {
  if (MIRROR_REGEX.test(content.value)) {
    return content.value.replace(MIRROR_REGEX, 'transform: scale(-1, 1);')
  }
}

export default plugin
