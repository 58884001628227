// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgBellSnooze = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 8a6 6 0 0 1 8.573-5.422.75.75 0 1 1-.644 1.355A4.5 4.5 0 0 0 5.5 8v2a.75.75 0 0 1-.071.32L3.932 13.5h12.136l-1.497-3.18a.75.75 0 1 1 1.358-.64l2 4.25A.75.75 0 0 1 17.25 15h-3.548l-.011.064a3.927 3.927 0 0 1-.408 1.17 3.236 3.236 0 0 1-1.157 1.226c-.556.34-1.257.54-2.126.54s-1.57-.2-2.125-.54a3.235 3.235 0 0 1-1.158-1.226A3.929 3.929 0 0 1 6.297 15H2.75a.75.75 0 0 1-.679-1.07L4 9.833V8Zm3.83 7c.043.161.108.34.203.516.137.25.333.487.624.664.288.177.712.32 1.343.32s1.055-.143 1.343-.32c.29-.177.487-.414.624-.664.095-.176.16-.355.202-.516H7.83ZM14 2.75a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 .61 1.186L16.207 5.5h1.043a.75.75 0 0 1 0 1.5h-2.5a.75.75 0 0 1-.61-1.186L15.793 3.5H14.75a.75.75 0 0 1-.75-.75Zm-4 4a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 .643 1.136L12.075 8.5h.175a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1-.643-1.136l.818-1.364h-.175a.75.75 0 0 1-.75-.75Z"
    />
  </svg>
)

export default SvgBellSnooze
