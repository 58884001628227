// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPen = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.782 2.907a3.048 3.048 0 1 1 4.31 4.31L7.53 16.78a.75.75 0 0 1-.358.2l-4.25 1a.75.75 0 0 1-.902-.902l1-4.25a.75.75 0 0 1 .2-.358l9.562-9.563Zm3.25 1.06a1.548 1.548 0 0 0-2.19 0l-.53-.53.53.53-9.41 9.413-.674 2.862 2.863-.673 9.411-9.412a1.548 1.548 0 0 0 0-2.19Z"
    />
  </svg>
)

export default SvgPen
