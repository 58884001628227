import {createApi} from '@reduxjs/toolkit/query/react'
import {mergeAll, pipe, prop, sortBy, values} from 'ramda'
import {iso18nCountriesLocaleMapper} from 'services/localization/getCountryListNames'
import {publicUrl} from 'utils/urls'

import {countryDialCodes} from '@daedalus/core/src/localization/business/countryDialCodes'
import {axiosBaseQuery} from '@daedalus/core/src/utils/network/axiosBaseQuery'
import {ensureUrlCorrectSlashes} from '@daedalus/core/src/utils/url'

interface GetCountriesListRequestParams {
  locale: string
}

interface GetCountriesListResponse {
  locale: string
  countries: Record<string, string>
}

export type GetCountriesListTransformedResponse = Array<{
  name: string
  code: string
  dialCode: string
}>

export const localizationApi = createApi({
  reducerPath: 'localizationApi',
  tagTypes: ['Localization'],
  baseQuery: axiosBaseQuery({
    baseUrl: ''
  }),
  serializeQueryArgs: ({endpointName}) => endpointName,
  endpoints: builder => ({
    getCountriesList: builder.query<
      GetCountriesListTransformedResponse,
      GetCountriesListRequestParams
    >({
      query: ({locale}) => {
        const mappedLocale = iso18nCountriesLocaleMapper(locale)

        const isoCountriesFolder = ensureUrlCorrectSlashes([
          publicUrl,
          `/js/i18n-iso-countries/langs`
        ])

        return {
          url: `/${isoCountriesFolder}${mappedLocale}.json`
        }
      },
      transformResponse: ({countries}: GetCountriesListResponse) => {
        const sortAlphabetically = pipe(values, sortBy(prop('name')))

        const mergedList = mergeAll(
          Object.keys(countries).map(countryCode => {
            return {
              [countryCode]: {
                name: countries[countryCode],
                code: countryCode,
                dialCode: countryDialCodes[countryCode]
              }
            }
          })
        )

        return sortAlphabetically(mergedList)
      }
    })
  })
})

export const {useGetCountriesListQuery} = localizationApi
