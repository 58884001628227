// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgMaximize2 = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 2a.75.75 0 0 0 0 1.5h2.69l-4.22 4.22a.75.75 0 1 0 1.06 1.06l4.22-4.22v2.69a.75.75 0 0 0 1.5 0v-4.5a.75.75 0 0 0-.75-.75h-4.5ZM3.5 12.75a.75.75 0 0 0-1.5 0v4.5c0 .414.336.75.75.75h4.5a.75.75 0 0 0 0-1.5H4.56l4.22-4.22a.75.75 0 1 0-1.06-1.06L3.5 15.44v-2.69Z"
    />
  </svg>
)

export default SvgMaximize2
