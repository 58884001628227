// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgWine = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2a.75.75 0 0 0-.656.386L6 2.75l-.656-.364v.002l-.002.002-.004.008-.014.026a8.473 8.473 0 0 0-.202.414A12.73 12.73 0 0 0 4 8c0 3.16 2.214 5.61 5.25 5.957V16.5h-3.5a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5h-3.5v-2.543C13.786 13.61 16 11.16 16 8a12.73 12.73 0 0 0-.664-3.987 12.117 12.117 0 0 0-.458-1.175 8.46 8.46 0 0 0-.202-.414l-.014-.026-.004-.008-.001-.002v-.001h-.001L14 2.75l.656-.364A.75.75 0 0 0 14 2H6Zm.087 2.487c.138-.416.276-.751.382-.987h7.062a11.507 11.507 0 0 1 .92 3.5H5.55c.095-.983.315-1.847.538-2.513ZM5.524 8.5c.23 2.329 2.06 4 4.476 4s4.246-1.671 4.476-4H5.524Z"
    />
  </svg>
)

export default SvgWine
