import {useEffect} from 'react'

import {isRtlLanguage} from '../../services/locale'

interface LanguageConfigProps {
  locale: string
}

/**
 * Set text direction and lang attributes on the html tag
 */
const LanguageConfig = ({locale}: LanguageConfigProps) => {
  const isRtl = isRtlLanguage(locale)
  const languageDirection = isRtl ? 'rtl' : 'ltr'

  useEffect(() => {
    document.querySelectorAll('html')[0].setAttribute('dir', languageDirection)
    document.querySelectorAll('html')[0].setAttribute('lang', locale)
  }, [languageDirection, locale])

  return null
}

export default LanguageConfig
