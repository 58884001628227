import {Offer} from '@findhotel/sapi'

/**
 * Sort offers by price
 */
export const sortOffersByPrice = <T extends Pick<Offer, 'rate'>>(
  offers: T[]
) => {
  // eslint-disable-next-line fp/no-mutating-methods
  return [...offers].sort((a, b) => a.rate.base - b.rate.base)
}
