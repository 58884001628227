import React, {useMemo} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import isBefore from 'date-fns/isBefore'

import {Icon} from '@daedalus/atlas/Icon'
import {Price} from '@daedalus/atlas/Price'
import {Text} from '@daedalus/atlas/Text'
import {AvailabilityHotelEntity} from '@daedalus/core/src/availability/types'
import {
  calculatePriceType,
  getPriceTypeCalendarBackgroundColor,
  getPriceTypeCalendarColor,
  PRICE_TYPE
} from '@daedalus/core/src/_web/availability'
import {
  dateFormat,
  dateToMiddayDate,
  UTS_DATE_FORMAT
} from '@daedalus/core/src/utils/date'
import {getRoundingStrategy} from '@daedalus/core/src/utils/number'

const DAY_SIZE = 44

const Wrapper = styled.div<{shouldShowPrice?: boolean}>(
  ({theme, shouldShowPrice}) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    flex-wrap: wrap;
    height: ${DAY_SIZE}px;

    ${shouldShowPrice &&
    css`
      align-items: flex-start;
      padding-top: ${theme.layout.spacing.s200}px;
    `}

    &.low {
      background-color: ${getPriceTypeCalendarBackgroundColor(
        PRICE_TYPE.LOW,
        theme
      )};
      border-radius: ${theme.layout.radius.lg}px;
      .selected:not(.start):not(.startEnd) & {
        background-color: ${theme.colors.background.interactive.c100};
      }
      .startEnd & {
        background-color: ${theme.colors.background.interactive.c500};
      }
    }

    &.high {
      background-color: ${getPriceTypeCalendarBackgroundColor(
        PRICE_TYPE.HIGH,
        theme
      )};
      border-radius: ${theme.layout.radius.lg}px;
    }
    .end & {
      .price,
      .loading {
        display: none;
      }
    }
  `
)
const DayContainer = styled(Text)(
  () => css`
    width: 100%;
  `
)

const PriceWrapper = styled(Text)<{priceType: PRICE_TYPE}>(
  ({theme, priceType}) => css`
    color: ${getPriceTypeCalendarColor(priceType, theme)};
    display: flex;
    justify-content: center;
    width: 100%;
    color: ${theme.colors.content.neutral.c600};
    .selected:not(.start) & {
      color: ${theme.colors.content.interactive.c500};
    }
    .low & {
      color: ${theme.colors.content.success.c700};
    }
    .high & {
      color: ${theme.colors.content.accent.c600};
    }
    .startEnd & {
      color: ${theme.colors.content.neutral.c000};
    }
  `
)

interface Props {
  day: Date
  hotelAvailabilityPrices?: AvailabilityHotelEntity
  searchComplete?: boolean
  showTotalPrices?: boolean
  noHighPrices?: boolean
  noUnavailabilityIcon?: boolean
  shouldShowPrices: boolean
}

const getPriceType = (
  hotelAvailabilityPrices: AvailabilityHotelEntity,
  price: number,
  noHighPrices: boolean,
  date: string
) => {
  if (price) {
    const type = calculatePriceType(hotelAvailabilityPrices, price, date)
    if (type === PRICE_TYPE.HIGH && noHighPrices) {
      return PRICE_TYPE.NEUTRAL
    }
    return type
  }
  return PRICE_TYPE.NEUTRAL
}

export const ColorPriceDayInner: React.FC<Props> = ({
  day,
  hotelAvailabilityPrices,
  searchComplete,
  showTotalPrices,
  noHighPrices = false,
  noUnavailabilityIcon = false,
  shouldShowPrices
}) => {
  const stringDate = dateFormat(day, UTS_DATE_FORMAT)
  const {price, currency, priceType, displayPrice} = useMemo(() => {
    if (!hotelAvailabilityPrices) {
      return {
        price: 0,
        currency: '',
        priceType: PRICE_TYPE.NEUTRAL,
        displayPrice: 0
      }
    }
    const price = hotelAvailabilityPrices[stringDate]?.cheapestNightlyRate
    const currency = hotelAvailabilityPrices[stringDate]?.offers[0]?.currency
    const priceType = getPriceType(
      hotelAvailabilityPrices,
      price,
      noHighPrices,
      stringDate
    )

    const rateBreakdown =
      hotelAvailabilityPrices[stringDate]?.cheapestPriceRateBreakdown
    const displayPrice =
      showTotalPrices && rateBreakdown
        ? rateBreakdown.base + rateBreakdown.taxes + rateBreakdown.hotelFees
        : price

    return {
      price,
      currency,
      priceType,
      displayPrice
    }
  }, [hotelAvailabilityPrices, showTotalPrices, stringDate, noHighPrices])

  const isDateBeforeToday = isBefore(
    dateToMiddayDate(day),
    dateToMiddayDate(new Date())
  )

  const getFormattedDate = (date: Date) =>
    date
      .toLocaleDateString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: '2-digit'
      })
      .replaceAll(',', '')

  const shouldShowUnavailability =
    !noUnavailabilityIcon && !price && searchComplete && !isDateBeforeToday

  const shouldShowLoader = !price && !searchComplete && shouldShowPrices

  return (
    <Wrapper
      className={`day-inner ${priceType}`}
      shouldShowPrice={shouldShowPrices}
      aria-label={getFormattedDate(day)}
    >
      <DayContainer variant="labelS">{day.getDate()}</DayContainer>
      {shouldShowPrices && (
        <PriceWrapper priceType={priceType} variant="bodyXS" className="price">
          <Price
            noStyle
            currency={currency}
            value={displayPrice}
            roundingStrategy={getRoundingStrategy()}
            isAbbreviated
            useShortCurrency
          />
        </PriceWrapper>
      )}
      {shouldShowUnavailability && (
        <div className="forbidIcon">
          <Icon size="sm" name="Forbid" colorPath="content.neutral.c300" />
        </div>
      )}
      {shouldShowLoader && (
        <div className="loading">
          <Text variant="bodyXS" colorPath="content.neutral.c300">
            -
          </Text>
        </div>
      )}
    </Wrapper>
  )
}
