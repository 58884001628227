// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPawn = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM10 2a3 3 0 0 0-2.072 5.17l-1.895 7.107c-.534.26-.98.691-1.257 1.243l-.697 1.395A.75.75 0 0 0 4.75 18h10.5a.75.75 0 0 0 .67-1.085l-.696-1.395a2.748 2.748 0 0 0-1.257-1.243L12.07 7.17A3 3 0 0 0 10 2Zm.717 5.914a3.007 3.007 0 0 1-1.434 0L7.66 14h4.68l-1.623-6.086ZM6.118 16.19a1.25 1.25 0 0 1 1.118-.691h5.528c.473 0 .906.268 1.118.691l.155.309H5.963l.154-.309Z"
    />
  </svg>
)

export default SvgPawn
