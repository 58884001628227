import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import CompactSearchSummaryWithStore from 'components/CompactSearchSummaryWithStore'
import FakeSearchFormWithStore from 'components/FakeSearchFormWithStore'
import {getIsSearchBoxVisible} from 'modules/common/selectors'
import {changePreferencesVisibility} from 'modules/overlay/actions'
import Settings from 'Settings'

import {CompactHeader} from '@daedalus/atlas/CompactHeader'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {Page} from '@daedalus/core/src/analytics/types/Events'
import {
  getUser,
  selectAuthEnabled,
  selectIsAuthenticated
} from '@daedalus/core/src/auth/modules/selectors'
import {isReactNativeWebView} from '@daedalus/core/src/native'

import {MobileHeaderMenu} from './MobileHeaderMenu'

const CenterWrapper = styled('div')(
  ({theme}) => css`
    width: 100%;
    padding-left: ${theme.layout.spacing.s300}px;
    padding-right: ${theme.layout.spacing.s250}px;
  `
)

const BottomContentWrapper = styled.div(
  ({theme}) => css`
    padding: ${theme.layout.spacing.s300}px ${theme.layout.spacing.s400}px 0;
  `
)

interface Props {
  /** Override the default center content (defaults to search form if enabled) */
  centerContent?: React.ReactElement
  hasShadow?: boolean
  hasSearchForm?: boolean
  hasHeaderMenu?: boolean
  disableLogoClick?: boolean
  forceHideSearchBox?: boolean
  onBackButtonClick?: () => void
  pageName?: Page
  showStartLogo?: boolean
}

export const MobileHeader = ({
  hasHeaderMenu = true,
  hasSearchForm = false,
  hasShadow = false,
  disableLogoClick = false,
  forceHideSearchBox = false,
  centerContent,
  onBackButtonClick,
  showStartLogo,
  pageName
}: Props) => {
  const isPaymentPage = pageName === Page.Payment
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const dispatch = useDispatch()
  const {brand} = useBrand()
  const user = useSelector(getUser)
  const authEnabled = useSelector(selectAuthEnabled)
  const isSearchBoxOpen =
    useSelector(getIsSearchBoxVisible) && !forceHideSearchBox

  const showMenuOnRNWebView = !isReactNativeWebView()

  const shouldShowEndContent = hasHeaderMenu && showMenuOnRNWebView
  const hasExpandedCenterContent = !showMenuOnRNWebView && !isPaymentPage

  const handleShowPreferences = () => {
    dispatch(changePreferencesVisibility(true, false, false)())
  }

  const EndContent = () => {
    if (
      (shouldShowEndContent && isPaymentPage && isAuthenticated) ||
      (shouldShowEndContent && !isPaymentPage)
    ) {
      return (
        <MobileHeaderMenu
          shouldShowUserAvatar={authEnabled}
          user={user || undefined}
          onShowPreferences={handleShowPreferences}
          hideBurgerMenu={isPaymentPage}
        />
      )
    }
    return null
  }

  return (
    <CompactHeader
      brand={brand}
      hasExpandedCenterContent={hasExpandedCenterContent}
      hasShadow={hasShadow}
      logoUrl={Settings.get('REACT_APP_VIO_SEARCH_URL')}
      onBackButtonClick={onBackButtonClick}
      disableLogoClick={disableLogoClick}
      showCenterLogo={!(centerContent || hasSearchForm)}
      showStartLogo={showStartLogo}
      centerContent={
        centerContent ? (
          centerContent
        ) : hasSearchForm && !isSearchBoxOpen ? (
          <CenterWrapper>
            <CompactSearchSummaryWithStore />
          </CenterWrapper>
        ) : undefined
      }
      endContent={<EndContent />}
      bottomContent={
        <>
          {isSearchBoxOpen && (
            <BottomContentWrapper>
              <FakeSearchFormWithStore />
            </BottomContentWrapper>
          )}
        </>
      }
    />
  )
}
