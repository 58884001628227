import {useContext} from 'react'
import {useDispatch} from 'react-redux'

import {useDeviceLayout} from '@daedalus/atlas/src/context/deviceLayout'
import {
  setUnlockHotelId,
  showPasswordlessLogin
} from '@daedalus/core/src/auth/modules/slice'
import {
  AuthenticationFlows,
  ShowPasswordlessLoginPayload
} from '@daedalus/core/src/auth/types/Auth'

import {SusiHistoryState} from '../../authentication/SUSI/bottomSheet/SusiBottomSheet/types'
import {SusiContext} from '../SUSI/bottomSheet/SusiBottomSheet/SusiProvider'

type UseLogInParamsType = Pick<
  ShowPasswordlessLoginPayload,
  'flow' | 'anonymousId' | 'unlockHotelId' | 'analyticsData'
> & {sourceComponent?: string}

type HandleLogInParamsType = UseLogInParamsType & {
  isMobile: boolean
  updateSUSILocationState?: (state: SusiHistoryState) => void
}

const handleLogIn = ({
  anonymousId,
  flow,
  isMobile,
  sourceComponent,
  unlockHotelId,
  updateSUSILocationState,
  analyticsData
}: HandleLogInParamsType) => {
  const authFlow = flow

  const isBottomSheetLoginVisible = isMobile
  const isPasswordlessLoginVisible = !isBottomSheetLoginVisible

  if (isBottomSheetLoginVisible && updateSUSILocationState) {
    updateSUSILocationState({active: true, analyticsData})
  }

  return showPasswordlessLogin({
    anonymousId,
    flow: authFlow || AuthenticationFlows.Login,
    isPasswordlessLoginVisible,
    sourceComponent,
    unlockHotelId
  })
}

export const useLogIn = () => {
  const dispatch = useDispatch()
  const {isMobile} = useDeviceLayout()
  const {updateSUSILocationState, trackSusiDisplayed} = useContext(SusiContext)

  return ({
    anonymousId,
    flow,
    sourceComponent,
    unlockHotelId,
    analyticsData
  }: UseLogInParamsType) => {
    dispatch(setUnlockHotelId(unlockHotelId))
    dispatch(
      handleLogIn({
        anonymousId,
        flow,
        isMobile,
        sourceComponent,
        unlockHotelId,
        analyticsData,
        ...(!!updateSUSILocationState && {updateSUSILocationState})
      })
    )
    if (trackSusiDisplayed) trackSusiDisplayed(flow, analyticsData)
  }
}
