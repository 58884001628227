import React, {ReactElement, SyntheticEvent} from 'react'
import {styled} from '@linaria/react'

import {cssTheme} from '../../../themes'
import {linariaMq} from '../../../utils/breakpoints'
import {NoStyleLink} from '../helpers/NoStyleLink'

type DropDownMenuLinkProps = {
  children: React.ReactNode
  /** Pass through classname to allow styles overrides */
  className?: string
  url?: string
  icon?: ReactElement
  onClick?: (e: SyntheticEvent) => void
  /** Identify the element for selection in integration tests, FullStory, etc. */

  dataId?: string
}

const LinkElement = styled(NoStyleLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${cssTheme.layout.spacing.s500};
  ${cssTheme.typography.text.labelS};
  color: ${cssTheme.colors.content.neutral.c950};
  text-decoration: none;
  gap: ${cssTheme.layout.spacing.s300};

  &:first-child {
    border-radius: ${cssTheme.layout.radius.md} ${cssTheme.layout.radius.md} 0 0;
  }
  &:last-child {
    border-radius: 0 0 ${cssTheme.layout.radius.md} ${cssTheme.layout.radius.md};
  }

  &:active {
    background: ${cssTheme.colors.background.neutral.c000};
  }

  ${linariaMq.desktopXs} {
    &:hover {
      background: ${cssTheme.colors.background.neutral.c050};
    }

    &:focus {
      background: ${cssTheme.colors.background.neutral.c100};
    }
  }
`

const IconWrapper = styled.div`
  display: flex;
`

export const DropDownMenuLink = ({
  children,
  url = '#',
  icon,
  className,
  onClick,
  dataId
}: DropDownMenuLinkProps) => {
  return (
    <LinkElement
      data-Id={dataId}
      href={url}
      onClick={onClick}
      className={className}
    >
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {children}
    </LinkElement>
  )
}
