import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {GemsText} from '@findhotel/atlas-assets'

interface Props {
  hasBorder?: boolean
}

const TextBackground = styled('div')<Props>(
  ({theme, hasBorder}) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    border-radius: ${theme.layout.radius.sm}px;
    border: 2px solid ${theme.colors.border.neutral.c000};
    background-color: ${theme.colors.background.special.c600};
    ${hasBorder && `border: 2px solid ${theme.colors.border.neutral.c000};`}
  `
)

export const GemsTextTag = ({hasBorder = false}: Props) => (
  <TextBackground hasBorder={hasBorder}>
    <GemsText size={6} />
  </TextBackground>
)
