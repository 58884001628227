// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgWasher = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 10a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0ZM10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm-1.5 8a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM10 7a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
    />
  </svg>
)

export default SvgWasher
