// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgBookmark = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4.75A2.75 2.75 0 0 1 6.75 2h6.5A2.75 2.75 0 0 1 16 4.75v12.5a.75.75 0 0 1-1.238.57L10 13.737l-4.762 4.081A.75.75 0 0 1 4 17.25V4.75ZM6.75 3.5c-.69 0-1.25.56-1.25 1.25v10.87l4.012-3.44a.75.75 0 0 1 .976 0l4.012 3.44V4.75c0-.69-.56-1.25-1.25-1.25h-6.5Z"
    />
  </svg>
)

export default SvgBookmark
