// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCheckmarksDouble = ({
  color = 'currentColor',
  size = 20
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.533 6.288a.779.779 0 0 0-.043-1.084.741.741 0 0 0-1.062.043l-6.95 7.685-2.157-2.548a.741.741 0 0 0-1.06-.08.779.779 0 0 0-.078 1.084l2.707 3.199a.745.745 0 0 0 1.122.019l7.52-8.318Zm2.29 2.883a.66.66 0 0 0-.018-.919.627.627 0 0 0-.9.018l-4.83 5.128-.449-.507a.627.627 0 0 0-.898-.046.66.66 0 0 0-.046.917l.906 1.024a.63.63 0 0 0 .931.015l5.303-5.63Z"
    />
  </svg>
)

export default SvgCheckmarksDouble
