import React, {useCallback} from 'react'
import {useIntl} from 'react-intl'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Icon} from '@daedalus/atlas/Icon'
import {InputText} from '@daedalus/atlas/InputText'

interface Props<T> {
  /**
   * Items to search through
   */
  items: T[]
  /**
   * @param matchedItems - Items that match the search criteria
   */
  children: (matchedItems: T[], isSearching?: boolean) => React.ReactNode
  /**
   * Function to determine whether an item matches the search criteria.
   * You can provide your own implementation, or use one already available in
   * SearchInput.matchers
   *
   * @example SearchInput.matchers.default('name')
   * @example (item, searchValue) =\> item.name.toLowerCase().includes(searchValue.toLowerCase()
   */
  itemMatcher: (item: T, searchValue: string) => boolean
  onFocus?: () => void
}

const InputTextWrapper = styled.div(
  ({theme}) => css`
    position: sticky;
    background-color: ${theme.colors.background.neutral.c000};
    z-index: 1;
    top: 0;
    padding: ${theme.layout.spacing.s500}px 0;
  `
)

export const SearchInput = <T,>({
  items,
  children,
  itemMatcher,
  onFocus
}: Props<T>) => {
  const {formatMessage} = useIntl()
  const [searchValue, setSearchValue] = React.useState('')
  const [matchedItems, setMatchedItems] = React.useState<T[]>([])

  const onClear = useCallback(() => {
    setSearchValue('')
  }, [setSearchValue])

  const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()

    // This is so we can close the mobile keyboard when pressing enter.
    if (
      document?.activeElement &&
      'blur' in document.activeElement &&
      typeof document.activeElement.blur === 'function'
    ) {
      document.activeElement.blur()
    }
  }

  React.useEffect(() => {
    setMatchedItems(items.filter(item => itemMatcher(item, searchValue)))
  }, [items, searchValue, itemMatcher])

  return (
    <form onSubmit={onSubmit}>
      <InputTextWrapper>
        <InputText
          isRounded
          id="search-input"
          name="search-input"
          placeholder={formatMessage({id: 'search', defaultMessage: 'Search'})}
          icon={<Icon name="Search" colorPath="content.neutral.c600" />}
          value={searchValue}
          onChange={setSearchValue}
          onClear={onClear}
          onFocus={onFocus}
        />
      </InputTextWrapper>
      {children(matchedItems, Boolean(searchValue))}
    </form>
  )
}

// eslint-disable-next-line fp/no-mutation
SearchInput.matchers = {
  /**
   * Helper function to check whether a string property of an object matches
   * the current search criteria.
   *
   * @param path - Property within the provided item object to match against
   */
  default:
    <T,>(path: Array<keyof T>) =>
    (item: T, searchValue: string) => {
      const values = path.map(key => item[key])
      return values.some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(searchValue.toLowerCase())
      )
    }
}
