// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgScroll = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4.75A2.75 2.75 0 0 1 6.75 2h8.875c.75 0 1.362.422 1.757.97.396.548.618 1.269.618 2.03v.25a.75.75 0 0 1-.75.75H15v9a3.18 3.18 0 0 1-.787 2.085c-.456.521-1.116.915-1.838.915H4.75A2.75 2.75 0 0 1 2 15.25v-.5a.75.75 0 0 1 .75-.75H4V4.75ZM5.5 14h5.25a.75.75 0 0 1 .75.75V15c0 .481.142.886.334 1.152.193.267.394.348.541.348.175 0 .453-.11.71-.403A1.68 1.68 0 0 0 13.5 15V5c0-.52.073-1.04.238-1.5H6.75c-.69 0-1.25.56-1.25 1.25V14ZM15.625 3.5c-.113 0-.237.043-.372.28a2.058 2.058 0 0 0-.22.72h1.413a1.78 1.78 0 0 0-.28-.652c-.193-.267-.394-.348-.541-.348Zm-5.312 13a3.552 3.552 0 0 1-.28-1H3.524c.116.57.62 1 1.225 1h5.563ZM7 6.75A.75.75 0 0 1 7.75 6h3.5a.75.75 0 0 1 0 1.5h-3.5A.75.75 0 0 1 7 6.75Zm0 3A.75.75 0 0 1 7.75 9h3.5a.75.75 0 0 1 0 1.5h-3.5A.75.75 0 0 1 7 9.75Z"
    />
  </svg>
)

export default SvgScroll
