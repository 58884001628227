import {append, update} from 'ramda'

import {
  HideOverlayParams,
  ModuleActionType,
  OVERLAY_HIDE,
  OVERLAY_REPLACE_TOP,
  OVERLAY_SHOW,
  OVERLAY_STACK_REPLACE,
  ReplaceOverlaysParams,
  ReplaceOverlayTopParams,
  ShowOverlayParams
} from './actions'

export type OverlayStateItemType = {
  extraState?: Record<string, unknown> | null
  id: string
}

export type OverlayStateType = OverlayStateItemType[]

const initialState: OverlayStateType = []

export default (
  state: OverlayStateType = initialState,
  action: ModuleActionType
): OverlayStateType => {
  switch (action.type) {
    case OVERLAY_SHOW:
      return append<OverlayStateItemType>(
        action.params as ShowOverlayParams,
        state
      )

    case OVERLAY_HIDE:
      return state.filter(
        overlay => overlay.id !== (action.params as HideOverlayParams).id
      )

    case OVERLAY_STACK_REPLACE:
      return (action.params as ReplaceOverlaysParams).overlays

    case OVERLAY_REPLACE_TOP:
      return update<OverlayStateItemType>(
        state.length - 1,
        action.params as ReplaceOverlayTopParams,
        state
      )

    default:
      return state
  }
}
