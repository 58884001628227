import {MiddlewareType} from 'middlewares'
import {getEventProperties as getEventV2Properties} from 'modules/analytics/trackEventV2Helpers'
import {getSearchId} from 'modules/meta/selectors'
import {logToDataDog} from 'services/datadogLogger'
import {trackFullStoryEvent} from 'services/fullstory'
import Settings, {SettingsKey} from 'Settings'

import {
  TRACK_EXPERIMENT_SERVED,
  trackEvent
} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  ExperimentContext,
  Team,
  TrackEventPayload,
  TrackEventProperties
} from '@daedalus/core/src/analytics/types/Events'
import AnalyticsUtils from '@daedalus/core/src/analytics/utils'
import {createDataPipelineEventName} from '@daedalus/core/src/analytics/utils/trackEventHelpers'

export enum Component {
  BookingPreviewCard = 'BookingPreviewCard',
  PersonalDetailsForm = 'PersonalDetailsForm',
  GuestDetailsDetailsForm = 'GuestDetailsDetailsForm',
  BillingDetailsForm = 'BillingDetailsForm',
  PaymentDetailsForm = 'PaymentDetailsForm',
  ImportantInfoCard = 'ImportantInfoCard',
  PostBooking = 'PostBooking',
  Preferences = 'Preferences',
  Language = 'Language',
  Currency = 'Currency'
}

/**
 * Reset the Segment analytics endpoint to the default endpoint after the endpoint was set to the team endpoint.
 * This is to avoid the analytics data to be sent to the wrong endpoint. All events that do not belong to a specific
 * team will be sent to the endpoint which is set by the following function.
 */
const resetSegmentEndpoint = () => {
  const ANALYTICS_ENDPOINT = Settings.get('REACT_APP_ANALYTICS_ENDPOINT')
  const API_KEY = Settings.get('REACT_APP_ANALYTICS_API_KEY')
  AnalyticsUtils.overrideSegmentDestinationSettings(ANALYTICS_ENDPOINT, API_KEY)
}

/**
 * In order to send each team events to a different endpoint, we need to override the segment endpoint by the team
 * endpoint which is defined in the environment variables. This function overrides the Segment endpoint with the
 * team endpoint.
 * @param team - the team that the event is being sent by
 */
const setSegmentTeamEndpoint = (team: Team) => {
  const teamName = team.toUpperCase() as Uppercase<Team>
  let TEAM_ANALYTICS_ENDPOINT = Settings.get(
    `REACT_APP_ANALYTICS_${teamName}_ENDPOINT` as SettingsKey
  )

  let TEAM_ANALYTICS_API_KEY = Settings.get(
    `REACT_APP_ANALYTICS_${teamName}_API_KEY` as SettingsKey
  )

  if (team === Team.Default) {
    TEAM_ANALYTICS_ENDPOINT = Settings.get('REACT_APP_ANALYTICS_OTA_ENDPOINT')

    TEAM_ANALYTICS_API_KEY = Settings.get('REACT_APP_ANALYTICS_OTA_API_KEY')
  }

  if (!TEAM_ANALYTICS_ENDPOINT || !TEAM_ANALYTICS_API_KEY) {
    throw new Error(
      `Missing analytics endpoint and API key environment variable for team: ${team}`
    )
  }

  AnalyticsUtils.overrideSegmentDestinationSettings(
    TEAM_ANALYTICS_ENDPOINT,
    TEAM_ANALYTICS_API_KEY
  )
}

const trackAnalyticsEvent = (
  eventName: string,
  eventProperties: (TrackEventProperties | ExperimentContext) & {team?: Team}
) => {
  try {
    const {team = Team.Default, ...propertiesWithoutTeam} = eventProperties
    setSegmentTeamEndpoint(team)
    AnalyticsUtils.trackEvent(eventName, propertiesWithoutTeam)
    resetSegmentEndpoint()
  } catch (error) {
    console.error(error)
  }
}

const middleware: MiddlewareType = store => next => async action => {
  const {dispatch, getState} = store
  if (!AnalyticsUtils.analyticsPlatformEnabled()) return next(action)

  switch (action.type) {
    case trackEvent.type: {
      const metaData = {
        searchId: getSearchId(getState())
      }

      const payload = action.payload as TrackEventPayload
      const analyticsEventName = createDataPipelineEventName(payload)
      const eventProperties = getEventV2Properties(payload, metaData)

      trackAnalyticsEvent(analyticsEventName, eventProperties)
      trackFullStoryEvent(analyticsEventName, eventProperties)

      logToDataDog({
        logName: eventProperties.name, // Use more descriptive full name for DD so that it's easier to navigate the logs
        metadata: eventProperties,
        level: 'info'
      })

      break
    }

    case TRACK_EXPERIMENT_SERVED: {
      const {experimentId, variationId, isActive} = action

      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.Experiment,
          team: Team.CoreWebVitals,
          action: Action.Served,
          analyticsContext: {
            [AnalyticsContext.ExperimentContext]: {
              experimentId,
              variationId,
              isActive
            }
          }
        })
      )
      break
    }

    default:
      break
  }

  return next(action)
}

export default middleware
