// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgServiceBell = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-7 9a7 7 0 0 1 14 0v2.168c.591.281 1 .884 1 1.582v1.5A1.75 1.75 0 0 1 16.25 18H3.75A1.75 1.75 0 0 1 2 16.25v-1.5c0-.698.409-1.3 1-1.582V11Zm12.5 0v2h-11v-2a5.5 5.5 0 1 1 11 0ZM3.75 14.5a.25.25 0 0 0-.25.25v1.5c0 .138.112.25.25.25h12.5a.25.25 0 0 0 .25-.25v-1.5a.25.25 0 0 0-.25-.25H3.75Z"
    />
  </svg>
)

export default SvgServiceBell
