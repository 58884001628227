import React, {ReactElement} from 'react'
import {css, cx} from '@linaria/core'
import {styled} from '@linaria/react'

import {cssTheme} from '../../../themes'
import {BodyVariant} from '../Text'

interface BaseProps {
  /** Content to be labeled */
  children?: ReactElement
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Pass through style to allow styles overrides */
  style?: React.CSSProperties
  /** Content of the label */
  value: ReactElement | string
  /** Should align label to the left */
  isHorizontalAligned?: boolean
  /** The body variant of the component */
  variant?: BodyVariant
  /** Should render isRequired icon * */
  isRequired?: boolean
}

interface PropsWithChildren extends BaseProps {
  /** Content to be labeled */
  children: ReactElement
  /** Id of an explicitly associated element. Can be used to disassociate the label from its child by setting it to an empty string. */
  forId?: string
}

interface PropsWithoutChildren extends BaseProps {
  /** Content to be labeled */
  children?: ReactElement
  /** Id of an explicitly associated element. You have to provide this id if you are not wrapping element that you want to label. */
  forId: string
}

type Props = PropsWithChildren | PropsWithoutChildren

export const RequiredMark = styled.span`
  color: ${cssTheme.colors.content.danger.c700};
`

const typographyStyles: Record<BodyVariant, string> = {
  bodyXS: css`
    ${cssTheme.typography.text.bodyXS}
  `,
  bodyS: css`
    ${cssTheme.typography.text.bodyS}
  `,
  bodyM: css`
    ${cssTheme.typography.text.bodyM}
  `
}

const LabelWrapper = styled.label`
  display: flex;

  &:not(&.--horizontal) {
    align-items: flex-start;
    flex-direction: column;
    gap: ${cssTheme.layout.spacing.s250};
  }

  &.--horizontal {
    align-items: center;
    flex-direction: row;
    gap: ${cssTheme.layout.spacing.s400};
  }
`

export const Label = ({
  children,
  forId,
  value,
  isHorizontalAligned = false,
  variant = 'bodyM',
  isRequired = false,
  className,
  style
}: Props) => (
  <LabelWrapper
    htmlFor={forId}
    className={cx(
      className,
      isHorizontalAligned && '--horizontal',
      typographyStyles[variant]
    )}
    style={style}
  >
    <span>
      {value}
      {isRequired && <RequiredMark>*</RequiredMark>}
    </span>
    {children}
  </LabelWrapper>
)
