import React from 'react'
import {useSelector} from 'react-redux'

import {Card} from '@daedalus/atlas/Card'
import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {selectIsAuthenticated} from '@daedalus/core/src/auth/modules/selectors'
import {Brand} from '@daedalus/core/src/brand/types'

import {AccountSideMenu} from '../../../account/AccountSideMenu'
import {ProfilePicture} from '../../../account/ProfilePicture'

interface Props {
  currentPageId: string
  brand: Brand
  anonymousId: string
}

export const SideBar = ({currentPageId, anonymousId, brand}: Props) => {
  const {isMobile} = useDeviceLayout()
  const isAuthenticated = useSelector(selectIsAuthenticated)

  return !isMobile ? (
    <div>
      <Card borderRadius="md" shadow="action">
        {isAuthenticated && <ProfilePicture />}
        <AccountSideMenu
          currentPageId={currentPageId}
          anonymousId={anonymousId}
          brand={brand}
        />
      </Card>
    </div>
  ) : null
}
