import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Alert} from '@daedalus/atlas/Alert'
import {Button} from '@daedalus/atlas/Button'
import {CookieIcon} from '@daedalus/atlas/foundation/illustrations/CookieIcon'
import {NoStyleLink} from '@daedalus/atlas/helpers/NoStyleLink'
import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import {mq} from '@daedalus/atlas/utils/breakpoints'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {CookieBannerClickComponent} from './useCookieConsent'

const CookieBannerWrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
  `
)

const TitleWrapper = styled.div(
  ({theme}) => css`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.layout.spacing.s400}px;
  `
)

const CookieIconWrapper = styled.div(
  ({theme}) => css`
    display: flex;
    margin-right: ${theme.layout.spacing.s300}px;
  `
)

const BodyWrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: column;

    ${mq.desktopSm(css`
      flex-direction: row;
      justify-content: space-between;
    `)};
  `
)

const SubTitleWrapper = styled.div(
  ({theme}) => css`
    display: flex;

    ${mq.desktopSm(css`
      padding-right: ${theme.layout.spacing.s600}px;
    `)};
  `
)

const SubTitle = styled(Text)(
  ({theme}) => css`
    ${theme.typography.text.bodyS}

    ${mq.desktopSm(css`
      ${theme.typography.text.bodyM};
    `)};
  `
)

const SettingsLink = styled(NoStyleLink)(
  () => css`
    text-decoration: underline;
    font-weight: inherit;
  `
)

const ButtonContainer = styled.div(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-top: ${theme.layout.spacing.s400}px;
    gap: ${theme.layout.spacing.s400}px;

    ${mq.desktopSm(css`
      padding-top: 0;
    `)};
  `
)

const ActionButton = styled(Button)`
  &.__linariaOverride {
    width: 100%;
    ${mq.desktopSm(css`
      width: 190px;
    `)};
  }
`

const StyledAlertBannerFullWidth = styled(Alert)`
  max-width: 1150px;
`

interface Props {
  acceptAllCookies: () => void
  openPreferencesCenter: () => void
  rejectAllCookies: (component?: CookieBannerClickComponent) => void
}

const CookieConsentBannerComponent = ({
  acceptAllCookies,
  openPreferencesCenter,
  rejectAllCookies
}: Props) => {
  return (
    <StyledAlertBannerFullWidth
      onClose={rejectAllCookies}
      title={
        <TitleWrapper>
          <CookieIconWrapper>
            <CookieIcon />
          </CookieIconWrapper>
          <Text variant="labelM">
            <FormattedMessageWrapper
              id="cookieProBannerTitle"
              defaultMessage="We use cookies"
            />
          </Text>
        </TitleWrapper>
      }
    >
      <CookieBannerWrapper>
        <BodyWrapper>
          <SubTitleWrapper>
            <SubTitle>
              <FormattedMessageWrapper
                id="cookieProBannerMessage"
                defaultMessage="We use cookies to give you the best experience on our website and also for analytics purposes."
              />{' '}
              <SettingsLink onClick={openPreferencesCenter}>
                <FormattedMessageWrapper
                  id="cookieProBannerSettingsLink"
                  defaultMessage="Learn more and adjust settings."
                />
              </SettingsLink>
            </SubTitle>
          </SubTitleWrapper>

          <ButtonContainer>
            <ActionButton
              fullWidth
              iconStart={<Icon name="Checkmark" size="md" />}
              dataId="acceptAllCookiesButton"
              size="lg"
              variant="secondary"
              onClick={acceptAllCookies}
              className="__linariaOverride"
            >
              <FormattedMessageWrapper
                id="acceptAllCookiesButton"
                defaultMessage="Accept"
              />
            </ActionButton>
            <ActionButton
              fullWidth
              iconStart={<Icon name="Close" size="md" />}
              dataId="rejectAllCookiesButton"
              size="lg"
              variant="secondary"
              className="__linariaOverride"
              onClick={() =>
                rejectAllCookies(CookieBannerClickComponent.RejectButton)
              }
            >
              <FormattedMessageWrapper
                id="rejectAllCookiesButton"
                defaultMessage="Reject"
              />
            </ActionButton>
          </ButtonContainer>
        </BodyWrapper>
      </CookieBannerWrapper>
    </StyledAlertBannerFullWidth>
  )
}

export const CookieConsentBanner = React.memo(CookieConsentBannerComponent)
