import Settings from 'Settings'

import {getAnonymousId} from '@daedalus/core/src/_web/anonymousId'
import {getCookie} from '@daedalus/core/src/_web/utils/cookies'
import AnalyticsUtils from '@daedalus/core/src/analytics/utils'
import {
  safelyGetStorageItem,
  safelySetStorageItem
} from '@daedalus/core/src/utils/persistence'

const ANALYTICS_ENDPOINT = Settings.get('REACT_APP_ANALYTICS_ENDPOINT')
const API_KEY = Settings.get('REACT_APP_ANALYTICS_API_KEY')
const GTM_CONTAINER_ID = Settings.get('REACT_APP_GTM_ID')
const getLogEvents = (): string =>
  safelyGetStorageItem(localStorage, 'logEvents', 'getLogEvents failed')

const setLogEvents = (logEvents: string): void =>
  safelySetStorageItem(
    localStorage,
    'logEvents',
    logEvents,
    'setLogEvents failed'
  )
const initializeSDK = () => {
  const integrations = {
    'Segment.io': {
      apiKey: API_KEY,
      apiHost: ANALYTICS_ENDPOINT
    },
    'Google Tag Manager': {
      containerId: GTM_CONTAINER_ID,
      // This prevents double page view tracking
      trackCategorizedPages: false
    }
  }
  // Switch for easier debugging
  const logEventsParam = new URLSearchParams(window.location.search).get(
    'logEvents'
  )
  if (logEventsParam) setLogEvents(logEventsParam)
  const logEvents = getLogEvents() === '1'

  AnalyticsUtils.setLogEventsStatus(logEvents)
  AnalyticsUtils.initialize(integrations, getAnonymousId())
  /** Meta pixel cookies. Helpful for retargeting.
   * https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc
   */
  const fbp = getCookie('_fbp')
  const fbc = getCookie('_fbc')
  AnalyticsUtils.identify(undefined, {fbp, fbc})
}

export default initializeSDK
