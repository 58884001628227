// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgIce = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.22 2.22a.75.75 0 0 1 1.06 0L10 3.94l1.72-1.72a.75.75 0 0 1 1.06 1.06l-2.03 2.03v2.88l2.97-2.97a.75.75 0 0 1 1.06 1.06l-2.97 2.97h2.88l2.03-2.03a.75.75 0 0 1 1.06 1.06L16.06 10l1.72 1.72a.75.75 0 0 1-1.06 1.06l-2.03-2.03h-2.88l2.97 2.97a.75.75 0 1 1-1.06 1.06l-2.97-2.97v2.652l2.057 2.286a.75.75 0 1 1-1.114 1.004L10 15.872l-1.693 1.88a.75.75 0 1 1-1.114-1.004l2.057-2.286V11.81l-2.97 2.97a.75.75 0 0 1-1.06-1.06l2.969-2.97H5.538l-2.286 2.057a.75.75 0 1 1-1.004-1.114L4.128 10l-1.88-1.692a.75.75 0 1 1 1.004-1.115L5.538 9.25h2.651L5.22 6.28A.75.75 0 0 1 6.28 5.22l2.97 2.97V5.31L7.22 3.28a.75.75 0 0 1 0-1.06Z"
    />
  </svg>
)

export default SvgIce
