/**
 * Before adding a new error type:
 * 1. Check if it's already defined in packages/core/src/api-types/bovio/response/error.ts
 * 2. If not, it's preferred to update the backend schema first, then generate
 * types from it.
 */
export enum ErrorTypes {
  Fatal = 'fatal',
  PCIProxy = 'pci_proxy'
}
