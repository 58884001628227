import {PaymentFlowType} from 'modules/offerCheck/types'

import {LinkType} from '@daedalus/core/src/booking/types/Link'
import {ActionLinkTypes} from '@daedalus/core/src/offer/types/actionLink'

const findLinkByType = (linkType: ActionLinkTypes, links: LinkType[] = []) =>
  links.find(link => link.type === linkType)

export const getBookingFinalizationLink = (links: LinkType[] = []) =>
  findLinkByType(ActionLinkTypes.BOOKING_FINALIZATION_ASYNC, links)

export const getSplitBookingFinalizationLink = (links: LinkType[] = []) =>
  findLinkByType(ActionLinkTypes.SB_BOOKING_FINALIZATION, links)

export const getExistingBookingConfirmationLink = (links: LinkType[] = []) =>
  findLinkByType(ActionLinkTypes.BOOKING_PAYMENT_CONFIRMATION, links)

export const getChargeLaterFinalizationLink = (links: LinkType[] = []) =>
  findLinkByType(ActionLinkTypes.CHARGE_LATER_BOOKING_FINALIZATION, links)

export const getSplitBookingStatusLink = (
  links: LinkType[] = [],
  isBundle: boolean
) =>
  // clear this in [sc-126922] - BookingCoreExperience
  isBundle
    ? findLinkByType(ActionLinkTypes.BOOKING_STATUS, links)
    : findLinkByType(ActionLinkTypes.SB_BOOKING_STATUS, links)

export const getBookingStatusLink = (links: LinkType[] = []) =>
  findLinkByType(ActionLinkTypes.BOOKING_STATUS, links)

export const getBookingCreationLink = (
  links: LinkType[] = [],
  flowType?: PaymentFlowType
) =>
  flowType === PaymentFlowType.SplitBooking
    ? findLinkByType(ActionLinkTypes.SB_BOOKING_CREATION, links) ||
      findLinkByType(ActionLinkTypes.SB_BUNDLE_BOOKING_CREATION, links)
    : findLinkByType(ActionLinkTypes.BOOKING_CREATION_ASYNC, links)

export const getChargeLaterBookingCreationLink = (links: LinkType[] = []) =>
  findLinkByType(ActionLinkTypes.CHARGE_LATER_BOOKING_CREATION, links)

export const getBookingPaymentOptionLink = (links: LinkType[] = []) =>
  findLinkByType(ActionLinkTypes.PAYMENT_OPTIONS, links)

export const getBookingPaymentLink = (links: LinkType[] = []) =>
  findLinkByType(ActionLinkTypes.BOOKING_PAYMENT, links)

export const getPaymentMethodLink = (links: LinkType[] = []) =>
  findLinkByType(ActionLinkTypes.PAYMENT_METHODS, links)

export const getBookingConfirmationNotificationLink = (
  links: LinkType[] = []
) => findLinkByType(ActionLinkTypes.BOOKING_CONFIRMATION_NOTIFICATION, links)

export const getManageBookingLink = (links: LinkType[] = []) =>
  findLinkByType(ActionLinkTypes.MANAGE_BOOKING, links)

interface getFinalizationLinkParams {
  isExistingBooking: boolean
  isChargeLaterBookingCreation: boolean
  isSplitBooking: boolean
  isBundle: boolean
  links?: LinkType[]
}
export const getFinalizationLink = ({
  isExistingBooking,
  isChargeLaterBookingCreation,
  isSplitBooking,
  isBundle,
  links
}: getFinalizationLinkParams) => {
  if (isExistingBooking) return getExistingBookingConfirmationLink(links)
  if (isChargeLaterBookingCreation) return getChargeLaterFinalizationLink(links)
  if (isSplitBooking && !isBundle) return getSplitBookingFinalizationLink(links)
  return getBookingFinalizationLink(links)
}
