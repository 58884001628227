import React, {useCallback, useEffect, useRef} from 'react'
import {
  CaptionLabelProps,
  DateFormatter,
  DayContentProps,
  DayModifiers,
  DayPicker,
  Matcher,
  Months
} from 'react-day-picker'
import addDays from 'date-fns/addDays'
import addMonths from 'date-fns/addMonths'
import isBefore from 'date-fns/isBefore'
import isSameDay from 'date-fns/isSameDay'
import isSameMonth from 'date-fns/isSameMonth'
import {enUS} from 'date-fns/locale'
import parseIso from 'date-fns/parseISO'
import subDays from 'date-fns/subDays'

import {Icon} from '@daedalus/atlas/Icon'
import {scrollContainerToElement} from '@daedalus/core/src/_web/utils/browser'
import {MAX_DATE_DIFFERENCE} from '@daedalus/core/src/datePicker/config'
import {
  DatePickerType,
  DatePickerVariant,
  DayOfWeekType
} from '@daedalus/core/src/datePicker/types'
import {
  dateFormat,
  dateStringToMiddayDate,
  dateToMiddayDate,
  MiddayDate,
  UTS_DATE_FORMAT
} from '@daedalus/core/src/utils/date'

import {MonthName, VirtualizedMonths, WeekDay} from '../components'
import {DatePickerElement} from '../styles'

type ReactRefType<ElementType> = {current: null | ElementType}

interface Props {
  containerRef?: ReactRefType<HTMLDivElement>
  checkIn: string | null | undefined
  checkOut: string | null | undefined
  firstDayOfWeek: number
  maxMonthsCount?: number
  months: string[]
  numberOfMonthsToShow?: number
  onDOMChange?: (ev?: Event) => void
  onMount?: () => void
  onChange: (checkIn: string, checkOut: string) => void
  onCheckOutSelected?: () => void
  onDatePickerOpen: (dateType: DatePickerType) => void
  onDayClick?: (dateType: DatePickerType, date: string) => void
  openedDatePickerType?: DatePickerType | null | undefined
  weekdaysShort: string[]
  maxLengthOfStay?: number
  variant?: DatePickerVariant
  earliestCheckInDate?: Date
  isCompact?: boolean
  /**
   * Will wrap Months in a Virtuoso component.
   * Only used in `vertical` variant when `numberOfMonthsToShow` is greater than 10.
   */
  virtualize?: boolean
}

type DisabledDays = {
  before: Date
  after: Date | undefined
}

type DisabledDaysParams = {
  checkInDate: Date | undefined
  earliestCheckInDate: Date
  maxLengthOfStay: number
}

const determineCheckOutDisabledDays = ({
  checkInDate,
  earliestCheckInDate,
  maxLengthOfStay
}: DisabledDaysParams): DisabledDays | (() => true) => {
  if (checkInDate && checkInDate < earliestCheckInDate) {
    // Disable all checkOut days
    return () => true
  }

  const lengthOfStayBoundary =
    checkInDate && addDays(checkInDate, maxLengthOfStay)

  return {
    before: earliestCheckInDate,
    after: lengthOfStayBoundary
  }
}

const getInitialMonth = (
  checkIn: MiddayDate | undefined,
  variant: DatePickerVariant
) => {
  const todayDate = dateToMiddayDate(new Date())
  if (!checkIn) {
    return todayDate
  }

  if (variant === 'vertical') {
    return checkIn < todayDate ? checkIn : todayDate
  }

  return checkIn
}

const DatePickerComponent = ({
  containerRef,
  checkIn,
  checkOut,
  openedDatePickerType = DatePickerType.CheckIn,
  weekdaysShort,
  months,
  firstDayOfWeek,
  numberOfMonthsToShow = 14,
  maxMonthsCount = 12,
  onMount,
  onChange,
  onDatePickerOpen,
  onDayClick,
  onDOMChange,
  onCheckOutSelected,
  maxLengthOfStay = MAX_DATE_DIFFERENCE,
  variant = 'horizontal',
  earliestCheckInDate = dateToMiddayDate(new Date()),
  virtualize = false
}: Props) => {
  const checkInMonthRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    onMount?.()
  }, [onMount])

  useEffect(() => {
    const containerElement = containerRef?.current
    const monthElement = checkInMonthRef?.current
    if (!containerElement || !monthElement) return

    if (onDOMChange) {
      setTimeout(() =>
        containerElement.addEventListener('DOMSubtreeModified', onDOMChange)
      )
    }

    window.setTimeout(() => {
      const offsetPx = 12
      scrollContainerToElement(containerElement, monthElement, 0, offsetPx)
    }, 0)

    return () => {
      if (onDOMChange) {
        containerElement.removeEventListener('DOMSubtreeModified', onDOMChange)
      }
    }
  }, [containerRef, onDOMChange])

  // NOTE: Check `core/src/datePicker/business.ts` for a shared version of this logic
  const handleCheckInClick = (date: MiddayDate, modifiers: DayModifiers) => {
    if (modifiers.disabled) return

    const checkInDate = dateFormat(date, UTS_DATE_FORMAT)

    if (onDayClick) onDayClick(DatePickerType.CheckIn, checkInDate)

    onChange(checkInDate, '')

    onDatePickerOpen(DatePickerType.CheckOut)
  }

  const getFormattedDate = (date: Date) =>
    date
      .toLocaleDateString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: '2-digit'
      })
      .replaceAll(',', '')

  // NOTE: Check `core/src/datePicker/business.ts` for a shared version of this logic
  const handleCheckOutClick = (
    date: MiddayDate,
    modifiers: DayModifiers
  ): void => {
    if (modifiers.disabled) return

    const checkInDate = checkIn || dateFormat(subDays(date, 1), UTS_DATE_FORMAT)

    const checkOutDate = dateFormat(date, UTS_DATE_FORMAT)

    if (onDayClick) onDayClick(DatePickerType.CheckOut, checkOutDate)

    onChange(checkInDate, checkOutDate)

    onDatePickerOpen(DatePickerType.CheckIn)

    if (onCheckOutSelected) onCheckOutSelected()
  }

  // NOTE: Check `core/src/datePicker/business.ts` for a shared version of this logic
  const handleDayClick = (date: MiddayDate, modifiers: DayModifiers) => {
    if (openedDatePickerType === 'checkIn') {
      handleCheckInClick(date, modifiers)
    } else if (isBefore(date, parseIso(checkIn as string))) {
      handleCheckInClick(date, modifiers)
    } else if (isSameDay(date, parseIso(checkIn as string))) {
      handleCheckInClick(date, modifiers)
    } else {
      handleCheckOutClick(date, modifiers)
    }
  }

  const checkInDate = checkIn ? dateStringToMiddayDate(checkIn) : undefined
  const checkOutDate = checkOut ? dateStringToMiddayDate(checkOut) : undefined
  const maxCheckInDate = subDays(
    addMonths(earliestCheckInDate, maxMonthsCount),
    1
  )

  const initialMonth = getInitialMonth(checkInDate, variant)
  const fromMonth =
    variant === 'horizontal' ? earliestCheckInDate : initialMonth

  const toMonth =
    checkOutDate && checkOutDate > maxCheckInDate
      ? checkOutDate
      : maxCheckInDate

  // FIXME: Days Of Week should be configurable based on Locale
  const modifiers: Record<string, Matcher | Matcher[]> = {
    startEnd: (day: Date) =>
      isSameDay(day, checkInDate as Date) ||
      isSameDay(day, checkOutDate as Date),
    start: (day: Date) => isSameDay(day, checkInDate as Date),
    end: (day: Date) => isSameDay(day, checkOutDate as Date),
    weekends: {dayOfWeek: [0, 6]}
  }

  const checkOutDisabledDays = determineCheckOutDisabledDays({
    checkInDate,
    earliestCheckInDate,
    maxLengthOfStay
  })

  const disabledDays = {
    checkIn: {
      before: earliestCheckInDate
    },
    checkOut: checkOutDisabledDays
  }

  const CaptionLabel = useCallback(
    ({displayMonth}: CaptionLabelProps) => {
      const isCheckInMonth = checkInDate
        ? isSameMonth(displayMonth, checkInDate)
        : false
      return (
        <MonthName
          datePickerVariant={variant}
          months={months}
          isCheckInMonth={isCheckInMonth}
          checkInMonthRef={checkInMonthRef}
          date={displayMonth}
        />
      )
    },
    [checkInDate, months, variant]
  )

  const DayContent = useCallback(({date}: DayContentProps) => {
    return (
      <div className="day-inner" aria-label={getFormattedDate(date)}>
        {date.getDate()}
      </div>
    )
  }, [])

  const formatWeekdayName: DateFormatter = useCallback(
    (date: Date) => {
      return (
        <WeekDay variant={variant} title={weekdaysShort[date.getDay()]}>
          {weekdaysShort[date.getDay()]}
        </WeekDay>
      )
    },
    [weekdaysShort, variant]
  )

  const shouldVirtualizeList =
    variant === 'vertical' && numberOfMonthsToShow > 10 && virtualize

  const MonthsComponent = useCallback<React.FC>(
    ({children}) => {
      if (!shouldVirtualizeList) {
        return <Months>{children}</Months>
      }

      return (
        <VirtualizedMonths
          checkInDate={checkInDate}
          initialMonth={initialMonth}
        >
          {children}
        </VirtualizedMonths>
      )
    },
    [shouldVirtualizeList]
  )
  return (
    <DatePickerElement
      data-id="DatePicker"
      ref={containerRef}
      variant={variant}
      singleDaySelected={!!checkIn && !checkOut}
    >
      <DayPicker
        classNames={{
          root: 'wrapper',
          day: 'day-cell',
          months: 'months-wrapper',
          month: 'month-wrapper',
          caption: 'caption-wrapper',
          caption_start: 'caption-start-wrapper',
          caption_label: 'caption-label-wrapper',
          caption_end: 'caption-end-wrapper',
          nav_button: 'nav-button',
          nav_button_next: 'nav-button-next',
          nav_button_previous: 'nav-button-previous',
          head_cell: 'week-day-wrapper',
          table: 'calendar-wrapper',
          head: 'calendar-week-days-wrapper',
          day_selected: 'day-selected',
          day_disabled: 'day-disabled'
        }}
        locale={enUS}
        modifiers={modifiers}
        selected={[{from: checkInDate, to: checkOutDate}]}
        disabled={
          openedDatePickerType ? disabledDays[openedDatePickerType] : undefined
        }
        defaultMonth={initialMonth} // Superfluous but required for test mocking
        fromMonth={fromMonth}
        toMonth={toMonth}
        numberOfMonths={numberOfMonthsToShow}
        onDayClick={handleDayClick}
        formatters={{
          formatWeekdayName
        }}
        disableNavigation={variant !== 'horizontal'}
        weekStartsOn={firstDayOfWeek as DayOfWeekType}
        components={{
          DayContent,
          CaptionLabel,
          IconRight: () => <Icon name="ChevronRight" />,
          IconLeft: () => <Icon name="ChevronLeft" />,
          Months: MonthsComponent
        }}
        modifiersClassNames={{
          selected: 'selected',
          today: 'today',
          weekends: 'weekends',
          startEnd: 'startEnd',
          start: 'start',
          disabled: 'disabled',
          outside: 'outside',
          week: 'week',
          end: 'end'
        }}
      />
    </DatePickerElement>
  )
}

export const BaseDatePicker = React.memo(DatePickerComponent)
