import {getFullStorySessionUrl} from 'services/fullstory'
import {urlParams} from 'utils/urlParams'

import {getWindowSize} from '@daedalus/core/src/_web/utils/browser/getWindowSize'
import {UserParametersContext} from '@daedalus/core/src/analytics/types/Events'
import {isReactNativeWebView} from '@daedalus/core/src/native'

const getUserParametersContext = (): UserParametersContext => {
  const {deviceType, userCountry} = urlParams()
  const {width: viewPortWidth, height: viewPortHeight} = getWindowSize()
  const fullStorySessionUrl = getFullStorySessionUrl()
  return {
    deviceType,
    userCountry,
    viewPortWidth,
    viewPortHeight,
    fullStorySessionUrl,
    isReactNativeWebView: isReactNativeWebView()
  } as UserParametersContext
}

export default getUserParametersContext
