import React, {useCallback, useEffect} from 'react'
import {useDispatch} from 'react-redux'

import {useIsGDPRCountry} from '@daedalus/core/src/_web/utils/cookies/hooks/cookieComplianceGeolocation'
import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {isReactNativeWebView} from '@daedalus/core/src/native'

import {CookieConsentBannerContainer} from './CookieConsentBannerContainer'

export interface CookieConsentBannerProps {
  /**
   * Callback invoked when a user accepts the cookies, either in the
   * banner or the preference center
   */
  onAccept?: (acceptedByUser: boolean) => void
}

const shouldCookieBannerBeShown = (): boolean => {
  return window.Osano?.cm?.dialogOpen ?? false
}

export const CookieConsentBanner = ({onAccept}: CookieConsentBannerProps) => {
  const isGDPRCountry = useIsGDPRCountry()
  const dispatch = useDispatch()
  const dispatchTeamEvent = useDispatchTeamEvent()

  const handleCookieConsentScriptLoaded = useCallback(() => {
    dispatchTeamEvent(
      trackEvent({
        category: Category.System,
        entity: Entity.CookieBannerScript,
        action: Action.Loaded
      })
    )
  }, [dispatch])

  useEffect(() => {
    window.Osano?.cm?.addEventListener(
      'osano-cm-initialized',
      handleCookieConsentScriptLoaded
    )

    return () => {
      window.removeEventListener(
        'osano-cm-initialized',
        handleCookieConsentScriptLoaded
      )
    }
  }, [handleCookieConsentScriptLoaded])

  if (
    isReactNativeWebView() ||
    !isGDPRCountry ||
    !shouldCookieBannerBeShown()
  ) {
    return null
  }

  return <CookieConsentBannerContainer onAccept={onAccept} />
}
