import React from 'react'
import {useIntl} from 'react-intl'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {HeaderWithStore} from 'components/HeaderWithStore'
import {MetaType} from 'types/Meta'
import {ThemeType} from 'types/Theme'
import {urlParams} from 'utils/urlParams'

import {Button} from '@daedalus/atlas/Button'
import {MOBILE_HEADER_HEIGHT} from '@daedalus/atlas/CompactHeader'
import {ContentWrapper} from '@daedalus/atlas/helpers/ContentWrapper'
import {Overlay} from '@daedalus/atlas/Overlay'
import {Text} from '@daedalus/atlas/Text'
import {mq} from '@daedalus/atlas/utils/breakpoints'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {
  FormattedMessageWrapper,
  FormattedMessageWrapperProps
} from '@daedalus/core/src/localization/components/FormattedMessage'

interface ErrorPresenterPropsType {
  buttonAction?: () => void
  imageURL?: string
  messages?: {
    buttonLabel?: FormattedMessageWrapperProps
    firstLine?: FormattedMessageWrapperProps
    secondLine?: FormattedMessageWrapperProps
    title?: FormattedMessageWrapperProps
  }
  metaParams?: MetaType
  showWasNotChargedMessage?: boolean
  supportButton?: {
    label: FormattedMessageWrapperProps
    url: string
  }
}

const ImageElement = styled('div')<{src: string}>(
  ({src}) => css`
    width: 100%;
    max-width: 874px;
    height: 250px;
    margin: 0 auto;
    background-position: center;
    background-image: url(${src});
    background-size: cover;

    ${mq.desktopXs(css`
      height: 350px;
    `)}
  `
)

const Wrapper = styled('div')(
  ({theme}: {theme: ThemeType}) => css`
    height: calc(100% - ${MOBILE_HEADER_HEIGHT}px);
    padding-top: ${theme.layout.spacing.s400}px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `
)

const Title = styled('div')(
  ({theme}: {theme: ThemeType}) => css`
    margin-bottom: ${theme.layout.spacing.s500}px;
  `
)

const Message = styled('div')(
  ({theme}: {theme: ThemeType}) => css`
    color: ${theme.colors.content.neutral.c950};
    margin-top: ${theme.layout.spacing.s700}px;
  `
)

const ErrorTextElement = styled('div')(
  ({theme}: {theme: ThemeType}) => css`
    margin: ${theme.layout.spacing.s300}px auto;
    max-width: 328px;
  `
)

const Footer = styled('div')(
  ({theme}: {theme: ThemeType}) => css`
    text-align: center;
    max-width: 328px;
    margin: ${theme.layout.spacing.s600}px auto 0;
  `
)

const FooterItem = styled('div')(
  ({theme}: {theme: ThemeType}) => css`
    margin-bottom: ${theme.layout.spacing.s600}px;
  `
)

export const ErrorPresenter = ({
  messages,
  buttonAction,
  showWasNotChargedMessage,
  supportButton,
  imageURL
}: ErrorPresenterPropsType) => {
  const {currency} = urlParams()
  const {locale} = useIntl()
  const {brandShowSignIn} = useBrand()

  return (
    <Overlay isOpen>
      <HeaderWithStore
        disableLogoClick
        currentCurrency={currency}
        currentLanguage={locale}
        onBackButtonClick={buttonAction}
        hasUserWidget={brandShowSignIn}
        hideGemsMembership={!brandShowSignIn}
      />
      <Wrapper>
        <Message>
          <ContentWrapper padding="s500">
            {messages?.title && (
              <Title>
                <Text as="h2" variant="titleL" align="center">
                  <FormattedMessageWrapper {...messages.title} />
                </Text>
              </Title>
            )}

            {showWasNotChargedMessage && (
              <ErrorTextElement>
                <Text align="center">
                  <FormattedMessageWrapper
                    id="noChargingMessage"
                    defaultMessage="Nothing was charged on your credit card."
                  />
                </Text>
              </ErrorTextElement>
            )}

            {messages?.firstLine && (
              <ErrorTextElement>
                <Text align="center">
                  <FormattedMessageWrapper {...messages.firstLine} />
                </Text>
              </ErrorTextElement>
            )}

            {messages?.secondLine && (
              <ErrorTextElement>
                <Text align="center">
                  <FormattedMessageWrapper {...messages.secondLine} />
                </Text>
              </ErrorTextElement>
            )}

            <Footer>
              {buttonAction && messages?.buttonLabel && (
                <FooterItem>
                  <Button
                    fullWidth
                    dataId="ButtonAction"
                    size="lg"
                    onClick={buttonAction}
                  >
                    <FormattedMessageWrapper {...messages.buttonLabel} />
                  </Button>
                </FooterItem>
              )}

              {supportButton && (
                <FooterItem>
                  <Text variant="bodyS" align="center">
                    <Button
                      fullWidth
                      dataId="SupportButton"
                      size="lg"
                      variant="secondary"
                      onClick={() => window.open(supportButton.url)}
                    >
                      <FormattedMessageWrapper {...supportButton.label} />
                    </Button>
                  </Text>
                </FooterItem>
              )}
            </Footer>
          </ContentWrapper>
        </Message>

        {imageURL && <ImageElement src={imageURL} />}
      </Wrapper>
    </Overlay>
  )
}

export default ErrorPresenter
