import {CookieSecurityOptions} from 'types/Cookie'

import {getDomainName} from '../../utils/url'

const getHostName = () => {
  try {
    const url = new URL(location.origin)
    return url.hostname
  } catch (e) {
    return 'www.vio.com'
  }
}

const cookieSecuritySettings = (isShared = true): CookieSecurityOptions => {
  const isBuild = process.env.NODE_ENV === 'production' // Any build except local development - available through webpack optimization.nodeEnv
  const isSecure = isBuild && isShared

  return {
    domain: getDomainName(getHostName()),
    sameSite: isSecure ? 'none' : 'lax',
    secure: isSecure
  }
}

const maxAge = 365 * 2 // 2 years
const name = 'v3_anonymousId'

const config = {
  name,
  options: {
    maxAge,
    ...cookieSecuritySettings(true)
  }
}

export default config
