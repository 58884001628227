import {toggle} from 'opticks'

import {Booking} from '../api-types/bovio/response/booking'
import {brands} from '../brand/config'
import {BrandCode} from '../brand/types'
import {Offer} from '../offer/types/offer'
import {OfferCheckAPIResponseWithAdaptedRoom} from '../offer/types/OfferCheck'

const PROD_ENVIRONMENTS = ['prd', 'production']
export const getBovioApiKey = ({
  brandCode,
  appEnv
}: {
  brandCode: BrandCode
  appEnv: string
}): string => {
  const BOFH_PROFILE_CL_EXPERIMENT_STAGING =
    'VXBkYXRlIENMIGVsaWdpYmlsaXR5IHJ1bGVz_test'
  const BOFH_PROFILE_CL_EXPERIMENT_PROD = 'VXBkYXRlIENMIGVsaWdpYmlsaXR5IHJ1bGVz'

  if (toggle('169ce369-cl-eligibility', false, true))
    return PROD_ENVIRONMENTS.includes(appEnv)
      ? BOFH_PROFILE_CL_EXPERIMENT_PROD
      : BOFH_PROFILE_CL_EXPERIMENT_STAGING

  if (PROD_ENVIRONMENTS.includes(appEnv)) {
    return (
      brands[brandCode].bovioProdApiKey ||
      (brands.vio.bovioProdApiKey as string)
    )
  } else {
    return (
      brands[brandCode].bovioStagingApiKey ||
      (brands.vio.bovioStagingApiKey as string)
    )
  }
}

// A type-guard function that checks if the given argument is valid booking data
export const isExistingBooking = (
  bookingData?: OfferCheckAPIResponseWithAdaptedRoom | Offer | Booking
): bookingData is Booking => {
  return !!(bookingData as Booking)?.confirmationId
}
