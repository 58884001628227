// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgStreet = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      d="M14.293 2.012c.414-.073.81.188.887.583l2.807 14.546c.077.395-.197.774-.61.847-.413.073-.81-.189-.887-.584L13.683 2.86c-.076-.395.197-.774.61-.847ZM5.423 2.01c.415.066.695.44.626.837L3.512 17.392c-.07.396-.461.664-.876.598-.415-.066-.695-.44-.626-.837L4.547 2.608c.07-.396.462-.664.876-.598ZM10.626 3.155c0-.401-.341-.727-.761-.727s-.761.326-.761.727v1.141c0 .402.34.727.76.727.421 0 .762-.325.762-.727v-1.14ZM10.626 6.578c0-.402-.341-.728-.761-.728s-.761.326-.761.728v2.281c0 .402.34.727.76.727.421 0 .762-.325.762-.727V6.578ZM10.626 11.14c0-.4-.341-.726-.761-.726s-.761.325-.761.727v2.281c0 .402.34.728.76.728.421 0 .762-.326.762-.728v-2.281ZM10.626 15.704c0-.402-.341-.727-.761-.727s-.761.325-.761.727v1.14c0 .402.34.728.76.728.421 0 .762-.325.762-.727v-1.141Z"
      fill="#6C6770"
    />
  </svg>
)

export default SvgStreet
