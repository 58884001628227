import React, {useContext} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {Button} from '@daedalus/atlas/src/components/designSystem/Button'
import {Text} from '@daedalus/atlas/src/components/designSystem/Text'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {getUser} from '@daedalus/core/src/auth/modules/selectors'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {DeleteAccountContext} from '../DeleteAccountProvider'

export const DeleteUserProfile = () => {
  const dispatch = useDispatch()
  const {openModal} = useContext(DeleteAccountContext)
  const user = useSelector(getUser)
  const userId = user?.attributes?.sub

  const onUserDeleteClick = () => {
    openModal()
    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.DeleteAccountOverlay,
        action: Action.Displayed,
        payload: {
          userId
        },
        team: Team.Retention
      })
    )
  }

  return (
    <Button variant="quiet" onClick={onUserDeleteClick}>
      <Text variant="labelM" colorPath="content.danger.c600">
        <FormattedMessageWrapper
          id="membership.deleteMyAccount"
          defaultMessage="Delete my account"
        />
      </Text>
    </Button>
  )
}
