import {createAsyncThunk} from '@reduxjs/toolkit'

import {fetchOptimizelyDataFile as fetchOptimizelyDataFileService} from '../services/fetchOptimizelyDataFile'

/**
 * Async thunk that fires the service that loads the experiment datafile
 */
export interface FetchOptimizelyDataFileAction {
  /** The URL for the remote source of the datafile */
  dataFileUrl: string
}

/**
 * Async thunk that fires the service that loads the experiment datafile
 */
export const fetchOptimizelyDataFile = createAsyncThunk(
  'experiments/fetchDataFile',
  async ({dataFileUrl}: FetchOptimizelyDataFileAction) => {
    return fetchOptimizelyDataFileService({dataFileUrl})
  }
)
