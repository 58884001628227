import {Room, RoomsSplitBooking} from '@findhotel/sapi'

import {RoomsResponseType} from '../../../../types/Rooms'
import {RoomsInfoType} from '../slice'

const roomsEndpointResponseToRoomsState = (
  response: RoomsResponseType
): {
  roomsInfo: RoomsInfoType
  roomsList: Room[]
  splitBooking?: RoomsSplitBooking
} => {
  const {checkInInstructions, hotelId, searchId, rooms, splitBooking} = response

  return {
    roomsInfo: {
      checkInInstructions,
      hotelId,
      searchId
    },
    roomsList: rooms,
    splitBooking
  }
}

export default roomsEndpointResponseToRoomsState
