import {CookieSecurityOptions} from 'types/Cookie'

// TODO (Core): Remove the side effect process.env.NODE_ENV of the function
export const cookieSecuritySettings = (
  isShared = true
): CookieSecurityOptions => {
  const isBuild = process.env.NODE_ENV === 'production' // Any build except local development - available through webpack optimization.nodeEnv
  const isSecure = isBuild && isShared

  return {
    sameSite: isSecure ? 'none' : 'lax',
    secure: isSecure
  }
}
