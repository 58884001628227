import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import noop from 'lodash/noop'

const Flex = styled.ul(
  ({theme}) => css`
    display: flex;
    margin: 0;
    padding: 0;
    width: 100%;
    gap: ${theme.layout.spacing.s600}px;
  `
)

export const TabsContext = React.createContext<Props>({
  value: '',
  onChange: noop
})

interface Props {
  value?: string
  onChange: (value: string) => void
}

export const Tabs: React.FC<Props> = ({value, onChange, children}) => {
  return (
    <TabsContext.Provider value={{value, onChange}}>
      <Flex>{children}</Flex>
    </TabsContext.Provider>
  )
}
