export const COUNTRY_LIST_FETCH = 'COUNTRY_LIST_FETCH'
export const COUNTRY_LIST_FETCH_PENDING = 'COUNTRY_LIST_FETCH_PENDING'
export const COUNTRY_LIST_FETCH_ERROR = 'COUNTRY_LIST_FETCH_ERROR'
export const COUNTRY_LIST_FETCH_DONE = 'COUNTRY_LIST_FETCH_DONE'

export type FetchCountryListType = {
  language: string
  type: typeof COUNTRY_LIST_FETCH
}

export type ActionType = FetchCountryListType | CountryListDoneType

export const countryListPending = () => ({
  type: COUNTRY_LIST_FETCH_PENDING
})

export interface Data {
  [countryCode: string]: {
    name: string
    code: string
    dialCode: string
  }
}

interface CountryListDoneType {
  type: typeof COUNTRY_LIST_FETCH_DONE
  data: Data
}

export const countryListDone = (data: Data): CountryListDoneType => ({
  type: COUNTRY_LIST_FETCH_DONE,
  data
})

export const countryListError = (error: Error) => ({
  type: COUNTRY_LIST_FETCH_ERROR,
  error
})

const fetchCountryList = (language: string): FetchCountryListType => ({
  type: COUNTRY_LIST_FETCH,
  language
})

export default fetchCountryList
