// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgSparkles = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 2a.75.75 0 0 1 .75.75c0 1.257.34 2.402.942 3.214.587.791 1.433 1.286 2.558 1.286a.75.75 0 0 1 0 1.5c-1.125 0-1.971.495-2.558 1.286-.602.812-.942 1.957-.942 3.214a.75.75 0 0 1-1.5 0c0-1.257-.34-2.402-.942-3.214-.587-.791-1.432-1.286-2.558-1.286a.75.75 0 0 1 0-1.5c1.126 0 1.971-.495 2.558-1.286.602-.812.942-1.957.942-3.214A.75.75 0 0 1 13 2Zm0 4.07a5.6 5.6 0 0 1-.487.787A4.776 4.776 0 0 1 11.32 8c.46.306.86.694 1.193 1.143A5.6 5.6 0 0 1 13 9.93a5.6 5.6 0 0 1 .487-.788A4.776 4.776 0 0 1 14.68 8a4.776 4.776 0 0 1-1.193-1.143A5.6 5.6 0 0 1 13 6.07ZM6 10a.75.75 0 0 1 .75.75c0 .591.29 1.222.784 1.716.494.494 1.125.784 1.716.784a.75.75 0 0 1 0 1.5c-.591 0-1.222.29-1.716.784-.494.494-.784 1.125-.784 1.716a.75.75 0 0 1-1.5 0c0-.591-.29-1.222-.784-1.716-.494-.494-1.125-.784-1.716-.784a.75.75 0 0 1 0-1.5c.591 0 1.222-.29 1.716-.784.494-.494.784-1.125.784-1.716A.75.75 0 0 1 6 10Zm0 2.969A4.47 4.47 0 0 1 4.969 14 4.464 4.464 0 0 1 6 15.031 4.462 4.462 0 0 1 7.031 14 4.472 4.472 0 0 1 6 12.969Z"
    />
  </svg>
)

export default SvgSparkles
