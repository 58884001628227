// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCaratDown = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="M3.122 7.976C2.288 7.003 2.98 5.5 4.262 5.5h11.477c1.281 0 1.973 1.503 1.139 2.476l-5.74 6.695a1.5 1.5 0 0 1-2.277 0L3.122 7.976Z" />
  </svg>
)

export default SvgCaratDown
