// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgWriteNote = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.75 3.5A2.25 2.25 0 0 0 3.5 5.75v8.5a2.25 2.25 0 0 0 2.25 2.25h6.5a2.25 2.25 0 0 0 2.25-2.25v-.5a.75.75 0 0 1 1.5 0v.5A3.75 3.75 0 0 1 12.25 18h-6.5A3.75 3.75 0 0 1 2 14.25v-8.5A3.75 3.75 0 0 1 5.75 2h2.5a.75.75 0 0 1 0 1.5h-2.5Zm7.992-.77a2.494 2.494 0 1 1 3.527 3.528l-3.283 3.283a2.88 2.88 0 0 1-1.337.757l-2.717.68a.75.75 0 0 1-.91-.91l.68-2.717c.126-.506.388-.968.757-1.337l3.283-3.283Zm2.467 1.061a.994.994 0 0 0-1.406 0l-3.284 3.283a1.38 1.38 0 0 0-.362.641l-.376 1.504 1.504-.376c.242-.06.464-.186.64-.363l3.284-3.283a.994.994 0 0 0 0-1.406ZM5.75 12.5a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5ZM5 10.25a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1-.75-.75Zm.75-3.75a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"
    />
  </svg>
)

export default SvgWriteNote
