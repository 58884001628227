import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {BookingError} from 'hooks/useBookingError'
import {ThemeType} from 'types/Theme'

import {Text} from '@daedalus/atlas/Text'
import {ErrorCategory} from '@daedalus/core/src/booking/types/ErrorCategory'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {OverlayErrorMessage} from '../OverlayErrorMessage'

const Title = styled('div')(
  ({theme}: {theme: ThemeType}) => css`
    ${theme.typography.text.titleXL};
    margin-bottom: ${theme.layout.spacing.s600}px;
  `
)

export const getIsDuplicateBooking = (error?: BookingError) => {
  return error?.data?.details?.category === ErrorCategory.DuplicateBooking
}

export const DuplicateBooking = React.memo(() => {
  return (
    <OverlayErrorMessage>
      <Title>
        <FormattedMessageWrapper
          id="errors.duplicateBookingTitle"
          defaultMessage="Duplicate booking"
        />
      </Title>
      <Text>
        <FormattedMessageWrapper
          id="errors.duplicateBookingDescriptionV3"
          defaultMessage="You made an identical booking a few seconds ago.<br></br><br></br>You can't make duplicate bookings. Check your inbox or spam for confirmation of the first booking."
          values={{br: () => <br />}}
        />
      </Text>
    </OverlayErrorMessage>
  )
})

DuplicateBooking.displayName = 'DuplicateBooking'
