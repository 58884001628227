// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCustomTrendingDown = ({
  color = 'currentColor',
  size = 20
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="M14.167 15a.804.804 0 0 1-.593-.24.804.804 0 0 1-.24-.593c0-.236.08-.434.24-.594.16-.16.357-.24.593-.24H15.5l-4.333-4.291-2.75 2.75a.864.864 0 0 1-.583.23.784.784 0 0 1-.313-.064 1.095 1.095 0 0 1-.27-.166L2.228 6.729A.765.765 0 0 1 2 6.167a.8.8 0 0 1 .25-.584.79.79 0 0 1 .584-.229.79.79 0 0 1 .583.23L7.834 10l2.75-2.75a.736.736 0 0 1 .27-.177.93.93 0 0 1 .313-.052.93.93 0 0 1 .313.052.736.736 0 0 1 .27.177l4.917 4.917v-1.334c0-.236.08-.434.24-.594.16-.16.357-.239.593-.239s.434.08.594.24c.16.16.24.357.24.593v3.334c0 .236-.08.434-.24.593a.804.804 0 0 1-.594.24h-3.333Z" />
  </svg>
)

export default SvgCustomTrendingDown
