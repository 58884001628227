import {AxiosErrorResponse} from 'modules/book/actions/bookOfferFinalization'
import {AnyAction} from 'redux'
import {
  CommonErrorPayloadFormatType,
  RejectResponseType
} from 'types/errorResponse'

import {Type as BoVioErrorType} from '@daedalus/core/src/api-types/bovio/response/error'
import {ErrorResponse} from '@daedalus/core/src/booking/types/ErrorResponse'
import {ErrorTypes} from '@daedalus/core/src/booking/types/ErrorTypes'
import {SearchApiError} from '@daedalus/core/src/sapi/services/searchApi'
export const ERROR = 'ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'

export type ErrorActionType = {
  error: CommonErrorPayloadFormatType
  type: typeof ERROR | typeof CLEAR_ERROR
}

export type ActionType = ErrorActionType

export const error = (error: CommonErrorPayloadFormatType) => ({
  type: ERROR,
  error
})

export const clearError = () => ({
  type: CLEAR_ERROR
})

export const toCommonErrorPayloadFormat = (
  action: {type: string},
  error: RejectResponseType | AxiosErrorResponse
): CommonErrorPayloadFormatType => ({
  originalErrorAction: action?.type,
  errorResponse: error?.data,
  statusCode: error?.status,
  value: {
    statusCode: error?.status,
    params: error?.config?.params,
    headers: error?.config?.headers,
    url: error?.config?.url || '',
    method: error?.config?.method || '',
    errorResponse: error?.data
  }
})

const formatRtqErrorResponse = (
  data: ErrorResponse | ErrorTypes | BoVioErrorType
): ErrorResponse => {
  if (typeof data !== 'string') {
    return data
  }

  return {
    message: data,
    details: {},
    type: data
  }
}
const formatSapiRtqErrorResponse = (
  data: SearchApiError | undefined
): ErrorResponse => ({
  message: 'sapi failed to load',
  details: {recoverable: true},
  type: data
})

export const rtqToCommonErrorPayloadFormat = (
  action: AnyAction
): CommonErrorPayloadFormatType => ({
  originalErrorAction: action?.type,
  errorResponse: formatRtqErrorResponse(action?.payload?.data),
  statusCode: action?.payload?.status,
  value: {
    ...action?.meta?.arg?.originalArgs,
    statusCode: action?.payload?.status,
    errorResponse: action?.payload?.data
  }
})

export const rtqResponseWithErrorDataToRejectResponse = (
  error?: ErrorResponse
): RejectResponseType => ({
  status: error?.details?.reason?.statusCode || '',
  data: {
    type: error?.type,
    details: error?.details || {},
    message: error?.message
  },
  config: {},
  headers: {},
  request: {},
  statusText: error?.message || ''
})

export const sapiRtkQueryErrorToCommonErrorPayloadFormat = (
  action: AnyAction
): CommonErrorPayloadFormatType => ({
  originalErrorAction: action?.type,
  errorResponse: formatSapiRtqErrorResponse(action?.payload),
  statusCode: action?.payload?.status,
  value: {
    ...action?.meta?.arg?.originalArgs,
    statusCode: action?.payload?.status,
    errorResponse: action?.payload?.data
  }
})
