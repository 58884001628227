import React, {useContext} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {logEvent} from '@daedalus/core/src/analytics/modules/middleware'
import {
  Action,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {getUser} from '@daedalus/core/src/auth/modules/selectors'
import {deleteUserService} from '@daedalus/core/src/auth/services'
import {Brand} from '@daedalus/core/src/brand/types'

import {DeleteAccountContext} from '../DeleteAccountProvider'
import {Modal} from './Modal'

interface DeleteAccountModalPropTypes {
  brand: Brand
}

export const DeleteAccountModal = ({brand}: DeleteAccountModalPropTypes) => {
  const {isOpen, closeModal} = useContext(DeleteAccountContext)
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const userId = user?.id

  const hideAccountDeleteModal = () => {
    closeModal()
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.DeleteAccountCancel,
        action: Action.Clicked,
        payload: {
          userId
        },
        team: Team.Retention
      })
    )
  }

  const onUserApproveDelete = async () => {
    try {
      await deleteUserService()
      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.DeleteAccountConfirm,
          action: Action.Clicked,
          payload: {
            userId
          },
          team: Team.Retention
        })
      )

      // eslint-disable-next-line fp/no-mutation
      window.location.href = brand.address
    } catch (error) {
      logEvent('Membership.DeleteUserError', {
        errorType: (error as Error)?.message
      })
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onDeleteClick={onUserApproveDelete}
      onCancelClick={hideAccountDeleteModal}
    />
  )
}
