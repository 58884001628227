// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgHeadphones = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 10.25a8 8 0 1 1 16 0v4.3A3.45 3.45 0 0 1 14.55 18 2.55 2.55 0 0 1 12 15.45v-2.9A2.55 2.55 0 0 1 14.55 10c.724 0 1.395.223 1.95.604v-.354a6.5 6.5 0 1 0-13 0v.354A3.434 3.434 0 0 1 5.45 10 2.55 2.55 0 0 1 8 12.55v2.9A2.55 2.55 0 0 1 5.45 18 3.45 3.45 0 0 1 2 14.55V10.25ZM3.5 14v-.55a1.95 1.95 0 0 1 1.95-1.95c.58 0 1.05.47 1.05 1.05v2.9c0 .58-.47 1.05-1.05 1.05a1.95 1.95 0 0 1-1.95-1.95V14Zm10-1.45c0-.58.47-1.05 1.05-1.05a1.95 1.95 0 0 1 1.95 1.95v1.1a1.95 1.95 0 0 1-1.95 1.95c-.58 0-1.05-.47-1.05-1.05v-2.9Z"
    />
  </svg>
)

export default SvgHeadphones
