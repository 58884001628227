// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgKitchen = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="M13.415 4.006c-.272 0-.533.106-.725.296a1.006 1.006 0 0 0 0 1.432 1.032 1.032 0 0 0 1.45 0 1.006 1.006 0 0 0 0-1.432 1.032 1.032 0 0 0-.725-.296ZM13.415 11.64c.272 0 .533-.106.725-.296a1.006 1.006 0 0 0 0-1.432 1.032 1.032 0 0 0-1.45 0 1.007 1.007 0 0 0 0 1.432c.192.19.453.297.725.297Z" />
    <path d="M17.19 2h-6.072c-.204 0-.4.08-.544.223a.755.755 0 0 0-.225.537v5.732H5.846V4.836c0-.113.045-.221.126-.3.081-.08.19-.125.305-.125a.436.436 0 0 1 .407.26.421.421 0 0 1 .034.165v.81c0 .201.08.395.225.537.144.143.34.222.544.222a.768.768 0 0 0 .547-.219.749.749 0 0 0 .222-.54v-.81a1.923 1.923 0 0 0-.58-1.378 1.971 1.971 0 0 0-1.4-.566c-.521 0-1.022.205-1.392.57-.369.364-.576.858-.576 1.374v2.025a1.034 1.034 0 0 0-.564-.182c-.272 0-.533.107-.726.296-.192.19-.3.448-.3.717a1.003 1.003 0 0 0 .482.83h-.43a.784.784 0 0 0-.542.225.765.765 0 0 0-.228.534v7.96c.003.2.085.392.228.534A.785.785 0 0 0 2.77 18H17.23a.785.785 0 0 0 .541-.225.765.765 0 0 0 .229-.534V2.79a.761.761 0 0 0-.232-.569.78.78 0 0 0-.579-.221Zm-5.303 1.52h4.533v3.037h-4.533V3.52Zm-1.538 7.635H3.497V10.01h6.852v1.145Zm-6.852 1.519h6.852v3.746H3.497v-3.746ZM16.42 16.45h-4.533V8.026h4.533v8.425Z" />
  </svg>
)

export default SvgKitchen
