import React from 'react'
import styled from '@emotion/styled'
import {toggle} from 'opticks'

import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import {Brand} from '@daedalus/core/src/brand/types'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {ArrowRow} from '../ArrowButton'
import {IconWrapper, Label, Link, RowWrapper} from '../commonStyles'

interface PropsType {
  brand: Brand
  onShowReferralOverlay: () => void
}

const RowText = styled(FormattedMessageWrapper)`
  display: inline;
`

export const InviteFriendsToGems = ({onShowReferralOverlay}: PropsType) => (
  <Link onClick={onShowReferralOverlay}>
    <RowWrapper>
      <ArrowRow>
        <Label>
          <IconWrapper>
            <Icon name="SpeechBubblePlus" />
          </IconWrapper>
          <Text variant="labelM">
            <RowText
              id="membership.InviteFriendsToGems"
              defaultMessage="Invite Friends to {gems}"
              values={{
                gems: toggle(
                  'c9f92dea-new-tags-poc',
                  <Icon className="inline-icon" size="lg" name="GemsLogo" />,
                  'Vio.com'
                )
              }}
            />
          </Text>
        </Label>
      </ArrowRow>
    </RowWrapper>
  </Link>
)
