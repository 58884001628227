import React from 'react'
import {styled} from '@linaria/react'
import {SpacingPropsType} from 'types/Layout'

import {cssTheme} from '../../../themes'

interface Props {
  /** Pass through classname to allow styles overrides */
  className?: string
  /** The size of the left and right margins */
  sideMargin?: SpacingPropsType
}

const DividerWrapper = styled.div<{sideMargin: SpacingPropsType}>`
  width: 100%;
  background: ${cssTheme.colors.transparent};
  padding: 0 ${({sideMargin}) => cssTheme.layout.spacing[sideMargin]};
`

export const DividerElement = styled.div`
  height: 1px;
  border-bottom: 1px solid;
  border-color: ${cssTheme.colors.border.neutral.c100};
`

export const Divider = ({sideMargin = 's000', className}: Props) => (
  <DividerWrapper sideMargin={sideMargin} className={className}>
    <DividerElement />
  </DividerWrapper>
)
