import {pickAll} from 'ramda'
import {ProviderRequestType} from 'types/providerInfo'

import {UrlCheckoutParamsType} from '@daedalus/core/src/utils/url/types/UrlParams'

export const getDefaultParams = (params: UrlCheckoutParamsType) =>
  pickAll<UrlCheckoutParamsType, ProviderRequestType>(
    ['providerCode', 'countryCode'],
    params
  )

export default getDefaultParams
