import * as React from 'react'
const SvgComponent = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    fill="none"
    {...props}
  >
    <mask
      id="a"
      width={64}
      height={64}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha'
      }}
    >
      <circle cx={32} cy={32} r={32} fill="#D9D9D9" />
    </mask>
    <g mask="url(#a)">
      <circle cx={32} cy={32} r={32} fill="#EAE4FF" />
      <path
        fill="#365EDB"
        d="M6.816 42.593a1 1 0 0 1 .993-1.12h48.39a1 1 0 0 1 .992 1.125L53.956 68.19a1 1 0 0 1-.992.874H10.919a1 1 0 0 1-.993-.879l-3.11-25.592Z"
      />
      <path
        fill="#3C69F5"
        d="M11.084 45.917h42.255l-2.751 18.575H13.474l-2.39-18.575Z"
      />
      <path fill="#365EDB" d="M28.453 18.934h7.54V16h-7.54z" />
      <path
        fill="#26429B"
        d="M10.836 20.269a3 3 0 0 1 2.998-2.873h36.654a3 3 0 0 1 2.997 2.856l1.024 21.22H9.936l.9-21.203Z"
      />
      <path
        fill="#13214D"
        d="M14.567 21.33H50.05l.598 16.208h-36.87l.79-16.208Z"
      />
      <path
        fill="#1B2F6E"
        fillRule="evenodd"
        d="M13.769 37.526h.006c2.503.102 4.686-3.44 4.876-7.913.19-4.473-1.686-8.182-4.19-8.284h-.006l-.686 16.197ZM50.727 37.713h-.006c-2.503.103-4.686-3.44-4.876-7.913-.19-4.473 1.686-8.182 4.19-8.284h.006l.686 16.197Z"
        clipRule="evenodd"
      />
      <mask
        id="b"
        width={54}
        height={24}
        x={5}
        y={18}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha'
        }}
      >
        <path
          fill="#13214D"
          d="M5 20.02s52.943-2.505 53.279 0c1.148 8.563 0 21.452 0 21.452H5V20.02Z"
        />
      </mask>
      <g mask="url(#b)">
        <g clipPath="url(#c)">
          <path
            fill="#42AAFD"
            d="m45.466 32.866-5.782-9.93c-3.453 1.984-5.78 5.685-5.78 9.93h11.562Z"
          />
          <path
            fill="#6EBDFD"
            d="M51.246 22.936a11.588 11.588 0 0 0-5.78-1.536c-2.105 0-4.077.561-5.779 1.536l5.78 9.927 5.779-9.927Z"
          />
          <path
            fill="#1B2F6E"
            d="M51.245 42.793c3.456-1.983 5.78-5.685 5.78-9.927H45.463l5.78 9.927h.002Z"
          />
          <path
            fill="#365EDB"
            d="M33.904 32.866c0 4.242 2.327 7.947 5.78 9.927l5.779-9.927H33.904Z"
          />
          <path
            fill="#26429B"
            d="m51.246 22.936-5.78 9.927h11.562c0-4.242-2.326-7.947-5.779-9.927h-.003Z"
          />
          <path
            fill="#2E51BE"
            d="m45.467 32.866-5.78 9.927a11.589 11.589 0 0 0 5.78 1.536c2.106 0 4.078-.561 5.779-1.536l-5.78-9.927Z"
          />
          <path
            fill="#3C69F5"
            d="M48.539 27.582h-6.15l-3.073 5.284 3.074 5.281h6.149l3.076-5.28-3.076-5.285Z"
          />
        </g>
        <path
          fill="#FFF4E4"
          d="M22.515 24.161c.043-.174.289-.174.331 0l.263 1.072c.015.062.063.11.125.126l1.065.265c.172.043.172.29 0 .333l-1.065.265a.171.171 0 0 0-.125.126l-.263 1.072c-.042.174-.288.174-.33 0l-.263-1.072a.17.17 0 0 0-.125-.126l-1.065-.265c-.173-.043-.173-.29 0-.333l1.065-.265a.171.171 0 0 0 .125-.126l.262-1.072Z"
        />
        <g clipPath="url(#d)">
          <path
            fill="#FFD172"
            d="M29.26 24.534a2.765 2.765 0 0 0-2.132-.962l-18.176.122a2.804 2.804 0 0 0-1.604.512 3.005 3.005 0 0 0-.707.696l13.314 8.096 9.328-7.953.221-.187a2.935 2.935 0 0 0-.245-.324Z"
          />
          <path
            fill="#C69100"
            d="M29.665 25.142a3.898 3.898 0 0 0-.162-.284l-9.549 8.14-1.448 10.491-.25 1.851a2.845 2.845 0 0 0 2.205-1.444l9.128-16a2.897 2.897 0 0 0 .076-2.754Z"
          />
          <path
            fill="#FFC400"
            d="M6.643 24.902c-.628.877-.778 2.087-.174 3.146l4.823 8.465 1.477 2.59 2.748 4.822a2.8 2.8 0 0 0 2.742 1.412l1.698-12.339-13.314-8.096Z"
          />
          <path
            fill="#fff"
            d="M11.719 35.257c.042-.174.288-.174.33 0l.263 1.073c.016.062.064.11.125.125l1.065.265c.173.043.173.29 0 .334l-1.065.264a.171.171 0 0 0-.125.126l-.262 1.072c-.043.175-.289.175-.332 0l-.262-1.072a.171.171 0 0 0-.125-.126l-1.065-.264c-.172-.043-.172-.291 0-.334l1.065-.265a.171.171 0 0 0 .125-.125l.263-1.073Z"
          />
        </g>
        <g clipPath="url(#e)">
          <path
            fill="#E22252"
            d="M45.214 31.127a3.451 3.451 0 0 0-3.035-1.795l-5.385-.029-1.917 4.988 5.706 8.108 4.592-7.83c.67-1.141.602-2.43.039-3.445v.003ZM23.455 36.184l-6.01 10.248a2.987 2.987 0 0 0 .056 3.137c.129.204.28.394.454.562.53.518 1.255.852 2.096.858l9.853.053.641-11.632-7.093-3.223.003-.003Z"
          />
          <path
            fill="#F36"
            d="M30.497 29.27a5.163 5.163 0 0 0-4.484 2.55l-1.804 3.077-.754 1.287 7.06 3.208.033.015 4.326-5.092 1.923-5.012-6.3-.032Z"
          />
          <path
            fill="#C6284F"
            d="m34.88 34.294-4.333 5.113-.64 11.632 2.614.015a5.186 5.186 0 0 0 3.366-1.222c.438-.37.82-.816 1.118-1.327l3.58-6.106-5.706-8.108v.003Z"
          />
        </g>
      </g>
      <path
        fill="#fff"
        d="M30.57 25.058c.044-.18.298-.18.342 0l.271 1.108a.177.177 0 0 0 .13.13l1.1.273c.178.045.178.3 0 .345l-1.1.274a.177.177 0 0 0-.13.13l-.271 1.107c-.044.18-.298.18-.342 0l-.272-1.108a.177.177 0 0 0-.129-.13l-1.1-.273c-.178-.044-.178-.3 0-.345l1.1-.273a.177.177 0 0 0 .13-.13l.27-1.108Z"
      />
      <mask
        id="f"
        width={43}
        height={20}
        x={11}
        y={45}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha'
        }}
      >
        <path
          fill="#3C69F5"
          d="M11.082 45.917h42.255l-2.751 18.575H13.472l-2.39-18.575Z"
        />
      </mask>
      <g mask="url(#f)">
        <path fill="#1B2F6E" d="M9.934 43.099h44.573v4.796H9.934z" />
      </g>
      <ellipse cx={32.221} cy={46.689} fill="#1B2F6E" rx={3.721} ry={3.621} />
      <ellipse cx={32.223} cy={46.689} fill="#fff" rx={2.318} ry={2.256} />
      <ellipse cx={32.221} cy={46.229} fill="#13214D" rx={0.734} ry={0.714} />
      <rect
        width={0.669}
        height={1.43}
        x={31.879}
        y={46.521}
        fill="#13214D"
        rx={0.334}
      />
      <ellipse cx={32.221} cy={46.086} fill="#26429B" rx={3.721} ry={3.621} />
      <ellipse cx={32.223} cy={46.086} fill="#fff" rx={2.318} ry={2.256} />
      <ellipse cx={32.221} cy={45.625} fill="#13214D" rx={0.734} ry={0.714} />
      <rect
        width={0.669}
        height={1.43}
        x={31.879}
        y={45.917}
        fill="#13214D"
        rx={0.334}
      />
      <path
        fill="#2E51BE"
        fillRule="evenodd"
        d="M17.68 69.064h-6.762a1 1 0 0 1-.993-.879l-.679-5.586a7.342 7.342 0 0 1 1.226-.102c3.79 0 6.898 2.889 7.209 6.567ZM46.179 69.064h6.763a1 1 0 0 0 .992-.879l.68-5.586a7.343 7.343 0 0 0-1.227-.102c-3.79 0-6.898 2.889-7.208 6.567Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M39.736 39.567c.044-.18.298-.18.342 0l.271 1.108a.177.177 0 0 0 .13.13l1.1.273c.178.044.178.3 0 .344l-1.1.274a.177.177 0 0 0-.13.13l-.271 1.108c-.044.18-.298.18-.342 0l-.272-1.108a.177.177 0 0 0-.129-.13l-1.1-.273c-.178-.045-.178-.3 0-.345l1.1-.274a.177.177 0 0 0 .13-.13l.27-1.107Z"
      />
    </g>
    <defs>
      <clipPath id="c">
        <path fill="#fff" d="M33.904 21.4h23.12v22.93h-23.12z" />
      </clipPath>
      <clipPath id="d">
        <path fill="#fff" d="M6.086 23.572h23.892v21.783H6.086z" />
      </clipPath>
      <clipPath id="e">
        <path fill="#fff" d="M17.027 29.27h28.631v21.784h-28.63z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
