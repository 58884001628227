import {isReactNative} from './isReactNative'
import {isReactNativeWebView} from './isReactNativeWebView'

/**
 * Determine whether this code is running in the mobile app
 * This will return true in React Native AND Webview environments.
 *
 * Use `isReactNativeWebView()` to specifically target webviews
 */
export const isMobileApp = (): boolean => {
  return isReactNative || isReactNativeWebView()
}
