// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgResize = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.854 5.854a.5.5 0 0 0-.708-.708l-2 2-2 2-4 4-2 2-2 2a.5.5 0 0 0 .708.708l2-2 2-2 4-4 2-2 2-2Zm0 4a.5.5 0 0 0-.708-.708l-2 2-2 2v.001l-2 2-2 2a.5.5 0 0 0 .707.707l2-2 2-2 2-2 .001-.001 2-2Zm0 4a.5.5 0 0 0-.708-.708l-4 4a.5.5 0 0 0 .708.708l4-4Z"
    />
  </svg>
)

export default SvgResize
