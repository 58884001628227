import {useSelector} from 'react-redux'
import withURLParamsMapper from 'components/data/URLParams/withURLParamsMapper'
import {getAnonymousId} from 'modules/meta/selectors'
import Settings from 'Settings'

import {ignoreErrors, ignoreUrls} from './ignorePatterns'
import SentryMock from './SentryMock'

// Local type reassigning just for error handling section
declare global {
  interface Window {
    Sentry: typeof SentryMock
  }
}

const InitializeSentry = () => {
  const anonymousId = useSelector(getAnonymousId)
  const SENTRY_DSN = Settings.get('REACT_APP_SENTRY_DSN')

  try {
    if (typeof Sentry === 'object' && SENTRY_DSN) {
      const sentryOpts = {
        dsn: SENTRY_DSN,
        environment: Settings.get('REACT_APP_ENV'),
        release: Settings.get('REACT_APP_VERSION'),
        blacklistUrls: ignoreUrls,
        ignoreErrors
      }

      Sentry.init(sentryOpts)

      if (anonymousId) {
        Sentry.configureScope(scope => {
          scope.setUser({id: anonymousId})
        })
      }
    }
  } catch (error) {
    console.error('Error initializing Sentry.', error)
    // eslint-disable-next-line fp/no-mutation
    window.Sentry = SentryMock
  }

  return null
}

export default withURLParamsMapper(InitializeSentry)
