// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgBowl = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.28 3.22a.75.75 0 0 0-1.06 1.06L5.94 8H2.75a.75.75 0 0 0-.75.75V10a8 8 0 1 0 16 0V8.75a.75.75 0 0 0-.75-.75H8.059a.758.758 0 0 0-.029-.03L3.28 3.22ZM3.5 10v-.5h13v.5a6.5 6.5 0 1 1-13 0Z"
    />
  </svg>
)

export default SvgBowl
