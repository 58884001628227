import {Room, SapiAbstractOfferType, SplitBooking} from '../../types/room'

export const mapRoomToAbstract = (room: Room) => ({
  id: room.id,
  type: SapiAbstractOfferType.Regular,
  data: room
})

export const mapSplitBookingToAbstract = (splitBooking: SplitBooking) => ({
  id: splitBooking.bundleId,
  type: SapiAbstractOfferType.SplitBooking,
  data: splitBooking
})
