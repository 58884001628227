import {RateBreakdownType} from '@daedalus/core/src/offer/types/AugmentedOffer'
import {OfferPrice} from '@daedalus/core/src/offer/types/offer'
import PriceTypes from '@daedalus/core/src/offer/types/PriceTypes'
import {TaxDisplayLogic} from '@daedalus/core/src/offer/types/TaxDisplayLogic'

import {
  calculateNightlyPrice,
  getRaaOrSapiNightlyDisplayTotalPrice
} from './price'

export const adaptRaaPriceToChargeablePrice = (
  price: RateBreakdownType,
  taxDisplayLogic: TaxDisplayLogic,
  currencyCode: string,
  numberOfNights: number,
  numberOfRooms: number
): OfferPrice => {
  const {baseRate, taxes, localTaxes} = price

  const nightlyBase = calculateNightlyPrice(
    baseRate,
    numberOfNights,
    numberOfRooms
  )

  const nightlyTaxes = calculateNightlyPrice(
    taxes,
    numberOfNights,
    numberOfRooms
  )

  const nightlyDisplayTotal =
    taxDisplayLogic &&
    getRaaOrSapiNightlyDisplayTotalPrice(
      baseRate,
      taxes,
      localTaxes,
      numberOfNights,
      numberOfRooms,
      taxDisplayLogic
    )

  const hotelFeesInNumberFormat = {
    nightly: Number(
      calculateNightlyPrice(localTaxes, numberOfNights, numberOfRooms)
    ),
    total: localTaxes
  }

  const chargeableInNumberFormat = {
    base: baseRate,
    nightlyBase: Number(nightlyBase),
    nightlyDisplayTotal,
    nightlyTaxes: Number(nightlyTaxes),
    taxes
  }

  return {
    chargeable: chargeableInNumberFormat,
    currencyCode,
    hotelFees: hotelFeesInNumberFormat,
    type: PriceTypes.CHARGEABLE_PRICE
  }
}

export default adaptRaaPriceToChargeablePrice
