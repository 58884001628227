// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgFaceLove = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.5a6.5 6.5 0 1 0 6.5 6.5.75.75 0 0 1 1.5 0 8 8 0 1 1-8-8 .75.75 0 0 1 0 1.5Zm5-.117a1.846 1.846 0 0 1 2.46.188c.72.746.72 1.945 0 2.691l-1.125 1.167a1.847 1.847 0 0 1-2.67 0L12.54 6.262a1.947 1.947 0 0 1 0-2.691A1.846 1.846 0 0 1 15 3.383Zm-.87 1.23a.347.347 0 0 0-.51 0 .448.448 0 0 0 0 .608l1.124 1.166c.145.15.367.15.512 0l1.125-1.166a.448.448 0 0 0 0-.609.347.347 0 0 0-.512 0l-.33.342a.75.75 0 0 1-1.079 0l-.33-.342ZM8 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm3 1a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-3.7 3.15a.75.75 0 0 1 1.045.143l.01.012a2.037 2.037 0 0 0 .374.321c.277.184.697.374 1.271.374s.994-.19 1.271-.374a2.035 2.035 0 0 0 .374-.321l.01-.012a.75.75 0 0 1 1.195.907l-.6-.45.6.45-.002.002-.002.003-.005.007-.014.018a2.074 2.074 0 0 1-.187.206c-.123.123-.301.281-.537.438A3.754 3.754 0 0 1 10 14.5c-.926 0-1.63-.31-2.104-.626a3.527 3.527 0 0 1-.68-.593 1.825 1.825 0 0 1-.043-.052l-.014-.017-.005-.007-.002-.003-.001-.001c0-.001-.001-.001.599-.451l-.6.45a.75.75 0 0 1 .15-1.05Z"
    />
  </svg>
)

export default SvgFaceLove
