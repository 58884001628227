// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgEraser = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.237 3.927a1.75 1.75 0 0 0-2.474 0L2.22 10.47a.75.75 0 0 0 0 1.06l2.25 2.25c.14.141.331.22.53.22h4a.75.75 0 0 0 .53-.22l4.543-4.542a1.75 1.75 0 0 0 0-2.475l-2.836-2.836-.516.516.516-.516Zm-1.414 1.06a.25.25 0 0 1 .354 0l2.836 2.836a.25.25 0 0 1 0 .354L8.689 12.5H5.311L3.81 11l6.012-6.012ZM10.75 16.5a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5Z"
    />
  </svg>
)

export default SvgEraser
