import React, {ReactNode, useEffect, useRef, useState} from 'react'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'
import {SizesType} from 'types/Sizes'

import {cssTheme} from '../../../../themes'
import {Divider} from '../../Divider'

export type TitleBarVariant = 'default' | 'inverse' | 'floating'

type TitleBarSize = Extract<SizesType, 'md' | 'lg'>
export interface TitleBarProps {
  /** The TitleBar variant, options are default or inverse */
  variant?: TitleBarVariant
  /** Content placed before the center content. It can be any type of React Element */
  startContent?: ReactNode
  /** Content placed after the center content. It can be any type of React Element */
  endContent?: ReactNode
  /** Content of the Title */
  centerContent?: ReactNode
  /** Extra content at the bottom of the TitleBar */
  bottomContent?: ReactNode
  /** Whether the bottomContent (subHeader) should have paddings around */
  bottomContentPadding?: boolean
  /** Determine if a divider is rendered in the bottom of the TitleBar */
  hasDivider?: boolean
  /** Pass through className to allow styles overrides */
  className?: string
  /** Adds equal horizontal margins on both sides */
  centerAligned?: boolean
  /** The startContent is removed and the Title is aligned to the left */
  leftAligned?: boolean
  /** The size of the PopUp header */
  size?: TitleBarSize
}

const HEADER_HEIGHT = 56

const getBackground = (variant: TitleBarVariant) => {
  if (variant === 'inverse') {
    return cssTheme.colors.background.inverse.c950
  } else if (variant === 'floating') {
    return cssTheme.colors.transparent
  }
  return cssTheme.colors.background.neutral.c000
}

export const TitleBarWrapper = styled.div<{variant?: TitleBarVariant}>`
  width: 100%;
  position: ${({variant}) =>
    variant === 'floating' ? 'absolute' : 'relative'};
  display: flex;
  flex: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${({variant}) => getBackground(variant ?? 'default')};
  min-height: ${HEADER_HEIGHT}px;
`

export const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;

  align-items: center;

  &.--size {
    &-lg {
      padding: ${cssTheme.layout.spacing.s400} ${cssTheme.layout.spacing.s250};
      min-height: 68px;
    }
    &-md {
      padding: ${cssTheme.layout.spacing.s300} ${cssTheme.layout.spacing.s200};
      min-height: 64px;
    }
  }
`

export const StartIconWrapper = styled.div<{
  size: TitleBarSize
}>`
  position: absolute;
  z-index: 1;
  left: ${({size}) =>
    size === 'lg'
      ? cssTheme.layout.spacing.s250
      : cssTheme.layout.spacing.s200};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const EndIconWrapper = styled.div<{
  size: TitleBarSize
}>`
  position: absolute;
  right: ${({size}) =>
    size === 'lg'
      ? cssTheme.layout.spacing.s250
      : cssTheme.layout.spacing.s200};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`

export const CenterContentWrapper = styled.div<{
  variant: TitleBarVariant
  startWidth: number
  endWidth: number
  leftAligned: boolean
}>`
  position: relative;
  width: ${({startWidth, endWidth, leftAligned}) =>
    `calc(100% - ${leftAligned ? '0' : startWidth}px - ${endWidth}px)`};
  margin-left: ${({startWidth, leftAligned}) =>
    leftAligned ? '0' : startWidth}px;
  margin-right: ${({endWidth}) => endWidth}px;
  padding: 0px ${cssTheme.layout.spacing.s300};
  display: flex;
  flex-grow: 1;
  justify-content: ${({leftAligned}) =>
    leftAligned ? 'flex-start' : 'center'};
  align-items: center;
  text-align: center;
  touch-action: none;
  min-height: ${cssTheme.layout.spacing.s800};

  color: ${({variant}) =>
    variant === 'inverse'
      ? cssTheme.colors.content.neutral.c000
      : cssTheme.colors.content.neutral.c950};

  &.--size {
    &-md {
      ${cssTheme.typography.text.titleM}
    }
    &-lg {
      ${cssTheme.typography.text.titleL}
    }
  }
`

export const SubHeader = styled.div<{
  variant: TitleBarVariant
}>`
  width: 100%;
  flex-grow: 1;
  text-align: center;

  ${cssTheme.typography.text.titleS};
  color: ${({variant}) =>
    variant === 'inverse'
      ? cssTheme.colors.content.neutral.c000
      : cssTheme.colors.content.neutral.c950};

  &.--bottom-content-padding {
    padding: ${cssTheme.layout.spacing.s000} ${cssTheme.layout.spacing.s500}
      ${cssTheme.layout.spacing.s300} ${cssTheme.layout.spacing.s500};
  }
`

export const TitleBar = ({
  variant = 'default',
  startContent,
  endContent,
  hasDivider = false,
  centerContent,
  bottomContent,
  bottomContentPadding = true,
  centerAligned = true,
  leftAligned = false,
  size = 'md',
  className
}: TitleBarProps) => {
  const [startContentWidth, setStartContentWidth] = useState(0)
  const [endContentWidth, setEndContentWidth] = useState(0)
  const startContentRef = useRef<null | HTMLDivElement>(null)
  const endContentRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    if (startContentRef.current) {
      setStartContentWidth(startContentRef.current.offsetWidth)
    }
    if (endContentRef.current) {
      setEndContentWidth(endContentRef.current.offsetWidth)
    }
  }, [])

  const sideContentWidth: number = Math.max(startContentWidth, endContentWidth)
  const startWidth = centerAligned ? sideContentWidth : startContentWidth
  const endWidth = centerAligned ? sideContentWidth : endContentWidth

  return (
    <>
      <TitleBarWrapper variant={variant} className={className}>
        <HeaderWrapper className={`--size-${size}`}>
          <CenterContentWrapper
            className={`--size-${size}`}
            variant={variant}
            startWidth={startWidth}
            endWidth={endWidth}
            leftAligned={leftAligned}
          >
            {centerContent}
          </CenterContentWrapper>
          {startContent && !leftAligned && (
            <StartIconWrapper
              ref={startContentRef}
              className={className}
              size={size}
            >
              {startContent}
            </StartIconWrapper>
          )}
          {endContent && (
            <EndIconWrapper
              ref={endContentRef}
              size={size}
              className={className}
            >
              {endContent}
            </EndIconWrapper>
          )}
        </HeaderWrapper>
        {Boolean(bottomContent) && (
          <SubHeader
            variant={variant}
            className={cx(
              className,
              bottomContentPadding && '--bottom-content-padding'
            )}
          >
            {bottomContent}
          </SubHeader>
        )}
      </TitleBarWrapper>
      {hasDivider && <Divider />}
    </>
  )
}
