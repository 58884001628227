import qs from 'qs'
import {isNil} from 'ramda'

const buildUrl = (
  url: string,
  search: Record<string, unknown> = {},
  qsOptions: qs.IStringifyOptions = {}
): string => {
  if (isNil(search)) {
    return url
  }

  const query = qs.stringify(search, {
    arrayFormat: 'repeat',
    encodeValuesOnly: true,
    ...qsOptions
  })

  return `${url}?${query}`
}

export default buildUrl
