export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'

export type ChangeLanguageType = {
  language: string
  type: typeof CHANGE_LANGUAGE
}

export type ActionType = ChangeLanguageType

export const changeLanguage = (language: string): ChangeLanguageType => ({
  type: CHANGE_LANGUAGE,
  language
})
