// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgMap2 = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 3.585A1.5 1.5 0 0 0 3.5 5V12.4a2.98 2.98 0 0 1 1-.36V3.585Zm.625-1.575A.755.755 0 0 0 5 2a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3 .755.755 0 0 0-.125.01.75.75 0 0 0-.875.74V4H6V2.75a.75.75 0 0 0-.875-.74ZM6 5.5v7.25a.75.75 0 0 1-.875.74.757.757 0 0 1-.125.01 1.5 1.5 0 0 0 0 3h10a1.5 1.5 0 1 0 0-3 .758.758 0 0 1-.125-.01.75.75 0 0 1-.875-.74V5.5H6Zm9.5 6.541c.359.06.696.185 1 .36V5a1.5 1.5 0 0 0-1-1.415v8.456ZM10.25 6a.75.75 0 0 1 .75.75v.5a.75.75 0 0 1-1.5 0v-.5a.75.75 0 0 1 .75-.75Zm0 3a.75.75 0 0 1 .75.75v.5a.75.75 0 1 1-1.5 0v-.5a.75.75 0 0 1 .75-.75Zm0 3a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-.75.75h-.5a.75.75 0 0 1-.25-1.457v-.793a.75.75 0 0 1 .75-.75ZM6 14.25a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75Z"
    />
  </svg>
)

export default SvgMap2
