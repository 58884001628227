// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgSortAscending = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 3.75A.75.75 0 0 1 2.75 3h6.5a.75.75 0 0 1 0 1.5h-6.5A.75.75 0 0 1 2 3.75ZM2 8a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5A.75.75 0 0 1 2 8Zm.75 3.5a.75.75 0 0 0 0 1.5h3.5a.75.75 0 0 0 0-1.5h-3.5ZM14 3a.75.75 0 0 1 .55.24l3.25 3.5a.75.75 0 0 1-1.1 1.02l-1.95-2.1v10.59a.75.75 0 0 1-1.5 0V5.66l-1.95 2.1a.75.75 0 0 1-1.1-1.02l3.25-3.5A.75.75 0 0 1 14 3Z"
    />
  </svg>
)

export default SvgSortAscending
