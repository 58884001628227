import axios from 'axios'
import {identity, memoizeWith, mergeAll} from 'ramda'
import {publicUrl} from 'utils/urls'

import {countryDialCodes} from '@daedalus/core/src/localization/business/countryDialCodes'
import {getMappedLocaleOrDefault} from '@daedalus/core/src/localization/services/locale'
import {ensureUrlCorrectSlashes} from '@daedalus/core/src/utils/url'

export const iso18nCountriesLocaleMapper = (locale: string) =>
  getMappedLocaleOrDefault(locale, {
    en: 'en',
    'en-GB': 'en',
    es: 'es',
    fr: 'fr',
    no: 'nb',
    'pt-BR': 'pt',
    'zh-CN': 'zh',
    'zh-HK': 'zh',
    'zh-TW': 'zh',
    'fr-CA': 'fr'
  })

const getCountryListNames = memoizeWith(identity, async (locale: string) => {
  const mappedLocale = iso18nCountriesLocaleMapper(locale)

  const isoCountriesFolder = ensureUrlCorrectSlashes([
    publicUrl,
    `/js/i18n-iso-countries/langs`
  ])

  return axios
    .get(`/${isoCountriesFolder}${mappedLocale}.json`)
    .then(res => {
      return res.data.countries
    })
    .then(countries => {
      return Object.keys(countries).map(countryCode => {
        return {
          [countryCode]: {
            name: countries[countryCode],
            code: countryCode,
            dialCode: countryDialCodes[countryCode]
          }
        }
      })
    })
    .then(mergeAll)
})

export default getCountryListNames
