// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgHotTub = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="M12.008 6.523a.24.24 0 0 1 .24.24V7.77a.754.754 0 0 0 .753.754.762.762 0 0 0 .753-.754V6.764a1.75 1.75 0 0 0-1.746-1.749.24.24 0 0 1-.241-.241v-.895a.764.764 0 0 0-.753-.753.762.762 0 0 0-.753.753v.895a1.75 1.75 0 0 0 1.747 1.749ZM15.3 6.523a.24.24 0 0 1 .24.24V7.77a.764.764 0 0 0 .754.754.752.752 0 0 0 .752-.754V6.764A1.75 1.75 0 0 0 15.3 5.015a.231.231 0 0 1-.231-.241v-.895a.764.764 0 0 0-.753-.753.752.752 0 0 0-.753.753v.895A1.75 1.75 0 0 0 15.3 6.523Z" />
    <path d="M17.247 10.211H10.01l-1.064-.372a7.084 7.084 0 0 1-2.148-1.166 8.425 8.425 0 0 1-.632-.562l-.452-.453c-.07-.07-.15-.12-.22-.18a2.77 2.77 0 0 0 2.248-2.714A2.766 2.766 0 0 0 4.982 2a2.758 2.758 0 0 0-1.416 5.126A2.168 2.168 0 0 0 2 9.206v8.04a.745.745 0 0 0 .753.754h14.494a.752.752 0 0 0 .753-.754v-6.281a.754.754 0 0 0-.753-.754ZM4.981 3.508a1.254 1.254 0 0 1 1.255 1.256A1.257 1.257 0 0 1 4.98 6.02a1.254 1.254 0 0 1-1.254-1.256A1.257 1.257 0 0 1 4.98 3.508Zm-.803 5.025a.631.631 0 0 1 .472.2l.452.443c.242.24.496.469.763.683l.491.352h-.833a.732.732 0 0 0-.612.312.754.754 0 0 0-.1.683l.552 1.588a.382.382 0 0 1 0 .311.432.432 0 0 1-.231.201.39.39 0 0 1-.502-.24L3.526 10.05v-.794a.674.674 0 0 1 .652-.723Zm-.672 5.598a1.887 1.887 0 0 0 2.739.216 1.89 1.89 0 0 0 .453-.618 1.911 1.911 0 0 0 .09-1.468l-.21-.583h9.916v4.774H3.506v-2.321Z" />
  </svg>
)

export default SvgHotTub
