// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgBbq = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.75 2.75a.75.75 0 0 0-1.5 0v.5a.75.75 0 0 0 1.5 0v-.5ZM2.75 5a.75.75 0 0 0-.75.75c0 2.67 1.685 5.059 4.129 6.316l-.97 1.94A2 2 0 1 0 6.937 16.5h7.124l.51 1.073a.75.75 0 0 0 1.355-.645l-2.265-4.758C16.218 10.944 18 8.496 18 5.75a.75.75 0 0 0-.75-.75H2.75Zm3.983 10h6.615l-1.097-2.303a8.49 8.49 0 0 1-4.728-.066L6.5 14.677c.088.1.166.208.233.323ZM10 11.5c-3.348 0-6.012-2.327-6.44-5h12.88c-.428 2.673-3.092 5-6.44 5ZM10 2a.75.75 0 0 1 .75.75v.5a.75.75 0 0 1-1.5 0v-.5A.75.75 0 0 1 10 2Zm3.75.75a.75.75 0 0 0-1.5 0v.5a.75.75 0 0 0 1.5 0v-.5ZM4.5 16a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Z"
    />
  </svg>
)

export default SvgBbq
