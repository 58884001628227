// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCalendar = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 2.75a.75.75 0 0 0-1.5 0V4h-.5A2.75 2.75 0 0 0 2 6.75v8.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-8.5A2.75 2.75 0 0 0 15.25 4h-.5V2.75a.75.75 0 1 0-1.5 0V4h-6.5V2.75Zm6.5 3.5V5.5h-6.5v.75a.75.75 0 0 1-1.5 0V5.5h-.5c-.69 0-1.25.56-1.25 1.25v8.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-8.5c0-.69-.56-1.25-1.25-1.25h-.5v.75a.75.75 0 1 1-1.5 0ZM5.75 8a.75.75 0 1 0 0 1.5h8.5a.75.75 0 1 0 0-1.5h-8.5Z"
    />
  </svg>
)

export default SvgCalendar
