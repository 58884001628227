import React from 'react'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'
import {RangeSizesType} from 'types/Sizes'

import {cssTheme} from '../../../../themes'
import {linariaMq} from '../../../../utils/breakpoints'

interface PageWidthContainerPropsType {
  /** The page content */
  children: React.ReactNode
  /** A class name that can be given to the container */
  className?: string
  /** The max width the page will be refined to. If not supplied it is taken from the theme file */
  maxPageWidth?: number
  /** Whether to span the container full width - this will override maxPageWidth */
  isFullWidth?: boolean
  /** The page padding at the desktop-sm breakpoint. Only spacing sizes from the theme are accepted */
  paddingDesktopSmSize?: RangeSizesType
  /** The page padding at the desktop-xs breakpoint. Only spacing sizes from the theme are accepted */
  paddingDesktopXsSize?: RangeSizesType
  /** The page padding at the desktop-md breakpoint. Only spacing sizes from the theme are accepted */
  paddingDesktopMdSize?: RangeSizesType
  /** The page padding at the desktop-lg breakpoint. Only spacing sizes from the theme are accepted */
  paddingDesktopLgSize?: RangeSizesType
  /** The page padding at the mobile-sm breakpoint. Only spacing sizes from the theme are accepted */
  paddingMobileSmSize?: RangeSizesType
  /** The page padding at the mobile-lg breakpoint. Only spacing sizes from the theme are accepted */
  paddingMobileLgSize?: RangeSizesType
}

type PageWidthContainerElementPropsType = Omit<
  PageWidthContainerPropsType,
  'children' | 'className'
>

const getPadding = (size?: RangeSizesType) =>
  cssTheme.layout.spacing[size || 's000']

const PageWidthContainerElement = styled.div<PageWidthContainerElementPropsType>`
  max-width: ${({isFullWidth, maxPageWidth}) =>
    isFullWidth
      ? '100%'
      : maxPageWidth
        ? `${maxPageWidth}px`
        : cssTheme.layout.maxPageWidth};
  margin: 0 auto;
  padding: 0 ${({paddingMobileSmSize}) => getPadding(paddingMobileSmSize)};

  &.--has-paddingMobileLgSize {
    ${linariaMq.mobileLg} {
      padding: 0 ${({paddingMobileLgSize}) => getPadding(paddingMobileLgSize)};
    }
  }
  &.--has-paddingDesktopXsSize {
    ${linariaMq.desktopXs} {
      width: 100%;
      padding: 0 ${({paddingDesktopXsSize}) => getPadding(paddingDesktopXsSize)};
    }
  }
  &.--has-paddingDesktopSmSize {
    ${linariaMq.desktopSm} {
      padding: 0 ${({paddingDesktopSmSize}) => getPadding(paddingDesktopSmSize)};
    }
  }
  &.--has-paddingDesktopMdSize {
    ${linariaMq.desktopMd} {
      padding: 0 ${({paddingDesktopMdSize}) => getPadding(paddingDesktopMdSize)};
    }
  }
  &.--has-paddingDesktopLgSize {
    ${linariaMq.desktopLg} {
      padding: 0 ${({paddingDesktopLgSize}) => getPadding(paddingDesktopLgSize)};
    }
  }
`

export const PageWidthContainer = ({
  children,
  className,
  isFullWidth,
  maxPageWidth,
  paddingDesktopLgSize,
  paddingDesktopMdSize,
  paddingDesktopSmSize,
  paddingDesktopXsSize,
  paddingMobileSmSize,
  paddingMobileLgSize
}: PageWidthContainerPropsType) => (
  <PageWidthContainerElement
    className={cx(
      className,
      paddingDesktopLgSize && '--has-paddingDesktopLgSize',
      paddingDesktopMdSize && '--has-paddingDesktopMdSize',
      paddingDesktopSmSize && '--has-paddingDesktopSmSize',
      paddingDesktopXsSize && '--has-paddingDesktopXsSize',
      paddingMobileSmSize && '--has-paddingMobileSmSize',
      paddingMobileLgSize && '--has-paddingMobileLgSize'
    )}
    maxPageWidth={maxPageWidth}
    isFullWidth={isFullWidth}
    paddingDesktopLgSize={paddingDesktopLgSize}
    paddingDesktopMdSize={paddingDesktopMdSize}
    paddingDesktopSmSize={paddingDesktopSmSize}
    paddingDesktopXsSize={paddingDesktopXsSize}
    paddingMobileSmSize={paddingMobileSmSize}
    paddingMobileLgSize={paddingMobileLgSize}
  >
    {children}
  </PageWidthContainerElement>
)
