// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCopy = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.75 2A2.75 2.75 0 0 0 2 4.75v6.75A2.5 2.5 0 0 0 4.5 14a.75.75 0 0 0 0-1.5 1 1 0 0 1-1-1V4.75c0-.69.56-1.25 1.25-1.25h6.75a1 1 0 0 1 1 1 .75.75 0 0 0 1.5 0A2.5 2.5 0 0 0 11.5 2H4.75Zm4 4A2.75 2.75 0 0 0 6 8.75v6.5A2.75 2.75 0 0 0 8.75 18h6.5A2.75 2.75 0 0 0 18 15.25v-6.5A2.75 2.75 0 0 0 15.25 6h-6.5ZM7.5 8.75c0-.69.56-1.25 1.25-1.25h6.5c.69 0 1.25.56 1.25 1.25v6.5c0 .69-.56 1.25-1.25 1.25h-6.5c-.69 0-1.25-.56-1.25-1.25v-6.5Z"
    />
  </svg>
)

export default SvgCopy
