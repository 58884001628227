import {createSelector} from '@reduxjs/toolkit'
import {StoreStateType} from 'modules'
import {pathOr} from 'ramda'
import {RootState} from 'store'

export const getUserCountry = (state: RootState) => state.meta.userCountry || ''

export const getUserIp = (state: RootState) => state.meta.userIp

export const getAnonymousId = (state: RootState) => state.meta.anonymousId

export const getSearchId = createSelector(
  (state: StoreStateType) => pathOr(null, ['meta', 'searchId'], state),
  searchId => searchId || ''
)

export const getDeviceId = (state: RootState): string | null =>
  state.meta.deviceId || null
