import React, {ReactNode} from 'react'
import {styled} from '@linaria/react'
import {SizesType} from 'types/Sizes'

import * as iconsList from '@findhotel/atlas-assets'

import {cssTheme} from '../../../themes'
import {Icon, IconSize} from '../Icon'

export type BadgeVariant =
  | 'info'
  | 'neutral'
  | 'special'
  | 'success'
  | 'warning'
  | 'danger'

export type BadgeSize = Extract<SizesType, 'xs' | 'sm' | 'md'>

interface Props {
  /** Content to be displayed in the Badge */
  children?: ReactNode
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Icon used instead of children font */
  iconName?: keyof typeof iconsList
  /** The size of the component */
  size?: BadgeSize
  /** The variation of styling the component will have */
  variant?: BadgeVariant
}

interface StyleProps {
  iconName: Props['iconName']
  size: BadgeSize
  variant: BadgeVariant
}

interface SizeMapItem {
  iconSize: IconSize
  iconPadding: number
  size: number
}

const sizeMap: Record<string, SizeMapItem> = {
  xs: {iconSize: 'xs', iconPadding: 2, size: 16},
  sm: {iconSize: 'sm', iconPadding: 2, size: 20},
  md: {iconSize: 'sm', iconPadding: 4, size: 24}
}

export const Wrapper = styled.div<StyleProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${cssTheme.layout.radius.rounded};
  ${cssTheme.typography.text.labelXS}

  color: ${({variant}) => cssTheme.colors.badge[variant].content};
  background-color: ${({variant}) => cssTheme.colors.badge[variant].background};

  height: ${({size}) => sizeMap[size].size}px;
  min-width: ${({size}) => sizeMap[size].size}px;

  padding: 0
    ${({iconName, size}) =>
      iconName
        ? `${sizeMap[size].iconPadding}px`
        : cssTheme.layout.spacing.s200};
`

export const Badge = ({
  children,
  iconName,
  size = 'xs',
  variant = 'info',
  className
}: Props) => (
  <Wrapper
    iconName={iconName}
    size={size}
    variant={variant}
    className={className}
  >
    {iconName ? (
      <Icon name={iconName} size={sizeMap[size].iconSize} />
    ) : (
      children
    )}
  </Wrapper>
)
