import {createSelector} from '@reduxjs/toolkit'
import getRoomConfig from 'components/data/URLParams/mappers/getRoomConfig'
import {getSearchId} from 'modules/meta/selectors'
import {urlParams} from 'utils/urlParams'

import {getCugDeals} from './getCugDeals'

/**
 * Returns the search context
 * @param state - redux state object
 * @param searchIdParam - optional param to be able to pass in the searchId directly for when it is not available in the store yet
 */
export const getSearchContext = createSelector(
  [getSearchId, getCugDeals],
  (searchId, cugDeals) => {
    const params = urlParams()

    const roomsConfig = getRoomConfig(params)
    const {numberOfAdults, numberOfChildren} = roomsConfig
    const {
      checkIn,
      checkOut,
      locale,
      currency,
      hotelId,
      oid,
      providerCode,
      rooms
    } = params
    return {
      clickedHotelId: hotelId,
      clickedOfferId: oid,
      roomsProviderCode: providerCode,
      rooms: rooms,
      searchId,
      cugDeals,
      checkIn: checkIn,
      checkOut: checkOut,
      numberOfAdults,
      numberOfChildren,
      currency,
      locale,
      isDefaultDates: false,
      isStoredDates: false,
      anchorHotelId: hotelId,
      isHomeSearch: false,
      isMapOpen: false,
      isHotelMapOpen: false,
      isHotelDetailsOpen: false,
      isOffersOnlyFetch: true,
      searchWillRetriggerIfNoOffers: false,
      isFsmr: false,
      priceDisplay: 'nightly_price',
      searchType: 'hotel'
    }
  }
)
