import {toDecreasePercentage} from '../../utils/number'

export const ANCHOR_PRICE_DELTA_THRESHOLD_PERCENTAGE = 0.03

/**
 * Calculates the difference in percentage between the anchor price and the offer price.
 *
 * @param anchorPrice - The anchor price is the price the user would pay if got the more expensive rate from our selection
 * @param price - The price for the offer
 *
 * @returns A number representing the difference in percentage between the values
 */
export const getSavingPercentage = (anchorPrice: number, price: number) =>
  toDecreasePercentage(price, anchorPrice, 'round')

/**
 * Calculates if the anchor price has to be displayed or not.
 *
 * @param price - The price for the offer
 * @param anchorPrice - The anchor price is the price the user would pay if got the more expensive rate from our selection
 *
 * @returns A boolean representing wether the anchor price has to be displayed or not
 */
export const hasAnchorPrice = (price: number, anchorPrice: number): boolean => {
  return (
    anchorPrice - price > anchorPrice * ANCHOR_PRICE_DELTA_THRESHOLD_PERCENTAGE
  )
}
