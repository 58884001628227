export const MAX_DATE_DIFFERENCE = 29
export const MAX_MONTHS_COUNT = 13
export const FIRST_DAY_OF_THE_WEEK = 1
export const DEFAULT_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
export const WEEKDAYS_SHORT = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
// FIXME: Days Of Week should be configurable based on Locale
export const WEEKENDS_INDEXES = [0, 6]
