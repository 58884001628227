// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPdLocked = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.875 8.4C14.85 4.86 12.677 2 10 2S5.15 4.86 5.125 8.4c-.897 0-1.625.781-1.625 1.745v6.11c0 .963.728 1.745 1.625 1.745h9.75c.897 0 1.625-.782 1.625-1.746v-6.109c0-.964-.728-1.745-1.625-1.745Zm-2.45 0C12.296 6.165 11.26 4.423 10 4.423c-1.26 0-2.297 1.742-2.424 3.977h4.848ZM10 11.032a1.29 1.29 0 0 1 .65 2.411v1.68a.648.648 0 0 1-.65.646.648.648 0 0 1-.65-.646v-1.68a1.29 1.29 0 0 1 .65-2.411Z"
    />
  </svg>
)

export default SvgPdLocked
