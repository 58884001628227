import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {ThemeType} from 'types/Theme'

import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

const LegendWrapper = styled('div')(
  ({theme}) => css`
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-column-gap: ${theme.layout.spacing.s200}px;
    align-items: center;
  `
)

const LegendBox = styled.div(
  ({theme}: {theme: ThemeType}) => css`
    padding-top: ${theme.layout.spacing.s100}px;
    padding-bottom: ${theme.layout.spacing.s100}px;
    &:before {
      content: '';
      display: block;
      height: ${theme.layout.spacing.s400}px;
      width: ${theme.layout.spacing.s400}px;
      border-radius: ${theme.layout.radius.sm}px;
      background-color: ${theme.colors.background.success.c400};
    }
  `
)

export const Legend = () => {
  return (
    <LegendWrapper>
      <LegendBox />
      <Text variant="bodyXS" colorPath="content.neutral.c600">
        <FormattedMessageWrapper
          id="cheapestNightlyPrice"
          defaultMessage="Cheapest nightly price"
        />
      </Text>
    </LegendWrapper>
  )
}
