import React, {useCallback} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {NoStyleButton} from '@daedalus/atlas/helpers/NoStyleButton'
import {Text} from '@daedalus/atlas/Text'

import {TabsContext} from './Tabs'

interface Props {
  value: string
}

const ActiveUnderline = styled.span(
  ({theme}) => css`
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${theme.colors.border.neutral.c950};
    opacity: 0;
    transition: all 0.3s;
  `
)

const TabWrapper = styled.li<{
  isActive: boolean
  showActiveOnHover: boolean
}>(
  ({isActive, showActiveOnHover}) => css`
    height: 70px;
    display: flex;
    list-style: none;
    position: relative;
    ${isActive &&
    css`
      > ${ActiveUnderline} {
        opacity: 1;
      }
    `}
    ${showActiveOnHover &&
    css`
      :hover {
        > ${ActiveUnderline} {
          opacity: 1;
        }
      }
    `}
  `
)

const StyledButton = styled(NoStyleButton)(
  ({theme}) => css`
    padding: ${theme.layout.spacing.s300}px ${theme.layout.spacing.s500}px;
  `
)

export const Tab: React.FC<Props> = ({value, children}) => {
  const {onChange, value: activeTab} = React.useContext(TabsContext)

  const onClick = useCallback(() => {
    onChange(value)
  }, [onChange, value])

  return (
    <TabWrapper
      isActive={activeTab === value}
      // Only show the underline when there's no active tab
      showActiveOnHover={!activeTab}
    >
      <ActiveUnderline />
      <StyledButton onClick={onClick}>
        <Text variant="labelM">{children}</Text>
      </StyledButton>
    </TabWrapper>
  )
}
