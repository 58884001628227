import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {CompactHeader} from '@daedalus/atlas/src/components/designSystem/CompactHeader'
import {MOBILE_HEADER_HEIGHT} from '@daedalus/atlas/src/components/designSystem/CompactHeader'
import {
  ANIMATION_TYPES,
  Overlay
} from '@daedalus/atlas/src/components/designSystem/Overlay'
import {Brand} from '@daedalus/core/src/brand/types'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {AccountContent} from './AccountContent'
import {TopBarSubmitButton} from './TopBarSubmitButton'

const HeaderWrapper = styled.div(
  ({theme}) => css`
    position: relative;
    z-index: 2;
    border-bottom: 1px solid ${theme.colors.border.neutral.c100};
  `
)

const HeaderContentWrapper = styled.div(
  ({theme}) => css`
    font-family: ${theme.fonts.family.default};
    font-weight: ${theme.fonts.weight.lg};
    line-height: ${theme.fonts.lineHeight.xs};
    font-size: ${theme.fonts.size.md};
    color: ${theme.colors.content.neutral.c950};
  `
)

const OverlaySection = styled.section`
  height: calc(100% - ${MOBILE_HEADER_HEIGHT}px);
  display: flex;
  flex-direction: column;
`

interface AccountOverlayPropsType {
  isOpen: boolean
  onClose: () => void
  brand: Brand
}

export const AccountOverlay = ({
  isOpen,
  onClose,
  brand
}: AccountOverlayPropsType) => (
  <Overlay
    isOpen={isOpen}
    animationType={ANIMATION_TYPES.SLIDE_RIGHT}
    shadeBackground={false}
    onClose={onClose}
  >
    <HeaderWrapper>
      <CompactHeader
        brand={brand}
        onBackButtonClick={onClose}
        endContent={<TopBarSubmitButton />}
        centerContent={
          <HeaderContentWrapper>
            <FormattedMessageWrapper
              id="account.editAccount"
              defaultMessage="Edit Account"
            />
          </HeaderContentWrapper>
        }
      />
    </HeaderWrapper>
    <OverlaySection>
      <AccountContent brand={brand} />
    </OverlaySection>
  </Overlay>
)
