import {ComponentType} from 'react'

export type LazyRetry = <T extends ComponentType>(
  factory: () => Promise<{default: T}>
) => Promise<{default: T}>

export const withLoadingRetry: LazyRetry = componentImport =>
  new Promise((resolve, reject) => {
    const storageKey = `retry-lazy-refreshed${btoa(componentImport.toString())}`
    const hasRefreshed = window.sessionStorage.getItem(storageKey) === 'true'

    componentImport()
      .then(component => {
        if (component === undefined && !hasRefreshed) {
          window.sessionStorage.setItem(storageKey, 'true')
          return window.location.reload() // refresh the page
        } else {
          window.sessionStorage.setItem(storageKey, 'false') // In case chunk load fails multiple times
        }
        resolve(component)
      })
      .catch(error => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(storageKey, 'true')
          window.location.reload()
        }
        reject(error) // Default error behaviour as already tried refresh
      })
  })
