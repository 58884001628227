// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgShower = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.446 3.5a.946.946 0 0 0-.946.946V17.25a.75.75 0 0 1-1.5 0V4.446a2.446 2.446 0 0 1 4.34-1.549l1.528 1.868a4.287 4.287 0 0 1 5.46.502l.5.5a.75.75 0 0 1 0 1.06l-5 5a.75.75 0 0 1-1.061 0l-.5-.5a4.287 4.287 0 0 1-.471-5.503L7.179 3.847a.946.946 0 0 0-.733-.347Zm9.774 4.72a.75.75 0 0 1 1.06 0l.5.5a.75.75 0 0 1-1.06 1.06l-.5-.5a.75.75 0 0 1 0-1.06Zm-.94 2a.75.75 0 1 0-1.06 1.06l.5.5a.75.75 0 0 0 1.06-1.06l-.5-.5Zm-3.06 2a.75.75 0 0 1 1.06 0l.5.5a.75.75 0 1 1-1.06 1.06l-.5-.5a.75.75 0 0 1 0-1.06Zm-1.893-5.893a2.786 2.786 0 0 1 3.909-.03l-3.939 3.939a2.786 2.786 0 0 1 .03-3.909Z"
    />
  </svg>
)

export default SvgShower
