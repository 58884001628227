// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgAnnotationWarning = ({
  color = 'currentColor',
  size = 20
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 5.75A3.75 3.75 0 0 1 5.75 2h8.5A3.75 3.75 0 0 1 18 5.75v6.5A3.75 3.75 0 0 1 14.25 16h-1.372l-2.424 1.846a.75.75 0 0 1-.909 0L7.122 16H5.75A3.75 3.75 0 0 1 2 12.25v-6.5ZM5.75 3.5A2.25 2.25 0 0 0 3.5 5.75v6.5a2.25 2.25 0 0 0 2.25 2.25h1.625a.75.75 0 0 1 .454.153L10 16.307l2.171-1.654a.75.75 0 0 1 .455-.153h1.625a2.25 2.25 0 0 0 2.25-2.25v-6.5a2.25 2.25 0 0 0-2.25-2.25h-8.5ZM10 5a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0V6a1 1 0 0 1 1-1Zm0 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
    />
  </svg>
)

export default SvgAnnotationWarning
