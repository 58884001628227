// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgEdit = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.988 3.177a1.75 1.75 0 0 0-2.475 0L3.22 12.47a.75.75 0 0 0-.2.358l-1 4.25a.75.75 0 0 0 .902.902l4.25-1a.75.75 0 0 0 .358-.2l9.293-9.292a1.75 1.75 0 0 0 0-2.475l-1.835-1.836Zm-1.415 1.06a.25.25 0 0 1 .354 0l1.836 1.836a.25.25 0 0 1 0 .354L6.62 15.569l-2.863.673.673-2.862 9.142-9.142ZM11.75 16.5a.75.75 0 0 0 0 1.5h5.5a.75.75 0 1 0 0-1.5h-5.5Z"
    />
  </svg>
)

export default SvgEdit
