import {Details as UserAgentDetails, UserAgent} from 'express-useragent'

import type {DeviceCategory, DeviceDetails, DevicePlatform} from './types'

export const getDevicePlatform = (
  userAgentDetails?: UserAgentDetails
): DevicePlatform => {
  const userAgent =
    userAgentDetails || new UserAgent().parse(navigator.userAgent)
  if (userAgent.isiPad || userAgent.isiPhone || userAgent.isiPod) return 'iOS'
  if (userAgent.isAndroid) return 'android'
  if (userAgent.isWindows) return 'windows'
  if (userAgent.isMac) return 'mac'
  if (userAgent.isLinux || userAgent.isLinux64) return 'linux'
  if (userAgent.isChromeOS) return 'chromeOS'
  return 'unknown'
}

export const isApple = () => {
  const devicePlatform = getDevicePlatform()
  return ['iOS', 'mac'].includes(devicePlatform)
}

export const getDeviceCategory = (
  userAgentDetails?: UserAgentDetails
): DeviceCategory => {
  // check tablet before mobile, because for express-useragent tablet is mobile
  const userAgent =
    userAgentDetails || new UserAgent().parse(navigator.userAgent)

  if (userAgent.isTablet) return 'tablet'
  if (userAgent.isMobile) return 'mobile'
  return 'desktop'
}

export const getUserDeviceDetails = (): DeviceDetails => {
  const userAgentDetails = new UserAgent().parse(navigator.userAgent)
  return {
    devicePlatform: getDevicePlatform(userAgentDetails),
    deviceCategory: getDeviceCategory(userAgentDetails),
    userAgentDetails
  }
}
