import React from 'react'
import {useIntl} from 'react-intl'

import {Icon} from '@daedalus/atlas/Icon'
import {InputButton} from '@daedalus/atlas/InputButton'

import {guestPickerMessages} from '../GuestPicker/guestPickerMessages'

interface Props {
  /** Rooms configuration */
  numberOfAdults: number
  /** Rooms configuration */
  numberOfChildren: number
  /** Rooms configuration */
  numberOfRooms: number
  /** The click handler */
  onClick: () => void
  /** Whether the rooms count and label is shown */
  isRoomsLabelShown?: boolean
}

export const FakeGuestPickerInput = ({
  numberOfAdults,
  numberOfChildren,
  numberOfRooms,
  onClick,
  isRoomsLabelShown = true
}: Props) => {
  const {formatMessage} = useIntl()

  const numberOfGuests = numberOfAdults + numberOfChildren

  const guestMessage = formatMessage(guestPickerMessages.guest, {
    count: numberOfGuests
  })

  const roomsMessage = formatMessage(guestPickerMessages.room, {
    count: numberOfRooms
  })

  return (
    <InputButton
      dataId="GuestPickerTrigger"
      id="GuestPickerTrigger"
      size="lg"
      icon={<Icon colorPath="content.neutral.c600" name="Person" />}
      onClick={onClick}
    >
      {numberOfGuests} {guestMessage}
      {isRoomsLabelShown && (
        <>
          , {numberOfRooms} {roomsMessage}
        </>
      )}
    </InputButton>
  )
}
