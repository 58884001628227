import {prop} from 'ramda'

import {COGNITO_ROUTES} from '../../constants'

export const getUrlPathName = (url?: string) => {
  if (!url) return
  const lastSearchUrl = new URL(url)

  return prop('pathname')(lastSearchUrl)
}

export const isCognitoRoute = (pathname: string) =>
  COGNITO_ROUTES.includes(pathname)
