// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCustomTrendingUp = ({
  color = 'currentColor',
  size = 20
}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="M14.167 5.354a.804.804 0 0 0-.593.24.804.804 0 0 0-.24.594c0 .236.08.434.24.593.16.16.357.24.593.24H15.5l-4.333 4.292-2.75-2.75a.865.865 0 0 0-.583-.23.785.785 0 0 0-.313.063 1.096 1.096 0 0 0-.27.167l-5.022 5.062a.765.765 0 0 0-.229.563.8.8 0 0 0 .25.583.79.79 0 0 0 .584.23.79.79 0 0 0 .583-.23l4.417-4.417 2.75 2.75a.736.736 0 0 0 .27.178.928.928 0 0 0 .313.052.928.928 0 0 0 .313-.052.736.736 0 0 0 .27-.178l4.917-4.916V9.52c0 .236.08.434.24.594.16.16.357.24.593.24s.434-.08.594-.24c.16-.16.24-.358.24-.594V6.188a.804.804 0 0 0-.24-.594.804.804 0 0 0-.594-.24h-3.333Z" />
  </svg>
)

export default SvgCustomTrendingUp
