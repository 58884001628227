import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import {styled} from '@linaria/react'
import {AnimatePresence, motion} from 'framer-motion'
import {v4 as uuid} from 'uuid'

import {Portal} from '../components/designSystem/helpers/Portal'
import {Props as ToastProps, Toast} from '../components/designSystem/Toast'
import {cssTheme} from '../themes'

type ToastType = {
  id: string
  content: ReactNode
  toastProps?: Omit<ToastProps, 'children'>
}

export type ToastOpenType = (
  content: ReactNode,
  toastProps?: Omit<ToastProps, 'children'> & {
    singleToastMessage?: boolean
  }
) => string

export interface ToastContextValue {
  open: ToastOpenType
  warning: ToastOpenType
  privateDeal: ToastOpenType
  close: (id: string) => void
  closeAll: () => void
}

const ToastWrapper = styled(motion.div)`
  position: fixed;
  z-index: 110;
  /*needed to apply extra padding at the bottom for iOS gesture bar*/
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  bottom: calc(${cssTheme.layout.spacing.s300} + var(--safe-area-inset-bottom));
  right: ${cssTheme.layout.spacing.s300};
  left: ${cssTheme.layout.spacing.s300};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${cssTheme.layout.spacing.s300};
  pointer-events: none;
`

export const ToastContext = createContext<ToastContextValue>({
  open: () => '',
  warning: () => '',
  privateDeal: () => '',
  close: () => null,
  closeAll: () => null
})

export const ToastProvider = ({children}: {children: ReactNode}) => {
  const [toasts, setToasts] = useState<ToastType[]>([])

  const open: ToastOpenType = (content, toastProps): string => {
    const id = uuid()
    if (toastProps?.singleToastMessage) {
      setToasts([{id, content, toastProps}])
    } else {
      setToasts(currentToasts => [...currentToasts, {id, content, toastProps}])
    }
    return id
  }

  const warning: ToastOpenType = (content, toastProps) =>
    open(content, {...toastProps, variant: 'warning'})

  const privateDeal: ToastOpenType = (content, toastProps) =>
    open(content, {...toastProps, variant: 'special'})

  const close: (id: string) => void = id =>
    setToasts(currentToasts => currentToasts.filter(toast => toast.id !== id))

  const closeAll: () => void = () => setToasts([])

  const contextValue = useMemo(
    () => ({open, warning, privateDeal, close, closeAll}),
    []
  )

  return (
    <ToastContext.Provider value={contextValue}>
      {children}

      <Portal>
        <ToastWrapper layout>
          <AnimatePresence>
            {toasts.map(toast => {
              const {toastProps} = toast
              return (
                <Toast
                  key={toast.id}
                  {...toastProps}
                  onClose={() => {
                    close(toast.id)
                    toastProps?.onClose && toastProps.onClose()
                  }}
                >
                  {toast.content}
                </Toast>
              )
            })}
          </AnimatePresence>
        </ToastWrapper>
      </Portal>
    </ToastContext.Provider>
  )
}

export const useToastController = () => {
  const context = useContext(ToastContext)

  // Ensure context is not undefined
  if (!context) {
    throw new Error('useToastController must be used within a ToastProvider')
  }

  const {open, close, closeAll, warning, privateDeal} = context

  const [toastId, setToastId] = useState<string | null>(null)

  const openWithId = useCallback(
    (
      content: ReactNode,
      toastProps?: Omit<ToastProps, 'children'> & {
        singleToastMessage?: boolean
      }
    ) => {
      const id = open(content, toastProps)
      if (id) {
        setToastId(id)
      }
    },
    [open]
  )

  return {
    open: openWithId,
    close: () => {
      if (toastId) {
        close(toastId)
      }
    },
    closeAll,
    toastId,
    warning,
    privateDeal
  }
}
