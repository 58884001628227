// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgMug = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 2.75A.75.75 0 0 1 5.75 2h11.5a.75.75 0 0 1 .75.75v9.5A2.75 2.75 0 0 1 15.25 15h-7.5A2.75 2.75 0 0 1 5 12.25V12h-.25A2.75 2.75 0 0 1 2 9.25v-2.5A2.75 2.75 0 0 1 4.75 4H5V2.75ZM5 5.5v5h-.25c-.69 0-1.25-.56-1.25-1.25v-2.5c0-.69.56-1.25 1.25-1.25H5Zm1.5-2v8.75c0 .69.56 1.25 1.25 1.25h7.5c.69 0 1.25-.56 1.25-1.25V3.5h-10Zm-3.75 13a.75.75 0 0 0 0 1.5h14.5a.75.75 0 1 0 0-1.5H2.75Z"
    />
  </svg>
)

export default SvgMug
