// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPizza = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2a.75.75 0 0 1 .665.404l6.242 11.984a.748.748 0 0 1-.14.905c-3.789 3.776-9.746 3.776-13.535 0a.75.75 0 0 1-.14-.905L9.336 2.404A.75.75 0 0 1 10 2Zm4.99 11.953.332.64c-3.077 2.71-7.567 2.71-10.644 0l.333-.64C6.5 15.289 8.232 15.987 10 15.987c1.768 0 3.5-.698 4.99-2.034Zm-.72-1.382c-1.321 1.303-2.816 1.916-4.27 1.916-1.454 0-2.949-.613-4.27-1.916L10 4.374l4.27 8.197ZM8 10a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm4 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
    />
  </svg>
)

export default SvgPizza
