// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgBuilding = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 4.75A2.75 2.75 0 0 1 5.75 2h8.5A2.75 2.75 0 0 1 17 4.75V16.5h.25a.75.75 0 1 1 0 1.5H2.75a.75.75 0 0 1 0-1.5H3V4.75ZM5.75 3.5c-.69 0-1.25.56-1.25 1.25V16.5H7v-2.75A2.75 2.75 0 0 1 9.75 11h.5A2.75 2.75 0 0 1 13 13.75v2.75h2.5V4.75c0-.69-.56-1.25-1.25-1.25h-8.5Zm5.75 13v-2.75c0-.69-.56-1.25-1.25-1.25h-.5c-.69 0-1.25.56-1.25 1.25v2.75h3ZM7 8a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm5-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
    />
  </svg>
)

export default SvgBuilding
