import {useEffect, useState} from 'react'

/**
 *
 * useDebounce is a hook that when used will delay the assignment of a value into a variable until the
 * pre-defined amount of time has passed.
 * @returns The value provided on the value param
 * @param value - The value that should be returned after the amount of time defined on delay has passed
 * @param delay - The amount of time to delay until the value is assigned to the variable
 * @example
 * ```typescript
 * const value = useDebounce('delayedString', delay = 250)
 * console.log(value)  //undefined
 * // *after 250ms*
 * * console.log(value)  //delayedString
 * ```
 */
export default function useDebounce(value: any, delay = 250) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}
