/* eslint-disable fp/no-mutating-methods */
import {generateMaid, MaidLandingParameters} from './maid'

interface LandingParameters extends MaidLandingParameters {
  label?: string
  gclid?: string
  msclkid?: string
  utm_source?: string
}

/**
 * Pure function to generate a sapiLabel from the provided context.
 * This is used in app and web contexts, please do not colocate with browser-specific implementations (ie. cookies)
 */
export const generateEncodedSapiLabel = ({
  landingParameters,
  anonymousId,
  partnerId,
  hasPersistedSapiLabel
}: {
  landingParameters: LandingParameters
  anonymousId: string
  partnerId?: string | undefined
  hasPersistedSapiLabel: boolean
}) => {
  const trafficParameterKeys = ['label', 'gclid', 'msclkid'] as const

  const labels = trafficParameterKeys.reduce((prev, curr) => {
    const value = landingParameters[curr]
    if (value) prev.push(`${curr}=${encodeURIComponent(value.toString())}`)
    return prev
  }, [] as string[])

  if (partnerId) {
    labels.push(`utm_source=${partnerId}`)
  } else {
    if (landingParameters['utm_source']) {
      labels.push(
        `utm_source=${encodeURIComponent(
          landingParameters['utm_source'].toString()
        )}`
      )
    }
  }

  if (labels.length > 0 || !hasPersistedSapiLabel) {
    const maid = generateMaid({anonymousId, landingParameters})
    labels.push(`maid=${maid}`)
  }

  return labels.join('&') || null
}
