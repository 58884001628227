// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgTrendingDown = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 3.5a.25.25 0 0 0-.25.25v12.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25V3.75a.25.25 0 0 0-.25-.25h-.5ZM2 3.75C2 2.784 2.784 2 3.75 2h.5C5.216 2 6 2.784 6 3.75v12.5A1.75 1.75 0 0 1 4.25 18h-.5A1.75 1.75 0 0 1 2 16.25V3.75Zm9.22-1.53a.75.75 0 0 1 1.06 0l4.22 4.22V4.75a.75.75 0 0 1 1.5 0v3.5a.75.75 0 0 1-.75.75h-3.5a.75.75 0 0 1 0-1.5h1.69l-4.22-4.22a.75.75 0 0 1 0-1.06ZM9.75 10.5a.25.25 0 0 0-.25.25v5.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-5.5a.25.25 0 0 0-.25-.25h-.5ZM8 10.75C8 9.784 8.784 9 9.75 9h.5c.966 0 1.75.784 1.75 1.75v5.5A1.75 1.75 0 0 1 10.25 18h-.5A1.75 1.75 0 0 1 8 16.25v-5.5Zm7.75 2.75a.25.25 0 0 0-.25.25v2.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-2.5a.25.25 0 0 0-.25-.25h-.5Zm-1.75.25c0-.966.784-1.75 1.75-1.75h.5c.966 0 1.75.784 1.75 1.75v2.5A1.75 1.75 0 0 1 16.25 18h-.5A1.75 1.75 0 0 1 14 16.25v-2.5Z"
    />
  </svg>
)

export default SvgTrendingDown
