import {BaseQueryFn} from '@reduxjs/toolkit/dist/query'
import axios, {AxiosError, AxiosRequestConfig} from 'axios'

import {ErrorResponse} from '../../booking/types/ErrorResponse'

export const axiosBaseQuery =
  (
    {baseUrl}: {baseUrl: string} = {baseUrl: ''}
  ): BaseQueryFn<
    {
      url: string
      method?: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
      headers?: AxiosRequestConfig['headers']
    },
    unknown,
    {
      status?: number
      data: ErrorResponse
      headers: AxiosRequestConfig['headers']
    }
  > =>
  async ({url, method = 'GET', data, params, headers}) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers
      })
      return {data: result?.data, meta: {headers: result?.headers}}
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
          headers: err.response?.headers
        }
      }
    }
  }
