// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgHeartFilled = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="M9.995 4.192c-1.704-1.424-4.21-1.707-6.158-.113-2.127 1.741-2.446 4.696-.753 6.783a.745.745 0 0 0 .073.078l6.328 5.86a.75.75 0 0 0 1.02 0l6.328-5.86a.744.744 0 0 0 .073-.078c1.686-2.079 1.416-5.056-.761-6.789-1.97-1.568-4.448-1.305-6.15.119Zm-.56 1.54C8.21 4.36 6.246 4.049 4.788 5.24c-1.49 1.22-1.694 3.21-.57 4.637l5.778 5.35 5.778-5.35c1.131-1.435.942-3.433-.562-4.63-1.497-1.192-3.437-.88-4.657.486a.75.75 0 0 1-1.118 0Z" />
  </svg>
)

export default SvgHeartFilled
