import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import {AnimatePresence, motion} from 'framer-motion'
import {$Values} from 'utility-types'

import {scrollTo} from '@daedalus/core/src/_web/utils/browser'
import useWindowSize from '@daedalus/core/src/_web/utils/browser/hooks/useWindowSize'
import {hexToRGBAString} from '@daedalus/core/src/utils/string'

import {useDeviceLayout} from '../../../context/deviceLayout'
import {Portal} from '../helpers/Portal'
import useBodyScrollLock from './useBodyScrollLock'

export {BODY_SCROLL_LOCK_IGNORE} from './useBodyScrollLock'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'
import noop from 'lodash/noop'

import {usePreviousValue} from '@daedalus/core/src/utils/react/hooks/usePreviousValue'

import {cssTheme} from '../../../themes'
import {closeOnEscape} from '../../../utils/closeOnEscape'
import transitions from '../../../utils/framerTransitions'

export type AnimationType = $Values<typeof ANIMATION_TYPES>
type StatusType = $Values<typeof STATUSES>
interface Props {
  /** The body of the overlay */
  children: ReactNode
  /** Whether the overlay is open */
  isOpen?: boolean
  /** The styles for the overlay container */
  containerStyles?: React.CSSProperties
  /** The styles for the overlay content. It's a classname from Linaria. */
  customStyles?: string
  /** Whether to add a shade to the background */
  shadeBackground?: boolean
  /** Callback when user clicks on the background shadow */
  onShadeBackgroundClick?: () => void
  /** The style of animation for opening and closing */
  animationType?: AnimationType
  /** Whether the overlay should call the onClose callback when the Esc key is pressed */
  closeOnEsc?: boolean
  /** The callback for closing the overlay. Optionally pass in the element that was selected to close. */
  onClose?: (e?: React.SyntheticEvent, element?: string) => void
  /** Enable body scroll lock  */
  enableBodyScrollLock?: boolean
  /**
   * Enable content background hack.
   * On iOS the viewport height lags after the navbar hiding on scroll, this means the content behind the overlay is shown while the navbar animates out
   * This is a bandaid solution, putting a white background under the overlay content that extends under the nav bar
   *
   * It's advisable to enable this for fullscreen overlays with a busy page underneath
   */
  enableContentBackgroundHack?: boolean
  /** How long the animation will last in milliseconds */
  transitionDuration?: number
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Pass through style to allow styles overrides */
  style?: React.CSSProperties
  /**
   *  By passing `animatePresenceInitial={false}`, `AnimatePresence` will disable any initial animations on children that are present when the component is first rendered.
   * **/
  animatePresenceInitial?: boolean
}

const STATUSES = {
  INITIAL: 'INITIAL',
  RENDERED: 'RENDERED',
  VISIBLE: 'VISIBLE',
  HIDDEN: 'HIDDEN'
} as const

export const ANIMATION_TYPES = {
  NONE: 'none',
  SLIDE_LEFT: 'slideLeft',
  SLIDE_RIGHT: 'slideRight',
  SLIDE_UP: 'slideUp',
  SLIDE_DOWN: 'slideDown',
  FADE_IN_OUT: 'overlayFade'
} as const

export const TRANSITION_DURATION = 300 // ms
const SCROLL_TOP_DURATION = 250 // ms
const INSTANT_TRANSITION_DURATION = 0 // ms

const OverlayContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 100;
  overflow: hidden; /* prevent scroll-locking issues in webviews */
`

export const OverlayContent = styled(motion.div)`
  background: ${cssTheme.colors.background.neutral.c000};
  height: 100%;
  position: relative;
  overflow: auto;
  z-index: 100;

  &.--shadow {
    box-shadow: ${cssTheme.shadows.action};
  }
`

export const OverlayBackground = styled(motion.div)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
  position: fixed;

  &.--shadow {
    background: ${hexToRGBAString(cssTheme.colors.overlay, 0.6)};
  }
`

const ContentBackgroundHack = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100vh; /* extends under the iOS navbar */
  background-color: ${cssTheme.colors.background.neutral.c000};
`
export interface OverlayContextValue {
  scrollToTop: () => void
}

export const OverlayContext = React.createContext<
  OverlayContextValue | undefined
>(undefined)

export const useOverlayHandlers = () => {
  const context = useContext(OverlayContext)
  if (context === undefined) {
    throw new Error('useOverlayHandlers must be within OverlayContext Provider')
  }

  return context
}

const FullHeightContainer = ({
  children,
  style,
  containerStyles,
  className
}: {
  children: ReactNode
  containerStyles: Props['containerStyles']
  className?: string
  style?: React.CSSProperties
}) => {
  // Use the true viewport-height since CSS 100% / 100vh etc. break on on iOS Chrome
  // There is a layout bug on iOS Chrome when opening a tab by clicking a link that causes the viewport extend under the navbar (interacting with the URL bar will fix the layout)
  // This bug does not show up on Browserstack. Test this flow with search overlays on a real device if any changes are made!
  const {isMobile} = useDeviceLayout()
  const {height} = useWindowSize()
  const additionalStyles = isMobile ? {height: `${height}px`} : {}

  return (
    <OverlayContainer
      style={{...style, ...containerStyles, ...additionalStyles}}
      className={className}
    >
      {children}
    </OverlayContainer>
  )
}

const Content = ({
  children,
  className,
  shadeBackground,
  enableBodyScrollLock,
  animationType,
  transitionDuration
}: {
  children: ReactNode
  className?: string // Pass through linaria styling
  shadeBackground: Props['shadeBackground']
  enableBodyScrollLock: Props['enableBodyScrollLock']
  animationType: AnimationType
  transitionDuration: number
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useBodyScrollLock({ref, isEnabled: enableBodyScrollLock})

  const scrollToTop = useCallback((duration = SCROLL_TOP_DURATION) => {
    if (ref.current) scrollTo(ref.current, 0, duration)
  }, [])

  const contextValue: OverlayContextValue = useMemo(() => ({scrollToTop}), [])

  if (animationType === ANIMATION_TYPES.NONE) {
    return (
      <OverlayContext.Provider value={contextValue}>
        <OverlayContent
          className={cx(className, shadeBackground && '--shadow')}
          ref={ref}
        >
          {children}
        </OverlayContent>
      </OverlayContext.Provider>
    )
  }

  const transitionFunc =
    transitions[animationType] || transitions[ANIMATION_TYPES.SLIDE_RIGHT] // or default animation type if provided one is incorrect

  return (
    <OverlayContext.Provider value={contextValue}>
      <OverlayContent
        key="OverlayContent"
        variants={transitionFunc}
        initial="exited"
        animate="entered"
        exit="exited"
        transition={{
          duration: transitionDuration / 1000
        }}
        ref={ref}
        className={cx(className, shadeBackground && '--shadow')}
      >
        {children}
      </OverlayContent>
    </OverlayContext.Provider>
  )
}

export const Overlay = ({
  transitionDuration = TRANSITION_DURATION,
  animationType = ANIMATION_TYPES.SLIDE_RIGHT,
  closeOnEsc = false,
  shadeBackground = true,
  enableBodyScrollLock = true,
  enableContentBackgroundHack = false,
  onShadeBackgroundClick = noop,
  isOpen,
  onClose,
  customStyles,
  children,
  containerStyles,
  className,
  style,
  animatePresenceInitial = false
}: Props) => {
  const isOpenPrevious = usePreviousValue(isOpen)
  const [status, setStatus] = useState<StatusType>(STATUSES.INITIAL)
  // eslint-disable-next-line no-undef
  const timeout = useRef<NodeJS.Timeout>()
  const isInstant = animationType === ANIMATION_TYPES.NONE
  const overrideTransitionDuration = isInstant
    ? INSTANT_TRANSITION_DURATION
    : transitionDuration
  useEffect(() => {
    if (closeOnEsc && onClose) {
      closeOnEscape({onClose})
    }
  }, [closeOnEsc, onClose])

  const openOverlay = () => {
    if (timeout.current) clearTimeout(timeout.current)

    setStatus(STATUSES.RENDERED)

    window.requestAnimationFrame(() => {
      setStatus(STATUSES.VISIBLE)
    })
  }

  const closeOverlay = () => {
    if (timeout.current) clearTimeout(timeout.current)

    setStatus(STATUSES.HIDDEN)

    // eslint-disable-next-line fp/no-mutation
    timeout.current = setTimeout(() => {
      setStatus(STATUSES.INITIAL)
    }, overrideTransitionDuration)
  }

  useEffect(() => {
    const shouldOpenOverlay =
      status === STATUSES.INITIAL && !isOpenPrevious && isOpen
    const shouldCloseOverlay = isOpenPrevious && !isOpen

    if (shouldOpenOverlay) openOverlay()
    if (shouldCloseOverlay) closeOverlay()
  }, [status, timeout, isOpen, isOpenPrevious])

  const backgroundTransition = transitions.overlayFade

  if (status === STATUSES.INITIAL) return null

  return (
    <Portal>
      <AnimatePresence initial={animatePresenceInitial}>
        {status === STATUSES.VISIBLE && (
          <>
            {enableContentBackgroundHack && (
              <ContentBackgroundHack
                key="OverlayBackgroundHack"
                initial="exited"
                animate="entered"
                exit="exited"
                variants={backgroundTransition}
              />
            )}
            <FullHeightContainer
              containerStyles={containerStyles}
              className={className}
              style={style}
            >
              {shadeBackground && (
                <OverlayBackground
                  key="OverlayBackground"
                  variants={backgroundTransition}
                  initial="exited"
                  animate="entered"
                  exit="exited"
                  onClick={onShadeBackgroundClick}
                  className={cx(shadeBackground && '--shadow')}
                />
              )}
              <Content
                className={customStyles}
                shadeBackground={shadeBackground}
                enableBodyScrollLock={enableBodyScrollLock}
                animationType={animationType}
                transitionDuration={overrideTransitionDuration}
              >
                {children}
              </Content>
            </FullHeightContainer>
          </>
        )}
      </AnimatePresence>
    </Portal>
  )
}
