// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCamera = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.051 3.077A1.75 1.75 0 0 1 7.667 2h4.666a1.75 1.75 0 0 1 1.616 1.077l-.693.288.693-.288.737 1.77a.25.25 0 0 0 .23.153h.334A2.75 2.75 0 0 1 18 7.75v7.5A2.75 2.75 0 0 1 15.25 18H4.75A2.75 2.75 0 0 1 2 15.25v-7.5A2.75 2.75 0 0 1 4.75 5h.333c.101 0 .192-.06.231-.154l.737-1.77Zm1.616.423a.25.25 0 0 0-.231.154l-.737 1.77A1.75 1.75 0 0 1 5.083 6.5H4.75c-.69 0-1.25.56-1.25 1.25v7.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-7.5c0-.69-.56-1.25-1.25-1.25h-.333A1.75 1.75 0 0 1 13.3 5.423l-.737-1.77a.25.25 0 0 0-.23-.153H7.666ZM10 8.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM6 11a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z"
    />
  </svg>
)

export default SvgCamera
