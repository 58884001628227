import {
  AvailableCountriesType,
  AvailableCountryType,
  CountryType
} from '../types/Country'

/* eslint-disable max-len */
// prettier-ignore
export const availableCountries: AvailableCountriesType = {
  AF: { code: 'AF', name: 'Afghanistan', currencyCode: 'AFN', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  AL: { code: 'AL', name: 'Albania', currencyCode: 'ALL', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  AX: { code: 'AX', name: 'Åland Islands', currencyCode: 'EUR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  DZ: { code: 'DZ', name: 'Algeria', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  AS: { code: 'AS', name: 'American Samoa', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  AD: { code: 'AD', name: 'Andorra', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  AO: { code: 'AO', name: 'Angola', currencyCode: 'AOA', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  AI: { code: 'AI', name: 'Anguilla', currencyCode: 'XCD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  AQ: { code: 'AQ', name: 'Antarctica', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  AG: { code: 'AG', name: 'Antigua And Barbuda', currencyCode: 'XCD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  AR: { code: 'AR', name: 'Argentina', currencyCode: 'ARS', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  AM: { code: 'AM', name: 'Armenia', currencyCode: 'AMD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  AW: { code: 'AW', name: 'Aruba', currencyCode: 'AWG', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  AU: { code: 'AU', name: 'Australia', currencyCode: 'AUD', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  AT: { code: 'AT', name: 'Austria', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  AZ: { code: 'AZ', name: 'Azerbaijan', currencyCode: 'AZN', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  BS: { code: 'BS', name: 'Bahamas', currencyCode: 'BSD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  BH: { code: 'BH', name: 'Bahrain', currencyCode: 'BHD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  BD: { code: 'BD', name: 'Bangladesh', currencyCode: 'BDT', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  BB: { code: 'BB', name: 'Barbados', currencyCode: 'BBD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  BY: { code: 'BY', name: 'Belarus', currencyCode: 'BYR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  BE: { code: 'BE', name: 'Belgium', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  BZ: { code: 'BZ', name: 'Belize', currencyCode: 'BZD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  BJ: { code: 'BJ', name: 'Benin', currencyCode: 'XOF', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  BM: { code: 'BM', name: 'Bermuda', currencyCode: 'BMD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  BT: { code: 'BT', name: 'Bhutan', currencyCode: 'BTN', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  BO: { code: 'BO', name: 'Bolivia', currencyCode: 'BOB', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  BQ: { code: 'BQ', name: 'Bonaire, Saint Eustatius and Saba', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  BA: { code: 'BA', name: 'Bosnia And Herzegovina', currencyCode: 'BAM', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  BW: { code: 'BW', name: 'Botswana', currencyCode: 'BWP', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  BR: { code: 'BR', name: 'Brazil', currencyCode: 'BRL', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  IO: { code: 'IO', name: 'British Indian Ocean Territory', currencyCode: 'GBP', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  BN: { code: 'BN', name: 'Brunei', currencyCode: 'BND', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  BG: { code: 'BG', name: 'Bulgaria', currencyCode: 'BGN', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  BF: { code: 'BF', name: 'Burkina Faso', currencyCode: 'XOF', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  BI: { code: 'BI', name: 'Burundi', currencyCode: 'BIF', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  KH: { code: 'KH', name: 'Cambodia', currencyCode: 'KHR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  CM: { code: 'CM', name: 'Cameroon', currencyCode: 'XAF', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  CA: { code: 'CA', name: 'Canada', currencyCode: 'CAD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  CV: { code: 'CV', name: 'Cape Verde', currencyCode: 'CVE', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  KY: { code: 'KY', name: 'Cayman Islands', currencyCode: 'KYD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  CF: { code: 'CF', name: 'Central African Republic', currencyCode: 'XAF', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  TD: { code: 'TD', name: 'Chad', currencyCode: 'XAF', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  CL: { code: 'CL', name: 'Chile', currencyCode: 'CLP', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  CN: { code: 'CN', name: 'China', currencyCode: 'CNY', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  CX: { code: 'CX', name: 'Christmas Island', currencyCode: 'AUD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  CC: { code: 'CC', name: 'Cocos (Keeling) Islands', currencyCode: 'AUD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  CO: { code: 'CO', name: 'Colombia', currencyCode: 'COP', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  KM: { code: 'KM', name: 'Comoros', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  CG: { code: 'CG', name: 'Congo', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  CK: { code: 'CK', name: 'Cook Islands', currencyCode: 'NZD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  CR: { code: 'CR', name: 'Costa Rica', currencyCode: 'CRC', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  HR: { code: 'HR', name: 'Croatia', currencyCode: 'HRK', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  CU: { code: 'CU', name: 'Cuba', currencyCode: 'CUP', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  CW: { code: 'CW', name: 'Curaçao', currencyCode: 'ANG', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  CY: { code: 'CY', name: 'Cyprus', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  CZ: { code: 'CZ', name: 'Czech Republic', currencyCode: 'CZK', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  CI: { code: 'CI', name: "Côte d'Ivoire", currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  CD: { code: 'CD', name: 'Democratic Republic of the Congo', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  DK: { code: 'DK', name: 'Denmark', currencyCode: 'DKK', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  DJ: { code: 'DJ', name: 'Djibouti', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  DM: { code: 'DM', name: 'Dominica', currencyCode: 'XCD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  DO: { code: 'DO', name: 'Dominican Republic', currencyCode: 'DOP', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  EC: { code: 'EC', name: 'Ecuador', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  EG: { code: 'EG', name: 'Egypt', currencyCode: 'EGP', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  SV: { code: 'SV', name: 'El Salvador', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  GQ: { code: 'GQ', name: 'Equatorial Guinea', currencyCode: 'XAF', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  ER: { code: 'ER', name: 'Eritrea', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  EE: { code: 'EE', name: 'Estonia', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  ET: { code: 'ET', name: 'Ethiopia', currencyCode: 'ETB', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  FK: { code: 'FK', name: 'Falkland Islands', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  FO: { code: 'FO', name: 'Faroe Islands', currencyCode: 'DKK', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  FM: { code: 'FM', name: 'Federated States Of Micronesia', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  FJ: { code: 'FJ', name: 'Fiji', currencyCode: 'FJD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  FI: { code: 'FI', name: 'Finland', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  FR: { code: 'FR', name: 'France', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  GF: { code: 'GF', name: 'French Guiana', currencyCode: 'EUR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  PF: { code: 'PF', name: 'French Polynesia', currencyCode: 'XPF', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  TF: { code: 'TF', name: 'French Southern and Antarctic Lands', currencyCode: 'EUR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  GA: { code: 'GA', name: 'Gabon', currencyCode: 'XAF', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  GM: { code: 'GM', name: 'Gambia', currencyCode: 'GMD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  GE: { code: 'GE', name: 'Georgia', currencyCode: 'GEL', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  DE: { code: 'DE', name: 'Germany', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  GH: { code: 'GH', name: 'Ghana', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  GI: { code: 'GI', name: 'Gibraltar', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  GR: { code: 'GR', name: 'Greece', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  GL: { code: 'GL', name: 'Greenland', currencyCode: 'DKK', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  GD: { code: 'GD', name: 'Grenada', currencyCode: 'XCD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  GP: { code: 'GP', name: 'Guadeloupe', currencyCode: 'EUR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  GU: { code: 'GU', name: 'Guam', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  GT: { code: 'GT', name: 'Guatemala', currencyCode: 'GTQ', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  GG: { code: 'GG', name: 'Guernsey', currencyCode: 'GBP', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  GN: { code: 'GN', name: 'Guinea', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  GW: { code: 'GW', name: 'Guinea Bissau', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  GY: { code: 'GY', name: 'Guyana', currencyCode: 'GYD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  HT: { code: 'HT', name: 'Haiti', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  HN: { code: 'HN', name: 'Honduras', currencyCode: 'HNL', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  HK: { code: 'HK', name: 'Hong Kong', currencyCode: 'HKD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  HU: { code: 'HU', name: 'Hungary', currencyCode: 'HUF', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  IS: { code: 'IS', name: 'Iceland', currencyCode: 'ISK', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  IN: { code: 'IN', name: 'India', currencyCode: 'INR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  ID: { code: 'ID', name: 'Indonesia', currencyCode: 'IDR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  IR: { code: 'IR', name: 'Iran', currencyCode: 'IRR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  IQ: { code: 'IQ', name: 'Iraq', currencyCode: 'IQD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  IE: { code: 'IE', name: 'Ireland', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  IM: { code: 'IM', name: 'Isle of Man', currencyCode: 'GBP', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  IL: { code: 'IL', name: 'Israel', currencyCode: 'ILS', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  IT: { code: 'IT', name: 'Italy', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  JM: { code: 'JM', name: 'Jamaica', currencyCode: 'JMD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  JP: { code: 'JP', name: 'Japan', currencyCode: 'JPY', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  JE: { code: 'JE', name: 'Jersey', currencyCode: 'GBP', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  JO: { code: 'JO', name: 'Jordan', currencyCode: 'JOD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  KZ: { code: 'KZ', name: 'Kazakhstan', currencyCode: 'KZT', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  KE: { code: 'KE', name: 'Kenya', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  KI: { code: 'KI', name: 'Kiribati', currencyCode: 'AUD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  KW: { code: 'KW', name: 'Kuwait', currencyCode: 'KWD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  KG: { code: 'KG', name: 'Kyrgyzstan', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  LA: { code: 'LA', name: 'Laos', currencyCode: 'LAK', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  LV: { code: 'LV', name: 'Latvia', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  LB: { code: 'LB', name: 'Lebanon', currencyCode: 'LBP', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  LS: { code: 'LS', name: 'Lesotho', currencyCode: 'LSL', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  LR: { code: 'LR', name: 'Liberia', currencyCode: 'LRD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'imperial' },
  LY: { code: 'LY', name: 'Libya', currencyCode: 'LYD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  LI: { code: 'LI', name: 'Liechtenstein', currencyCode: 'CHF', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  LT: { code: 'LT', name: 'Lithuania', currencyCode: 'EUR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  LU: { code: 'LU', name: 'Luxembourg', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  MO: { code: 'MO', name: 'Macau', currencyCode: 'MOP', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  MK: { code: 'MK', name: 'Macedonia', currencyCode: 'MKD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  MG: { code: 'MG', name: 'Madagascar', currencyCode: 'MGA', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  MW: { code: 'MW', name: 'Malawi', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  MY: { code: 'MY', name: 'Malaysia', currencyCode: 'MYR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  MV: { code: 'MV', name: 'Maldives', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  ML: { code: 'ML', name: 'Mali', currencyCode: 'XOF', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  MT: { code: 'MT', name: 'Malta', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  MH: { code: 'MH', name: 'Marshall Islands', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  MQ: { code: 'MQ', name: 'Martinique', currencyCode: 'EUR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  MR: { code: 'MR', name: 'Mauritania', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  MU: { code: 'MU', name: 'Mauritius', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  YT: { code: 'YT', name: 'Mayotte', currencyCode: 'EUR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  MX: { code: 'MX', name: 'Mexico', currencyCode: 'MXN', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  MD: { code: 'MD', name: 'Moldova', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  MC: { code: 'MC', name: 'Monaco', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  MN: { code: 'MN', name: 'Mongolia', currencyCode: 'MNT', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  ME: { code: 'ME', name: 'Montenegro', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  MS: { code: 'MS', name: 'Montserrat', currencyCode: 'XCD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  MA: { code: 'MA', name: 'Morocco', currencyCode: 'MAD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  MZ: { code: 'MZ', name: 'Mozambique', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  MM: { code: 'MM', name: 'Myanmar', currencyCode: 'MMK', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'imperial' },
  NA: { code: 'NA', name: 'Namibia', currencyCode: 'NAD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  NR: { code: 'NR', name: 'Nauru', currencyCode: 'AUD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  NP: { code: 'NP', name: 'Nepal', currencyCode: 'NPR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  NL: { code: 'NL', name: 'Netherlands', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  NC: { code: 'NC', name: 'New Caledonia', currencyCode: 'XPF', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  NZ: { code: 'NZ', name: 'New Zealand', currencyCode: 'NZD', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  NI: { code: 'NI', name: 'Nicaragua', currencyCode: 'NIO', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  NE: { code: 'NE', name: 'Niger', currencyCode: 'XOF', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  NG: { code: 'NG', name: 'Nigeria', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  NU: { code: 'NU', name: 'Niue', currencyCode: 'NZD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  NF: { code: 'NF', name: 'Norfolk Island', currencyCode: 'AUD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  KP: { code: 'KP', name: 'North Korea', currencyCode: 'KPW', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  MP: { code: 'MP', name: 'Northern Mariana Islands', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  NO: { code: 'NO', name: 'Norway', currencyCode: 'NOK', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  OM: { code: 'OM', name: 'Oman', currencyCode: 'OMR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  PK: { code: 'PK', name: 'Pakistan', currencyCode: 'PKR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  PW: { code: 'PW', name: 'Palau', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  PS: { code: 'PS', name: 'Palestinian Territory', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  PA: { code: 'PA', name: 'Panama', currencyCode: 'PAB', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  PG: { code: 'PG', name: 'Papua New Guinea', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  PY: { code: 'PY', name: 'Paraguay', currencyCode: 'PYG', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  PE: { code: 'PE', name: 'Peru', currencyCode: 'PEN', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  PH: { code: 'PH', name: 'Philippines', currencyCode: 'PHP', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  PN: { code: 'PN', name: 'Pitcairn Islands', currencyCode: 'NZD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  PL: { code: 'PL', name: 'Poland', currencyCode: 'PLN', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  PT: { code: 'PT', name: 'Portugal', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  PR: { code: 'PR', name: 'Puerto Rico', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  QA: { code: 'QA', name: 'Qatar', currencyCode: 'QAR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  RE: { code: 'RE', name: 'Reunion', currencyCode: 'EUR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  RO: { code: 'RO', name: 'Romania', currencyCode: 'RON', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  RU: { code: 'RU', name: 'Russia', currencyCode: 'RUB', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  RW: { code: 'RW', name: 'Rwanda', currencyCode: 'RWF', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  BL: { code: 'BL', name: 'Saint Barthelemy', currencyCode: 'EUR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  SH: { code: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha', currencyCode: 'SHP', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  KN: { code: 'KN', name: 'Saint Kitts And Nevis', currencyCode: 'XCD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  LC: { code: 'LC', name: 'Saint Lucia', currencyCode: 'XCD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  MF: { code: 'MF', name: 'Saint Martin', currencyCode: 'EUR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  PM: { code: 'PM', name: 'Saint Pierre and Miquelon', currencyCode: 'EUR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  VC: { code: 'VC', name: 'Saint Vincent And The Grenadines', currencyCode: 'XCD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  WS: { code: 'WS', name: 'Samoa', currencyCode: 'WST', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  SM: { code: 'SM', name: 'San Marino', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  ST: { code: 'ST', name: 'Sao Tome And Principe', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  SA: { code: 'SA', name: 'Saudi Arabia', currencyCode: 'SAR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  SN: { code: 'SN', name: 'Senegal', currencyCode: 'XOF', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  RS: { code: 'RS', name: 'Serbia', currencyCode: 'RSD', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  SC: { code: 'SC', name: 'Seychelles', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  SL: { code: 'SL', name: 'Sierra Leone', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  SG: { code: 'SG', name: 'Singapore', currencyCode: 'SGD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  SX: { code: 'SX', name: 'Sint Maarten', currencyCode: 'ANG', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  SK: { code: 'SK', name: 'Slovakia', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  SI: { code: 'SI', name: 'Slovenia', currencyCode: 'EUR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  SB: { code: 'SB', name: 'Solomon Islands', currencyCode: 'SBD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  SO: { code: 'SO', name: 'Somalia', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  ZA: { code: 'ZA', name: 'South Africa', currencyCode: 'ZAR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  GS: { code: 'GS', name: 'South Georgia and the South Sandwich Islands', currencyCode: 'GBP', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  KR: { code: 'KR', name: 'South Korea', currencyCode: 'KRW', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  SS: { code: 'SS', name: 'South Sudan', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  ES: { code: 'ES', name: 'Spain', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  LK: { code: 'LK', name: 'Sri Lanka', currencyCode: 'LKR', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  SD: { code: 'SD', name: 'Sudan', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  SR: { code: 'SR', name: 'Suriname', currencyCode: 'SRD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  SJ: { code: 'SJ', name: 'Svalbard and Jan Mayen', currencyCode: 'NOK', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  SZ: { code: 'SZ', name: 'Swaziland', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  SE: { code: 'SE', name: 'Sweden', currencyCode: 'SEK', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  CH: { code: 'CH', name: 'Switzerland', currencyCode: 'CHF', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  SY: { code: 'SY', name: 'Syria', currencyCode: 'SYP', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  TW: { code: 'TW', name: 'Taiwan', currencyCode: 'TWD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  TJ: { code: 'TJ', name: 'Tajikistan', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  TZ: { code: 'TZ', name: 'Tanzania', currencyCode: 'TZS', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  TH: { code: 'TH', name: 'Thailand', currencyCode: 'THB', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  TL: { code: 'TL', name: 'Timor-Leste', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  TG: { code: 'TG', name: 'Togo', currencyCode: 'XOF', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  TK: { code: 'TK', name: 'Tokelau', currencyCode: 'NZD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  TO: { code: 'TO', name: 'Tonga', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  TT: { code: 'TT', name: 'Trinidad And Tobago', currencyCode: 'TTD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  TN: { code: 'TN', name: 'Tunisia', currencyCode: 'TND', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  TR: { code: 'TR', name: 'Turkey', currencyCode: 'TRY', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  TM: { code: 'TM', name: 'Turkmenistan', currencyCode: 'TMM', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  TC: { code: 'TC', name: 'Turks and Caicos Islands', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  TV: { code: 'TV', name: 'Tuvalu', currencyCode: 'AUD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  UG: { code: 'UG', name: 'Uganda', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  UA: { code: 'UA', name: 'Ukraine', currencyCode: 'UAH', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  AE: { code: 'AE', name: 'United Arab Emirates', currencyCode: 'AED', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  GB: { code: 'GB', name: 'United Kingdom', currencyCode: 'GBP', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'imperial' },
  US: { code: 'US', name: 'United States', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'imperial' },
  UM: { code: 'UM', name: 'United States Minor Outlying Islands', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  UY: { code: 'UY', name: 'Uruguay', currencyCode: 'UYU', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  UZ: { code: 'UZ', name: 'Uzbekistan', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  VU: { code: 'VU', name: 'Vanuatu', currencyCode: 'VUV', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  VA: { code: 'VA', name: 'Vatican City', currencyCode: 'EUR', includeTaxes: true, includeLocalTaxes: true, measurementSystem: 'metric' },
  VE: { code: 'VE', name: 'Venezuela', currencyCode: 'VEF', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  VN: { code: 'VN', name: 'Vietnam', currencyCode: 'VND', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  VG: { code: 'VG', name: 'Virgin Islands, British', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  VI: { code: 'VI', name: 'Virgin Islands, U.S.', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  WF: { code: 'WF', name: 'Wallis and Futuna', currencyCode: 'XPF', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  EH: { code: 'EH', name: 'Western Sahara', currencyCode: 'MAD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  YE: { code: 'YE', name: 'Yemen', currencyCode: 'YER', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  ZM: { code: 'ZM', name: 'Zambia', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' },
  ZW: { code: 'ZW', name: 'Zimbabwe', currencyCode: 'USD', includeTaxes: false, includeLocalTaxes: false, measurementSystem: 'metric' }
};

/* eslint-enable */
export const defaultCountry = availableCountries.US

export const getTaxDisplayLogic = (countryCode: string) => {
  return availableCountries[countryCode] || defaultCountry
}

export const getCountryAttribute = <
  Attribute extends keyof AvailableCountryType
>(
  countryCode: CountryType['code'],
  attribute: Attribute
) => {
  if (availableCountries[countryCode]) {
    return availableCountries[countryCode][attribute]
  }

  return defaultCountry[attribute]
}
