export const maskSensitiveData = (
  payload: Record<string, string>,
  keys: string[]
) => {
  const payloadMasked = {...payload}

  Object.keys(payloadMasked).forEach(key => {
    if (keys.includes(key)) {
      payloadMasked[key] = '*'
    }
  })

  return payloadMasked
}
