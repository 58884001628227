import {getMappedLocale, getMappedLocaleOrDefault} from './locale'

/**
 * Returns the mapped language for the specified locale.
 * By default, returns the specified locale as the language.
 *
 * @returns The mapped language
 * @param locale The locale whose language should be returned
 */
export const localeLanguageMapper = (locale: string): string =>
  getMappedLocaleOrDefault(locale, {
    en: 'en',
    'zh-CN': 'zh',
    iw: 'he',
    nb: 'no',
    nn: 'no'
  })

/**
 * Returns the mapped `react-intl` locale for the specified locale.
 * By default, returns the specified locale as the `react-intl` locale.
 *
 * @returns The mapped `react-intl` locale
 * @param locale The locale whose `react-intl` locale should be returned
 */
export const reactIntlLocaleMapper = (locale: string): string =>
  getMappedLocale(locale, {
    'en-GB': 'en',
    'pt-BR': 'pt',
    'zh-CN': 'zh',
    'zh-HK': 'zh',
    'zh-TW': 'zh',
    no: 'nb'
  })

/**
 * Returns the mapped Latin number system if the specified locale is Arabic
 * and the implementation of plural rules is native (not polyfilled).
 * By default, returns the specified locale.
 * Will affect formatted numbers in "ar" locale on Safari:
 * https://github.com/yahoo/react-intl/pull/1093#issuecomment-367506127
 *
 * @returns The mapped Latin number system
 * @param locale The locale whose Latin number system should be returned
 */
export const mapArabicToLatinNumberSystem = (locale: string): string => {
  // @ts-expect-error - Property 'polyfilled' does not exist in Intl.PluralRules type
  if (Intl.PluralRules && !Intl.PluralRules.polyfilled) {
    return locale === 'ar' ? 'ar-u-nu-latn' : locale
  }

  return locale
}
