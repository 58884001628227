// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgBath = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 4.75c0-.69.56-1.25 1.25-1.25H8.5v.75a.75.75 0 0 0 1.5 0v-1.5A.75.75 0 0 0 9.25 2h-2.5A2.75 2.75 0 0 0 4 4.75V9H2.75a.75.75 0 0 0-.75.75v1.5c0 1.616.667 3.076 1.74 4.12l-.68 1.585a.75.75 0 1 0 1.38.59l.538-1.256A5.724 5.724 0 0 0 7.75 17h4.5c1.005 0 1.95-.258 2.772-.711l.539 1.256a.75.75 0 0 0 1.378-.59l-.678-1.584A5.733 5.733 0 0 0 18 11.25v-1.5a.75.75 0 0 0-.75-.75H5.5V4.75Zm-2 6.5v-.75h13v.75a4.25 4.25 0 0 1-4.25 4.25h-4.5a4.25 4.25 0 0 1-4.25-4.25Z"
    />
  </svg>
)

export default SvgBath
