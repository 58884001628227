// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgSadFaceWithBorder = ({
  color = 'currentColor',
  size = 20
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.8 7.45A6.65 6.65 0 0 1 7.45.8h.7a1.05 1.05 0 1 1 0 2.1h-.7A4.55 4.55 0 0 0 2.9 7.45v.7a1.05 1.05 0 1 1-2.1 0v-.7Zm1.05 7.35c.58 0 1.05.47 1.05 1.05v.7a4.55 4.55 0 0 0 4.55 4.55h.7a1.05 1.05 0 1 1 0 2.1h-.7A6.65 6.65 0 0 1 .8 16.55v-.7c0-.58.47-1.05 1.05-1.05Zm14-14a1.05 1.05 0 1 0 0 2.1h.7a4.55 4.55 0 0 1 4.55 4.55v.7a1.05 1.05 0 1 0 2.1 0v-.7A6.65 6.65 0 0 0 16.55.8h-.7Zm6.3 14c.58 0 1.05.47 1.05 1.05v.7a6.65 6.65 0 0 1-6.65 6.65h-.7a1.05 1.05 0 1 1 0-2.1h.7a4.55 4.55 0 0 0 4.55-4.55v-.7c0-.58.47-1.05 1.05-1.05ZM9.2 7.8a1.4 1.4 0 1 0 0 2.8 1.4 1.4 0 0 0 0-2.8Zm4.2 1.4a1.4 1.4 0 1 1 2.8 0 1.4 1.4 0 0 1-2.8 0Zm-6.123 8.386a1.05 1.05 0 0 0 1.2-.819.573.573 0 0 1 .032-.075c.042-.084.134-.231.324-.395.371-.318 1.24-.797 3.167-.797 1.926 0 2.796.48 3.167.797.19.164.282.311.324.395a.583.583 0 0 1 .032.075 1.05 1.05 0 0 0 2.063-.39l-1.036.173a52.56 52.56 0 0 0 1.035-.174v-.007l-.002-.009-.004-.02a1.607 1.607 0 0 0-.05-.19 2.64 2.64 0 0 0-.16-.397 3.382 3.382 0 0 0-.836-1.05C15.68 13.971 14.273 13.4 12 13.4c-2.274 0-3.68.57-4.533 1.303-.422.362-.68.739-.836 1.05a2.63 2.63 0 0 0-.199.535l-.01.051-.005.021-.001.01-.001.003v.003s0 .002 1.035.174l-1.036-.172a1.05 1.05 0 0 0 .863 1.208Z"
    />
  </svg>
)

export default SvgSadFaceWithBorder
