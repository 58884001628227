import React, {useCallback, useState} from 'react'
import styled from '@emotion/styled'

import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {
  MenuItemComponent,
  useTrackMenuItemClick
} from '@daedalus/core/src/analytics/hooks/useTrackMenuItemClick'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'
import {CurrencyType} from '@daedalus/core/src/localization/types/CurrencyType'

import {LanguageCurrencySelector} from '../../../../search/LanguageCurrencySelector'
import {IconWrapper, Label, WrapperSelector} from '../commonStyles'

const WrapperPriceDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

interface Props {
  /** List of currencies to be displayed */
  currenciesList: CurrencyType[]
  /** The currently selected value */
  currentCurrency: string
  /** The callback for when a currency is selected. The new currency code will be passed. */
  onChangeCurrency: (code: string) => void
  /** List of currencies to be highlighted and shown first. */
  popularCurrenciesList: CurrencyType[]
  /** The callback when currency input is clicked */
  onClickCurrency?: () => void
  /** The callback when user changed the price display setting */
  onChangePriceDisplay?: (showTotal: boolean) => void
  /** The price display setting status */
  showTotalPrices?: boolean
  /** To show the price display setting */
  showPriceDisplaySetting?: boolean
}

export const MobileCurrencySelector = ({
  currenciesList,
  currentCurrency,
  popularCurrenciesList,
  onChangeCurrency,
  onClickCurrency,
  onChangePriceDisplay,
  showTotalPrices,
  showPriceDisplaySetting
}: Props) => {
  const dispatchTeamEvent = useDispatchTeamEvent()

  const {trackMenuItemClick} = useTrackMenuItemClick()

  const [isCurrencySelectorOpen, setCurrencySelectorOpen] = useState(false)

  const handleCurrencyChange = (currency: string) => {
    onChangeCurrency(currency)
    setCurrencySelectorOpen(false)
  }

  const handleClick = () => {
    trackMenuItemClick(MenuItemComponent.Currency, Team.Retention)
    if (onClickCurrency) onClickCurrency()
    setCurrencySelectorOpen(true)
  }

  const handleClickSearchInput = useCallback(
    () =>
      dispatchTeamEvent(
        trackEvent({
          category: Category.User,
          entity: Entity.CurrencySelectorSearchInput,
          action: Action.Clicked
        })
      ),
    [dispatchTeamEvent]
  )

  const handleChangePriceDisplay = useCallback(
    (showTotal: boolean) => {
      onChangePriceDisplay?.(showTotal)
      setCurrencySelectorOpen(false)
    },
    [onChangePriceDisplay]
  )

  return (
    <>
      <WrapperSelector onClick={handleClick}>
        <Label>
          <IconWrapper>
            <Icon name="Money" />
          </IconWrapper>
          <Text variant="labelM">
            <FormattedMessageWrapper
              id="currencyLabel"
              defaultMessage="Currency"
            />
          </Text>
        </Label>
        {showPriceDisplaySetting ? (
          <WrapperPriceDetails>
            <Text variant="bodyS">
              <FormattedMessageWrapper
                id={showTotalPrices ? 'totalPrice' : 'nightlyPrice'}
                defaultMessage={
                  showTotalPrices ? 'Total price' : 'Nightly price'
                }
              />
            </Text>

            <Text variant="labelM">{currentCurrency?.toUpperCase()}</Text>
          </WrapperPriceDetails>
        ) : (
          <Text variant="labelM">{currentCurrency?.toUpperCase()}</Text>
        )}
      </WrapperSelector>
      <LanguageCurrencySelector
        isMobile
        initialSelector="currency"
        isOpen={isCurrencySelectorOpen}
        onClose={() => setCurrencySelectorOpen(false)}
        availableLanguages={[]}
        selectedLanguage=""
        onChangeLanguage={() => ({})}
        hasCurrency={true}
        availableCurrencies={[...popularCurrenciesList, ...currenciesList]}
        selectedCurrency={currentCurrency}
        onChangeCurrency={handleCurrencyChange}
        onSearchInputFocus={handleClickSearchInput}
        onChangePriceDisplay={handleChangePriceDisplay}
        showTotalPrices={showTotalPrices}
        showPriceDisplaySetting={showPriceDisplaySetting}
      />
    </>
  )
}
