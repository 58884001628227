// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgIphone = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 21 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4.75c0-.69.56-1.25 1.25-1.25h6.5c.69 0 1.25.56 1.25 1.25v10.5c0 .69-.56 1.25-1.25 1.25h-6.5c-.69 0-1.25-.56-1.25-1.25V4.75ZM7.25 2A2.75 2.75 0 0 0 4.5 4.75v10.5A2.75 2.75 0 0 0 7.25 18h6.5a2.75 2.75 0 0 0 2.75-2.75V4.75A2.75 2.75 0 0 0 13.75 2h-6.5Zm3 12a.75.75 0 1 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5Z"
    />
  </svg>
)

export default SvgIphone
