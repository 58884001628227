import {createSelector} from '@reduxjs/toolkit'
import {adaptRaaPriceToChargeablePrice} from 'business/adaptRaaPriceToChargeablePrice'
import decorateRaaResponseWithChargeablePrices from 'business/decorateRaaResponseWithChargeablePrices'
import {getDisplayTotalPrice} from 'business/price'
import getNumberOfNights from 'components/data/URLParams/mappers/getNumberOfNights'
import getRoomConfig from 'components/data/URLParams/mappers/getRoomConfig'
import {comparator, includes, pathOr, sort, take} from 'ramda'
import {RootState} from 'store'
import {urlParams} from 'utils/urlParams'

import {defaultCountry} from '@daedalus/core/src/localization/business/countries'
import {RateBreakdownType} from '@daedalus/core/src/offer/types/AugmentedOffer'
import {OfferPrice} from '@daedalus/core/src/offer/types/offer'
import {
  RaaAnchorPriceBreakdownType,
  RaaOfferWithPrices
} from '@daedalus/core/src/offer/types/Raa'

import {getTaxDisplayLogic} from '../rooms/selectors'
import {RAA_OFFERS_DONE, RAA_OFFERS_ERROR} from './actions/fetchRaaOffers'
import {FetchRaaOffersResponseType} from './reducer'

const BEST_OFFERS_COUNT = 2

const raaResponsePath = ['raaOffers', 'response']

const offerByTotalPrice = (numberOfRooms: number) =>
  comparator((a: RaaOfferWithPrices, b: RaaOfferWithPrices) => {
    return (
      Number(getDisplayTotalPrice(a.prices, numberOfRooms)) <
      Number(getDisplayTotalPrice(b.prices, numberOfRooms))
    )
  })

export const getAnchorPriceBreakDownFromRaa = (
  state: RootState
): RateBreakdownType | null =>
  pathOr(null, [...raaResponsePath, 'anchorPriceRateBreakdown'], state)

const getRaaResponseOffers = createSelector(
  (state: RootState) =>
    (state.raaOffers as FetchRaaOffersResponseType)?.response,
  response => response?.offers || []
)

export const getAdaptedRaaOffers = createSelector(
  getRaaResponseOffers,
  getTaxDisplayLogic,
  (offers, taxDisplayLogic) => {
    const numberOfNights = getNumberOfNights(urlParams())
    return decorateRaaResponseWithChargeablePrices(
      offers,
      taxDisplayLogic || defaultCountry,
      numberOfNights
    )
  }
)

export const getRaaAnchorPriceBreakdown = createSelector(
  getTaxDisplayLogic,
  getAnchorPriceBreakDownFromRaa,
  (taxDisplayLogic, anchorPriceRateBreakdown) => {
    const params = urlParams()
    const numberOfNights = getNumberOfNights(params)

    if (!anchorPriceRateBreakdown) return null

    return adaptRaaPriceToChargeablePrice(
      anchorPriceRateBreakdown,
      taxDisplayLogic || defaultCountry,
      params.currency,
      numberOfNights,
      1
    )
  }
)

export const getAnchorPriceNightlyTotal = (
  anchorPriceBreakDown?: RaaAnchorPriceBreakdownType | OfferPrice | null
) => anchorPriceBreakDown?.chargeable?.nightlyDisplayTotal ?? 0

export const getRaaLowestTotalPrice = (state: RootState) => {
  const {numberOfRooms} = getRoomConfig(urlParams())
  const sortedOffers = getRaaBestOffers(state)

  if (sortedOffers.length === 0) {
    return null
  }

  return getDisplayTotalPrice(sortedOffers[0].prices, numberOfRooms)
}

export const getRaaBestOffers = createSelector(
  getAdaptedRaaOffers,
  raaOffers => {
    const {numberOfRooms} = getRoomConfig(urlParams())

    const filteredOffers = raaOffers.filter(
      offer => offer.proxyProviderCode !== 'FHT'
    )

    return sort(
      offerByTotalPrice(numberOfRooms),
      take(BEST_OFFERS_COUNT, filteredOffers)
    )
  }
)

export const getIsRaaDone = (state: RootState) => {
  const moduleState = pathOr(null, ['raaOffers', 'moduleState'], state)

  return includes(moduleState, [RAA_OFFERS_DONE, RAA_OFFERS_ERROR])
}
