import {dissocPath, pick, when} from 'ramda'
import {AnyAction, Middleware} from 'redux'
import createSentryMiddleware from 'redux-sentry-middleware'

import {State} from '../selectors'

declare let Sentry: typeof import('@sentry/browser')

const middlewareList = [] as Middleware[]
export const removeUserProfile = (state: State) =>
  dissocPath(['user', 'profile'], state)

export const removeUserData = (action: AnyAction) => {
  const regex = /USER_/g
  const hasUserActionType = (action: AnyAction) => action?.type?.match(regex)

  return when(hasUserActionType, pick(['type']))(action)
}

const middlewareFinalList =
  typeof Sentry === 'undefined'
    ? middlewareList
    : [
        ...middlewareList,
        createSentryMiddleware(Sentry as any, {
          stateTransformer: state => removeUserProfile(state as State),
          actionTransformer: action => removeUserData(action)
        })
      ]

export default middlewareFinalList
