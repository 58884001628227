import {Params} from '../getConfig'

export const amplifyVioProduction: Params = {
  aws_user_pools_id: 'eu-west-1_lNHHlN2Fh',
  aws_cognito_identity_pool_id:
    'eu-west-1:2e99882d-4888-462a-9b3b-8f403b44fa72',
  aws_user_pools_web_client_id: '5jlm1jdbf455jb97fnujaniipp',
  oauth_domain: 'production.authentication.vio.com',
  aws_user_files_s3_bucket: 'findhotel-profile-pictures-prod'
}
