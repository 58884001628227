import {createSelector} from '@reduxjs/toolkit'
import {urlParams} from 'utils/urlParams'

import {
  selectIsEmployee,
  selectIsMemberPlus
} from '@daedalus/core/src/auth/modules/selectors'
import {getCugDealsParameter} from '@daedalus/core/src/offer/business/getCugDealsParameter'

export const getCugDeals = createSelector(
  [selectIsMemberPlus, selectIsEmployee],
  (isMemberPlus, isEmployee) => {
    const params = urlParams()
    return getCugDealsParameter({isMemberPlus, isEmployee, urlParams: params})
  }
)
