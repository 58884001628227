// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgWarningTriangle = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.12 4.193c-.46-.92-1.773-.92-2.234 0L3.623 14.69A1.25 1.25 0 0 0 4.74 16.5h10.521a1.25 1.25 0 0 0 1.118-1.81L11.12 4.193ZM7.546 3.52c1.014-2.023 3.903-2.023 4.917.001l5.258 10.497c.916 1.829-.413 3.982-2.459 3.982H4.741c-2.047 0-3.376-2.154-2.46-3.983L7.546 3.52ZM10 7a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1Zm0 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
    />
  </svg>
)

export default SvgWarningTriangle
