import React, {MouseEvent, SyntheticEvent} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {toggle} from 'opticks'

import {Button, ButtonSize} from '@daedalus/atlas/Button'
import {Flag} from '@daedalus/atlas/Flag'
import {getCountryCodeFromLanguage} from '@daedalus/core/src/localization/services/country'

import {SelectedElement} from '..'

interface Props {
  currency: string
  language: string
  hasCurrency?: boolean
  hasLanguage?: boolean
  onClick: (element: SelectedElement) => void
}

const CurrencyWrapper = styled.span<{hasLanguage: boolean}>(
  ({theme, hasLanguage}) => css`
    ${hasLanguage && `margin-left: ${theme.layout.spacing.s300}px;`}
  `
)

const LanguageWrapper = styled.span`
  display: flex;
`

export const PreferencesLink = ({
  currency,
  language,
  hasLanguage = true,
  hasCurrency = true,
  onClick
}: Props) => {
  const countryCode = getCountryCodeFromLanguage(language)

  const handleClicked = (
    event: MouseEvent | SyntheticEvent,
    element: SelectedElement
  ) => {
    event.stopPropagation()
    onClick(element)
  }

  return (
    <Button
      dataId="PreferencesSelectorButton"
      size={toggle<ButtonSize[]>('a707ecbb-home-page-redesign--v3', 'lg', 'md')}
      variant="quiet"
      onClick={event =>
        handleClicked(event, hasLanguage ? 'language' : 'currency')
      }
      iconStart={
        <>
          {hasLanguage && (
            <LanguageWrapper
              data-id="language"
              data-language={language}
              onClick={event => handleClicked(event, 'language')}
            >
              <Flag countryCode={countryCode} />
            </LanguageWrapper>
          )}
          {hasCurrency && (
            <CurrencyWrapper
              hasLanguage={hasLanguage}
              data-id="currency"
              onClick={event => handleClicked(event, 'currency')}
            >
              {currency}
            </CurrencyWrapper>
          )}
        </>
      }
    />
  )
}
