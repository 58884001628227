// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPdUnlocked = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.843 2.228c2.077-.844 4.668.73 5.786 3.515l-1.888.767c-.796-1.732-2.157-2.79-3.138-2.39-1.039.421-1.257 2.316-.488 4.23l.187.466 5.894-.833a1.353 1.353 0 0 1 1.527 1.157l.107.767c.027.079.046.16.058.246l.072.682.484 3.467c.013.091.016.18.011.269l.154.744a1.359 1.359 0 0 1-1.15 1.536l-8.033 1.136A1.353 1.353 0 0 1 4.9 16.829l-.107-.767a1.377 1.377 0 0 1-.057-.246l-.722-5.162a1.359 1.359 0 0 1 1.15-1.536l.07-.01-.124-.308c-1.12-2.786-.343-5.728 1.734-6.572Zm1.818 9.97.232.58c.116.144.267.258.439.328l.196 1.401a.541.541 0 1 0 1.071-.151l-.195-1.402a1.091 1.091 0 0 0 .404-1.008 1.083 1.083 0 0 0-1.221-.926 1.086 1.086 0 0 0-.926 1.178Zm7.32-9.02a.395.395 0 0 0-.558-.044l-1.346 1.159a.4.4 0 0 0-.043.562.395.395 0 0 0 .558.043l1.346-1.158a.4.4 0 0 0 .043-.562Zm-1.19 2.543 1.741-.322a.398.398 0 0 1 .143.784l-1.741.322a.398.398 0 0 1-.143-.784Z"
    />
  </svg>
)

export default SvgPdUnlocked
