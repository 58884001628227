// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCaratUp = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path d="M3.122 13.024c-.834.973-.142 2.476 1.14 2.476h11.477c1.281 0 1.973-1.503 1.139-2.476l-5.74-6.695a1.5 1.5 0 0 0-2.277 0l-5.739 6.695Z" />
  </svg>
)

export default SvgCaratUp
