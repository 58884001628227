// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPlaceholder = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    fill={color}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <rect x={3} y={3} width={18} height={18} rx={3} />
  </svg>
)

export default SvgPlaceholder
