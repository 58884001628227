// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPencilBook = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 3.75C2 2.784 2.784 2 3.75 2h6.5a.75.75 0 0 1 0 1.5H6v13h5.25a.75.75 0 0 1 0 1.5h-7.5A1.75 1.75 0 0 1 2 16.25V3.75ZM4.5 16.5v-13h-.75a.25.25 0 0 0-.25.25v12.5c0 .138.112.25.25.25h.75ZM12 4.75A2.75 2.75 0 0 1 14.75 2h.5A2.75 2.75 0 0 1 18 4.75v9.5a.75.75 0 0 1-.15.45l-2.25 3a.75.75 0 0 1-1.2 0l-2.25-3a.75.75 0 0 1-.15-.45v-9.5Zm2.75-1.25c-.69 0-1.25.56-1.25 1.25V14l1.5 2 1.5-2V4.75c0-.69-.56-1.25-1.25-1.25h-.5Z"
    />
  </svg>
)

export default SvgPencilBook
