// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgAvatar = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2c-4.337 0-8 3.663-8 8s3.663 8 8 8 8-3.663 8-8-3.663-8-8-8Zm0 4c1.382 0 2.4 1.018 2.4 2.4s-1.018 2.4-2.4 2.4c-1.38 0-2.4-1.018-2.4-2.4S8.62 6 10 6Zm-.8 6.058c-1.37 0-2.568.704-3.285 1.76A5.575 5.575 0 0 0 10 15.6a5.575 5.575 0 0 0 4.084-1.782c-.717-1.056-1.913-1.76-3.284-1.76H9.2Z"
    />
  </svg>
)

export default SvgAvatar
