export enum DatePickerType {
  CheckIn = 'checkIn',
  CheckOut = 'checkOut'
}

export interface DatePickerState {
  checkIn: string
  checkOut: string
  datePickerFocus?: DatePickerType
}

export type DatePickerVariant = 'vertical' | 'horizontal'

export type DayOfWeekType = 0 | 1 | 2 | 3 | 4 | 5 | 6
