import {colors} from './colors'
import {fonts} from './fonts'
import {layout} from './layout'
import {opacity} from './opacity'
import {shadows} from './shadows'
import {typography} from './typography'

const theme = {
  colors,
  fonts,
  typography,
  layout,
  shadows,
  opacity
}

export {theme}
