/* eslint-disable fp/no-mutation */
import React from 'react'
import {getBookingFinalizationLink} from 'business/bofhLinks'
import {logEvent} from 'services/datadogLogger'
import {
  BaseInitializationResponse,
  BoFHResponses,
  ChallengeResponse,
  FinalizationBookingResponse,
  Psd2Strategy,
  ServerCode,
  StatusCodes
} from 'services/psd2/types'
import Settings from 'Settings'
import {v4 as uuid} from 'uuid'

import loadScript from '@daedalus/core/src/_web/utils/network/loadScript'
import {
  BookingStatusThreeDomainSecureRequiredResponse,
  EPSThreeDomainSecureParams
} from '@daedalus/core/src/booking/types/BookingStatus'

interface ThreeDSConnectorInterface {
  new (
    iframeId: string,
    url: string
  ): Psd2Strategy<EpsSetupResponse, EpsBookingStatusResponse>
}

interface PayThreeDSConnectorInterface {
  ThreeDSConnector: ThreeDSConnectorInterface
}

declare let PayThreeDSConnector: PayThreeDSConnectorInterface

// EPS Specific subtypes

interface EpsInitSessionConfig {
  paymentSessionId?: string
  encodedInitConfig?: string
}

type EpsInitializationResponse = BaseInitializationResponse &
  EpsInitSessionConfig

interface EpsSetupResponseDefaults {
  browserAcceptHeader: string
  merchantUrl: string
  preferredChallengeWindowSize: string
}

export interface EpsSetupResponse extends EpsSetupResponseDefaults {
  encodedBrowserMetadata: string
  version: string
}

interface ThreeDSConnectorSetupResponse {
  version: string
  encodedBrowserMetadata: string
}

type EpsBookingStatusResponse =
  BookingStatusThreeDomainSecureRequiredResponse<EPSThreeDomainSecureParams>

// Private
const _secureParams: EpsSetupResponseDefaults = {
  browserAcceptHeader: 'text/html,application/xml',
  merchantUrl: 'https://secure.findhotel.net',
  preferredChallengeWindowSize: 'full_screen'
}

const _bofhResponses: BoFHResponses<EpsBookingStatusResponse> = {
  initialization: undefined,
  challenge: undefined,
  isChargeLater: false
}

// Public

type Connector = Psd2Strategy<EpsSetupResponse, EpsBookingStatusResponse> & {
  initSession: <T extends BaseInitializationResponse>(
    config: EpsInitSessionConfig
  ) => Promise<T>
}

let connector: Connector

const iframeRef = React.createRef<HTMLIFrameElement>()
const sessionId = uuid()

const beforeBooking = (retryCount = 2) => {
  const divContainer = iframeRef.current

  // Sometimes divContainer is not mounted yet when we starting initialization.
  // In this case we want to postpone iframe injection to the next tick.
  // If after 3 tries injection will be not successful we log error to DD
  if (!divContainer && retryCount > 0) {
    setTimeout(() => {
      beforeBooking(retryCount - 1)
    }, 0)
    return
  } else if (!divContainer && retryCount === 0) {
    logEvent(
      'services.PSD2.connector.InjectingIframeContainerNotFound',
      {meta: connector},
      'error'
    )
  }

  try {
    logEvent('services.PSD2.connector.InjectingIframe')
    if (divContainer?.children.length === 0) {
      const iframe = document.createElement('iframe')
      iframe.src = Settings.get('REACT_APP_EPS_PSD2_IFRAME_URL')
      iframe.id = 'threedsiframe'
      iframe.width = '100%'
      iframe.height = '350'
      //@ts-expect-error maybe try to rewrite with `iframe.setAttribute`
      iframe.sandbox = 'allow-scripts allow-forms allow-same-origin'

      iframe.frameBorder = '0'
      divContainer?.appendChild(iframe)
    }
  } catch (error) {
    logEvent(
      'services.PSD2.connector.InjectingIframeError',
      {meta: error},
      'error'
    )
  }
}

const setup = async () => {
  try {
    const scriptUrl =
      'https://static.pay.expedia.com/3ds/1.3.39/pay-3ds-js-libs-connector.min.js'
    logEvent('services.PSD2.connector.LoadingScript', {
      scriptUrl
    })
    await loadScript(scriptUrl, true)
  } catch (error) {
    logEvent('services.PSD2.connector.LoadingScriptError', {meta: error})
  }

  connector = new PayThreeDSConnector.ThreeDSConnector(
    'threedsiframe',
    'https://static.pay.expedia.com'
  ) as Connector

  logEvent('services.PSD2.connector.ConnectorInstance', {meta: {connector}})

  const setupConfig = {
    referenceId: `${Settings.get('REACT_APP_EPS_PROFILE_KEY')}_${sessionId}`
  }

  logEvent('services.PSD2.connector.setup', {
    meta: setupConfig
  })
  const setupResult: ThreeDSConnectorSetupResponse =
    await connector.setup(setupConfig)

  const decoratedSecureParams: EpsSetupResponse = {
    ..._secureParams,
    ...setupResult
  }
  logEvent('services.PSD2.connector.setupResponse', {
    meta: decoratedSecureParams
  })
  return decoratedSecureParams
}

const initializeBooking = async (
  resp: EpsBookingStatusResponse
): Promise<EpsInitializationResponse> => {
  if (resp) _bofhResponses.initialization = resp

  logEvent('services.PSD2.initializedBookingResponse', {
    meta: _bofhResponses.initialization
  })

  const {paymentSessionId, encodedInitConfig} =
    _bofhResponses?.initialization?.threeDomainSecure?.params || {}
  logEvent('services.PSD2.connector.initSession', {
    meta: {
      paymentSessionId,
      encodedInitConfig
    }
  })
  const initSessionResponse: EpsInitializationResponse =
    await connector.initSession({
      paymentSessionId,
      encodedInitConfig
    })

  logEvent('services.PSD2.connector.initSessionResponse', {
    meta: initSessionResponse
  })

  return initSessionResponse
}

const challenge = async (): Promise<ChallengeResponse> => {
  const params = _bofhResponses.initialization
  const connectorChallengeConfig = {
    paymentSessionId: params?.threeDomainSecure?.params?.paymentSessionId,
    encodedChallengeConfig:
      params?.threeDomainSecure?.params?.encodedChallengeConfig
  }

  logEvent('services.PSD2.connector.challenge', {
    meta: connectorChallengeConfig
  })

  try {
    const response = await connector.challenge(connectorChallengeConfig)
    logEvent('services.PSD2.connector.challengeResponse', {
      meta: response
    })

    // We won't return ERROR as EPS asks us to always finish the payment process
    // regardless of the outcome. So for EPS the user will proceed to the booking-finalize
    // but get an error. After that the user might try again.
    return {
      status: StatusCodes.SUCCESS
    }
  } catch (error) {
    logEvent(`services.PSD2.${ServerCode.Eps}.AuthError`, error)
    return {
      status: StatusCodes.ERROR,
      error
    }
  }
}

const isEnrolled = <T extends EpsBookingStatusResponse>(resp: T) => {
  _bofhResponses.initialization = resp
  return (
    resp?.threeDomainSecure?.enrolled ||
    resp?.status === 'three_domain_secure_required'
  )
}

const finalizeBooking = async (): Promise<FinalizationBookingResponse> => {
  const params = _bofhResponses.initialization
  const bookingFinalizationLink = getBookingFinalizationLink(params?.links)

  logEvent('services.PSD2.finalizeBooking', {
    meta: {bookingFinalizationLink}
  })

  return {
    success: true,
    link: bookingFinalizationLink
  }
}

const Psd2: Psd2Strategy<EpsSetupResponse, EpsBookingStatusResponse> = {
  setup,
  beforeBooking,
  initializeBooking,
  finalizeBooking,
  iframeRef,
  challenge,
  isEnrolled,
  serverCode: ServerCode.Eps
}

export default Psd2
