import {AxiosInstance} from 'axios'

export interface RetryAxios {
  /** The axios instance to configure */
  axiosInstance: AxiosInstance
  /** The max number of retry attempts you want to make */
  retryAttempts: number
  /** The time in ms between each retry attempt */
  delayTime: number
}

/**
 * Utility used to configure an axios instance to retry when a request fails
 */
const retryAxios = ({
  axiosInstance,
  retryAttempts = 0,
  delayTime = 0
}: RetryAxios) => {
  let counter = 0
  axiosInstance.interceptors.response.use(undefined, error => {
    const {config} = error
    if (counter < retryAttempts) {
      counter++

      return new Promise(resolve => {
        setTimeout(() => resolve(axiosInstance(config)), delayTime)
      })
    }

    return Promise.reject(error)
  })
}

export default retryAxios
