import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {CompactHeader} from '@daedalus/atlas/src/components/designSystem/CompactHeader'
import {MOBILE_HEADER_HEIGHT} from '@daedalus/atlas/src/components/designSystem/CompactHeader'
import {
  ANIMATION_TYPES,
  Overlay
} from '@daedalus/atlas/src/components/designSystem/Overlay'
import {Brand} from '@daedalus/core/src/brand/types'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {ReferralContent} from '../ReferralContent'
import {ReferralLinkProvider} from '../ReferralLinkProvider'

const HeaderWrapper = styled.div(
  ({theme}) => css`
    position: relative;
    z-index: 2;
    border-bottom: 1px solid ${theme.colors.border.neutral.c100};
  `
)

const HeaderContentWrapper = styled.div(
  ({theme}) => css`
    font-family: ${theme.fonts.family.default};
    font-weight: ${theme.fonts.weight.lg};
    line-height: ${theme.fonts.lineHeight.xs};
    font-size: ${theme.fonts.size.md};
    color: ${theme.colors.content.neutral.c950};
  `
)

const OverlaySection = styled.section`
  height: calc(100% - ${MOBILE_HEADER_HEIGHT}px);
  display: flex;
  flex-direction: column;
`

interface ReferralOverlayPropTypes {
  isOpen: boolean
  onClose: () => void
  brand: Brand
  anonymousId: string
  referralShortLinkUrl: string
}

export const ReferralOverlay = ({
  isOpen,
  onClose,
  brand,
  anonymousId,
  referralShortLinkUrl
}: ReferralOverlayPropTypes) => (
  <ReferralLinkProvider
    brand={brand}
    referralShortLinkUrl={referralShortLinkUrl}
  >
    <Overlay
      isOpen={isOpen}
      animationType={ANIMATION_TYPES.SLIDE_RIGHT}
      shadeBackground={false}
      onClose={onClose}
    >
      <HeaderWrapper>
        <CompactHeader
          brand={brand}
          onBackButtonClick={onClose}
          centerContent={
            <HeaderContentWrapper>
              <FormattedMessageWrapper
                id="referral.inviteFriends"
                defaultMessage="Invite friends"
              />
            </HeaderContentWrapper>
          }
        />
      </HeaderWrapper>
      <OverlaySection>
        <ReferralContent brand={brand} anonymousId={anonymousId} />
      </OverlaySection>
    </Overlay>
  </ReferralLinkProvider>
)
