// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgCalendarCross3 = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2a.75.75 0 0 1 .75.75V4h6.5V2.75a.75.75 0 0 1 1.5 0V4h.5A2.75 2.75 0 0 1 18 6.75v3.5a.75.75 0 0 1-1.5 0v-3.5a1.25 1.25 0 0 0-1.25-1.25h-.5v.75a.75.75 0 0 1-1.5 0V5.5h-6.5v.75a.75.75 0 0 1-1.5 0V5.5h-.5A1.25 1.25 0 0 0 3.5 6.75v8.5a1.25 1.25 0 0 0 1.25 1.25h4.5a.75.75 0 0 1 0 1.5h-4.5A2.75 2.75 0 0 1 2 15.25v-8.5A2.75 2.75 0 0 1 4.75 4h.5V2.75A.75.75 0 0 1 6 2ZM5 8.75A.75.75 0 0 1 5.75 8h8.5a.75.75 0 0 1 0 1.5h-8.5A.75.75 0 0 1 5 8.75Zm8.22 4.47a.75.75 0 0 1 1.06 0l1.22 1.22 1.22-1.22a.75.75 0 1 1 1.06 1.06l-1.22 1.22 1.22 1.22a.75.75 0 1 1-1.06 1.06l-1.22-1.22-1.22 1.22a.75.75 0 1 1-1.06-1.06l1.22-1.22-1.22-1.22a.75.75 0 0 1 0-1.06Z"
    />
  </svg>
)

export default SvgCalendarCross3
