import {toggle} from 'opticks'

/**
 * Collect all experiments that start from "sapi4eva" prefix, activate them and send to SAPI SDK
 * This allows SAPI to launch A/B tests without changing the code
 *
 * Once we merge this PR https://github.com/FindHotel/opticks/pull/46 and release the new Opticks version
 * we'll be able to import getEnabledFeatures method directly from opticks
 * then no need to read the dataFile
 */
export const collectSapiVariationsParam = (
  dataFileExperiments: string[] = [],
  nonSapiExperiments: string[] = []
): Record<string, 'a' | 'b'> => {
  return Object.fromEntries(
    dataFileExperiments
      .filter(key => key.startsWith('sapi4eva'))
      .concat(nonSapiExperiments)
      .map(key => [key, toggle(key, 'a', 'b') as 'a' | 'b'])
  )
}
