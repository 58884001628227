import {LinkType} from '@daedalus/core/src/booking/types/Link'
import {
  CancellationPenalty,
  OfferPrice
} from '@daedalus/core/src/offer/types/offer'

export enum OfferCheckStatuses {
  available = 'available',
  price_mismatch = 'price_mismatch'
}

export interface OfferCheckResponse {
  cancellationPenalties: CancellationPenalty[]
  checkInInstructions: string
  expiresAt: string
  extraParams: null
  links: LinkType[]
  merchantOfRecord: string
  prices: OfferPrice[]
  providerTraderInformation: null | string
  status: OfferCheckStatuses
}
