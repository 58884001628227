import {parse} from 'qs'
import {__, assoc, pickBy, pipe} from 'ramda'

type RawParamsType = Record<string, string>

const FORCE_PREFIX = 'ab_'

const isForceToggleParam = (value: string, key: string) =>
  key.startsWith(FORCE_PREFIX)

const filterABTestParameters = (params: RawParamsType): RawParamsType =>
  pickBy(isForceToggleParam, params)

const curriedParse = (url: string) =>
  parse(url, {
    ignoreQueryPrefix: true
  }) as RawParamsType

const removeForcePrefix = (params: RawParamsType) => {
  return Object.keys(params).reduce((previous, current) => {
    const newKey = current.split(FORCE_PREFIX)[1]
    return {
      ...previous,
      [newKey]: params[current]
    }
  }, {})
}

/**
 *
 * @param url - url with params
 * @returns object with a list of param: value taken from given url
 * composed with enabled experiments and useAgentData
 */
export const urlParamsParser = (url: string) => {
  const parseAllowedParams = pipe(
    curriedParse,
    assoc('userAgent', window.navigator.userAgent)
  )

  const parseForcedExperiments = pipe(
    curriedParse,
    filterABTestParameters,
    removeForcePrefix,
    assoc('experiments', __, {})
  )

  return {
    ...parseAllowedParams(url),
    ...parseForcedExperiments(url)
  }
}

/**
 * @returns List of params from history.location.search
 * composed with enabled experiments and useAgentData
 */
const parser = () => urlParamsParser(location.search)

export default parser
