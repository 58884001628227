import {path, split} from 'ramda'
import {ColorPath, ThemeType} from 'types/Theme'

import {cssTheme} from '../themes'

/**
 * Get the color from the theme based on the color path
 */

export const getColorByPath = (theme: ThemeType, colorPath: ColorPath) =>
  colorPath ? (path(split('.', colorPath), theme.colors) as string) : undefined

export const inverseGradient = `linear-gradient(
      122.03deg,
      ${cssTheme.colors.background.inverse.c800} -15.69%,
      ${cssTheme.colors.background.inverse.c950} 64.03%
    )`

export const getCssColorByPath = (colorPath: ColorPath) =>
  colorPath
    ? (path(split('.', colorPath), cssTheme.colors) as string)
    : undefined
