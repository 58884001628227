import React from 'react'
import {FormattedMessage} from 'react-intl'

/**
 *
 * The FormattedMessageWrapper is a custom wrapper for the FormattedMessage component
 * from the react-intl library. It's used to wrap the translation message in a <span>
 * element with a data attribute for the translation's id. This is used for locating
 * any translations in the DOM, for testing purposes.
 */

export interface FormattedMessageWrapperProps {
  dataId?: string
  /**
   * The translation's id.
   * This will correspond with the id in the internationalization service
   * */
  id: string
  /**
   * Default message to be displayed in case no translation is found for the given id.
   * */
  defaultMessage: string
  /**
   * Values to use for the translation's parameters
   * */
  values?: Record<
    string,
    | string
    | number
    | boolean
    | React.ReactFragment
    | React.ReactPortal
    | React.ReactNode
    | Date
    | React.ReactElement
    | ((...nodes: React.ReactNode[]) => React.ReactElement)
    | null
  >
  /**
   * A class name that can be given to the element
   * */
  className?: string
  /**
   * Used by DataDog to identify the element when displaying in the RUM dashboard
   * https://docs.datadoghq.com/real_user_monitoring/browser/tracking_user_actions/#declare-a-name-for-click-actions
   * */
  ddActionName?: string
}

export const FormattedMessageWrapper = (
  props: FormattedMessageWrapperProps
) => {
  // Using props spread instead of describing all the parameters as a
  // workaround for this issue https://github.com/yahoo/babel-plugin-react-intl/issues/119
  return (
    <FormattedMessage {...props}>
      {(...txt) => (
        <span
          data-id={props.dataId}
          data-translations-id={props.id}
          data-dd-action-name={props.ddActionName}
          className={props.className}
        >
          {txt}
        </span>
      )}
    </FormattedMessage>
  )
}

export default FormattedMessageWrapper
