import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Button} from '@daedalus/atlas/src/components/designSystem/Button'
import {PopUp} from '@daedalus/atlas/src/components/designSystem/PopUp'
import {Text} from '@daedalus/atlas/src/components/designSystem/Text'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

const TextWrapper = styled.div(
  ({theme}) => css`
    margin-bottom: ${theme.layout.spacing.s600}px;
    max-width: 326px;
    text-align: center;
  `
)

const ButtonsWrapper = styled.div(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.layout.spacing.s500}px;
  `
)

type PropsType = {
  isOpen: boolean
  onCancelClick: () => void
  onDeleteClick: () => void
}

export const Modal = ({isOpen, onCancelClick, onDeleteClick}: PropsType) => (
  <PopUp
    isOpen={isOpen}
    titleBarProps={{
      centerContent: (
        <FormattedMessageWrapper
          id="deleteAccountModal.areYouSure"
          defaultMessage="Are you sure?"
        />
      )
    }}
  >
    <TextWrapper>
      <Text colorPath="content.neutral.c600">
        <FormattedMessageWrapper
          id="deleteAccountModal.deleteAccountMessage"
          defaultMessage="Are you sure you want to delete your account? Please remember that this is permanent and can't be undone."
        />
      </Text>
    </TextWrapper>

    <ButtonsWrapper>
      <Button fullWidth variant="primary" size="lg" onClick={onDeleteClick}>
        <FormattedMessageWrapper
          id="deleteAccountModal.yesDelete"
          defaultMessage="Yes, delete"
        />
      </Button>
      <Button fullWidth variant="quiet" size="lg" onClick={onCancelClick}>
        <FormattedMessageWrapper
          id="deleteAccountModal.noKeep"
          defaultMessage="No, keep"
        />
      </Button>
    </ButtonsWrapper>
  </PopUp>
)
