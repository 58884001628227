// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgMoney = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.75 4A2.75 2.75 0 0 0 2 6.75v6.5A2.75 2.75 0 0 0 4.75 16h10.5A2.75 2.75 0 0 0 18 13.25v-6.5A2.75 2.75 0 0 0 15.25 4H4.75ZM3.5 6.75c0-.69.56-1.25 1.25-1.25h.232A1.751 1.751 0 0 1 3.5 6.982V6.75Zm0 1.74A3.25 3.25 0 0 0 6.49 5.5h7.02a3.25 3.25 0 0 0 2.99 2.99v3.02a3.25 3.25 0 0 0-2.99 2.99H6.49a3.25 3.25 0 0 0-2.99-2.99V8.49Zm13-1.508V6.75c0-.69-.56-1.25-1.25-1.25h-.232c.11.766.716 1.373 1.482 1.482ZM15.018 14.5h.232c.69 0 1.25-.56 1.25-1.25v-.232a1.751 1.751 0 0 0-1.482 1.482ZM3.5 13.018c.766.11 1.373.716 1.482 1.482H4.75c-.69 0-1.25-.56-1.25-1.25v-.232ZM8.5 10c0-.764.216-1.424.526-1.871.312-.45.668-.629.974-.629.306 0 .662.178.974.629.31.447.526 1.107.526 1.871s-.216 1.423-.526 1.871c-.312.45-.668.629-.974.629-.306 0-.662-.178-.974-.629-.31-.448-.526-1.107-.526-1.871ZM10 6c-.937 0-1.705.55-2.208 1.275C7.288 8.004 7 8.969 7 10c0 1.03.288 1.996.792 2.725C8.295 13.451 9.063 14 10 14c.937 0 1.705-.55 2.208-1.275.504-.729.792-1.694.792-2.725 0-1.03-.288-1.996-.792-2.725C11.705 6.549 10.937 6 10 6Z"
    />
  </svg>
)

export default SvgMoney
