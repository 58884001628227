// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgTimer2 = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 2.75A.75.75 0 0 1 8 2h4a.75.75 0 0 1 0 1.5h-1.25v.54A7.001 7.001 0 0 1 10 18c-3.9 0-7-3.37-7-7.25a.75.75 0 0 1 .75-.75h5.5V4.75c0-.043.004-.084.01-.125a.755.755 0 0 1-.01-.125v-1H8a.75.75 0 0 1-.75-.75Zm3.5 8v-5.2A5.501 5.501 0 0 1 10 16.5c-2.76 0-5.09-2.22-5.452-5H10a.75.75 0 0 0 .75-.75ZM4.28 5.22a.75.75 0 0 0-1.06 1.06l1.5 1.5a.75.75 0 0 0 1.06-1.06l-1.5-1.5Z"
    />
  </svg>
)

export default SvgTimer2
