import getHotelRequestParams from 'components/data/URLParams/mappers/getHotelRequestParams'
import useParams from 'components/data/URLParams/useParams'
import Settings from 'Settings'

import {useGetHotelQuery} from '@daedalus/core/src/sapi/services/searchApi'
import {useInitializeSapi} from '@daedalus/core/src/sapi/services/useInitializeSapi'

import {useGetSapiInitializationParams} from './useGetSapiInitializationParams'

export const useGetHotel = (useOldEndpoint?: boolean) => {
  const params = useGetSapiInitializationParams()
  useInitializeSapi(params)
  const urlParams = useParams()
  const hotelRequestParams = getHotelRequestParams(urlParams)

  const {data, isLoading, isSuccess, error, isError} = useGetHotelQuery(
    hotelRequestParams,
    {skip: useOldEndpoint}
  )

  // Redirecting users to the homepage in case when hotel data was removed from our content base
  // More details: https://app.shortcut.com/findhotel/story/122022/respect-deletedat-attribute-in-hotel-http-api-response
  if (data?.isDeleted) {
    window.location.replace(Settings.get('REACT_APP_VIO_SEARCH_URL'))
  }

  return {
    data: data && !data.isDeleted ? data : undefined,
    isLoading,
    isSuccess,
    error,
    isError
  }
}
