import {
  ActionCreatorWithoutPayload,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
  SliceCaseReducers
} from '@reduxjs/toolkit'
import {mergeDeepRight} from 'ramda'

import {
  persistCognitoUserTierOnCookie,
  removeUnnecessaryCookies
} from '../business/persistData'
import {
  AuthenticatedUser,
  AuthenticationFlows,
  ClaimsType,
  ShowPasswordlessLoginPayload,
  UserAction
} from '../types/Auth'
import {CognitoError, User, UserAttributes, UserPoolId} from '../types/Cognito'
import {
  makeSocialLogin,
  updateUserAttributes,
  upgradeUserToPlus
} from './thunks'

export type AuthStep = 'SignInStep'

/**
 * The Auth Redux state Slice
 */
export type StateType = {
  /** Authentication errors */
  error: {
    errorType?: string
    errorMessage?: string | CognitoError
  }
  lastAction?: string
  /** Handles the UI state to manage the passwordless login modal visibility */
  isPasswordlessLoginVisible: boolean
  /** ??? */
  status: string
  /** The user's phone number */
  userPhoneNumber: string | null | undefined
  /** Handles the UI state to manage the "resending code" message visibility */
  isUserResending: boolean
  /** The authenticated user id */
  userId: string | null | undefined
  /** Which flow the user is using to login/signup */
  flow: AuthenticationFlows | null
  /** The hotelId the user clicked on the unlock flow */
  unlockHotelId?: string | null
  /** Which component the user triggered login/signup */
  sourceComponent?: string | null
  /** Is the user privileged */
  shouldSeeOffersUnlocked: boolean
  /** Is the user authenticated */
  isAuthenticated: boolean
  /** The user */
  user: User | null
  /** The loading state when authenticating the user */
  isLoading: boolean
  /** Profile picture URL from Amplify Storage.get */
  profilePictureUrl?: string
  userPoolId: UserPoolId | null
  isBottomSheetLoginVisible: boolean
  authStep: AuthStep
}

/** @private */
export const INIT_STATUS = 'init'

export const initialState: StateType = {
  isPasswordlessLoginVisible: false,
  isUserResending: false,
  error: {},
  status: INIT_STATUS,
  userPhoneNumber: null,
  userId: null,
  flow: null,
  unlockHotelId: null,
  shouldSeeOffersUnlocked: false,
  isAuthenticated: false,
  user: null,
  isLoading: true,
  userPoolId: null,
  sourceComponent: null,
  isBottomSheetLoginVisible: false,
  authStep: 'SignInStep'
}

/* eslint fp/no-mutation: 0 */
const {actions, reducer} = createSlice<StateType, SliceCaseReducers<StateType>>(
  {
    name: 'authentication',
    initialState,
    reducers: {
      showPasswordlessLogin: (
        state: StateType,
        action: PayloadAction<ShowPasswordlessLoginPayload>
      ) => {
        if (action.payload) {
          state.isPasswordlessLoginVisible =
            action.payload.isPasswordlessLoginVisible
          state.flow = action.payload.flow
          state.unlockHotelId = action.payload.unlockHotelId
          state.sourceComponent = action.payload.sourceComponent
          state.status = action.type
        }
      },
      hidePasswordlessLogin: (state: StateType) => {
        state.isPasswordlessLoginVisible = false
        state.flow = null
        state.unlockHotelId = null
        state.sourceComponent = null
        state.status = INIT_STATUS
      },
      cleanUpLoginErrors: (state: StateType) => {
        state.error = {}
      },
      identifyAuthenticatedUser: (
        state: StateType,
        action: PayloadAction<ClaimsType>
      ) => {
        state.userId = action.payload?.sub
      },
      setShouldSeeOffersUnlocked: (
        state: StateType,
        action: PayloadAction<boolean>
      ) => {
        state.shouldSeeOffersUnlocked = action.payload
      },
      setIsAuthenticated: (
        state: StateType,
        action: PayloadAction<AuthenticatedUser>
      ) => {
        state.isAuthenticated = action.payload.isAuthenticated
      },
      setUser: (state: StateType, action: PayloadAction<UserAction>) => {
        state.user = action.payload.user
        state.isAuthenticated = action.payload.isAuthenticated
        state.shouldSeeOffersUnlocked = action.payload.shouldSeeOffersUnlocked
        state.userPoolId = action.payload.userPoolId
        persistCognitoUserTierOnCookie(action.payload.user)
        removeUnnecessaryCookies()
      },
      setCustomAppData: (
        state: StateType,
        action: PayloadAction<{attributes: UserAttributes}>
      ) => {
        state.user = mergeDeepRight(state.user as User, action.payload)
      },
      setIsLoading: (state: StateType, action: PayloadAction<boolean>) => {
        state.isLoading = action.payload
      },
      hideBottomSheetLogin: (state: StateType) => {
        state.isBottomSheetLoginVisible = false
        state.status = INIT_STATUS
        state.authStep = initialState.authStep
      },
      logOut: (state: StateType) => {
        state.user = null
        state.isAuthenticated = false
        state.userPoolId = null
        state.authStep = initialState.authStep
      },
      setUnlockHotelId: (
        state: StateType,
        action: PayloadAction<StateType['unlockHotelId']>
      ) => {
        state.unlockHotelId = action.payload
      }
    },
    extraReducers: builder => {
      builder
        .addCase(
          makeSocialLogin.rejected.type,
          (state: StateType, action: PayloadAction<CognitoError>) => {
            state.error = {
              errorType: 'SocialLogin',
              errorMessage: action.payload?.name
            }

            state.status = action.type
          }
        )
        .addCase(
          upgradeUserToPlus.fulfilled.type,
          (state: StateType, action: PayloadAction<any>) => {
            if (state.user) {
              state.user.attributes['custom:app_data'] = action.payload
            }
            persistCognitoUserTierOnCookie(state.user)
          }
        )
        .addMatcher(
          isFulfilled(updateUserAttributes),
          (state: StateType, action: PayloadAction<UserAttributes>) => {
            state.lastAction = action.type
            state.user = {
              ...state.user,
              attributes: action.payload
            }
            state.error = {}
          }
        )
        .addMatcher(
          isPending(updateUserAttributes),
          (state: StateType, action: PayloadAction<any>) => {
            state.lastAction = action.type
            state.error = {}
          }
        )
        .addMatcher(
          isRejected(updateUserAttributes),
          (state: StateType, action: PayloadAction<any>) => {
            state.lastAction = action.type
            state.error = {
              errorType: 'profileUpdate',
              errorMessage: action?.payload?.message
            }
          }
        )
    }
  }
)

export const {
  showPasswordlessLogin,
  setMessageAttemptsHistory,
  identifyAuthenticatedUser,
  setShouldSeeOffersUnlocked,
  setIsAuthenticated,
  setUser,
  setCustomAppData,
  setIsLoading,
  setAuthStep,
  setUnlockHotelId
} = actions

export const {
  hidePasswordlessLogin,
  cleanUpLoginErrors,
  setResendMessage,
  hideBottomSheetLogin,
  logOut
} = actions as Record<
  string,
  ActionCreatorWithoutPayload<`${string}/${string}`>
>

export default reducer
