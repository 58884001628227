/* eslint-disable fp/no-mutation */
import {updateAllPrices} from 'modules/rooms/updateAllPrices'
import {urlParams} from 'utils/urlParams'

import {Price} from '@daedalus/core/src/api-types/bovio/response/booking'
import {TotalPrice} from '@daedalus/core/src/api-types/bovio/response/split_bookings/offer_check'
import {getTaxDisplayLogic} from '@daedalus/core/src/localization/business/countries'
import {OfferPrice} from '@daedalus/core/src/offer/types/offer'
import {OfferCheck} from '@daedalus/core/src/offer/types/OfferCheck'

export interface OfferCheckState extends OfferCheck {
  loaded: boolean
  preFetchedOffers?: OfferCheck[]
}

export const updatePrices = (prices: Price[] | TotalPrice[]): OfferPrice[] => {
  const {userCountry} = urlParams()
  const taxDisplayLogic = getTaxDisplayLogic(userCountry)

  return prices.map(price => updateAllPrices(price, taxDisplayLogic))
}
