// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPhoto = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 4.75A2.75 2.75 0 0 1 4.75 2h10.5A2.75 2.75 0 0 1 18 4.75v10.5A2.75 2.75 0 0 1 15.25 18H4.75A2.75 2.75 0 0 1 2 15.25V4.75Zm1.5 10.5v-.99l2.586-3.29a1.25 1.25 0 0 1 1.93-.042l.319.37a.648.648 0 0 0 .022.026l2.074 2.415a.75.75 0 1 0 1.138-.978l-1.684-1.96c.98-1.252 2.117-2.722 2.2-2.83l.005-.007a1.25 1.25 0 0 1 1.927-.036l2.414 2.81a.767.767 0 0 0 .069.071v4.441c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25Zm13-6.733V4.75c0-.69-.56-1.25-1.25-1.25H4.75c-.69 0-1.25.56-1.25 1.25v7.082l1.407-1.789a2.75 2.75 0 0 1 3.963-.379c.935-1.196 1.95-2.508 2.028-2.61l.002-.003.01-.013.004-.004a2.75 2.75 0 0 1 4.24-.083L16.5 8.517Z"
    />
  </svg>
)

export default SvgPhoto
