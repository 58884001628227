import {
  AccessTier,
  MatchedDim,
  RoomLinks
} from '@findhotel/sapi/dist/types/packages/core/src'

import {ActionLink} from './actionLink'
import PriceTypes from './PriceTypes'
import {SearchOffer} from './SearchOffer'

export type HotelFeeBreakdownType = 'resort_fee' | 'tax' | 'other'

export type HotelFeesBreakdown = Array<{
  /** The amount to be paid for the specific fee */
  total: number | string
  /** The type of fee. For more info, see [here](https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/rooms.md#hotel-fees-breakdown-types) */
  type: HotelFeeBreakdownType
}>

/**
 * The amount a user will pay at the hotel total and nightly.
 * Used in {@link OfferPrice}
 */
export interface HotelFees {
  /** The breakdown of the fees to be paid. */
  breakdown?: HotelFeesBreakdown
  /** The total amount to pay in fees */
  total: number
  /** The nightly amount to pay in fees */
  nightly: number
  /** The currency of the hotel fees */
  currencyCode?: string
  /**
   * Due to an inconsistency between SAPI and BoVio prices, BoVio uses `currency` while SAPI uses `currencyCode`.
   * Addressing this discrepancy requires coordination across teams and might not be immediately prioritized.
   * To accurately represent our current data, we've included both fields.
   * Once the inconsistency is resolved, either `currency` or `currencyCode` should be removed based on the standardized API response.
   */
  currency?: string
}

export interface PayAtHotel {
  /** The total amount to pay in hotel currency */
  total: number
  /** The currency of the hotel */
  currency: string
}

/**
 * The breakdown information of the amount the user will pay.
 * Used in {@link OfferPrice}
 */
export interface Chargeable {
  /** Total rate without taxes */
  base: number
  /** Nightly total rate per room without taxes */
  nightlyBase: number
  /** Nightly total rate + taxes (Based on Tax display logic) */
  nightlyDisplayTotal: number
  /** Nightly total of all taxes per room */
  nightlyTaxes: number
  /** Total of all taxes */
  taxes: number
  /** The currency of the chargeable amount.
   * Note: this should become a required prop after [this](https://app.clubhouse.io/findhotel/story/26663/add-currency-to-chargeable-and-hotel-fees-in-bofh-api-responses)
   * is implemented.
   */
  currencyCode?: string

  /**
   * Due to an inconsistency between SAPI and BoVio prices, BoVio uses `currency` while SAPI uses `currencyCode`.
   * Addressing this discrepancy requires coordination across teams and might not be immediately prioritized.
   * To accurately represent our current data, we've included both fields.
   * Once the inconsistency is resolved, either `currency` or `currencyCode` should be removed based on the standardized API response.
   */
  currency?: string
  /** The discount applied to the chargeable amount - this is a negative number */
  discount?: number
}

/**
 * The complete information for the price the user is going to pay for an {@link Offer}.
 * Contains more information than just the amount
 */
export interface OfferPrice {
  chargeable: Chargeable
  /** @deprecated
   *  The currency code of the offer being displayed. Remove after chargeable and hotelFees have their own currencies */
  currencyCode?: string
  /** The fees to be paid at the hotel in chargeable currency */
  hotelFees: HotelFees
  /** Pay at property amount in hotel currency. For pay now deals equals hotel fees. For pay later deals equals total price of stay */
  payAtHotel?: PayAtHotel
  /** The type of price */
  type: PriceTypes
}

interface OfferMetadata {
  providerRateType?: string
  feedID: string
  originalAccessTier?: string
}

/**
 * An {@link Offer} is the bookable entity of a room.
 * A {@link Room} is not bookable because the same physical {@link Room} can be purchased
 * at different conditions
 */
export interface Offer {
  /** The number of rooms available of that kind */
  availableRooms: number
  /** CUG stands for closed user group */
  cug: string[]
  /** The unique id of the offer */
  id: string
  /** These links are used to communicate with the API */
  links: ActionLink[] | RoomLinks[]
  /** The price configuration for the offer */
  prices: OfferPrice[]
  providerRateType?: string
  /** The rate id used by the provider. We use to group rates on the UI that are similar but differ on type of payment */
  providerRateId?: string
  rateId?: string
  /** Whether the user can receive its funds back in case of cancellation */
  /**
   * @deprecated Use isOfferRefundable instead
   */
  refundable?: boolean
  /** The types of services the user will have available at the room such as "Free Internet", "Free breakfast" */
  services: string[]
  postPaid?: boolean
  /** The entity that handles the payment. */
  merchantOfRecord?: string
  /** The conditions on which the user can cancel a reservation  */
  cancellationPenalties: CancellationPenalty[]
  /** Whether the user will pay the price in full at the hotel and not at our checkout  */
  canPayLater: boolean
  /** Useful information for the customer at the moment of check-in. It might contain information about additional costs.  */
  checkInInstructions?: string
  providerCode: string
  /** If this is the offer that was selected by the user on SRP */
  isClicked?: boolean
  /** How close is the match for isClicked */
  matchType?: 'exact' | 'partial' | 'by_terms' | 'by_price'
  /**
   * Which properties were matched when we could not find an exact match but a by_terms one
   */
  matchedDim?: MatchedDim
  /** We might change the price of an offer to match the expected price across the funnel. This field shows the difference that
   * was applied so the price is accurate
   */
  matchedOfferPriceDiff?: number
  /** Used to point that the offer should be considered as sold out, part of sapi4eva-check-price ab test */
  isSoldOut?: boolean
  /** The offer's tags, e.g top_offer, anchor_price, preferred_rate  */
  tags?: string[]
  metadata: OfferMetadata
}

/**
 * A {@link Room} has a list of cancellation penalties which states what penalties the user
 * is due to pay in case of cancellation.
 */
export interface CancellationPenalty {
  start: string
  end: string
  amount: string | number
  currency: string
}

export enum OfferInfoType {
  Parity = 'PriceParity',
  Discount = 'PriceDiscount'
}

export enum OfferTagLabel {
  IsYourChoice = 'preferred_rate',
  IsTopOffer = 'top_offer',
  IsAnchorPriceOffer = 'anchor_price',
  IsCheapest = 'exclusive_cheapest_offer'
}

export enum OfferPromoKey {
  Web2App = 'web2app'
}

export type GenericOffer = (Partial<SearchOffer> | Partial<Offer>) & {
  accessTier?: AccessTier
  cug?: string[]
}
