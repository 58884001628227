// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgDoor = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4.75A2.75 2.75 0 0 1 6.75 2h6.5A2.75 2.75 0 0 1 16 4.75V16.5h1.25a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1 0-1.5H4V4.75ZM6.75 3.5c-.69 0-1.25.56-1.25 1.25V16.5h9V4.75c0-.69-.56-1.25-1.25-1.25h-6.5ZM11 10a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
    />
  </svg>
)

export default SvgDoor
