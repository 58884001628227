// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgNoPin = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.28 2.22a.75.75 0 0 0-1.06 1.06l14.5 14.5a.75.75 0 0 0 1.06-1.06l-2.904-2.905C15.996 12.45 17 10.754 17 9c0-3.929-3.287-7-7-7-1.87 0-3.621.777-4.903 2.037L3.28 2.22Zm2.878 2.877L7.91 6.85a3 3 0 0 1 4.242 4.242l1.659 1.658C14.813 11.497 15.5 10.188 15.5 9c0-3.071-2.586-5.5-5.5-5.5-1.449 0-2.826.602-3.842 1.597Zm4.933 4.933a1.5 1.5 0 0 0-2.12-2.12l2.12 2.12ZM3.953 7.26a.75.75 0 0 1 .618.862A5.413 5.413 0 0 0 4.5 9c0 .81.32 1.688.867 2.586.544.893 1.281 1.752 2.038 2.509A23.82 23.82 0 0 0 10 16.326l.015-.011c.28-.208.673-.512 1.123-.89a.75.75 0 1 1 .965 1.15 26.008 26.008 0 0 1-1.644 1.27l-.026.018-.008.005-.002.001-.001.001-.422-.62-.422.62-.002-.001-.004-.003-.016-.011-.057-.04a23.86 23.86 0 0 1-.94-.702 25.297 25.297 0 0 1-2.214-1.958c-.806-.805-1.631-1.758-2.259-2.788C3.462 11.343 3 10.19 3 9c0-.382.031-.757.091-1.122a.75.75 0 0 1 .862-.618ZM10 17.25l.422.62a.75.75 0 0 1-.844 0l.422-.62Z"
    />
  </svg>
)

export default SvgNoPin
