/**
 * Dynamically import MSW and the handlers to prevent users from having to load it
 */
const initializeSimulator = async () => {
  try {
    const {worker} = await import(
      /* webpackChunkName: "scenarioSimulator" */
      './browser'
    )
    const loadedWorker = await worker()
    loadedWorker.start({
      onUnhandledRequest: 'bypass' // Silence warnings for unhandled requests
    })
  } catch (error) {
    console.error(`Error initializing scenario simulator`, error)
  }
}

export default initializeSimulator
