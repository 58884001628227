import {doesWindowObjectExist} from './doesWindowObjectExist'

/**
 *
 * Get item from local storage or session storage in a safe way, logging an error when the window objects does not exists
 *
 * @example get `searchUri` from local storage
 *
 * ```
 * safelyGetStorageItem(localStorage, 'searchUri', 'getSearchUri failed')
 * ```
 *
 * @param storage - the storage where to get the item. Local storage or Session storage
 * @param param - the chosen parameter to get from the storage
 * @param errorString - the error message to log when it fails
 *
 * @returns The value for the requested param or undefined
 */
export const safelyGetStorageItem = (
  storage: typeof localStorage | typeof sessionStorage,
  param: string,
  errorString?: string
): string | undefined | null => {
  try {
    return doesWindowObjectExist() ? storage.getItem(param) : undefined
  } catch {
    console.error(errorString)

    return undefined
  }
}
