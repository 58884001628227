import {flatten, map, pipe, split} from 'ramda'
import {v4 as uuid} from 'uuid'

import {Room as BoVioRoom} from '../../../api-types/bovio/response/bookings'
import {
  Room,
  RoomConfigurationType,
  RoomSplitType,
  UniqueRoomSplitType
} from '../../types/RoomConfiguration'
import {
  CHILD_SEPARATOR,
  DEFAULT_ADULTS_PER_ROOM,
  DEFAULT_ROOMS,
  GUEST_SEPARATOR,
  ROOM_SEPARATOR
} from './config'

export const roomsStringToRoomsSplit = (rooms: string) =>
  pipe(split(ROOM_SEPARATOR), map(roomToRoomSplit))(rooms || DEFAULT_ROOMS)

const roomToRoomSplit = (room: string): RoomSplitType => {
  const [adults, children] = room.split(GUEST_SEPARATOR)

  return {
    adults: Number(adults),
    children: children
      ? pipe(split(CHILD_SEPARATOR), map(Number))(children)
      : []
  }
}

export const roomsSplitToConfigurationObject = (
  roomsSplit: RoomSplitType[]
): RoomConfigurationType => {
  const numberOfAdults = roomsSplit.reduce((acc, room) => acc + room.adults, 0)

  const numberOfChildren = roomsSplit.reduce(
    (acc, room) => acc + (room.children ? room.children.length : 0),
    0
  )

  return {
    numberOfAdults,
    numberOfChildren,
    numberOfRooms: roomsSplit.length,
    roomsSplit
  }
}

export const roomsToConfigurationObject = (
  rooms: string
): RoomConfigurationType => {
  const roomConfig = pipe(
    split(ROOM_SEPARATOR),
    map(roomToRoomSplit)
  )(rooms || DEFAULT_ROOMS)

  return roomsSplitToConfigurationObject(roomConfig)
}

const roomSplitToString = (roomSplit: RoomSplitType): string =>
  roomSplit.children.length > 0
    ? `${roomSplit.adults}:` + roomSplit.children.join(CHILD_SEPARATOR)
    : roomSplit.adults.toString()

export const roomsSplitToString = (
  roomsSplit: readonly RoomSplitType[]
): string => roomsSplit.map(roomSplitToString).join(ROOM_SEPARATOR)

export const numberOfRooms = (rooms: string): number =>
  roomsToConfigurationObject(rooms).numberOfRooms

export const numberOfGuests = (rooms: string): number =>
  roomsToConfigurationObject(rooms).numberOfAdults +
  roomsToConfigurationObject(rooms).numberOfChildren

export const addIdsToRoomsSplit = (
  roomsSplit: RoomSplitType[] = [],
  generateId: () => string = uuid
): UniqueRoomSplitType[] =>
  roomsSplit.map(
    (roomSplit: RoomSplitType): UniqueRoomSplitType => ({
      ...roomSplit,
      id: generateId()
    })
  )

export const removeIdsFromRoomsSplit = (
  roomsSplit: UniqueRoomSplitType[] | RoomSplitType[]
): RoomSplitType[] =>
  roomsSplit.map(
    ({
      adults,
      children
    }: UniqueRoomSplitType | RoomSplitType): RoomSplitType => ({
      adults,
      children
    })
  )

export const flattenRoomsSplitToSingleRoom = ({
  roomsSplit,
  maxGuests
}: {
  roomsSplit: RoomSplitType[]
  maxGuests: number
}): RoomSplitType[] => {
  if (roomsSplit === undefined || roomsSplit.length === 0) return []

  const reducedRoomsSplit = [
    roomsSplit.reduce<RoomSplitType>(
      (acc, roomSplit) => ({
        adults: acc.adults + roomSplit.adults,
        children: [...acc.children, ...roomSplit.children]
      }),
      {adults: 0, children: []}
    )
  ]

  const allowedChildren = maxGuests - reducedRoomsSplit[0].adults

  if (reducedRoomsSplit[0].adults > maxGuests) {
    return [{...reducedRoomsSplit[0], adults: maxGuests}]
  }

  if (reducedRoomsSplit[0].children.length > allowedChildren) {
    return [
      {
        ...reducedRoomsSplit[0],
        children: reducedRoomsSplit[0].children.slice(0, allowedChildren)
      }
    ]
  }

  return reducedRoomsSplit
}

export const totalNumberOfGuestsPerRoom = (roomSplit: RoomSplitType) => {
  return roomSplit.adults + roomSplit.children.length
}

export const roomsToRoomsSplit = (rooms: Room[] | BoVioRoom[]) =>
  rooms.map(room => ({
    adults: room?.guests?.adults ?? DEFAULT_ADULTS_PER_ROOM,
    children: room?.guests?.childAges ?? []
  }))

export const roomsToString = <T extends Room[] | BoVioRoom[]>(
  rooms: T
): string => {
  const roomsSplit = roomsToRoomsSplit(rooms)
  return roomsSplitToString(roomsSplit)
}

export const spreadAdultsIntoRooms = (
  roomsSplit: RoomSplitType[]
): RoomSplitType[] => {
  const totalAdults = roomsSplit.reduce((acc, roomSplit) => {
    return acc + roomSplit.adults
  }, 0)

  const spreadRoomsSplit = []
  let remainingValue = totalAdults

  while (remainingValue > 0) {
    const roomAdults = Math.min(DEFAULT_ADULTS_PER_ROOM, remainingValue)
    spreadRoomsSplit.push({adults: roomAdults, children: []})
    remainingValue -= roomAdults
  }

  return spreadRoomsSplit
}

export const ensureRoomsSplitGuestCountIsValid = ({
  roomsSplit,
  maxGuests
}: {
  roomsSplit: RoomSplitType[]
  maxGuests: number
}): RoomSplitType[] => {
  const validManualRoomsSplit = roomsSplit.map(roomSplit => {
    const totalGuests = totalNumberOfGuestsPerRoom(roomSplit)
    const hasTooManyGuests = totalGuests > maxGuests
    return hasTooManyGuests ? spreadAdultsIntoRooms([roomSplit]) : roomSplit
  })
  return flatten(validManualRoomsSplit)
}
