// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgWhatsappColor = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <g clipPath="url(#whatsappColor_svg__a)">
      <path
        d="m0 20 1.413-5.135A9.859 9.859 0 0 1 .083 9.91C.087 4.446 4.554 0 10.043 0a9.92 9.92 0 0 1 7.044 2.907A9.827 9.827 0 0 1 20 9.917c-.002 5.465-4.47 9.911-9.958 9.911h-.005a9.987 9.987 0 0 1-4.758-1.206L0 20Z"
        fill="#fff"
      />
      <path
        d="M10.003 2C5.59 2 2.002 5.587 2 9.996a7.977 7.977 0 0 0 1.223 4.256l.19.303-.808 2.95 3.028-.793.292.173A7.99 7.99 0 0 0 9.997 18H10c4.41 0 7.998-3.588 8-7.997a7.948 7.948 0 0 0-2.341-5.658A7.947 7.947 0 0 0 10.003 2Z"
        fill="url(#whatsappColor_svg__b)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.543 5.918c-.185-.397-.379-.406-.554-.412L6.517 5.5c-.164 0-.43.06-.656.299-.225.239-.861.816-.861 1.99 0 1.176.882 2.31 1.005 2.47.122.16 1.702 2.647 4.203 3.604 2.078.795 2.5.637 2.952.598.451-.04 1.456-.578 1.66-1.136.206-.557.206-1.035.144-1.135-.061-.1-.225-.159-.472-.278-.246-.12-1.455-.697-1.68-.777-.226-.08-.39-.12-.554.12-.165.238-.635.776-.78.935-.143.16-.286.18-.532.06-.247-.119-1.039-.371-1.979-1.185-.731-.633-1.225-1.414-1.369-1.653-.143-.239-.015-.368.108-.487.11-.107.246-.279.37-.418.123-.14.163-.24.245-.398.082-.16.041-.3-.02-.418-.062-.12-.54-1.3-.758-1.773"
        fill="#fff"
      />
    </g>
    <defs>
      <linearGradient
        id="whatsappColor_svg__b"
        x1={9.837}
        y1={2.96}
        x2={9.918}
        y2={16.633}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#57D163" />
        <stop offset={1} stopColor="#23B33A" />
      </linearGradient>
      <clipPath id="whatsappColor_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgWhatsappColor
