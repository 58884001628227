// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgFindBaggage = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 33 32"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      d="M19.333 5V1h-10v4M25.333 14V8a3 3 0 0 0-3-3h-16a3 3 0 0 0-3 3v20a3 3 0 0 0 3 3h13.545M9.333 12v12M14.333 12v12M19.333 12v2M30.333 30l-3.465-3.465"
      stroke="#000"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <path
      d="M23.333 28a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
      stroke="#000"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </svg>
)

export default SvgFindBaggage
